import axios, { AxiosResponse } from "axios";
import { Media } from "./paths";
import { ConfirmUpload, UploadParams, UploadUrl } from "src/@types/media";

const upload = (params: UploadParams): Promise<AxiosResponse<UploadUrl>> => {
    const url = Media.admin.generateUpload();

    return axios.post(url, params);
};

const uploadComplete = (params: ConfirmUpload): Promise<AxiosResponse> => {
    const url = Media.admin.confirmUpload();

    return axios.post(url, params);
};

export {
    upload,
    uploadComplete
};
