import { Rate } from "src/@types/currency";
import { CurrencyTypes } from "src/@types/vehicle";

function convert(value: number, from: CurrencyTypes, to: CurrencyTypes, rates: Rate[]): number {
    // Trova i tassi di cambio per la valuta di partenza e la valuta di destinazione
    const rateFrom = from === CurrencyTypes.EUR ? 1 : rates.find(rate => rate.currency === from)?.rate;

    const rateTo = to === CurrencyTypes.EUR ? 1 : rates.find(rate => rate.currency === to)?.rate;

    // Verifica se i tassi di cambio sono stati trovati
    if (rateFrom === undefined) {
        throw new Error(`Tasso di cambio non trovato per la valuta di partenza '${from}'`);
    }
    if (rateTo === undefined) {
        throw new Error(`Tasso di cambio non trovato per la valuta di destinazione '${to}'`);
    }

    // Calcola il valore convertito
    return value * (1 / rateFrom) * rateTo;
}

function toMoneySymbol(currency: CurrencyTypes | string): string {
    switch (currency.toUpperCase()) {
        case CurrencyTypes.USD:
            return '$';
        case CurrencyTypes.JPY:
            return '¥';
        case CurrencyTypes.BGN:
            return 'лв';
        case CurrencyTypes.GBP:
            return '£';
        case CurrencyTypes.DKK:
            return 'kr';
        case CurrencyTypes.CHF:
            return 'CHF';
        case CurrencyTypes.NOK:
            return 'kr';
        case CurrencyTypes.AUD:
            return '$';
        case CurrencyTypes.CNY:
            return '¥';
        case CurrencyTypes.IDR:
            return 'Rp';
        case CurrencyTypes.ILS:
            return '₪';
        case CurrencyTypes.KRW:
            return '₩';
        case CurrencyTypes.SGD:
            return '$';
        case CurrencyTypes.RON:
            return 'lei';
        case CurrencyTypes.BRL:
            return 'R$';
        case CurrencyTypes.INR:
            return '₹';
        case CurrencyTypes.MXN:
            return '$';
        case CurrencyTypes.MYR:
            return 'RM';
        case CurrencyTypes.PHP:
            return '₱';
        case CurrencyTypes.THB:
            return '฿';
        case CurrencyTypes.PLN:
            return 'zł';
        case CurrencyTypes.SEK:
            return 'kr';
        case CurrencyTypes.CAD:
            return '$';
        case CurrencyTypes.HKD:
            return '$';
        case CurrencyTypes.EUR:
            return '€';
        case CurrencyTypes.CZK:
            return 'Kč';
        case CurrencyTypes.HUF:
            return 'Ft';
        case CurrencyTypes.ISK:
            return 'kr';
        case CurrencyTypes.TRY:
            return 'TL';
        case CurrencyTypes.NZD:
            return '$';
        case CurrencyTypes.ZAR:
            return 'R';
        default:
            throw new Error('Invalid currency type');
    }
}

export { toMoneySymbol, convert };