import Container from '@mui/material/Container';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import Page from 'src/appComponents/Page';
import useLocales from 'src/appHooks/useLocales';
import { useSettingsContext } from 'src/components/settings';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSelector } from 'react-redux';
import { dispatch, RootState } from 'src/redux/store';
import { FetchDataFuncArgs } from 'src/@types/logs';
import Logs from 'src/appComponents/logs/Logs';
import { PATH_DASHBOARD } from 'src/routes/paths';
import useResponsive from 'src/hooks/useResponsive';
import { rolesOperations } from 'src/redux/roles';

export default function AdminRoleLogs() {

    const { themeStretch } = useSettingsContext();

    const isDesktop = useResponsive('up', 'sm');

    const { translate, currentLang } = useLocales();

    const { logsLoading, logsList, role } = useSelector((state: RootState) => state.roles);

    const { id = '' } = useParams();

    useEffect(() => {
        if (role?.id !== id) {
            dispatch(rolesOperations.getAdminRole(id));
        }
    }, [id, currentLang, role]);

    const fetchLogs = (searchFilters: FetchDataFuncArgs) => {
        dispatch(rolesOperations.getAdminRoleLogs({ id: id, filters: { ...searchFilters, eventType: searchFilters.eventType || "all" } }));
    };

    //-----LABELS

    const createLabels = useCallback(() => {

        const LABELS: string[] = [
            'roleCreated',
            'roleUpdated',
            'roleDisabled',
            'roleEnabled'
        ];

        return LABELS.map((label) => ({
            label: `${translate(`role.logs.eventTypes.${label}`)}`,
            key: label,
        }));
    }, [translate]);

    const typesLabels = useMemo(
        () => [
            {
                label: `${translate(`commons.all`)}`,
                key: 'all',
            },
            ...createLabels(),
        ],
        [createLabels, translate]
    );

    return (
        <Page title={`${translate('role.logs.title.admin')}`}>
            <Container maxWidth={themeStretch ? false : 'lg'} sx={{ p: 0 }}>

                <HeaderBreadcrumbs
                    heading={`${translate('role.logs.title.admin')}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate("role.title_plural")}`, href: PATH_DASHBOARD.tenant.role.admin.list },
                        { name: `${role?.name}`, href: PATH_DASHBOARD.tenant.role.admin.detail(id) },
                        { name: `${translate('commons.logs')}` }
                    ]}
                    sx={{ px: isDesktop ? 5 : 2, mb: isDesktop ? 5 : 2 }}
                />

                <Logs
                    tableData={logsList as any}
                    isLoading={logsLoading}
                    id={id!}
                    header={`${translate(`commons.logs`)} `}
                    subheader={`${translate(`role.logs.subtitle`)}`}
                    labels={typesLabels}
                    fetchData={fetchLogs}
                    localesPath={"role"}
                />
            </Container>
        </Page>
    );
}
