import { Box, Typography } from "@mui/material";
import { UseFormReturn } from 'react-hook-form';
import useLocales from 'src/appHooks/useLocales';
import { RHFTextField } from 'src/components/hook-form';
import { GenericOrganizationEditWithFather } from "src/@types/organizations";
import { CustomField } from "src/@types/customField";

type Props = {
    outerRef: any;
    formMethods: UseFormReturn<GenericOrganizationEditWithFather>;
    setOnEditForm: (value: boolean) => void;
    onEditForm: boolean;
    isDisabled: (name: string, other?: boolean, and?: boolean) => boolean;
    customFieldsProp: CustomField[];
    handleCustomFields: (event: any) => void;
    setErrorInCustom: (value: boolean) => void;
    setFieldsWithError: (value: CustomField[]) => void;
};

export default function OrganizationStep3({
    outerRef,
    formMethods,
    setOnEditForm,
    onEditForm,
    isDisabled,
    customFieldsProp,
    handleCustomFields,
    setErrorInCustom,
    setFieldsWithError
}: Props) {
    const { getValues, trigger, watch } = formMethods;

    const { translate } = useLocales();

    const validateFields = async (yupSchema: any) => {
        const formValues = getValues();

        try {
            await yupSchema.validate(formValues, { abortEarly: false });

            return true;
        } catch (err: any) {
            return false;
        }
    };

    if (!outerRef?.current) {
        outerRef.current = {
            validateFields,
            onSave: () => {
                const formValues = getValues();

                return { ...formValues };
            }
        };
    };

    const onChangeValidate = async (field: any) => {
        await trigger(field, { shouldFocus: true });
    };

    const customFieldsValues = watch("customFields");

    return (
        <Box sx={{ height: 500 }}>
            <Typography variant="h5">{`${translate('organization.form.steps.contact')}`}</Typography>

            <Typography variant="body2" sx={{ mt: 1 }}>
                {`${translate('organization.form.description.contact')}`}
            </Typography>

            <Box>

                <Box
                    sx={{
                        display: 'grid',
                        columnGap: 2,
                        rowGap: 3,
                        mt: 5,
                        mb: 3,
                        gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                    }}
                >

                    <RHFTextField
                        name="contact.privateEmail"
                        label={`${translate('organization.form.contact.privateEmail')}`}
                        disabled={isDisabled("contact.privateEmail")}
                    />

                    <RHFTextField
                        name="contact.businessEmail"
                        label={`${translate('organization.form.contact.businessEmail')}`}
                        disabled={isDisabled("contact.businessEmail")}
                    />

                    <RHFTextField
                        name="contact.privatePhone"
                        label={`${translate('organization.form.contact.privatePhone')}`}
                        disabled={isDisabled("contact.privatePhone")}
                    />

                    <RHFTextField
                        name="contact.businessPhone"
                        label={`${translate('organization.form.contact.businessPhone')}`}
                        disabled={isDisabled("contact.businessPhone")}
                    />

                    <RHFTextField
                        name="contact.privateMobilePhone"
                        label={`${translate('organization.form.contact.privateMobilePhone')}`}
                        disabled={isDisabled("contact.privateMobilePhone")}
                    />

                    <RHFTextField
                        name="contact.businessMobilePhone"
                        label={`${translate('organization.form.contact.businessMobilePhone')}`}
                        disabled={isDisabled("contact.businessMobilePhone")}
                    />

                    <RHFTextField
                        name="contact.fax"
                        label={`${translate('organization.form.contact.fax')}`}
                        disabled={isDisabled("contact.fax")}
                    />

                </Box>

                {/*                 {customFieldsProp && (
                    <CustomFieldFormRenderer
                        context={"organization"}
                        handleCustomFields={handleCustomFields}
                        values={customFieldsValues}
                        columnGap={2}
                        repeat={'repeat(2, 1fr)'}
                        isDisabled={isDisabled}
                        section={["Contact", "Contacts"]}
                        onChangeValidate={onChangeValidate}
                        setOnEditForm={setOnEditForm}
                        onEditForm={onEditForm}
                        setErrorInCustom={setErrorInCustom}
                        setFieldsWithError={setFieldsWithError}
                    />
                )} */}

            </Box>
        </Box>
    );
}
