import { Crop } from "react-image-crop";

export type UploadTypes = "Image" | "Video" | "Audio" | "Attach" | "Bundle";

export type EntityType = "ReturnRequest" | "Shop";

export interface FileWithSection extends File {
    section?: string
}

export interface FileWithResizeInfo extends FileWithSection {
    imageResizeParameters?: ImageResizeParameters
}

export type ImageResizeParameters = {
    width: number,
    height: number,
    cropInfo: {
        x: number,
        y: number,
        width: number,
        height: number
    } | null
}

export type UploadParams = {
    entityId: string,
    fileName: string,
    entityType: EntityType,
    mediaType: UploadTypes,
}

export type ConfirmUpload = {
    entityType: EntityType,
    entityId: string,
    fileName: string,
    section: string | null,
    mediaType: UploadTypes,
    uploadUrl: string,
    imageResizeParameters: ImageResizeParameters | null
}

export type UploadUrl = {
    uploadUrl: string
}

//------ USED IN IMAGE EDITOR

export interface FileWithPreview extends File {
    preview: string
}

export type ImageInModalType = {
    preview: string,
    aspect: number,
    crop?: Crop,
    rotation?: number,
    scale?: number,
    originalWidth?: number,
    originalHeight?: number
}

export const DEFAULT_IMAGE_IN_MODAL: ImageInModalType = {
    preview: "",
    rotation: 0,
    scale: 1,
    aspect: 16 / 9
};