import axios, { AxiosResponse } from "axios";
import { Orders } from "./paths";
import { PagedResponseType, Tag } from "src/@types/commons";
import { CreditNote, DeliveryNote, DocumentFilters, DocumentSearchResult, Invoice, Order, OrderFilters, OrderSearchResult } from "src/@types/orders";

const searchVendor = (options: OrderFilters): Promise<AxiosResponse<PagedResponseType<OrderSearchResult>>> => {
    const url = Orders.admin.orders.vendor.search();

    return axios.get(url, { params: options, paramsSerializer: { indexes: null } });
};

const searchCustomer = (options: OrderFilters): Promise<AxiosResponse<PagedResponseType<OrderSearchResult>>> => {
    const url = Orders.admin.orders.customer.search();

    return axios.get(url, { params: options, paramsSerializer: { indexes: null } });
};

const searchTags = (tag: string, size: number): Promise<AxiosResponse<Tag[]>> => {
    const url = Orders.admin.orders.searchTags();

    return axios.get(url, {
        params: {
            query: tag,
            limit: size
        }
    });
};

const searchDocumentsTags = (tag: string, size: number): Promise<AxiosResponse<Tag[]>> => {
    const url = Orders.admin.orders.searchDocumentsTags();

    return axios.get(url, {
        params: {
            query: tag,
            limit: size
        }
    });
};

const detail = (id: string): Promise<AxiosResponse<Order>> => {
    const url = Orders.admin.orders.detail(id);

    return axios.get(url);
};

const searchVendorDocuments = (options: DocumentFilters): Promise<AxiosResponse<PagedResponseType<DocumentSearchResult>>> => {
    const url = Orders.admin.orders.vendor.searchDocument();

    return axios.get(url, { params: options });
};

const searchCustomerDocuments = (options: DocumentFilters): Promise<AxiosResponse<PagedResponseType<DocumentSearchResult>>> => {
    const url = Orders.admin.orders.customer.searchDocument();

    return axios.get(url, { params: options });
};

const detailInvoice = (id: string): Promise<AxiosResponse<Invoice>> => {
    const url = Orders.admin.orders.invoiceDetail(id);

    return axios.get(url);
};

const detailDeliveryNote = (id: string): Promise<AxiosResponse<DeliveryNote>> => {
    const url = Orders.admin.orders.deliveryNoteDetail(id);

    return axios.get(url);
};

const detailCreditNote = (id: string): Promise<AxiosResponse<CreditNote>> => {
    const url = Orders.admin.orders.creditNoteDetail(id);

    return axios.get(url);
};

const searchOrdersLogs = (id: string, filters: any, isMine: boolean): Promise<AxiosResponse<PagedResponseType<any>>> => {
    const url = Orders.admin.orders.searchOrderLogs(id);

    return axios.get<any>(url, { params: filters });
};

const searchDocumentsLogs = (id: string, type: string, filters: any, isMine: boolean): Promise<AxiosResponse<PagedResponseType<any>>> => {
    let url = "";
    switch (type) {
        case "Invoice":
            url = Orders.admin.orders.searchInvoiceLogs(id);

            break;
        case "CreditNote":
            url = Orders.admin.orders.searchCreditNoteLogs(id);

            break;
        case "DeliveryNote":
            url = Orders.admin.orders.searchDeliveryNoteLogs(id);

            break;
    }

    return axios.get<any>(url, { params: filters });
};

export {
    searchVendor,
    searchCustomer,
    searchTags,
    searchDocumentsTags,
    detail,
    searchVendorDocuments,
    searchCustomerDocuments,
    detailDeliveryNote,
    detailInvoice,
    searchOrdersLogs,
    searchDocumentsLogs,
    detailCreditNote
};