import { Box } from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { MatrixResult } from "src/hooks/usePowersearch";

interface SparePartsSearchTemplateProps {
    option: MatrixResult;
    searchQuery: string;
}

export default function SparePartsSearchTemplate({ option, searchQuery }: SparePartsSearchTemplateProps) {

    const externalIdParts = parse(
        `${option.partsExternalId}`,
        match(`${option.partsExternalId}`, searchQuery)
    );

    const nameParts = parse(
        `${option.sparePartName}`,
        match(`${option.sparePartName}`, searchQuery)
    );

    const pathParts = parse(option?.path, match(option?.path, searchQuery));

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                {option.partsExternalId && (
                    <div>
                        {externalIdParts.map((part, index) => (
                            <Box key={index}
                                component='span'
                                sx={{
                                    typography: 'subtitle2',
                                    textTransform: 'capitalize',
                                    color: part.highlight ? 'primary.main' : 'text.primary'
                                }}>
                                {part.text}
                            </Box>
                        ))}
                    </div>
                )}
                {option.sparePartName && (
                    <div>
                        {nameParts.map((part, index) => (
                            <Box
                                key={index}
                                component="span"
                                sx={{
                                    typography: 'subtitle2',
                                    textTransform: 'capitalize',
                                    color: part.highlight ? 'primary.main' : 'text.primary'
                                }}
                            >
                                {part.text}
                            </Box>
                        ))}
                    </div>
                )}
            </div>
            {option.path && (
                <div>
                    {pathParts.map((part, index) => (
                        <Box key={index}
                            component="span"
                            sx={{
                                typography: 'caption',
                                color: part.highlight ? 'primary.main' : 'text.secondary',
                            }}>
                            {part.text}
                        </Box>
                    ))}
                </div>
            )}
        </div>
    );
}