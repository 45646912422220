import { useState } from 'react';
import { Box, alpha } from '@mui/material';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { Media } from 'src/@types/request';
import { ReactComponent as MissingImage } from "./ic_missingImage.svg";
import { MediaWithFocus } from './MediaWithFocus';
import { getMediaName, getMediaUrl } from './utils';

type MediaSliderProps = {
    images: Media[],
    blockZoom?: boolean
}

const MediaSliderStyle = {
    "& .thumbnails": {
        minWidth: { xs: "200px", md: "205px" },
        maxWidth: { xs: "200px", md: "205px" },
        maxHeight: "150px",
        position: 'relative'
    },
    "& .thumbnails-images": {
        borderRadius: "15px",
        width: "100%",
        height: "100%",
        objectFit: "cover",
        border: "1px ridge",
        borderColor: (theme: any) => theme.palette.text.main
    },
    "& .missingImageBox": {
        borderRadius: "15px",
        width: "100%",
        height: "100%",
        objectFit: "cover",
        border: "1px ridge",
        borderColor: (theme: any) => theme.palette.text.main,
        display: "flex",
        justifyContent: "center"
    }
};

export default function MediaSlider({ images, blockZoom }: MediaSliderProps) {

    const [isFocusOpen, seIsFocusOpen] = useState(false);

    const [indexToOpen, setIndexToOpen] = useState(0);

    const focusToggle = (index?: string) => {
        if (index) setIndexToOpen(Number(index));
        isFocusOpen ? seIsFocusOpen(false) : seIsFocusOpen(true);
    };

    return (
        <>
            <MediaWithFocus
                isOpen={isFocusOpen}
                toggle={focusToggle}
                images={images}
                indexToStart={indexToOpen}
            />

            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    overflowX: 'auto',
                    pb: 1,
                    height: 180,
                    gap: 1.5,
                    ...MediaSliderStyle
                }}
            >
                {images.map((image, index) =>
                    <Box
                        key={index}
                        className={"thumbnails"}
                    >
                        {image.fileInfo.length > 0 ?
                            <>
                                <img
                                    src={getMediaUrl(image, 250, 250)}
                                    alt={getMediaName(getMediaUrl(image, 250, 250))}
                                    className={"thumbnails-images"}
                                />

                                {!blockZoom &&
                                    <ZoomOutMapIcon
                                        onClick={() => focusToggle(index.toString())}
                                        sx={{
                                            backgroundColor: (theme) => theme.palette.background.paper,
                                            border: "3px solid",
                                            borderRadius: "10px",
                                            borderColor: (theme) => theme.palette.text.primary,
                                            cursor: "pointer",
                                            position: 'absolute',
                                            top: '7%', left: '77.5%',
                                            width: "17.5%", height: "22.5%", p: 0.4
                                        }}
                                    />
                                }

                            </>
                            :
                            <Box className={"missingImageBox"} sx={{ backgroundColor: (theme) => theme.palette.mode === "light" ? '#F9FAFB' : alpha('#F9FAFB', 0.8) }}>
                                <MissingImage />
                            </Box>
                        }
                    </Box>
                )}
            </Box>
        </>
    );
}