import { useMemo } from "react";
import { Navigate } from "react-router";
import { useAuth } from "src/appHooks/useAuth";
import { PATH_DASHBOARD } from "src/routes/paths";

export default function PendingActivationGuard() {

    const { status } = useAuth();

    const page = useMemo(() => {
        if (status === 201)
            return <Navigate to={PATH_DASHBOARD.general.pendingActivation} />;
        if (status === 400)
            return <Navigate to={PATH_DASHBOARD.general.contactAdmin} />;
        else
            return <Navigate to={PATH_DASHBOARD.general.organizationSelector} replace />;

    }, [status]);

    return (<>
        {page}
    </>);
}