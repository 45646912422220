import { useRef } from "react";
import { UseFormReturn } from "react-hook-form";
import FormProvider from '../../../../components/hook-form';
import { Vehicle } from "src/@types/vehicle";
import { Box } from "@mui/material";
import useResponsive from "src/hooks/useResponsive";
import LogisticStep1 from './logisticsSteps/LogisticStep1';
import LogisticStep3 from './logisticsSteps/LogisticStep3';
import LogisticStep4 from './logisticsSteps/LogisticStep4';
import LogisticStep5 from './logisticsSteps/LogisticStep5';
import LogisticStep2 from './logisticsSteps/LogisticStep2';
import { logisticStep1Schema } from "./Constant";
import { LogisticsData } from "src/@types/logistics";

type Props = {
    outerRef: any;
    vehicle?: Vehicle;
    step: number;
    onSubmitFn: (data: LogisticsData) => Promise<void>;
    onEditForm: boolean;
    setOnEditForm: (value: boolean) => void;
    formMethods: UseFormReturn<LogisticsData>;
};

export default function LogisticsNewEditFormContainer({ outerRef, step, onSubmitFn, onEditForm, setOnEditForm, formMethods }: Props) {
    const isDesktop = useResponsive('up', 'sm');

    const step1Ref = useRef();

    const step2Ref = useRef();

    const step3Ref = useRef();

    const step4Ref = useRef();

    const step5Ref = useRef();

    const { getValues, handleSubmit } = formMethods;

    const validateStep1 = () => (step1Ref?.current as any)?.validateFields(logisticStep1Schema);

    const onSave = () => (step1Ref?.current as any)?.onSave();

    if (!outerRef?.current) {
        outerRef.current = {
            getValues,
            validateStep1,
            onSave
        };
    }

    return (
        <FormProvider methods={formMethods} onSubmit={handleSubmit(onSubmitFn)}>
            <Box sx={{ p: isDesktop ? 5 : 2 }}>
                {step === 0 &&
                    <LogisticStep1
                        outerRef={step1Ref}
                        formMethods={formMethods}
                        onEditForm={onEditForm}
                        setOnEditForm={setOnEditForm}
                    />}
                {step === 1 && <LogisticStep2 outerRef={step2Ref} formMethods={formMethods} />}
                {step === 2 && <LogisticStep3 outerRef={step3Ref} formMethods={formMethods} />}
                {step === 3 && <LogisticStep4 outerRef={step4Ref} formMethods={formMethods} />}
                {step === 4 && <LogisticStep5 outerRef={step5Ref} formMethods={formMethods} />}
            </Box>
        </FormProvider>
    );
}

