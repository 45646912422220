import Container from '@mui/material/Container';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import Page from 'src/appComponents/Page';
import useLocales from 'src/appHooks/useLocales';
import { useSettingsContext } from 'src/components/settings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { dispatch, RootState } from 'src/redux/store';
import { vehicleOperations } from 'src/redux/vehicle';
import { resetVehicle } from 'src/redux/vehicle/vehicle-slices';
import LoadingScreen from 'src/appComponents/loading-screen';
import { FetchDataFuncArgs } from 'src/@types/logs';
import Logs from 'src/appComponents/logs/Logs';

const LABELS = [
    'vehicleLogisticsCreated',
    'vehicleLogisticsUpdated'
];

export default function LogisticsLogs() {

    const { themeStretch } = useSettingsContext();

    const isDesktop = useResponsive('up', 'sm');

    const { translate, currentLang } = useLocales();

    const { vehicle, isLoading, vehicleLogs, isVehicleLogsLoading } = useSelector((state: RootState) => state.vehicle);

    const { vehicleId } = useParams();

    useEffect(() => {
        dispatch(vehicleOperations.getVehicleDisplayById(vehicleId || ""));

        return () => {
            dispatch(resetVehicle());
        };
    }, [vehicleId, currentLang]);

    const fetchLogs = (searchFilters: FetchDataFuncArgs) => {
        dispatch(vehicleOperations.getVehicleLogisticsLogs(searchFilters));
    };

    //-----LABELS

    const createLabels = useCallback(() => {
        return LABELS.map((label) => ({
            label: `${translate(`vehicle.logistic.logs.eventTypes.${label}`)}`,
            key: label,
        }));
    }, [translate]);

    const typesLabels = useMemo(
        () => [
            {
                label: `${translate(`commons.all`)}`,
                key: '',
            },
            ...createLabels(),
        ],
        [createLabels, translate]
    );

    //----------------------------------------------------------------

    return (
        <Page title="Vehicle: Logistics Logs">
            <Container maxWidth={themeStretch ? false : 'lg'} sx={{ p: 0 }}>
                {isLoading || !vehicleLogs ? (
                    <LoadingScreen />
                ) : (
                    <>
                        <HeaderBreadcrumbs
                            heading={`${vehicle?.brand} ${vehicle?.model} : ${translate('vehicle.logistic.logs.title')}`}
                            links={[
                                { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                                { name: `${translate('vehicle.title_plural')}`, href: PATH_DASHBOARD.vehicle.list },
                                { name: `${vehicle?.brand} ${vehicle?.model}`, href: PATH_DASHBOARD.vehicle.detail(vehicle?.id) },
                                { name: `${translate('vehicle.logistic.title')}`, href: PATH_DASHBOARD.vehicle.logisticView(vehicle?.id) },
                                { name: `${translate('commons.logs')}` }
                            ]}
                            sx={{ px: isDesktop ? 5 : 2, mb: isDesktop ? 5 : 2 }}
                        />
                        {/* ------------------------------------------------------------------- */}
                        <Logs
                            tableData={vehicleLogs}
                            isLoading={isVehicleLogsLoading}
                            id={vehicleId!}
                            header={`${translate(`commons.logs`)} `}
                            subheader={`${translate(`vehicle.logistic.logs.subtitle`)}`}
                            labels={typesLabels}
                            fetchData={fetchLogs}
                            localesPath={"vehicle.logistic"}
                        />
                    </>
                )}
            </Container>
        </Page>
    );
}
