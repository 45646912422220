import { Stack, InputAdornment, TextField, MenuItem, Button, FormControl, InputLabel, OutlinedInput, Select, Box } from '@mui/material';
import useLocales from "src/appHooks/useLocales";
import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { DEFAULT_ORGANIZATION_FILTERS, OrganizationFilters } from 'src/@types/organizations';
import Iconify from 'src/components/iconify';
import { ToolbarSearchFilters } from 'src/@types/commons';
import { useFormContext } from 'react-hook-form';

type FilterProps = {
  filters: OrganizationFilters,
  onFilter: VoidFunction,
  resetAll: VoidFunction,
  optionsFields: ToolbarSearchFilters[],
  setShowSummary?: (value: boolean) => void,
  showSummary?: boolean
};

export default function OrganizationGeneralFilters({ filters, onFilter, resetAll, optionsFields, setShowSummary, showSummary }: FilterProps) {

  const { translate } = useLocales();

  const { setValue } = useFormContext();

  const [resetFilter, setResetFilter] = useState(false);

  const [showDelete, setShowDelete] = useState(false);

  const [filterValues, setFilterValues] = useState({ ...filters, serviceType: "all", searchValue: "" });

  const [strFilterCheck, setStrFilterCheck] = useState(JSON.stringify(filters));

  const searchServiceAndValue = (filters: OrganizationFilters) => {

    if (filters.name) return "name";
    if (filters.externalId) return "externalId";

    return "all";
  };

  useEffect(() => {

    const strFilter = JSON.stringify(filters);

    if (!showSummary) {

      if (strFilter !== strFilterCheck) {

        setStrFilterCheck(strFilter);

        const serviceType = searchServiceAndValue(filters);

        setFilterValues({
          ...filters,
          serviceType: serviceType,
          searchValue: filters[serviceType] || ""
        });

        if (filters[serviceType]) {
          setShowDelete(true);
        }
      }
    } else {
      setFilterValues({
        ...filters,
        serviceType: "all",
        searchValue: filters.all || ""
      });
      setShowDelete(!!filters.all);
    }

  }, [filters]);

  useEffect(() => {
    if (resetFilter) {
      setFilterValues({ ...DEFAULT_ORGANIZATION_FILTERS, serviceType: "all", searchValue: "" });
      setResetFilter(false);
      resetAll();
    }
  }, [resetFilter, resetAll]);

  const handleChangeValues = (newVal: any, type: string) => {
    const valuesCopy = JSON.parse(JSON.stringify(filterValues));

    valuesCopy[type] = newVal;

    setFilterValues(valuesCopy);
  };

  const setAllValues = () => {
    for (const [key, value] of Object.entries(filterValues)) {

      if (key === "serviceType") {
        setValue(value!.toString(), filterValues.searchValue);
      } else if (key !== "searchValue") {
        setValue(`${key}`, DEFAULT_ORGANIZATION_FILTERS[key]);
      }
    }
  };

  const handleSearch = () => {
    if (filterValues.searchValue) {
      setAllValues();
      onFilter();
      setShowDelete(true);
      if (setShowSummary) setShowSummary(false);
    }
  };

  const handleDelete = () => {
    setResetFilter(true);
    setShowDelete(false);
  };

  return (
    <Stack
      direction={{ xs: 'column', sm: 'column', md: 'row' }}
      sx={{
        px: 3,
        pt: 2.5,
        pb: { xs: 0, md: 2.5 }
      }}
    >
      <FormControl
        sx={{
          minWidth: { xs: "100%", md: 160 },
          mr: 2,
          mb: { xs: 2, md: 0 }
        }}
        variant="outlined"
      >
        <InputLabel
          id="label"
          sx={{ '&.Mui-focused': { color: 'grey.500' } }}
        >
          {`${translate('returns.searchType')}`}
        </InputLabel>
        <Select
          labelId="label"
          id="serviceType"
          value={filterValues.serviceType}
          input={
            <OutlinedInput label={`${translate('returns.searchType')}`} />
          }
          onChange={(event) => handleChangeValues(event.target.value, "serviceType")}
        >
          {optionsFields.map((option) => (
            <MenuItem
              key={option.key}
              value={option.key}
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        type="search"
        value={filterValues.searchValue}
        onChange={(event) => handleChangeValues(event.target.value, "searchValue")}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleSearch();
          }
        }}
        placeholder={`${translate('commons.search')}`}
        sx={{ width: "100%", mr: 2 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon={'eva:search-fill'}
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Button
          startIcon={
            <Iconify
              icon={'bx:search-alt'}
              sx={{
                ml: 1.5,
                mt: 0.5
              }}
            />
          }
          onClick={handleSearch}
          size="large"
          sx={{
            mt: 0.5,
            width: { xs: "100%" }
          }}
        />

        <Button
          startIcon={<DeleteIcon sx={{ ml: 1.5, mt: 0.75 }} />}
          onClick={handleDelete}
          sx={{
            display: `${(showDelete) ? "inline" : "none"}`,
            mt: 0.5,
            width: { xs: "100%" }
          }}
          size="large"
        />
      </Box>

    </Stack>
  );
}
