import { Typography, Table, TableHead, TableRow, TableCell, TableBody, Box, TypographyProps, useTheme } from "@mui/material";
import { CreditNote, DeliveryNote, DocType, Invoice, OrderAmount, VendorAndCustomerInOrders } from "src/@types/orders";
import CurrencyAdapter from "src/appComponents/CurrencyAdapter";
import DateZone from "src/appComponents/DateZone";
import { Logo } from "src/appComponents/logo";
import Label from "src/components/label";
import { useLocales } from "src/locales";

interface InvoiceProps {
    document: Invoice | DeliveryNote | CreditNote,
    type?: DocType,
    isPrinting?: boolean
}

export default function DocumentView({ document, type, isPrinting }: InvoiceProps) {

    const { translate } = useLocales();

    const theme = useTheme();

    const vat = (obj: OrderAmount, sx?: TypographyProps["sx"]): JSX.Element => {

        let val: JSX.Element;

        if (!obj)
            return <Typography variant="body2" sx={sx}>—</Typography>;

        if (obj.withoutVat !== undefined)
            val = <CurrencyAdapter display={'inline'} variant="body2" sx={sx} value={obj.withoutVat} currency={obj.currentCurrency} />;
        else if (obj.withVat !== undefined)
            val = <CurrencyAdapter display={'inline'} variant="body2" sx={sx} value={obj.withVat} currency={obj.currentCurrency} />;
        else
            val = <Typography variant="body2" sx={sx}>—</Typography>;

        return val;
    };

    const getAddress = (org: VendorAndCustomerInOrders): string[] => {
        let addr = "";
        let city = "";
        let country = "";

        addr += org.address.address ? org.address.address + ", " : "";
        addr += org.address.district ? org.address.district + "" : "";
        city += org.address.zipCode ? org.address.zipCode + ", " : "";
        city += org.address.city ? org.address.city + "" : "";
        country += org.address.country ? org.address.country : "";

        return [addr, city, country];
    };

    const getDoc: Record<DocType, string> = {
        "": "",
        Invoice: `${translate('documents.detail.invoiceN')}`,
        CreditNote: `${translate('documents.detail.creditNoteN')}`,
        DeliveryNote: `${translate('documents.detail.deliveryNoteN')}`
    };

    return (
        <>
            <Box
                display={'flex'}
                sx={{ bgcolor: theme.palette.error.lighter }}
            >
                <Typography sx={{ mx: "auto", color: theme.palette.error.darker }} variant="body1">
                    {`${translate('documents.messages.notOriginal')}`}
                </Typography>
            </Box>

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 2.5
            }}>
                <Logo sx={{ m: 3 }} align='left' disabledLink={true} />
                <Box sx={{ m: 3 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                        {(type && type === "Invoice" && document.hasDeliveryNote) &&
                            <Label
                                color={"success"}
                                sx={{
                                    textTransform: 'uppercase',
                                    mr: 1
                                }}
                            >
                                {`${translate('documents.messages.periodicInvoice')}`}
                            </Label>
                        }
                        <Typography variant="h6">{getDoc[type || ""]} {document.externalId}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'right' }} gap={1}>
                        <Typography variant="body2">{`${translate('documents.detail.documentDate')}`}</Typography>
                        <DateZone variant="subtitle2" date={document.createdOn} shortMonth noSeconds />
                    </Box>
                </Box>
            </div>
            <Box sx={{
                display: 'grid',
                columnGap: 2,
                p: 3,
                rowGap: 10,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }
            }}>

                <Box>
                    <Typography variant="overline" color={'#6D6D6D'}>{`${translate('documents.detail.documentTo')}`}</Typography>
                    <Typography sx={{ mt: 3 }} variant="subtitle1">{document.customer.externalId + ' - ' + document.customer.name}</Typography>
                    <Typography>{`${translate('orders.vat')}: ` + document.customer.vat}</Typography>
                    {getAddress(document.customer).map((add, ind) =>
                        <Typography key={ind}>{add}</Typography>
                    )}
                    <Typography sx={{ mt: 3 }}>{`${translate('organization.types.hub')}`}: {document.vendor.externalId + " " + document.vendor.name}</Typography>
                </Box>

            </Box>
            <Box sx={{ width: "100%" }}>
                <Table sx={{ background: 'paper' }}>
                    <TableHead >
                        <TableRow sx={{ borderBottom: '1px solid', borderColor: 'divider' }}>
                            <TableCell />
                            <TableCell>#</TableCell>
                            <TableCell>{`${translate('documents.documentHeaders.part')}`}</TableCell>
                            <TableCell>{`${translate('documents.documentHeaders.qty')}`}</TableCell>
                            <TableCell>{`${translate('documents.documentHeaders.unitPrice')}`}</TableCell>
                            <TableCell>{`${translate('documents.documentHeaders.grossPrice')}`}</TableCell>
                            <TableCell>{`${translate('documents.documentHeaders.regularDiscount')}`}</TableCell>
                            <TableCell>{`${translate('documents.documentHeaders.regularDiscountAmount')}`}</TableCell>
                            <TableCell>{`${translate('documents.documentHeaders.extraDiscount')}`}</TableCell>
                            <TableCell>{`${translate('documents.documentHeaders.totalDiscount')}`}</TableCell>
                            <TableCell>{`${translate('documents.documentHeaders.total')}`}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {document.items.map((item, ind) => (
                            <TableRow key={ind} sx={{ borderBottom: '1px solid ', borderColor: 'divider' }}>
                                <TableCell />
                                <TableCell>{ind + 1}</TableCell>
                                <TableCell>
                                    <Box>
                                        {item.externalId + ""}
                                    </Box>
                                    <Box>
                                        {item.name + ""}
                                    </Box>
                                </TableCell>
                                <TableCell>{item.quantity}</TableCell>
                                <TableCell sx={{ px: 1 }}>{vat(item.cataloguePrice)}</TableCell>
                                <TableCell>{vat(item.grossAmount)}</TableCell>
                                <TableCell>
                                    <Typography variant="body2">
                                        {(item.regularDiscountPercentage ? (item.regularDiscountPercentage * 100).toFixed(2) : '0') + ' %'}
                                    </Typography>
                                </TableCell>
                                <TableCell>{vat(item.regularDiscountAmount)}</TableCell>
                                <TableCell>{vat(item.extraDiscountAmount)}</TableCell>
                                <TableCell>{vat(item.totalDiscountAmount)}</TableCell>
                                <TableCell sx={{ px: 1 }}>{vat(item.netAmount)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    justifyItems: "end", gap: 1,
                    my: 3, mr: 4, ml: "auto", width: "35%"
                }}
            >
                <Typography>
                    {`${translate('documents.documentHeaders.subtotal')}`}
                </Typography>
                <Typography>
                    {vat(document.grossAmount)}
                </Typography>

                <Typography>
                    {`${translate('documents.documentHeaders.discount')}`}
                </Typography>
                <Typography >
                    {vat(document.totalDiscountAmount, { color: 'red !important' })}
                </Typography>

                <Typography>
                    <b>{`${translate('documents.documentHeaders.total')}`}</b>
                </Typography>
                <Typography>
                    <b>{vat(document.netAmount)}</b>
                </Typography>

                <Typography />

                {document.netAmount.withoutVat !== undefined &&
                    <Typography variant="body2">{`${translate('documents.detail.noVat')}`}</Typography>
                }
            </Box>
        </>
    );
}