import { useFormContext } from 'react-hook-form';
import { Box, Stack, Badge, Button, Drawer, Divider, IconButton, Typography, ButtonGroup, TextField, MenuItem } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { NAV } from 'src/config';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import useLocales from 'src/appHooks/useLocales';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DEFAULT_REPORT_FILTERS, RecurringTypeArr, ReportFilters, ReportTypeDataArr } from 'src/@types/report';
import { RHFSelect } from 'src/components/hook-form';

type UserFilterProps = {
  filters: ReportFilters,
  isDefault: (filter: ReportFilters, controller?: ReportFilters) => boolean
  isOpen: boolean,
  onOpen: VoidFunction,
  onFilter: VoidFunction,
  onResetAll: VoidFunction,
  onClose: VoidFunction,
  reset: boolean,
  resetFormElement: string,
  setResetFormElement: (value: string) => void,
  isScheduled: boolean
};

export default function ReportFilterSidebar({
  isDefault,
  isOpen,
  onResetAll,
  onFilter,
  onOpen,
  onClose,
  reset,
  resetFormElement,
  filters,
  setResetFormElement,
  isScheduled
}: UserFilterProps) {

  const { translate } = useLocales();

  const { setValue, getValues } = useFormContext();

  const [values, setValues] = useState(filters);

  const [valuesAfterFilter, setValuesAfterFilter] = useState(filters);

  useEffect(() => {
    if (reset) setValues(DEFAULT_REPORT_FILTERS);
  }, [reset]);

  const handleChangeValues = useCallback((values: any, newVal: any, type: string) => {

    const valuesCopy = JSON.parse(JSON.stringify(values));

    valuesCopy[type] = newVal;

    setValues(valuesCopy);
  }, []);

  useEffect(() => {
    if (resetFormElement) {
      handleChangeValues(values, DEFAULT_REPORT_FILTERS[resetFormElement], resetFormElement);

      setResetFormElement("");
    }
  }, [resetFormElement, handleChangeValues, values, setResetFormElement]);

  useEffect(() => {
    if (JSON.stringify(filters) !== JSON.stringify(values)) {
      setValues(filters);
      setValuesAfterFilter(filters);
    }
  }, [filters]);

  const handleCloseFilter = () => {
    if (reset) {
      setValues(DEFAULT_REPORT_FILTERS);
    } else if (JSON.stringify(values) !== JSON.stringify(valuesAfterFilter)) {
      setValues(valuesAfterFilter);
    }
    onClose();
  };

  const handleOnResetAll = () => {
    setValues(DEFAULT_REPORT_FILTERS);
    onResetAll();
  };

  const setAllValues = () => {
    for (const [key, value] of Object.entries(values)) {

      if (key === "all") {
        setValue(key, DEFAULT_REPORT_FILTERS[key]);
      } else {
        const formValue = getValues(key);

        if (JSON.stringify(value) !== JSON.stringify(formValue)) {
          setValue(key, value);
        }
      }
    }
    setValuesAfterFilter(values);
  };

  const handleOnFilter = () => {
    setAllValues();
    onFilter();
  };

  const dotController = useMemo(() => {
    if (valuesAfterFilter) {
      return valuesAfterFilter;
    }

    return DEFAULT_REPORT_FILTERS;
  }, [valuesAfterFilter]);

  return (
    <>
      <Button variant="outlined" size="small" onClick={onOpen}>
        <FilterListIcon />
      </Button>

      <Drawer
        anchor="right"
        open={isOpen}
        onClose={handleCloseFilter}
        PaperProps={{
          sx: { width: NAV.W_DASHBOARD },
          style: { boxShadow: 'none' }
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            {`${translate('commons.filters')}`}
          </Typography>

          <IconButton onClick={handleCloseFilter}>
            <Iconify icon={'eva:close-fill'} width={20} height={20} />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ p: 2.5 }}>

            <Stack spacing={1}>
              <TextField
                name='title'
                label={`${translate(`commons.title`)}`}
                variant="outlined"
                value={values.name}
                onChange={(event) => {
                  handleChangeValues(values, event.target.value, 'title');
                }}
              />
            </Stack>

            {!isScheduled &&
              <Stack spacing={1}>
                <RHFSelect
                  name="recurringType"
                  label={`${translate("report.tableHeaders.recurringType")}`}
                  value={values.recurringType}
                  onChange={(e) => {
                    handleChangeValues(values, e.target.value, "recurringType");
                  }}
                >
                  {RecurringTypeArr.map((element) => (
                    <MenuItem key={element || "All"} value={element || "All"}>
                      {`${translate(element ? `report.types.${element[0].toLowerCase() + element.slice(1)}` : `commons.all`)}`}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Stack>
            }

            <Stack spacing={1}>
              <RHFSelect
                name="reportType"
                label={`${translate("report.tableHeaders.reportType")}`}
                value={values.reportType}
                onChange={(e) => {
                  handleChangeValues(values, e.target.value, "reportType");
                }}
              >
                {ReportTypeDataArr.map((element) => (
                  <MenuItem key={element || "All"} value={element || "All"}>
                    {`${translate(element ? `report.types.${element[0].toLowerCase() + element.slice(1)}` : `commons.all`)}`}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Stack>

          </Stack>
        </Scrollbar>

        <Box sx={{ p: 1, pb: 2, position: 'relative' }}>

          <Badge
            color="error"
            variant="dot"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            invisible={isDefault(values, dotController)}
            sx={{ width: 1, right: 25, top: 20, position: 'absolute' }}
          />

          <ButtonGroup variant="outlined" aria-label="outlined button group" fullWidth>
            <Button
              fullWidth
              size="large"
              type="submit"
              color="inherit"
              variant="outlined"
              onClick={handleOnResetAll}
              startIcon={<Iconify icon="ic:round-clear-all" />}
              sx={{ px: 1 }}
            >
              {`${translate('commons.clear')}`}
            </Button>
            <Button
              fullWidth
              size="large"
              type="submit"
              color="inherit"
              variant="outlined"
              onClick={handleOnFilter}
              startIcon={<Iconify icon="eva:search-fill" />}
              sx={{ px: 1 }}
            //disabled={inEditTags}
            >
              {`${translate('commons.apply')}`}
            </Button>
          </ButtonGroup>

        </Box>

      </Drawer>
    </>
  );
}

