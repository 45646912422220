import { AddCircle, Business, DeleteForever } from "@mui/icons-material";
import { Box, Card, Chip, Divider, FormControlLabel, IconButton, InputAdornment, MenuItem, Switch, TablePagination, TextField, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { cloneDeep } from "lodash";
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { LocalizedString } from "src/@types/commons";
import { CustomField } from "src/@types/customField";
import { NewGroup, PropertyCategory, PropertyType } from "src/@types/group";
import { CurrencyTypes } from "src/@types/vehicle";
import useCurrency from "src/appHooks/useCurrency";
import useTable from "src/appHooks/useTable";
import useTenant from "src/appHooks/useTenant";
import StepButtons from "src/components/buttons/StepButtons";
import { noData } from "src/components/empty-content/EmptyContent";
import { useSettingsContext } from "src/components/settings";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";
import { Action } from "src/sections/@dashboard/group/newEditGroup/GroupWizard";
import { convert } from "src/utils/currency";
import { getCustomFieldLabel, getLocalizedString } from "src/utils/CustomFieldManagment";
import { DataGridStyle } from "src/utils/DataGridStyle";

interface PropertiesStepProps {
    changeStep: Dispatch<SetStateAction<number>>,
    state?: NewGroup,
    onSubmit?: Dispatch<Action>
}

export default function PropertiesStep({ changeStep, onSubmit, state }: PropertiesStepProps) {

    const { translate, currentLang } = useLocales();

    const { customFields } = useTenant();

    const isDesktop = useResponsive('up', 'md');

    const { dense, onChangeDense, page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable();

    const [selectedFields, setSelectedFields] = useState<CustomField[]>(() => {

        let fields: CustomField[] = [];

        state?.propertyDependency.properties.forEach((v) => {
            let found = customFields.find(fiel => fiel.id === v.name);

            if (found)
                fields.push(found);
        });

        return fields;
    });

    const [properties, setProperties] = useState<Record<string, string[]>>(() => {
        return state?.propertyDependency.properties.reduce((acc, item) => {

            acc[item.name] = item.values as string[];

            return acc;
        }, {} as Record<string, string[]>) ?? {};
    });

    const fields = useMemo(() => {
        return customFields.filter(v => v.enabled && (v.entityType === "User" || v.entityType === "Organization"));
    }, [customFields]);

    const handleSwitch = (field: CustomField) => {

        setSelectedFields(p => {
            const exists = p.some(v => v.id === field.id);

            if (exists) {
                setProperties(p => {

                    const { [field.id]: _, ...rest } = p;

                    return rest;
                });

                return p.filter(v => v.id !== field.id);
            }

            setProperties(p => ({ ...p, [field.id]: field.type.toLowerCase() === "checkbox" ? ['false'] : [] }));

            return [...p, field];
        });
    };

    const disabled = useMemo(() => {
        return Object.values(properties).some(v => !v.length);
    }, [properties]);

    const handleSave = () => {

        let ret = {
            propertyDependency: {
                properties: [] as PropertyType[]
            }
        };

        Object.entries(properties).map(([key, val]) => {

            let found = fields.find(f => f.id === key)?.entityType;

            let tmp: PropertyType = {
                category: (found ?? 'None') as PropertyCategory,
                name: key,
                values: val
            };

            ret.propertyDependency.properties.push(tmp);
        });

        if (onSubmit)
            onSubmit({
                payload: ret,
                type: 'update'
            });

    };

    const COLUMNS: GridColDef<CustomField>[] = [
        {
            field: 'name',
            headerName: `${translate('groups.form.propertyName')}`,
            valueGetter: (_, field) => getCustomFieldLabel(customFields, field.id, currentLang),
            flex: 1
        },
        {
            field: 'entityType',
            headerName: translate('commons.category'),
            flex: 1
        },
        {
            field: '',
            headerName: translate('commons.enabled'),
            renderCell: (p) => <Switch
                checked={!!selectedFields.find(v => v.id === p.row.id)}
                onClick={(e) => {
                    e.stopPropagation();
                    handleSwitch(p.row);
                }}
            />,
            flex: 1
        }
    ];

    const getHeight = () => {
        let height: string | number = "auto";

        if (!dense || fields.length === 0) {
            if (isDesktop) height = rowsPerPage === 5 ? 380 : 650;
            else height = rowsPerPage === 5 ? 440 : 700;
        }

        return height;
    };

    const getMaxHeight = () => {
        return isDesktop ? 650 : 700;
    };

    return (
        <Card sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', mb: 3 }}>
                <Typography variant='h6'>
                    {translate('groups.form.steps.properties')}
                </Typography>
                {state?.groupType && <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Business />
                    <Typography variant="subtitle1">
                        {translate(state.groupType === "Customer" ? "commons.customer_plural" : "commons.organizations")}
                    </Typography>
                </Box>}
            </Box>
            <DataGrid
                columns={COLUMNS}
                rows={fields}
                disableColumnMenu
                disableRowSelectionOnClick
                disableColumnResize
                onRowClick={(row) => handleSwitch(row.row)}
                paginationModel={{
                    page: page,
                    pageSize: rowsPerPage
                }}
                paginationMode="client"
                density={dense ? 'compact' : 'standard'}
                sx={{
                    ...DataGridStyle,
                    maxHeight: () => getMaxHeight(),
                    height: () => getHeight()
                }}
                pageSizeOptions={[5, 10, 15, 30]}
                slots={{
                    noRowsOverlay: noData,
                    footer: () => (
                        <Box sx={{
                            position: 'relative',
                            width: { xs: "85vw", sm: "90vw", md: "auto" },
                        }}>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15, 30]}
                                component="div"
                                count={fields.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={onChangePage}
                                onRowsPerPageChange={onChangeRowsPerPage}
                                labelRowsPerPage={`${translate('commons.rowsPerPage')}`}
                                sx={{
                                    overflow: "hidden",
                                    "& .MuiTablePagination-toolbar": { px: 0 },
                                    "& .MuiTablePagination-actions": { m: isDesktop ? "inherit" : 0 }
                                }}
                            />

                            <FormControlLabel
                                control={<Switch checked={dense} onChange={onChangeDense} />}
                                label={`${translate('commons.dense')}`}
                                sx={{
                                    px: { xs: 0, sm: 2 },
                                    py: { xs: 0, sm: 1.5 },
                                    pb: { xs: 1.5, sm: 0 },
                                    mx: 0, top: 0,
                                    justifyContent: "center",
                                    width: { xs: "80vw", sm: "auto" },
                                    position: { sm: 'absolute' }
                                }}
                            />
                        </Box>
                    )
                }}
            />
            {Object.keys(properties).length > 0 &&
                <Box>
                    <Divider />
                    <Typography variant="h6" sx={{ mt: 5, px: 2 }}>
                        {translate("groups.messages.helpProperties")}
                    </Typography>
                    <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
                        <PropertiesInput properties={properties} setProperties={setProperties} selectedField={selectedFields} setSelectedField={setSelectedFields} />
                    </Box>
                </Box>}
            <StepButtons changeStep={changeStep} onSave={() => handleSave()} disabled={disabled} />
        </Card>
    );
}

interface PropertiesInputProps {
    setProperties: Dispatch<SetStateAction<Record<string, string[]>>>,
    properties: Record<string, string[]>,
    selectedField: CustomField[],
    setSelectedField: Dispatch<SetStateAction<CustomField[]>>
}

function PropertiesInput({ properties, setProperties, selectedField, setSelectedField }: PropertiesInputProps) {

    const { customFields } = useTenant();

    const { currentLang } = useLocales();

    const { currency } = useSettingsContext();

    const { rates } = useCurrency();

    const getLabel = useCallback((id: string) => getCustomFieldLabel(customFields, id, currentLang), [currentLang, customFields]);

    const handleProperties = (field: CustomField, value: any) => {

        let propertiesCopy = cloneDeep(properties);

        propertiesCopy[field.id] = value;

        setProperties(propertiesCopy);
    };

    const handleRemoveField = (val: CustomField) => {
        setProperties(p => {

            const { [val.id]: _, ...rest } = p;

            return rest;
        });

        setSelectedField(p => p.filter(v => v.id !== val.id));
    };

    const handleRemoveValue = (index: number, id: string) => {
        setProperties(p => {
            let copy = cloneDeep(p);

            copy[id].splice(index, 1);

            return copy;
        });
    };

    return (
        <Box sx={{ my: 2 }}>

            <Box sx={{ display: 'flex', flexDirection: 'column', my: 3, gap: 3 }}>
                {selectedField.filter(v => v.type.toLowerCase() !== "checkbox").map((val, index) =>

                    <Box key={index} sx={{ display: 'flex', gap: 3, width: '100%', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '50%' }}>
                            <IconButton onClick={() => handleRemoveField(val)} color="error">
                                <DeleteForever />
                            </IconButton>
                            <CustomFieldComponent field={val} handleProperties={handleProperties} label={getLabel(val.id)} setProperties={setProperties} />

                        </Box>
                        {val.type === "Currency" &&
                            <Box sx={{ maxWidth: '50%', width: '50%' }}>
                                {properties[val.id] && properties[val.id].map((chip, ind) =>
                                    <Chip
                                        sx={{ m: 1 }}
                                        key={ind}
                                        label={convert(+chip, CurrencyTypes.EUR, currency.label, rates)}
                                        onDelete={() => handleRemoveValue(ind, val.id)}
                                    />)}
                            </Box>
                        }
                        {val.type !== "Currency" && <Box sx={{ maxWidth: '50%', width: '50%' }}>
                            {properties[val.id] && properties[val.id].map((chip, ind) =>
                                <Chip
                                    sx={{ m: 1 }}
                                    key={ind}
                                    label={chip}
                                    onDelete={() => handleRemoveValue(ind, val.id)}
                                />)}
                        </Box>}

                    </Box>
                )}
            </Box>
            {selectedField.filter(v => v.type.toLowerCase() === "checkbox").length > 0 && <Divider />}
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 3, my: 3 }}>
                {selectedField.filter(v => v.type.toLowerCase() === "checkbox").map((val, index) =>

                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }} key={index}>

                        <CustomFieldComponent field={val} handleProperties={handleProperties} label={getLabel(val.id)} setProperties={setProperties} />
                        <IconButton onClick={() => handleRemoveField(val)} color="error">
                            <DeleteForever />
                        </IconButton>
                    </Box>
                )}
            </Box>
        </Box>
    );
}

interface CustomFieldComponentProp {
    label: string,
    field: CustomField,
    handleProperties: (field: CustomField, value: any) => void,
    setProperties: Dispatch<SetStateAction<Record<string, string[]>>>
}

function CustomFieldComponent({ label, field, handleProperties, setProperties }: CustomFieldComponentProp) {

    const { currentLang } = useLocales();

    const { currency } = useSettingsContext();

    const { rates } = useCurrency();

    const [value, setValue] = useState("");

    const add = (date?: string) => {
        setProperties(p => {
            const copy = cloneDeep(p);

            if (field.type.toLowerCase() === "currency")
                copy[field.id].push(convert(+value, currency.label, CurrencyTypes.EUR, rates).toString());

            else
                copy[field.id].push(date || value);

            return copy;
        });

        setValue("");
    };

    const handleCheckBox = (val: boolean) => {
        setProperties(p => {
            const copy = cloneDeep(p);

            copy[field.id][0] = val + "";

            return copy;
        });

        setValue(val + "");
    };

    const handleSelect = (val: LocalizedString[]) => {
        setProperties(p => {
            const copy = cloneDeep(p);

            copy[field.id].push(val[0].text || "");

            return copy;
        });
    };

    const getField = (field: CustomField) => {

        switch (field.type.toLowerCase()) {
            case 'string':
                return (

                    <TextField
                        name={field.id}
                        key={field.id}
                        id={field.id}
                        sx={{ width: '100%' }}
                        value={value}
                        label={label}
                        onChange={(e) => setValue(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter" && value) {
                                add();
                            }
                        }}
                    />
                );
            case 'number':
                return (
                    <TextField
                        name={field.id}
                        id={field.id}
                        sx={{ width: '100%' }}
                        key={field.id}
                        label={label}
                        value={value}
                        required={field.required}
                        type="number"
                        onKeyDown={(e) => {
                            if (e.key === "Enter" && value) {
                                add();
                            }
                        }}
                        onChange={(e) => {
                            if (!isNaN(+e.target.value)) {
                                setValue(value);

                            }
                        }}

                    />
                );
            case 'currency':
                return (
                    <TextField

                        label={label}
                        sx={{ width: '100%' }}
                        value={value}
                        onChange={(e) => {
                            if (!isNaN(+e.target.value))
                                setValue(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter" && value) {
                                add();
                            }
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{currency.symbol}</InputAdornment>
                        }}
                    />
                );
            case 'date':
                return (
                    <DatePicker
                        name={field.id}
                        key={field.id}
                        sx={{ width: '100%' }}
                        label={label}
                        onAccept={(value) => {
                            if (value)
                                add(format(new Date(value), 'yyyy-MM-dd'));
                        }}
                        onChange={(fromDateValue, inputValue) => {
                            if (!inputValue.validationError && fromDateValue)
                                setValue(format(new Date(fromDateValue), 'yyyy-MM-dd'));

                        }}
                    />
                );
            case 'checkbox':
                return (
                    <FormControlLabel
                        sx={{ width: '70%' }}
                        name={field.id}
                        id={field.id}
                        key={field.id}
                        onChange={(_, checked) => handleCheckBox(checked)}
                        control={
                            <Switch
                                value={value === 'true'}
                            />}
                        label={label}
                    />
                );
            case 'select':
                return <TextField
                    select
                    label={label}
                    sx={{ width: '100%' }}
                    value={value}
                >
                    <MenuItem sx={{ display: 'none' }} value={""} />

                    {field.selectOptions && field.selectOptions.map((option, index) => (
                        <MenuItem
                            key={index}
                            hidden={!!option.values.find(l => l.text === '')}
                            value={option.values[0]?.text || ""}
                            onClick={() => handleSelect(option.values)}
                        >
                            {getLocalizedString(option.values, currentLang)}
                        </MenuItem>)
                    )}

                </TextField>;
        }
    };

    return (
        <Box sx={{ width: field.type.toLowerCase() === "checkbox" ? '70%' : '100%', display: 'flex', alignItems: 'center', gap: 2 }}>
            {getField(field)}

            {field.type.toLowerCase() !== "checkbox" && <IconButton
                sx={{ visibility: value ? 'visible' : 'hidden' }}
                onClick={() => {
                    add();
                }}>
                <AddCircle />
            </IconButton>}

        </Box>
    );
}