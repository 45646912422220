import { Box, Button, Card, Modal, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { To, useNavigate } from "react-router";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";
import { resetSuccessMessage } from "src/redux/modal/modal-slices";
import { dispatch, RootState } from "src/redux/store";

export default function SuccessModal() {

    const { translate } = useLocales();

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'lg');

    const { success } = useSelector((state: RootState) => state.modal);

    const goBack = () => {
        dispatch(resetSuccessMessage());
        if (success) navigate((success.returnTo || success.goTo) as To);
    };

    const getLabel = () => {
        if (success) {
            if (success.returnTo) return `${translate('commons.toList')}`;

            if (success.goTo) return `${translate('commons.goToList')}`;
        }

        return "";
    };

    return (
        <Modal open={!!success} onClose={() => dispatch(resetSuccessMessage())}>
            <Card
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: isDesktop ? '25vw' : '70vw',
                    p: isDesktop ? 4 : 2,
                }}
            >
                <Box sx={{ textAlign: "center" }}>

                    <Box component="img" src="/assets/illustrations/illustration_docs.svg" sx={{ width: "45%", m: "auto" }} />

                    <Typography variant="h6" sx={{ mt: 3 }}>
                        {translate(`commons.success`)}
                    </Typography>

                    <Typography variant='body1'>
                        {success?.text as string ?? ""}
                    </Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4 }}>

                        {success && (success.returnTo || success.goTo) &&
                            <Button
                                variant="contained"
                                size={isDesktop ? "small" : "medium"}
                                onClick={goBack}
                                sx={{ mt: 3, borderRadius: "100px" }}
                            >
                                {getLabel()}
                            </Button>
                        }

                        <Button
                            variant="contained"
                            size={isDesktop ? "small" : "medium"}
                            onClick={() => {
                                (success && success.callback) && success.callback();
                                dispatch(resetSuccessMessage());
                            }}
                            sx={{ mt: 3, borderRadius: "100px" }}
                        >
                            {`${translate('commons.close')}`}
                        </Button>

                    </Box>
                </Box>
            </Card>
        </Modal>
    );
}
