import { TableRow, TableCell } from '@mui/material';
import useLocales from 'src/appHooks/useLocales';
import Label from 'src/components/label';
import DateZone from 'src/appComponents/DateZone';
import { Email } from 'src/@types/email';

// ----------------------------------------------------------------------

type Props = {
  row: Email;
  onDetailsRow: VoidFunction;
};

export default function EmailTableRow({ row, onDetailsRow }: Props) {
  const { recipient, subject, requestedAt, status } = row;

  const { translate } = useLocales();

  const statusColorPicker = () => {
    switch (status) {
      case "Delivery": return "success";
      case "Bounce": return "warning";
      default: return "error";
    };
  };

  return (
    <TableRow hover onClick={onDetailsRow}>

      <TableCell align="left">{recipient}</TableCell>

      <TableCell align="left">{subject}</TableCell>

      <TableCell align='left'><DateZone date={new Date(requestedAt)} /></TableCell>

      <TableCell align="left">
        <Label
          color={statusColorPicker()}
          sx={{ textTransform: 'uppercase' }}
        >
          {`${translate(`statuses.${status[0].toLowerCase() + status.slice(1)}`)}`}
        </Label>
      </TableCell>

    </TableRow>
  );
}
