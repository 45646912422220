import {
  Box,
  Grid,
  Stack,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Divider,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  ListSubheader,
  Radio,
} from '@mui/material';
import { useLocales } from 'src/locales';
import CancelIcon from '@mui/icons-material/Cancel';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { useState } from 'react';
import useResponsive from 'src/hooks/useResponsive';
import { RequestNew, RequestReason, RequestType } from 'src/@types/request';

interface RequestProps {
  onChange: (name: string, value: any) => void;
  request: RequestNew;
}

export default function Request({ onChange, request }: RequestProps) {
  const { translate } = useLocales();

  const [val, setVal] = useState<RequestType | null>(
    request.requestType !== '' ? request.requestType : null
  );

  const [option, setOption] = useState<RequestReason | null>(request.requestReason);

  const isDesktop = useResponsive('up', 'sm');

  const TOGGLES = [
    {
      val: 'NewPart',
      type: 'unused',
      icon: <PublishedWithChangesIcon sx={{ mt: 3 }} />,
    },
    {
      val: 'DamagedPart',
      type: 'unused',
      icon: <CancelIcon sx={{ mt: 3 }} />,
    },
    {
      val: 'WrongShipment',
      type: 'unused',
      icon: <LocalShippingIcon sx={{ mt: 3 }} />,
    },
    {
      val: 'Core',
      type: 'used',
      icon: <KeyboardReturnIcon sx={{ mt: 3 }} />,
    },
    {
      val: 'Quality',
      type: 'unused',
      icon: <FactCheckIcon sx={{ mt: 3 }} />,
    },
    {
      val: 'Warranty',
      type: 'used',
      icon: <BrokenImageIcon sx={{ mt: 3 }} />,
    },
  ];

  return (
    <Box>
      <Typography variant="h6" sx={{ m: 3 }}>
        {`${translate('request.reason')}`}
      </Typography>

      <ToggleButtonGroup
        sx={{
          gridTemplateColumns: isDesktop ? '1fr 1fr 1fr' : '1fr 1fr',
          display: 'grid',
          border: 'none',
          m: 2,
          gap: 3,
        }}
        value={val}
        onChange={(_, v) => {
          setVal(v);
          onChange('requestType', v);
          setOption(null);
        }}
        exclusive
        size="large"
        color="primary"
      >
        {TOGGLES.map((toggle, index) => (
          <ToggleButton
            key={index}
            value={toggle.val}
            sx={{
              outlineWidth: '1px',
              outlineStyle: 'solid',
              ml: '-1px !important',
              outlineColor: val === toggle.val ? 'inherit' : '#E2E2E2',
            }}
          >
            <Grid item xs={4}>
              <Stack
                sx={{
                  alignContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                {toggle.icon}

                <Typography sx={{ mt: 1 }} variant="subtitle2">
                  {`${translate(`request.${toggle.val}`)}`}
                </Typography>
                <Typography sx={{ mb: 3 }} variant="caption">
                  {`${translate(`request.form.${toggle.type}`)}`}
                </Typography>
              </Stack>
            </Grid>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>

      <SelectReasonComponent val={val} option={option} setOption={setOption} onChange={onChange} />
    </Box>
  );
}

interface SelectReasonComponentProps {
  val: RequestType | null;
  option: RequestReason | null;
  setOption: (opt: RequestReason | null) => void;
  onChange: (name: string, value: any) => void;
}

function SelectReasonComponent({ val, option, setOption, onChange }: SelectReasonComponentProps) {
  const isDesktop = useResponsive('up', 'sm');

  const { translate } = useLocales();

  if (val === 'NewPart') {
    return (
      <Box>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            px: isDesktop ? 5 : 2,
            py: isDesktop ? 3 : 1,
            gap: 1,
            flexDirection: isDesktop ? 'row' : 'column',
            alignItems: 'center',
          }}
        >
          <Box>
            <Typography variant="h6">{`${translate('request.reason')}`}</Typography>
            <Typography variant="body1">{`${translate('request.validSub')}`}</Typography>
          </Box>
          <Box
            sx={{
              ml: isDesktop ? 'auto' : 0,
              display: 'flex',
              gap: 1,
              flexDirection: isDesktop ? 'default' : 'column',
            }}
          >
            <FormControl>
              <InputLabel>{`${translate('request.selectReason.requestReason')} (${translate('commons.optional')})`}</InputLabel>

              <Select
                name="requestReason"
                value={option ?? ''}
                renderValue={(val) => (
                  <Typography>{`${translate(`request.selectReason.${val[0].toLowerCase() + val.slice(1)}`)}`}</Typography>
                )}
                label={`${translate('request.selectReason.requestReason')} (${translate('commons.optional')})`}
                onChange={(e) => {
                  setOption((e.target.value as RequestReason) || null);
                  onChange(e.target.name, (e.target.value as RequestReason) || null);
                }}
                sx={{ minWidth: '250px' }}
              >
                <ListSubheader>
                  <Typography
                    variant="body2"
                    color="text.primary"
                    fontWeight="bold"
                    textTransform={'uppercase'}
                  >{`${translate('request.selectReason.incorrectsize')}`}</Typography>
                </ListSubheader>
                <MenuItem value="TooBig">
                  <Radio />
                  <Typography
                    variant="subtitle2"
                    color={'text.secondary'}
                    sx={{ ml: 1.5 }}
                  >{`${translate('request.selectReason.tooBig')}`}</Typography>
                </MenuItem>
                <MenuItem value="TooSmall">
                  <Radio />
                  <Typography
                    variant="subtitle2"
                    color={'text.secondary'}
                    sx={{ ml: 1.5 }}
                  >{`${translate('request.selectReason.tooSmall')}`}</Typography>
                </MenuItem>
                <MenuItem value="Other">
                  <Radio />
                  <Typography
                    variant="subtitle2"
                    color={'text.secondary'}
                    sx={{ ml: 1.5 }}
                  >{`${translate('request.selectReason.other')}`}</Typography>
                </MenuItem>
                <Divider sx={{ mx: 2 }} />
                <ListSubheader>
                  <Typography
                    variant="body2"
                    color="text.primary"
                    fontWeight="bold"
                    textTransform={'uppercase'}
                  >{`${translate('request.selectReason.wrong')}`}</Typography>
                </ListSubheader>
                <MenuItem value={'UnclearProductInformation'}>
                  <Radio />
                  <Typography
                    variant="subtitle2"
                    color={'text.secondary'}
                    sx={{ ml: 1.5 }}
                  >{`${translate('request.selectReason.unclearProductInformation')}`}</Typography>
                </MenuItem>
                <MenuItem value="UnclearProductPicture">
                  <Radio />
                  <Typography
                    variant="subtitle2"
                    color={'text.secondary'}
                    sx={{ ml: 1.5 }}
                  >{`${translate('request.selectReason.unclearProductPicture')}`}</Typography>
                </MenuItem>
                <MenuItem value="Other">
                  <Radio />
                  <Typography
                    variant="subtitle2"
                    color={'text.secondary'}
                    sx={{ ml: 1.5 }}
                  >{`${translate('request.selectReason.other')}`}</Typography>
                </MenuItem>
                <Divider sx={{ mx: 2 }} />
                <ListSubheader>
                  <Typography
                    variant="body2"
                    color="text.primary"
                    fontWeight="bold"
                    textTransform={'uppercase'}
                  >{`${translate('request.selectReason.delivery')}`}</Typography>
                </ListSubheader>
                <MenuItem value="PartDeliveredTooLate">
                  <Radio />
                  <Typography
                    variant="subtitle2"
                    color={'text.secondary'}
                    sx={{ ml: 1.5 }}
                  >{`${translate('request.selectReason.partDeliveredTooLate')}`}</Typography>
                </MenuItem>
                <MenuItem value="Other">
                  <Radio />
                  <Typography
                    variant="subtitle2"
                    color={'text.secondary'}
                    sx={{ ml: 1.5 }}
                  >{`${translate('request.selectReason.other')}`}</Typography>
                </MenuItem>
                <Divider sx={{ mx: 2 }} />
                <MenuItem value="SomewhereElseCheaper">
                  <Radio />
                  <Typography
                    variant="subtitle2"
                    color={'text.secondary'}
                    sx={{ ml: 1.5 }}
                  >{`${translate('request.selectReason.somewhereElseCheaper')}`}</Typography>
                </MenuItem>
                <MenuItem value="ReceivedDouble">
                  <Radio />
                  <Typography
                    variant="subtitle2"
                    color={'text.secondary'}
                    sx={{ ml: 1.5 }}
                  >{`${translate('request.selectReason.receivedDouble')}`}</Typography>
                </MenuItem>
                <MenuItem value="DidNotUseThePart">
                  <Radio />
                  <Typography
                    variant="subtitle2"
                    color={'text.secondary'}
                    sx={{ ml: 1.5 }}
                  >{`${translate('request.selectReason.didNotUseThePart')}`}</Typography>
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
    );
  }

  if (val === 'Quality') {
    return (
      <Box>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            px: isDesktop ? 5 : 2,
            py: isDesktop ? 3 : 1,
            gap: 1,
            flexDirection: isDesktop ? 'row' : 'column',
            alignItems: isDesktop ? 'center' : 'start',
          }}
        >
          <Box>
            <Typography variant="h6">{`${translate('request.reason')}`}</Typography>
            <Typography variant="body1">{`${translate('request.form.validQual')}`}</Typography>
          </Box>
          <Box
            sx={{
              ml: isDesktop ? 'auto' : 0,
              display: 'flex',
              gap: 1,
              flexDirection: isDesktop ? 'default' : 'column',
              width: isDesktop ? 'auto' : '100%',
            }}
          >
            <FormControl>
              <InputLabel>{`${translate('request.selectReason.requestReason')}`}</InputLabel>
              <Select
                fullWidth
                name="requestReason"
                value={option ?? ''}
                label={`${translate('request.selectReason.requestReason')}`}
                onChange={(e) => {
                  setOption((e.target.value as RequestReason) || null);
                  onChange(e.target.name, (e.target.value as RequestReason) || null);
                }}
                sx={{ minWidth: '250px' }}
              >
                <MenuItem
                  value={'ThePartDoesntWork'}
                >{`${translate('request.selectReason.thePartDoesntWork')}`}</MenuItem>
                <MenuItem
                  value={'OriginalPackagingContainsWrongParts'}
                >{`${translate('request.selectReason.originalPackagingContainsWrongParts')}`}</MenuItem>
                <MenuItem
                  value={'PartOrderedIsNotComplete'}
                >{`${translate('request.selectReason.partOrderedIsNotComplete')}`}</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
    );
  }

  if (val === 'WrongShipment') {
    return (
      <Box>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            px: isDesktop ? 5 : 2,
            py: isDesktop ? 3 : 1,
            gap: 1,
            flexDirection: isDesktop ? 'row' : 'column',
            alignItems: 'center',
          }}
        >
          <Box>
            <Typography variant="h6">{`${translate('request.reason')}`}</Typography>
            <Typography variant="body1">{`${translate('request.form.validDam')}`}</Typography>
          </Box>
          <Box
            sx={{
              ml: isDesktop ? 'auto' : 0,
              display: 'flex',
              gap: 1,
              flexDirection: isDesktop ? 'default' : 'column',
            }}
          >
            <FormControl>
              <InputLabel>{`${translate('request.selectReason.requestReason')}`}</InputLabel>
              <Select
                name="requestReason"
                value={option ?? ''}
                label={`${translate('request.selectReason.requestReason')}`}
                onChange={(e) => {
                  setOption((e.target.value as RequestReason) || null);
                  onChange(e.target.name, (e.target.value as RequestReason) || null);
                }}
                sx={{ minWidth: '250px' }}
              >
                <MenuItem
                  value={'SurplusDelivered'}
                >{`${translate('request.selectReason.surplusDelivered')}`}</MenuItem>
                <MenuItem
                  value={'MissingParts'}
                >{`${translate('request.selectReason.missingParts')}`}</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
    );
  }

  return <></>;
}
