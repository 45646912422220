import { ReactNode, createContext, useCallback, useEffect } from "react";
import { RatesContext } from "src/@types/currency";
import { useAuth } from "src/appHooks/useAuth";
import useLocalStorage from "src/hooks/useLocalStorage";
import { rates as getRates } from "src/services/currencyService";

const initialState: RatesContext = {
    rates: [],
};

const CurrencyRatesContext = createContext(initialState);

type CurrencyRatesProviderProps = {
    children: ReactNode;
};

function CurrencyRatesProvider({ children }: CurrencyRatesProviderProps) {

    const [rates, setRates] = useLocalStorage('rates', initialState);

    const { isAuthenticated } = useAuth();

    const initialize = useCallback(async () => {

        if (isAuthenticated) {
            const response = await getRates();

            setRates({ rates: response.data });
        }
    }, [isAuthenticated]);

    useEffect(() => {
        initialize();
    }, [initialize]);

    return <CurrencyRatesContext.Provider value={rates}>{children}</CurrencyRatesContext.Provider>;
}

export { CurrencyRatesContext, CurrencyRatesProvider };
