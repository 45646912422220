import { Add, Delete, DragIndicator, FilterAlt } from "@mui/icons-material";
import { Typography, Divider, TextField, MenuItem, Chip, FormControlLabel, Checkbox, Button, Box, Stack, IconButton, alpha } from "@mui/material";
import { DateTimePicker, renderTimeViewClock } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { isArray, isObject } from "lodash";
import { useState, useEffect, useCallback, useReducer, useMemo, DragEvent } from "react";
import { useSelector } from "react-redux";
import Flag from "react-world-flags";
import { NewReportScheduled, NewReportRecurring, DEFAULT_NEW_REPORT_SCHEDULED, DEFAULT_NEW_REPORT_RECURRING, ReportTemplate, Fields, OutputTypeArr, OutputSeparatorArr, } from "src/@types/report";
import useTenant from "src/appHooks/useTenant";
import MenuPopover from "src/components/menu-popover";
import { allLangs, useLocales } from "src/locales";
import { RootState } from "src/redux/store";
import { getCustomFieldLabel } from "src/utils/CustomFieldManagment";

enum ActionTypes {
    TITLE,
    DESCRIPTION,
    OUTPUTTYPE,
    RECURRINGTYPE,
    FROM,
    TO,
    EMAILS,
    ENABLED,
    LANGUAGE,
    SEPARATOR,
    RESET,
    TEMPLATE
}

type Action = {
    type: ActionTypes
    payload: any
};

function reduce(state: NewReportRecurring | NewReportScheduled, action: Action): NewReportRecurring | NewReportScheduled {
    switch (action.type) {
        case ActionTypes.TITLE:
            return { ...state, name: action.payload, };

        case ActionTypes.DESCRIPTION:
            return { ...state, description: action.payload, };

        case ActionTypes.OUTPUTTYPE: {
            if (action.payload === "CSV")
                return { ...state, reportExportType: action.payload, reportExportSeparatorType: state.reportExportSeparatorType ?? 'Semicolon' };
            else
                return { ...state, reportExportType: action.payload, reportExportSeparatorType: null };
        }

        case ActionTypes.SEPARATOR:
            return { ...state, reportExportSeparatorType: action.payload };

        case ActionTypes.RECURRINGTYPE:
            return { ...state, recurringType: action.payload, };

        case ActionTypes.FROM:
            return { ...state, from: action.payload, };

        case ActionTypes.TO:
            return { ...state, to: action.payload, };

        case ActionTypes.EMAILS:
            return { ...state, additionalRecipients: action.payload, };

        case ActionTypes.ENABLED:
            return { ...state as NewReportRecurring, enabled: action.payload, };

        case ActionTypes.LANGUAGE:
            return { ...state, overrideCulture: action.payload, };

        case ActionTypes.RESET:
            return action.payload;

        case ActionTypes.TEMPLATE: {
            if (action.payload?.reportExportType === "CSV")
                return { ...state, ...action.payload, reportExportSeparatorType: state.reportExportSeparatorType ?? 'Semicolon', };
            else
                return { ...state, ...action.payload, reportExportSeparatorType: null };
        }
        default:
            return state;
    };
}

const getDate = () => {
    let d = new Date();
    d.setDate(d.getDate() - 15);

    return d;
};

interface ReportNewEditProps {
    isOpen: "recurring" | "scheduled" | null,
    toggle: () => void,
    onSubmit: (state: NewReportScheduled | NewReportRecurring, overrideFilters?: any) => void,
    isEdit?: boolean,
    filter?: any
    isDetailExport?: boolean
}

export default function ReportNewEdit({ isOpen, toggle, onSubmit, isEdit, filter, isDetailExport }: ReportNewEditProps) {

    const { translate, currentLang } = useLocales();

    const initialState: NewReportScheduled | NewReportRecurring = useMemo(() => isOpen === "recurring" ?

        DEFAULT_NEW_REPORT_RECURRING :
        {
            ...DEFAULT_NEW_REPORT_SCHEDULED,
            to: isDetailExport ? null : dayjs(new Date()).endOf('day').format('YYYY-MM-DDTHH:mmZ'),
            from: isDetailExport ? null : dayjs(getDate()).format('YYYY-MM-DDTHH:mmZ')
        },
        [isDetailExport, isOpen]);

    const [state, changeState] = useReducer(reduce, initialState);

    const { columns, report, templates } = useSelector((state: RootState) => state.reports);

    const [selectedTemplate, setSelectedTemplate] = useState<ReportTemplate | null>(null);

    const [columnSwitches, setColumnsSwitches] = useState<Fields[]>([]);

    const [templateTrigger, setTemplateTrigger] = useState(false);

    const [type, setType] = useState(isOpen);

    const [isDisabled, setIsDisabled] = useState(true);

    const parsedFilter = useMemo(() => {
        if (selectedTemplate)
            return selectedTemplate.filter;

        if (filter) {

            const { vendorId: _, customerId: __, ...rest } = filter;

            //remove null or undefined values
            return Object.keys(rest).reduce((acc: any, key) => {
                const value = rest[key];

                if (value !== null && value !== undefined) {
                    acc[key] = value;
                }

                return acc;
            }, {});
        }
    }, [filter, selectedTemplate]);

    const recurringTypes = useMemo(() => ["Daily", "Weekly", "Monthly", "MonthToDay"], []);

    const [error, setError] = useState({
        title: "",
        from: "",
        to: "",
        email: "",
        date: ""
    });

    const reset = useCallback(() => {
        changeState({ type: ActionTypes.RESET, payload: initialState });

        setError({
            title: "",
            from: "",
            to: "",
            email: "",
            date: ""
        });

        setType(isOpen);

        setSelectedTemplate(null);
    }, [isOpen, initialState]);

    useEffect(() => {
        reset();
    }, [isOpen, reset]);

    useEffect(() => {
        if (isEdit && report) {
            changeState({ type: ActionTypes.RESET, payload: { ...initialState, ...report } });
        }
    }, [initialState, isEdit, report]);

    const handleClose = () => {
        toggle();

        reset();
    };

    useEffect(() => {
        if (!isEdit)
            setColumnsSwitches(columns);
        else if (report)
            setColumnsSwitches(report.fields);
    }, [columns, isEdit, report]);

    useEffect(() => {
        if (!state.name || Object.values(error).some(v => !!v)) {
            setIsDisabled(true);
        }
        else if (type === "scheduled" && !isDetailExport && (!state.from || !state.to))
            setIsDisabled(true);
        else if (!columnSwitches.length)
            setIsDisabled(true);
        else {
            setIsDisabled(false);
        }

    }, [state, type, error, columnSwitches.length, isDetailExport]);

    const submit = () => {
        let submitState: NewReportScheduled | NewReportRecurring = {
            ...state,
            fields: columnSwitches,
            overrideCulture: state.overrideCulture || currentLang.locale.code
        };

        onSubmit(submitState, (templates.length > 0 && selectedTemplate) ? selectedTemplate.filter : undefined);

        if (!isEdit)
            reset();
    };

    const handleTemplate = (template: ReportTemplate) => {
        setSelectedTemplate(template);

        changeState({ payload: template, type: ActionTypes.TEMPLATE });

        setTemplateTrigger(p => !p);
    };

    const defaultColumns = useMemo(() => {

        if (isEdit && report)
            return report.fields;

        if (templates.length > 0)
            return state.fields;

    }, [isEdit, report, templates.length, state.fields]);

    return (

        <Box >
            <Box sx={{ overflowY: !isEdit ? 'auto' : undefined, overflowX: 'hidden', height: !isEdit ? '70vh' : undefined, px: 3 }}>
                {!isEdit && <Box sx={{ mb: 2 }}>
                    <Typography variant="h4"> {isOpen === "scheduled" ? `${translate('report.form.title.newScheduled')}` : `${translate('report.form.title.newRecurring')}`}</Typography>
                    <Typography variant='body2'>{`${translate('report.messages.newSubtitle')}`}</Typography>
                </Box>}
                {isEdit && <Box sx={{ mb: 2 }}>
                    <Typography variant='body2'>{`${translate('report.messages.editSubtitle')}`}</Typography>
                </Box>}
                <Divider />

                <Box sx={{ my: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
                    {templates.length > 0 &&
                        <TextField fullWidth select value={selectedTemplate?.name || ""} label={`${translate('report.form.templates')}`}>
                            {templates.map((val, index) => (
                                <MenuItem
                                    key={index}
                                    value={val.name}
                                    onClick={() => handleTemplate(val)}
                                >
                                    {val.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    }
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>

                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, width: '100%' }}>
                            <TextField
                                name='title'
                                label={`${translate('commons.name')}`}
                                autoComplete="off"
                                required
                                fullWidth
                                error={!!error.title}
                                helperText={!!error.title && error.title}
                                sx={{
                                    '& .MuiInputLabel-asterisk': { color: 'error.main' }
                                }}
                                value={state.name}
                                onChange={(e) => {
                                    changeState({ type: ActionTypes.TITLE, payload: e.target.value });

                                    if (!e.target.value)
                                        setError(prev => ({ ...prev, title: `${translate('commons.validation.requiredField')}` }));
                                    else
                                        setError(prev => ({ ...prev, title: "" }));
                                }}
                            />
                            <TextField
                                fullWidth
                                select
                                label={`${translate('commons.culture')}`}
                                defaultValue={""}
                                value={state.overrideCulture}
                                SelectProps={{
                                    renderValue: (value) => {
                                        let val = allLangs.find(v => v.locale.code === value);

                                        if (val)
                                            return <Box sx={{ display: 'flex' }}>
                                                <Flag
                                                    width="28"
                                                    alt={val.label}
                                                    code={val.icon}
                                                />

                                                <Typography
                                                    variant='inherit'
                                                    sx={{ ml: 1 }}>
                                                    {val.label}
                                                </Typography>
                                            </Box>;
                                    }
                                }}
                                onChange={(e) => {
                                    changeState({ type: ActionTypes.LANGUAGE, payload: e.target.value });
                                }}
                            >
                                <MenuItem value={""} sx={{ display: 'none' }} />
                                {allLangs.map((option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.locale.code}
                                        selected={option.locale.code === currentLang.locale.code}
                                    >
                                        <Flag
                                            width="28"
                                            alt={option.label}
                                            code={option.icon}
                                        />

                                        <Typography
                                            variant='inherit'
                                            sx={{ ml: 1 }}>
                                            {option.label}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                        {isEdit && isOpen === "recurring" &&
                            <FormControlLabel
                                onChange={(_, checked) => changeState({ type: ActionTypes.ENABLED, payload: checked })}
                                control={<Checkbox checked={(state as NewReportRecurring).enabled} />}
                                label={`${translate('commons.enabled')}`}
                            />}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, width: '100%' }}>
                        <TextField
                            select
                            name='reportExportType'
                            label={`${translate('report.tableHeaders.outputType')}`}
                            sx={{ width: '100%' }}
                            value={state.reportExportType}
                            onChange={(e) => {
                                changeState({ type: ActionTypes.OUTPUTTYPE, payload: e.target.value });

                            }}
                        >
                            {OutputTypeArr.map(type =>
                                <MenuItem value={type} key={type}>
                                    {`${translate('report.types.' + type.toLowerCase())}`}
                                </MenuItem>
                            )}

                        </TextField>
                        {state.reportExportType === "CSV" && <TextField
                            select
                            name='reportExportSeparatorType'
                            label={`${translate('report.form.separator')}`}
                            sx={{ width: '100%' }}
                            value={state.reportExportSeparatorType}
                            onChange={(e) => {
                                changeState({ type: ActionTypes.SEPARATOR, payload: e.target.value });

                            }}
                        >
                            {OutputSeparatorArr.map(type =>
                                <MenuItem value={type} key={type}>
                                    {`${translate('report.separator.' + type.toLowerCase())}`}
                                </MenuItem>
                            )}

                        </TextField>}
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <TextField
                            fullWidth
                            name='description'
                            label={`${translate('commons.description')}`}
                            value={state.description}
                            onChange={(e) => {
                                changeState({ type: ActionTypes.DESCRIPTION, payload: e.target.value });

                            }}
                        />
                    </Box>
                    {isOpen === "scheduled" && !isDetailExport &&
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                            <DateTimePicker
                                label={`${translate('commons.from')}`}
                                views={['year', 'month', 'day', 'hours', 'minutes']}
                                format={'dd/MM/yyyy HH:mm'}
                                disableFuture
                                viewRenderers={{
                                    hours: renderTimeViewClock,
                                    minutes: renderTimeViewClock,
                                }}
                                value={state!.from ? dayjs(state!.from).toDate() : null}

                                onAccept={(newValue) => {
                                    if (newValue) {

                                        const date = dayjs(newValue).format('YYYY-MM-DDTHH:mmZ');

                                        changeState({ type: ActionTypes.FROM, payload: date });

                                        if (state.to && new Date(state.to).getTime() < new Date(newValue).getTime())
                                            setError(prev => ({
                                                ...prev,
                                                date: `${translate('commons.validation.invalidDate')}`,
                                            }));
                                        else
                                            setError(prev => ({
                                                ...prev,
                                                date: '',
                                            }));

                                        setError(prev => ({ ...prev, from: '' }));

                                    }
                                }}

                                onChange={(fromDateValue, inputval) => {

                                    if (!inputval.validationError && fromDateValue) {

                                        const date = dayjs(fromDateValue).format('YYYY-MM-DDTHH:mmZ');

                                        changeState({ type: ActionTypes.FROM, payload: date });

                                        if (state.to && new Date(state.to).getTime() < fromDateValue.getTime())
                                            setError(prev => ({
                                                ...prev,
                                                date: `${translate('commons.validation.invalidDate')}`,
                                            }));
                                        else
                                            setError(prev => ({
                                                ...prev,
                                                date: '',
                                            }));

                                        setError(prev => ({ ...prev, from: '' }));

                                    }

                                    if (inputval.validationError) {
                                        setError(prev => ({ ...prev, from: `${translate('commons.validation.invalidDate')}` }));
                                    }
                                }}

                                slotProps={{
                                    textField: {
                                        error: !!error.from || !!error.date,
                                        required: true,
                                        helperText: !!error.from || !!error.date && (error.from || error.date),
                                        sx: { '& .MuiInputLabel-asterisk': { color: 'error.main' }, width: '100%' }
                                    }
                                }}
                            />
                            <DateTimePicker
                                label={`${translate('commons.to')}`}
                                views={['year', 'month', 'day', 'hours', 'minutes']}
                                format={'dd/MM/yyyy HH:mm'}
                                viewRenderers={{
                                    hours: renderTimeViewClock,
                                    minutes: renderTimeViewClock,
                                }}

                                value={state!.to ? dayjs(state!.to).toDate() : null}

                                onAccept={(newValue) => {
                                    if (newValue) {
                                        const date = dayjs(newValue).format('YYYY-MM-DDTHH:mmZ');

                                        changeState({ type: ActionTypes.TO, payload: date });
                                        if (state.from && new Date(newValue).getTime() < new Date(state.from).getTime())
                                            setError(prev => ({
                                                ...prev,
                                                date: `${translate('commons.validation.invalidDate')}`,
                                            }));
                                        else
                                            setError(prev => ({
                                                ...prev,
                                                date: "",
                                            }));
                                        setError(prev => ({ ...prev, to: '' }));
                                    }
                                }}

                                onChange={(fromDateValue, inputval) => {

                                    if (!inputval.validationError && fromDateValue) {

                                        const date = dayjs(fromDateValue).format('YYYY-MM-DDTHH:mmZ');

                                        changeState({ type: ActionTypes.TO, payload: date });

                                        if (state.from && fromDateValue.getTime() < new Date(state.from).getTime())
                                            setError(prev => ({
                                                ...prev,
                                                date: `${translate('commons.validation.invalidDate')}`,
                                            }));
                                        else
                                            setError(prev => ({
                                                ...prev,
                                                date: "",
                                            }));
                                        setError(prev => ({ ...prev, to: '' }));

                                    }
                                    if (inputval.validationError) {
                                        setError(prev => ({ ...prev, to: `${translate('commons.validation.invalidDate')}` }));
                                    }
                                }}
                                slotProps={{
                                    textField: {
                                        error: !!error.from || !!error.date,
                                        helperText: !!error.from || !!error.date && (error.from || error.date),
                                        sx: { '& .MuiInputLabel-asterisk': { color: 'error.main' }, width: '100%' },
                                        required: true
                                    }
                                }}
                            />
                        </Box>}
                    {
                        isOpen === "recurring" &&
                        <Box>
                            <TextField
                                select
                                name='recurringType'
                                label={`${translate('report.tableHeaders.recurringType')}`}
                                sx={{ width: '100%' }}
                                value={state.recurringType}
                                onChange={(e) => {
                                    changeState({ type: ActionTypes.RECURRINGTYPE, payload: e.target.value });

                                }}
                            >
                                {recurringTypes.map((val, ind) =>
                                    <MenuItem key={ind} value={val}>
                                        {`${translate(`report.types.${val[0].toLowerCase() + val.slice(1)}`)}`}
                                    </MenuItem>)
                                }

                            </TextField>
                        </Box>
                    }
                    <Box sx={{ display: 'grid', gridTemplateColumns: '100%', gap: 3 }}>
                        {/* <Autocomplete
                            clearIcon={false}
                            options={[]}
                            value={state.additionalRecipients}
                            freeSolo
                            multiple
                            autoSelect
                            onChange={(_, val) => {
                                let tester = val.map((v) => emailRegExp.test(v));

                                if (tester.some(v => !v))
                                    setError(prev => ({ ...prev, email: `${translate('user.validation.emailWrongFormat')}` }));
                                else
                                    setError(prev => ({ ...prev, email: "" }));

                                changeState({ type: ActionTypes.EMAILS, payload: val });
                            }}
                            renderTags={(value, props) =>
                                value.map((option, index) => (
                                    <Chip label={option} {...props({ index })} key={index} />
                                ))
                            }
                            renderInput={(params) =>
                                <TextField
                                    error={!!error.email}
                                    helperText={!!error.email && error.email}
                                    label={`${translate('report.form.addEmails')}`}
                                    {...params}
                                />}
                        /> */}

                    </Box>
                    {(parsedFilter && Object.keys(parsedFilter).length > 0) && <Divider />}
                    {(parsedFilter && Object.keys(parsedFilter).length > 0) &&
                        <FilterDisplay filter={parsedFilter} />
                    }

                </Box>
                <Divider sx={{ my: 1 }} />
                <Box sx={{ my: 2 }}>
                    <Typography variant='h4'>
                        {`${translate('report.form.reportColumns')}`}
                    </Typography>
                    <Typography variant='body2'>{`${translate('report.messages.reportColumnsSubtitle')}`}</Typography>
                </Box>
                <DraggableList
                    columns={columns}
                    onChange={(columns) => setColumnsSwitches(columns)}
                    defaultValue={defaultColumns}
                    templateTrigger={templateTrigger}
                />
            </Box>
            <Divider sx={{ mb: 1 }} />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, px: 3 }}>
                <Button variant='soft' color="inherit" onClick={() => handleClose()}>
                    {`${translate("commons.cancel")}`}
                </Button>
                <Button
                    variant="contained"
                    disabled={isDisabled}
                    onClick={() => submit()}
                >
                    {`${translate('commons.justSave')}`}
                </Button>
            </Box>
        </Box>
    );
}

interface DraggableListProps {
    columns: Fields[],
    onChange: (columns: Fields[]) => void,
    defaultValue?: Fields[],
    templateTrigger?: boolean
}

function DraggableList({ columns, onChange, defaultValue, templateTrigger }: DraggableListProps) {

    const [addableColumns, setAddableColumns] = useState(() => {

        if (defaultValue) {
            return columns.filter(v => !defaultValue.find(d => d.id === v.id));
        }
        else {
            return columns;
        }
    });

    const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

    const [items, setItems] = useState<Fields[]>(defaultValue || []);

    const [draggingItem, setDraggingItem] = useState<Fields | null>(null);

    const [hoveringTarget, setHoveringTarget] = useState<number | null>(null);

    const { translate, currentLang } = useLocales();

    const { customFields } = useTenant();

    useEffect(() => {

        setItems(defaultValue || []);

        setAddableColumns(() => {
            if (defaultValue) {
                return columns.filter(v => !defaultValue.find(d => d.id === v.id));
            }
            else {
                return columns;
            }
        });

    }, [templateTrigger]);

    const handleDragStart = (e: DragEvent<HTMLDivElement>, item: Fields) => {
        setDraggingItem(item);
        e.dataTransfer.setData('text/plain', '');
    };

    const handleDragEnd = () => {
        setDraggingItem(null);
        setHoveringTarget(null);
    };

    const handleDrop = (targetItem: Fields) => {

        let itemsCopy = [...items];

        if (!draggingItem) return;

        const currentIndex = itemsCopy.indexOf(draggingItem);

        const targetIndex = itemsCopy.indexOf(targetItem);

        if (currentIndex !== -1 && targetIndex !== -1) {
            itemsCopy.splice(currentIndex, 1);
            itemsCopy.splice(targetIndex, 0, draggingItem);
            setItems(itemsCopy);
        }
    };

    const handleAdd = (col: Fields, index: number) => {

        setAddableColumns(prev => {
            let copy = [...prev];
            copy.splice(index, 1);

            if (!copy.length)
                setOpenPopover(null);

            return copy;
        });

        setItems(prev => prev.concat([col]));

    };

    const handleRemove = (col: Fields, e: any) => {
        e.stopPropagation();

        setAddableColumns(prev => {
            let copy = [...prev];

            copy.push(col);

            return copy;
        });

        setItems(prev => prev.filter(v => v !== col));

    };

    useEffect(() => {
        onChange(items);

    }, [items, onChange]);

    const getTranslation = useCallback((column: Fields) => {
        if (!column.isCustomField)
            return `${translate(column.id)}`;

        return getCustomFieldLabel(customFields, column.id, currentLang);

    }, [currentLang, customFields, translate]);

    return (
        <Box >
            <Box >
                <Button
                    variant="outlined"
                    size='small'
                    sx={{ mr: 2 }}
                    onClick={(e) => setOpenPopover(e.currentTarget)}
                    disabled={!addableColumns.length}
                >
                    <Add />
                    <Typography>{`${translate(`commons.addCol`)}`}</Typography>
                </Button>

                <MenuPopover
                    disableScrollLock
                    open={openPopover}
                    onClose={() => setOpenPopover(null)}
                    sx={{
                        maxHeight: '30vh',
                        overflowY: 'auto'
                    }}
                >
                    <Stack>
                        {addableColumns.map((val, index) =>
                            <MenuItem
                                key={index}
                                onClick={() => handleAdd(val, index)}
                            >
                                {getTranslation(val)}
                            </MenuItem>
                        )}
                    </Stack>
                </MenuPopover>
            </Box>
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', my: 2, gap: 2 }}>
                {items.map((item, index) => (
                    <Box
                        key={index}
                        draggable="true"
                        onDragStart={(e) => handleDragStart(e, item)}
                        onDragOver={(e) => e.preventDefault()}
                        onDragEnter={() => setHoveringTarget(index)}
                        onDragExit={() => setHoveringTarget(null)}
                        onDragEnd={() => handleDragEnd()}
                        onDrop={() => handleDrop(item)}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            border: '1px solid var(--components-input-outlined, rgba(145, 158, 171, 0.32))',
                            borderRadius: '8px',
                            p: 1,
                            cursor: 'pointer',
                            bgcolor: (theme) => hoveringTarget === index ? theme.palette.primary.lighter : undefined,
                        }}
                    >
                        <Typography variant="body1"><b>{(index + 1) + "."}</b> {getTranslation(item)}</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton onClick={(e) => handleRemove(item, e)}>
                                <Delete />
                            </IconButton>
                            <DragIndicator />
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );

}

interface FilterDisplayProps {
    filter: any
}

export function FilterDisplay({ filter }: FilterDisplayProps) {

    const { translate, currentLang } = useLocales();

    const { customFields } = useTenant();

    const contextCustomFields = useMemo(() => {

        //const context = "ReturnRequestApprover";

        const fields = customFields.filter((field) => field.enabled && field.searchable); //&& field.entityType.toLowerCase() === context.toLowerCase())

        return fields.sort((a, b) => a.name[0].text!.toLowerCase().localeCompare(b.name[0].text!.toLowerCase()));

    }, [customFields]);

    const getFilterLabel = (field: string, name?: string) => {
        let label = field + ":";

        if (isArray(filter[field])) {
            filter[field].forEach((filt: string, index: number) => {
                label += ' "' + filt + '"' + (index !== filter[field].length - 1 ? "," : "");
            });

        } else if (isObject(filter[field]) && name) {
            let text = "";
            if (field === "customFields") {
                const customFound = contextCustomFields.find(x => x.id === name);

                text = customFound!.name.find(x => x.culture === currentLang.value)?.text || customFound!.name[0].text || "";
            } else {
                text = name;
            }
            label = text + ': "' + (filter as Record<string, any>)[field][name] + '"';
        } else label += ' "' + filter[field] + '"';

        return label;
    };

    const [singleFilters, arrayFilters, objectFilters] = useMemo(() => {

        const [single, array, object]: [string[], string[], string[]] = [[], [], []];

        if (filter) {
            Object.keys(filter).forEach((field) => {

                if (!filter[field])
                    return;

                if (isArray(filter[field])) array.push(field);
                else if (isObject(filter[field])) object.push(field);
                else single.push(field);

            });
        }

        return [single, array, object];
    }, [filter]);

    return (
        <Box>
            <Typography variant="h6">
                {`${translate('commons.filters')}`}
            </Typography>
            {singleFilters.filter(x => !x.startsWith("vendorId") && !x.startsWith("customerId")).map((filter) =>
                <Chip
                    sx={{ m: '5px', "& .MuiChip-label": { fontSize: 14 } }}
                    key={"filter.key." + filter}
                    label={getFilterLabel(filter)}
                    icon={<FilterAlt />}
                />
            )}

            {arrayFilters.map((filt, index) =>
                <Box key={"filter.array.box." + filt}>
                    {singleFilters.length > 0 && <Divider sx={{ my: 1 }} />}
                    <Box sx={{ display: "flex" }}>
                        <Chip
                            sx={{ m: '5px', "& .MuiChip-label": { fontSize: 14 } }}
                            key={"filter.array.key." + filt}
                            label={filt + ":"} //`${translate(`report.columns.${column}`)}`
                            icon={<FilterAlt />}
                        />
                        <Box>
                            {filter[filt].map((field: string) =>
                                <Chip
                                    sx={{ m: '5px', "& .MuiChip-label": { fontSize: 14 }, bgcolor: (theme) => alpha(theme.palette.primary.light, 0.6) }}
                                    key={"filter.array.key." + filt + "." + field}
                                    label={field}
                                />
                            )}
                        </Box>
                    </Box>
                    {(index < arrayFilters.length - 1 || objectFilters.length > 0) && <Divider sx={{ my: 1 }} />}
                </Box>
            )}

            {objectFilters.map((filt, index) =>
                <Box key={"filter.object.box." + filt}>
                    <Box sx={{ display: "flex" }}>
                        <Chip
                            sx={{ m: '5px', "& .MuiChip-label": { fontSize: 14 } }}
                            key={"filter.object.key." + filt}
                            label={filt + ":"} //`${translate(`report.columns.${column}`)}`
                            icon={<FilterAlt />}
                        />
                        <Box>
                            {Object.keys(filter[filt]).map((field: string) =>
                                <Chip
                                    sx={{ m: '5px', "& .MuiChip-label": { fontSize: 14 }, bgcolor: (theme) => alpha(theme.palette.primary.light, 0.6) }}
                                    key={"filter.object.key." + filt + "." + field}
                                    label={getFilterLabel(filt, field)}
                                />
                            )}
                        </Box>
                    </Box>
                    {index < objectFilters.length - 1 && <Divider sx={{ my: 1 }} />}
                </Box>
            )}
        </Box>
    );
}