import { Box, IconButton } from '@mui/material';
import Iconify from '../components/iconify';
import MenuPopover from '../components/menu-popover';

type Props = {
  actions: React.ReactNode;
  open: HTMLElement | null;
  onClose?: VoidFunction;
  onOpen?: (event: React.MouseEvent<HTMLElement>) => void;
  showMenu?: boolean
};

export default function TableMoreMenu({ actions, open, onClose, onOpen, showMenu = true }: Props) {
  return (
    <Box>
      <IconButton onClick={onOpen}>
        <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
      </IconButton>

      {
        showMenu &&
        <MenuPopover
          open={open}
          anchorEl={open}
          onClose={onClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          arrow="right-top"
          sx={{
            mt: -1,
            minWidth: 160,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
              '& svg': { mr: 2, width: 20, height: 20 },
            },
          }}
        >
          {actions}
        </MenuPopover>
      }

    </Box>
  );
}
