import { useEffect, useRef, useState } from 'react';
import { Box, Card, Modal, alpha } from '@mui/material';
import useResponsive from 'src/hooks/useResponsive';
import { Media } from 'src/@types/request';
import { ReactComponent as MissingImage } from "./ic_missingImage.svg";
import { BigMedia } from './BigMedia';
import { getMediaName, getMediaUrl } from './utils';

export const MediaWithFocusStyle = {
    "& .thumbnails": {
        minWidth: "175px",
        maxWidth: "175px"
    },
    "& .thumbnails img": {
        borderRadius: "15px",
        width: "100%",
        height: "100%",
        cursor: "pointer",
        border: "1px ridge",
        borderColor: (theme: any) => theme.palette.text.main,
        objectFit: "cover"
    },
    "& .thumbnails img.active": {
        border: "5px solid",
        borderColor: (theme: any) => theme.palette.primary.main
    },
    "& .fullImage": {
        maxHeight: "100%",
        aspectRatio: "auto"
    },
    "& .missingImageBox": {
        borderRadius: "15px",
        width: "100%",
        height: "100%",
        objectFit: "cover",
        border: "1px ridge",
        borderColor: (theme: any) => theme.palette.text.main,
        display: "flex",
        justifyContent: "center"
    },
    "& .active": {
        borderRadius: "15px",
        width: "100%",
        height: "100%",
        objectFit: "cover",
        border: "5px solid",
        borderColor: (theme: any) => theme.palette.primary.main,
        display: "flex",
        justifyContent: "center"
    }
};

interface MediaWithFocusProps {
    isOpen: boolean,
    toggle: () => void,
    images: Media[],
    indexToStart: number
}

export function MediaWithFocus({ isOpen, toggle, images, indexToStart }: MediaWithFocusProps) {

    const isDesktop = useResponsive('up', 'sm');

    const [currentIndex, setCurrentIndex] = useState(indexToStart);

    useEffect(() => {
        setCurrentIndex(indexToStart);
    }, [indexToStart, images]);

    const showImage = (index: number) => {
        setCurrentIndex(index);
    };

    const thumbnailsBoxRef = useRef<any>(null);

    function getMap() {
        if (!thumbnailsBoxRef.current) {

            thumbnailsBoxRef.current = new Map();
        }

        return thumbnailsBoxRef.current;
    }

    useEffect(() => {
        const map = getMap();

        const node = map.get(currentIndex);

        node?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center'
        });
    }, [currentIndex]);

    return (
        <Modal
            open={isOpen}
            onClose={toggle}
        >
            <Card
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: isDesktop ? "60vw" : "90vw",
                    height: isDesktop ? "80vh" : "90vh",
                    p: isDesktop ? 4 : 1.5
                }}
            >
                <Box sx={{ height: "100%", width: "100%" }}>

                    <BigMedia
                        toggle={toggle}
                        images={images}
                        currentIndex={currentIndex}
                        setCurrentIndex={setCurrentIndex}
                    />

                    <Box
                        sx={{
                            display: 'flex',
                            //justifyContent: "center",
                            height: "11vh",
                            width: "100%",
                            overflowX: "auto",
                            overflowY: "hidden",
                            pb: 0.75, gap: 1.5,
                            ...MediaWithFocusStyle
                        }}
                    >
                        {images.map((image, index) =>
                            <Box
                                key={index}
                                className={"thumbnails"}
                                ref={(node) => {
                                    const map = getMap();

                                    if (node) {
                                        map.set(index, node);
                                    }
                                }}
                            >
                                {image.fileInfo.length > 0 ?
                                    <img
                                        src={getMediaUrl(image, 150, 150)}
                                        alt={getMediaName(getMediaUrl(image, 150, 150))}
                                        className={index === currentIndex ? 'active' : ''}
                                        onClick={() => showImage(index)}
                                    />
                                    :
                                    <Box
                                        className={'missingImageBox'}
                                        sx={{ backgroundColor: (theme) => theme.palette.mode === "light" ? '#F9FAFB' : alpha('#F9FAFB', 0.8) }}
                                    >
                                        <Box className={index === currentIndex ? 'active' : ''}>
                                            <MissingImage />
                                        </Box>
                                    </Box>
                                }
                            </Box>
                        )}
                    </Box>
                </Box>
            </Card>
        </Modal>
    );
}