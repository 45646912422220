import { ReactNode, createContext, useCallback, useContext, useMemo } from "react";
import { DEFAULT_USER_ORGANIZATION, UserOrganization } from "src/@types/user";
import useLocalStorage from "src/hooks/useLocalStorage";

type UserOrganizationContextState = {
    remember: boolean,
    organizationId: string,
    organization: UserOrganization
    changeValues: (remember: boolean, organization: UserOrganization) => void
}

type UserOrganizationProviderProps = {
    children: ReactNode;
};

const initialState: UserOrganizationContextState = {
    remember: false,
    organizationId: "",
    organization: DEFAULT_USER_ORGANIZATION,
    changeValues: (_remember: boolean, _organization: UserOrganization) => { }
};

const UserOrganizationContext = createContext(initialState);

export const useUserOrganizationContext = () => {
    const context = useContext(UserOrganizationContext);

    if (!context) throw new Error('useSettingsContext must be use inside SettingsProvider');

    return context;
};

function UserOrganizationProvider({ children }: UserOrganizationProviderProps) {
    const [userOrganization, setUserOrganization] = useLocalStorage("userOrganization", {
        remember: false,
        organization: {},
        organizationId: ""
    },);

    const changeValues = useCallback((remember: boolean, organization: UserOrganization) => {
        setUserOrganization({ remember: remember, organization: organization, organizationId: organization.organizationId });
    }, [setUserOrganization]);

    const memoizedValue = useMemo(() => ({
        ...userOrganization,
        changeValues,
    }), [changeValues, userOrganization]);

    return <UserOrganizationContext.Provider value={memoizedValue}>{children}</UserOrganizationContext.Provider>;
}

export { UserOrganizationProvider, UserOrganizationContext };