import { useFormContext } from 'react-hook-form';
import { Box, Stack, Badge, Button, Drawer, Divider, IconButton, Typography, ButtonGroup, MenuItem, Slider, TextField, Switch } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import useLocales from 'src/appHooks/useLocales';
import AutocompleteField from 'src/appComponents/AutocompleteField';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CurrencyTypes } from 'src/@types/vehicle';
import useCurrency from 'src/appHooks/useCurrency';
import { convert } from "src/utils/currency";
import { useSettingsContext } from 'src/components/settings';
import { DEFAULT_ORGANIZATION_FILTERS, OrganizationFilters } from "src/@types/organizations";
import { MIN_BALANCE_ORGANIZATION_FILTER, MIN_LIMIT_ORGANIZATION_FILTER, STEP_ORGANIZATION_FILTER, typesOfOrganization } from 'src/sections/@dashboard/organizations/Constants';
import { Currency, NAV } from 'src/config';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { RHFSelect } from 'src/components/hook-form';
import { FormatString } from 'src/appUtils/string';
import CustomFieldSidebarRenderer from 'src/utils/CustomFieldSidebarRenderer';

type Props = {
    filters: OrganizationFilters,
    isDefault: (filter: OrganizationFilters, controller?: OrganizationFilters) => boolean,
    isOpen: boolean,
    onOpen: VoidFunction,
    onFilter: VoidFunction,
    onResetAll: VoidFunction,
    onClose: VoidFunction,
    reset: boolean,
    resetFormElement: string,
    setResetFormElement: (value: string) => void,
    MAX_BALANCE_CONVERTED: number,
    MAX_LIMIT_CONVERTED: number,
    setShowSummary: (value: boolean) => void,
    isCustomer: boolean
};

export default function OrganizationFilterSidebar({
    filters,
    isDefault,
    isOpen,
    onOpen,
    onFilter,
    onResetAll,
    onClose,
    reset,
    resetFormElement,
    setResetFormElement,
    MAX_BALANCE_CONVERTED,
    MAX_LIMIT_CONVERTED,
    setShowSummary,
    isCustomer
}: Props) {

    const { translate } = useLocales();

    const { rates } = useCurrency();

    const { setValue, getValues } = useFormContext();

    const { currency: currentCurrency } = useSettingsContext();

    const [values, setValues] = useState(filters);

    const [valuesAfterFilter, setValuesAfterFilter] = useState(filters);

    const [inEditTags, setInEditTags] = useState<boolean>(false);

    const STEP_CONVERTED = useMemo(() => Math.round(convert(STEP_ORGANIZATION_FILTER, CurrencyTypes.EUR, currentCurrency.label, rates)), [currentCurrency, rates]);

    useEffect(() => {
        if (reset) {
            setValues({
                ...DEFAULT_ORGANIZATION_FILTERS,
                maxCreditBalance: MAX_BALANCE_CONVERTED,
                maxCreditLimit: MAX_LIMIT_CONVERTED
            });
        }
    }, [reset, MAX_BALANCE_CONVERTED, MAX_LIMIT_CONVERTED]);

    const handleChangeValues = useCallback((values: any, newVal: any, type: string) => {
        const valuesCopy = JSON.parse(JSON.stringify(values));

        if (type === "creditBalance") {
            valuesCopy.minCreditBalance = newVal[0];
            valuesCopy.maxCreditBalance = newVal[1];
        } else if (type === "creditLimit") {
            valuesCopy.minCreditLimit = newVal[0];
            valuesCopy.maxCreditLimit = newVal[1];
        } else {
            valuesCopy[type] = newVal;
        }
        setValues(valuesCopy);
    }, []);

    const handleChangeTagsValues = (newVal: any, type: string) => {
        const valuesCopy = JSON.parse(JSON.stringify(values));

        valuesCopy[type] = newVal;

        setValues(valuesCopy);
    };

    useEffect(() => {
        if (resetFormElement) {
            handleChangeValues(values, DEFAULT_ORGANIZATION_FILTERS[resetFormElement], resetFormElement);
            setResetFormElement("");
        }
    }, [resetFormElement, handleChangeValues, values, setResetFormElement]);

    useEffect(() => {
        if (JSON.stringify(filters) !== JSON.stringify(values)) {
            setValues(filters);
            setValuesAfterFilter(filters);
        }
    }, [filters]);

    const handleCloseFilter = () => {
        if (reset) {
            setValues({
                ...DEFAULT_ORGANIZATION_FILTERS,
                maxCreditBalance: MAX_BALANCE_CONVERTED,
                maxCreditLimit: MAX_LIMIT_CONVERTED
            });
        } else if (JSON.stringify(values) !== JSON.stringify(valuesAfterFilter)) {
            setValues(valuesAfterFilter);
        }
        onClose();
    };

    const handleOnResetAll = () => {
        setValues({
            ...DEFAULT_ORGANIZATION_FILTERS,
            maxCreditBalance: MAX_BALANCE_CONVERTED,
            maxCreditLimit: MAX_LIMIT_CONVERTED
        });
        onResetAll();
    };

    const setAllValues = () => {
        for (const [key, value] of Object.entries(values)) {
            if (key === "all") {
                setValue(key, DEFAULT_ORGANIZATION_FILTERS[key]);
            } else {
                const formValue = getValues(key);

                if (JSON.stringify(value) !== JSON.stringify(formValue)) {
                    if (key !== "tags") {
                        setValue(`${key}`, value);
                    }
                }
            }
        }
        setValuesAfterFilter(values);
    };

    const handleOnFilter = () => {
        setAllValues();
        onFilter();
        setShowSummary(true);
    };

    const dotController = useMemo(
        () => {
            if (valuesAfterFilter) {
                return ({ ...valuesAfterFilter });
            }

            return ({ ...DEFAULT_ORGANIZATION_FILTERS, maxCreditBalance: MAX_BALANCE_CONVERTED, maxCreditLimit: MAX_LIMIT_CONVERTED });
        }, [valuesAfterFilter, MAX_BALANCE_CONVERTED, MAX_LIMIT_CONVERTED]
    );

    const marksLabelPrice = [...Array(21)].map((_, index) => {
        const value = index * STEP_CONVERTED;

        var firstValue = "";
        if (value / 1000 > 1) {
            firstValue = `${Math.round(value / 1000)}kk`;
        } else {
            firstValue = index === 0 ? `${currentCurrency.symbol} ${value}k` : `${value}k`;
        }

        return {
            value,
            label: index % 4 ? '' : firstValue,
        };
    });

    return (
        <>
            <Button variant="outlined" size='small' onClick={onOpen}>
                <FilterListIcon />
            </Button>
            <Drawer
                anchor="right"
                open={isOpen}
                onClose={handleCloseFilter}
                PaperProps={{
                    sx: { width: NAV.W_DASHBOARD },
                    style: { boxShadow: 'none' }
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ px: 1, py: 2 }}
                >
                    <Typography variant="subtitle1" sx={{ ml: 1 }}>
                        {`${translate("commons.filters")}`}
                    </Typography>

                    <IconButton onClick={handleCloseFilter}>
                        <Iconify icon={'eva:close-fill'} width={20} height={20} />
                    </IconButton>
                </Stack>

                <Divider />

                <Scrollbar>
                    <Stack spacing={3} sx={{ p: 2.5 }}>

                        <Stack spacing={1}>
                            <TextField
                                name='name'
                                label={`${translate(`organization.list.table.organization`)}`}
                                variant="outlined"
                                value={values.name}
                                onChange={(e) => handleChangeValues(values, e.target.value, "name")}
                            />
                        </Stack>

                        <Stack spacing={1}>
                            <TextField
                                name='vat'
                                label={`${translate(`organization.detail.vat`)}`}
                                variant="outlined"
                                value={values.vat}
                                onChange={(e) => handleChangeValues(values, e.target.value, "vat")}
                            />
                        </Stack>

                        <Stack spacing={1}>
                            <TextField
                                name='email'
                                label={`${translate(`commons.email`)}`}
                                variant="outlined"
                                value={values.email}
                                onChange={(e) => handleChangeValues(values, e.target.value, "email")}
                            />
                        </Stack>

                        <Stack spacing={1}>
                            <TextField
                                name='externalId'
                                label={`${translate(`commons.code`)}`}
                                variant="outlined"
                                value={values.externalId}
                                onChange={(e) => handleChangeValues(values, e.target.value, "externalId")}
                            />
                        </Stack>

                        <Stack spacing={1}>
                            <RHFSelect
                                name="type"
                                label={`${translate("commons.type")}`}
                                value={values.type}
                                onChange={(e) => handleChangeValues(values, e.target.value, "type")}
                            >
                                {typesOfOrganization.map((element) => (
                                    <MenuItem key={element} value={element}>
                                        {element}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                        </Stack>

                        {isCustomer &&
                            <Stack spacing={1}>
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                                        {`${translate('organization.list.filter.surveillance')}`}
                                    </Typography>
                                    <Switch
                                        onChange={(_, checked) => handleChangeValues(values, checked, "isMonitored")}
                                        checked={values.isMonitored}
                                    />
                                </Box>
                            </Stack>
                        }

                        {rates.length > 1 &&
                            <>
                                <Stack spacing={1} sx={{ pb: 2 }}>
                                    <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                                        {`${translate('organization.form.payment.creditLimit')}`}
                                    </Typography>

                                    <Stack direction="row" spacing={2}>
                                        <InputRange type="min" currentCurrency={currentCurrency} label={`${translate('commons.min')}`} value={values.minCreditLimit!} />
                                        <InputRange type="max" currentCurrency={currentCurrency} label={`${translate('commons.max')}`} value={values.maxCreditLimit!} />
                                    </Stack>

                                    <Slider
                                        name="creditLimit"
                                        step={STEP_CONVERTED}
                                        min={MIN_LIMIT_ORGANIZATION_FILTER}
                                        max={MAX_LIMIT_CONVERTED}
                                        marks={marksLabelPrice}
                                        sx={{ alignSelf: 'center', width: `calc(100% - 20px)` }}
                                        valueLabelDisplay="auto"

                                        getAriaValueText={(value: any) => `${value}k ${currentCurrency.symbol}`}
                                        valueLabelFormat={(value: any) => `${value}k ${currentCurrency.symbol}`}

                                        value={[values.minCreditLimit!, values.maxCreditLimit!]}

                                        onChange={(event: Event, newValue: number | number[], activeThumb: number) => {
                                            if (!Array.isArray(newValue)) {
                                                return;
                                            }

                                            const { minCreditLimit, maxCreditLimit } = values;

                                            var min = minCreditLimit!;
                                            var max = maxCreditLimit!;

                                            if (activeThumb === 0) {
                                                min = Math.min(newValue[0], maxCreditLimit! - MIN_LIMIT_ORGANIZATION_FILTER);
                                            } else {
                                                max = Math.max(newValue[1], minCreditLimit! + MIN_LIMIT_ORGANIZATION_FILTER);
                                            }
                                            if (max - min >= STEP_ORGANIZATION_FILTER) {
                                                handleChangeValues(values, [min, max], "creditLimit");
                                            }
                                        }}
                                    />
                                </Stack>

                                <Stack spacing={1} sx={{ pb: 2 }}>
                                    <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                                        {`${translate('organization.form.payment.balance')}`}
                                    </Typography>

                                    <Stack direction="row" spacing={2}>
                                        <InputRange type="min" currentCurrency={currentCurrency} label={`${translate('commons.min')}`} value={values.minCreditBalance!} />
                                        <InputRange type="max" currentCurrency={currentCurrency} label={`${translate('commons.max')}`} value={values.maxCreditBalance!} />
                                    </Stack>

                                    <Slider
                                        name="creditBalance"
                                        step={STEP_CONVERTED}
                                        min={MIN_BALANCE_ORGANIZATION_FILTER}
                                        max={MAX_BALANCE_CONVERTED}
                                        marks={marksLabelPrice}
                                        sx={{ alignSelf: 'center', width: `calc(100% - 20px)` }}
                                        valueLabelDisplay="auto"

                                        getAriaValueText={(value: any) => `${value}k ${currentCurrency.symbol}`}
                                        valueLabelFormat={(value: any) => `${value}k ${currentCurrency.symbol}`}

                                        value={[values.minCreditBalance!, values.maxCreditBalance!]}
                                        onChange={(event: Event, newValue: number | number[], activeThumb: number) => {
                                            if (!Array.isArray(newValue)) {
                                                return;
                                            }

                                            const { minCreditBalance, maxCreditBalance } = values;

                                            var min = minCreditBalance!;
                                            var max = maxCreditBalance!;
                                            if (activeThumb === 0) {
                                                min = Math.min(newValue[0], maxCreditBalance! - MIN_BALANCE_ORGANIZATION_FILTER);
                                            } else {
                                                max = Math.max(newValue[1], minCreditBalance! + MIN_BALANCE_ORGANIZATION_FILTER);
                                            }
                                            if (max - min >= STEP_ORGANIZATION_FILTER) {
                                                handleChangeValues(values, [min, max], "creditBalance");
                                            }
                                        }}
                                    />
                                </Stack>
                            </>
                        }

                        <Typography variant="subtitle1">{`${translate("commons.tags")}`}</Typography>

                        <Stack spacing={1}>
                            <AutocompleteField
                                name={'tags'}
                                context={'organizationFilterTag'}
                                label={`${translate("commons.tags")}`}
                                freeSolo
                                multiple
                                tagsFromForm={values.tags!.length ? values.tags : getValues("tags")}
                                handleChangeValues={handleChangeTagsValues}
                                inEditTags={inEditTags}
                                setInEditTags={setInEditTags}
                            />
                        </Stack>

                        <CustomFieldSidebarRenderer
                            context={["Organization"]}
                            filterValues={values}
                            setFilterValues={setValues}
                        />

                    </Stack>
                </Scrollbar>

                <Box sx={{ p: 1, pb: 2, position: 'relative' }}>

                    <Badge
                        color="error"
                        variant="dot"
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        invisible={isDefault(values, dotController as OrganizationFilters)}
                        sx={{ width: 1, right: 25, top: 20, position: 'absolute' }}
                    />

                    <ButtonGroup variant="outlined" aria-label="outlined button group" fullWidth>

                        <Button
                            fullWidth
                            size="large"
                            type="submit"
                            color="inherit"
                            variant="outlined"
                            onClick={handleOnResetAll}
                            startIcon={<Iconify icon="ic:round-clear-all" />}
                            sx={{ px: 1 }}
                        >
                            {`${translate("commons.clear")}`}
                        </Button>
                        <Button
                            fullWidth
                            size="large"
                            type="submit"
                            color="inherit"
                            variant="outlined"
                            onClick={handleOnFilter}
                            startIcon={<Iconify icon="eva:search-fill" />}
                            disabled={inEditTags}
                            sx={{ px: 1 }}
                        >
                            {`${translate("commons.apply")}`}
                        </Button>
                    </ButtonGroup>
                </Box>
            </Drawer>
        </>
    );
}

// ----------------------------------------------------------------------

type InputRangeProps = {
    type: 'min' | 'max';
    label: string;
    value: number;
    currentCurrency?: Currency;
    metricSystem?: boolean;
};

(String.prototype as any).supplant = function (o: any) {
    return this.replace(/{([^{}]*)}/g, function (a: any, b: any) {
        var r = o[b];

        return typeof r === 'string' || typeof r === 'number' ? r : a;
    });
};

function InputRange({ type, label, value, currentCurrency, metricSystem }: InputRangeProps) {

    return (
        <Stack direction="row" spacing={0.5} alignItems="center" sx={{ width: 1 }}>
            <Typography
                variant="caption"
                sx={{
                    flexShrink: 0,
                    color: 'text.disabled',
                    textTransform: 'capitalize',
                    fontWeight: 'fontWeightBold',
                }}
            >
                {FormatString(label, type)}:
            </Typography>
            <Typography
                variant="caption"
                sx={{
                    pl: 1,
                    flexShrink: 0,
                    fontWeight: 'fontWeightBold',
                }}
            >
                {value}{currentCurrency ? `` : ` `}{currentCurrency ? "k " + currentCurrency.symbol : (metricSystem ? `km` : `mi`)}
            </Typography>
        </Stack>
    );
}
