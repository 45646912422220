import IconButtonAnimate from "src/components/animate/IconButtonAnimate";
import { dispatch } from "src/redux/store";
import { setOpen } from 'src/redux/slices/settings';
import SettingsIcon from '@mui/icons-material/Settings';

export default function SettingsPopover() {

    const handleOpen = () => {
        dispatch(setOpen(true));
    };

    return <>
        <IconButtonAnimate
            color={'default'}
            onClick={() => handleOpen()}
            sx={{ width: 40, height: 40 }}
        >
            <SettingsIcon />
        </IconButtonAnimate>
    </>;
}