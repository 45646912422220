import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, Chip, Container, Divider, Modal, Typography, alpha } from "@mui/material";
import Page from "src/appComponents/Page";
import { useSettingsContext } from "src/components/settings";
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useLocales } from "src/locales";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useSelector } from "react-redux";
import { dispatch, RootState } from "src/redux/store";
import { useCallback, useEffect, useState } from "react";
import { inboxOperations } from "src/redux/inbox";
import LoadingScreen from "src/appComponents/loading-screen/LoadingScreen";
import { LocalizedString } from "src/@types/commons";
import DateZone from "src/appComponents/DateZone";
import { NotificationTemplate } from "src/@types/inbox";
import useResponsive from "src/hooks/useResponsive";
import { lokalizableRoleStr } from "../role/utils";
import Iconify from "src/components/iconify";
import Label from "src/components/label";
import { useNavigate } from "react-router";
import { ExpandMore } from "@mui/icons-material";
import { getLocalizedString } from "src/utils/CustomFieldManagment";

export default function NotificationTemplateList() {

    const { themeStretch } = useSettingsContext();

    const { translate, currentLang } = useLocales();

    const isDesktop = useResponsive("up", "md");

    const navigate = useNavigate();

    const [selectedTemplate, setSelectedTemplate] = useState<NotificationTemplate | null>(null);

    const { templateList, isTemplateLoading } = useSelector((state: RootState) => state.inbox);

    const LocalizedString = useCallback((localizedString: LocalizedString[]): string => {
        return getLocalizedString(localizedString, currentLang);
    }, [currentLang]);

    useEffect(() => {
        if (!templateList)
            dispatch(inboxOperations.searchNotificationTemplate());
    }, [templateList]);

    return (
        <Page title={`${translate('menu.management.notification.title')}`}>
            {isTemplateLoading ?
                <LoadingScreen /> :

                <Container maxWidth={themeStretch ? false : 'lg'}>
                    <NotificationTemplateDetail selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} />
                    <HeaderBreadcrumbs
                        heading={`${translate('menu.management.notification.title')}`}
                        links={[
                            { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                            { name: `${translate('commons.list')}` },
                        ]}
                        action={
                            <Button
                                variant="contained"
                                startIcon={<Iconify icon={'eva:plus-fill'} />}
                                sx={{ borderRadius: "100px" }}
                                onClick={() => navigate(PATH_DASHBOARD.tenant.notificationTemplate.new)}
                            >
                                {`${translate('commons.new')}`}
                            </Button>
                        }
                    />
                    <Card sx={{ p: 3, display: 'grid', gridTemplateColumns: isDesktop ? '1fr 1fr 1fr' : "1fr", gap: 3 }}>
                        {
                            templateList && templateList.items.map((template, index) => (
                                <Card
                                    key={index}
                                    sx={{
                                        p: 2,
                                        transition: "0.5s",
                                        cursor: 'pointer',
                                        ':hover': {
                                            bgcolor: (theme) => theme.palette.action.selected
                                        }
                                    }}
                                    onClick={() => setSelectedTemplate(template)}
                                >
                                    <Box sx={{
                                        display: "flex",
                                        flexDirection: isDesktop ? 'row' : "column",
                                        alignItems: 'center'
                                    }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: isDesktop ? 'row' : "column",
                                                width: '100%',
                                                gap: 1,
                                                color: (theme) => theme.palette.action.disabled
                                            }}
                                        >

                                            <Typography variant="caption">{`${translate('commons.createdOn')}: `}</Typography>
                                            <DateZone variant="caption" date={new Date(template.createdOn)} noSeconds shortMonth />

                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, ml: 'auto' }}>
                                            <Label color={template.status === "Active" ? "success" : "warning"} >
                                                {`${translate(`statuses.${template.status.toLowerCase()}`)}`}
                                            </Label>
                                            <Label color="primary">
                                                {`${translate(`notifications.settings.${template.notificationType[0].toLowerCase() + template.notificationType.slice(1)}`)}`}
                                            </Label>
                                        </Box>
                                    </Box>
                                    <Typography variant="body1" sx={{ wordBreak: 'break-word' }}><b>{LocalizedString(template.displayName)}</b></Typography>

                                    <Typography variant="body2" paragraph>{LocalizedString(template.description)}</Typography>

                                </Card>
                            ))}

                    </Card>
                </Container>}
        </Page>
    );
}

interface notificationTemplateDetailProps {
    selectedTemplate: NotificationTemplate | null,
    setSelectedTemplate: (val: NotificationTemplate | null) => void
}

function NotificationTemplateDetail({ selectedTemplate, setSelectedTemplate }: notificationTemplateDetailProps) {

    const isDesktop = useResponsive('up', 'md');

    const { translate, currentLang } = useLocales();

    const navigate = useNavigate();

    const LocalizedString = useCallback((localizedString: LocalizedString[]): string => {
        return getLocalizedString(localizedString, currentLang);
    }, [currentLang]);

    return (
        <Modal
            open={!!selectedTemplate}
            onClose={() => {
                setSelectedTemplate(null);
            }}
            sx={{
                overflowY: 'auto',
            }}
        >
            <Card sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                minWidth: '40vw',
                maxHeight: '80vh',
                overflowY: 'auto',
                transform: 'translate(-50%, -50%)',
                width: isDesktop ? '25vw' : '70vw',
                p: isDesktop ? 4 : 2,
            }}>
                {!!selectedTemplate &&
                    <Box sx={{ maxHeight: '100%', overflowY: 'auto' }}>
                        <Box sx={{
                            display: "flex",
                            flexDirection: isDesktop ? 'row' : "column",
                            alignItems: 'center'
                        }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 1,
                                    mb: 1,
                                    color: (theme) => theme.palette.action.disabled
                                }}
                            >

                                <Typography variant="caption">{`${translate('commons.createdOn')}: `}</Typography>
                                <DateZone variant="caption" date={new Date(selectedTemplate.createdOn)} noSeconds shortMonth />
                            </Box>
                            <Label color={selectedTemplate.status === "Active" ? "success" : "warning"} sx={{ ml: 'auto', px: 2.5, mb: 1 }}>
                                {`${translate(`statuses.${selectedTemplate.status.toLowerCase()}`)}`}
                            </Label>
                            <Label color="primary" sx={{ px: 2.5, mb: 1, ml: 1 }}>
                                {`${translate(`notifications.settings.${selectedTemplate.notificationType[0].toLowerCase() + selectedTemplate.notificationType.slice(1)}`)}`}
                            </Label>
                        </Box>
                        <Box>
                            <Typography><b>{`${translate('commons.name')}: `}</b></Typography>
                            <Typography>{LocalizedString(selectedTemplate.displayName)}</Typography>
                        </Box>
                        <Divider sx={{ my: 1 }} />
                        <Box>
                            <Typography><b>{`${translate('commons.description')}: `}</b></Typography>
                            <Typography paragraph>{LocalizedString(selectedTemplate.description)}</Typography>
                        </Box>

                        <Divider sx={{ my: 2 }} />

                        <Box>
                            <Accordion
                                sx={{
                                    p: 0,
                                    backgroundColor: (theme) => theme.palette.mode === "light" ? '#F9FAFB' : alpha('#F9FAFB', 0.35),
                                    borderRadius: '8px',
                                    overflowY: 'auto'
                                }}

                            >
                                <AccordionSummary
                                    sx={{
                                        backgroundColor: (theme) => theme.palette.mode === "light" ? theme.palette.primary.lighter : alpha('#F9FAFB', 0.35),
                                        borderRadius: '8px',
                                        maxHeight: '40vh',
                                        overflowY: 'auto',
                                        color: (theme) => theme.palette.getContrastText(theme.palette.primary.lighter)

                                    }}
                                    expandIcon={<ExpandMore />}
                                >
                                    <Box sx={{
                                        display: 'flex',
                                        maxHeight: '40vh',
                                        overflowY: 'auto',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '100%'
                                    }}>
                                        <Typography variant="h5">
                                            {`${translate('notificationTemplate.form.permission_plural')}`}
                                        </Typography>

                                        {selectedTemplate.permissionDependency.atLeastOne &&
                                            <Label color="success" sx={{ mr: 3 }}>
                                                {`${translate('notificationTemplate.form.atLeastOne')}`}
                                            </Label>
                                        }
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails >
                                    {selectedTemplate.permissionDependency.permissions.map((v, index) => (
                                        <Chip key={index} sx={{ m: 0.5 }} label={
                                            <Typography
                                                key={index}
                                                variant="body2">
                                                {`${translate(`role.permission.${lokalizableRoleStr(v)}.title`)}`}
                                            </Typography>
                                        } />
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </Box>

                }
                <Divider sx={{ my: 2 }} />
                <Box sx={{ width: '100%', display: 'flex', justifyContent: "end" }}>
                    <Button
                        variant="contained"
                        disabled={selectedTemplate?.status !== "Active"}
                        onClick={() => navigate(PATH_DASHBOARD.tenant.notificationTemplate.edit(selectedTemplate?.notificationId || ""))}
                    >
                        {`${translate('commons.edit')}`}
                    </Button>
                </Box>
            </Card>
        </Modal>);
}

