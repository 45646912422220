import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorResponse, Tag } from 'src/@types/commons';
import { Vehicle } from 'src/@types/vehicle';
import vehicleOperations from './vehicle-operations';
import { LogsData } from "src/@types/logs";
import { ContractualData } from 'src/@types/contractualData';
import { LogisticsData } from 'src/@types/logistics';
import { CommercialData } from 'src/@types/commercialData';

interface VehicleReducer {
    isVehiclePowersearchLoading: boolean;
    isVehicleLogsLoading: boolean;
    vehicleLogs: LogsData;
    initialized: boolean;
    vehicle: Vehicle | any;
    isLoading: boolean;
    isSuccess: boolean | string;
    error: ErrorResponse;
    vehicleList: any[];
    vehiclesPowersearch: any[];
    pageIndex: number;
    pageSize: number;
    brands: Tag[];
    models: Tag[];
    totalCount: number;
    isEditMode: boolean;
    contractualData: ContractualData | any;
    isContractualDataLoading: boolean;
    isCDFound: boolean;
    logistics: LogisticsData | null;
    isLogisticsLoading: boolean;
    isLogisticsFound: boolean;
    commercialData: CommercialData | null;
    isCommercialDataLoading: boolean;
    isCommercialDataFound: boolean;
}

const initialState: VehicleReducer = {
    isVehiclePowersearchLoading: false,
    isVehicleLogsLoading: false,
    vehicleLogs: {
        pageIndex: 0,
        pageSize: 0,
        totalCount: 0,
        hasNextPage: false,
        hasPreviousPage: false,
        totalPages: 0,
        items: []
    },
    vehicle: null,
    initialized: false,
    vehicleList: [],
    vehiclesPowersearch: [],
    pageIndex: 0,
    pageSize: 0,
    totalCount: 0,
    isLoading: false,
    isSuccess: false,
    error: null,
    brands: [],
    models: [],
    isEditMode: false,
    contractualData: null,
    isContractualDataLoading: false,
    isCDFound: false,
    logistics: null,
    isLogisticsLoading: false,
    isLogisticsFound: false,
    commercialData: null,
    isCommercialDataLoading: false,
    isCommercialDataFound: false
};

const VehicleSlice = createSlice({
    name: "vehicle",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(vehicleOperations.createVehicle.fulfilled, (state, action) => {
                state.isSuccess = "Vehicle Created";
                state.vehicle = action.payload;
            })
            .addCase(vehicleOperations.updateVehicle.fulfilled, (state, action) => {
                state.isSuccess = "Vehicle Updated";
            })
            .addCase(vehicleOperations.createVehicle.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as ErrorResponse;
            })
            .addCase(vehicleOperations.updateVehicle.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as ErrorResponse;
            })
            .addCase(vehicleOperations.getFilteredVehicles.fulfilled, (state, action) => {
                state.vehicleList = action.payload.items;
                state.pageIndex = action.payload.pageIndex;
                state.pageSize = action.payload.pageSize;
                state.totalCount = action.payload.totalCount;
                state.initialized = true;
                state.isLoading = false;
            })
            .addCase(vehicleOperations.getFilteredVehicles.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(vehicleOperations.getFilteredVehicles.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(vehicleOperations.getBrands.fulfilled, (state, action) => {
                state.brands = action.payload;
            })
            .addCase(vehicleOperations.getModels.fulfilled, (state, action) => {
                state.models = action.payload;
            })
            .addCase(vehicleOperations.getBrands.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(vehicleOperations.getVehicleById.fulfilled, (state, action) => {
                state.vehicle = action.payload;
                state.isLoading = false;
            })
            .addCase(vehicleOperations.getVehicleById.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(vehicleOperations.getVehicleById.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message as string;
                state.vehicle = null;
            })
            .addCase(vehicleOperations.getVehicleDisplayById.fulfilled, (state, action) => {
                state.vehicle = action.payload;
                state.isLoading = false;
            })
            .addCase(vehicleOperations.getVehicleDisplayById.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(vehicleOperations.getVehicleDisplayById.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message as string;
                state.vehicle = null;
            })
            .addCase(vehicleOperations.getModels.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(vehicleOperations.removeMedia.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(vehicleOperations.removeMedia.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(vehicleOperations.removeMedia.fulfilled, (state, action) => {
                state.isSuccess = 'Removed';
                state.isLoading = false;
            })
            .addCase(vehicleOperations.uploadMadia.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(vehicleOperations.uploadMadia.fulfilled, (state, action) => {
                action.payload === "Attach" ? state.isSuccess = 'Attachments uploaded' : state.isSuccess = 'Images uploaded';
                state.isLoading = false;
            })
            .addCase(vehicleOperations.getVehiclesPowersearch.pending, (state, action) => {
                state.isVehiclePowersearchLoading = true;
            })
            .addCase(vehicleOperations.getVehiclesPowersearch.fulfilled, (state, action) => {
                state.isVehiclePowersearchLoading = false;
                state.vehiclesPowersearch = action.payload.items;
            })
            .addCase(vehicleOperations.getVehiclesPowersearch.rejected, (state, action) => {
                state.isVehiclePowersearchLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(vehicleOperations.getVehicleLogs.pending, (state, action) => {
                state.isVehicleLogsLoading = true;
            })
            .addCase(vehicleOperations.getVehicleLogs.fulfilled, (state, action) => {
                state.isVehicleLogsLoading = false;
                state.vehicleLogs = action.payload;
            })
            .addCase(vehicleOperations.getVehicleContractualDataLogs.pending, (state, action) => {
                state.isVehicleLogsLoading = true;
            })
            .addCase(vehicleOperations.getVehicleContractualDataLogs.fulfilled, (state, action) => {
                state.isVehicleLogsLoading = false;
                state.vehicleLogs = action.payload;
            })
            .addCase(vehicleOperations.getVehicleContractualDataLogs.rejected, (state, action) => {
                state.isVehicleLogsLoading = false;
                state.vehicleLogs = initialState.vehicleLogs;
                state.error = action.error.message as string;
            })
            .addCase(vehicleOperations.getVehicleContractualData.pending, (state, action) => {
                state.isContractualDataLoading = true;
            })
            .addCase(vehicleOperations.getVehicleContractualData.fulfilled, (state, action) => {
                state.contractualData = action.payload;
                state.isContractualDataLoading = false;
                state.isCDFound = true;
            })
            .addCase(vehicleOperations.getVehicleContractualData.rejected, (state, action) => {
                state.contractualData = initialState.contractualData;
                state.isContractualDataLoading = false;
                state.isCDFound = false;
                state.error = action.error.message as string;
            })
            .addCase(vehicleOperations.getVehicleLogistics.pending, (state, action) => {
                state.isLogisticsLoading = true;
            })
            .addCase(vehicleOperations.getVehicleLogistics.fulfilled, (state, action) => {
                state.logistics = action.payload;
                state.isLogisticsLoading = false;
                state.isLogisticsFound = true;
            })
            .addCase(vehicleOperations.getVehicleLogistics.rejected, (state, action) => {
                state.logistics = initialState.logistics;
                state.isLogisticsLoading = false;
                state.isLogisticsFound = false;
                state.error = action.error.message as string;
            })
            .addCase(vehicleOperations.getVehicleLogisticsLogs.pending, (state, action) => {
                state.isVehicleLogsLoading = true;
            })
            .addCase(vehicleOperations.getVehicleLogisticsLogs.fulfilled, (state, action) => {
                state.isVehicleLogsLoading = false;
                state.vehicleLogs = action.payload;
            })
            .addCase(vehicleOperations.getVehicleLogisticsLogs.rejected, (state, action) => {
                state.isVehicleLogsLoading = false;
                state.vehicleLogs = initialState.vehicleLogs;
                state.error = action.error.message as string;
            })
            .addCase(vehicleOperations.getVehicleCommercialData.pending, (state, action) => {
                state.isCommercialDataLoading = true;
            })
            .addCase(vehicleOperations.getVehicleCommercialData.fulfilled, (state, action) => {
                state.commercialData = action.payload;
                state.isCommercialDataLoading = false;
                state.isCommercialDataFound = true;
            })
            .addCase(vehicleOperations.getVehicleCommercialData.rejected, (state, action) => {
                state.commercialData = initialState.contractualData;
                state.isCommercialDataLoading = false;
                state.isCommercialDataFound = false;
                state.error = action.error.message as string;
            })
            .addCase(vehicleOperations.getVehicleCommercialDataLogs.pending, (state, action) => {
                state.isVehicleLogsLoading = true;
            })
            .addCase(vehicleOperations.getVehicleCommercialDataLogs.fulfilled, (state, action) => {
                state.isVehicleLogsLoading = false;
                state.vehicleLogs = action.payload;
            })
            .addCase(vehicleOperations.getVehicleCommercialDataLogs.rejected, (state, action) => {
                state.isVehicleLogsLoading = false;
                state.vehicleLogs = initialState.vehicleLogs;
                state.error = action.error.message as string;
            });
    },
    reducers: {
        hasError(state, action: PayloadAction<ErrorResponse>) {
            state.isLoading = false;
            state.isSuccess = false;
            state.error = action.payload as ErrorResponse;
        },
        startPowerSearchLoading(state) {
            state.isVehiclePowersearchLoading = true;
        },
        setVehicle(state, action) {
            state.vehicle = action.payload;
        },
        clearError(state) {
            state.error = null;
        },
        clearSuccess(state) {
            state.isSuccess = false;
        },
        finishLoading(state) {
            state.isLoading = false;
        },
        startLoading(state) {
            state.isLoading = true;
            state.isSuccess = false;
        },
        sortVehicles(state, action) {
            state.vehicleList = action.payload;
        },
        setEditMode(state, action) {
            state.isEditMode = action.payload;
        },
        resetVehicle(state) {
            state.vehicle = null;
        },
        enableVehicleById(state, action) {
            const ind = state.vehicleList.findIndex(item => item.id === action.payload);

            state.vehicleList[ind].enabled = true;
        },
        disableVehicleById(state, action) {
            const ind = state.vehicleList.findIndex(item => item.id === action.payload);

            state.vehicleList[ind].enabled = false;
        },
        //RESET VEHICLE LIST
        resetPageIndexSize(state) {
            state.pageIndex = 0;
            state.pageSize = 10;
        }
    },
});

export const {
    resetVehicle,
    enableVehicleById,
    disableVehicleById,
    clearSuccess, clearError,
    hasError,
    finishLoading,
    startLoading,
    sortVehicles,
    setEditMode,
    setVehicle,
    startPowerSearchLoading,
    resetPageIndexSize
} = VehicleSlice.actions;

export default VehicleSlice.reducer;
