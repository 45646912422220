import { m } from 'framer-motion';
// @mui
import { Button, Container, styled, Typography } from '@mui/material';
// components
import { MotionContainer, varBounce } from '../../components/animate';
// assets
import { ForbiddenIllustration } from '../../assets/illustrations';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Error403() {

    const navigate = useNavigate();

    const { translate } = useLocales();

    return (
        <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
            <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
                <m.div variants={varBounce().in}>
                    <Typography variant="h3" paragraph>
                        {`${translate(`403.title`)}`}
                    </Typography>
                </m.div>

                <m.div variants={varBounce().in}>
                    <Typography sx={{ color: 'text.secondary' }}>
                        {`${translate(`403.message`)}`}
                    </Typography>
                </m.div>

                <m.div variants={varBounce().in}>
                    <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
                </m.div>
                <Button variant="contained" onClick={() => navigate(PATH_DASHBOARD.root)}>
                    {`${translate(`commons.home`)}`}
                </Button>
            </ContentStyle>
        </Container>
    );
}
