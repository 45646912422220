import { Box, FormControlLabel, Radio, Switch, TablePagination, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { GenericOrganization } from "src/@types/organizations";
import useTable from "src/appHooks/useTable";
import { noData } from "src/components/empty-content/EmptyContent";
import { useLocales } from "src/locales";
import { resetOrganizationList } from "src/redux/organizations/organizations-slices";
import { dispatch } from "src/redux/store";
import { DataGridStyle } from "src/utils/DataGridStyle";

interface SearchSupplierProps {
    owners: GenericOrganization[],
    onChange: (id: string) => void
}

export default function SearchSupplier({ owners, onChange }: SearchSupplierProps) {

    const { translate } = useLocales();

    const [selectionModel, setSelectionModel] = useState("");

    const { dense, onChangeDense } = useTable();

    const handleChange = (value: GridRenderCellParams<GenericOrganization> | GridRowParams<GenericOrganization>) => {
        setSelectionModel(value.id.toString());
        onChange(value.id.toString());
    };

    useEffect(() => {
        return () => { dispatch(resetOrganizationList()); };
    }, []);

    const columns: GridColDef<GenericOrganization>[] = [
        {
            field: '',
            headerName: ``,
            flex: 0.25,
            sortable: false,
            align: 'left',
            renderCell: (v) =>
                <Radio
                    checked={selectionModel === v.id.toString()}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleChange(v);
                    }}
                />
        },
        {
            field: 'externalId',

            headerName: `${translate('commons.code')}`,
            flex: 0.5,
            valueGetter: (params: string) => {
                if (!params) {
                    return '—';
                }

                return params;
            },
        },
        {
            field: 'name',
            headerName: `${translate('commons.name')}`,
            flex: 0.75,
            valueGetter: (params: string) => {
                if (!params) {
                    return '—';
                }

                return params;
            },
        },
        {
            field: 'regions',
            headerName: `${translate('organization.list.table.region')}`,
            flex: 0.75,
            valueGetter: (value: string[]) => {
                if (!value) {
                    return '—';
                }

                return value.join();
            },
        },
        {
            field: 'type',
            headerName: `${translate('organization.list.table.type')}`,
            flex: 0.75,
            valueGetter: (value: string) => {
                if (!value) {
                    return '—';
                }

                return value;
            },
        },
    ];

    return (
        <Box>
            <Typography variant="h6" sx={{ m: 3 }}>
                {`${translate('request.messages.researchSupplier')}`}
            </Typography>
            <DataGrid
                columns={columns}
                rows={owners}
                disableColumnMenu
                disableColumnResize
                disableRowSelectionOnClick
                density={dense ? 'compact' : 'standard'}
                sortingMode={"server"}
                onRowClick={(p) => {
                    handleChange(p);
                }}
                sx={{
                    ...DataGridStyle,
                    //cursor: 'pointer',
                    height: owners.length <= 5 ? 380 : 650,
                    maxHeight: 650,
                    '& .MuiDataGrid-columnHeaderTitle': {
                        whiteSpace: 'break-spaces',
                        lineHeight: 1,
                    }
                }}
                pageSizeOptions={[5, 10, 15, 30]}
                slots={{
                    noRowsOverlay: noData,
                    footer: () => (
                        <Box sx={{ position: 'relative' }} >
                            <TablePagination
                                component="div"
                                count={owners.length}
                                rowsPerPage={10}
                                page={0}
                                onPageChange={() => { }}
                                onRowsPerPageChange={() => { }}
                                labelRowsPerPage={`${translate('commons.rowsPerPage')}`} />

                            <FormControlLabel
                                control={<Switch checked={dense} onChange={onChangeDense} />}
                                label={`${translate('commons.dense')}`}
                                sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }} />
                        </Box>
                    )
                }}
            />
        </Box>
    );
}