import { Box, Typography } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import { LogisticsData } from 'src/@types/logistics';
import useLocales from 'src/appHooks/useLocales';
import * as Yup from 'yup';
import { TransportDataInfo } from '../formCards/TransportDataInfo';

type Props = {
    outerRef: any;
    formMethods: UseFormReturn<LogisticsData>;
};

export default function LogisticStep3({ formMethods, outerRef }: Props) {

    const { getValues, setError, clearErrors } = formMethods;

    const { translate } = useLocales();

    const validateFields = async (yupSchema: any) => {
        const formValues = getValues();

        try {
            await yupSchema.validate(formValues, { abortEarly: false });
            clearErrors();

            return true;
        } catch (err: any) {
            clearErrors();
            (err as Yup.ValidationError).inner.forEach((er) => {
                setError(er.path as any, { message: er.message });
            });

            return false;
        }
    };

    if (!outerRef?.current) {
        outerRef.current = {
            validateFields,
        };
    }

    return (
        <>
            <Typography variant="h5">
                {`${translate('vehicle.logistic.form.transport')}`}
            </Typography>

            <Typography variant="body2" sx={{ mt: 1 }}>
                {`${translate('vehicle.logistic.form.transportDescription')}`}.
            </Typography>

            <Box>
                <TransportDataInfo formMethods={formMethods} />
            </Box>
        </>
    );
}
