import { useFormContext } from 'react-hook-form';
import { Box, Stack, Badge, Button, Drawer, Divider, IconButton, Typography, MenuItem, TextField, ButtonGroup, Switch } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import useLocales from 'src/appHooks/useLocales';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { NAV } from 'src/config';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { RHFSelect } from 'src/components/hook-form';
import { DEFAULT_REQUEST_FILTERS, RequestFilters, RequestTypeArr } from 'src/@types/request';
import CustomFieldSidebarRenderer from 'src/utils/CustomFieldSidebarRenderer';
import { CustomerAutocomplete } from 'src/sections/@dashboard/orders/filters/OrdersFilterSidebar';

type RequestsFilterSidebarProps = {
    filters: RequestFilters,
    isDefault: (valuesToCheck: any, other?: any) => boolean,
    isOpen: boolean,
    onOpen: VoidFunction,
    onFilter: VoidFunction,
    onResetAll: VoidFunction,
    onClose: VoidFunction,
    reset: boolean,
    resetFormElement: string,
    setResetFormElement: (value: string) => void,
    isVendor: boolean
};

export default function RequestsFilterSidebar({
    isDefault,
    isOpen,
    onResetAll,
    onFilter,
    onOpen,
    onClose,
    reset,
    resetFormElement,
    filters,
    setResetFormElement,
    isVendor
}: RequestsFilterSidebarProps) {

    const { translate } = useLocales();

    const { setValue, getValues } = useFormContext();

    const [values, setValues] = useState(filters);

    const [valuesAfterFilter, setValuesAfterFilter] = useState(filters);

    const [resetSearchCustomer, setResetSearchCustomer] = useState<boolean>(false);

    const showCustomer = isVendor;

    useEffect(() => {
        if (reset) {
            setValues(DEFAULT_REQUEST_FILTERS);
            setResetSearchCustomer(true);
        }
    }, [reset]);

    const handleChangeValues = useCallback((values: any, newVal: any, type: string) => {
        const valuesCopy = JSON.parse(JSON.stringify(values));

        valuesCopy[type] = newVal;

        setValues(valuesCopy);
    }, []);

    const handleChangeCustomer = (newVal: any, type: string) => {
        const valuesCopy = JSON.parse(JSON.stringify(values));

        valuesCopy.customerId = newVal.id;

        valuesCopy.customer = newVal;

        setValues(valuesCopy);
    };

    useEffect(() => {
        if (resetFormElement) {
            handleChangeValues(values, DEFAULT_REQUEST_FILTERS[resetFormElement], resetFormElement);
            if (resetFormElement === "customerId") setResetSearchCustomer(true);
            setResetFormElement("");
        }
    }, [resetFormElement, handleChangeValues, values, setResetFormElement]);

    useEffect(() => {
        if (JSON.stringify(filters) !== JSON.stringify(values)) {
            setValues(filters);
            setValuesAfterFilter(filters);
        }
    }, [filters]);

    const handleCloseFilter = () => {
        if (reset) {
            setValues(DEFAULT_REQUEST_FILTERS);
        } else if (JSON.stringify(values) !== JSON.stringify(valuesAfterFilter)) {
            setValues(valuesAfterFilter);
        }
        onClose();
    };

    const handleOnResetAll = () => {
        setValues(DEFAULT_REQUEST_FILTERS);
        onResetAll();
    };

    const setAllValues = () => {
        for (const [key, value] of Object.entries(values)) {

            if (key === "all") {
                setValue(key, DEFAULT_REQUEST_FILTERS[key]);
            } else {
                const formValue = getValues(key);

                if (JSON.stringify(value) !== JSON.stringify(formValue)) {
                    setValue(key, value);
                }
            }
        }
        setValuesAfterFilter(values);
    };

    const handleOnFilter = () => {
        setAllValues();
        onFilter();
    };

    const dotController = useMemo(() => {
        if (valuesAfterFilter) {
            return ({ ...valuesAfterFilter });
        }

        return ({ ...DEFAULT_REQUEST_FILTERS });
    }, [valuesAfterFilter]);

    return (
        <>
            <Button variant="outlined" size='small' onClick={onOpen}>
                <FilterListIcon />
            </Button>
            <Drawer
                anchor="right"
                open={isOpen}
                onClose={handleCloseFilter}
                PaperProps={{
                    sx: { width: NAV.W_DASHBOARD },
                    style: { boxShadow: 'none' }
                }}

            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ px: 1, py: 2 }}
                >
                    <Typography variant="subtitle1" sx={{ ml: 1 }}>
                        {`${translate("commons.filters")}`}
                    </Typography>

                    <IconButton onClick={handleCloseFilter}>
                        <Iconify icon={'eva:close-fill'} width={20} height={20} />
                    </IconButton>
                </Stack>

                <Divider />

                <Scrollbar>
                    <Stack spacing={3} sx={{ p: 2.5 }}>

                        <Stack spacing={1}>
                            <TextField
                                name='externalId'
                                label={`${translate(`returns.filter.partRef`)}`}
                                variant="outlined"
                                value={values.externalId}
                                onChange={(e) => {
                                    handleChangeValues(values, e.target.value, "externalId");
                                }}
                            />
                        </Stack>

                        <Stack spacing={1}>
                            <RHFSelect
                                name="requestType"
                                label={`${translate("returns.tableHeaders.reason")}`}
                                value={values.requestType}
                                onChange={(e) => {
                                    handleChangeValues(values, e.target.value, "requestType");
                                }}
                            >
                                {RequestTypeArr.map((element) => (
                                    <MenuItem key={element || "All"} value={element || "All"}>
                                        {`${translate(element ? `request.${element}` : `commons.all`)}`}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                        </Stack>

                        <Stack spacing={1}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                                    {`${translate('returns.filter.claimed')}`}
                                </Typography>
                                <Switch
                                    onChange={(_, checked) => handleChangeValues(values, checked, "hasClaim")}
                                    checked={values.hasClaim}
                                />
                            </Box>
                        </Stack>

                        {showCustomer &&
                            <>
                                <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                                    {`${translate('commons.customer')}`}
                                </Typography>
                                <Stack spacing={1}>
                                    <CustomerAutocomplete
                                        onChange={handleChangeCustomer}
                                        setResetSearchCustomer={setResetSearchCustomer}
                                        resetSearchCustomer={resetSearchCustomer}
                                        defaultCustomer={values.customer}
                                    />
                                </Stack>
                            </>
                        }

                        <CustomFieldSidebarRenderer
                            context={["ReturnRequestApprover"]}
                            filterValues={values}
                            setFilterValues={setValues}
                        />

                    </Stack>

                </Scrollbar>

                <Box sx={{ p: 1, pb: 2, position: 'relative' }}>

                    <Badge
                        color="error"
                        variant="dot"
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        invisible={isDefault(values, dotController)}
                        sx={{ width: 1, right: 35, top: 20, position: 'absolute' }}
                    />

                    <ButtonGroup variant="outlined" aria-label="outlined button group" fullWidth>

                        <Button
                            fullWidth
                            size="large"
                            type="submit"
                            color="inherit"
                            variant="outlined"
                            onClick={handleOnResetAll}
                            startIcon={<Iconify icon="ic:round-clear-all" />}
                            sx={{ px: 1 }}
                        >
                            {`${translate("commons.clear")}`}
                        </Button>
                        <Button
                            fullWidth
                            size="large"
                            type="submit"
                            color="inherit"
                            variant="outlined"
                            onClick={handleOnFilter}
                            startIcon={<Iconify icon="eva:search-fill" />}
                            sx={{ px: 1 }}
                        >
                            {`${translate("commons.apply")}`}
                        </Button>
                    </ButtonGroup>
                </Box>

            </Drawer >
        </>
    );
}