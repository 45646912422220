import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ErrorResponse, PagedResponseType } from "src/@types/commons";
import { ContainerTemplate, ContainerTemplateSearchResult, DEFAULT_STATISTIC, ItemsInContainerSearchResult, ContainerStatistics, Container, EnableDisableSliceProps, EditSliceProps, NewSliceProps, CloseShipSliceProps } from "src/@types/container";
import containerOperations from "./container-operations";

interface ContainerReducer {
    //---------CONTAINER
    error: ErrorResponse,
    isLogsLoading: boolean,
    suggest: string[],
    filtersInUrl: string,
    isLoading: boolean,
    isTemplateLoading: boolean,
    containerTemplate: ContainerTemplate | null,
    container: Container | null,
    totalCount: number,
    containersList: ContainerTemplateSearchResult[],
    statistics: ContainerStatistics,
    containerLogs: PagedResponseType<any>,
    pageIndex: number,
    pageSize: number,
    //---------ISTANCES
    istanceTotalCount: number,
    istanceList: ContainerTemplateSearchResult[],
    istancePageIndex: number,
    istancePageSize: number,
    //---------ITEMS
    areItemsLoading: boolean,
    totalItemsCount: number,
    itemsInContainerList: ItemsInContainerSearchResult[]
}

const initialState: ContainerReducer = {
    //---------CONTAINER
    error: null,
    isLogsLoading: false,
    isTemplateLoading: false,
    suggest: [],
    filtersInUrl: "",
    isLoading: false,
    containerTemplate: null,
    container: null,
    totalCount: 0,
    containersList: [],
    statistics: DEFAULT_STATISTIC,
    containerLogs: {
        pageIndex: 0,
        pageSize: 0,
        items: [],
        totalCount: 0
    },
    pageIndex: 0,
    pageSize: 10,
    //---------ISTANCES
    istanceTotalCount: 0,
    istanceList: [],
    istancePageIndex: 0,
    istancePageSize: 10,
    //---------ITEMS
    areItemsLoading: false,
    totalItemsCount: 0,
    itemsInContainerList: []
};

const ContainerSlice = createSlice({
    name: 'container',
    initialState,
    extraReducers: (builder) => {
        builder
            //--------------------------------------
            .addCase(containerOperations.createContainerTemplate.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isTemplateLoading = false;
            })
            .addCase(containerOperations.createContainerTemplate.fulfilled, (state, action) => {
                state.containerTemplate = action.payload;
                state.isTemplateLoading = false;
            })
            //--------------------------------------
            .addCase(containerOperations.updateContainerTemplate.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isTemplateLoading = false;
            })
            .addCase(containerOperations.updateContainerTemplate.fulfilled, (state, action) => {
                state.containerTemplate = action.payload;
                state.isTemplateLoading = false;
            })
            //--------------------------------------
            .addCase(containerOperations.getContainerTemplate.rejected, (state, action) => {
                state.containerTemplate = initialState.containerTemplate;
                state.error = action.error.message as string;
                state.isTemplateLoading = false;
            })
            .addCase(containerOperations.getContainerTemplate.fulfilled, (state, action) => {
                state.containerTemplate = action.payload;
                state.isTemplateLoading = false;
            })
            //--------------------------------------
            .addCase(containerOperations.getContainer.fulfilled, (state, action) => {
                state.container = action.payload;
                state.isLoading = false;
            })
            .addCase(containerOperations.getContainer.rejected, (state, action) => {
                state.container = initialState.container;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            //--------------------------------------
            .addCase(containerOperations.searchContainerTemplate.rejected, (state, action) => {
                state.containersList = initialState.containersList;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(containerOperations.searchContainerTemplate.fulfilled, (state, action) => {
                state.containersList = action.payload.items;
                state.totalCount = action.payload.totalCount;
                state.pageIndex = action.payload.pageIndex;
                state.pageSize = action.payload.pageSize;
                state.isLoading = false;
            })
            //--------------------------------------
            .addCase(containerOperations.searchContainer.rejected, (state, action) => {
                state.containersList = initialState.containersList;
                state.istanceList = initialState.istanceList;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(containerOperations.searchContainer.fulfilled, (state, action) => {
                if (action.payload.isIstance) {
                    state.istanceList = action.payload.items;
                    state.istanceTotalCount = action.payload.totalCount;
                    state.istancePageIndex = action.payload.pageIndex;
                    state.istancePageSize = action.payload.pageSize;
                } else {
                    state.containersList = action.payload.items;
                    state.totalCount = action.payload.totalCount;
                    state.pageIndex = action.payload.pageIndex;
                    state.pageSize = action.payload.pageSize;
                }
                state.isLoading = false;
            })
            //--------------------------------------
            .addCase(containerOperations.enableDisableContainerTemplate.rejected, (state, action) => {
                state.error = action.error.message as string;
            })
            //--------------------------------------
            .addCase(containerOperations.openContainer.rejected, (state, action) => {
                state.error = action.error.message as string;
            })
            //--------------------------------------
            .addCase(containerOperations.closeShipContainer.rejected, (state, action) => {
                state.error = action.error.message as string;
            })
            //--------------------------------------
            .addCase(containerOperations.searchStatisticsContainerTemplate.fulfilled, (state, action) => {
                state.statistics = action.payload;
                state.isLoading = false;
            })
            .addCase(containerOperations.searchStatisticsContainerTemplate.rejected, (state, action) => {
                state.statistics = initialState.statistics;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            //--------------------------------------
            .addCase(containerOperations.searchStatisticsContainer.fulfilled, (state, action) => {
                state.statistics = action.payload;
                state.isLoading = false;
            })
            .addCase(containerOperations.searchStatisticsContainer.rejected, (state, action) => {
                state.statistics = initialState.statistics;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            //--------------------------------------
            .addCase(containerOperations.getContainerLogs.rejected, (state, action) => {
                state.isLogsLoading = false;
                state.error = action.error.message as string;
                state.containerLogs = initialState.containerLogs;
            })
            .addCase(containerOperations.getContainerLogs.fulfilled, (state, action) => {
                state.isLogsLoading = false;
                state.containerLogs = action.payload;
            })
            //--------------------------------------
            .addCase(containerOperations.addRequest.rejected, (state, action) => {
                state.error = action.error.message as string;
            })
            //--------------------------------------
            .addCase(containerOperations.getContainerByTemplate.rejected, (state, action) => {
                state.container = initialState.container;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(containerOperations.getContainerByTemplate.fulfilled, (state, action) => {
                state.container = action.payload;
                state.isLoading = false;
            })
            //--------------------------------------
            .addCase(containerOperations.addExtraItems.rejected, (state, action) => {
                state.error = action.error.message as string;
            })
            .addCase(containerOperations.addExtraItems.fulfilled, (state, action) => {
                state.container?.extraItems.push({ ...action.payload, createdOn: new Date().toISOString() });
            })
            .addCase(containerOperations.closeContainer.rejected, (state, action) => {
                state.error = action.error.message as string;
            })
            .addCase(containerOperations.shipContainer.rejected, (state, action) => {
                state.error = action.error.message as string;
            })
            .addCase(containerOperations.closeContainer.fulfilled, (state, action) => {
                state.container!.statusTypes = "Closed";
            })
            .addCase(containerOperations.shipContainer.fulfilled, (state, action) => {
                state.container!.statusTypes = "Shipped";
            })
            ;
    },
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
        startTemplateLoading(state) {
            state.isTemplateLoading = true;
        },
        startLogsLoading(state) {
            state.isLogsLoading = true;
        },
        resetContainer(state) {
            state.containerTemplate = null;
        },
        resetitemsInContainer(state) {
            state.itemsInContainerList = [];
        },
        setFiltersInUrl(state, action: PayloadAction<string>) {
            state.filtersInUrl = action.payload;
        },
        //RESET CONTAINER LIST
        resetPageIndexSize(state) {
            state.pageIndex = 0;
            state.pageSize = 10;
        },
        //DISABLE/ENABLE IN LIST AFTER SERVICE
        enableDisableTemplate(state, action: PayloadAction<EnableDisableSliceProps>) {

            const { id, tab, newItem, action: payloadAction } = action.payload;

            const index = state.containersList.findIndex((template) => template.id === id);

            state.containersList[index].enabled = payloadAction === "Enable";

            if (tab !== "") {
                state.containersList.splice(index, 1);

                state.totalCount -= 1;

                if (newItem) state.containersList.push(newItem);
                else if (state.containersList.length === 0 && state.pageIndex > 0) {
                    state.pageIndex -= 1;
                }
            }

            if (payloadAction === "Enable") {
                state.statistics.disabled -= 1;

                state.statistics.enabled += 1;
            } else {
                state.statistics.disabled += 1;

                state.statistics.enabled -= 1;
            }
        },
        //NEW IN LIST AFTER SERVICE
        newTemplateInList(state, action: PayloadAction<NewSliceProps>) {

            const { item, tab } = action.payload;

            const newItemInList: ContainerTemplateSearchResult = {
                id: item.id,
                createdOn: item.createdOn,
                name: item.name,
                contentTypes: item.contentTypes,
                enabled: item.enabled,
                status: "",
                tags: [],
                createdBy: item.createdBy,
                customFields: item.customFields,
                active: item.active
            };

            if ((tab === "") || (tab === "Enabled" && newItemInList.enabled) || (tab === "Disabled" && !newItemInList.enabled)) {
                state.containersList = [newItemInList, ...state.containersList];

                if (state.containersList.length > state.pageSize) state.containersList.pop();
            }

            state.statistics = {
                ...state.statistics,
                all: state.statistics.all + 1,
                enabled: newItemInList.enabled ? state.statistics.enabled + 1 : state.statistics.enabled,
                disabled: !newItemInList.enabled ? state.statistics.disabled + 1 : state.statistics.disabled
            };

            state.totalCount += 1;
        },
        //EDIT IN LIST AFTER SERVICE
        editTemplateInList(state, action: PayloadAction<EditSliceProps>) {

            const { item, tab, newItem } = action.payload;

            const newItemInList: ContainerTemplateSearchResult = {
                id: item!.id,
                createdOn: item!.createdOn,
                name: item!.name,
                contentTypes: item!.contentTypes,
                enabled: item!.enabled,
                status: "",
                tags: [],
                createdBy: item!.createdBy,
                customFields: item!.customFields
            };

            const editIndex = state.containersList.findIndex((template) => template.id === item!.id);

            if (editIndex >= 0) state.containersList[editIndex] = newItemInList;

            if ((tab === "Enabled" && !newItemInList.enabled) || (tab === "Disabled" && newItemInList.enabled)) {
                state.containersList.splice(editIndex, 1);

                state.totalCount -= 1;

                if (newItem) state.containersList.push(newItem);
                else if (state.containersList.length === 0 && state.pageIndex > 0) {
                    state.pageIndex -= 1;
                }
            }

            if (state.containerTemplate?.enabled !== newItemInList?.enabled) {
                if (!newItemInList.enabled) {
                    state.statistics.disabled += 1;

                    state.statistics.enabled -= 1;
                } else if (newItemInList.enabled) {
                    state.statistics.disabled -= 1;

                    state.statistics.enabled += 1;
                }
            }

        },
        //OPEN IN LIST AFTER SERVICE
        openTemplate(state, action: PayloadAction<{ id: string }>) {

            const { id } = action.payload;

            const index = state.containersList.findIndex((template) => template.id === id);

            state.containersList[index].active = true;
        },
        ///CLOSE/SHIP IN LIST AFTER SERVICE
        closeShip(state, action: PayloadAction<CloseShipSliceProps>) {

            const { id, tab, newItem, action: payloadAction, now, shippedOn } = action.payload;

            const index = state.containersList.findIndex((template) => template.id === id);

            state.containersList[index].enabled = payloadAction === "Close";

            if (tab !== "") {
                state.containersList.splice(index, 1);

                state.totalCount -= 1;

                if (newItem) state.containersList.push(newItem);
                else if (state.containersList.length === 0 && state.pageIndex > 0) {
                    state.pageIndex -= 1;
                }
            } else {
                if (payloadAction === "Close") {
                    state.containersList[index].closedOn = now;
                    state.containersList[index].status = "Closed";
                }
                else if (payloadAction === "Ship") {
                    state.containersList[index].shippedOn = shippedOn;
                    state.containersList[index].status = "Shipped";
                }
            }

            if (payloadAction === "Close") {
                state.statistics.open -= 1;

                state.statistics.closed += 1;
            } else {
                state.statistics.shipped += 1;

                state.statistics.closed -= 1;
            }
        }
    }

});

export const {
    startLoading,
    startLogsLoading,
    resetContainer,
    setFiltersInUrl,
    resetPageIndexSize,
    resetitemsInContainer,
    startTemplateLoading,
    enableDisableTemplate,
    newTemplateInList,
    editTemplateInList,
    openTemplate,
    closeShip
} = ContainerSlice.actions;

export default ContainerSlice.reducer;