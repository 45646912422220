import Container from '@mui/material/Container';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import Page from 'src/appComponents/Page';
import useLocales from 'src/appHooks/useLocales';
import { useSettingsContext } from 'src/components/settings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSelector } from 'react-redux';
import { dispatch, RootState } from 'src/redux/store';
import { LogsFilters } from 'src/@types/logs';
import GenericLogsList from 'src/appComponents/logs/GenericLogsList';
import { webshopOperations } from 'src/redux/webshop';

const LABELS: string[] = [];

export default function GroupLogs() {

    const { themeStretch } = useSettingsContext();

    const { translate, currentLang } = useLocales();

    const { isLogsLoading, basketRuleLogs, basketRule } = useSelector((state: RootState) => state.webshop);

    const { id } = useParams();

    useEffect(() => {
        if ((!basketRule && id) || (basketRule && id && basketRule.id !== id)) dispatch(webshopOperations.getBasketRule(id));
    }, [id, currentLang, basketRule]);

    const fetchLogs = useCallback((searchFilters: LogsFilters) => {
        dispatch(webshopOperations.getBasketRuleLogs({ id: id!, filters: searchFilters }));
    }, [id]);

    return (
        <Page title={`${translate('basketRule.logs.title')}`}>

            <Container maxWidth={themeStretch ? false : 'lg'} sx={{ p: 0 }}>

                <HeaderBreadcrumbs
                    heading={`${translate('basketRule.logs.title')}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate('menu.management.basketRule.title')}`, href: PATH_DASHBOARD.groups.list },
                        { name: `${basketRule?.name}`, href: PATH_DASHBOARD.basketRules.edit(id!) },
                        { name: `${translate('commons.logs')}` }
                    ]}
                />

                <GenericLogsList
                    labels={LABELS}
                    logsData={{ ...basketRuleLogs, items: basketRuleLogs.items.map((item, index) => ({ ...item, id: index })) }}
                    searchFunc={fetchLogs}
                    isLoading={isLogsLoading}
                    description={translate(`basketRule.logs.subtitle`)}
                    localesPath={"basketRule"}
                />

            </Container>
        </Page>
    );
}
