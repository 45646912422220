import { createSlice } from "@reduxjs/toolkit";
import ordersOperations from "./orders-operations";
import { CreditNote, DeliveryNote, DocumentSearchResult, Invoice, Order, OrderSearchResult } from "src/@types/orders";
import { ErrorResponse, PagedResponseType } from "src/@types/commons";

interface OrdersReducer {
    error: ErrorResponse,
    //-------------------------------------- ORDER
    isOrderLoading: boolean,
    order: Order | null,
    totalCount: number,
    orderList: OrderSearchResult[],
    orderPowersearch: OrderSearchResult[],
    suggest: string[],
    isOrderPowersearchLoading: boolean,
    isOrderLogsLoading: boolean,
    ordersLogs: PagedResponseType<any>,
    orderPageSize: number,
    orderPageIndex: number,
    //-------------------------------------- DOCUMENT
    documentList: DocumentSearchResult[],
    isDocumentLoading: boolean,
    documentTotalCount: number,
    invoice: Invoice | null,
    deliveryNote: DeliveryNote | null,
    creditNote: CreditNote | null,
    isDocsLogsLoading: boolean,
    documentsLogs: PagedResponseType<any>,
    documentPageSize: number,
    documentPageIndex: number
};

const initialState: OrdersReducer = {
    error: null,
    order: null,
    totalCount: 0,
    orderList: [],
    orderPowersearch: [],
    isOrderPowersearchLoading: false,
    documentList: [],
    isDocumentLoading: false,
    documentTotalCount: 0,
    invoice: null,
    deliveryNote: null,
    creditNote: null,
    suggest: [],
    isOrderLoading: false,
    isOrderLogsLoading: false,
    isDocsLogsLoading: false,
    ordersLogs: {
        pageIndex: 0,
        pageSize: 0,
        items: [],
        totalCount: 0
    },
    documentsLogs: {
        pageIndex: 0,
        pageSize: 0,
        items: [],
        totalCount: 0
    },
    orderPageSize: 10,
    orderPageIndex: 0,
    documentPageSize: 10,
    documentPageIndex: 0
};

const OrdersSlice = createSlice({
    name: 'order',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(ordersOperations.getOrder.rejected, (state, action) => {
                state.order = initialState.order;
                state.error = action.error.message as string;
                state.isOrderLoading = false;
            })
            .addCase(ordersOperations.getOrder.fulfilled, (state, action) => {
                state.order = action.payload;
                state.isOrderLoading = false;
            })
            .addCase(ordersOperations.searchVendorOrders.fulfilled, (state, action) => {
                state.isOrderLoading = false;
                state.orderPageIndex = action.payload.pageIndex;
                state.orderPageSize = action.payload.pageSize;
                state.totalCount = action.payload.totalCount;
                state.orderList = action.payload.items;
            })
            .addCase(ordersOperations.searchVendorOrders.rejected, (state, action) => {
                state.orderList = initialState.orderList;
                state.orderPageIndex = initialState.orderPageIndex;
                state.orderPageSize = initialState.orderPageSize;
                state.totalCount = initialState.totalCount;
                state.error = action.error.message as string;
                state.isOrderLoading = false;
            })
            .addCase(ordersOperations.searchCustomerOrders.fulfilled, (state, action) => {
                state.isOrderLoading = false;
                state.orderPageIndex = action.payload.pageIndex;
                state.orderPageSize = action.payload.pageSize;
                state.totalCount = action.payload.totalCount;
                state.orderList = action.payload.items;
            })
            .addCase(ordersOperations.searchCustomerOrders.rejected, (state, action) => {
                state.orderList = initialState.orderList;
                state.orderPageIndex = initialState.orderPageIndex;
                state.orderPageSize = initialState.orderPageSize;
                state.totalCount = initialState.totalCount;
                state.error = action.error.message as string;
                state.isOrderLoading = false;
            })
            .addCase(ordersOperations.searchVendorDocument.fulfilled, (state, action) => {
                state.documentList = action.payload.items;
                state.documentTotalCount = action.payload.totalCount;
                state.documentPageIndex = action.payload.pageIndex;
                state.documentPageSize = action.payload.pageSize;
                state.isDocumentLoading = false;
            })
            .addCase(ordersOperations.searchVendorDocument.rejected, (state, action) => {
                state.documentList = initialState.documentList;
                state.documentPageIndex = initialState.documentPageIndex;
                state.documentPageSize = initialState.documentPageSize;
                state.documentTotalCount = initialState.totalCount;
                state.error = action.error.message as string;
                state.isDocumentLoading = false;
            })
            .addCase(ordersOperations.searchCustomerDocument.fulfilled, (state, action) => {
                state.documentList = action.payload.items;
                state.documentTotalCount = action.payload.totalCount;
                state.documentPageIndex = action.payload.pageIndex;
                state.documentPageSize = action.payload.pageSize;
                state.isDocumentLoading = false;
            })
            .addCase(ordersOperations.searchCustomerDocument.rejected, (state, action) => {
                state.documentList = initialState.documentList;
                state.documentPageIndex = initialState.documentPageIndex;
                state.documentPageSize = initialState.documentPageSize;
                state.documentTotalCount = initialState.totalCount;
                state.error = action.error.message as string;
                state.isDocumentLoading = false;
            })
            .addCase(ordersOperations.searchOrdersPowerSearch.rejected, (state, action) => {
                state.orderPowersearch = initialState.orderPowersearch;
                state.isOrderPowersearchLoading = initialState.isOrderPowersearchLoading;
                state.error = action.error.message as string;
            })
            .addCase(ordersOperations.searchOrdersPowerSearch.fulfilled, (state, action) => {
                state.orderPowersearch = action.payload.items;
                state.isOrderPowersearchLoading = false;
            })
            .addCase(ordersOperations.getDeliveryNote.rejected, (state, action) => {
                state.deliveryNote = initialState.deliveryNote;
                state.isDocumentLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(ordersOperations.getDeliveryNote.fulfilled, (state, action) => {
                state.deliveryNote = action.payload;
                state.isDocumentLoading = false;
            })
            .addCase(ordersOperations.getInvoice.rejected, (state, action) => {
                state.invoice = initialState.invoice;
                state.isDocumentLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(ordersOperations.getCreditNote.fulfilled, (state, action) => {
                state.creditNote = action.payload;
                state.isDocumentLoading = false;
            })
            .addCase(ordersOperations.getCreditNote.rejected, (state, action) => {
                state.creditNote = initialState.creditNote;
                state.isDocumentLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(ordersOperations.getInvoice.fulfilled, (state, action) => {
                state.invoice = action.payload;
                state.isDocumentLoading = false;
            })
            .addCase(ordersOperations.getOrdersLogs.rejected, (state, action) => {
                state.isOrderLogsLoading = false;
                state.error = action.error.message as string;
                state.ordersLogs = initialState.ordersLogs;
            })
            .addCase(ordersOperations.getOrdersLogs.fulfilled, (state, action) => {
                state.isOrderLogsLoading = false;
                state.ordersLogs = action.payload;
            })
            .addCase(ordersOperations.getDocsLogs.rejected, (state, action) => {
                state.isDocsLogsLoading = false;
                state.error = action.error.message as string;
                state.documentsLogs = initialState.documentsLogs;
            })
            .addCase(ordersOperations.getDocsLogs.fulfilled, (state, action) => {
                state.isDocsLogsLoading = false;
                state.documentsLogs = action.payload;
            })
            ;
    },
    reducers: {
        startDocumentLoading(state) {
            state.isDocumentLoading = true;
        },
        startDocLogsLoading(state) {
            state.isDocsLogsLoading = true;
        },
        startOrderLoading(state) {
            state.isOrderLoading = true;
        },
        startOrderLogsLoading(state) {
            state.isOrderLogsLoading = true;
        },
        startPowerSearchLoading(state) {
            state.isOrderPowersearchLoading = true;
        },
        //RESET ORDER LIST
        orderResetPageIndexSize(state) {
            state.orderPageIndex = 0;
            state.orderPageSize = 10;
        },
        //RESET DOCUMENT LIST
        documentResetPageIndexSize(state) {
            state.documentPageIndex = 0;
            state.documentPageSize = 10;
        }
    }
});

export const {
    startOrderLoading,
    startDocumentLoading,
    startPowerSearchLoading,
    startDocLogsLoading,
    startOrderLogsLoading,
    orderResetPageIndexSize,
    documentResetPageIndexSize
} = OrdersSlice.actions;

export default OrdersSlice.reducer;