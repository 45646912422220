import { useEffect, useState, ChangeEvent, useMemo } from "react";
import { IconButtonAnimate } from "src/components/animate";
import { useLocales } from "src/locales";
import SettingsIcon from '@mui/icons-material/Settings';
import MenuPopover from "src/components/menu-popover/MenuPopover";
import { Box, Button, Checkbox, CircularProgress, Divider, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "src/redux/store";
import { inboxOperations } from "src/redux/inbox";
import { NotificationSettings } from "src/@types/inbox";
import { isEqual } from "lodash";
import { setSuccessMessage } from "src/redux/modal/modal-slices";

export default function NotificationsSettingsPopover() {

    const { translate } = useLocales();

    const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

    const { settings: intialSettings, isSettingsLoading } = useSelector((state: RootState) => state.inbox);

    const [settings, setSettings] = useState<NotificationSettings | null>(intialSettings);

    useEffect(() => {
        if (!intialSettings)
            dispatch(inboxOperations.getNotificationSettings()).unwrap().then(val => setSettings(val));
    }, [intialSettings]);

    const handleChange = (index: number, e: ChangeEvent<HTMLInputElement>,) => {
        setSettings(prev => {
            let copy = structuredClone(prev);

            if (copy)
                copy.items[index] = { ...copy.items[index], [e.target.name]: e.target.checked };

            return copy;
        });
    };

    const head = useMemo(() => [
        translate('commons.email').toString(),
        translate("menu.management.inbox.title").toString(),
        translate('commons.pushNotification').toString(),
    ], [translate]);

    const handleClose = () => {
        setOpenPopover(null);

    };

    const handleSave = async () => {
        if (settings)
            await dispatch(inboxOperations.updateNotificationSettings(settings)).unwrap();

        dispatch(setSuccessMessage({ text: translate('commons.success') }));
    };

    return (
        <>
            <IconButtonAnimate
                onClick={(e) => setOpenPopover(e.currentTarget)}
                sx={{
                    width: 40,
                    height: 40,
                    ...(openPopover && {
                        bgcolor: 'action.selected',
                    })
                }}
            >
                <SettingsIcon />
            </IconButtonAnimate>

            <MenuPopover
                open={openPopover}
                onClose={() => handleClose()}
            >
                <TableContainer sx={{ maxHeight: '50vh', overflowY: 'auto' }}>
                    <Table stickyHeader sx={{ borderRadius: '8px' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell >
                                    {`${translate('notifications.type')}`}
                                </TableCell>
                                {head.map((val, index) =>
                                    <TableCell key={index}>
                                        {val}
                                    </TableCell>
                                )}

                            </TableRow>
                        </TableHead>
                        {isSettingsLoading ? (
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={12}>
                                        <Stack
                                            alignItems="center"
                                            justifyContent="center"
                                            sx={{
                                                height: 1,
                                                textAlign: 'center',
                                                p: (theme) => theme.spacing(8, 2),
                                            }}
                                        >
                                            <CircularProgress />
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            </TableBody>)
                            :
                            <TableBody sx={{ maxHeight: '15vh', overflow: 'auto' }} >

                                {settings?.items.map((val, index) =>
                                    <TableRow key={index}>
                                        <TableCell>
                                            {`${translate(`notifications.settings.${val.notificationType.replace(/^./, val.notificationType[0].toLowerCase())}`)}`}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Checkbox
                                                name="allowEmail"
                                                checked={val.allowEmail}
                                                onChange={(e) => handleChange(index, e)}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Checkbox
                                                name="allowInbox"
                                                checked={val.allowInbox}
                                                onChange={(e) => handleChange(index, e)}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Checkbox
                                                name='allowPush'
                                                checked={val.allowPush}
                                                onChange={(e) => handleChange(index, e)}
                                            />
                                        </TableCell>

                                    </TableRow>)}
                            </TableBody>}
                    </Table>
                </TableContainer>
                <Divider sx={{ my: 2 }} />
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                    <Button variant="contained" sx={{ mr: 2, mb: 2 }} disabled={isEqual(settings, intialSettings)} onClick={() => handleSave()}>
                        {`${translate('commons.justSave')}`}
                    </Button>
                </Box>
            </MenuPopover>
        </>
    );

}