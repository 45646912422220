export type LogisticsData = {
    ingress: IngressData[],
    transport: TransportData[],
    eam: EAMData[],
    etm: ETMData[],
    appraisalDate: string | null,
    damagesWithVat: number | null,
    damagesWithoutVat: number | null,
    vehicleId?: string
}

export type IngressData = {
    location: string | null,
    ingressDate: string | null,
    leaveDate: string | null
}

export type TransportData = {
    fromLocation: string | null,
    toLocation: string | null,
    startDate: string | null,
    arrivalDate: string | null
}

export type EAMData = {
    code: string | null,
    description: string | null,
    price: number | null
}

export type ETMData = {
    code: string | null,
    description: string | null,
    price: number | null
}

/* ---------------------------------------------- */

export const DEFAULT_INGRESS_DATA: IngressData = {
    location: '',
    ingressDate: null,
    leaveDate: null
} as IngressData;

export const DEFAULT_TRANSPORT_DATA: TransportData = {
    fromLocation: '',
    toLocation: '',
    startDate: null,
    arrivalDate: null
} as TransportData;

export const DEFAULT_EAM_DATA: EAMData = {
    code: "",
    description: "",
    price: 0
} as EAMData;

export const DEFAULT_ETM_DATA: ETMData = {
    code: "",
    description: "",
    price: 0
} as ETMData;

export const DEFAULT_LOGISTICS_DATA: LogisticsData = {
    ingress: [DEFAULT_INGRESS_DATA],
    transport: [DEFAULT_TRANSPORT_DATA],
    eam: [DEFAULT_EAM_DATA],
    etm: [DEFAULT_ETM_DATA],
    appraisalDate: "",
    damagesWithVat: 0,
    damagesWithoutVat: 0
} as LogisticsData;

