import { useState } from "react";
import { IconButtonAnimate } from "src/components/animate";
import { BusinessTwoTone, CheckCircle } from "@mui/icons-material";
import MenuPopover from "src/components/menu-popover/MenuPopover";
import Scrollbar from "src/components/scrollbar/Scrollbar";
import { Box, MenuItem, Stack, Typography } from "@mui/material";
import { useUserOrganizationContext } from "src/contexts/UserOrganizationContext";
import { UserOrganization } from "src/@types/user";
import { useAuth } from "src/appHooks/useAuth";
import { useNavigate } from "react-router";
import { PATH_DASHBOARD } from "src/routes/paths";

export default function OrganizationsPopover() {

    const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

    const { organizationId: organization, changeValues, remember } = useUserOrganizationContext();

    const { user } = useAuth();

    const navigate = useNavigate();

    const handleChange = (org: UserOrganization) => {
        if (organization !== org.organizationId)
            changeValues(false, org);
        else
            changeValues(remember, org);

        navigate(PATH_DASHBOARD.root);
        window.location.reload();
    };

    return <>
        <IconButtonAnimate
            color={openPopover ? 'primary' : 'default'}
            onClick={(e) => setOpenPopover(e.currentTarget)}
            sx={{ width: 40, height: 40 }}
        >
            <BusinessTwoTone />
        </IconButtonAnimate>

        <MenuPopover
            disableScrollLock
            open={openPopover}
            onClose={() => setOpenPopover(null)}
            sx={{
                width: 'max-content',
                maxHeight: '40vh',
                overflowY: user.organizations.length > 4 ? 'scroll' : 'auto'
            }}
        >
            <Scrollbar>
                <Stack spacing={0.75} >
                    {user.organizations.map((org, ind) => (
                        <MenuItem
                            key={ind}
                            onClick={() => handleChange(org)}
                            selected={org.organizationId === organization}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                gap: 5
                            }}
                        >

                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="subtitle1">
                                    {org.type}
                                </Typography>
                                <Typography variant="body1">
                                    {org.externalId ? org.externalId : ""} {org.name}
                                </Typography>
                            </Box>
                            <Box >
                                <CheckCircle sx={{ visibility: org.organizationId === organization ? 'visible' : 'hidden' }} />
                            </Box>
                        </MenuItem>
                    ))}
                </Stack>
            </Scrollbar>
        </MenuPopover>
    </>;

}