import Page from 'src/appComponents/Page';
import { useLocales } from 'src/locales';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { NewShop } from 'src/@types/webshop';
import { dispatch, RootState } from 'src/redux/store';
import { webshopOperations } from 'src/redux/webshop';
import { useSelector } from 'react-redux';
import LoadingScreen from 'src/appComponents/loading-screen';
import WebshopWizard from 'src/sections/@dashboard/webshop/newWebshop/WebshopWizard';
import { resetWebshop } from 'src/redux/webshop/webshop-slices';
import { termConditionOperations } from 'src/redux/termCondition';
import { resetTermCondition } from 'src/redux/termCondition/termCondition-slices';

export default function NewEditWebshop() {

    const { translate } = useLocales();

    const { id: isEdit } = useParams();

    const { webshop, isWebshopLoading } = useSelector((state: RootState) => state.webshop);

    useEffect(() => {
        if (isEdit)
            dispatch(webshopOperations.getShop(isEdit)).unwrap()
                .then(
                    (v) => {
                        if (v.termAndCondition.id)
                            dispatch(termConditionOperations.getTermCondition(v.termAndCondition.id));
                        else
                            dispatch(resetTermCondition());
                    }
                );

        return () => void dispatch(resetWebshop());
    }, [isEdit]);

    useEffect(() => {
        dispatch(termConditionOperations.searchTermConditions({ pageIndex: 0, pageSize: 100, entityType: 'Shop' }));

    }, []);

    return (
        <Page title={!isEdit ? translate("menu.management.webshop.admin.new") : translate("menu.management.webshop.admin.edit")}>
            {isWebshopLoading ?
                <LoadingScreen /> :
                <WebshopWizard key={isEdit} webshop={isEdit ? (webshop as NewShop | null ?? undefined) : undefined} />
            }
        </Page>
    );
}