import { Box } from "@mui/material";
import { ContainerSection } from "./WarehouseNewArticle";

interface WarehouseNewArticleProps {
    changeContainer: (val: string | null) => void
}

export default function WarehouseWarranty({ changeContainer }: WarehouseNewArticleProps) {

    //const isDesktop = useResponsive('up', 'sm');

    return (
        <Box sx={{ m: 3 }}>
            <ContainerSection onContainerChange={(container) => changeContainer(container?.id || null)} contentTypes={"Warranty"} />
        </Box >
    );
}