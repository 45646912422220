import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import ErrorModal from "./ErrorModal";
import SuccessModal from "./SuccessModal";

export default function ModalHandler() {

    const { error, success } = useSelector((state: RootState) => state.modal);

    return (
        <>
            {error && <ErrorModal />}

            {success && <SuccessModal />}

        </>
    );
}
