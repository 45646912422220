// @mui
import { styled } from '@mui/material/styles';
// ----------------------------------------------------------------------

export const StyledRootScrollbar = styled('div')(() => ({
  overflowY: "auto",
  margin: 0,
  padding: 0,
  listStyle: "none",
  height: "100%",
}));

export const StyledScrollbar ={
  '::-webkit-scrollbar & .MuiDataGrid-virtualScroller::-webkit-scrollbar' :{
    width: '8px',
    backgroundColor: 'transparent',
  },
  
  '::-webkit-scrollbar-thumb & .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
    borderRadius: '50px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    opacity:' 0.5',
  },
  
  '::-webkit-scrollbar-thumb:hover & .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
    opacity: '0.8',
  },
  
  /* Show the scrollbar track when in use */
  '::-webkit-scrollbar-track & .MuiDataGrid-virtualScroller::-webkit-scrollbar-track' :{
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
  }
  ,
  '::-webkit-scrollbar-track:hover': {
   ' backgroundColor': 'rgba(0, 0, 0, 0.2)',
  }
  ,
  /* Hide the scrollbar track when not in use */
  '::-webkit-scrollbar-track:vertical': {
    width: '8px',
  }
  ,
  '::-webkit-scrollbar-track:horizontal': {
    height: '8px',
  }
  ,
  /* Hide the scrollbar buttons */
 ' ::-webkit-scrollbar-button': {
    display: 'none',
  }
};
