import { ShoppingCart } from "@mui/icons-material";
import { Badge, Box, Card } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Timeout } from "react-number-format/types/types";
import Label from "src/components/label";

export default function ShopCartPopover() {

    const Ref = useRef<Timeout | null>(null);

    // The state for our timer
    const [timer, setTimer] = useState("00:00");

    const getTimeRemaining = (e: string) => {
        const total = Date.parse(e) - Date.parse(new Date().toISOString());

        const seconds = Math.floor((total / 1000) % 60);

        const minutes = Math.floor(
            (total / 1000 / 60) % 60
        );

        return {
            total,
            minutes,
            seconds,
        };
    };

    const startTimer = (e: Date) => {
        let { total, minutes, seconds } =
            getTimeRemaining(e.toISOString());
        if (total >= 0) {

            setTimer(

                (minutes > 9
                    ? minutes
                    : "0" + minutes) +
                ":" +
                (seconds > 9 ? seconds : "0" + seconds)
            );
        }
    };

    const clearTimer = (e: Date) => {

        setTimer("10:00");

        if (Ref.current) clearInterval(Ref.current);

        const id = setInterval(() => {
            startTimer(e);
        }, 1000);

        Ref.current = id;
    };

    const getDeadTime = () => {
        let deadline = new Date();

        deadline.setMinutes(deadline.getMinutes() + 10);

        return deadline;
    };

    useEffect(() => {
        clearTimer(getDeadTime());
    }, []);

    return (
        <Card sx={{ cursor: 'pointer', width: '8rem' }}>
            <Box sx={{ p: 2, display: 'flex', gap: 2 }}>
                <Badge badgeContent={2} color="error">
                    <ShoppingCart />
                </Badge>
                <Box sx={{ width: '100%' }}>
                    <Label color="default" sx={{ width: 'fit-content' }}>
                        {" -" + timer}
                    </Label>
                </Box>
            </Box>
        </Card>
    );
}