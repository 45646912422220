import { Card, Typography, Button, Tabs, Tab, Box, useTheme, Container } from "@mui/material";
import { CustomAvatar } from "src/components/custom-avatar";
import PermissionBasedGuard from "src/guards/PermissionBasedGuard";
import { PATH_DASHBOARD } from "src/routes/paths";
import { bgBlur } from "src/utils/cssStyles";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocales } from "src/locales";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "src/redux/store";
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import { ClusterPermissionTypes, OrganizationPermissionTypes } from "src/@types/permissions";
import { StyledInfo, StyledRoot } from "src/utils/Detail";
import { groupOperations } from "src/redux/group";
import PeopleIcon from '@mui/icons-material/People';
import BlockIcon from '@mui/icons-material/Block';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Page from "src/appComponents/Page";
import LoadingScreen from "src/appComponents/loading-screen";
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from "src/components/settings";
import GroupsIcon from '@mui/icons-material/Groups';
import Label from "src/components/label";
import dayjs from "dayjs";
import GroupMembersList from "src/sections/@dashboard/group/detail/GroupMembersList";
import { useAuth } from "src/appHooks/useAuth";
import { hasPermissions } from "src/utils/user";
import ListAltIcon from '@mui/icons-material/ListAlt';
import GroupGeneralDetail from "src/sections/@dashboard/group/detail/GroupGeneralDetail";
import GroupOrganizationsList from "src/sections/@dashboard/group/detail/GroupOrganizationsList";
import { useUserOrganizationContext } from "src/contexts/UserOrganizationContext";
import GroupWhiteBlacklist from "src/sections/@dashboard/group/detail/GroupWhiteBlacklist";
import { resetGroup } from "src/redux/group/group-slices";

export default function GroupDetail() {

    const { user } = useAuth();

    const { organization } = useUserOrganizationContext();

    const { themeStretch } = useSettingsContext();

    const { isLoading, group } = useSelector((state: RootState) => state.group);

    const { translate, currentLang } = useLocales();

    const theme = useTheme();

    //const isDesktop = useResponsive('up', 'lg');

    const { id: groupId, tab } = useParams();

    const [currentTab, setCurrentTab] = useState(tab ?? 'properties-permissions');

    const navigate = useNavigate();

    const organizationPermissionToCheck = useMemo(() =>
        group && group.groupType === "Organization" ?
            OrganizationPermissionTypes.Organization_View :
            OrganizationPermissionTypes.Customer_View
        , [group]);

    const TABS = useMemo(() => [
        {
            value: 'properties-permissions',
            label: `${translate('groups.detail.propertiesAndPermissions')}`,
            icon: <GroupWorkIcon />,
            component: group && <GroupGeneralDetail />
        },
        ...(group && group.status === "Active" ? [
            ...(hasPermissions([ClusterPermissionTypes.UserView], user.roles) ? [
                {
                    value: 'users',
                    label: `${translate('commons.users')}`,
                    icon: <PeopleIcon />,
                    component: <GroupMembersList />
                }
            ] : []),
            ...(hasPermissions([organizationPermissionToCheck], organization.roles) ? [
                {
                    value: 'organizations',
                    label: `${translate('commons.organizations')}`,
                    icon: <ListAltIcon />,
                    component: <GroupOrganizationsList />
                }
            ] : []),
            ...(hasPermissions([organizationPermissionToCheck, ClusterPermissionTypes.UserView], organization.roles) ? [
                {
                    value: 'whitelist',
                    label: `${translate('groups.form.steps.whitelist')}`,
                    icon: <ReceiptIcon />,
                    component: <GroupWhiteBlacklist />
                },
                {
                    value: 'blacklist',
                    label: `${translate('groups.form.steps.blacklist')}`,
                    icon: <BlockIcon />,
                    component: <GroupWhiteBlacklist isBlacklist />
                }
            ] : [])
        ] : [])
    ], [group, organization.roles, organizationPermissionToCheck, translate, user.roles]);

    const handleEdit = () => {
        if (groupId) navigate(PATH_DASHBOARD.groups.edit(groupId));
    };

    const onTabChange = useCallback((newValue: string) => {
        if (groupId) {
            navigate(PATH_DASHBOARD.groups.detailsTab(groupId, newValue));
            setCurrentTab(newValue);
        }
    }, [groupId, navigate]);

    useEffect(() => {
        if (tab && currentTab !== tab) setCurrentTab(tab);
    }, [tab]);

    useEffect(() => {
        if (groupId && (!group || (group.groupId !== groupId)))
            dispatch(groupOperations.getGroup(groupId));
    }, [groupId, group, currentLang]);

    useEffect(() => {
        return () => { dispatch(resetGroup()); };
    }, []);

    return (
        <Page title={`${translate('groups.detail.title')}`}>
            {isLoading ?
                <LoadingScreen />
                :
                <Container maxWidth={themeStretch ? false : 'lg'}>

                    <HeaderBreadcrumbs
                        heading={`${translate('groups.detail.title')}`}
                        links={[
                            { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                            { name: `${translate(`role.type.groups`)}`, href: PATH_DASHBOARD.groups.list },
                            { name: group?.displayName }
                        ]}
                        action={
                            (group?.status === "Active") &&
                            <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Group_Edit]}>
                                <Button
                                    variant="contained"
                                    onClick={handleEdit}>
                                    {`${translate('commons.edit')}`}
                                </Button>
                            </PermissionBasedGuard>
                        }
                    />

                    <Card
                        sx={{
                            mb: 3,
                            height: 280,
                            position: 'relative',
                        }}
                    >
                        <StyledRoot sx={{ '&:before': { ...bgBlur({ color: theme.palette.primary.lighter }) } }}>
                            <StyledInfo>
                                <CustomAvatar
                                    name={''}
                                    color={"primary"}
                                    sx={{
                                        mx: 'auto',
                                        borderWidth: 2,
                                        borderStyle: 'solid',
                                        borderColor: 'common.white',
                                        color: "white",
                                        width: { xs: 80, md: 128 },
                                        height: { xs: 80, md: 128 }
                                    }}
                                >
                                    {<GroupsIcon color="inherit" />}
                                </CustomAvatar>

                                <Box
                                    sx={{
                                        ml: { md: 3 },
                                        mt: { xs: 1, md: 0 },
                                        mb: 5,
                                        textAlign: { xs: 'center', md: 'left' }
                                    }}
                                >
                                    <Typography color={"black"} variant="subtitle2">
                                        {group?.groupType.toUpperCase()}
                                    </Typography>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography color={"black"} variant="h4">
                                            {group?.displayName}
                                        </Typography>
                                        <Label
                                            color={group?.enabled ? "success" : "warning"}
                                            sx={{ textTransform: 'uppercase', mt: 0.75, ml: 3 }}>
                                            {`${translate(`commons.${group?.enabled ? "enabled" : "disabled"}`)}`}
                                        </Label>
                                    </Box>
                                    <Typography color={"GrayText"} variant="body2">
                                        {`${translate(`commons.createdOn`)} `}
                                        {dayjs(group?.createdOn).format('DD MMM YYYY, HH:mm')}
                                    </Typography>
                                </Box>
                            </StyledInfo>
                        </StyledRoot>

                        <Box
                            sx={{
                                width: 1,
                                bottom: 0,
                                zIndex: 9,
                                position: 'absolute',
                                bgcolor: 'background.paper',
                                display: "flex !important",
                                justifyContent: "flex-end !important"
                            }}
                        >
                            <Tabs
                                value={currentTab}
                                onChange={(_, newValue) => onTabChange(newValue)}
                                sx={{ pr: { md: 3 } }}
                            >
                                {TABS.map((tab) => (
                                    <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} sx={{ mr: "28px !important" }} />
                                ))}
                            </Tabs>
                        </Box>

                    </Card>

                    {TABS.map((tab) => tab.value === currentTab &&
                        <Box key={tab.value}> {tab.component} </Box>
                    )}

                </Container>
            }
        </Page>
    );
}