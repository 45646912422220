export const timezones = [
  { code: 'Pacific/Niue', label: '(UTC-11:00) Niue Time' },
  { code: 'Pacific/Midway', label: '(UTC-11:00) Samoa Standard Time (Midway)' },
  { code: 'Pacific/Pago_Pago', label: '(UTC-11:00) Samoa Standard Time (Pago Pago)' },
  { code: 'Pacific/Rarotonga', label: '(UTC-10:00) Cook Islands Standard Time (Rarotonga)' },
  { code: 'America/Adak', label: '(UTC-10:00) Hawaii-Aleutian Time (Adak)' },
  { code: 'Pacific/Honolulu', label: '(UTC-10:00) Hawaii-Aleutian Time (Adak) (Honolulu)' },
  { code: 'Pacific/Tahiti', label: '(UTC-10:00) Tahiti Time' },
  { code: 'Pacific/Marquesas', label: '(UTC-09:30) Marquesas Time' },
  { code: 'America/Anchorage', label: '(UTC-09:00) Alaska Time (Anchorage)' },
  { code: 'America/Juneau', label: '(UTC-09:00) Alaska Time (Juneau)' },
  { code: 'America/Metlakatla', label: '(UTC-09:00) Alaska Time (Metlakatla)' },
  { code: 'America/Nome', label: '(UTC-09:00) Alaska Time (Nome)' },
  { code: 'America/Sitka', label: '(UTC-09:00) Alaska Time (Sitka)' },
  { code: 'America/Yakutat', label: '(UTC-09:00) Alaska Time (Yakutat)' },
  { code: 'Pacific/Gambier', label: '(UTC-09:00) Gambier Time' },
  { code: 'America/Los_Angeles', label: '(UTC-08:00) Pacific Time (Los Angeles)' },
  { code: 'America/Tijuana', label: '(UTC-08:00) Pacific Time (Tijuana)' },
  { code: 'America/Vancouver', label: '(UTC-08:00) Pacific Time (Vancouver)' },
  { code: 'Pacific/Pitcairn', label: '(UTC-08:00) Pitcairn Islands Time' },
  { code: 'America/Chihuahua', label: '(UTC-07:00) Mexican Pacific Time (Chihuahua)' },
  { code: 'America/Hermosillo', label: '(UTC-07:00) Mexican Pacific Time (Hermosillo)' },
  { code: 'America/Mazatlan', label: '(UTC-07:00) Mexican Pacific Time (Mazatlan)' },
  { code: 'America/Boise', label: '(UTC-07:00) Mountain Time (Boise)' },
  { code: 'America/Cambridge_Bay', label: '(UTC-07:00) Mountain Time (Cambridge Bay)' },
  { code: 'America/Creston', label: '(UTC-07:00) Mountain Time (Creston)' },
  { code: 'America/Dawson_Creek', label: '(UTC-07:00) Mountain Time (Dawson Creek)' },
  { code: 'America/Denver', label: '(UTC-07:00) Mountain Time (Denver)' },
  { code: 'America/Edmonton', label: '(UTC-07:00) Mountain Time (Edmonton)' },
  { code: 'America/Fort_Nelson', label: '(UTC-07:00) Mountain Time (Fort Nelson)' },
  { code: 'America/Inuvik', label: '(UTC-07:00) Mountain Time (Inuvik)' },
  { code: 'America/Ojinaga', label: '(UTC-07:00) Mountain Time (Ojinaga)' },
  { code: 'America/Phoenix', label: '(UTC-07:00) Mountain Time (Phoenix)' },
  { code: 'America/Yellowknife', label: '(UTC-07:00) Mountain Time (Yellowknife)' },
  { code: 'America/Dawson', label: '(UTC-07:00) Pacific Time (Dawson)' },
  { code: 'America/Whitehorse', label: '(UTC-07:00) Pacific Time (Whitehorse)' },
  { code: 'America/Bahia_Banderas', label: '(UTC-06:00) Central Time (Bahia Banderas)' },
  { code: 'America/Belize', label: '(UTC-06:00) Central Time (Belize)' },
  { code: 'America/North_Dakota/Beulah', label: '(UTC-06:00) Central Time (Beulah, North Dakota)' },
  { code: 'America/North_Dakota/Center', label: '(UTC-06:00) Central Time (Center, North Dakota)' },
  { code: 'America/Chicago', label: '(UTC-06:00) Central Time (Chicago)' },
  { code: 'America/Costa_Rica', label: '(UTC-06:00) Central Time (Costa Rica)' },
  { code: 'America/El_Salvador', label: '(UTC-06:00) Central Time (El Salvador)' },
  { code: 'America/Guatemala', label: '(UTC-06:00) Central Time (Guatemala)' },
  { code: 'America/Indiana/Knox', label: '(UTC-06:00) Central Time (Knox, Indiana)' },
  { code: 'America/Managua', label: '(UTC-06:00) Central Time (Managua)' },
  { code: 'America/Matamoros', label: '(UTC-06:00) Central Time (Matamoros)' },
  { code: 'America/Menominee', label: '(UTC-06:00) Central Time (Menominee)' },
  { code: 'America/Merida', label: '(UTC-06:00) Central Time (Merida)' },
  { code: 'America/Mexico_City', label: '(UTC-06:00) Central Time (Mexico City)' },
  { code: 'America/Monterrey', label: '(UTC-06:00) Central Time (Monterrey)' },
  { code: 'America/North_Dakota/New_Salem', label: '(UTC-06:00) Central Time (New Salem, North Dakota)' },
  { code: 'America/Rainy_River', label: '(UTC-06:00) Central Time (Rainy River)' },
  { code: 'America/Rankin_Inlet', label: '(UTC-06:00) Central Time (Rankin Inlet)' },
  { code: 'America/Regina', label: '(UTC-06:00) Central Time (Regina)' },
  { code: 'America/Resolute', label: '(UTC-06:00) Central Time (Resolute)' },
  { code: 'America/Swift_Current', label: '(UTC-06:00) Central Time (Swift Current)' },
  { code: 'America/Tegucigalpa', label: '(UTC-06:00) Central Time (Tegucigalpa)' },
  { code: 'America/Indiana/Tell_City', label: '(UTC-06:00) Central Time (Tell City, Indiana)' },
  { code: 'America/Winnipeg', label: '(UTC-06:00) Central Time (Winnipeg)' },
  { code: 'Pacific/Easter', label: '(UTC-06:00) Easter Island Time' },
  { code: 'Pacific/Galapagos', label: '(UTC-06:00) Galapagos Time' },
  { code: 'America/Eirunepe', label: '(UTC-05:00) Acre Standard Time (Eirunepe)' },
  { code: 'America/Rio_Branco', label: '(UTC-05:00) Acre Standard Time (Rio Branco)' },
  { code: 'America/Bogota', label: '(UTC-05:00) Colombia Standard Time (Bogota)' },
  { code: 'America/Havana', label: '(UTC-05:00) Cuba Time' },
  { code: 'America/Atikokan', label: '(UTC-05:00) Eastern Time (Atikokan)' },
  { code: 'America/Cancun', label: '(UTC-05:00) Eastern Time (Cancun)' },
  { code: 'America/Cayman', label: '(UTC-05:00) Eastern Time (Cayman)' },
  { code: 'America/Detroit', label: '(UTC-05:00) Eastern Time (Detroit)' },
  { code: 'America/Grand_Turk', label: '(UTC-05:00) Eastern Time (Grand Turk)' },
  { code: 'America/Indiana/Indianapolis', label: '(UTC-05:00) Eastern Time (Indianapolis)' },
  { code: 'America/Iqaluit', label: '(UTC-05:00) Eastern Time (Iqaluit)' },
  { code: 'America/Jamaica', label: '(UTC-05:00) Eastern Time (Jamaica)' },
  { code: 'America/Kentucky/Louisville', label: '(UTC-05:00) Eastern Time (Louisville)' },
  { code: 'America/Indiana/Marengo', label: '(UTC-05:00) Eastern Time (Marengo, Indiana)' },
  { code: 'America/Kentucky/Monticello', label: '(UTC-05:00) Eastern Time (Monticello, Kentucky)' },
  { code: 'America/Nassau', label: '(UTC-05:00) Eastern Time (Nassau)' },
  { code: 'America/New_York', label: '(UTC-05:00) Eastern Time (New York)' },
  { code: 'America/Nipigon', label: '(UTC-05:00) Eastern Time (Nipigon)' },
  { code: 'America/Panama', label: '(UTC-05:00) Eastern Time (Panama)' },
  { code: 'America/Pangnirtung', label: '(UTC-05:00) Eastern Time (Pangnirtung)' },
  { code: 'America/Indiana/Petersburg', label: '(UTC-05:00) Eastern Time (Petersburg, Indiana)' },
  { code: 'America/Port-au-Prince', label: '(UTC-05:00) Eastern Time (Port-au-Prince)' },
  { code: 'America/Thunder_Bay', label: '(UTC-05:00) Eastern Time (Thunder Bay)' },
  { code: 'America/Toronto', label: '(UTC-05:00) Eastern Time (Toronto)' },
  { code: 'America/Indiana/Vevay', label: '(UTC-05:00) Eastern Time (Vevay, Indiana)' },
  { code: 'America/Indiana/Vincennes', label: '(UTC-05:00) Eastern Time (Vincennes, Indiana)' },
  { code: 'America/Indiana/Winamac', label: '(UTC-05:00) Eastern Time (Winamac, Indiana)' },
  { code: 'America/Guayaquil', label: '(UTC-05:00) Ecuador Time' },
  { code: 'America/Lima', label: '(UTC-05:00) Peru Standard Time (Lima)' },
  { code: 'America/Boa_Vista', label: '(UTC-04:00) Amazon Standard Time (Boa Vista)' },
  { code: 'America/Campo_Grande', label: '(UTC-04:00) Amazon Standard Time (Campo Grande)' },
  { code: 'America/Cuiaba', label: '(UTC-04:00) Amazon Standard Time (Cuiaba)' },
  { code: 'America/Manaus', label: '(UTC-04:00) Amazon Standard Time (Manaus)' },
  { code: 'America/Porto_Velho', label: '(UTC-04:00) Amazon Standard Time (Porto Velho)' },
  { code: 'America/Anguilla', label: '(UTC-04:00) Atlantic Time (Anguilla)' },
  { code: 'America/Antigua', label: '(UTC-04:00) Atlantic Time (Antigua)' },
  { code: 'America/Aruba', label: '(UTC-04:00) Atlantic Time (Aruba)' },
  { code: 'America/Barbados', label: '(UTC-04:00) Atlantic Time (Barbados)' },
  { code: 'Atlantic/Bermuda', label: '(UTC-04:00) Atlantic Time (Bermuda)' },
  { code: 'America/Blanc-Sablon', label: '(UTC-04:00) Atlantic Time (Blanc-Sablon)' },
  { code: 'America/Curacao', label: '(UTC-04:00) Atlantic Time (Curaçao)' },
  { code: 'America/Dominica', label: '(UTC-04:00) Atlantic Time (Dominica)' },
  { code: 'America/Glace_Bay', label: '(UTC-04:00) Atlantic Time (Glace Bay)' },
  { code: 'America/Goose_Bay', label: '(UTC-04:00) Atlantic Time (Goose Bay)' },
  { code: 'America/Grenada', label: '(UTC-04:00) Atlantic Time (Grenada)' },
  { code: 'America/Guadeloupe', label: '(UTC-04:00) Atlantic Time (Guadeloupe)' },
  { code: 'America/Halifax', label: '(UTC-04:00) Atlantic Time (Halifax)' },
  { code: 'America/Kralendijk', label: '(UTC-04:00) Atlantic Time (Kralendijk)' },
  { code: 'America/Lower_Princes', label: '(UTC-04:00) Atlantic Time (Lower Prince’s Quarter)' },
  { code: 'America/Marigot', label: '(UTC-04:00) Atlantic Time (Marigot)' },
  { code: 'America/Martinique', label: '(UTC-04:00) Atlantic Time (Martinique)' },
  { code: 'America/Moncton', label: '(UTC-04:00) Atlantic Time (Moncton)' },
  { code: 'America/Montserrat', label: '(UTC-04:00) Atlantic Time (Montserrat)' },
  { code: 'America/Port_of_Spain', label: '(UTC-04:00) Atlantic Time (Port of Spain)' },
  { code: 'America/Puerto_Rico', label: '(UTC-04:00) Atlantic Time (Puerto Rico)' },
  { code: 'America/Santo_Domingo', label: '(UTC-04:00) Atlantic Time (Santo Domingo)' },
  { code: 'America/St_Barthelemy', label: '(UTC-04:00) Atlantic Time (St. Barthélemy)' },
  { code: 'America/St_Kitts', label: '(UTC-04:00) Atlantic Time (St. Kitts)' },
  { code: 'America/St_Lucia', label: '(UTC-04:00) Atlantic Time (St. Lucia)' },
  { code: 'America/St_Thomas', label: '(UTC-04:00) Atlantic Time (St. Thomas)' },
  { code: 'America/St_Vincent', label: '(UTC-04:00) Atlantic Time (St. Vincent)' },
  { code: 'America/Thule', label: '(UTC-04:00) Atlantic Time (Thule)' },
  { code: 'America/Tortola', label: '(UTC-04:00) Atlantic Time (Tortola)' },
  { code: 'America/La_Paz', label: '(UTC-04:00) Bolivia Time' },
  { code: 'America/Santiago', label: '(UTC-04:00) Chile Time' },
  { code: 'America/Guyana', label: '(UTC-04:00) Guyana Time' },
  { code: 'America/Asuncion', label: '(UTC-04:00) Paraguay Time' },
  { code: 'America/Caracas', label: '(UTC-04:00) Venezuela Time' },
  { code: 'America/St_Johns', label: '(UTC-03:30) Newfoundland Time (St. John’s)' },
  { code: 'America/Argentina/Buenos_Aires', label: '(UTC-03:00) Argentina Standard Time (Buenos Aires)' },
  { code: 'America/Argentina/Catamarca', label: '(UTC-03:00) Argentina Standard Time (Catamarca)' },
  { code: 'America/Argentina/Cordoba', label: '(UTC-03:00) Argentina Standard Time (Cordoba)' },
  { code: 'America/Argentina/Jujuy', label: '(UTC-03:00) Argentina Standard Time (Jujuy)' },
  { code: 'America/Argentina/La_Rioja', label: '(UTC-03:00) Argentina Standard Time (La Rioja)' },
  { code: 'America/Argentina/Mendoza', label: '(UTC-03:00) Argentina Standard Time (Mendoza)' },
  { code: 'America/Argentina/Rio_Gallegos', label: '(UTC-03:00) Argentina Standard Time (Rio Gallegos)' },
  { code: 'America/Argentina/Salta', label: '(UTC-03:00) Argentina Standard Time (Salta)' },
  { code: 'America/Argentina/San_Juan', label: '(UTC-03:00) Argentina Standard Time (San Juan)' },
  { code: 'America/Argentina/San_Luis', label: '(UTC-03:00) Argentina Standard Time (San Luis)' },
  { code: 'America/Argentina/Tucuman', label: '(UTC-03:00) Argentina Standard Time (Tucuman)' },
  { code: 'America/Argentina/Ushuaia', label: '(UTC-03:00) Argentina Standard Time (Ushuaia)' },
  { code: 'America/Araguaina', label: '(UTC-03:00) Brasilia Standard Time (Araguaina)' },
  { code: 'America/Bahia', label: '(UTC-03:00) Brasilia Standard Time (Bahia)' },
  { code: 'America/Belem', label: '(UTC-03:00) Brasilia Standard Time (Belem)' },
  { code: 'America/Fortaleza', label: '(UTC-03:00) Brasilia Standard Time (Fortaleza)' },
  { code: 'America/Maceio', label: '(UTC-03:00) Brasilia Standard Time (Maceio)' },
  { code: 'America/Recife', label: '(UTC-03:00) Brasilia Standard Time (Recife)' },
  { code: 'America/Santarem', label: '(UTC-03:00) Brasilia Standard Time (Santarem)' },
  { code: 'America/Sao_Paulo', label: '(UTC-03:00) Brasilia Standard Time (Sao Paulo)' },
  { code: 'Atlantic/Stanley', label: '(UTC-03:00) Falkland Islands Standard Time (Stanley)' },
  { code: 'America/Cayenne', label: '(UTC-03:00) French Guiana Time' },
  { code: 'America/Nuuk', label: '(UTC-03:00) GMT' },
  { code: 'Antarctica/Palmer', label: '(UTC-03:00) Palmer Time' },
  { code: 'America/Punta_Arenas', label: '(UTC-03:00) Punta Arenas Time' },
  { code: 'Antarctica/Rothera', label: '(UTC-03:00) Rothera Time' },
  { code: 'America/Miquelon', label: '(UTC-03:00) St. Pierre & Miquelon Time' },
  { code: 'America/Paramaribo', label: '(UTC-03:00) Suriname Time' },
  { code: 'America/Montevideo', label: '(UTC-03:00) Uruguay Standard Time (Montevideo)' },
  { code: 'America/Noronha', label: '(UTC-02:00) Fernando de Noronha Standard Time' },
  { code: 'Atlantic/South_Georgia', label: '(UTC-02:00) South Georgia Time' },
  { code: 'Atlantic/Azores', label: '(UTC-01:00) Azores Time' },
  { code: 'Atlantic/Cape_Verde', label: '(UTC-01:00) Cape Verde Standard Time' },
  { code: 'America/Scoresbysund', label: '(UTC-01:00) East Greenland Time (Ittoqqortoormiit)' },
  { code: 'UTC', label: '(UTC) Coordinated Universal Time' },
  { code: 'Africa/Ouagadougou', label: '(UTC+00:00) Burkina Faso Time' },
  { code: 'Africa/Abidjan', label: '(UTC+00:00) Côte d’Ivoire Time' },
  { code: 'America/Danmarkshavn', label: '(UTC+00:00) Danmarkshavn Time' },
  { code: 'Africa/Banjul', label: '(UTC+00:00) Gambia Time' },
  { code: 'Africa/Accra', label: '(UTC+00:00) Ghana Time' },
  { code: 'Europe/Guernsey', label: '(UTC+00:00) Guernsey Time' },
  { code: 'Africa/Conakry', label: '(UTC+00:00) Guinea Time' },
  { code: 'Africa/Bissau', label: '(UTC+00:00) Guinea-Bissau Time' },
  { code: 'Atlantic/Reykjavik', label: '(UTC+00:00) Iceland Time' },
  { code: 'Europe/Isle_of_Man', label: '(UTC+00:00) Isle of Man Time' },
  { code: 'Europe/Jersey', label: '(UTC+00:00) Jersey Time' },
  { code: 'Africa/Monrovia', label: '(UTC+00:00) Liberia Time' },
  { code: 'Africa/Bamako', label: '(UTC+00:00) Mali Time' },
  { code: 'Africa/Nouakchott', label: '(UTC+00:00) Mauritania Time' },
  { code: 'Africa/Dakar', label: '(UTC+00:00) Senegal Time' },
  { code: 'Africa/Freetown', label: '(UTC+00:00) Sierra Leone Time' },
  { code: 'Atlantic/St_Helena', label: '(UTC+00:00) St. Helena Time' },
  { code: 'Africa/Sao_Tome', label: '(UTC+00:00) São Tomé & Príncipe Time' },
  { code: 'Africa/Lome', label: '(UTC+00:00) Togo Time' },
  { code: 'Antarctica/Troll', label: '(UTC+00:00) Troll Time' },
  { code: 'Europe/London', label: '(UTC+00:00) United Kingdom Time' },
  { code: 'Atlantic/Canary', label: '(UTC+00:00) Western European Time (Canary)' },
  { code: 'Atlantic/Faroe', label: '(UTC+00:00) Western European Time (Faroe)' },
  { code: 'Europe/Lisbon', label: '(UTC+00:00) Western European Time (Lisbon)' },
  { code: 'Atlantic/Madeira', label: '(UTC+00:00) Western European Time (Madeira)' },
  { code: 'Africa/Algiers', label: '(UTC+01:00) Central European Time (Algiers)' },
  { code: 'Europe/Amsterdam', label: '(UTC+01:00) Central European Time (Amsterdam)' },
  { code: 'Europe/Andorra', label: '(UTC+01:00) Central European Time (Andorra)' },
  { code: 'Europe/Belgrade', label: '(UTC+01:00) Central European Time (Belgrade)' },
  { code: 'Europe/Berlin', label: '(UTC+01:00) Central European Time (Berlin)' },
  { code: 'Europe/Bratislava', label: '(UTC+01:00) Central European Time (Bratislava)' },
  { code: 'Europe/Brussels', label: '(UTC+01:00) Central European Time (Brussels)' },
  { code: 'Europe/Budapest', label: '(UTC+01:00) Central European Time (Budapest)' },
  { code: 'Europe/Busingen', label: '(UTC+01:00) Central European Time (Busingen)' },
  { code: 'Africa/Ceuta', label: '(UTC+01:00) Central European Time (Ceuta)' },
  { code: 'Europe/Copenhagen', label: '(UTC+01:00) Central European Time (Copenhagen)' },
  { code: 'Europe/Gibraltar', label: '(UTC+01:00) Central European Time (Gibraltar)' },
  { code: 'Europe/Ljubljana', label: '(UTC+01:00) Central European Time (Ljubljana)' },
  { code: 'Arctic/Longyearbyen', label: '(UTC+01:00) Central European Time (Longyearbyen)' },
  { code: 'Europe/Luxembourg', label: '(UTC+01:00) Central European Time (Luxembourg)' },
  { code: 'Europe/Madrid', label: '(UTC+01:00) Central European Time (Madrid)' },
  { code: 'Europe/Malta', label: '(UTC+01:00) Central European Time (Malta)' },
  { code: 'Europe/Monaco', label: '(UTC+01:00) Central European Time (Monaco)' },
  { code: 'Europe/Oslo', label: '(UTC+01:00) Central European Time (Oslo)' },
  { code: 'Europe/Paris', label: '(UTC+01:00) Central European Time (Paris)' },
  { code: 'Europe/Podgorica', label: '(UTC+01:00) Central European Time (Podgorica)' },
  { code: 'Europe/Prague', label: '(UTC+01:00) Central European Time (Prague)' },
  { code: 'Europe/Rome', label: '(UTC+01:00) Central European Time (Rome)' },
  { code: 'Europe/San_Marino', label: '(UTC+01:00) Central European Time (San Marino)' },
  { code: 'Europe/Sarajevo', label: '(UTC+01:00) Central European Time (Sarajevo)' },
  { code: 'Europe/Skopje', label: '(UTC+01:00) Central European Time (Skopje)' },
  { code: 'Europe/Stockholm', label: '(UTC+01:00) Central European Time (Stockholm)' },
  { code: 'Europe/Tirane', label: '(UTC+01:00) Central European Time (Tirane)' },
  { code: 'Africa/Tunis', label: '(UTC+01:00) Central European Time (Tunis)' },
  { code: 'Europe/Vaduz', label: '(UTC+01:00) Central European Time (Vaduz)' },
  { code: 'Europe/Vatican', label: '(UTC+01:00) Central European Time (Vatican)' },
  { code: 'Europe/Vienna', label: '(UTC+01:00) Central European Time (Vienna)' },
  { code: 'Europe/Warsaw', label: '(UTC+01:00) Central European Time (Warsaw)' },
  { code: 'Europe/Zagreb', label: '(UTC+01:00) Central European Time (Zagreb)' },
  { code: 'Europe/Zurich', label: '(UTC+01:00) Central European Time (Zurich)' },
  { code: 'Europe/Dublin', label: '(UTC+01:00) Ireland Time' },
  { code: 'Africa/Casablanca', label: '(UTC+01:00) Morocco Time' },
  { code: 'Africa/Bangui', label: '(UTC+01:00) West Africa Standard Time (Bangui)' },
  { code: 'Africa/Brazzaville', label: '(UTC+01:00) West Africa Standard Time (Brazzaville)' },
  { code: 'Africa/Douala', label: '(UTC+01:00) West Africa Standard Time (Douala)' },
  { code: 'Africa/Kinshasa', label: '(UTC+01:00) West Africa Standard Time (Kinshasa)' },
  { code: 'Africa/Lagos', label: '(UTC+01:00) West Africa Standard Time (Lagos)' },
  { code: 'Africa/Libreville', label: '(UTC+01:00) West Africa Standard Time (Libreville)' },
  { code: 'Africa/Luanda', label: '(UTC+01:00) West Africa Standard Time (Luanda)' },
  { code: 'Africa/Malabo', label: '(UTC+01:00) West Africa Standard Time (Malabo)' },
  { code: 'Africa/Ndjamena', label: '(UTC+01:00) West Africa Standard Time (Ndjamena)' },
  { code: 'Africa/Niamey', label: '(UTC+01:00) West Africa Standard Time (Niamey)' },
  { code: 'Africa/Porto-Novo', label: '(UTC+01:00) West Africa Standard Time (Porto-Novo)' },
  { code: 'Africa/El_Aaiun', label: '(UTC+01:00) Western Sahara Time' },
  { code: 'Africa/Blantyre', label: '(UTC+02:00) Central Africa Time (Blantyre)' },
  { code: 'Africa/Bujumbura', label: '(UTC+02:00) Central Africa Time (Bujumbura)' },
  { code: 'Africa/Gaborone', label: '(UTC+02:00) Central Africa Time (Gaborone)' },
  { code: 'Africa/Harare', label: '(UTC+02:00) Central Africa Time (Harare)' },
  { code: 'Africa/Khartoum', label: '(UTC+02:00) Central Africa Time (Khartoum)' },
  { code: 'Africa/Kigali', label: '(UTC+02:00) Central Africa Time (Kigali)' },
  { code: 'Africa/Lubumbashi', label: '(UTC+02:00) Central Africa Time (Lubumbashi)' },
  { code: 'Africa/Lusaka', label: '(UTC+02:00) Central Africa Time (Lusaka)' },
  { code: 'Africa/Maputo', label: '(UTC+02:00) Central Africa Time (Maputo)' },
  { code: 'Africa/Windhoek', label: '(UTC+02:00) Central Africa Time (Windhoek)' },
  { code: 'Africa/Juba', label: '(UTC+02:00) East Africa Time (Juba)' },
  { code: 'Asia/Amman', label: '(UTC+02:00) Eastern European Time (Amman)' },
  { code: 'Europe/Athens', label: '(UTC+02:00) Eastern European Time (Athens)' },
  { code: 'Asia/Beirut', label: '(UTC+02:00) Eastern European Time (Beirut)' },
  { code: 'Europe/Bucharest', label: '(UTC+02:00) Eastern European Time (Bucharest)' },
  { code: 'Africa/Cairo', label: '(UTC+02:00) Eastern European Time (Cairo)' },
  { code: 'Europe/Chisinau', label: '(UTC+02:00) Eastern European Time (Chisinau)' },
  { code: 'Asia/Damascus', label: '(UTC+02:00) Eastern European Time (Damascus)' },
  { code: 'Asia/Gaza', label: '(UTC+02:00) Eastern European Time (Gaza)' },
  { code: 'Asia/Hebron', label: '(UTC+02:00) Eastern European Time (Hebron)' },
  { code: 'Europe/Helsinki', label: '(UTC+02:00) Eastern European Time (Helsinki)' },
  { code: 'Europe/Kaliningrad', label: '(UTC+02:00) Eastern European Time (Kaliningrad)' },
  { code: 'Europe/Kiev', label: '(UTC+02:00) Eastern European Time (Kiev)' },
  { code: 'Europe/Mariehamn', label: '(UTC+02:00) Eastern European Time (Mariehamn)' },
  { code: 'Asia/Nicosia', label: '(UTC+02:00) Eastern European Time (Nicosia)' },
  { code: 'Europe/Riga', label: '(UTC+02:00) Eastern European Time (Riga)' },
  { code: 'Europe/Sofia', label: '(UTC+02:00) Eastern European Time (Sofia)' },
  { code: 'Europe/Tallinn', label: '(UTC+02:00) Eastern European Time (Tallinn)' },
  { code: 'Africa/Tripoli', label: '(UTC+02:00) Eastern European Time (Tripoli)' },
  { code: 'Europe/Uzhgorod', label: '(UTC+02:00) Eastern European Time (Uzhhorod)' },
  { code: 'Europe/Vilnius', label: '(UTC+02:00) Eastern European Time (Vilnius)' },
  { code: 'Europe/Zaporozhye', label: '(UTC+02:00) Eastern European Time (Zaporozhye)' },
  { code: 'Asia/Famagusta', label: '(UTC+02:00) Famagusta Time' },
  { code: 'Asia/Jerusalem', label: '(UTC+02:00) Israel Time' },
  { code: 'Africa/Johannesburg', label: '(UTC+02:00) South Africa Standard Time (Johannesburg)' },
  { code: 'Africa/Maseru', label: '(UTC+02:00) South Africa Standard Time (Maseru)' },
  { code: 'Africa/Mbabane', label: '(UTC+02:00) South Africa Standard Time (Mbabane)' },
  { code: 'Asia/Aden', label: '(UTC+03:00) Arabian Standard Time (Aden)' },
  { code: 'Asia/Baghdad', label: '(UTC+03:00) Arabian Standard Time (Baghdad)' },
  { code: 'Asia/Bahrain', label: '(UTC+03:00) Arabian Standard Time (Bahrain)' },
  { code: 'Asia/Kuwait', label: '(UTC+03:00) Arabian Standard Time (Kuwait)' },
  { code: 'Asia/Qatar', label: '(UTC+03:00) Arabian Standard Time (Qatar)' },
  { code: 'Asia/Riyadh', label: '(UTC+03:00) Arabian Standard Time (Riyadh)' },
  { code: 'Europe/Minsk', label: '(UTC+03:00) Belarus Time' },
  { code: 'Africa/Addis_Ababa', label: '(UTC+03:00) East Africa Time (Addis Ababa)' },
  { code: 'Indian/Antananarivo', label: '(UTC+03:00) East Africa Time (Antananarivo)' },
  { code: 'Africa/Asmara', label: '(UTC+03:00) East Africa Time (Asmara)' },
  { code: 'Indian/Comoro', label: '(UTC+03:00) East Africa Time (Comoro)' },
  { code: 'Africa/Dar_es_Salaam', label: '(UTC+03:00) East Africa Time (Dar es Salaam)' },
  { code: 'Africa/Djibouti', label: '(UTC+03:00) East Africa Time (Djibouti)' },
  { code: 'Africa/Kampala', label: '(UTC+03:00) East Africa Time (Kampala)' },
  { code: 'Indian/Mayotte', label: '(UTC+03:00) East Africa Time (Mayotte)' },
  { code: 'Africa/Mogadishu', label: '(UTC+03:00) East Africa Time (Mogadishu)' },
  { code: 'Africa/Nairobi', label: '(UTC+03:00) East Africa Time (Nairobi)' },
  { code: 'Europe/Kirov', label: '(UTC+03:00) Kirov Time' },
  { code: 'Europe/Moscow', label: '(UTC+03:00) Moscow Time' },
  { code: 'Europe/Simferopol', label: '(UTC+03:00) Simferopol Time' },
  { code: 'Antarctica/Syowa', label: '(UTC+03:00) Syowa Time' },
  { code: 'Europe/Istanbul', label: '(UTC+03:00) Turkey Time' },
  { code: 'Europe/Volgograd', label: '(UTC+03:00) Volgograd Standard Time' },
  { code: 'Asia/Tehran', label: '(UTC+03:30) Iran Time' },
  { code: 'Asia/Yerevan', label: '(UTC+04:00) Armenia Standard Time (Yerevan)' },
  { code: 'Europe/Astrakhan', label: '(UTC+04:00) Astrakhan Time' },
  { code: 'Asia/Baku', label: '(UTC+04:00) Azerbaijan Standard Time (Baku)' },
  { code: 'Asia/Tbilisi', label: '(UTC+04:00) Georgia Standard Time (Tbilisi)' },
  { code: 'Asia/Dubai', label: '(UTC+04:00) Gulf Standard Time (Dubai)' },
  { code: 'Asia/Muscat', label: '(UTC+04:00) Gulf Standard Time (Muscat)' },
  { code: 'Indian/Mauritius', label: '(UTC+04:00) Mauritius Standard Time' },
  { code: 'Indian/Reunion', label: '(UTC+04:00) Réunion Time' },
  { code: 'Europe/Samara', label: '(UTC+04:00) Samara Standard Time' },
  { code: 'Europe/Saratov', label: '(UTC+04:00) Saratov Time' },
  { code: 'Indian/Mahe', label: '(UTC+04:00) Seychelles Time' },
  { code: 'Europe/Ulyanovsk', label: '(UTC+04:00) Ulyanovsk Time' },
  { code: 'Asia/Kabul', label: '(UTC+04:30) Afghanistan Time' },
  { code: 'Indian/Kerguelen', label: '(UTC+05:00) French Southern & Antarctic Time (Kerguelen)' },
  { code: 'Indian/Maldives', label: '(UTC+05:00) Maldives Time' },
  { code: 'Antarctica/Mawson', label: '(UTC+05:00) Mawson Time' },
  { code: 'Asia/Karachi', label: '(UTC+05:00) Pakistan Standard Time (Karachi)' },
  { code: 'Asia/Dushanbe', label: '(UTC+05:00) Tajikistan Time' },
  { code: 'Asia/Ashgabat', label: '(UTC+05:00) Turkmenistan Standard Time (Ashgabat)' },
  { code: 'Asia/Samarkand', label: '(UTC+05:00) Uzbekistan Standard Time (Samarkand)' },
  { code: 'Asia/Tashkent', label: '(UTC+05:00) Uzbekistan Standard Time (Tashkent)' },
  { code: 'Asia/Aqtau', label: '(UTC+05:00) West Kazakhstan Time (Aqtau)' },
  { code: 'Asia/Aqtobe', label: '(UTC+05:00) West Kazakhstan Time (Aqtobe)' },
  { code: 'Asia/Atyrau', label: '(UTC+05:00) West Kazakhstan Time (Atyrau)' },
  { code: 'Asia/Oral', label: '(UTC+05:00) West Kazakhstan Time (Oral)' },
  { code: 'Asia/Qyzylorda', label: '(UTC+05:00) West Kazakhstan Time (Qyzylorda)' },
  { code: 'Asia/Yekaterinburg', label: '(UTC+05:00) Yekaterinburg Standard Time' },
  { code: 'Asia/Colombo', label: '(UTC+05:30) India Standard Time (Colombo)' },
  { code: 'Asia/Kolkata', label: '(UTC+05:30) India Standard Time (Kolkata)' },
  { code: 'Asia/Kathmandu', label: '(UTC+05:45) Nepal Time' },
  { code: 'Asia/Dhaka', label: '(UTC+06:00) Bangladesh Standard Time (Dhaka)' },
  { code: 'Asia/Thimphu', label: '(UTC+06:00) Bhutan Time' },
  { code: 'Asia/Almaty', label: '(UTC+06:00) East Kazakhstan Time (Almaty)' },
  { code: 'Asia/Qostanay', label: '(UTC+06:00) East Kazakhstan Time (Kostanay)' },
  { code: 'Indian/Chagos', label: '(UTC+06:00) Indian Ocean Time (Chagos)' },
  { code: 'Asia/Bishkek', label: '(UTC+06:00) Kyrgyzstan Time' },
  { code: 'Asia/Omsk', label: '(UTC+06:00) Omsk Standard Time' },
  { code: 'Asia/Urumqi', label: '(UTC+06:00) Urumqi Time' },
  { code: 'Antarctica/Vostok', label: '(UTC+06:00) Vostok Time' },
  { code: 'Indian/Cocos', label: '(UTC+06:30) Cocos Islands Time' },
  { code: 'Asia/Yangon', label: '(UTC+06:30) Myanmar Time (Yangon)' },
  { code: 'Asia/Barnaul', label: '(UTC+07:00) Barnaul Time' },
  { code: 'Indian/Christmas', label: '(UTC+07:00) Christmas Island Time' },
  { code: 'Antarctica/Davis', label: '(UTC+07:00) Davis Time' },
  { code: 'Asia/Hovd', label: '(UTC+07:00) Hovd Standard Time' },
  { code: 'Asia/Bangkok', label: '(UTC+07:00) Indochina Time (Bangkok)' },
  { code: 'Asia/Ho_Chi_Minh', label: '(UTC+07:00) Indochina Time (Ho Chi Minh City)' },
  { code: 'Asia/Phnom_Penh', label: '(UTC+07:00) Indochina Time (Phnom Penh)' },
  { code: 'Asia/Vientiane', label: '(UTC+07:00) Indochina Time (Vientiane)' },
  { code: 'Asia/Krasnoyarsk', label: '(UTC+07:00) Krasnoyarsk Standard Time' },
  { code: 'Asia/Novokuznetsk', label: '(UTC+07:00) Krasnoyarsk Standard Time (Novokuznetsk)' },
  { code: 'Asia/Novosibirsk', label: '(UTC+07:00) Novosibirsk Standard Time' },
  { code: 'Asia/Tomsk', label: '(UTC+07:00) Tomsk Time' },
  { code: 'Asia/Jakarta', label: '(UTC+07:00) Western Indonesia Time (Jakarta)' },
  { code: 'Asia/Pontianak', label: '(UTC+07:00) Western Indonesia Time (Pontianak)' },
  { code: 'Australia/Perth', label: '(UTC+08:00) Australian Western Standard Time (Perth)' },
  { code: 'Asia/Brunei', label: '(UTC+08:00) Brunei Darussalam Time' },
  { code: 'Asia/Makassar', label: '(UTC+08:00) Central Indonesia Time (Makassar)' },
  { code: 'Asia/Macau', label: '(UTC+08:00) China Standard Time (Macao)' },
  { code: 'Asia/Shanghai', label: '(UTC+08:00) China Standard Time (Shanghai)' },
  { code: 'Asia/Hong_Kong', label: '(UTC+08:00) Hong Kong Standard Time' },
  { code: 'Asia/Irkutsk', label: '(UTC+08:00) Irkutsk Standard Time' },
  { code: 'Asia/Kuala_Lumpur', label: '(UTC+08:00) Malaysia Time' },
  { code: 'Asia/Kuching', label: '(UTC+08:00) Malaysia Time (Kuching)' },
  { code: 'Asia/Manila', label: '(UTC+08:00) Philippine Standard Time (Manila)' },
  { code: 'Asia/Singapore', label: '(UTC+08:00) Singapore Standard Time' },
  { code: 'Asia/Taipei', label: '(UTC+08:00) Taipei Standard Time' },
  { code: 'Asia/Ulaanbaatar', label: '(UTC+08:00) Ulaanbaatar Standard Time' },
  { code: 'Asia/Choibalsan', label: '(UTC+08:00) Ulaanbaatar Standard Time (Choibalsan)' },
  { code: 'Australia/Eucla', label: '(UTC+08:45) Australian Central Western Standard Time (Eucla)' },
  { code: 'Asia/Dili', label: '(UTC+09:00) East Timor Time (Dili)' },
  { code: 'Asia/Jayapura', label: '(UTC+09:00) Eastern Indonesia Time (Jayapura)' },
  { code: 'Asia/Tokyo', label: '(UTC+09:00) Japan Standard Time (Tokyo)' },
  { code: 'Asia/Pyongyang', label: '(UTC+09:00) Korean Standard Time (Pyongyang)' },
  { code: 'Asia/Seoul', label: '(UTC+09:00) Korean Standard Time (Seoul)' },
  { code: 'Pacific/Palau', label: '(UTC+09:00) Palau Time' },
  { code: 'Asia/Yakutsk', label: '(UTC+09:00) Yakutsk Standard Time' },
  { code: 'Asia/Chita', label: '(UTC+09:00) Yakutsk Standard Time (Chita)' },
  { code: 'Asia/Khandyga', label: '(UTC+09:00) Yakutsk Standard Time (Khandyga)' },
  { code: 'Australia/Adelaide', label: '(UTC+09:30) Central Australia Time (Adelaide)' },
  { code: 'Australia/Broken_Hill', label: '(UTC+09:30) Central Australia Time (Broken Hill)' },
  { code: 'Australia/Darwin', label: '(UTC+09:30) Central Australia Time (Darwin)' },
  { code: 'Pacific/Guam', label: '(UTC+10:00) Chamorro Standard Time (Guam)' },
  { code: 'Pacific/Saipan', label: '(UTC+10:00) Chamorro Standard Time (Saipan)' },
  { code: 'Pacific/Chuuk', label: '(UTC+10:00) Chuuk Time' },
  { code: 'Antarctica/DumontDUrville', label: '(UTC+10:00) Dumont-d’Urville Time (Dumont d’Urville)' },
  { code: 'Australia/Brisbane', label: '(UTC+10:00) Eastern Australia Time (Brisbane)' },
  { code: 'Australia/Hobart', label: '(UTC+10:00) Eastern Australia Time (Hobart)' },
  { code: 'Australia/Lindeman', label: '(UTC+10:00) Eastern Australia Time (Lindeman)' },
  { code: 'Australia/Melbourne', label: '(UTC+10:00) Eastern Australia Time (Melbourne)' },
  { code: 'Australia/Sydney', label: '(UTC+10:00) Eastern Australia Time (Sydney)' },
  { code: 'Antarctica/Macquarie', label: '(UTC+10:00) Macquarie Island Time' },
  { code: 'Pacific/Port_Moresby', label: '(UTC+10:00) Papua New Guinea Time (Port Moresby)' },
  { code: 'Asia/Vladivostok', label: '(UTC+10:00) Vladivostok Standard Time' },
  { code: 'Asia/Ust-Nera', label: '(UTC+10:00) Vladivostok Standard Time (Ust-Nera)' },
  { code: 'Australia/Lord_Howe', label: '(UTC+10:30) Lord Howe Time' },
  { code: 'Antarctica/Casey', label: '(UTC+11:00) Australian Western Standard Time (Casey)' },
  { code: 'Pacific/Bougainville', label: '(UTC+11:00) Bougainville Time' },
  { code: 'Pacific/Kosrae', label: '(UTC+11:00) Kosrae Time' },
  { code: 'Asia/Magadan', label: '(UTC+11:00) Magadan Standard Time' },
  { code: 'Pacific/Noumea', label: '(UTC+11:00) New Caledonia Standard Time (Noumea)' },
  { code: 'Pacific/Norfolk', label: '(UTC+11:00) Norfolk Island Time' },
  { code: 'Pacific/Pohnpei', label: '(UTC+11:00) Ponape Time (Pohnpei)' },
  { code: 'Asia/Sakhalin', label: '(UTC+11:00) Sakhalin Standard Time' },
  { code: 'Pacific/Guadalcanal', label: '(UTC+11:00) Solomon Islands Time' },
  { code: 'Asia/Srednekolymsk', label: '(UTC+11:00) Srednekolymsk Time' },
  { code: 'Pacific/Efate', label: '(UTC+11:00) Vanuatu Standard Time (Efate)' },
  { code: 'Asia/Anadyr', label: '(UTC+12:00) Anadyr Standard Time' },
  { code: 'Pacific/Fiji', label: '(UTC+12:00) Fiji Time' },
  { code: 'Pacific/Tarawa', label: '(UTC+12:00) Gilbert Islands Time (Tarawa)' },
  { code: 'Pacific/Majuro', label: '(UTC+12:00) Marshall Islands Time' },
  { code: 'Pacific/Kwajalein', label: '(UTC+12:00) Marshall Islands Time (Kwajalein)' },
  { code: 'Pacific/Nauru', label: '(UTC+12:00) Nauru Time' },
  { code: 'Pacific/Auckland', label: '(UTC+12:00) New Zealand Time' },
  { code: 'Antarctica/McMurdo', label: '(UTC+12:00) New Zealand Time (McMurdo)' },
  { code: 'Asia/Kamchatka', label: '(UTC+12:00) Petropavlovsk-Kamchatski Standard Time (Kamchatka)' },
  { code: 'Pacific/Funafuti', label: '(UTC+12:00) Tuvalu Time' },
  { code: 'Pacific/Wake', label: '(UTC+12:00) Wake Island Time' },
  { code: 'Pacific/Wallis', label: '(UTC+12:00) Wallis & Futuna Time' },
  { code: 'Pacific/Chatham', label: '(UTC+12:45) Chatham Time' },
  { code: 'Pacific/Enderbury', label: '(UTC+13:00) Phoenix Islands Time (Enderbury)' },
  { code: 'Pacific/Apia', label: '(UTC+13:00) Samoa Time' },
  { code: 'Pacific/Fakaofo', label: '(UTC+13:00) Tokelau Time' },
  { code: 'Pacific/Tongatapu', label: '(UTC+13:00) Tonga Standard Time (Tongatapu)' },
  { code: 'Pacific/Kiritimati', label: '(UTC+14:00) Line Islands Time (Kiritimati)' }
];
