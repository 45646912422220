import { ReactNode } from 'react';
import useTenant from 'src/appHooks/useTenant';
import { LicenseTypes } from 'src/@types/tenant';
import { hasLicense } from 'src/utils/tenant';

// ----------------------------------------------------------------------

type LicenseGuardProps = {
    license: LicenseTypes | undefined;
    children: ReactNode;
};

export default function LicenseGuard({ license, children }: LicenseGuardProps) {

    const tenant = useTenant();

    if (!license) {
        return <>{children}</>;
    }

    if (hasLicense(tenant, license)) {
        return <>{children}</>;
    }

    return null;
}
