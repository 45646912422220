import { Button } from '@mui/material';
import { DEFAULT_REPORT_FILTERS, ReportFilters } from 'src/@types/report';
import Iconify from 'src/components/iconify';
import { useLocales } from 'src/locales';
import { RootStyle, FilterChip } from 'src/utils/SummaryUtils';

type Props = {
    filters: ReportFilters,
    isShowReset: boolean,
    onResetAll: VoidFunction,
    onResetFilter: (el: any, value?: any) => void
}

export default function ReportFilterSummary({ filters, isShowReset, onResetAll, onResetFilter }: Props) {

    const { translate } = useLocales();

    const { name: title, outputType, recurringType, reportType } = filters;

    return (
        <RootStyle sx={{ m: isShowReset ? 2 : 0 }}>
            {title && title !== DEFAULT_REPORT_FILTERS.name &&
                <FilterChip
                    el={"commons.title"}
                    value={title}
                    onResetFilter={onResetFilter}
                />
            }

            {outputType && outputType !== DEFAULT_REPORT_FILTERS.outputType &&
                <FilterChip
                    el={"report.tableHeaders.outputType"}
                    value={`${translate(`report.types.${outputType[0].toLowerCase() + outputType.slice(1)!}`)}`}
                    onResetFilter={onResetFilter}
                    toDelete={"outputType"}
                />
            }

            {recurringType && recurringType !== DEFAULT_REPORT_FILTERS.recurringType &&
                <FilterChip
                    el={"report.tableHeaders.recurringType"}
                    value={`${translate(`report.types.${recurringType[0].toLowerCase() + recurringType.slice(1)!}`)}`}
                    onResetFilter={onResetFilter}
                    toDelete={"recurringType"}
                />
            }

            {reportType && reportType !== DEFAULT_REPORT_FILTERS.reportType &&
                <FilterChip
                    el={"report.tableHeaders.reportType"}
                    value={`${translate(`report.types.${reportType[0].toLowerCase() + reportType.slice(1)!}`)}`}
                    onResetFilter={onResetFilter}
                    toDelete={"reportType"}
                />
            }

            {isShowReset &&
                <Button
                    color="error"
                    size="small"
                    onClick={onResetAll}
                    startIcon={<Iconify icon={'ic:round-clear-all'} />}
                >
                    {`${translate("commons.clear")}`}
                </Button>
            }
        </RootStyle >
    );
}