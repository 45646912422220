import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import { MatrixResult } from "src/hooks/usePowersearch";
import { Box } from '@mui/material';

interface OrdersSearchTemplateProps {
    option: MatrixResult;
    searchQuery: string;
}

export default function OrdersSearchTemplate({ option, searchQuery }: OrdersSearchTemplateProps) {

    const nameParts = parse(`${option.orderName}`, match(`${option.orderName}`, searchQuery));

    const pathParts = parse(option?.path, match(option?.path, searchQuery));

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                {option.orderName && (
                    <div>
                        {nameParts.map((part, index) => (
                            <Box
                                key={index}
                                component="span"
                                sx={{
                                    typography: 'subtitle2',
                                    textTransform: 'capitalize',
                                    color: part.highlight ? 'primary.main' : 'text.primary'
                                }}
                            >
                                {part.text}
                            </Box>
                        ))}
                    </div>
                )}
            </div>

            {
                option.path && (
                    <div>
                        {pathParts.map((part, index) => (
                            <Box
                                key={index}
                                component="span"
                                sx={{
                                    typography: 'caption',
                                    color: part.highlight ? 'primary.main' : 'text.secondary',
                                }}
                            >
                                {part.text}
                            </Box>
                        ))}
                    </div>
                )
            }
        </div >
    );
}