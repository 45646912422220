import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
    disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
    ({ disabledLink = false, sx, ...other }, ref) => {

        const logo = (
            <Box
                component="img"
                src={`/assets/${process.env.REACT_APP_TENANT}/logo-light.svg`}
                sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
            />
        );

        if (disabledLink) {
            return <>{logo}</>;
        }

        return (
            <Link to="/dashboard" component={RouterLink} sx={{ display: 'contents' }}>
                {logo}
            </Link>
        );
    }
);

export default Logo;
