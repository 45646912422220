import { Box, Button, Card, Divider, TextField, Typography } from "@mui/material";
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import { useLocales } from "src/locales";
import useResponsive from "src/hooks/useResponsive";

interface WarehouseRequestConditionsProps {
    handlePreviousStep: VoidFunction,
    setOpen: VoidFunction
}

export default function WarehouseReturnCustomer({ handlePreviousStep, setOpen }: WarehouseRequestConditionsProps) {

    const { translate } = useLocales();

    const isDesktop = useResponsive('up', 'sm');

    return (
        <Card sx={{ my: 2 }}>
            <Box sx={{ p: 3 }}>
                <Typography variant="h6">{`${translate('request.shippingFrom')}`}</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        width: '100%',
                        backgroundColor: '#F9FAFB',
                        borderRadius: '8px',
                        p: 5,
                        my: 2,

                    }}>
                    <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center', gap: 2 }}><HomeWorkIcon /> Warehouse Region A</Typography>
                    <Typography variant="body2" >1147 Rohan Drive Suite 819 - Burlington, VT / 82021</Typography>
                </Box>
                <Typography variant="h6">{`${translate('request.detail.shippingTo')}`}</Typography>
                <Box sx={{ display: 'grid', gridTemplateColumns: isDesktop ? "1fr 1fr" : "1fr", gap: 2, width: "100%", my: 2 }}>
                    <TextField label={`${translate('commons.quantityWrong')}`} />
                    <TextField label={`${translate('commons.country')}`} />
                    <TextField label={`${translate('request.city')}`} />
                    <TextField label={`${translate('organization.detail.zipcode')}`} />
                    <TextField label={`${translate('commons.phone')}`} />
                    <TextField label={`${translate('organization.email')}`} />
                    <TextField
                        label={`${translate('organization.deliveryNotes')}`}
                        variant="outlined"
                        rows={4}
                        multiline
                        fullWidth
                        type="text"
                    />
                    <TextField label="SAP/CSPS*" />
                </Box>
                <Divider sx={{ my: 3 }} />
                <Box
                    sx={{
                        display: 'flex',
                        px: 5,
                        py: 3,
                        gap: 1,
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}
                >
                    <Button variant="soft" onClick={handlePreviousStep}>{`${translate('commons.goBack')}`}</Button>
                    <Button sx={{ ml: 'auto' }} variant="contained" onClick={setOpen} >{`${translate('commons.confirm')}`}</Button>

                </Box>
            </Box>
        </Card>
    );
}