import { GridOn } from "@mui/icons-material";
import { Box, Card, Divider, Grid, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { NewGroup } from "src/@types/group";
import StepButtons from "src/components/buttons/StepButtons";
import { useLocales } from "src/locales";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Action } from "src/sections/@dashboard/group/newEditGroup/GroupWizard";
import { PATH_DASHBOARD } from "src/routes/paths";

interface DetailStepProps {
    changeStep: Dispatch<SetStateAction<number>>,
    state: NewGroup,
    onSubmit: Dispatch<Action>
}

const schema = Yup.object().shape({
    displayName: Yup.string().trim().required('commons.validation.requiredField'),
    groupType: Yup.string().trim().oneOf(['Customer', 'Organization'])
});

export default function DetailStep({ changeStep, state, onSubmit }: DetailStepProps) {

    const { translate } = useLocales();

    const formik = useFormik({
        initialValues: state,
        validationSchema: schema,
        validateOnMount: true,
        onSubmit: (val) => onSubmit({
            payload: val,
            type: 'update'
        })
    });

    return (
        <Card sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', mb: 3 }}>
                <Typography variant="h5">
                    {translate('groups.form.steps.details')}
                </Typography>
            </Box>
            <TextField
                fullWidth
                label={translate('commons.name')}
                name='displayName'
                value={formik.values.displayName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required

                error={formik.touched.displayName && !!formik.errors.displayName}
                helperText={(formik.touched.displayName && !!formik.errors.displayName) && translate(formik.errors.displayName)}
                sx={{ '& .MuiInputLabel-asterisk': { color: 'error.main' }, my: 2 }}

            />
            <Divider />
            <Box sx={{ my: 2 }}>
                <Typography variant="h5">
                    {translate('groups.form.type')}
                </Typography>
                <ToggleButtonGroup
                    sx={{
                        gridTemplateColumns: '1fr 1fr',
                        display: 'grid',
                        border: 'none',
                        m: 2,
                        gap: 3,
                    }}
                    id="groupType"
                    onChange={(_, value) => formik.setFieldValue('groupType', value)}
                    value={formik.values.groupType}
                    exclusive
                    size="large"
                    color="primary"
                >
                    <ToggleButton
                        value={"Organization"}
                        sx={{
                            outlineWidth: '1px',
                            outlineStyle: 'solid',
                            ml: '-1px !important',
                            outlineColor: '#E2E2E2',
                            p: 3
                        }}
                    >
                        <Grid item xs={4}>
                            <Stack
                                sx={{
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                }}
                            >
                                <GridOn />

                                <Typography sx={{ mt: 1 }} variant="subtitle2">
                                    {translate('commons.organizations')}
                                </Typography>
                            </Stack>
                        </Grid>
                    </ToggleButton>
                    <ToggleButton
                        value={"Customer"}
                        sx={{
                            outlineWidth: '1px',
                            outlineStyle: 'solid',
                            ml: '-1px !important',
                            outlineColor: '#E2E2E2',
                            p: 3
                        }}
                    >
                        <Grid item xs={4}>
                            <Stack
                                sx={{
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                }}
                            >
                                <GridOn />
                                <Typography sx={{ mt: 1 }} variant="subtitle2">
                                    {translate('commons.customer_plural')}
                                </Typography>
                            </Stack>
                        </Grid>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <Divider sx={{ mb: 2 }} />
            <StepButtons returnPage={PATH_DASHBOARD.groups.list} changeStep={changeStep} onSave={formik.handleSubmit} disabled={!(formik.isValid)} />
        </Card>
    );
}