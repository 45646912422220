import { InputAdornment, TextField, TextFieldProps, useTheme } from '@mui/material';
import { isUndefined } from 'lodash';
import { useEffect } from 'react';
import { Controller, ControllerRenderProps, FieldError, FieldErrorsImpl, FieldValues, Merge, useFormContext } from 'react-hook-form';
import { CurrencyTypes } from 'src/@types/vehicle';
import useCurrency from 'src/appHooks/useCurrency';
import { useSettingsContext } from 'src/components/settings';
import { useLocales } from 'src/locales';
import { convert, toMoneySymbol } from 'src/utils/currency';
import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

type IProps = {
    name: string;
    isCustom: boolean;
    currency?: CurrencyTypes | string;
    showPrefix?: boolean;
    setShowPrefix?: (value: boolean) => void;
    onChangeVal?: (value: any) => void;
    setOnEditForm?: (value: boolean) => void;
    onEditForm?: boolean;
};

type Props = IProps & TextFieldProps;

export default function RHFCurrencyTextField({ name, isCustom, currency = '0', showPrefix, setShowPrefix, onChangeVal, setOnEditForm, onEditForm, ...other }: Props) {

    const { control, formState, setValue, getValues } = useFormContext();

    const { currency: currentCurrency } = useSettingsContext();

    const { rates } = useCurrency();

    const { errors } = formState;

    const { translate } = useLocales();

    const theme = useTheme();

    useEffect(() => {
        if (isCustom) {
            const actualValue = getValues("customFields." + other.id);

            const newValue = Math.round(convert(actualValue, CurrencyTypes.EUR, currentCurrency.label, rates));

            setValue(other.id!, newValue);
        }
    }, [currentCurrency]);

    const getError = (error: FieldError | undefined) => {
        if (errors.customFields) {

            const errorNames = Object.keys(errors.customFields as Merge<FieldError, FieldErrorsImpl<any>>);

            return errorNames.includes(name);
        }

        return !!error;
    };

    const onBlurFunc = (event: any) => {
        if (!event.target.value && setShowPrefix) {
            setShowPrefix(false);
        }
        if (onChangeVal) {
            onChangeVal(name);
            if (setOnEditForm) {
                setOnEditForm(false);
            }
        }
    };

    const onFocusFunc = () => {
        if (setShowPrefix) {
            setShowPrefix(true);
        }
        if (setOnEditForm) {
            setOnEditForm(true);
        }
    };

    const handleChange = (field: ControllerRenderProps<FieldValues, string>, newVal: string) => {
        field.onChange(newVal);
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error }, }) => (
                <TextField
                    {...field}
                    {...other}

                    type="number"
                    fullWidth

                    value={other.value}

                    onChange={(event) => {
                        if (event) {

                            if (other.onChange && !isNaN(+event.target.value)) {
                                other.onChange(event);
                            }

                            else if (!isNaN(+event.target.value))
                                handleChange(field, event.target.value);

                        }
                    }}

                    error={getError(error)}
                    helperText={getError(error) && `${translate(other.helperText ? other.helperText : error?.message)}`}
                    sx={{ ...other.sx, '& .MuiInputLabel-asterisk': { color: 'error.main' }, '& .MuiOutlinedInput-root': { '& fieldset': { background: other.disabled ? alpha(theme.palette.grey[300], 0.20) : "", } } }}

                    onFocus={onFocusFunc}
                    onBlur={(event) => onBlurFunc(event)}

                    InputProps={{
                        startAdornment: isUndefined(showPrefix) ?
                            <InputAdornment position="start">{toMoneySymbol(currency)}</InputAdornment>
                            :
                            showPrefix && <InputAdornment position="start">{toMoneySymbol(currency)}</InputAdornment>
                    }}
                />
            )}
        />
    );
}

