import { Box, Divider, SxProps, Theme, Typography } from "@mui/material";
import { RequestItem } from "src/@types/request";
import { useLocales } from "src/locales";

interface ApprovedProductsDetailsProps {
    approvedProducts: RequestItem[],
    hideFirstDivider?: boolean,
    sx?: SxProps<Theme> | undefined
}

export default function ApprovedProductsDetails({ approvedProducts, hideFirstDivider, sx }: ApprovedProductsDetailsProps) {

    const { translate } = useLocales();

    return (
        <Box sx={{ ...sx }}>

            {!hideFirstDivider && <Divider sx={{ mb: 2 }} />}

            <Box
                sx={{
                    display: 'grid',
                    gridAutoColumns: '1fr',
                    gap: 1,
                    textAlign: "center"
                }}
            >
                <Box sx={{ gridRow: '1', gridColumn: 'span 7', textAlign: "left" }}>
                    <Typography variant="overline" color={'#6D6D6D'}>
                        {`${translate('commons.description')}`}
                    </Typography>
                </Box>
                <Box sx={{ gridRow: '1', gridColumn: 'span 1' }}>
                    <Typography variant="overline" color={'#6D6D6D'}>
                        {`${translate('returns.tableHeaders.bigAndHeavy')}`}
                    </Typography>
                </Box>
                <Box sx={{ gridRow: '1', gridColumn: 'span 1' }}>
                    <Typography variant="overline" color={'#6D6D6D'}>
                        {`${translate('returns.tableHeaders.quantity')}`}
                    </Typography>
                </Box>
            </Box>

            <Divider sx={{ my: 2 }} />

            {approvedProducts.map((item) =>
                <Box
                    key={item.product.id}
                    sx={{
                        display: 'grid',
                        gridAutoColumns: '1fr',
                        gap: 1, mb: 2,
                        textAlign: "center"
                    }}
                >
                    <Box sx={{ gridRow: '1', gridColumn: 'span 7', textAlign: "left", fontWeight: "700" }}>
                        {item.product.externalId + " [" + item.product.name + "]"}
                    </Box>
                    <Box sx={{ gridRow: '1', gridColumn: 'span 1' }}>
                        {item.bigAndHeavy ? `${translate('commons.yes')}` : `${translate('commons.no')}`}
                    </Box>
                    <Box sx={{ gridRow: '1', gridColumn: 'span 1' }}>
                        {item.approvedQuantity}
                    </Box>
                </Box>
            )}
        </Box>
    );
}