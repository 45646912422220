import { useLocation, matchPath } from 'react-router-dom';
import { NavListProps } from 'src/components/nav-section';

// ----------------------------------------------------------------------

type ReturnType = {
  active: boolean;
  isExternalLink: boolean;
};

export default function useActiveLink(data: NavListProps, deep = true): ReturnType {
  const { pathname } = useLocation();

  const checkPathAndChildren = (path: string, end: boolean) => {
    return path ? !!matchPath({ path, end }, pathname) : false;
  };

  const globalNormalActive = (data.children) ?
    !!data.children.find((child: any) => {
      return checkPathAndChildren(child.path, true);
    })
    :
    checkPathAndChildren(data.path, true);

  const globalDeepActive = (data.children) ?
    !!data.children.find((child: any) => {
      return checkPathAndChildren(child.path, false);
    })
    :
    checkPathAndChildren(data.path, false);

  // For fixing home path /dashboard active
  if (data.path === "/dashboard") {
    return {
      active: pathname === data.path,
      isExternalLink: data.path.includes('http'),
    };
  }

  return {
    active: deep ? globalDeepActive : globalNormalActive,
    isExternalLink: data.path.includes('http'),
  };
}
