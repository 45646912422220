import { Box, Button, Card, Divider, Grid, Modal, Step, StepLabel, Stepper, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import Page from 'src/appComponents/Page';
import useLocales from 'src/appHooks/useLocales';
import { useSettingsContext } from 'src/components/settings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { dispatch, RootState } from 'src/redux/store';
import { vehicleOperations } from 'src/redux/vehicle';
import LoadingScreen from 'src/appComponents/loading-screen';
import { LogisticsData } from 'src/@types/logistics';
import { logisticStep1Schema, defaultLogisticsData } from './Constant';
import LogisticsNewEditFormContainer from './LogisticsNewEditFormContainer';
import { setSuccessMessage } from 'src/redux/modal/modal-slices';

export default function LogisticsNewEdit() {

    const { themeStretch } = useSettingsContext();

    const isDesktop = useResponsive('up', 'sm');

    const { translate, currentLang } = useLocales();

    const { vehicle, isLogisticsLoading, logistics, isLogisticsFound } = useSelector((state: RootState) => state.vehicle);

    const [closeM, setCloseM] = useState(false);

    const [saveMo, setSaveMo] = useState(false);

    const [step, setStep] = useState<number>(0);

    const STEPS = useMemo(() => {
        return [`${translate('commons.general')}`,
        `${translate("vehicle.logistic.form.ingress")}`,
        `${translate("vehicle.logistic.form.transport")}`,
        `${translate("vehicle.logistic.form.adminMissingEl")}`,
        `${translate("vehicle.logistic.form.techMissElement")}`];
    }, [translate]);

    const navigate = useNavigate();

    const { vehicleId = '' } = useParams();

    useEffect(() => {
        dispatch(vehicleOperations.getVehicleDisplayById(vehicleId));
        dispatch(vehicleOperations.getVehicleLogistics(vehicleId));
    }, [vehicleId, currentLang]);

    const formContainerRef = useRef<{
        getValues: () => LogisticsData,
        validateStep1: () => boolean,
        onSave: () => LogisticsData
    }>();

    const defaultValues = useMemo(() => defaultLogisticsData(logistics as LogisticsData), [logistics]);

    const yupSchema = useMemo(() => logisticStep1Schema, []);

    const methods = useForm<LogisticsData>({
        mode: 'onChange',
        resolver: yupResolver<any>(yupSchema),
        defaultValues
    });

    const { reset } = methods;

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    const handleNextStep = async () => {
        let stepValidationResponse: boolean = true;
        if (step === 0) {
            stepValidationResponse = (await formContainerRef?.current?.validateStep1()) || false;
        }
        if (!stepValidationResponse) {
            return;
        }
        setStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleChangeStep = (newStep: number) => {
        if (isFormValid) {
            setStep(newStep);
        }
    };

    const handlePreviousStep = () => {
        setStep((prevActiveStep) => prevActiveStep - 1);
    };

    const [isFormValid, setIsFormValid] = useState<boolean>(false);

    const [onEditForm, setOnEditForm] = useState<boolean>(false);

    const checkValidity = useCallback(async () => {
        const validate1 = await formContainerRef.current?.validateStep1() || false;

        if (!onEditForm && validate1) {
            setIsFormValid(true);
        } else setIsFormValid(false);

        return validate1;
    }, [onEditForm, formContainerRef]);

    const getCorrectFormValues = (values: LogisticsData) => {
        return {
            vehicleId: vehicleId,
            ingress: values.ingress || [],
            transport: values.transport || [],
            eam: values.eam || [],
            etm: values.etm || [],
            appraisalDate: values.appraisalDate,
            damagesWithVat: values.damagesWithVat,
            damagesWithoutVat: values.damagesWithoutVat
        };
    };

    const handleSave = async () => {
        const isValid = await checkValidity();

        if (isValid) {
            const allValues = await formContainerRef?.current?.onSave();

            if (allValues) {
                const correctValues = getCorrectFormValues(allValues);

                if (isLogisticsFound) {
                    await dispatch(vehicleOperations.updateLogisticsData(correctValues)).unwrap();
                } else {
                    await dispatch(vehicleOperations.createLogisticsData(correctValues)).unwrap();
                }
            }

            dispatch(setSuccessMessage({ text: translate('vehicle.logistic.messages.added'), returnTo: PATH_DASHBOARD.vehicle.logisticView(vehicleId) }));
        }

    };

    useEffect(() => {
        if (!isLogisticsLoading) {
            checkValidity();
        }
    }, [onEditForm, isLogisticsLoading, checkValidity]);

    // called by child form container before submitting values
    const getVehicleFormValues = async (data: LogisticsData) => {
    };

    return (
        <Page title="Vehicle: Add Logistic Data">
            <Container maxWidth={themeStretch ? false : 'lg'} sx={{ p: 0 }}>
                {isLogisticsLoading ? (
                    <LoadingScreen />
                ) : (
                    <>
                        <Modal
                            open={closeM}
                            onClose={() => setCloseM(false)}
                            aria-labelledby="modal-title"
                            aria-describedby="modal-description"
                        >
                            <Card
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: isDesktop ? '45vw' : '90vw',
                                    p: isDesktop ? 5 : 2,
                                }}
                            >
                                <Box>
                                    <Typography variant="h3">{`${translate('commons.quit')}`}</Typography>
                                    <Typography variant="body1" sx={{ my: 3 }}>
                                        {`${translate('vehicle.logistic.messages.return')}`}
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            gap: isDesktop ? 3 : 1,
                                            mt: 3,
                                            flexDirection: isDesktop ? 'row' : 'column-reverse'
                                        }}
                                    >
                                        <Button variant="soft" size={isDesktop ? "small" : 'large'} onClick={() => {
                                            setCloseM(false);
                                        }}>
                                            {`${translate("commons.cancel")}`}
                                        </Button>
                                        <Button variant="contained" size={isDesktop ? "small" : 'large'} onClick={() => { setCloseM(false); navigate(PATH_DASHBOARD.vehicle.logisticView(vehicleId)); }}>
                                            {`${translate("commons.quit")}`}
                                        </Button>
                                    </Box>
                                </Box>
                            </Card>
                        </Modal>
                        <Modal
                            open={saveMo}
                            onClose={() => setSaveMo(false)}
                            aria-labelledby="modal-title"
                            aria-describedby="modal-description"
                        >
                            <Card
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: isDesktop ? '45vw' : '90vw',
                                    p: isDesktop ? 5 : 2,
                                }}
                            >
                                <Box>
                                    <Typography variant="h3">{`${translate('commons.save')}`}</Typography>
                                    <Typography variant="body1" sx={{ my: 3 }}>
                                        {`${translate('vehicle.logistic.messages.save')}`}
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            gap: isDesktop ? 3 : 1,
                                            mt: 3,
                                            flexDirection: isDesktop ? 'row' : 'column-reverse'
                                        }}
                                    >
                                        <Button variant="soft" size={isDesktop ? "small" : 'large'} onClick={() => setSaveMo(false)}>
                                            {`${translate("commons.cancel")}`}
                                        </Button>
                                        <Button variant="contained" size={isDesktop ? "small" : 'large'} onClick={() => { handleSave(); setSaveMo(false); }}>
                                            {`${translate("commons.saveFinish")}`}
                                        </Button>
                                    </Box>
                                </Box>
                            </Card>
                        </Modal>
                        <HeaderBreadcrumbs
                            heading={`${vehicle?.brand} ${vehicle?.model}: ${translate('vehicle.logistic.add')}`}
                            links={[
                                { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                                { name: `${translate('vehicle.title_plural')}`, href: PATH_DASHBOARD.vehicle.list },
                                { name: `${vehicle?.brand} ${vehicle?.model}`, href: PATH_DASHBOARD.vehicle.detail(vehicle?.id) },
                                { name: `${translate('vehicle.logistic.title')}`, href: PATH_DASHBOARD.vehicle.logisticView(vehicle?.id) },
                                { name: STEPS[step] }
                            ]}
                            sx={{ px: isDesktop ? 5 : 2, mb: isDesktop ? 5 : 2 }}
                        />
                        <Stepper sx={{ mb: 2, px: isDesktop ? 5 : 2 }} activeStep={step} alternativeLabel>
                            {STEPS.map((step, ind) => (
                                <Step key={ind}>
                                    <StepLabel onClick={() => handleChangeStep(ind)}>
                                        {isDesktop ? step : ''}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                <Card sx={{ mt: 2 }}>

                                    <LogisticsNewEditFormContainer
                                        outerRef={formContainerRef}
                                        vehicle={vehicle}
                                        step={step}
                                        onSubmitFn={getVehicleFormValues}
                                        onEditForm={onEditForm}
                                        setOnEditForm={setOnEditForm}
                                        formMethods={methods}
                                    />

                                    <Divider />
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            px: isDesktop ? 5 : 2,
                                            py: isDesktop ? 3 : 1,
                                            gap: 1,
                                            flexDirection: isDesktop ? 'row' : 'column-reverse',
                                        }}
                                    >
                                        <Button
                                            variant="soft"
                                            size={isDesktop ? 'small' : 'large'}
                                            onClick={() => setCloseM(true)}
                                        >
                                            {`${translate('vehicle.logistic.back')}`}
                                        </Button>

                                        <Box
                                            sx={{
                                                ml: isDesktop ? 'auto' : 0,
                                                display: 'flex',
                                                gap: 1,
                                                flexDirection: isDesktop ? 'defaul' : 'column-reverse',
                                            }}
                                        >
                                            <Button
                                                color="success"
                                                onClick={() => setSaveMo(true)}
                                                variant="outlined"
                                                size={isDesktop ? 'small' : 'large'}
                                                disabled={!isFormValid}
                                            >
                                                {`${translate('commons.saveFinish')}`}
                                            </Button>

                                            {step > 0 && (
                                                <Button
                                                    variant="contained"
                                                    size={isDesktop ? 'small' : 'large'}
                                                    onClick={handlePreviousStep}
                                                    disabled={!isFormValid}
                                                >
                                                    {`${translate('commons.previous')}`}
                                                </Button>
                                            )}
                                            {step < STEPS.length - 1 && (
                                                <Button
                                                    variant="contained"
                                                    size={isDesktop ? 'small' : 'large'}
                                                    onClick={handleNextStep}
                                                    disabled={!isFormValid}
                                                >
                                                    {`${translate('commons.next')}`}
                                                </Button>
                                            )}
                                        </Box>
                                    </Box>
                                </Card>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Container>
        </Page>
    );
}
