import axios, { AxiosResponse } from "axios";
import { Request, RequestFilters, RequestNew, RequestSearchResult, Statistic, ApproveOptions, RejectOptions, PrintOptions, WarehouseAcceptOptions, CloseAnomalyOptions, ItemFilters, ItemSearchResult, AlertOptions, Alert, RequestUpdate, ClaimOptions } from "src/@types/request";
import { Orders } from "./paths";
import { PagedResponseType } from "src/@types/commons";

const create = (newRequest: RequestNew): Promise<AxiosResponse<Request>> => {
    const url = Orders.admin.returns.create();

    return axios.post<Request>(url, newRequest);
};

const update = (id: string, request: RequestUpdate): Promise<AxiosResponse> => {
    const url = Orders.admin.returns.update(id);

    return axios.put(url, request);
};

const detail = (id: string): Promise<AxiosResponse<Request>> => {
    const url = Orders.admin.returns.detail(id);

    return axios.get(url);
};

const search = (options: { option: RequestFilters, isMine: boolean }): Promise<AxiosResponse<PagedResponseType<RequestSearchResult>>> => {
    const url = options.isMine ? Orders.admin.returns.vendor.search() : Orders.admin.returns.customer.search();

    return axios.get<PagedResponseType<RequestSearchResult>>(url, {
        params: options.option, paramsSerializer: { indexes: null }
    });
};

const searchCarrier = (options: RequestFilters): Promise<AxiosResponse<PagedResponseType<RequestSearchResult>>> => {
    const url = Orders.admin.returns.carrier.search();

    return axios.get(url, { params: options, paramsSerializer: { indexes: null } });
};

const searchCarrierStatistics = (options: RequestFilters): Promise<AxiosResponse<Statistic>> => {
    const url = Orders.admin.returns.carrier.statistics();

    return axios.get(url, { params: options });
};

const searchStatistics = (options: { option: RequestFilters, isMine: boolean }): Promise<AxiosResponse<Statistic>> => {
    const url = options.isMine ? Orders.admin.returns.vendor.statistics() : Orders.admin.returns.customer.statistics();

    return axios.get(url, { params: options.option });
};

const maxQuantity = (options: { orderId: string, productId: string }): Promise<AxiosResponse<{ quantity: number }>> => {
    const url = Orders.admin.returns.maxQuantity();

    return axios.get(url, { params: options });
};

const maxQuantityMissing = (options: { orderId: string, productId: string }): Promise<AxiosResponse<{ quantity: number }>> => {
    const url = Orders.admin.returns.maxQuantityMissing();

    return axios.get(url, { params: options });
};

const remove = (id: string): Promise<AxiosResponse> => {
    const url = Orders.admin.returns.delete(id);

    return axios.delete(url);
};

const approve = (options: ApproveOptions): Promise<AxiosResponse> => {
    const { id, ...option } = options;

    const url = Orders.admin.returns.approve(id);

    return axios.patch(url, option);
};

const rejectByWarehouse = (options: RejectOptions): Promise<AxiosResponse> => {
    const { id, ...option } = options;

    const url = Orders.admin.returns.rejectByWarehouse(id);

    return axios.patch(url, option);
};

const rejectByBackoffice = (options: RejectOptions): Promise<AxiosResponse> => {
    const { id, ...option } = options;

    const url = Orders.admin.returns.rejectByBackoffice(id);

    return axios.patch(url, option);
};

const printed = (options: PrintOptions): Promise<AxiosResponse> => {
    const { id, ...option } = options;

    const url = Orders.admin.returns.printed(id);

    return axios.patch(url, option);
};

const pickedUp = (options: PrintOptions): Promise<AxiosResponse> => {
    const { id, ...option } = options;

    const url = Orders.admin.returns.pickedUp(id);

    return axios.patch(url, option);
};

const warehouseAccept = (options: WarehouseAcceptOptions): Promise<AxiosResponse> => {
    const { id, ...option } = options;

    const url = Orders.admin.returns.warehouseAccept(id);

    return axios.patch(url, option);
};

const detailBarcode = (barcode: string): Promise<AxiosResponse<Request>> => {

    const url = Orders.admin.returns.detailBarcode(barcode);

    return axios.get(url);
};

const refused = (options: RejectOptions): Promise<AxiosResponse> => {

    const { id, ...option } = options;

    const url = Orders.admin.returns.refused(id);

    return axios.patch(url, option);
};

const refusedPrinted = (options: RejectOptions): Promise<AxiosResponse> => {

    const { id, ...option } = options;

    const url = Orders.admin.returns.refusedPrinted(id);

    return axios.patch(url, option);
};

const refusedPickedUp = (options: RejectOptions): Promise<AxiosResponse> => {

    const { id, ...option } = options;

    const url = Orders.admin.returns.refusedPickedUp(id);

    return axios.patch(url, option);
};

const returnToCustomer = (options: RejectOptions): Promise<AxiosResponse> => {
    const { id, ...option } = options;

    const url = Orders.admin.returns.returnToCustomer(id);

    return axios.patch(url, option);
};

const closeAnomaly = (options: CloseAnomalyOptions): Promise<AxiosResponse> => {
    const { id, ...option } = options;

    const url = Orders.admin.returns.closeAnomaly(id);

    return axios.patch(url, option);
};

const searchLogs = (id: string, filters: any): Promise<AxiosResponse<PagedResponseType<any>>> => {
    const url = Orders.admin.returns.searchLogs(id);

    return axios.get<any>(url, { params: filters });
};

const searchVendorItems = (options: ItemFilters): Promise<AxiosResponse<PagedResponseType<ItemSearchResult>>> => {
    const url = Orders.admin.returns.vendor.searchItems();

    return axios.get(url, { params: options });
};

const searchCustomerItems = (options: ItemFilters): Promise<AxiosResponse<PagedResponseType<ItemSearchResult>>> => {
    const url = Orders.admin.returns.customer.searchItems();

    return axios.get(url, { params: options });
};

const getAlert = (params: AlertOptions): Promise<AxiosResponse<Alert>> => {
    const url = Orders.admin.returns.alert();

    return axios.get(url, { params });
};

const approveRestore = (id: string, params: { note: string | null, customFields: Record<string, string> | null }): Promise<AxiosResponse> => {
    const url = Orders.admin.returns.approveRestore(id);

    return axios.patch(url, params);
};

const labelRestore = (id: string, params: { note: string | null, customFields: Record<string, string> | null }): Promise<AxiosResponse> => {
    const url = Orders.admin.returns.labelRestore(id);

    return axios.patch(url, params);
};

const toBeAmended = (id: string, params: { note: string | null, customFields: Record<string, string> | null }): Promise<AxiosResponse> => {
    const url = Orders.admin.returns.toBeAmended(id);

    return axios.patch(url, params);
};

const mediaDelete = (id: string, mediaId: string): Promise<AxiosResponse> => {
    const url = Orders.admin.returns.mediaDelete(id, mediaId);

    return axios.delete(url);
};

const paid = (id: string, params: { note: string | null, customFields: Record<string, string> | null }): Promise<AxiosResponse> => {
    const url = Orders.admin.returns.paid(id);

    return axios.patch(url, params);
};

const openClaim = (options: ClaimOptions): Promise<AxiosResponse> => {
    const { id, ...option } = options;

    const url = Orders.admin.returns.openClaim(id);

    return axios.patch(url, option);
};

const closeClaim = (options: ClaimOptions): Promise<AxiosResponse> => {
    const { id, ...option } = options;

    const url = Orders.admin.returns.closeClaim(id);

    return axios.patch(url, option);
};

const close = (id: string): Promise<AxiosResponse> => {

    const url = Orders.admin.returns.close(id);

    return axios.patch(url);
};

const lost = (id: string): Promise<AxiosResponse> => {

    const url = Orders.admin.returns.lost(id);

    return axios.patch(url);
};

export {
    create,
    detail,
    search,
    searchStatistics,
    maxQuantity,
    remove,
    rejectByWarehouse,
    rejectByBackoffice,
    approve,
    printed,
    pickedUp,
    warehouseAccept,
    detailBarcode,
    returnToCustomer,
    closeAnomaly,
    searchLogs,
    searchVendorItems,
    searchCustomerItems,
    searchCarrier,
    searchCarrierStatistics,
    getAlert,
    maxQuantityMissing,
    refused,
    refusedPickedUp,
    refusedPrinted,
    labelRestore,
    approveRestore,
    update,
    toBeAmended,
    mediaDelete,
    paid,
    openClaim,
    lost,
    close,
    closeClaim
};