import { useEffect } from "react";
import { useNavigate } from "react-router";
import LoadingScreen from "src/appComponents/loading-screen/LoadingScreen";
import { PATH_DASHBOARD } from "src/routes/paths";

export default function MatrixCreateReset() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(PATH_DASHBOARD.matrix.create);
    }, []);

    return (
        <LoadingScreen />
    );
}
