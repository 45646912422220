import { styled } from "@mui/material/styles";
import { bgBlur } from "src/utils/cssStyles";

export const StyledRoot = styled('div')(({ theme }) => ({
    '&:before': {
        ...bgBlur({
            //color: theme.palette.warning.lighter,
            color: theme.palette.primary.darker,
        }),
        top: 0,
        zIndex: 9,
        content: "''",
        width: '100%',
        height: '100%',
        position: 'absolute',
    },
}));

export const StyledInfo = styled('div')(({ theme }) => ({
    left: 0,
    right: 0,
    zIndex: 99,
    position: 'absolute',
    marginTop: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
        right: 'auto',
        display: 'flex',
        alignItems: 'center',
        left: theme.spacing(3),
        bottom: theme.spacing(3),
    },
}));