import { createContext, useEffect, useReducer, useCallback } from 'react';
import { Auth0Client, Auth0ClientOptions } from '@auth0/auth0-spa-js';
import { PATH_DASHBOARD } from '../routes/paths';
import { AUTH0_API } from '../config';
import { ActionMapType, AuthStateType, Auth0ContextType, AuthError } from '../@types/auth';
import { UserManager } from 'src/@types/user';
import userService from 'src/services/userService';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  REFRESH = 'REFRESH',
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    user: UserManager | null;
    status: number | null;
    error: AuthError | null;
  };
  [Types.LOGIN]: {
    user: UserManager;
    status: number | null;
    error: AuthError | null;
  };
  [Types.LOGOUT]: undefined;
  [Types.REFRESH]: {
    user: UserManager;
    status: number | null;
    error: AuthError | null;
  };
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  status: null,
  error: null
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
      status: action.payload.status,
      error: action.payload.error
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
      status: action.payload.status,
      error: action.payload.error
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<Auth0ContextType | null>(null);

// ----------------------------------------------------------------------

let auth0Client: Auth0Client | null = null;

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getAccessToken = useCallback(async () => {
    try {
      const tokenResponse = await auth0Client?.getTokenSilently();

      return tokenResponse as string;
    } catch (error) {
      if (error.error === 'missing_refresh_token') logout();
      throw error;
    }
  }, []);

  const tokenProfile = useCallback(async () => {
    // this is needed because we are not inside the AxiosContextProvider
    const accessToken = await getAccessToken();

    return {
      'Content-Type': 'application/json',
      'X-Muovyti-Tenant': process.env.REACT_APP_CLIENT_TENANT_ID!,
      Authorization: `Bearer ${accessToken}`,
    };
  }, [getAccessToken]);

  const refreshUserProfile = async () => {
    const profiles = await tokenProfile();

    const { data: user, status } = (await userService.profile(profiles));

    dispatch({
      type: Types.REFRESH,
      payload: {
        user,
        status,
        error: null
      },
    });
  };

  const initialize = useCallback(async () => {
    try {
      const aut0Opt: Auth0ClientOptions = {
        clientId: AUTH0_API.clientId || '',
        domain: AUTH0_API.domain || '',
        /*
          Storing tokens in browser local storage provides persistence across page refreshes and browser tabs.
          However, if an attacker can achieve running JavaScript in the SPA using a cross-site scripting (XSS) attack,
          they can retrieve the tokens stored in local storage.
          A vulnerability leading to a successful XSS attack can be either in the SPA source code or in any third-party
          JavaScript code (such as bootstrap, jQuery, or Google Analytics) included in the SPA.
        */
        cacheLocation: 'localstorage',
        useRefreshTokens: true,
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          organization: process.env.REACT_APP_AUTH0_ORGANIZATION,
          ui_locales: 'en',
          scope: 'openid',
        },
      };

      auth0Client = new Auth0Client(aut0Opt);

      const query = window.location.search;

      if (query.includes('code=') && query.includes('state=')) {
        // Process the login state
        await auth0Client.handleRedirectCallback();
      }

      await auth0Client.checkSession();

      const isAuthenticated = await auth0Client.isAuthenticated();

      if (isAuthenticated) {
        const profiles = await tokenProfile();

        const { data: user, status } = (await userService.profile(profiles));

        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated,
            user,
            status,
            error: null
          },
        });
      } else {

        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated,
            user: null,
            status: null,
            error: Object.fromEntries(new URLSearchParams(window.location.search)) as AuthError
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          user: null,
          status: error.response.status,
          error: null
        },
      });
    }
  }, [tokenProfile]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = async () => {
    await auth0Client?.loginWithRedirect({
      authorizationParams: {
        redirect_uri: window.location.origin,
      },
    });

    const isAuthenticated = await auth0Client?.isAuthenticated();

    if (isAuthenticated) {
      const profiles = await tokenProfile();

      try {
        const { data: user, status } = (await userService.profile(profiles));

        window.location.href = PATH_DASHBOARD.root;

        dispatch({
          type: Types.LOGIN,
          payload: {
            user,
            status,
            error: null
          },
        });
      }

      catch (e) {
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated,
            user: null,
            status: e.response.status,
            error: null
          },
        });
      }
    }
  };

  // LOGOUT
  const logout = () => {
    auth0Client?.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

    window.location.href = PATH_DASHBOARD.general.login;
    dispatch({
      type: Types.LOGOUT,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'auth0',
        user: state.user!,
        login,
        logout,
        getAccessToken,
        refreshUserProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
