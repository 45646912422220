import { LocalizedString } from "./commons";

export type VehicleState = {
    isLoading: boolean;
    error: Error | string | null;
    vehicles: {
        byId: Record<string, VehicleSearchResult>;
        allIds: string[];
        totalNumberOfUsers: number;
        filters: VehicleSearchFilters;
    };
};

export type VehicleSearchResult = {
    id: string,
    pictureThumb: string
    pictureLarge: string
    externalId: string
    enabled: boolean
    protocol: string
    brand: string
    vin: string
    plate: string
    model: string
    salesAllocationCountry: string
    color: string
    modelCode: string
    category: string
    km: number
    used: boolean
    catalogPrice: number
    dealerPrice: boolean
    internetPrice: boolean
    transmissionType: string
    engineType: string
    hp: number
    kw: number
    equipments: string[]

}

export type ServiceSearchFilters = {
    pageIndex: number,
    pageSize: number,
    all?: string | null,
    protocol?: string | null,
    brand?: string | null,
    salesAllocationCountry: string | null,
    vin?: string | null,
    plate?: string | null,
    model?: string | null,
    color?: string | null,
    series?: string | null,
    category?: '' | 'Car' | 'OffRoad' | 'Motorbike' | 'CommercialVehicle' | 'Truck' | null,
    priceField?: 'catalogPrice' | 'dealerPrice' | 'internetPrice' | null,
    minPrice?: number | null,
    maxPrice?: number | null,
    minKm?: number | null,
    maxKm?: number | null,
    used: boolean,
    transmission?: string | null,
    engineType?: '' | 'Gasoline' | 'Diesel' | 'Gpl' | 'Methane' | 'Hybrid' | 'HybridPlugin' | 'Electric' | 'Ethanol' | null,
    hp?: string | null,
    kw?: string | null,
    optionals?: string[] | null,
    tags?: string[] | null,
    onlyEnabled?: boolean | null
    onlyDisabled?: boolean | null
    sortField?: string | null
    sortAscending?: boolean | null
}

export type VehicleSearchFilters = {
    [key: string]: any
    used: boolean,
    brand: '',
    salesAllocationCountry: string,
    category?: 'Car' | 'OffRoad' | 'Motorbike' | 'CommercialVehicle' | 'Truck' | '',
    color: '',
    engineType?: 'Gasoline' | 'Diesel' | 'Gpl' | 'Methane' | 'Hybrid' | 'HybridPlugin' | 'Electric' | 'Ethanol' | '',
    hp: null,
    kw: null,
    kmRange: number[],
    kmOnlyRange: number[],
    priceField?: 'catalogPrice' | 'dealerPrice' | 'internetPrice',
    priceRange: number[],
    priceRangeEur: number[],
    model: '',
    onlyEnabled: boolean,
    onlyDisabled: boolean,
    optionals: [],
    protocol: '',
    tags: string[]
}

export type MediaHttpResponse = {
    images: PictureHttpResponse[];
    video: VideoHttpResponse[];
    attaches: AttacheHttpResponse[];
}

export type PictureHttpResponse = {
    thumb: string;
    large: string;
}

export type VideoHttpResponse = {
    thumb: string;
    large: string;
    mediaUrl: string;
}

export type AttacheHttpResponse = {
    thumb: string;
    large: string;
    attachUrl: string;
}

export type Eurotax = {
    blu?: number | null;
    yellow?: number | null;
}

export enum VatTypes {
    Reclaimable = 0,
    NotReclaimable = 1
}

export enum VehicleCategoryTypes {
    Car = 0,
    OffRoad = 1,
    Motorbike = 2,
    CommercialVehicle = 3,
    Truck = 4
}

export enum TrasmissionTypes {
    Front = 0,
    Rear = 1,
    FourWheelDrive = 2
}

export enum GearTypes {
    Manual = 0,
    Automatic = 1
}

export enum EquipmentTypes {
    Standard,
    Pack,
    Optional,
    AfterMarket
}

export enum EmissionTypes {
    Euro0 = 0,
    Euro1 = 1,
    Euro2 = 2,
    Euro3 = 3,
    Euro4 = 4,
    Euro5 = 5,
    Euro6 = 6,
    ZeroEmission = 7,
}

export enum EngineTypes {
    Gasoline = 0,
    Diesel = 1,
    Gpl = 2,
    Methane = 3,
    Hybrid = 4,
    HybridPlugin = 5,
    Electric = 6,
    Ethanol = 7
}

export type Vehicle = {
    id: string | null;
    currency: CurrencyTypes | string;
    externalId: string | null;
    createdOn: string;
    modifiedOn: string | null;
    createdBy: string;
    modifiedBy: string | null;
    measurementSystem: MetricSystems | string | null;
    notes: LocalizedString[];
    enabled: boolean;
    km0: boolean;
    used: boolean;
    energyRating: VehicleEnergyRatingTypes | null | string;
    vendor: string | null;
    matriculationDate: string | null;
    registrationDate: string | null;
    salesAllocationCountry: string | null;
    orderId: string | null;
    brand: string | null;
    model: string | null;
    modelCode: string | null;
    version: string | null;
    carline: string | null;
    drivingSide: DrivingSideTypes | null | string;
    type: VehicleCategoryTypes | null | string;
    category: BodyTypes | null | string;
    technicalData: TechnicalData | null;
    modelDetail: ModelDetail | null;
    body: Body | null;
    catalogPrice: Price | null;
    vin: string | null;
    plate: string | null;
    protocol: string | null;
    origin: string | null;
    km: number | null;
    exterior: Exterior | null;
    interior: Interior | null;
    media: MediaHttpResponse;
    documents: string[];
    integrationEnabled: boolean;
    customFields: CustomField;
    providers: Provider[];
    equipments: Equipment[];
    tags: string[];
    performance: Performance | null;
    pictureThumb?: string;
    pictureLarge?: string;
}

export type ModelDetail = {
    manufacturerCode: string | null;
    modelYear: string;
    endOfProductionYear: number | string;
    beginOfProductionYear: number | string;
}

export type Body = {
    seats: string | null;
    doors: number | null;
}

export type Media = {
    images: { thumb: string; large: string }[];
    video: { thumb: string; large: string; mediaUrl: string }[];
    attaches: { thumb: string; large: string; attachUrl: string; }[]
}

export type Exterior = {
    roofColorDescription: string | null;
    roofColorCode: string | null;
    bodyColorDescription: string | null;
    bodyColorCode: string | null;
    paintType: string | null;
    wheelSet: WheelSet[];
}

export type Equipment = {
    id?: string;
    manufacturerDescription: string | null;
    manufacturerCode: string | null;
    normalizedDescription: string | null;
    normalizedCode: string | null;
    equipmentType: EquipmentTypes | null | string;
    priceWithVat: number | null;
    priceWithoutVat: number | null;
}

export type Interior = {
    colorDescription: string | null;
    colorCode: string | null;
    seatsType: SeatsTypes | null | string;
}

export type Provider = {
    id?: string;
    name: string | null;
    code: string | null;
}

export type Price = {
    equipmentsWithVat: number | null;
    equipmentsWithoutVat: number | null;
    catalogPriceWithVat: number | null;
    catalogPriceWithoutVat: number | null;
    vatValue: number | null;
    vatType: VatTypes | null | string;
    damagesWithVat: number | null;
    damagesWithoutVat: number | null;
    discountWithoutVat: number | null;
    discountWithVat: number | null;
}

export type TechnicalData = {
    transmission: Transmission | null;
    dimensions: Dimensions | null;
    engine: Engine | null;
}

export type Dimensions = {
    height: number | null;
    width: number | null;
    length: number | null;
    wheelbase: number | null;
    trunk: number | null;
    curbWeight: number | null;
}

export type Engine = {
    type: EngineTypes | null | string;
    emissions: EmissionTypes | null | string;
    consumptions: Consumption[];
    thermal: ThermalEnginData | null;
    electric: ElectricEnginData | null;
}

export type ThermalEnginData = {
    cylinders: number | null;
    displacement: number | null;
    kw: number | null;
    hp: number | null;
    fiscalHorsepower: number | null;
    rpm: number | null;
    torque: string | null;
}

export type ElectricEnginData = {
    batteryCapacity: number | null;
    batteryMinimumRatedCapacity: number | null;
    batteryCheckDate: string | null;
    maxACPower: number | null;
    maxDCPower: number | null;
    batteryThreshold: number | null;
    chargeTimeAC: string | null;
    chargeTimeDC: string | null;
    connector: PowerConnectorTypes | null | string;
}

export type Performance = {
    maxSpeed: string | null;
    acceleration: string | null;
}

export type Transmission = {
    gearType: GearTypes | null | string;
    type: TrasmissionTypes | null | string;
    gears: number | null;
}

export type WheelSet = {
    id?: string;
    front: string | null;
    rear: string | null;
    runFlat: boolean;
    tyreTypes: TyreTypes | null | string;
}

export type Consumption = {
    id?: string;
    source: ConsumptionSourceTypes | string | null;
    electric: string | null;
    thermal: string | null;
    details: ConsumptionCycles | null;
    co2Emissions: ConsumptionCycles | null;
}

export type ConsumptionCycles = {
    extremelyHigh: string | null | undefined;
    high: string | null | undefined;
    medium: string | null | undefined;
    low: string | null | undefined;
    combined: string | null | undefined;
}

export type CustomField = {
    [key: string]: string;
}

export enum PowerConnectorTypes {
    CCS,
    Type2
}

export enum TyreTypes {
    Summer = 0,
    Winter = 1,
    FourSeason = 2,
    Studded = 3
}

export enum ConsumptionSourceTypes {
    WLTP = 0,
    NEDC = 1,
    RDE = 2,
    Other = 100
}

export enum SeatsTypes {
    Fabric = 0,
    Leather = 1
}

export enum BodyTypes {
    CityCar = 0,
    Cabrio = 1,
    Coupé = 2,
    SuvOffRoad = 3,
    StationWagon = 4,
    Sedan = 5,
    Minivan = 6,
    Van = 7,
    Other = 100
}

export enum DrivingSideTypes {
    Left,
    Right,
    Central
}

export enum CurrencyTypes {
    EUR = 'EUR',
    GBP = 'GBP',
    USD = 'USD',
    CHF = 'CHF',
    JPY = 'JPY',
    BGN = 'BGN',
    DKK = 'DKK',
    NOK = 'NOK',
    AUD = 'AUD',
    CNY = 'CNY',
    IDR = 'IDR',
    ILS = 'ILS',
    KRW = 'KRW',
    SGD = 'SGD',
    RON = 'RON',
    BRL = 'BRL',
    INR = 'INR',
    MXN = 'MXN',
    MYR = 'MYR',
    PHP = 'PHP',
    THB = 'THB',
    PLN = 'PLN',
    SEK = 'SEK',
    CAD = 'CAD',
    HKD = 'HKD',
    CZK = 'CZK',
    HUF = 'HUF',
    ISK = 'ISK',
    TRY = 'TRY',
    NZD = 'NZD',
    ZAR = 'ZAR'
}

export enum MetricSystems {
    Metric,
    Imperial
}

export enum VehicleEnergyRatingTypes {
    A, B, C, D, E, F, G
}

export type MediaType = 'Image' | 'Audio' | 'Video' | 'Attach';
