import { MenuItem, TableCell, TableRow, Typography } from '@mui/material';
import { OrderAmount, OrderSearchItem, OrderSearchResult } from 'src/@types/orders';
import { useLocales } from 'src/locales';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ReceiptIcon from '@mui/icons-material/Receipt';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import CurrencyAdapter from 'src/appComponents/CurrencyAdapter';
import DateZone from 'src/appComponents/DateZone';
import TableMoreMenu from 'src/appComponents/TableMoreMenu';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { OrganizationPermissionTypes } from 'src/@types/permissions';

type ItemsTableProps = {
    index: number,
    clickedIndex: number | undefined,
    setClickedIndex: (value: number) => void,
    order: OrderSearchResult,
    isVendor: boolean
}

export default function OrdersTableRow({ index, clickedIndex, setClickedIndex, order, isVendor }: ItemsTableProps) {

    const navigate = useNavigate();

    const { deliveryNoteId, deliveryNoteExternalId, invoiceId, invoiceExternalId, items, grossAmount, totalDiscountAmount: discountAmount, netAmount, createdOn } = order;

    const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

    const getQuantity = (items: OrderSearchItem[]) => {
        let total = 0;

        items.forEach((item) => {
            total += item.quantity;
        });

        return total;
    };

    const getPrice = (prices: OrderAmount) => {

        if (prices.withoutVat) {
            return prices.withoutVat;
        }

        if (prices.withVat) {
            return prices.withVat;
        }

        return '—';
    };

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const handleDocumentDetail = (id: string, type: string) => {
        if (isVendor)
            navigate(PATH_DASHBOARD.orders.vendor.documents.detail(id, type));
        else
            navigate(PATH_DASHBOARD.orders.customer.documents.detail(id, type));

    };

    const handleLogs = useCallback((id: string) => {
        if (isVendor)
            navigate(PATH_DASHBOARD.orders.vendor.logs(id));
        else
            navigate(PATH_DASHBOARD.orders.customer.logs(id));
    }, [navigate, isVendor]);

    return (
        <TableRow
            hover
            selected={clickedIndex === index}
            onClick={() => isVendor ?
                navigate(PATH_DASHBOARD.orders.vendor.detail(order.id)) :
                navigate(PATH_DASHBOARD.orders.customer.detail(order.id))
            }
            sx={{
                cursor: 'pointer',
                alignContent: 'flex-start'
            }}
        >

            <TableCell
                align="center"
                onClick={(event) => {
                    event.stopPropagation();
                    clickedIndex === index ? setClickedIndex(-1) : setClickedIndex(index);
                    if (clickedIndex !== index)
                        event.currentTarget.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }}
            >
                {clickedIndex === index ? <KeyboardArrowUpIcon sx={{ pt: 0.9 }} /> : <KeyboardArrowDownIcon sx={{ pt: 1 }} />}
            </TableCell>

            <TableCell>
                {deliveryNoteExternalId ?
                    <Typography variant="subtitle2">{deliveryNoteExternalId}</Typography>
                    :
                    deliveryNoteId ?
                        <NewspaperIcon sx={{ mx: 1.75 }} />
                        :
                        <Typography variant="subtitle2">{"—"}</Typography>

                }
            </TableCell>

            <TableCell >
                {invoiceExternalId ?
                    <Typography variant="subtitle2">{invoiceExternalId}</Typography>
                    :
                    invoiceId ?
                        <ReceiptIcon sx={{ mx: 1.75 }} />
                        :
                        <Typography variant="subtitle2">{"—"}</Typography>

                }
            </TableCell>

            <TableCell>
                <Typography variant="body2">{getQuantity(items)}</Typography>
            </TableCell>

            <TableCell>
                <CurrencyAdapter
                    value={getPrice(grossAmount)}
                    currency={grossAmount.currentCurrency}
                    noVatAsterisk={!!grossAmount.withoutVat}
                    fontSize={"0.875rem"}
                />
            </TableCell>

            <TableCell>
                <CurrencyAdapter
                    value={getPrice(discountAmount)}
                    currency={discountAmount.currentCurrency}
                    noVatAsterisk={!!discountAmount.withoutVat}
                    fontSize={"0.875rem"}
                />
            </TableCell>

            <TableCell>
                <CurrencyAdapter
                    value={getPrice(netAmount)}
                    currency={netAmount.currentCurrency}
                    noVatAsterisk={!!netAmount.withoutVat}
                    fontSize={"0.875rem"}
                />
            </TableCell>

            <TableCell>
                <DateZone
                    date={createdOn}
                    noSeconds
                    shortMonth
                    fontSize={"0.875rem"}
                />
            </TableCell>

            <TableCell onClick={(event) => event.stopPropagation()}>
                <OptionsComponent
                    openMenu={openMenu}
                    handleOpenMenu={handleOpenMenu}
                    handleCloseMenu={handleCloseMenu}
                    handleDocumentDetail={handleDocumentDetail}
                    handleLogs={handleLogs}
                    object={order}
                />
            </TableCell>

        </TableRow >
    );
}

type OptionsComponentProps = {
    openMenu: HTMLElement | null,
    handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void,
    handleCloseMenu: () => void,
    handleDocumentDetail?: (id: string, type: string) => void,
    handleLogs: (id: string) => void,
    object: any
};

function OptionsComponent({ openMenu, handleOpenMenu, handleCloseMenu, handleDocumentDetail, handleLogs, object }: OptionsComponentProps) {

    const { translate } = useLocales();

    return (
        <>
            {(object.invoiceId || object.deliveryNoteId) &&
                <TableMoreMenu
                    open={openMenu}
                    onOpen={(event) => handleOpenMenu(event)}
                    onClose={() => handleCloseMenu()}
                    actions={
                        <>
                            {(handleDocumentDetail && object.invoiceId) &&
                                <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_Orders_CustomerView, OrganizationPermissionTypes.WebShop_Orders_VendorView]}>
                                    <MenuItem
                                        onClick={() => {
                                            handleDocumentDetail(object.invoiceId, "Invoice");
                                            handleCloseMenu();
                                        }}
                                    >
                                        {`${translate("orders.invoiceDetail")}`}
                                    </MenuItem>
                                </PermissionBasedGuard>
                            }

                            {(handleDocumentDetail && object.deliveryNoteId) &&
                                <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_Orders_CustomerView, OrganizationPermissionTypes.WebShop_Orders_VendorView]}>
                                    <MenuItem
                                        onClick={() => {
                                            handleDocumentDetail(object.deliveryNoteId, "DeliveryNote");
                                            handleCloseMenu();
                                        }}
                                    >
                                        {`${translate("orders.deliveryNoteDetail")}`}
                                    </MenuItem>
                                </PermissionBasedGuard>
                            }
                            {handleLogs &&
                                <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_Orders_CustomerViewLogs, OrganizationPermissionTypes.WebShop_Orders_VendorViewLogs]}>
                                    <MenuItem
                                        onClick={() => {
                                            handleLogs(object.id);
                                            handleCloseMenu();
                                        }}
                                    >
                                        {`${translate("commons.logs")}`}
                                    </MenuItem>
                                </PermissionBasedGuard>
                            }
                        </>
                    }
                />
            }
        </>
    );
}