import { Link as RouterLink } from 'react-router-dom';
import { Box, Tooltip, Link, ListItemText } from '@mui/material';
import { useLocales } from '../../../locales';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import Iconify from '../../iconify';
import { NavItemProps } from '../types';
import { StyledItem, StyledIcon, StyledDotIcon } from './styles';
import GodGuard from 'src/guards/GodGuard';
import { clearPageIndexSize as userClearPageIndexSize } from 'src/redux/users/users-slices';
import { dispatch } from 'src/redux/store';
import { documentResetPageIndexSize, orderResetPageIndexSize } from 'src/redux/orders/orders-slices';
import { resetPageIndexSize as requestResetPageIndexSize } from 'src/redux/request/request-slices';
import { resetPageIndexSize as organizationResetPageIndexSize } from 'src/redux/organizations/organizations-slices';
import { resetPageIndexSize as sparePartsResetPageIndexSize } from 'src/redux/spareParts/spareParts-slices';
import { resetPageIndexSize as vehicleResetPageIndexSize } from 'src/redux/vehicle/vehicle-slices';
import { resetPageIndexSize as containerResetPageIndexSize } from 'src/redux/container/container-slices';
import { resetPageIndexSize as reportResetPageIndexSize } from 'src/redux/reports/reports-slices';
import { resetPageIndexSize as groupResetPageIndexSize } from 'src/redux/group/group-slices';
import { resetPageIndexSize as termConditionResetPageIndexSize } from 'src/redux/termCondition/termCondition-slices';
import { resetBasketList, resetWebshopList } from 'src/redux/webshop/webshop-slices';

export const clearList: Record<string, any> = {
  //User list
  "menu.management.user.list": () => { dispatch(userClearPageIndexSize()); },
  //Vehicle list 
  "menu.management.vehicle.list": () => { dispatch(vehicleResetPageIndexSize()); },
  //Spare parts list
  "menu.management.spareParts.list": () => { dispatch(sparePartsResetPageIndexSize()); },
  //Vendor-Customer organizations list
  "menu.management.organizations.list": () => { dispatch(organizationResetPageIndexSize()); },
  //Vendor-Customer orders list
  "menu.management.orders.list": () => { dispatch(orderResetPageIndexSize()); },
  //Vendor-Customer document list
  "menu.management.documents.list": () => { dispatch(documentResetPageIndexSize()); },
  //Vendor-Customer-Carrier-Anomalies requests list
  "menu.management.request.list": () => { dispatch(requestResetPageIndexSize()); },
  "menu.management.anomalies.title": () => { dispatch(requestResetPageIndexSize()); },
  //Containers list
  "menu.management.containers.title": () => { dispatch(containerResetPageIndexSize()); },
  "menu.management.containersTemplate.title": () => { dispatch(containerResetPageIndexSize()); },
  //Groups list
  "menu.management.groups.title": () => { dispatch(groupResetPageIndexSize()); },
  //Term and conditions list
  "menu.management.termsCondition.title": () => { dispatch(termConditionResetPageIndexSize()); },
  //Reports list
  "menu.management.reports.list": () => { dispatch((reportResetPageIndexSize())); },
  //Basket rules list
  "menu.management.basketRules.title": () => { dispatch(resetBasketList()); },
  //Basket rules list
  "menu.management.webshop.title": () => { dispatch(resetWebshopList()); },
  //Templates list
  "menu.management.matrix.templates": () => { dispatch(userClearPageIndexSize()); }, //TODO: () => { gestire quando si rifarà templates matrici
  //Rvmatrix-Requests list
  "menu.management.matrix.matrices": () => { dispatch(userClearPageIndexSize()); }, //TODO: () => { gestire quando si rifarà matrici 
  "menu.management.matrix.requests": () => { dispatch(userClearPageIndexSize()); }
};

export default function NavItem({
  item,
  depth,
  open,
  active,
  isExternalLink,
  ...other
}: NavItemProps) {
  const { translate } = useLocales();

  const { title, path, icon, info, children, disabled, caption, permissions, forGod } = item;

  const subItem = depth !== 1;

  const renderContent = (
    <StyledItem depth={depth} active={active} disabled={disabled} caption={!!caption} {...other}>
      {icon && <StyledIcon>{icon}</StyledIcon>}

      {subItem && (
        <StyledIcon>
          <StyledDotIcon active={active && subItem} />
        </StyledIcon>
      )}

      <ListItemText
        primary={`${translate(title)}`}
        secondary={
          caption && (
            <Tooltip title={`${translate(caption)}`} placement="top-start">
              <span>{`${translate(caption)}`}</span>
            </Tooltip>
          )
        }
        primaryTypographyProps={{
          noWrap: true,
          component: 'span',
          variant: active ? 'subtitle2' : 'body2',
        }}
        secondaryTypographyProps={{
          noWrap: true,
          variant: 'caption',
        }}
      />

      {info && (
        <Box component="span" sx={{ lineHeight: 0 }}>
          {info}
        </Box>
      )}

      {!!children && (
        <Iconify
          width={16}
          icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
          sx={{ ml: 1, flexShrink: 0 }}
        />
      )}
    </StyledItem>
  );

  const renderItem = () => {
    // ExternalLink
    if (isExternalLink)
      return (
        <Link href={path} target="_blank" rel="noopener" underline="none">
          {renderContent}
        </Link>
      );

    // Has child
    if (children) {
      return renderContent;
    }

    // Default
    switch (title) {

    }

    return (
      <Link component={RouterLink} to={path} underline="none" onClick={() => { try { clearList[title](); } catch { } }}>
        {renderContent}
      </Link>
    );
  };

  if (forGod)
    return <GodGuard> {renderItem()} </GodGuard>;

  if (!permissions)
    return renderItem();

  return <PermissionBasedGuard permissions={permissions}> {renderItem()} </PermissionBasedGuard>;
}
