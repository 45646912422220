import { Grid, Typography } from "@mui/material";
import DateZone, { DateZoneProps } from "./DateZone";
import { ConversionType } from "src/@types/metricSystem";
import MetricImperial from "./MetricImperial";
import CurrencyAdapter from "./CurrencyAdapter";
import { CurrencyTypes } from "src/@types/vehicle";
import useResponsive from "src/hooks/useResponsive";

interface GridLabeledTextProps extends DateZoneProps {
    label: string;
    value?: string;
    rightAlign?: string;
    left?: number;
    right?: number;
    valueAsDate?: boolean;
    metricType?: ConversionType;
    currentCurrency?: CurrencyTypes;
}

/**
 * Component used to render a Label and its Value (Ex. Label: Value). 
 * 
 * All various Typography props are accepted and used on both Label and Value.
 * 
 * With valueAsDate you have to use "date" prop from DateZoneProps insted of "value".
 * 
 * @param label             text of the label
 * @param value             of the label
 * @param valueAsDate       renders Value with DateZone component (default: false)
 * @param metricType        renders Value with MetricImperial component (needs a metric system of reference of type ConversionType)
 * @param currentCurrency   renders Value with CurrencyAdapter component (requires the currency in which the data was saved of type CurrencyTypes)
 * @param rightAlign        used to change alignement of Value (default: left)
 * @param left              number of the grid columns used for the Label (default: 6) - (left + right = 12)
 * @param right             number of the grid columns used for the Value (default: 6) - (right + left = 12)
 * 
 */
export const GridLabeledText = ({ label, value, valueAsDate, metricType, currentCurrency, rightAlign, left, right, ...DateZoneProps }: GridLabeledTextProps, { ...TypographyProps }) => {

    const isDesktop = useResponsive('up', 'sm');

    const speedRemover = (text: string) => {

        let textClean = text;

        if (text.includes("km/h")) {
            textClean = text.replace("km/h", "");
        }

        return textClean;
    };

    const generalStyle = { color: 'text.primary', fontWeight: '600', textAlign: `${rightAlign ? rightAlign : "left"}` };

    return (
        <Grid container>

            <Grid item xs={isDesktop ? (left ? left : 6) : 12}>
                <Typography
                    display="flex"
                    sx={{ color: 'text.secondary', fontWeight: '400' }}
                    {...TypographyProps}
                >
                    {`${label}: `}
                </Typography>
            </Grid>

            <Grid item xs={right ? right : 6}>
                {valueAsDate ?
                    <DateZone sx={generalStyle} {...DateZoneProps} variant={TypographyProps.variant} />
                    :
                    <>
                        {(metricType && value && value !== "─") ?
                            <MetricImperial
                                value={Number(speedRemover(value))}
                                type={metricType}
                                display="inline"
                                sx={generalStyle}
                            />
                            :
                            <>
                                {(currentCurrency && value && value !== "─") ?
                                    <CurrencyAdapter
                                        value={value}
                                        currency={currentCurrency}
                                        sx={generalStyle}
                                    />
                                    :
                                    <Typography {...TypographyProps} sx={generalStyle} >
                                        {value}
                                    </Typography>
                                }
                            </>
                        }
                    </>
                }
            </Grid>

        </Grid>
    );
};