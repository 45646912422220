import { createAsyncThunk } from "@reduxjs/toolkit";
import removeEmptyKeys from "src/utils/removeEmptyKeys";
import { CategoriesFilter, SparePartsFilters } from "src/@types/spareParts";
import { startDetailLoading, startLoading, startLogsLoading, startStatisticsLoading } from "./spareParts-slices";
import sparePartsServices from "src/services/sparePartsServices";

const searchVendorStatistic = createAsyncThunk(
    'spareParts/searchVendorStatistic',
    async (options: SparePartsFilters, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await sparePartsServices.searchVendorStatistics(removeEmptyKeys(options));

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const searchVendorSpareParts = createAsyncThunk(
    'spareParts/searchVendorSpareParts',
    async (options: SparePartsFilters, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await sparePartsServices.searchVendor(removeEmptyKeys(options));

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const searchCustomerStatistic = createAsyncThunk(
    'spareParts/searchCustomerStatistic',
    async (options: SparePartsFilters, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await sparePartsServices.searchCustomerStatistics(removeEmptyKeys(options));

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const searchCustomerSpareParts = createAsyncThunk(
    'spareParts/searchCustomerSpareParts',
    async (options: SparePartsFilters, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await sparePartsServices.searchCustomer(removeEmptyKeys(options));

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const searchVendorSparePartsPowerSearch = createAsyncThunk(
    'spareParts/searchVendorSparePartsPowerSearch',
    async (options: { pageIndex: number, pageSize: number, all: string }, { rejectWithValue }) => {
        try {
            const { data } = await sparePartsServices.searchVendor(options);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;

        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const searchCustomerSparePartsPowerSearch = createAsyncThunk(
    'spareParts/searchCustomerSparePartsPowerSearch',
    async (options: { pageIndex: number, pageSize: number, all: string }, { rejectWithValue }) => {
        try {
            const { data } = await sparePartsServices.searchCustomer(options);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;

        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const getSparePart = createAsyncThunk(
    'spareParts/getSparePart',
    async (id: string, { rejectWithValue, dispatch }) => {
        dispatch(startDetailLoading());
        try {
            const { data } = await sparePartsServices.detail(id);

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const enableSparePart = createAsyncThunk(
    'spareParts/enableSparePart', async (id: string, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await sparePartsServices.enable(id);

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const disableSparePart = createAsyncThunk(
    'spareParts/disableSparePart', async (id: string, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await sparePartsServices.disable(id);

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const getSparePartLogs = createAsyncThunk(
    'spareParts/getSparePartsLog', async (options: { id: string, filters: any }, { rejectWithValue, dispatch }) => {
        dispatch(startLogsLoading());
        try {

            const { data } = await sparePartsServices.searchLogs(options.id, options.filters);

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const getProductStatistics = createAsyncThunk(
    'spareParts/getProductStatistic', async (organizationId: string, { rejectWithValue, dispatch }) => {
        dispatch(startStatisticsLoading());
        try {

            const { data } = await sparePartsServices.productStatistic(organizationId);

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    });

const searchRulesCategories = createAsyncThunk(
    'spareParts/searchRulesCategories',
    async (options: CategoriesFilter, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await sparePartsServices.searchCategories(options);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        }
        catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const sparePartsOperations = {
    searchCustomerSpareParts,
    searchCustomerStatistic,
    searchVendorSpareParts,
    searchVendorStatistic,
    getSparePart,
    getSparePartLogs,
    enableSparePart,
    disableSparePart,
    getProductStatistics,
    searchVendorSparePartsPowerSearch,
    searchCustomerSparePartsPowerSearch,
    searchRulesCategories
};

export default sparePartsOperations;
