import { TextField, InputAdornment, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import useResponsive from "src/hooks/useResponsive";

interface IncrementalInputProps {
    disabled?: boolean,
    maxValue?: number,
    onChange: (name: string, val: any) => void,
    defaultValue?: number
}

export default function IncrementalInput({ onChange, defaultValue, disabled, maxValue }: IncrementalInputProps) {

    const [quantity, setQuantity] = useState<number>(defaultValue || 0);

    useEffect(() => {
        setQuantity(defaultValue || 0);
    }, [defaultValue]);

    const isDesktop = useResponsive('up', 'sm');

    return <TextField
        sx={{
            width: isDesktop ? '40%' : '60%',
            background:
                disabled ?
                    ' var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))'
                    : '',
            borderRadius: '8px',
            border: '1px solid var(--components-input-outlined, rgba(145, 158, 171, 0.32))',
        }}
        type="number"
        value={quantity + ''}
        onChange={(e) => {
            let val;
            if (maxValue !== undefined)
                val = +e.target.value < 0 || +e.target.value > maxValue ? 0 : +e.target.value;
            else
                val = +e.target.value < 0 ? 0 : +e.target.value;

            setQuantity(val);
            onChange('quantity', val);
        }}
        inputProps={{ style: { textAlign: 'center' } }}
        disabled={disabled}
        InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <IconButton
                        disabled={disabled}
                        onClick={() => {
                            setQuantity((prev) => (prev > 0 ? prev - 1 : 0));
                            onChange('quantity', quantity > 0 ? quantity - 1 : 0);
                        }}
                    >
                        <RemoveIcon />
                    </IconButton>
                </InputAdornment>
            ),
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton
                        disabled={disabled}
                        onClick={() => {
                            setQuantity((prev) => {
                                if (maxValue !== undefined)
                                    return prev < maxValue ? prev + 1 : maxValue;

                                return prev + 1;
                            });
                            onChange('quantity', (() => {
                                if (maxValue !== undefined)
                                    return quantity < maxValue ? quantity + 1 : maxValue;

                                return quantity + 1;

                            })());
                        }}
                    >
                        <AddIcon />
                    </IconButton>
                </InputAdornment>
            ),
        }}
    />;
}