import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Avatar, Box, Divider, Typography } from '@mui/material';
import Markdown from '../../../../components/markdown';
import InboxDetailToolbar from './InboxDetailToolbar';
import { useSelector } from 'react-redux';
import { dispatch, RootState } from 'src/redux/store';
import { inboxOperations } from 'src/redux/inbox';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------
export const MarkdownStyle = styled('div')(({ theme }) => ({
  '& > p': {
    ...theme.typography.body1,
    marginBottom: theme.spacing(2),
  },
}));
// ----------------------------------------------------------------------

type InboxDetailProps = {
  handleChangeSelect: (messageId: string) => void,
  handleChangeStarred: (checked: boolean, messageId: string) => void,
  handleChangeImportant: (checked: boolean, messageId: string) => void,
  handleMarkRead: (messageId?: string) => void,
  handleMarkUnRead: (messageId?: string) => void,
  handleDelete: (messageId?: string) => void
};

export default function InboxDetail({ handleChangeSelect, handleChangeStarred, handleChangeImportant, handleMarkRead, handleMarkUnRead, handleDelete }: InboxDetailProps) {

  const { messageId } = useParams();

  const { currentLang } = useLocales();

  const { message } = useSelector((state: RootState) => state.inbox);

  const getMessage = useCallback(async () => {
    if (messageId) await dispatch(inboxOperations.getMessageDetail(messageId)).unwrap();
  }, [messageId]);

  useEffect(() => {
    getMessage();
  }, [messageId, currentLang, getMessage]);

  useEffect(() => {
    if (message && message.id === messageId && !message?.read) handleMarkRead(messageId);
  }, [message, handleMarkRead, messageId]);

  const getAvatarText = useCallback((name: string) => {

    if (name.includes("[")) {
      return name.split("]")[1][1];
    }

    return name[0];
  }, []);

  return (
    message &&
    <Box>

      <InboxDetailToolbar
        handleChangeSelect={handleChangeSelect}
        handleChangeStarred={handleChangeStarred}
        handleChangeImportant={handleChangeImportant}
        handleMarkRead={handleMarkRead}
        handleMarkUnRead={handleMarkUnRead}
        handleDelete={handleDelete}
      />

      <Divider />

      <Box sx={{ display: "flex", alignItems: "center", mx: 1.5, my: 1 }}>
        <Avatar
          sx={{
            bgcolor: (theme) => theme.palette.info.light,
            width: 50, height: 50, mr: 2,
            fontWeight: '500'
          }}
          src={message.from.avatar}
        >
          {getAvatarText(message.from.name)}
        </Avatar>

        <Box>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Typography variant="body1" fontWeight={"fontWeightBold"}>
              {message.from.name}
            </Typography>
            {message.from.email &&
              <Typography variant="body1">
                {"<" + (message.from.email) + ">"}
              </Typography>
            }
          </Box>
        </Box>
      </Box>

      <Box sx={{ px: 1.5, my: 1, width: '100%' }}>
        <Typography variant="subtitle2" sx={{ display: "inline-block", whiteSpace: "pre-line" }}>
          <MarkdownStyle>
            <Markdown children={message?.message} />
          </MarkdownStyle>
        </Typography>
      </Box>

      {/*       <Scrollbar sx={{ flexGrow: 1 }}>
        <Box sx={{ p: { xs: 3, md: 5 } }}>
          <MarkdownStyle>
            <Markdown children={message?.message || DEFAULT.message} />
          </MarkdownStyle>
        </Box>
      </Scrollbar> */}

      {/* {isAttached && <InboxDetailAttachments mail={message} />} */}

    </Box>
  );
}
