import { useEffect, useRef, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { RootState } from "src/redux/store";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import useResponsive from "src/hooks/useResponsive";
import { step1Schema, step2Schema, step3Schema, step4Schema } from "./Constants";
import OrganizationStep1 from "./steps/OrganizationStep1";
import OrganizationStep2 from "./steps/OrganizationStep2";
import OrganizationStep3 from "./steps/OrganizationStep3";
import OrganizationStep4 from "./steps/OrganizationStep4";
import OrganizationStep5 from "./steps/OrganizationStep5";
import { GenericOrganizationEditWithFather } from "src/@types/organizations";
import FormProvider from "src/components/hook-form/FormProvider";
import LoadingScreen from "src/appComponents/loading-screen/LoadingScreen";
import { useParams } from "react-router";
import useTenant from "src/appHooks/useTenant";
import OrganizationStepInfo from "./steps/OrganizationStepInfo";
import { CustomField } from "src/@types/customField";
import { convert } from "src/utils/currency";
import { CurrencyTypes } from "src/@types/vehicle";
import useCurrency from "src/appHooks/useCurrency";
import { useSettingsContext } from "src/components/settings";

type Props = {
    outerRef: any;
    organization?: GenericOrganizationEditWithFather | null;
    step: number;
    isEdit: boolean;
    hasType: string;
    onSubmitFn: (data: any) => Promise<void>;
    setOnEditForm: (value: boolean) => void;
    onEditForm: boolean;
    setShowBrand: (value: boolean) => void;
    showBrand: boolean;
    formMethods: UseFormReturn<GenericOrganizationEditWithFather>;
    setErrorInCustom: (value: boolean) => void;
    customContext: CustomField[] | undefined;
};

export default function OrganizationEditForm({
    outerRef,
    isEdit,
    step,
    onSubmitFn,
    setOnEditForm,
    onEditForm,
    setShowBrand,
    showBrand,
    formMethods,
    setErrorInCustom,
    customContext
}: Props) {

    const isDesktop = useResponsive('up', 'sm');

    const { nonEditables, organizationEdit } = useSelector((state: RootState) => state.organizations);

    const { publicBrandList, isLoading: isLoadingBrands } = useSelector((state: RootState) => state.brand);

    const { currency: currentCurrency } = useSettingsContext();

    const { rates } = useCurrency();

    const { id = '' } = useParams();

    const tenant = useTenant();

    const isDisabled = (name: string, other?: boolean, and?: boolean) => {
        let response = false;

        if (id && nonEditables.includes(name)) {
            response = true;
        }
        if (other) {
            if (and) {
                response = response && other;
            } else {
                response = response || other;
            }
        }

        return response;
    };

    const step1Ref = useRef();

    const step2Ref = useRef();

    const step3Ref = useRef();

    const step4Ref = useRef();

    const step5Ref = useRef();

    const { getValues, handleSubmit, watch, setValue, setError, clearErrors } = formMethods;

    const validateStep1 = () => (step1Ref?.current as any)?.validateFields(step1Schema);

    const validateStep2 = () => (step2Ref?.current as any)?.validateFields(step2Schema);

    const validateStep3 = () => (step3Ref?.current as any)?.validateFields(step3Schema);

    const validateStep4 = () => (step4Ref?.current as any)?.validateFields(step4Schema);

    const onSave = () => (step1Ref?.current as any)?.onSave();

    if (!outerRef?.current) {
        outerRef.current = {
            getValues,
            validateStep1,
            validateStep2,
            validateStep3,
            validateStep4,
            onSave
        };
    }

    const type = watch("type");

    useEffect(() => {
        if (type === "Holding" || type === "Company") {
            if (!showBrand) {
                setShowBrand(true);
            }
        } else if (showBrand) {
            setShowBrand(false);
        }
    }, [type]);

    const [tagsFromForm, setTagsFromForm] = useState<any[]>(organizationEdit?.tags || []);

    const [regionsFromForm, setRegionsFromForm] = useState<any[]>(organizationEdit?.regions || []);

    useEffect(() => {
        if (!isEdit) {
            setTagsFromForm([]);
            setRegionsFromForm([]);
        }
    }, [isEdit]);

    useEffect(() => {
        setTagsFromForm(organizationEdit?.tags || []);
        setRegionsFromForm(organizationEdit?.regions || []);
    }, [organizationEdit]);

    const getErrorItem = (name: string, type: "required" | "regEx") => {

        if (customContext) {
            const correctCustom = customContext.find(customField => customField.id === name);

            if (type === "required") {
                return correctCustom!.required;
            } else if (correctCustom!.regEx) {
                return new RegExp(correctCustom!.regEx.slice(1, -1));
            }
        }

        return new RegExp("");

    };

    const [fieldsWithError, setFieldsWithError] = useState<CustomField[]>([]);

    const removeField = (name: string) => {
        const newFields: CustomField[] = [];

        fieldsWithError.forEach(x => {
            if (x.id !== name) {
                newFields.push(x);
            }

            setFieldsWithError(newFields);
        });
    };

    const addField = (name: string) => {

        let field = fieldsWithError.find(customField => customField.id === name);

        if (!field) {
            const newFields: CustomField[] = fieldsWithError;

            const correctCustom = customContext!.find(customField => customField.id === name);

            newFields.push(correctCustom!);
            setFieldsWithError(newFields);
        }
    };

    useEffect(() => {
        if (fieldsWithError.length === 0) {
            setErrorInCustom(false);
        }
    }, [fieldsWithError]);

    const checkRegEx = (name: string, value: any, actualName: string) => {
        const regEx = getErrorItem(name, "regEx") as RegExp;

        if (regEx && value && !regEx.test(value)) {
            if (name === actualName) {
                setError(`customFields.${name}`, { type: 'regExError', message: '' });
            }
            addField(name);
            setErrorInCustom(true);

        } else {
            clearErrors(`customFields.${name}`);
            removeField(name);
        }
    };

    const getCustomFieldType = (id: string) => {

        const customField = tenant.customFields.find(x => x.enabled && x.entityType.toLowerCase() === "organization" && x.id === id);

        return customField?.type;
    };

    const handleCustomFields = (event: any, dateValue?: string, dateFieldName?: string) => {

        let customFieldList = getValues(`customFields`) || {};

        let newVal = "";

        let fieldName = "";

        if (dateValue && dateFieldName) {
            newVal = dateValue;

            fieldName = dateFieldName;

        } else {
            newVal = event.target.type !== "checkbox" ? event.target.value : (event.target.checked ? "enabled" : "disabled");

            if (getCustomFieldType(event.target.id) === "Currency") {
                newVal = Math.round(convert(Number(event.target.value), currentCurrency.label, CurrencyTypes.EUR, rates)).toString();
            }

            fieldName = event.target.name;

        }

        customFieldList[fieldName] = newVal;

        setValue("customFields", customFieldList);

        if (newVal === "" || (event && event.target.type === "checkbox" && !event.target.checked)) {
            addField(fieldName);
        }

        if (fieldsWithError.length > 0) {
            fieldsWithError.forEach((field) => {
                const name = field.id;

                const required = getErrorItem(name, "required") as boolean;

                const value = getValues(`customFields.${name}`);

                if (required) {
                    if (field.type === 'Checkbox') {
                        if (value !== "enabled") {
                            if (name === fieldName) {
                                setError(`customFields.${name}`, { type: 'required', message: '' });
                            }
                            addField(name);
                            setErrorInCustom(true);
                        } else {
                            clearErrors(`customFields.${name}`);
                            removeField(name);
                        }
                    } else if (!value || value === "") {
                        if (name === fieldName) {
                            setError(`customFields.${name}`, { type: 'required', message: '' });
                        }
                        addField(name);
                        setErrorInCustom(true);
                    } else {
                        checkRegEx(name, value, fieldName);
                    }
                } else {
                    checkRegEx(name, value, fieldName);
                }
            });
        }

    };

    return (
        <FormProvider methods={formMethods} onSubmit={handleSubmit(onSubmitFn)}>

            {isLoadingBrands || (isEdit && !organizationEdit) ?
                <LoadingScreen />
                : (
                    <Box sx={{
                        p: isDesktop ? 5 : 2,
                        pr: step === 1 ? 0 : (isDesktop ? 5 : 2)
                    }}>
                        {
                            step === 1 &&
                            <OrganizationStep1
                                outerRef={step1Ref}
                                formMethods={formMethods}
                                isEdit={isEdit}
                                onEditForm={onEditForm}
                                setOnEditForm={setOnEditForm}
                                setTagsFromForm={setTagsFromForm}
                                tagsFromForm={tagsFromForm}
                                setRegionsFromForm={setRegionsFromForm}
                                regionsFromForm={regionsFromForm}
                                isDisabled={isDisabled}
                            />
                        }
                        {
                            step === 2 &&
                            <OrganizationStep2
                                outerRef={step2Ref}
                                formMethods={formMethods}
                                onEditForm={onEditForm}
                                setOnEditForm={setOnEditForm}
                                isDisabled={isDisabled}
                                customFieldsProp={tenant.customFields}
                                handleCustomFields={handleCustomFields}
                                setErrorInCustom={setErrorInCustom}
                                setFieldsWithError={setFieldsWithError}
                            />
                        }
                        {
                            step === 3 &&
                            <OrganizationStep3
                                outerRef={step3Ref}
                                formMethods={formMethods}
                                onEditForm={onEditForm}
                                setOnEditForm={setOnEditForm}
                                isDisabled={isDisabled}
                                customFieldsProp={tenant.customFields}
                                handleCustomFields={handleCustomFields}
                                setErrorInCustom={setErrorInCustom}
                                setFieldsWithError={setFieldsWithError}
                            />
                        }
                        {
                            step === 4 &&
                            <OrganizationStep4
                                outerRef={step4Ref}
                                formMethods={formMethods}
                                onEditForm={onEditForm}
                                setOnEditForm={setOnEditForm}
                                isDisabled={isDisabled}
                                customFieldsProp={tenant.customFields}
                                handleCustomFields={handleCustomFields}
                                setErrorInCustom={setErrorInCustom}
                                setFieldsWithError={setFieldsWithError}
                            />
                        }
                        {
                            step === 5 &&
                            <OrganizationStepInfo
                                outerRef={step3Ref}
                                formMethods={formMethods}
                                onEditForm={onEditForm}
                                setOnEditForm={setOnEditForm}
                                isDisabled={isDisabled}
                                customFieldsProp={tenant.customFields}
                                handleCustomFields={handleCustomFields}
                                setErrorInCustom={setErrorInCustom}
                                setFieldsWithError={setFieldsWithError}
                            />
                        }
                        {
                            step === 6 &&
                            <OrganizationStep5
                                outerRef={step5Ref}
                                formMethods={formMethods}
                                brandList={publicBrandList || []}
                                isDisabled={isDisabled}
                            />
                        }
                    </Box>
                )
            }

        </FormProvider>
    );
}
