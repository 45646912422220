// @mui
import { Typography, Box, useTheme } from '@mui/material';
// components
import Image from '../../components/image';
//
import Carousel from 'react-material-ui-carousel';
import { useAuth } from 'src/appHooks/useAuth';
import { LoadingButton } from '@mui/lab';
import { AuthLogo } from 'src/appComponents/logo';
import useTenant from 'src/appHooks/useTenant';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';

// ----------------------------------------------------------------------
type Props = {
  title?: string;
  illustration?: string;
};

const backgroundImages = [
  '/assets/{0}/001.webp',
  '/assets/{0}/002.webp',
  '/assets/{0}/003.webp',
  '/assets/{0}/004.webp',
  '/assets/{0}/005.webp',
  '/assets/{0}/006.webp',
  '/assets/{0}/007.webp',
  '/assets/{0}/008.webp',
  '/assets/{0}/009.webp',
  '/assets/{0}/010.webp',
  '/assets/{0}/011.webp',
  '/assets/{0}/012.webp',
  '/assets/{0}/013.webp',
  '/assets/{0}/014.webp',
  '/assets/{0}/015.webp'
];

export default function LoginLayout({ illustration, title }: Props) {

  const { login, error, status } = useAuth();

  const { palette } = useTheme();

  const tenant = useTenant();

  const navigate = useNavigate();

  useMemo(() => {

    const envTenant = process.env.REACT_APP_CLIENT_TENANT_ID;

    if (tenant.id !== undefined) {
      for (let i = 0; i < 15; i++) {
        backgroundImages[i] = backgroundImages[i].replace('{0}', tenant.id);
      }
    }

    else if (envTenant !== undefined) {
      for (let i = 0; i < 15; i++) {
        backgroundImages[i] = backgroundImages[i].replace('{0}', envTenant);
      }
    }
  }, [tenant.id]);

  useEffect(() => {

    if (tenant.status === 503) {

      navigate(PATH_DASHBOARD.general.maintenance);

    }

    if (status === 400)
      navigate(PATH_DASHBOARD.general.contactAdmin, { replace: true });

  }, [navigate, status, tenant]);

  return (
    <Box
      sx={{
        height: '100vh',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          zIndex: 9,
          background: '#fff',
          position: 'absolute',
          top: 0,
          right: 0,
          width: 60,
          height: 60,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AuthLogo />
      </Box>

      <Carousel sx={{ height: '100vh' }} indicators={false}>
        {
          backgroundImages.map((item, i) => (
            <Image
              key={i}
              disabledEffect
              alt="smartCar"
              src={item}
              sx={{ height: '100vh', img: { objectFit: 'cover' } }}
            />
          ))}
      </Carousel>
      <Box
        sx={{
          height: '15vh',
          display: 'flex',
          bottom: 0,
          zIndex: 100,
          left: 0,
          position: 'absolute',
          background: palette.background.default,
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            width: '70%',
            p: 3,
            zIndex: 100,
            display: 'grid',
            columnGap: 2,
            rowGap: 1,
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
          }}
        >
          <Typography>{tenant?.title}</Typography>
          {tenant?.company && (
            <>
              <Typography>{tenant?.company?.name}</Typography>
              <Typography>{`${tenant?.company?.address}, ${tenant?.company?.zipcode} ${tenant?.company?.city} - ${tenant?.company?.country}`}</Typography>
            </>
          )}
        </Box>

        <Box
          sx={{
            p: '10px',
            height: '100%',
            width: error?.error_description ? '20%' : '12.37%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {error?.error_description &&
            <Typography sx={{ width: '100%' }} color={'error'}>
              {error.error_description[0].toUpperCase() + error.error_description.slice(1)}
            </Typography>
          }

          <LoadingButton
            fullWidth
            color='primary'
            size="medium"
            type="submit"
            variant="contained"
            sx={{
              boxShadow: error?.error_description ? '0px 0px 15px 7px red' : 'none',
              width: '80%',
              ":hover": {
                boxShadow: error?.error_description ? '0px 0px 15px 7px red' : 'none',
              }
            }}
            onClick={() => login('data', 'data')}
          >
            Login
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
}
