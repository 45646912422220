import { Box, Button, Card, Container, Tab, Tabs, Typography } from '@mui/material';
import Page from 'src/appComponents/Page';
import { useSettingsContext } from 'src/components/settings';
import HeaderBreadcrumbs from '../../../components/custom-breadcrumbs';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocales } from 'src/locales';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'src/redux/store';
import LoadingScreen from 'src/appComponents/loading-screen';
import termConditionOperations from 'src/redux/termCondition/termCondition-operations';
import Label from 'src/components/label';
import useResponsive from 'src/hooks/useResponsive';
import dayjs from 'dayjs';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import { OrganizationPermissionTypes } from 'src/@types/permissions';
import { LocalizedString } from 'src/@types/commons';
import { MarkdownStyle } from 'src/sections/@dashboard/inbox/detail/InboxDetail';
import Markdown from 'src/components/markdown';
import Flag from 'react-world-flags';

export default function TermsConditionDetail() {

    const { allLangs, translate } = useLocales();

    const { themeStretch } = useSettingsContext();

    const isDesktop = useResponsive('up', 'sm');

    const { id, tab } = useParams();

    const navigate = useNavigate();

    const { termCondition, isLoading } = useSelector((state: RootState) => state.terms);

    const getTerm = useCallback(async () => {
        if (id) await dispatch(termConditionOperations.getTermCondition(id));
    }, [id]);

    useEffect(() => {
        getTerm();
    }, [getTerm]);

    const countryFlag = useCallback((value: string) => {

        const currentLang = allLangs.find((lang) => lang.value === value);

        return (currentLang && <Flag width="28" code={currentLang.icon} alt={currentLang.label} />) ?? (<></>);

    }, [allLangs, termCondition]);

    const TABS = useMemo(() => [
        ...(termCondition?.text?.map((term) => (
            {
                value: term.culture,
                label: translate(`commons.languages.${term.culture}`),
                icon: countryFlag(term.culture),
                component: (<TermsCondtionText text={term} />)
            }
        )) ?? [])
    ], [termCondition]);

    const [currentTab, setCurrentTab] = useState(tab ?? "en");

    const onTabChange = useCallback((newValue: string) => {
        if (id) {
            navigate(PATH_DASHBOARD.termsCondition.detailTab(id, newValue));
            setCurrentTab(newValue);
        }
    }, [id, navigate]);

    const handleEdit = () => {
        if (id) navigate(PATH_DASHBOARD.termsCondition.edit(id));
    };

    /*     useEffect(() => {
            if (tab && tab !== currentTab) onTabChange(tab);
        }, [currentTab, onTabChange, tab]); */

    return (
        <Page title={translate('termsCondition.detail')}>

            {(isLoading || !termCondition) ? <LoadingScreen /> :

                <Container maxWidth={themeStretch ? false : 'lg'}>

                    <HeaderBreadcrumbs
                        heading={translate('termsCondition.detail')}
                        links={[
                            { name: translate('commons.home'), href: PATH_DASHBOARD.root },
                            { name: translate('commons.list'), href: PATH_DASHBOARD.termsCondition.list },
                            { name: translate('termsCondition.title_short_singular') }
                        ]}
                        action={
                            <PermissionBasedGuard permissions={[OrganizationPermissionTypes.TermsAndCondition_Edit]}>
                                <Button
                                    variant={"contained"}
                                    onClick={handleEdit}>
                                    {translate('commons.edit')}
                                </Button>
                            </PermissionBasedGuard>
                        }
                    />

                    <Card sx={{ p: 3, height: 155 }}>

                        <Box
                            sx={{
                                display: 'inline-flex',
                                justifyContent: 'flex-start',
                                alignItems: "flex-start",
                                flexDirection: isDesktop ? "row" : "column",
                                gap: 1.5, mb: 1
                            }}
                        >
                            <Box>
                                <Typography variant="h4">
                                    {termCondition.name}
                                </Typography>

                                <Typography variant="body1">
                                    {translate(`commons.createdOn`) + " "}
                                    <b>{dayjs(termCondition.createdOn).format('DD MMM YYYY, HH:mm')}</b>
                                </Typography>

                                <Typography variant="body1">
                                    {translate(`commons.type`) + ": "}
                                    <b>{translate(`termsCondition.form.type.${termCondition.entityType}`)}</b>
                                </Typography>
                            </Box>

                            <Label
                                color={termCondition.enabled ? "success" : "error"}
                                sx={{ textTransform: 'uppercase', ml: 2, mt: 1 }}
                            >
                                {translate(`commons.${termCondition.enabled ? "enabled" : "disabled"}`)}
                            </Label>

                            {termCondition.isDefault &&
                                <Label
                                    color={"warning"}
                                    sx={{ textTransform: 'uppercase', ml: 2, mt: 1 }}
                                >
                                    {translate(`termsCondition.form.default`)}
                                </Label>
                            }
                        </Box>

                        <Box
                            sx={{
                                width: 1,
                                bottom: 0,
                                zIndex: 9,
                                position: 'absolute',
                                bgcolor: 'background.paper',
                                display: "flex !important",
                                justifyContent: "flex-end !important"
                            }}
                        >
                            <Tabs
                                value={currentTab}
                                onChange={(_, newValue) => onTabChange(newValue)}
                                sx={{ pr: { md: 3 } }}
                            >
                                {TABS.map((tab) => (
                                    <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} sx={{ mr: "28px !important" }} />
                                ))}
                            </Tabs>
                        </Box>

                    </Card>

                    {TABS.map((tab) => tab.value === currentTab &&
                        <Box key={tab.value}>
                            {tab.component}
                        </Box>
                    )}

                </Container>
            }
        </Page>
    );
}

//---------------------------------------------------------------
interface TermsCondtionTextProps {
    text: LocalizedString
}

function TermsCondtionText({ text }: TermsCondtionTextProps) {

    return (
        <Card sx={{ p: 3, mt: 2 }}>
            <Typography variant="subtitle2" sx={{ display: "inline-block", whiteSpace: "pre-line" }}>
                <MarkdownStyle>
                    <Markdown children={text.text} />
                </MarkdownStyle>
            </Typography>
        </Card>
    );
}