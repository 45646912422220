import axios, { AxiosResponse } from 'axios';
import { Product } from './paths';
import { PagedResponseType, Tag } from 'src/@types/commons';
import { CategoriesFilter, CategoriesSearchResult, SparePartsFilters, ProductStatistic, SparePart, SparePartSearchResult, Statistic } from 'src/@types/spareParts';
import { CustomFieldSuggest } from 'src/@types/customField';

const searchVendorStatistics = (options: SparePartsFilters): Promise<AxiosResponse<Statistic>> => {
    const url = Product.admin.spareParts.vendor.statistic();

    return axios.get<Statistic>(url, { params: options });
};

const searchVendor = (options: SparePartsFilters): Promise<AxiosResponse<PagedResponseType<SparePartSearchResult>>> => {
    const url = Product.admin.spareParts.vendor.search();

    return axios.get<PagedResponseType<SparePartSearchResult>>(url, { params: options });
};

const searchCustomerStatistics = (options: SparePartsFilters): Promise<AxiosResponse<Statistic>> => {
    const url = Product.admin.spareParts.customer.statistic();

    return axios.get<Statistic>(url, { params: options });
};

const searchCustomer = (options: SparePartsFilters): Promise<AxiosResponse<PagedResponseType<SparePartSearchResult>>> => {
    const url = Product.admin.spareParts.customer.search();

    return axios.get<PagedResponseType<SparePartSearchResult>>(url, { params: options });
};

const searchCategories = (options: CategoriesFilter): Promise<AxiosResponse<PagedResponseType<CategoriesSearchResult>>> => {

    const url = Product.admin.spareParts.searchCategories();

    return axios.get<PagedResponseType<CategoriesSearchResult>>(url, { params: options, paramsSerializer: { indexes: null } });
};

const detail = (id: string): Promise<AxiosResponse<SparePart>> => {
    const url = Product.admin.spareParts.detail(id);

    return axios.get(url);
};

const enable = (id: string): Promise<AxiosResponse> => {
    const url = Product.admin.spareParts.enable(id);

    return axios.patch(url);
};

const disable = (id: string): Promise<AxiosResponse> => {
    const url = Product.admin.spareParts.disable(id);

    return axios.patch(url);
};

const searchLogs = (id: string, filters: any): Promise<AxiosResponse<PagedResponseType<any>>> => {
    const url = Product.admin.spareParts.searchLogs(id);

    return axios.get<any>(url, { params: filters });
};

const searchVendorTags = (tag: string, size: number): Promise<AxiosResponse<Tag[]>> => {
    const url = Product.admin.spareParts.vendor.searchTags();

    return axios.get<Tag[]>(url, {
        params: {
            query: tag,
            limit: size
        }
    });
};

const searchCustomerTags = (tag: string, size: number): Promise<AxiosResponse<Tag[]>> => {
    const url = Product.admin.spareParts.customer.searchTags();

    return axios.get<Tag[]>(url, {
        params: {
            query: tag,
            limit: size
        }
    });
};

const productStatistic = (organizationId: string): Promise<AxiosResponse<ProductStatistic>> => {
    const url = Product.admin.spareParts.productStatistic(organizationId);

    return axios.get<ProductStatistic>(url);
};

const customFieldSuggest = (options: CustomFieldSuggest): Promise<AxiosResponse<any>> => {
    const url = Product.admin.spareParts.customFieldSuggest();

    return axios.get<any>(url, { params: options });
};

const defaultExport = {
    searchCustomer,
    searchCustomerStatistics,
    searchCustomerTags,
    searchVendor,
    searchVendorStatistics,
    searchVendorTags,
    detail,
    enable,
    disable,
    searchLogs,
    productStatistic,
    searchCategories,
    customFieldSuggest
};

export default defaultExport;