import { Button } from '@mui/material';
import Iconify from 'src/components/iconify';
import { useLocales } from 'src/locales';
import { RootStyle, FilterChip, MultipleFilterChip, CustomFieldsFilterChip } from 'src/utils/SummaryUtils';
import { DEFAULT_ORDERS_FILTERS, OrderFilters } from 'src/@types/orders';

type Props = {
    filters: OrderFilters,
    isShowReset: boolean,
    onResetAll: VoidFunction,
    onResetFilter: (el: any, value?: any) => void,
    show: boolean
}

export default function OrdersFilterSummary({ filters, isShowReset, onResetAll, onResetFilter, show }: Props) {

    const { translate } = useLocales();

    const { prettyName, documentExternalId, itemName, itemExternalId, tags, customer, customerId, customFields } = filters;

    return (
        <RootStyle sx={{ mb: 2, ml: 2 }}>
            {show &&
                <>
                    {documentExternalId !== DEFAULT_ORDERS_FILTERS.documentExternalId &&
                        < FilterChip
                            el={"orders.filter.documentExternalId"}
                            value={documentExternalId!}
                            onResetFilter={onResetFilter}
                        />
                    }

                    {itemName !== DEFAULT_ORDERS_FILTERS.itemName &&
                        < FilterChip
                            el={"orders.filter.itemName"}
                            value={itemName!}
                            onResetFilter={onResetFilter}
                        />
                    }

                    {itemExternalId !== DEFAULT_ORDERS_FILTERS.itemExternalId &&
                        < FilterChip
                            el={"orders.filter.itemExternalId"}
                            value={itemExternalId!}
                            onResetFilter={onResetFilter}
                        />
                    }

                    {prettyName !== DEFAULT_ORDERS_FILTERS.prettyName &&
                        < FilterChip
                            el={"orders.filter.prettyName"}
                            value={prettyName!}
                            onResetFilter={onResetFilter}
                        />
                    }

                    {customerId !== DEFAULT_ORDERS_FILTERS.customerId &&
                        < FilterChip
                            el={"orders.filter.customerId"}
                            value={customer!.name!}
                            onResetFilter={onResetFilter}
                        />
                    }

                    {(tags && tags.length > 0) &&
                        < MultipleFilterChip
                            el={"commons.tags"}
                            value={tags!}
                            onResetFilter={onResetFilter}
                        />
                    }

                    {customFields && Object.entries(customFields).length > 0 &&
                        <CustomFieldsFilterChip
                            context={"order"}
                            customfieldValues={customFields}
                            onResetFilter={onResetFilter}
                        />
                    }

                    {isShowReset && (
                        <Button
                            color="error"
                            size="small"
                            onClick={onResetAll}
                            startIcon={<Iconify icon={'ic:round-clear-all'} />}
                        >
                            {`${translate("commons.clear")}`}
                        </Button>
                    )}
                </>
            }
        </RootStyle >
    );
}
