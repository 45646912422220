import { ClusterPermissionTypes, OrganizationPermissionTypes } from "src/@types/permissions";

export function lokalizableRoleStr(str: string): string {
    const newStr = str.trim().replace(/_/g, '');

    return newStr[0].toLowerCase() + newStr.slice(1);
}

export const getOrgKey = (enumValue: OrganizationPermissionTypes) => {
    return Object.keys(OrganizationPermissionTypes)
        .find((x) => OrganizationPermissionTypes[x as keyof typeof OrganizationPermissionTypes] === enumValue)!;
};

export const getAdminKey = (enumValue: ClusterPermissionTypes) => {
    return Object.keys(ClusterPermissionTypes)
        .find((x) => ClusterPermissionTypes[x as keyof typeof ClusterPermissionTypes] === enumValue)!;
};