// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, TextFieldProps, useTheme } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { alpha } from '@mui/material/styles';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
    name: string,
    label?: string,
    placeholder?: string,
    prefix?: string,
    suffix?: string,
    allowNegative?: boolean,
    onChangeVal?: (value: any) => void,
    setOnEditForm?: (value: boolean) => void,
    onEditForm?: boolean,
    decimalSeparator?: string
};

export default function RHFNumericFormat({ name, label, placeholder, prefix, suffix, onChangeVal, setOnEditForm, onEditForm, allowNegative, decimalSeparator, ...other }: Props) {
    const { control, setValue } = useFormContext();

    const theme = useTheme();

    const { translate } = useLocales();

    const onBlurFunc = () => {
        if (onChangeVal) {
            onChangeVal(name);
            if (setOnEditForm) {
                setOnEditForm(false);
            }
        }
    };

    const onFocusFunc = () => {
        if (setOnEditForm) {
            setOnEditForm(true);
        }
    };

    return (
        <Controller
            name={name}
            control={control}
            disabled={other.disabled}
            render={({ field, fieldState: { error } }) => (
                <NumericFormat
                    value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
                    label={label}
                    prefix={prefix}
                    disabled={other.disabled}
                    suffix={suffix}
                    required={other.required}
                    decimalScale={2}
                    decimalSeparator={decimalSeparator || "."}
                    helperText={error && `${translate(error?.message)}`}
                    error={!!error}
                    placeholder={placeholder}
                    onValueChange={(values) => {
                        setValue(name, values?.floatValue || 0);
                    }}
                    customInput={TextField}
                    sx={{ ...other.sx, '& .MuiInputLabel-asterisk': { color: 'error.main' }, '& .MuiOutlinedInput-root': { '& fieldset': { background: other.disabled ? alpha(theme.palette.grey[300], 0.20) : "", } } }}
                    allowNegative={allowNegative}

                    onFocus={() => onFocusFunc()}
                    onBlur={() => onBlurFunc()}
                />
            )}
        />
    );
}
