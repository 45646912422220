import { Card, Box, useTheme, alpha, Chip, Typography, Divider } from "@mui/material";
import { useLocales } from "src/locales";
import StarsIcon from '@mui/icons-material/Stars';
import useResponsive from "src/hooks/useResponsive";
import InfoCard from "src/utils/InfoCard";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import DetailPermissionsList from "src/utils/DetailPermissionsList";
import Label from "src/components/label";
import { useNavigate } from "react-router";
import { PATH_DASHBOARD } from "src/routes/paths";
import { FeeStyle, FeeTypographyStyle } from "src/components/request-utils/FeesAndNotes";
import { InfoIcon } from "src/theme/overrides/CustomIcons";

export default function GroupGeneralDetail() {

    const { translate } = useLocales();

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'sm');

    const theme = useTheme();

    const { group } = useSelector((state: RootState) => state.group);

    const nameCleaner = (name: string) => {
        return name.split('-').slice(3).join(" ");
    };

    return (group &&
        <Box>

            {group.status !== "Active" && <CreateOrEditAlert isCreated={group.status === "Creating"} />}

            <Box
                sx={{
                    my: 3,
                    display: 'flex',
                    flexDirection: isDesktop ? 'row' : 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 3,
                    flexShrink: 0
                }}
            >
                <InfoCard
                    label={translate('groups.detail.totalUsers')}
                    value={group.status === "Active" ? group.numberOfUsers : "─"}
                    icon={<StarsIcon fontSize="medium" color="info" />}
                    iconColor={theme.palette.info.lighter}
                    onClickFunc={group.status === "Active" ? () => navigate(PATH_DASHBOARD.groups.detailsTab(group.groupId, "users")) : undefined}
                />

                <InfoCard
                    label={translate('groups.detail.totalOrganizations')}
                    value={group.status === "Active" ? group.numberOfOrganizations : "─"}
                    icon={<StarsIcon fontSize="medium" color="info" />}
                    iconColor={theme.palette.info.lighter}
                    onClickFunc={group.status === "Active" ? () => navigate(PATH_DASHBOARD.groups.detailsTab(group.groupId, "organizations")) : undefined}
                />

                <InfoCard
                    label={translate('groups.detail.whitelist')}
                    value={group.status === "Active" ? group.whitelistCount : "─"}
                    icon={<StarsIcon fontSize="medium" color="info" />}
                    iconColor={theme.palette.info.lighter}
                    onClickFunc={group.status === "Active" ? () => navigate(PATH_DASHBOARD.groups.detailsTab(group.groupId, "whitelist")) : undefined}
                />

                <InfoCard
                    label={translate('groups.detail.blacklist')}
                    value={group.status === "Active" ? group.blacklistCount : "─"}
                    icon={<StarsIcon fontSize="medium" color="info" />}
                    iconColor={theme.palette.info.lighter}
                    onClickFunc={group.status === "Active" ? () => navigate(PATH_DASHBOARD.groups.detailsTab(group.groupId, "blacklist")) : undefined}
                />
            </Box>

            <Card sx={{ flexGrow: 1, p: 3 }}>

                <Typography variant="h5">
                    {`${translate('groups.form.property_plural')}`}
                </Typography>

                {group.propertyDependency.properties.map((property, index) =>
                    <Box key={"property." + index} sx={{ my: 2, display: "flex", alignItems: "center" }}>
                        <Typography variant="body1" width={"33%"}>
                            <b>{property.category}</b>
                            {": " + nameCleaner(property.name)}
                        </Typography>
                        <>
                            {property.values.map((value: string, index) =>
                                <Chip
                                    sx={{ m: '5px', "& .MuiChip-label": { fontSize: 14 }, bgcolor: (theme) => alpha(theme.palette.primary.light, 0.4) }}
                                    key={"filter.object.key." + index + "." + value}
                                    label={value}
                                />
                            )}
                        </>
                    </Box>
                )}

                <Divider sx={{ my: 3 }} />

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="h5">
                        {`${translate('notificationTemplate.form.permission_plural')}`}
                    </Typography>

                    {group.permissionDependency.atLeastOne &&
                        <Label color={"primary"} sx={{ mr: 3 }}>
                            {`${translate('notificationTemplate.form.atLeastOne')}`}
                        </Label>
                    }
                </Box>

                <DetailPermissionsList
                    list={group.permissionDependency.permissions}
                />

            </Card>
        </Box>
    );
}

//---------------------------------------------------------------------------------------

interface CreateOrEditAlertProps {
    isCreated: boolean
}

function CreateOrEditAlert({ isCreated }: CreateOrEditAlertProps) {

    const { translate } = useLocales();

    return (
        <Box sx={{ bgcolor: (theme) => theme.palette.warning.lighter, ...FeeStyle }}>
            <Typography sx={{ ...FeeTypographyStyle }}>
                <InfoIcon color="warning" />
                {translate(`groups.messages.${isCreated ? "create" : "update"}`)}
            </Typography>
        </Box>
    );
}