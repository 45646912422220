import { useEffect, useState } from 'react';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import { Box, Divider, Drawer, Stack, Typography, Tooltip, IconButton } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// config
import { NAV } from '../../../config';
//
import Iconify from '../../iconify';
//
import { defaultSettings } from '../config';
import { useSettingsContext } from '../SettingsContext';
import Block from './Block';
import BadgeDot from './BadgeDot';
import ModeOptions from './ModeOptions';
import LayoutOptions from './LayoutOptions';
import StretchOptions from './StretchOptions';
import ContrastOptions from './ContrastOptions';
import DirectionOptions from './DirectionOptions';
import FullScreenOptions from './FullScreenOptions';
import ColorPresetsOptions from './ColorPresetsOptions';

import { dispatch, useSelector } from '../../../redux/store';
import { setOpen as setStateOpen } from '../../../redux/slices/settings';
import useLocales from 'src/appHooks/useLocales';

// ----------------------------------------------------------------------

const SPACING = 2.5;

export default function SettingsDrawer() {
  const {
    themeMode,
    themeLayout,
    themeStretch,
    themeContrast,
    themeDirection,
    themeColorPresets,
    onResetSetting,
  } = useSettingsContext();

  const { translate } = useLocales();

  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const openState = useSelector((state) => state.settings.isOpen);

  useEffect(() => {
    setOpen(openState);
  }, [openState]);

  const handleClose = () => {
    dispatch(setStateOpen(false));
  };

  const notDefault =
    themeMode !== defaultSettings.themeMode ||
    themeLayout !== defaultSettings.themeLayout ||
    themeStretch !== defaultSettings.themeStretch ||
    themeContrast !== defaultSettings.themeContrast ||
    themeDirection !== defaultSettings.themeDirection ||
    themeColorPresets !== defaultSettings.themeColorPresets;

  return (
    <>
      {
        //!open && <ToggleButton open={open} notDefault={notDefault} onToggle={handleToggle} />
      }

      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        slotProps={{ backdrop: { invisible: true } }}
        PaperProps={{
          sx: {
            ...bgBlur({ color: theme.palette.background.default, opacity: 0.9 }),
            width: NAV.W_BASE,
            boxShadow: (theme) =>
              `-24px 12px 40px 0 ${alpha(
                theme.palette.mode === 'light'
                  ? theme.palette.grey[500]
                  : theme.palette.common.black,
                0.16
              )}`,
          },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ py: 2, pr: 1, pl: SPACING }}
        >
          <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
            {`${translate('commons.settings')}`}
          </Typography>

          <Tooltip title="Reset">
            <Box sx={{ position: 'relative' }}>
              {notDefault && <BadgeDot />}
              <IconButton onClick={onResetSetting}>
                <Iconify icon="ic:round-refresh" />
              </IconButton>
            </Box>
          </Tooltip>

          <IconButton onClick={handleClose}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: SPACING, pb: 0 }}>
          <Block title={`${translate('userViewSettings.mode')}`}>
            <ModeOptions />
          </Block>

          <Block title={`${translate('userViewSettings.contrast')}`}>
            <ContrastOptions />
          </Block>

          <Block title={`${translate('userViewSettings.direction')}`}>
            <DirectionOptions />
          </Block>

          <Block title={`${translate('userViewSettings.layout')}`}>
            <LayoutOptions />
          </Block>

          <Block title={`${translate('userViewSettings.stretch')}`} tooltip={`${translate('userViewSettings.messages.stretchTooltip')}`}>
            <StretchOptions />
          </Block>

          <Block title={`${translate('userViewSettings.presets')}`}>
            <ColorPresetsOptions />
          </Block>
        </Box>

        <Box sx={{ p: SPACING, pt: 0 }}>
          <FullScreenOptions />
        </Box>
      </Drawer>
    </>
  );
}
