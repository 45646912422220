import { Box, Card, Tab, Tabs, Typography, alpha, useTheme } from "@mui/material";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { Brand } from "src/@types/brand";
import { CustomField } from "src/@types/customField";
import { LabeledText } from "src/appComponents/LabeledText";
import useTenant from "src/appHooks/useTenant";
import { useLocales } from "src/locales";

interface DetailProps {
    brand: Brand
}

export default function BrandGeneralDetail({ brand }: DetailProps) {
    const [value, setValue] = useState(0);

    const [parsedField, setParsedField] = useState<{ id: string, name: string, value: string }[]>([]);

    const { translate, currentLang } = useLocales();

    const theme = useTheme();

    const handleChange = (e: SyntheticEvent, newVal: number) => {
        setValue(newVal);
    };

    const { customFields } = useTenant();

    const getLang = (field: CustomField) => {

        let tmp = field.name.find((f) => f.culture === currentLang.value);

        return tmp ? tmp.text : field.name[0].text;
    };

    useEffect(() => {
        let field: { id: string, name: string, value: string }[] = [];
        Object.entries(brand.customFields!).forEach(([id, val]) => {
            const x = customFields.find(x => x.id === id);

            let tmp = {
                id: id,
                value: val,
                name: x && (getLang(x) || '-') || '-'
            };
            field.push(tmp);
        });
        setParsedField(field);
    }, []);

    const TABS = useMemo(
        () => [
            {
                value: 0,
                label: `${translate('commons.moreDetail')}`,
                component: <>
                    <Typography variant="h6">{`${translate('commons.moreDetail')}`}</Typography>
                    <Box
                        sx={{
                            mt: 1,
                            display: 'grid',
                            columnGap: 8,
                            rowGap: 3,
                            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                        }}
                    >
                        <LabeledText label='Brand ID' text={brand.externalId || '-'} />
                        {parsedField.map(x => (
                            <LabeledText key={x.id} label={x.name} text={x.value || '-'} />
                        ))}
                    </Box>
                </>,
            }
        ],
        [translate]
    );

    return (
        <Card>
            <Box sx={{ flexGrow: 1, display: 'flex', height: 224 }}>

                <Tabs
                    orientation='vertical'
                    scrollButtons={false}
                    value={value}
                    onChange={handleChange}
                    sx={{
                        bgcolor: alpha(theme.palette.grey[500], 0.12),
                    }}
                    TabIndicatorProps={{
                        style: { display: 'none' }
                    }}
                    textColor={"primary"}
                >
                    {TABS.map((tab) => (
                        <Tab
                            sx={{
                                justifyContent: 'flex-start',
                                py: '13px',
                                px: 2,
                                m: 1,
                                borderRadius: '8px',
                                backgroundColor: value === tab.value ? 'primary.lighter' : '',
                                '&:not(:last-of-type)': { mr: 1 },
                                "&.Mui-selected": {
                                    color: "primary.main"
                                }
                            }}
                            key={tab.value}
                            value={tab.value}
                            label={tab.label}
                        />
                    ))}
                </Tabs>

                {TABS.map(
                    (tab) =>
                        tab.value === value && (
                            <Box sx={{ p: 3, bgcolor: 'background.paper' }} key={tab.value}>
                                {tab.component}
                            </Box>
                        )
                )}

            </Box>

        </Card>
    );

}
