import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IrvMatrixPreview, MatrixTemplateSearchResult, UserMatrixTemplateDocument, RvMatrixSearchResult, MatrixStatus, PNO, PNOPrices } from 'src/@types/matrix';
import { LogsData } from "../../@types/logs";
import matrixOperations from './matrix-operations';
import { ErrorResponse } from 'src/@types/commons';

interface MatrixReducer {
    matrixLogs: LogsData;
    isMatrixLoGsLoading: boolean;
    powersearchMatrixResults: any;
    powersearchMatrixTemplateResults: any;
    matrixStatuses: MatrixStatus;
    widgetStatuses: MatrixStatus;
    matrix: UserMatrixTemplateDocument | null;
    matrixForEdit: UserMatrixTemplateDocument | null;
    matrixTemplates: MatrixTemplateSearchResult[];
    rvMatrixes: RvMatrixSearchResult[];
    userMatrixes: any[];
    rvMatrixPreview: IrvMatrixPreview | null;
    rvMatrixPreviewPrice: IrvMatrixPreview | null;
    selectedMatrixesForApprove: IrvMatrixPreview[];
    watchMode: string;
    pageIndex: number;
    pageSize: number;
    totalCount: number;
    isMatrixPowersearchLoading: boolean;
    isTemplatePowersearchLoading: boolean;
    isLoading: boolean;
    isSuccess: boolean | string;
    error: ErrorResponse;
    isEditMode: boolean;
    PNO: PNO | null;
    PNOPrices: PNOPrices[] | null;
    excelLink: string | null;
    isLoadingTemplate: boolean;
    isStatusesLoading: boolean;
    isFilteredMatrixesLoading: boolean;
    isTemplateLoading: boolean;
}

const DEFAULT_STATUSES = {
    "draft": 0,
    "rejected": 0,
    "approved": 0,
    "pending": 0,
    "total": 0
};

const initialState: MatrixReducer = {
    matrixLogs: {
        pageIndex: 0,
        pageSize: 0,
        totalCount: 0,
        hasNextPage: false,
        hasPreviousPage: false,
        totalPages: 0,
        items: []
    },
    isMatrixLoGsLoading: false,
    isMatrixPowersearchLoading: false,
    isTemplatePowersearchLoading: false,
    powersearchMatrixResults: [],
    powersearchMatrixTemplateResults: [],
    matrixStatuses: DEFAULT_STATUSES,
    widgetStatuses: DEFAULT_STATUSES,
    matrix: null,
    matrixForEdit: null,
    matrixTemplates: [],
    userMatrixes: [],
    isStatusesLoading: false,
    rvMatrixes: [],
    selectedMatrixesForApprove: [],
    isLoadingTemplate: false,
    pageIndex: 0,
    pageSize: 0,
    rvMatrixPreview: null,
    rvMatrixPreviewPrice: null,
    watchMode: 'percent',
    totalCount: 0,
    isLoading: false,
    isSuccess: false,
    error: null,
    isEditMode: false,
    PNO: null,
    PNOPrices: null,
    excelLink: null,
    isFilteredMatrixesLoading: false,
    isTemplateLoading: false,
};

const MatrixSlice = createSlice({
    name: 'matrix',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(matrixOperations.createMatrixTemplate.fulfilled, (state, action) => {
                state.matrix = action.payload;
                state.isSuccess = 'Matrix Template Created';
            })
            .addCase(matrixOperations.createMatrixTemplate.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as ErrorResponse;
            })
            .addCase(matrixOperations.createRvMatrix.fulfilled, (state, action) => {
                state.isSuccess = 'RvMatrix Created';
            })
            .addCase(matrixOperations.approveRvMatrix.fulfilled, (state, action) => {
                state.isSuccess = 'RvMatrix Approved';
            })
            .addCase(matrixOperations.approveRvMatrix.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as ErrorResponse;
            })
            .addCase(matrixOperations.getPNO.fulfilled, (state, action) => {
                state.isLoading = false;
                state.PNO = action.payload.pnos;
                state.rvMatrixes = action.payload.matrixes.items;
            })
            .addCase(matrixOperations.getPNO.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(matrixOperations.getPNO.rejected, (state, action) => {
                state.isLoading = false;
                state.PNO = initialState.PNO;
                state.rvMatrixes = initialState.rvMatrixes;
                state.error = action.error.message as string;
            })
            .addCase(matrixOperations.getPNOOnly.fulfilled, (state, action) => {
                state.PNO = action.payload.pnos;
            })
            .addCase(matrixOperations.getPNOPrices.fulfilled, (state, action) => {
                state.isLoading = false;
                state.PNOPrices = action.payload;
            })
            .addCase(matrixOperations.getFilteredMatrixTemplates.fulfilled, (state, action) => {
                state.matrixTemplates = action.payload.items;
                state.pageIndex = action.payload.pageIndex;
                state.pageSize = action.payload.pageSize;
                state.totalCount = action.payload.totalCount;
                state.isTemplateLoading = false;
                state.isLoading = false;
            })
            .addCase(matrixOperations.getFilteredMatrixTemplates.pending, (state, action) => {
                state.isTemplateLoading = true;
            })
            .addCase(matrixOperations.getFilteredRvMatrixes.fulfilled, (state, action) => {
                state.isFilteredMatrixesLoading = false;
                state.rvMatrixes = action.payload.items;
                state.pageIndex = action.payload.pageIndex;
                state.pageSize = action.payload.pageSize;
                state.totalCount = action.payload.totalCount;
                state.isLoading = false;
            })
            .addCase(matrixOperations.getFilteredRvMatrixes.pending, (state, action) => {
                state.isFilteredMatrixesLoading = true;
            })
            .addCase(matrixOperations.getMatrixTemplateById.fulfilled, (state, action) => {
                state.matrix = action.payload;
                state.matrixForEdit = action.payload;
                state.isLoadingTemplate = false;
                state.isLoading = false;
            })
            .addCase(matrixOperations.getMatrixTemplateById.pending, (state) => {
                state.isLoading = true;
                state.isLoadingTemplate = true;
            })
            .addCase(matrixOperations.getMatrixTemplateById.rejected, (state, action) => {
                state.isLoading = false;
                state.isLoadingTemplate = false;
                state.error = action.error.message as string;
                state.matrix = null;
            })
            .addCase(matrixOperations.getRvMatrixById.fulfilled, (state, action) => {
                state.rvMatrixPreview = action.payload;
                state.isLoading = false;
            })
            .addCase(matrixOperations.getRvMatrixById.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(matrixOperations.getRvMatrixById.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message as string;
                state.rvMatrixPreview = null;
            })
            .addCase(matrixOperations.updateMatrixTemplate.fulfilled, (state) => {
                state.isSuccess = 'Matrix Template Updated';
            })
            .addCase(matrixOperations.updateMatrixTemplate.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as ErrorResponse;
            })
            .addCase(matrixOperations.duplicateTemplate.fulfilled, (state) => {
                state.isSuccess = 'Matrix Duplicated';
                state.isLoading = false;
            })
            .addCase(matrixOperations.duplicateTemplate.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as ErrorResponse;
            })
            .addCase(matrixOperations.getRvMatrixPreview.fulfilled, (state, action) => {
                state.rvMatrixPreview = action.payload;
            })
            .addCase(matrixOperations.createRvMatrixFromFile.fulfilled, (state, action) => {
                state.isSuccess = 'Matrix Uploaded';
            })
            .addCase(matrixOperations.createRvMatrixFromFile.rejected, (state, action) => {
                state.error = action.payload as ErrorResponse;
            })
            .addCase(matrixOperations.sendToApproveRvMatrix.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = 'Matrix Sent To Approve';
            })
            .addCase(matrixOperations.sendToApproveRvMatrix.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(matrixOperations.sendToApproveRvMatrix.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as ErrorResponse;
            })
            .addCase(matrixOperations.duplicateRvMatrix.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = 'Matrix Duplicated';
            })
            .addCase(matrixOperations.duplicateRvMatrix.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(matrixOperations.duplicateRvMatrix.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as ErrorResponse;
            })
            .addCase(matrixOperations.getRvMatrixesById.fulfilled, (state, action) => {
                state.isLoading = false;
                state.selectedMatrixesForApprove = action.payload;
            })
            .addCase(matrixOperations.getRvMatrixesById.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(matrixOperations.enableRvMatrix.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(matrixOperations.enableRvMatrix.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(matrixOperations.enableRvMatrix.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as ErrorResponse;
            })
            .addCase(matrixOperations.disableRvMatrix.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(matrixOperations.disableRvMatrix.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(matrixOperations.disableRvMatrix.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as ErrorResponse;
            })
            .addCase(matrixOperations.enableTemplate.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(matrixOperations.enableTemplate.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(matrixOperations.enableTemplate.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as ErrorResponse;
            })
            .addCase(matrixOperations.disableTemplate.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(matrixOperations.disableTemplate.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(matrixOperations.disableTemplate.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as ErrorResponse;
            })
            .addCase(matrixOperations.getMatrixStatusesCount.fulfilled, (state, action) => {
                state.matrixStatuses = action.payload;
                state.isStatusesLoading = false;
            })
            .addCase(matrixOperations.getMatrixStatusesCount.pending, (state, action) => {
                state.isStatusesLoading = true;
            })
            .addCase(matrixOperations.getMatrixWidgetStatistics.fulfilled, (state, action) => {
                state.widgetStatuses = action.payload;
            })
            .addCase(matrixOperations.getPowesearchMatrixesResults.pending, (state) => {
                state.isMatrixPowersearchLoading = true;
            })
            .addCase(matrixOperations.getPowesearchMatrixesTemplatesResults.pending, (state) => {
                state.isTemplatePowersearchLoading = true;
            })
            .addCase(matrixOperations.getPowesearchMatrixesResults.fulfilled, (state, action) => {
                state.isMatrixPowersearchLoading = false;
                state.powersearchMatrixResults = action.payload;
            })
            .addCase(matrixOperations.getPowesearchMatrixesResults.rejected, (state, action) => {
                state.isMatrixPowersearchLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(matrixOperations.getPowesearchMatrixesTemplatesResults.fulfilled, (state, action) => {
                state.isTemplatePowersearchLoading = false;
                state.powersearchMatrixTemplateResults = action.payload;
            })
            .addCase(matrixOperations.getPowesearchMatrixesTemplatesResults.rejected, (state, action) => {
                state.isTemplatePowersearchLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(matrixOperations.getMatrixLogs.fulfilled, (state, action) => {
                state.matrixLogs = action.payload;
                state.isMatrixLoGsLoading = false;
            })
            .addCase(matrixOperations.getMatrixLogs.pending, (state, action) => {
                state.isMatrixLoGsLoading = false;
            });
    },
    reducers: {
        hasError(state, action: PayloadAction<ErrorResponse>) {
            state.isLoading = false;
            state.isSuccess = false;
            state.error = action.payload as ErrorResponse;
        },
        startPowerSearchLoading(state) {
            state.isTemplatePowersearchLoading = true;
            state.isMatrixPowersearchLoading = true;
        },
        setMatrixSteps(state, action) {
            if (state.matrixForEdit) state.matrixForEdit.decreaseRvSteps.steps = action.payload;
        },
        setMatrixDecreaseKmVsPivot(state, action) {
            if (state.matrixForEdit) state.matrixForEdit.decreaseRv.decreaseKmVsPivot = action.payload;
        },
        setMatrix(state, action) {
            state.matrixForEdit = action.payload;
        },
        refreshMatrix(state, action) {
            state.matrix = action.payload;
        },
        setEditMode(state, action) {
            state.isEditMode = action.payload;
        },
        resetMatrix(state) {
            state.matrix = null;
            state.matrixForEdit = null;
        },
        enableTemplateById(state, action) {
            const ind = state.matrixTemplates.findIndex((item) => item.id === action.payload);

            state.matrixTemplates[ind].enabled = true;
        },
        disableTemplateById(state, action) {
            const ind = state.matrixTemplates.findIndex((item) => item.id === action.payload);

            state.matrixTemplates[ind].enabled = false;
        },
        enableRvMatrixById(state, action) {
            const ind = state.rvMatrixes.findIndex((item) => item.id === action.payload);

            state.rvMatrixes[ind].enabled = true;
        },
        disableRvMatrixById(state, action) {
            const ind = state.rvMatrixes.findIndex((item) => item.id === action.payload?.matrixId);

            state.rvMatrixes[ind].enabled = false;
            if (!action.payload?.includeDisabled) {
                state.rvMatrixes = state.rvMatrixes?.filter(item => item.enabled);
            }
        },
        setRvMatrixPreviewPrice(state, action) {
            state.rvMatrixPreviewPrice = action.payload;
        },
        setRvMatrixPreview(state, action) {
            state.rvMatrixPreview = action.payload;
        },
        setWatchMode(state, action) {
            state.watchMode = action.payload;
        },
        clearError(state) {
            state.error = null;
        },
        clearSuccess(state) {
            state.isSuccess = false;
        },
        resetRvMatrixPreview(state) {
            state.rvMatrixPreviewPrice = null;
        },
        resetPNOPrices(state) {
            state.PNOPrices = initialState.PNOPrices;
        }
    },
});

export const {
    setEditMode,
    setWatchMode,
    setRvMatrixPreview,
    setRvMatrixPreviewPrice,
    resetMatrix,
    setMatrixDecreaseKmVsPivot,
    clearError,
    clearSuccess,
    setMatrixSteps,
    setMatrix,
    enableTemplateById,
    disableTemplateById,
    enableRvMatrixById,
    disableRvMatrixById,
    startPowerSearchLoading,
    resetRvMatrixPreview,
    resetPNOPrices,
    refreshMatrix
} = MatrixSlice.actions;

export default MatrixSlice.reducer;
