import * as React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import { Mark } from '@mui/material/Slider/useSlider.types';

interface InputSliderProps extends BoxProps {
    label: string,
    value: number | undefined,
    setValue: (value: number) => void,
    minValue: number,
    maxValue: number,
    step: number,
    marks?: boolean | Mark[],
    icon?: React.ReactElement
}

export default function InputSlider({ label, value, setValue, minValue, maxValue, step, marks, icon, ...BoxProps }: InputSliderProps) {

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value === '' ? minValue : Number(event.target.value));
    };

    const handleBlur = () => {
        if (!value || value < minValue) {
            setValue(minValue);
        } else if (value > maxValue) {
            setValue(maxValue);
        }
    };

    return (
        <Box {...BoxProps}>

            <Typography id="input-slider" gutterBottom>
                {label}
            </Typography>

            <Grid container spacing={2} sx={{ pl: 1 }}>

                {icon &&
                    <Grid item sx={{ pr: 0.5 }}>
                        {icon}
                    </Grid>
                }

                <Grid item xs>
                    <Slider
                        value={typeof value === 'number' ? value : minValue}
                        onChange={handleSliderChange}
                        step={step}
                        min={minValue}
                        max={maxValue}
                        marks={marks ?? false}
                        size={'medium'}
                    />
                </Grid>

                <Grid item sx={{ pl: 2 }}>
                    <MuiInput
                        value={value}
                        size="small"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        inputProps={{
                            step: step,
                            min: minValue,
                            max: maxValue,
                            type: 'number',
                            style: { textAlign: 'center' }
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}