import { createAsyncThunk } from "@reduxjs/toolkit";
import { startEmailLoading, startLoading } from "./email-slices";
import * as emailService from '../../services/emailService';
import removeEmptyKeys from "src/utils/removeEmptyKeys";

const getAllEmails = createAsyncThunk(
    'email/getAllEmails',
    async (options: any, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await emailService.searchEmails(removeEmptyKeys(options));

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const getEmailDetails = createAsyncThunk(
    'email/getEmailDetails',
    async (id: string, { rejectWithValue, dispatch }) => {
        dispatch(startEmailLoading());
        try {
            const { data } = await emailService.emailDetails(id);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const emailOperations = {
    getAllEmails,
    getEmailDetails
};

export default emailOperations;
