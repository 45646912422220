import Container from '@mui/material/Container';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import Page from 'src/appComponents/Page';
import useLocales from 'src/appHooks/useLocales';
import { useSettingsContext } from 'src/components/settings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { dispatch, RootState } from 'src/redux/store';
import { FetchDataFuncArgs } from 'src/@types/logs';
import Logs from 'src/appComponents/logs/Logs';
import termConditionOperations from 'src/redux/termCondition/termCondition-operations';

const LABELS = [
    "TermAndConditionCreated",
    "TermAndConditionUpdated",
    "TermAndConditionDisabled",
    "TermAndConditionEnabled"
];

export default function TermsCondtionLogs() {

    const { themeStretch } = useSettingsContext();

    const isDesktop = useResponsive('up', 'sm');

    const { translate, currentLang } = useLocales();

    const { isLogsLoading, termConditionLogs, termCondition } = useSelector((state: RootState) => state.terms);

    const { id } = useParams();

    useEffect(() => {
        if (!termCondition || termCondition.id !== id) {
            if (id) dispatch(termConditionOperations.getTermCondition(id));
        }
    }, [id, currentLang, termCondition]);

    const fetchLogs = (searchFilters: FetchDataFuncArgs) => {
        if (id) dispatch(termConditionOperations.getTermConditionLogs({ id: id, filters: searchFilters }));
    };

    //-----LABELS

    const createLabels = useCallback(() => {
        return LABELS.map((label) => ({
            label: `${translate(`termsCondition.logs.eventTypes.${label.charAt(0).toLowerCase() + label.slice(1)}`)}`,
            key: label,
        }));
    }, [translate]);

    const typesLabels = useMemo(
        () => [
            {
                label: `${translate(`commons.all`)}`,
                key: 'all',
            },
            ...createLabels(),
        ],
        [createLabels, translate]
    );

    return (
        <Page title={translate('termsCondition.logs.title')}>

            <Container maxWidth={themeStretch ? false : 'lg'} sx={{ p: 0 }}>

                <HeaderBreadcrumbs
                    heading={translate('termsCondition.logs.title')}
                    links={[
                        { name: translate('commons.home'), href: PATH_DASHBOARD.root },
                        { name: translate('commons.request'), href: PATH_DASHBOARD.termsCondition.list },
                        { name: termCondition?.name, href: PATH_DASHBOARD.termsCondition.detailTab(id!, "en") },
                        { name: translate('commons.logs') }
                    ]}
                    sx={{ px: isDesktop ? 5 : 2, mb: isDesktop ? 5 : 2 }}
                />

                <Logs
                    tableData={termConditionLogs as any}
                    isLoading={isLogsLoading}
                    id={id!}
                    header={`${translate(`commons.logs`)} `}
                    subheader={`${translate(`termsCondition.logs.subtitle`)}`}
                    labels={typesLabels}
                    fetchData={fetchLogs}
                    localesPath={"termsCondition"}
                />
            </Container>
        </Page>
    );
}
