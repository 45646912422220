import { Box, Typography } from "@mui/material";
import { UseFormReturn } from 'react-hook-form';
import useLocales from 'src/appHooks/useLocales';
import { GenericOrganization, GenericOrganizationEditWithFather } from "src/@types/organizations";
import { CustomField } from "src/@types/customField";
import CustomFieldFormRenderer from "src/utils/CustomFieldFormRenderer";

type Props = {
    outerRef: any;
    formMethods: UseFormReturn<GenericOrganizationEditWithFather>;
    setOnEditForm: (value: boolean) => void;
    onEditForm: boolean;
    isDisabled: (name: string, other?: boolean, and?: boolean) => boolean;
    customFieldsProp: CustomField[];
    handleCustomFields: (event: any) => void;
    setErrorInCustom: (value: boolean) => void;
    setFieldsWithError: (value: CustomField[]) => void;
};

export default function OrganizationStepInfo({
    outerRef,
    formMethods,
    setOnEditForm,
    onEditForm,
    isDisabled,
    customFieldsProp,
    handleCustomFields,
    setErrorInCustom,
    setFieldsWithError
}: Props) {

    const { getValues, clearErrors, watch } = formMethods;

    const { translate } = useLocales();

    const validateFields = async (yupSchema: any) => {
        const formValues = getValues();

        try {
            await yupSchema.validate(formValues, { abortEarly: false });
            clearErrors();

            return true;
        } catch (err: any) {
            return false;
        }
    };

    if (!outerRef?.current) {
        outerRef.current = {
            validateFields,
            onSave: () => {
                const formValues = getValues();

                return { ...formValues };
            }
        };
    };

    const customFieldsValues = watch("customFields");

    return (
        <Box sx={{ height: 500 }}>
            <Typography variant="h5">{`${translate('organization.form.steps.others')}`}</Typography>

            <Typography variant="body2" sx={{ mt: 1, mb: 3 }}>
                {`${translate('organization.form.description.others')}`}
            </Typography>

            <Box>

                {/*                 {customFieldsProp && (
                    <CustomFieldFormRenderer
                        context={"organization"}
                        handleCustomFields={handleCustomFields}
                        values={customFieldsValues}
                        columnGap={2}
                        repeat={'repeat(2, 1fr)'}
                        isDisabled={isDisabled}
                        section={["Other Info", "OtherInfo"]}
                        setErrorInCustom={setErrorInCustom}
                        setFieldsWithError={setFieldsWithError}
                    />
                )} */}

            </Box>
        </Box>
    );
}
