import { Box, Button, Container } from '@mui/material';
import useLocales from 'src/appHooks/useLocales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import Page from 'src/appComponents/Page';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import Iconify from 'src/components/iconify';
import { useNavigate } from 'react-router';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import useResponsive from 'src/hooks/useResponsive';
import { OrganizationPermissionTypes } from 'src/@types/permissions';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'src/redux/store';
import { resetRequest } from 'src/redux/request/request-slices';
import { reportsOperations } from 'src/redux/reports';
import RequestList from './RequestList';

interface RequestListProps {
    anomaly?: boolean;
    vendor: boolean,
    carrier: boolean
}

export default function RequestListManager({ anomaly, vendor, carrier }: RequestListProps) {

    const { themeStretch } = useSettingsContext();

    const { translate } = useLocales();

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'md');

    const { request } = useSelector((state: RootState) => state.request);

    //reset to refresh the request when its status changes
    useEffect(() => {
        if (request)
            dispatch(resetRequest());

    }, [request]);

    useEffect(() => {
        dispatch(reportsOperations.getTemplates("ReturnRequest"));
    }, []);

    return (
        <Page title={`${translate(anomaly ? 'menu.management.anomalies.title' : 'menu.management.request.title')}`}>
            <Container maxWidth={themeStretch ? false : 'lg'}>

                <HeaderBreadcrumbs
                    heading={`${translate(anomaly ? 'menu.management.anomalies.title' : 'menu.management.request.title')}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate(anomaly ? 'menu.management.anomalies.title' : 'menu.management.request.title')}` }
                    ]}
                    action={
                        <Box sx={{ display: 'flex', gap: 2, flexDirection: isDesktop ? 'row' : 'column' }}>
                            {!vendor && !carrier && <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerCreate]}>
                                <Button
                                    variant="contained"
                                    startIcon={<Iconify icon={'eva:plus-fill'} />}
                                    onClick={() => navigate(PATH_DASHBOARD.request.customer.new)}
                                    sx={{ borderRadius: "100px" }}
                                >
                                    {`${translate('returns.newReturn')}`}
                                </Button>
                            </PermissionBasedGuard>}
                            {carrier && <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CarrierManagePickUp]}>
                                <Button
                                    variant="contained"
                                    startIcon={<QrCodeScannerIcon />}
                                    onClick={() => navigate(PATH_DASHBOARD.request.carrier.driver)}
                                    sx={{ borderRadius: "100px" }}
                                >
                                    {`${translate('request.scanCode')}`}
                                </Button>
                            </PermissionBasedGuard>}
                            {vendor && <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorWarehouseValidation]}>
                                <Button
                                    variant="contained"
                                    startIcon={<QrCodeScannerIcon />}
                                    onClick={() => navigate(PATH_DASHBOARD.request.vendor.warehouse)}
                                    sx={{ borderRadius: "100px" }}
                                >
                                    {`${translate('request.scanCodeWarehouse')}`}
                                </Button>
                            </PermissionBasedGuard>}
                        </Box>
                    }
                />

                <RequestList carrier={carrier} vendor={vendor} anomaly={anomaly} />

            </Container>
        </Page>
    );
}