import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ReactNode } from "react";
import { useLocales } from "src/locales";

type MuovytiLocalizationProviderProps = {
    children: ReactNode;
};

function MuovytiLocalizationProvider({ children }: MuovytiLocalizationProviderProps) {
    const { currentLang } = useLocales();

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={currentLang.locale}>
            {children}
        </LocalizationProvider>
    );
}

export { MuovytiLocalizationProvider };