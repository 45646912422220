import { Box, Button, Container, MenuItem, Typography } from '@mui/material';
import Page from 'src/appComponents/Page';
import { useNavigate, } from 'react-router-dom';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { useLocales } from 'src/locales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'src/redux/store';
import { useCallback, useMemo, useState } from 'react';
import Label from 'src/components/label';
import TableMoreMenu from 'src/appComponents/TableMoreMenu';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import { OrganizationPermissionTypes } from 'src/@types/permissions';
import { useSnackbar } from 'notistack';
import { isEqual } from 'lodash';
import useTabs from 'src/appHooks/useTabs';
import { QuickFilters } from 'src/@types/list';
import Iconify from 'src/components/iconify';
import { BasketRuleStatusType, BasketRuleFilters, BasketRuleSearchResult, DEFAULT_BASKET_RULES_FILTERS, EnableDisableBasketRuleSliceProps } from 'src/@types/webshop';
import { webshopOperations } from 'src/redux/webshop';
import { setBasketFiltersInUrl } from 'src/redux/webshop/webshop-slices';
import DateZone from 'src/appComponents/DateZone';
import CurrencyAdapter from 'src/appComponents/CurrencyAdapter';
import InfiniteScrollGenericList from 'src/utils/list/InfiniteScrollGenericList';
import { CurrencyTypes } from 'src/@types/vehicle';
import { durationToMinutes } from './NewEditBasketRule';

export default function BasketRulesList() {

    const { translate } = useLocales();

    const { themeStretch } = useSettingsContext();

    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'md');

    const { isBasketRuleLoading, basketRuleList, basketRulesTotalCount, basketRuleFiltersInUrl, basketRuleStatistics } = useSelector((state: RootState) => state.webshop);

    const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('');

    const [filters, setFilters] = useState<BasketRuleFilters>(DEFAULT_BASKET_RULES_FILTERS);

    const statsKeysToDelete: string[] = ["status"];

    const fullKeysToDelete: string[] = ["dateField", "min", "max"];

    //---- QUICKFILTERS START ----// 
    const quickFilters: QuickFilters[] = useMemo(() => [
        {
            key: '',
            label: `${translate('commons.all')}`,
            color: 'info',
        },
        {
            key: 'enabled',
            label: `${translate('statuses.enabled')}`,
            color: 'success',
        },
        {
            key: 'disabled',
            label: `${translate('statuses.disabled')}`,
            color: 'warning',
        }
    ], [translate]);

    const renderQuickFilters = (key: string) => {
        if (basketRuleStatistics) {
            switch (key) {
                case "":
                    return basketRuleStatistics.all;
                case "disabled":
                    return basketRuleStatistics.disabled;
                case "enabled":
                    return basketRuleStatistics.enabled;
                default:
                    return 0;
            }
        } else return 0;
    };
    //---- QUICKFILTERS END ----//

    const updateCheckField = useCallback((field: string, filtersToCheck: BasketRuleFilters) =>
        (filtersToCheck[field] || typeof filtersToCheck[field] === "boolean")
        &&
        !isEqual(filtersToCheck[field], DEFAULT_BASKET_RULES_FILTERS[field])
        , []);

    const extraSearchFiltersChecks = useCallback((searchFilters: BasketRuleFilters) => {

        switch (filterStatus) {
            case "":
                searchFilters.onlyEnabled = undefined;
                break;
            case "enabled":
                searchFilters.onlyEnabled = true;
                break;
            case "disabled":
                searchFilters.onlyEnabled = false;
                break;
        };

        return searchFilters;
    }, [filterStatus]);

    //---- HANDLE TABLE START ----//
    const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

    const [actualRow, setActualRow] = useState<any>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const handleCellClick = (params: GridCellParams<any>) => {

        setActualRow(params);

        if (params.field !== "options") {
            navigate(PATH_DASHBOARD.basketRules.edit(params.row.id));
        }
    };

    const handleEdit = useCallback((id: string) => {
        navigate(PATH_DASHBOARD.basketRules.edit(id));
    }, [navigate]);

    const handleLogs = useCallback((id: string) => {
        navigate(PATH_DASHBOARD.basketRules.logs(id));
    }, [navigate]);

    const handleEnableDisableGroup = useCallback(async (id: string, action: "Enable" | "Disable") => {

        const options: EnableDisableBasketRuleSliceProps = {
            id: id,
            action: action,
            tab: filterStatus as BasketRuleStatusType,
            filters: filters
        };

        let x = await dispatch(webshopOperations.enableDisableBasketRule(options));

        if (x.meta.requestStatus !== 'fulfilled') {
            enqueueSnackbar(`${translate('commons.error')}`, {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
        else {
            enqueueSnackbar(`${translate(action === "Enable" ? 'commons.enabled' : 'commons.disabled')}`, {
                variant: "success",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
    }, [enqueueSnackbar, filterStatus, filters, translate]);

    const COLUMNS: GridColDef<BasketRuleSearchResult>[] = useMemo(() => [
        {
            field: 'name',
            headerName: `${translate('commons.name')}`,
            flex: isDesktop ? 0.9 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: false
        },
        {
            field: 'duration',
            headerName: `${translate('basketRule.list.columns.duration')}`,
            flex: isDesktop ? 0.75 : undefined,
            minWidth: !isDesktop ? 180 : undefined,
            sortable: false,
            renderCell: (obj) => {
                return (
                    <Typography variant={"body2"} sx={{ ml: 0.5 }}>
                        {durationToMinutes(obj.row.duration) + " mm"}
                    </Typography>
                );
            }
        },
        {
            field: 'resetTimeWhenItemIsAdded',
            headerName: `${translate('basketRule.list.columns.resetTimeWhenItemIsAdded')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: false,
            renderCell: (obj) => {
                return (
                    <Typography variant={"body2"} sx={{ ml: 0.5 }}>
                        {translate(`commons.${obj.row.resetTimeWhenItemIsAdded ? "yes" : "no"}`)}
                    </Typography>
                );
            }
        },
        {
            field: 'cartAmount',
            headerName: `${translate('basketRule.list.columns.amount')}`,
            flex: isDesktop ? 1.25 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: false,
            renderCell: (obj) => {
                return (
                    <Box sx={{ ml: 0.5, display: "flex", gap: 0.5, alignItems: "center" }}>
                        <CurrencyAdapter
                            value={obj.row.minAmount ?? 0}
                            currency={CurrencyTypes.EUR}
                            variant={"body2"}
                        />
                        -
                        {obj.row.maxAmount ?
                            <CurrencyAdapter
                                value={obj.row.maxAmount}
                                currency={CurrencyTypes.EUR}
                                variant={"body2"}
                            />
                            :
                            <Box>{translate('basketRule.form.indefinite')}</Box>
                        }
                    </Box>
                );
            }
        },
        {
            field: 'cartQuantity',
            headerName: `${translate('basketRule.list.columns.quantity')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: false,
            renderCell: (obj) => {
                return (
                    <Typography variant={"body2"} sx={{ ml: 0.5 }}>
                        {(obj.row.minItems ?? 0) + " - " + (obj.row.maxItems ? obj.row.maxItems : translate('basketRule.form.indefinite'))}
                    </Typography>
                );
            }
        },
        {
            field: 'createdOn',
            headerName: `${translate('commons.createdOn')}`,
            flex: isDesktop ? 0.75 : undefined,
            minWidth: !isDesktop ? 180 : undefined,
            sortable: false,
            renderCell: (obj) => {
                return (
                    <Box sx={{ ml: 0.5 }}>
                        <DateZone
                            date={obj.row.createdOn}
                            onlyDate
                            variant={"body2"}
                        />
                    </Box>
                );
            }
        },
        {
            field: 'enabled',
            headerName: `${translate('commons.enabled')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj) => {
                return (
                    <Label
                        color={obj.row.enabled ? 'success' : 'error'}
                        sx={{ textTransform: 'uppercase' }}
                    >
                        {obj.row.enabled ? `${translate("commons.enabled")}` : `${translate("commons.disabled")}`}
                    </Label>
                );
            }
        },
        {
            field: 'isDefault',
            headerName: translate('termsCondition.form.default'),
            flex: isDesktop ? 0.5 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj) => {
                return (
                    <Iconify
                        icon={'eva:checkmark-fill'}
                        sx={{
                            width: 25,
                            height: 25,
                            color: 'success.main',
                            ...(!obj.row.isDefault && { color: (theme) => theme.palette.grey[300] })
                        }}
                    />
                );
            }
        },
        {
            field: 'options',
            headerName: ``,
            flex: isDesktop ? 0.2 : undefined,
            maxWidth: !isDesktop ? 70 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj: any) => {
                return (
                    <OptionsComponent
                        openMenu={openMenu}
                        handleOpenMenu={handleOpenMenu}
                        handleCloseMenu={handleCloseMenu}
                        object={obj}
                        currentRow={actualRow}
                        handleEnableDisable={handleEnableDisableGroup}
                        handleEdit={handleEdit}
                        handleLogs={handleLogs}
                    />
                );
            }
        }
    ], [translate, isDesktop, openMenu, actualRow, handleEnableDisableGroup, handleEdit, handleLogs]);
    //---- HANDLE TABLE END ----//

    return (
        <Page title={translate(`menu.management.basketRules.title`)}>

            <Container maxWidth={themeStretch ? false : 'lg'}>

                <HeaderBreadcrumbs
                    heading={translate(`menu.management.basketRules.title`)}
                    links={[
                        { name: translate('commons.home'), href: PATH_DASHBOARD.root },
                        { name: translate(`menu.management.basketRules.title`) }
                    ]}
                    action={
                        <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_BasketRule_Create]}>
                            <Button
                                variant="contained"
                                startIcon={<Iconify icon={'eva:plus-fill'} />}
                                onClick={() => navigate(PATH_DASHBOARD.basketRules.new)}
                                sx={{ borderRadius: "100px" }}
                            >
                                {translate('basketRule.form.new_short')}
                            </Button>
                        </PermissionBasedGuard>
                    }
                />

                <InfiniteScrollGenericList
                    list={basketRuleList}
                    isLoading={isBasketRuleLoading}
                    totalCount={basketRulesTotalCount}
                    defaultFilters={DEFAULT_BASKET_RULES_FILTERS}
                    specificStatsKeysToDelete={statsKeysToDelete}
                    specificFullKeysToDelete={fullKeysToDelete}
                    quickFilters={quickFilters}
                    renderQuickFilters={renderQuickFilters}
                    datagridColumns={COLUMNS}
                    updateCheckField={updateCheckField}
                    extraSearchFiltersChecks={extraSearchFiltersChecks}
                    context={"BasketRule"}
                    setActualRow={setActualRow}
                    handleCellClick={handleCellClick}
                    setFiltersCallback={setFilters}
                    filterStatus={filterStatus}
                    onChangeFilterStatus={onChangeFilterStatus}
                    search={webshopOperations.searchBasketRules}
                    searchStatistics={webshopOperations.getBasketRuleStatistics}
                    filtersInUrl={basketRuleFiltersInUrl}
                    setFiltersInUrl={setBasketFiltersInUrl}
                    listDescription={translate('basketRule.subtitle')}
                    resetList={() => { }}
                    showDates
                />

            </Container>
        </Page>
    );
}

type OptionsComponentProps = {
    openMenu: HTMLElement | null,
    handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void,
    handleCloseMenu: () => void,
    object: any,
    currentRow: any,
    handleEnableDisable: (id: string, action: "Enable" | "Disable") => Promise<void>,
    handleEdit: (id: string) => void,
    handleLogs: (id: string) => void
};

function OptionsComponent({ openMenu, handleOpenMenu, handleCloseMenu, object, currentRow, handleEnableDisable, handleEdit, handleLogs }: OptionsComponentProps) {

    const { translate } = useLocales();

    return (
        <TableMoreMenu
            showMenu={currentRow && object.id === currentRow.id}
            open={openMenu}
            onOpen={(event) => handleOpenMenu(event)}
            onClose={() => handleCloseMenu()}
            actions={
                <>
                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_BasketRule_EnableDisable]}>
                        <MenuItem
                            onClick={() => {
                                if (handleEnableDisable) {
                                    if (object.row.enabled) handleEnableDisable(object.id, "Disable");
                                    else handleEnableDisable(object.id, "Enable");
                                }
                                handleCloseMenu();
                            }}
                            sx={{ color: object.row.enabled ? 'error.main' : 'success.main' }}
                        >
                            {!object.row.enabled ? `${translate("commons.enable")}` : `${translate("commons.disable")}`}
                        </MenuItem>
                    </PermissionBasedGuard>

                    {object.row.status === "Active" &&
                        <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_BasketRule_Edit]}>
                            <MenuItem
                                onClick={() => {
                                    handleEdit(object.id);
                                    handleCloseMenu();
                                }}
                            >
                                {`${translate("commons.edit")}`}
                            </MenuItem>
                        </PermissionBasedGuard>
                    }

                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_BasketRule_ViewLogs]} >
                        <MenuItem
                            onClick={() => {
                                handleLogs(object.id as string);
                                handleCloseMenu();
                            }}
                        >
                            {`${translate("commons.logs")}`}
                        </MenuItem>
                    </PermissionBasedGuard>
                </>
            }
        />
    );
}