import { Box, SxProps, Theme, Typography } from "@mui/material";
import { Address } from "src/@types/organizations";
import { useLocales } from "src/locales";

interface DeliverToFromProps {
    deliveryOrganization: {
        name: string,
        address: Address,
        contact?: string
    },
    vendor: {
        name: string,
        address: Address,
        contact?: string
    },
    sx?: SxProps<Theme> | undefined
}

export default function DeliverToFrom({ deliveryOrganization, vendor, sx }: DeliverToFromProps) {

    const { translate } = useLocales();

    const getAddress = (address: Address): string[] => {
        let addr = "";
        let city = "";
        let country = "";

        addr += address.address ? address.address + ", " : "";
        addr += address.district ? address.district + "" : "";
        city += address.zipCode ? address.zipCode + ", " : "";
        city += address.city ? address.city + "" : "";
        country += address.country ? address.country : "";

        return [addr, city, country];
    };

    return (
        <Box sx={{ ...sx }}>
            <Box
                sx={{
                    display: 'grid', p: 5, gap: 3,
                    gridTemplateColumns: { sm: 'repeat(2, 1fr)' }
                }}>

                <Box>
                    <Typography variant="overline" color={'#6D6D6D'}>
                        {`${translate('request.detail.deliveryFrom')}`}
                    </Typography>

                    <Typography variant="body2" sx={{ mt: 3 }}>
                        {deliveryOrganization.name}
                    </Typography>

                    <Typography variant="body2">
                        {getAddress(deliveryOrganization.address).join("")}
                    </Typography>

                    <Typography variant="body2">
                        {`${translate('commons.phone')}`}: {deliveryOrganization.contact || "—"}
                    </Typography>
                </Box>

                <Box>
                    <Typography variant="overline" color={'#6D6D6D'}>
                        {`${translate('request.detail.deliveryTo')}`}
                    </Typography>

                    <Typography variant="body2" sx={{ mt: 3 }}>
                        {vendor.name}
                    </Typography>

                    <Typography variant="body2">
                        {getAddress(vendor.address).join("")}
                    </Typography>

                    <Typography variant="body2">
                        {`${translate('commons.phone')}`}: {vendor.contact || "—"}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}