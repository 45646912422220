import { Card, Typography, Divider, TablePagination, FormControlLabel, Switch, Button, Modal, TextField, MenuItem, Box, Tabs, Tab } from "@mui/material";
import { Container, Stack } from "@mui/system";
import { GridSortModel, GridColDef, DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useState, useRef, useEffect, useMemo, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useReactToPrint } from "react-to-print";
import { DEFAULT_NEW_EXTRAITEM, ItemContainer, NewExtraItem } from "src/@types/container";
import Page from "src/appComponents/Page";
import LoadingScreen from "src/appComponents/loading-screen";
import useTable from "src/appHooks/useTable";
import { noData } from "src/components/empty-content/EmptyContent";
import { useSettingsContext } from "src/components/settings";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";
import { containerOperations } from "src/redux/container";
import { RootState, dispatch } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";
import ContainerLabel from "src/sections/@dashboard/container/ContainerLabel";
import { DataGridStyle } from "src/utils/DataGridStyle";
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { ContainerMoreDetail } from "./ContainerTemplateDetail";
import DateZone from "src/appComponents/DateZone";
import Label from "src/components/label";
import PermissionBasedGuard from "src/guards/PermissionBasedGuard";
import { OrganizationPermissionTypes } from "src/@types/permissions";
import ReportNewEdit from "src/sections/@dashboard/report/edit/ReportNewEdit";
import { NewReportRecurring, NewReportScheduled } from "src/@types/report";
import { reportsOperations } from "src/redux/reports";
import useTenant from "src/appHooks/useTenant";
import { hasLicense } from "src/utils/tenant";
import { LicenseTypes } from "src/@types/tenant";
import LicenseGuard from "src/guards/LicenseGuard";
import { setSuccessMessage } from "src/redux/modal/modal-slices";
import { DatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import CustomFieldFormRenderer from "src/utils/CustomFieldFormRenderer";
import { useForm } from "react-hook-form";
import FormProvider from "src/components/hook-form/FormProvider";
import { getLocalizedString } from "src/utils/CustomFieldManagment";
import { deepClone } from "@mui/x-data-grid/internals";

export default function ContainerDetail() {

    const {
        page,
        setOrder,
        setOrderBy,
        rowsPerPage,
        dense,
        onChangePage,
        onChangeDense,
        onChangeRowsPerPage,
    } = useTable();

    const {
        page: extraPage,
        setOrder: setExtraOrder,
        setOrderBy: setExtraOrderBy,
        rowsPerPage: extraRowsPerPage,
        dense: extraDense,
        onChangePage: extraOnChangePage,
        onChangeDense: extraOnChangeDense,
        onChangeRowsPerPage: extraOnChangeRowsPerPage,
    } = useTable();

    const { translate, currentLang } = useLocales();

    const navigate = useNavigate();

    const { themeStretch } = useSettingsContext();

    const isDesktop = useResponsive('up', 'md');

    const { isLoading, container, filtersInUrl, itemsInContainerList } = useSelector((state: RootState) => state.container);

    const { id } = useParams();

    const [isOpen, setIsOpen] = useState(false);

    const tenant = useTenant();

    const [openReport, setOpenReport] = useState<"recurring" | "scheduled" | null>(null);

    const [currentTab, setCurrentTab] = useState("items");

    const [action, setAction] = useState("");

    const ref = useRef(null);

    useEffect(() => {
        dispatch(reportsOperations.getTemplates("container"));
    }, []);

    const handleOpenModal = (open: 'recurring' | 'scheduled') => {
        setOpenReport(open);
    };

    const handleSubmit = async (report: NewReportScheduled | NewReportRecurring, overrideFilters?: any) => {
        setOpenReport(null);
        setAction("");
        if (container) {
            report.containerId = container.id;
            if (overrideFilters)
                report.filter = overrideFilters;
            else
                delete report.filter;

            await dispatch(reportsOperations.createReport({ category: 'shipping', isScheduled: openReport === "scheduled", report, type: "container" })).unwrap();
        }
        dispatch(setSuccessMessage({ text: successText, goTo: (action !== "ship" && action !== "close") ? PATH_DASHBOARD.reports.scheduled.list : undefined }));
    };

    const successText = useMemo(() => {
        if (action === "ship")
            return `${translate(`containers.messages.successShip`)}`;
        if (action === "close")
            return `${translate(`containers.messages.successClose`)}`;

        return `${translate('report.messages.successSave')}`;
    }, [action, translate]);

    const Print = useReactToPrint({
        contentRef: ref,
        pageStyle: "color: black"
    });

    const getStatusColor = (status: string) => {
        if (status === "Open")
            return "success";
        if (status === "Closed")
            return "error";
        else
            return "warning";
    };

    useEffect(() => {
        if (id && (!container || (container.id !== id))) {
            dispatch(containerOperations.getContainer(id));
        }

    }, [id, container]);

    useEffect(() => {

        if (hasLicense(tenant, LicenseTypes.Reports))
            dispatch(reportsOperations.getColumns("container"));

    }, []);

    const handleShip = async () => {
        setAction("ship");

    };

    const getHeight = () => {
        let height: string | number = "auto";

        if (!dense || itemsInContainerList.length === 0) {
            if (isDesktop) height = rowsPerPage === 5 ? 380 : 650;
            else height = rowsPerPage === 5 ? 440 : 700;
        }

        return height;
    };

    const getMaxHeight = () => {
        return isDesktop ? 650 : 700;
    };

    const handleSort = (sortModel: GridSortModel) => {
        if (currentTab === "items")
            if (sortModel.length > 0) {
                setOrderBy(sortModel[0].field);
                setOrder(sortModel[0].sort!);
            } else {
                setOrderBy("");
                setOrder("asc");
            }
        else {
            if (sortModel.length > 0) {
                setExtraOrderBy(sortModel[0].field);
                setExtraOrder(sortModel[0].sort!);
            } else {
                setExtraOrderBy("");
                setExtraOrder("asc");
            }
        }
    };

    const customs = tenant.customFields.filter((v) => v.enabled && v.entityType === "ContainerExtraItems");

    const COLUMNS: GridColDef<ItemContainer>[] = useMemo(() => [
        {
            field: 'productExternalId',
            headerName: `${translate('returns.tableHeaders.partref')}`,
            flex: isDesktop ? 1.5 : undefined,
            minWidth: !isDesktop ? 155 : undefined
        },
        {
            field: 'productName',
            headerName: `${translate('commons.description')}`,
            flex: isDesktop ? 1.5 : undefined,
            minWidth: !isDesktop ? 155 : undefined
        },
        {
            field: 'quantity',
            headerName: `${translate('commons.quantity')}`,
            flex: isDesktop ? 1.5 : undefined,
            minWidth: !isDesktop ? 155 : undefined
        },
        {
            field: 'createdOn',
            headerName: `${translate('orders.tableHeaders.createdOn')}`,
            flex: isDesktop ? 1.5 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            renderCell: (val) => {
                return <DateZone
                    date={new Date(val.row.createdOn)}
                    variant="body2"
                    shortMonth
                    noSeconds
                />;
            }
        },
        {
            field: 'productType',
            headerName: `${translate('commons.product.productType.productType')}`,
            flex: isDesktop ? 1.5 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            renderCell: (val) => <Typography variant="body2"> {`${translate(`commons.product.productType.${val.row.productType}`)}`}</Typography>
        },

    ], [translate, isDesktop]);

    const EXTRA_COLUMNS: GridColDef<NewExtraItem & { createdOn: string }>[] = useMemo(() => [
        {
            field: 'itemId',
            headerName: `${translate('returns.tableHeaders.partref')}`,
            flex: isDesktop ? 1.15 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
        },
        {
            field: 'itemDescription',
            headerName: `${translate('commons.description')}`,
            align: 'left',
            flex: isDesktop ? 1.15 : undefined,
            minWidth: !isDesktop ? 155 : undefined,

        },
        {
            field: 'quantity',
            headerName: `${translate('commons.quantity')}`,
            flex: isDesktop ? 0.85 : undefined,
            minWidth: !isDesktop ? 155 : undefined
        },
        {
            field: 'createdOn',
            headerName: `${translate('orders.tableHeaders.createdOn')}`,
            flex: isDesktop ? 1.5 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            renderCell: (val) => {
                return <DateZone
                    date={new Date(val.row.createdOn)}
                    variant="body2"
                    shortMonth
                    noSeconds
                />;
            }
        },
        {
            field: 'reason',
            headerName: `${translate('request.anomalies.tableHeaders.reason')}`,
            flex: isDesktop ? 1.75 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            valueGetter: (_, param) => `${translate(`containers.reasonsExtraitems.${param.reason}`)}`
        },
        ...(customs.map((customField) => ({

            field: 'extraFields.' + customField.id,
            headerName: getLocalizedString(customField.name, currentLang),
            flex: isDesktop ? 2 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            valueGetter: (_, param) => param.extraFields[customField.id]

        } as GridColDef<NewExtraItem & { createdOn: string }>)))
    ], [currentLang, customs, isDesktop, translate]);
    //---- ITEMS LIST END    ----//

    return (
        <Page title={`${translate('containers.detail.title')}`}>
            <Container maxWidth={themeStretch ? false : 'lg'}>

                <AddItemsModal isOpen={isOpen} toggle={setIsOpen} id={container?.id || ""} />

                <Modal open={!!openReport}>
                    <Card
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: isDesktop ? '40vw' : '70vw',
                            heigth: '70vh',
                            py: isDesktop ? 4 : 2,
                        }}
                    >
                        <ReportNewEdit isOpen={openReport} toggle={() => setOpenReport(null)} onSubmit={handleSubmit} isDetailExport />
                    </Card>
                </Modal>

                <HeaderBreadcrumbs
                    heading={`${translate('containers.detail.title')}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate('menu.management.containers.title')}`, href: PATH_DASHBOARD.containers.list },
                        { name: `${container?.name}` }
                    ]}
                    action={
                        <>
                            <LicenseGuard license={LicenseTypes.Reports}>
                                <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Shipping_Container_ManageReports]}>
                                    <Button
                                        variant="contained"
                                        size='small'
                                        sx={{ mr: 2 }}
                                        onClick={(e) => handleOpenModal('scheduled')}
                                    >
                                        {`${translate('commons.export')}`}
                                    </Button>

                                </PermissionBasedGuard>
                            </LicenseGuard>
                        </>
                    }
                />

                {container && <Box sx={{ display: 'none' }}>
                    <Box ref={ref}>
                        <ContainerLabel container={container} />
                    </Box>
                </Box>
                }

                <Card sx={{ p: 4 }}>

                    {isLoading || !container ? (
                        <LoadingScreen />
                    ) : (
                        <>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 4 }}>
                                <ShipModal id={container.id} name={container.name} isOpen={action === "ship"} toggle={() => setAction("")} />
                                <CloseContainerModal id={container.id} name={container.name} isOpen={action === "close"} toggle={() => setAction("")} />
                                <Stack>
                                    <Box sx={{ display: 'flex', alignItems: "center", gap: 1 }}>
                                        <Typography variant="h4">
                                            {`${translate('containers.detail.title')} ${container.name}`}
                                        </Typography>
                                        <Label
                                            color={getStatusColor(container.statusTypes)}
                                            sx={{ textTransform: 'uppercase', ml: 1 }}
                                        >
                                            {`${translate(`statuses.${container.statusTypes.toLowerCase()}`)}`}
                                        </Label>
                                    </Box>
                                    <Typography variant="body2">
                                        {`${translate(`commons.createdOn`)} `}
                                        <b>{dayjs(container.createdOn).format('DD MMM YYYY, HH:mm')}</b>
                                    </Typography>
                                </Stack>
                            </Box>

                            <ContainerMoreDetail container={container} />

                            <>
                                <Divider />
                                <Tabs
                                    allowScrollButtonsMobile

                                    scrollButtons="auto"
                                    value={currentTab}
                                    onChange={(_, val) => setCurrentTab(val)}
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        gap: '3',
                                        justifyContent: 'space-between',
                                        bgcolor: 'background.neutral'
                                    }}
                                >
                                    <Tab

                                        label={`${translate(`orders.filter.item`)}`}
                                        value={"items"}
                                        sx={{ mx: 2, fontSize: 'big' }}

                                        icon={
                                            <Label sx={{ mr: 1 }} fontSize={'big'} color={"success"}>
                                                {container.items.length}
                                            </Label>}
                                    />

                                    <Tab

                                        label={`${translate(`containers.extraItems.extraItem_plural`)}`}
                                        value={"extra"}
                                        sx={{ mx: 2, fontSize: 'big' }}

                                        icon={
                                            <Label sx={{ mr: 1 }} fontSize={'big'} color={"warning"}>
                                                {container.extraItems.length}
                                            </Label>}
                                    />

                                </Tabs>

                                <Divider />

                                <DataGrid
                                    rows={currentTab === "items" ? container.items as any : container.extraItems as any}
                                    columns={currentTab === "items" ? COLUMNS as any : EXTRA_COLUMNS as any}
                                    getRowId={(row) => currentTab === "items" ? row.id : row.itemId + row.createdOn}
                                    pagination
                                    disableColumnResize
                                    paginationModel={{
                                        page: currentTab === "items" ? page : extraPage,
                                        pageSize: currentTab === "items" ? rowsPerPage : extraRowsPerPage
                                    }}
                                    paginationMode="client"
                                    density={currentTab === "items" ?
                                        ((dense && container.items.length > 0) ? 'compact' : 'standard') :
                                        ((extraDense && container.extraItems.length > 0) ? 'compact' : 'standard')
                                    }
                                    onSortModelChange={handleSort}
                                    onRowClick={(param) => {
                                        if (currentTab === "items" && !window.getSelection()?.toString())
                                            navigate(PATH_DASHBOARD.request.vendor.backlogDetail(param.id.toString()));
                                    }
                                    }
                                    slots={{
                                        noRowsOverlay: noData,
                                        footer: () => (
                                            <Box sx={{
                                                position: 'relative',
                                                width: { xs: "90vw", md: "auto" },
                                            }}>
                                                <TablePagination
                                                    rowsPerPageOptions={[5, 10, 15, 30]}
                                                    component="div"
                                                    count={currentTab === "items" ? container.items.length : container.extraItems.length}
                                                    rowsPerPage={currentTab === "items" ? rowsPerPage : extraRowsPerPage}
                                                    page={currentTab === "items" ? page : extraPage}
                                                    onPageChange={currentTab === "items" ? onChangePage : extraOnChangePage}
                                                    onRowsPerPageChange={currentTab === "items" ? onChangeRowsPerPage : extraOnChangeRowsPerPage}
                                                    labelRowsPerPage={`${translate('commons.rowsPerPage')}`}
                                                    sx={{
                                                        overflow: "hidden",
                                                        "& .MuiTablePagination-input": {
                                                            ml: { xs: 0.5, md: "default" },
                                                            mr: { xs: 3.5, md: "default" }
                                                        }
                                                    }}
                                                />
                                                <FormControlLabel
                                                    control={<Switch
                                                        checked={currentTab === "items" ? dense : extraDense}
                                                        onChange={currentTab === "items" ? onChangeDense : extraOnChangeDense}
                                                    />}
                                                    label={`${translate('commons.dense')}`}
                                                    sx={{
                                                        py: { xs: 0, sm: 1.5 },
                                                        pb: { xs: 1.5, sm: 0 },
                                                        mx: 0,
                                                        top: 0,
                                                        justifyContent: "center",
                                                        width: { xs: "90vw", sm: "auto" },
                                                        position: { sm: 'absolute' }
                                                    }}
                                                />
                                            </Box>
                                        )
                                    }}
                                    disableColumnMenu
                                    pageSizeOptions={[5, 10, 15, 30]}
                                    disableRowSelectionOnClick
                                    sx={{
                                        ...DataGridStyle,
                                        '& .MuiDataGrid-cell': {
                                            cursor: currentTab === "items" ? "pointer" : "default",
                                        },
                                        cursor: "default",
                                        height: getHeight(),
                                        maxHeight: getMaxHeight()
                                    }}
                                />
                            </>

                            <Divider />

                            <Box
                                sx={{
                                    justifyContent: 'space-between',
                                    ml: isDesktop ? 'auto' : 0,
                                    display: 'flex',
                                    gap: 1, mt: 3,
                                    flexDirection: isDesktop ? 'default' : 'column-reverse',
                                }}>

                                <Button
                                    variant="soft"
                                    sx={{ borderRadius: '100px' }}
                                    onClick={() => navigate(PATH_DASHBOARD.containers.list + filtersInUrl, { replace: true })}
                                >
                                    {`${translate('request.goBack')}`}
                                </Button>
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    {container.statusTypes === "Open" && <Button variant="contained" sx={{ borderRadius: '100px' }} onClick={() => { setIsOpen(true); }}>
                                        {`${translate('containers.detail.insertExtraItem')}`}
                                    </Button>}
                                    <Button variant="contained" sx={{ borderRadius: '100px' }} onClick={() => Print()}>
                                        {`${translate('request.print')}`}
                                    </Button>

                                    {container.statusTypes === "Open" &&
                                        <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Shipping_Container_Close]}>
                                            <Button variant="contained" color={"error"} sx={{ borderRadius: '100px' }} onClick={() => setAction('close')}>
                                                {`${translate('commons.close')}`}
                                            </Button>
                                        </PermissionBasedGuard>
                                    }

                                    {container.statusTypes === "Closed" &&
                                        <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Shipping_Container_Ship]}>

                                            <Button variant="contained" color={"error"} sx={{ borderRadius: '100px' }} onClick={() => { handleShip(); }}>
                                                {`${translate('containers.detail.ship')}`}
                                            </Button>
                                        </PermissionBasedGuard>
                                    }
                                </Box>
                            </Box>
                        </>
                    )}

                </Card>

            </Container>
        </Page >
    );
}

interface AddItemsModalProps {
    isOpen: boolean,
    toggle: (val: boolean) => void,
    id: string
}

function AddItemsModal({ isOpen, id, toggle }: AddItemsModalProps) {

    const { translate } = useLocales();

    const [item, setItem] = useState<NewExtraItem>(DEFAULT_NEW_EXTRAITEM);

    const [error, setError] = useState<Record<string, string>>({});

    const [customFielderr, setCustomFieldErr] = useState(false);

    const { customFields } = useTenant();

    const methods = useForm({ defaultValues: { customFields: {} } });

    const requiredFields = customFields.filter(v => v.enabled && v.required && v.entityType === "ContainerExtraItems").map(v => v.id);

    const disabled = useMemo(() => {
        if (Object.values(error).some(v => !v))
            return true;
        if (Object.values(item).some(v => !v))
            return true;

        let val = methods.getValues('customFields');

        if (Object.entries(val).some(([k, v]) => requiredFields.includes(k as string) && !v))
            return true;

        return customFielderr;
    }, [error, item, methods, customFielderr, requiredFields]);

    const reasons = useMemo(() => [
        "wrongOrder",
        "overage",
        "catalogueError",
        "partDamagedPackNOK",
        "partDamagedPackOK",
        "incomplete",
        "labelError",
        "productionFailure"
    ], []);

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setItem(prev => ({ ...prev, [e.target.name]: e.target.value }));

        if (!e.target.value) {
            setError(prev => {
                prev[e.target.name] = `${translate('commons.validation.requiredField')}`;

                return prev;
            });
        }
        else {
            setError(prev => {
                delete prev[e.target.name];

                return prev;
            });
        }
    };

    const handleQuantity = (value: string) => {
        if (!isNaN(+value)) {
            setItem(prev => ({ ...prev, quantity: +value }));

            if (+value <= 0)
                setError(prev => {
                    prev["quantity"] = `${translate('commons.validation.requiredField')}`;

                    return prev;
                });
            else {
                setError(prev => {
                    delete prev["quantity"];

                    return prev;
                });
            }
        }
    };

    const handleSave = async () => {
        toggle(false);

        const newExtraItem = deepClone(item);

        newExtraItem.extraFields = methods.getValues('customFields');

        await dispatch(containerOperations.addExtraItems({ containerId: id, newExtraItem })).unwrap();

        dispatch(setSuccessMessage({ text: translate("containers.extraItems.successMessage") }));

        setItem(DEFAULT_NEW_EXTRAITEM);

        methods.reset();
    };

    return (
        <Modal open={isOpen}>
            <Card
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    p: 3,
                    width: '60vw'
                }}
            >
                <Box sx={{ my: 2 }}>
                    <Typography variant="h4">{`${translate("containers.extraItems.addExternalItem")}`}</Typography>
                </Box>
                <Divider />
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'column',
                        gap: 3, my: 2
                    }}
                >

                    <Box
                        sx={{
                            display: 'flex',
                            gap: 3,
                            width: '100%'
                        }}
                    >

                        <TextField
                            fullWidth
                            label={`${translate('returns.tableHeaders.partref')}`}
                            name={'itemId'}
                            value={item.itemId}
                            onChange={handleChange}
                            required
                            error={!!error["itemId"]}
                            helperText={!!error["itemId"] && error["itemId"]}
                            sx={{ '& .MuiInputLabel-asterisk': { color: 'error.main' } }}

                        />
                        <TextField
                            fullWidth
                            label={`${translate('commons.partDescription')}`}
                            name={'itemDescription'}
                            required
                            value={item.itemDescription}
                            onChange={handleChange}
                            error={!!error["itemDescription"]}
                            helperText={!!error["itemDescription"] && error["itemDescription"]}
                            sx={{ '& .MuiInputLabel-asterisk': { color: 'error.main' } }}

                        />

                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            gap: 3,
                            width: '100%'
                        }}
                    >

                        <TextField
                            fullWidth
                            value={item.quantity + ""}
                            label={`${translate('commons.quantity')}`}
                            onChange={(e) => handleQuantity(e.target.value)}
                            required
                            error={!!error["quantity"]}
                            helperText={!!error["quantity"] && error["quantity"]}
                            sx={{ '& .MuiInputLabel-asterisk': { color: 'error.main' } }}
                        />

                        <TextField
                            label={`${translate('request.anomalies.tableHeaders.reason')}`}
                            name="reason"
                            value={item.reason}
                            fullWidth
                            onChange={handleChange}
                            required
                            select
                            error={!!error["reason"]}
                            helperText={!!error["reason"] && error["reason"]}
                            sx={{ '& .MuiInputLabel-asterisk': { color: 'error.main' } }}
                        >
                            {reasons.map((val, index) =>
                                <MenuItem value={val} key={index}>
                                    {`${translate(`containers.reasonsExtraitems.${val}`)}`}
                                </MenuItem>
                            )}
                        </TextField>
                    </Box>

                    <FormProvider methods={methods}>
                        <CustomFieldFormRenderer
                            context={"ContainerExtraItems"}
                            defaultValues={{ customFields: {} }}
                            setErrorInCustomfields={val => setCustomFieldErr(val)}
                            sx={{
                                gridTemplateColumns: { sm: `repeat(1, 1fr)` }
                            }}
                        />
                    </FormProvider>

                </Box>
                <Divider />

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, px: 3, mt: 2 }}>
                    <Button variant='soft' color="inherit" onClick={() => {
                        toggle(false);
                        setItem(DEFAULT_NEW_EXTRAITEM);
                        methods.reset();
                    }}
                    >
                        {`${translate("commons.cancel")}`}
                    </Button>
                    <Button
                        variant="contained"
                        disabled={disabled}
                        onClick={() => { handleSave(); }}
                    >
                        {`${translate('commons.justSave')}`}
                    </Button>
                </Box>
            </Card>
        </Modal>
    );
}

interface ShipModalProps {
    isOpen: boolean,
    toggle: (val: boolean) => void,
    id: string,
    name: string,
    onSubmit?: (id: string, action: "Close" | "Ship", date?: string) => void
}

export function ShipModal({ isOpen, id, toggle, name, onSubmit }: ShipModalProps) {

    const isDesktop = useResponsive('up', 'md');

    const { translate } = useLocales();

    const [shipDate, setShipDate] = useState<string | null>(null);

    const [invalidDate, setInvalidDate] = useState(false);

    const handleSubmit = async () => {

        if (shipDate) {
            if (onSubmit)
                onSubmit(id, "Ship", shipDate);
            else {
                await dispatch(containerOperations.shipContainer({ id, shippedOn: shipDate })).unwrap();
                dispatch(setSuccessMessage({ text: `${translate(`containers.messages.successShip`)}` }));
            }

            toggle(false);
            setShipDate(null);
            setInvalidDate(false);

        }

    };

    return (

        <Modal
            open={isOpen}
        >

            <Card
                sx={{
                    position: 'absolute',
                    top: '50%', left: '50%',
                    transform: 'translate(-50%, -50%)',
                    p: isDesktop ? 5 : 2,
                    maxWidth: "45%", minWidth: 450
                }}
            >
                <Typography variant="h3" textAlign={'center'}>{`${translate('containers.detail.ship')}`}</Typography>
                <Typography sx={{ mt: 1 }} textAlign={'center'}>{translate('containers.messages.insertDateShip')} {<b>{name}</b>}</Typography>

                <DatePicker
                    sx={{ width: '100%', my: 3 }}
                    views={['year', 'month', 'day']}
                    format={'dd/MM/yyyy'}
                    value={shipDate ? new Date(shipDate) : null}
                    onChange={(val, cont) => {

                        if (val && !cont.validationError) {
                            setShipDate(format(val, 'yyyy-MM-dd'));
                            setInvalidDate(false);
                        }
                        else
                            setInvalidDate(true);
                    }}
                    onAccept={(val) => {
                        setShipDate(val ? format(val, 'yyyy-MM-dd') : null);
                        setInvalidDate(false);
                    }} />
                <Box
                    sx={{
                        mt: 2,
                        justifyContent: 'space-between',
                        ml: isDesktop ? 'auto' : 0,
                        display: 'flex',
                        gap: 1,
                        flexDirection: isDesktop ? 'default' : 'column-reverse'
                    }}
                >
                    <Button
                        variant="soft"
                        size={isDesktop ? "small" : "medium"}
                        onClick={() => toggle(false)}
                        sx={{ mt: 3, mr: { xs: 0, sm: 2 }, borderRadius: "100px" }}
                    >
                        {`${translate("commons.cancel")}`}
                    </Button>
                    <Button
                        variant="contained"
                        color={"warning"}
                        disabled={!shipDate || invalidDate}
                        size={isDesktop ? "small" : "medium"}
                        onClick={() => handleSubmit()}
                        sx={{ mt: 3, ml: 2, borderRadius: "100px" }}
                    >
                        {`${translate("containers.detail.ship")}`}
                    </Button>
                </Box>
            </Card>
        </Modal>
    );

}

interface ShipModalProps {
    isOpen: boolean,
    toggle: (val: boolean) => void,
    id: string,
    name: string,
    onSubmit?: (id: string, action: "Close" | "Ship", date?: string) => void
}

export function CloseContainerModal({ isOpen, id, toggle, name, onSubmit }: ShipModalProps) {

    const isDesktop = useResponsive('up', 'md');

    const { translate } = useLocales();

    const handleSubmit = async () => {

        if (onSubmit)
            onSubmit(id, "Close");
        else {
            await dispatch(containerOperations.closeContainer(id)).unwrap();
            dispatch(setSuccessMessage({ text: translate(`containers.messages.successClose`) }));
        }

        toggle(false);

    };

    return (

        <Modal
            open={isOpen}
        >

            <Card
                sx={{
                    position: 'absolute',
                    top: '50%', left: '50%',
                    transform: 'translate(-50%, -50%)',
                    p: isDesktop ? 5 : 2,
                    maxWidth: "45%", minWidth: 450
                }}
            >
                <Typography variant="h3" textAlign={'center'}>{`${translate('commons.close')}`}</Typography>

                <Typography variant="body1" sx={{ my: 3 }}>
                    {`${translate('containers.messages.msgClose')} `}
                    <b>{name ?? ""}</b>
                    {"?"}
                </Typography>
                <Box
                    sx={{
                        mt: 2,
                        justifyContent: 'space-between',
                        ml: isDesktop ? 'auto' : 0,
                        display: 'flex',
                        gap: 1,
                        flexDirection: isDesktop ? 'default' : 'column-reverse'
                    }}
                >
                    <Button
                        variant="soft"
                        size={isDesktop ? "small" : "medium"}
                        onClick={() => toggle(false)}
                        sx={{ mt: 3, mr: { xs: 0, sm: 2 }, borderRadius: "100px" }}
                    >
                        {`${translate("commons.cancel")}`}
                    </Button>
                    <Button
                        variant="contained"
                        color={"error"}
                        size={isDesktop ? "small" : "medium"}
                        onClick={() => handleSubmit()}
                        sx={{ mt: 3, ml: 2, borderRadius: "100px" }}
                    >
                        {`${translate("commons.close")}`}
                    </Button>
                </Box>
            </Card>
        </Modal>
    );

}