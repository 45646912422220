import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, Box, FormControlLabel, Switch, TablePagination, Divider, Typography, TableCell, TableRow, Table, TableBody, CircularProgress, TableContainer, } from '@mui/material';
import { dispatch, RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import useLocales from 'src/appHooks/useLocales';
import useTable from 'src/appHooks/useTable';
import { useForm } from 'react-hook-form';
import FormProvider from 'src/components/hook-form';
import { ToolbarSearchFilters } from 'src/@types/commons';
import { DEFAULT_ORDERS_FILTERS, OrderFilters } from 'src/@types/orders';
import { ordersOperations } from 'src/redux/orders';
import { Stack } from '@mui/system';
import { scrollBarStyle } from 'src/components/scrollbar/Scrollbar';
import { TableHeadCustom, TableNoData } from 'src/components/table';
import { isArray, isEqual, isObject, remove } from 'lodash';
import { detailGeneric } from 'src/services/organizationsService';
import OrdersFilterSidebar from 'src/sections/@dashboard/orders/filters/OrdersFilterSidebar';
import OrdersFilterSummary from 'src/sections/@dashboard/orders/filters/OrdersFilterSummary';
import OrdersGeneralFilters from 'src/sections/@dashboard/orders/filters/OrdersGeneralFilters';
import ItemsTableRow from 'src/sections/@dashboard/orders/list/ItemsTableRow';
import OrdersTableRow from 'src/sections/@dashboard/orders/list/OrdersTableRow';

const BASIC_FILTER_OPTIONS = {
  pageIndex: 0,
  pageSize: 10,
  sortField: '',
  sortAscending: false
};

interface OrganizationOrdersProps {
  organizationID?: string;
  isVendor: boolean
}

export default function OrdersList({ organizationID, isVendor }: OrganizationOrdersProps) {
  const {
    page,
    setPage,
    order,
    orderBy,
    rowsPerPage,
    setRowsPerPage,
    dense,
    onChangePage,
    onChangeDense,
    onChangeRowsPerPage,
    onSort,
  } = useTable();

  const { translate, currentLang } = useLocales();

  const { isOrderLoading, orderList, totalCount, orderPageIndex: pageIndex, orderPageSize: pageSize } = useSelector((state: RootState) => state.orders);

  const [filters, setFilters] = useState<OrderFilters>({ ...DEFAULT_ORDERS_FILTERS });

  const [lastUsedLang, setLastUsedLang] = useState(currentLang.label);

  const [openSidebar, setOpenSidebar] = useState(false);

  const [resetForm, setResetForm] = useState(true);

  const [resetFormElement, setResetFormElement] = useState('');

  const [showSummary, setShowSummary] = useState(false);

  const methods = useForm({ defaultValues: DEFAULT_ORDERS_FILTERS });

  const { reset, getValues, watch, setValue } = methods;

  var formValues = watch();

  //---- IS DEFAULT FUNC ----//
  // Checks if there are some filters selected
  const keysToRemove: string[] = useMemo(() => ['pageIndex', 'pageSize', 'sortField', 'sortAscending'], []);

  const keyRemover = (key: string) => {
    return !keysToRemove.includes(key);
  };

  const isDefault = (filter: OrderFilters, check?: OrderFilters) => {

    const controller = check ? check : DEFAULT_ORDERS_FILTERS;

    const found = remove(Object.keys(controller), keyRemover)
      .find((element) => {
        if (filter[element]) {
          if (isArray(filter[element]) && isArray(controller[element])) {
            return !isEqual(filter[element], controller[element]);
          } else if (isObject(filter[element]) && isObject(controller[element])) {

            const strFilters = JSON.stringify(filter[element], Object.keys(filter[element]).sort());

            const strDefaultFilters = JSON.stringify(controller[element], Object.keys(controller[element]).sort());

            return strFilters !== strDefaultFilters;
          } else {
            return filter[element] !== controller[element];
          }
        }

        return false;
      });

    return !found;
  };

  //---- TOOLBAR OPTIONS ----//
  const toolbarFiltersList: ToolbarSearchFilters[] = useMemo(() => [
    { key: 'itemExternalId', label: `${translate('request.partReference')}` },
    { key: 'documentExternalId', label: `${translate('request.documentReference')}` },
    { key: 'itemCustomFields', label: `${translate('request.detail.depositReference')}` },
  ], [translate]);

  //---- CLOSE AND OPEN SIDEBAR FUNC
  const handleOpenSidebar = () => {
    setOpenSidebar(true);
  };

  const handleCloseSidebar = () => {
    if (resetForm) {
      reset(DEFAULT_ORDERS_FILTERS);
    }
    setOpenSidebar(false);
  };

  //---- CLEAR FROM SUMMARY FUNC
  const handleClearFromSummary = (section: string) => {
    setResetFormElement(section);
    if (isDefault(formValues)) {
      setResetForm(true);
    }
  };

  //---- FILTERS IN URL GET/SET START ----//
  const location = useLocation();

  const [firstRender, setFirstRender] = useState(true);

  const removeHash = () => {
    var uri = window.location.toString();

    if (uri.indexOf('#') > 0) {
      var clean_uri = uri.substring(0, uri.indexOf('#'));

      window.history.replaceState({}, document.title, clean_uri);
    }
  };

  const updateFiltersInUrl = useCallback((filters: any) => {

    const queryString = Object.keys(filters)
      .filter((x) => filters[x] && filters[x] !== DEFAULT_ORDERS_FILTERS[x])
      .map((key) => {
        if (isArray(filters[key]) || isObject(filters[key])) {
          return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(filters[key]).replace(/stellantis-europe-/g, ""))}`;
        }

        return `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`;
      })
      .join('&');

    window.location.hash = queryString;

    if (!queryString) removeHash();
  }, []);

  const getPageAndSize = useCallback(() => {
    if (firstRender) {
      return [pageSize, pageIndex];
    }
    else return [rowsPerPage, page];
  }, [firstRender, page, pageIndex, pageSize, rowsPerPage]);

  const getFiltersFromUrl = useCallback(() => {
    const { hash } = location;

    let searchFilters = {};

    const [pageSizeFunc, pageIndexFunc] = getPageAndSize();

    if (hash) {
      const cleanedHash = hash.slice(1);

      const decodedQuery = decodeURIComponent(cleanedHash);

      const searchParams = new URLSearchParams(decodedQuery);

      searchFilters = {
        pageIndex: pageIndexFunc,
        pageSize: pageSizeFunc,
        sortField: orderBy || null,
        sortAscending: orderBy ? (order === 'desc' ? true : false) : null,
        documentExternalId: searchParams.get('documentExternalId'),
        customerId: searchParams.get('customerId'),
        from: searchParams.get('from'),
        to: searchParams.get('to'),
        itemName: searchParams.get('itemName'),
        itemExternalId: searchParams.get('itemExternalId'),
        itemCustomFields: searchParams.get('itemCustomFields') ? JSON.parse(searchParams.get('itemCustomFields')!.replace(/spareparts/g, "stellantis-europe-spareparts")) : null,
        prettyName: searchParams.get('prettyName'),
        tags: searchParams.get('tags') ? JSON.parse(searchParams.get('tags')!) : null,
        customFields: searchParams.get('customFields') ? JSON.parse(searchParams.get('customFields')!.replace(/order/g, "stellantis-europe-order")) : null
      };

    } else {
      searchFilters = {
        pageIndex: pageIndexFunc,
        pageSize: pageSizeFunc,
        sortField: orderBy || null,
        sortAscending: orderBy ? (order === 'desc' ? true : false) : null,
      };
    }

    searchFilters = Object.fromEntries(Object.entries(searchFilters).filter(([_, value]) => value !== null && value !== 'null'));

    return searchFilters as OrderFilters;

  }, [getPageAndSize, location, order, orderBy]);
  //---- FILTERS IN URL GET/SET END ----//

  //---- FETCH DATA FUNC ----//
  // Gets all filter values ​​other than the default ones and puts them in the url
  const fetchData = useCallback(
    async (values: OrderFilters) => {
      var searchFilters: any = {};

      if (values === DEFAULT_ORDERS_FILTERS) {
        searchFilters = BASIC_FILTER_OPTIONS;
      } else {
        searchFilters = {
          pageIndex: page,
          pageSize: rowsPerPage,
          sortField: orderBy,
          sortAscending: order === 'desc' ? true : false,
          documentExternalId: values.documentExternalId !== DEFAULT_ORDERS_FILTERS.documentExternalId ? values.documentExternalId : null,
          customerId: values.customerId !== DEFAULT_ORDERS_FILTERS.customerId ? values.customerId : null,
          from: values.from !== DEFAULT_ORDERS_FILTERS.from ? values.from : null,
          to: values.to !== DEFAULT_ORDERS_FILTERS.to ? values.to : null,
          itemName: values.itemName !== DEFAULT_ORDERS_FILTERS.itemName ? values.itemName : null,
          itemExternalId: values.itemExternalId !== DEFAULT_ORDERS_FILTERS.itemExternalId ? values.itemExternalId : null,
          itemCustomFields: JSON.stringify(values.itemCustomFields) !== JSON.stringify(DEFAULT_ORDERS_FILTERS.itemCustomFields) ? values.itemCustomFields : null,
          prettyName: values.prettyName !== DEFAULT_ORDERS_FILTERS.prettyName ? values.prettyName : null,
          tags: values.tags && values.tags.length > 0 ? values.tags : null,
          customFields: JSON.stringify(values.customFields) !== JSON.stringify(DEFAULT_ORDERS_FILTERS.customFields) ? values.customFields : null
        };
      }

      updateFiltersInUrl(searchFilters);
    },
    [order, orderBy, page, rowsPerPage, updateFiltersInUrl]
  );

  //---- SEARCH FOR ITEMS AND STATISTICS FUNC ----//
  // This function is used to call APIs and get Orders' list
  const getVendorAndCustomerId = useCallback((filtersFromUrl: OrderFilters) => {
    //IF THIS LIST IS SHOWN IN SOME ORGANIZATION DETAIL
    if (organizationID) {
      return {
        ...filtersFromUrl,
        organizationId: organizationID,
      };
    }

    return filtersFromUrl;
  }, [organizationID]);

  const onSearch = useCallback(async (filtersFromUrl: OrderFilters) => {

    updateFiltersInUrl(filtersFromUrl);

    const filtersFromUrlCopy = JSON.parse(JSON.stringify(filtersFromUrl));

    if (filtersFromUrlCopy.from && filtersFromUrlCopy.to) {
      filtersFromUrlCopy.from = new Date(filtersFromUrlCopy.from || "").toISOString();
      filtersFromUrlCopy.to = new Date(new Date(filtersFromUrlCopy.to || "").setHours(24, 59, 59)).toISOString();
    }

    const customFieldsFromUrl: Record<string, string> = Object.entries(filtersFromUrlCopy.customFields || {})
      .map(([k, val]) => ({
        key: "customFields." + k,
        value: val
      }))
      .reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});

    const itemCustomFieldsFromUrl: Record<string, string> = Object.entries(filtersFromUrlCopy.itemCustomFields || {})
      .map(([k, val]) => ({
        key: "itemCustomFields." + k,
        value: val
      }))
      .reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});

    const updatedFiltersFromUrl = { ...filtersFromUrlCopy, ...customFieldsFromUrl, ...itemCustomFieldsFromUrl };

    delete updatedFiltersFromUrl.customFields;

    delete updatedFiltersFromUrl.itemCustomFields;

    if (isVendor) dispatch(ordersOperations.searchVendorOrders(getVendorAndCustomerId(updatedFiltersFromUrl)));
    else dispatch(ordersOperations.searchCustomerOrders(getVendorAndCustomerId(updatedFiltersFromUrl)));

    if (filtersFromUrl.customerId && filtersFromUrl.customerId !== getValues("customer")?.id) {
      const { data } = await detailGeneric(filtersFromUrl.customerId);

      setValue("customer", data);
      setShowSummary(true);
    }

    if (firstRender) setFirstRender(false);

  }, [getVendorAndCustomerId, isVendor, updateFiltersInUrl, firstRender, getValues, setValue]);

  //---- SEARCH FOR ITEMS AND STATISTICS HOOK ----//
  // This hook is used to call onSearch when filters or language are changed
  useEffect(() => {
    let searchFilters = getFiltersFromUrl();

    const strSearchFilters = JSON.stringify(searchFilters, Object.keys(searchFilters).sort());

    const strFilters = JSON.stringify(filters, Object.keys(filters).sort());

    if (strSearchFilters !== strFilters || lastUsedLang !== currentLang.label) {
      setFilters(searchFilters as OrderFilters);
      onSearch(searchFilters as OrderFilters);
      setLastUsedLang(currentLang.label);
      if (searchFilters.pageIndex !== page) setPage(searchFilters.pageIndex);
      if (searchFilters.pageSize !== rowsPerPage) setRowsPerPage(searchFilters.pageSize);
    }
  }, [location, page, rowsPerPage, orderBy, order, currentLang, filters, getFiltersFromUrl, lastUsedLang, onSearch, setPage, setRowsPerPage]);

  //---- FILTERS SEARCH FUNC ----//
  // Used for search buttons in filters
  const handleSearchFilters = () => {
    fetchData(getValues());
    setResetForm(false);
    setOpenSidebar(false);
    setPage(0);
  };

  //---- FILTERS RESET ALL FUNC ----//
  // Used for full clear buttons in filters
  const handleResetAllFilter = () => {
    if (openSidebar) {
      handleCloseSidebar();
    }
    setPage(0);
    setResetForm(true);
    fetchData(DEFAULT_ORDERS_FILTERS);
    reset(DEFAULT_ORDERS_FILTERS);
    setShowSummary(true);
  };

  const handleResetSingleFilter = (fieldName: string, value?: any) => {
    if (isArray(formValues[fieldName])) {
      const index = formValues[fieldName].indexOf(value);

      if (index > -1) {
        formValues[fieldName].splice(index, 1);
        setValue(fieldName, formValues[fieldName]);
        handleClearFromSummary(fieldName);
        fetchData(formValues);
      }
    } else if (isObject(formValues[fieldName])) {

      delete (formValues[fieldName] as Record<string, string>)[value];

      setValue(fieldName, formValues[fieldName]);
      handleClearFromSummary(fieldName);
      fetchData(formValues);

    } else {
      formValues[fieldName] = DEFAULT_ORDERS_FILTERS[fieldName];
      setValue(fieldName, DEFAULT_ORDERS_FILTERS[fieldName]);
      handleClearFromSummary(fieldName);
      fetchData(formValues);
    }
  };

  //---- HANDLE TABLE START ----//
  const [clickedIndex, setClickedIndex] = useState<number>();

  const TABLE_HEAD = [
    {
      id: 'arrow',
      label: '',
      align: 'left',
      sortable: false,
    },
    {
      id: 'deliveryNote',
      label: `${translate('orders.tableHeaders.deliveryNote')}`,
      align: 'left',
    },
    {
      id: 'invoice',
      label: `${translate('orders.tableHeaders.invoice')}`,
      align: 'left',
    },
    {
      id: 'quantity',
      label: `${translate('orders.tableHeaders.quantity')}`,
      align: 'left',
      sortable: false,
    },
    {
      id: 'grossAmount',
      label: `${translate('orders.tableHeaders.grossAmount')}`,
      align: 'left',
    },
    {
      id: 'discountAmount',
      label: `${translate('orders.tableHeaders.discountAmount')}`,
      align: 'left',
    },
    {
      id: 'netAmount',
      label: `${translate('orders.tableHeaders.netAmount')}`,
      align: 'left',
    },
    {
      id: 'createdOn',
      label: `${translate('orders.tableHeaders.createdOn')}`,
      align: 'left',
    },
    {
      id: 'options',
      label: ``,
      align: 'center',
      sortable: false,
    },
  ];

  useEffect(() => {
    if (totalCount <= rowsPerPage * page) onChangePage(null, 0);
  }, [onChangePage, page, rowsPerPage, totalCount]);
  //---- HANDLE TABLE END ----//

  return (
    <>
      <Card>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: { xs: 2, md: 3.5 }, pb: 2
          }}
        >
          <Box>
            {!organizationID && (
              <Typography variant="body2" sx={{ mt: 2 }}>
                {`${translate('orders.messages.subtitle')}`}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              mt: 2,
              display: 'flex',
            }}
          >
            <FormProvider methods={methods}>
              <OrdersFilterSidebar
                isDefault={isDefault}
                isOpen={openSidebar}
                onFilter={handleSearchFilters}
                onOpen={handleOpenSidebar}
                onClose={handleCloseSidebar}
                onResetAll={handleResetAllFilter}
                reset={resetForm}
                resetFormElement={resetFormElement}
                filters={{ ...formValues, ...getFiltersFromUrl() }}
                setShowSummary={setShowSummary}
                isVendor={isVendor}
                setResetFormElement={setResetFormElement}
              />
            </FormProvider>
          </Box>
        </Box>

        <FormProvider methods={methods}>
          <OrdersGeneralFilters
            //filters={{ ...DEFAULT_ORDERS_FILTERS, ...filters }}
            filters={{ ...formValues, ...(getFiltersFromUrl()) }}
            onFilter={handleSearchFilters}
            optionsFields={toolbarFiltersList}
            resetAll={handleResetAllFilter}
            setShowSummary={setShowSummary}
            showSummary={showSummary}
          />
        </FormProvider>

        <OrdersFilterSummary
          filters={openSidebar ? DEFAULT_ORDERS_FILTERS : { ...formValues, ...getFiltersFromUrl() }}
          isShowReset={!isDefault({ ...formValues, ...getFiltersFromUrl() }) && !openSidebar}
          onResetAll={handleResetAllFilter}
          onResetFilter={handleResetSingleFilter}
          show={showSummary}
        />

        <Divider />

        <Box>
          <TableContainer
            sx={[
              {
                position: 'relative',
                width: { xs: '100%', md: 'auto' },
                maxHeight: 500,
                height: dense ? '' : rowsPerPage <= 5 ? 400 : 500,
                '& .MuiTableRow-root': { height: 5 },
              },
              scrollBarStyle,
            ]}
          >
            <Table stickyHeader size={dense ? 'small' : 'medium'}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={totalCount}
                onSort={onSort}
              />

              {isOrderLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={12}>
                      <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          height: 1,
                          textAlign: 'center',
                          p: (theme) => theme.spacing(8, 2),
                        }}
                      >
                        <CircularProgress />
                      </Stack>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {orderList.map((order, index) => (
                    <Fragment key={index}>
                      <OrdersTableRow
                        index={index}
                        clickedIndex={clickedIndex}
                        setClickedIndex={setClickedIndex}
                        order={order}
                        isVendor={isVendor}
                      />

                      <ItemsTableRow
                        index={index}
                        clickedIndex={clickedIndex}
                        dense={dense}
                        order={order}
                      />
                    </Fragment>
                  ))}
                  <TableNoData isNotFound={totalCount === 0} />
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <Box
            sx={{
              position: 'relative',
              width: { xs: '90vw', md: 'auto' },
            }}
          >
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 30]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              labelRowsPerPage={`${translate('commons.rowsPerPage')}`}
              sx={{
                overflow: "hidden",
                "& .MuiTablePagination-input": {
                  ml: { xs: 0.5, md: "default" },
                  mr: { xs: 3.5, md: "default" }
                }
              }}
            />
            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label={`${translate('commons.dense')}`}
              sx={{
                px: { xs: 0, sm: 3 },
                py: { xs: 0, sm: 1.5 },
                pb: { xs: 1.5, sm: 0 },
                mx: 0,
                top: 0,
                justifyContent: 'center',
                width: { xs: '90vw', sm: 'auto' },
                position: { sm: 'absolute' },
              }}
            />
          </Box>
        </Box>
      </Card>

      <Typography sx={{ textAlign: 'right', mt: 3, mr: 1 }} fontSize={'13px'}>
        {`${translate('orders.messages.vatAdvice')}`}
      </Typography>
    </>
  );
}
