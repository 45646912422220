import { useParams } from "react-router";
import Page from "src/appComponents/Page";
import { useLocales } from "src/locales";
import TermsConditionForm from "../../../sections/@dashboard/termsCondition/TermsConditionForm";
import LoadingScreen from "src/appComponents/loading-screen";
import { useSelector } from "react-redux";
import { dispatch, RootState } from "src/redux/store";
import { useEffect } from "react";
import { termConditionOperations } from "src/redux/termCondition";

export default function NewEditTermsCondition() {

    const { translate } = useLocales();

    const { id } = useParams();

    const { isLoading, termCondition } = useSelector((state: RootState) => state.terms);

    useEffect(() => {
        if (id) dispatch(termConditionOperations.getTermCondition(id)).unwrap();
    }, [id]);

    return (
        <Page title={`${translate(`menu.management.termsCondition.${id ? 'update' : 'new'}`)}`}>
            {isLoading ?
                <LoadingScreen /> :
                <TermsConditionForm terms={id ? termCondition : null} key={id} />
            }
        </Page>
    );
}