import { Box } from "@mui/material";
import { Order } from "src/@types/orders";
import MoreDetails from "src/components/request-utils/MoreDetails";

interface CompleteNewPartsProps {
    order: Order
}

export default function CompleteNewParts({ order }: CompleteNewPartsProps) {

    return (
        <Box>
            {order && <MoreDetails order={order} />}
        </Box>
    );
}