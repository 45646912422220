import { useAuth } from '../appHooks/useAuth';
import Error403 from './errors/403';

// ----------------------------------------------------------------------

type GodGuardProp = {
  hasContent?: boolean;
  children: React.ReactNode;
};

export default function GodGuard({
  hasContent,
  children,
}: GodGuardProp) {
  const { user } = useAuth();

  if (!user.isGod) {
    return hasContent ? (
      <Error403 />
    ) : null;
  }

  return <>{children}</>;
}