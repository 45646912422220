import axios, { AxiosResponse } from 'axios';
import { Core } from './paths';
import { NewRole, Role, UpdateRole } from 'src/@types/role';
import { PagedResponseType } from 'src/@types/commons';

//---- ADMINISTRATIVE ROLE ----//      

const administrationRoleCreate = (role: NewRole): Promise<AxiosResponse<Role>> => {
    const url = Core.admin.role.create();

    return axios.post(url, role);
};

const administrationRoleUpdate = (role: UpdateRole): Promise<AxiosResponse<Role>> => {
    const url = Core.admin.role.update();

    return axios.put(url, role);
};

const administrationRoleDisable = (roleid: string): Promise<AxiosResponse> => {
    const url = Core.admin.role.disable(roleid);

    return axios.patch(url);
};

const administrationRoleEnable = (roleid: string): Promise<AxiosResponse> => {
    const url = Core.admin.role.enable(roleid);

    return axios.patch(url);
};

const administrationRoleSearch = (options: { pageIndex: number, pageSize: number }): Promise<AxiosResponse<PagedResponseType<Role>>> => {
    const url = Core.admin.role.search();

    return axios.get<PagedResponseType<Role>>(url, {
        params:
        {
            pageIndex: options.pageIndex.toString(),
            pageSize: options.pageSize.toString()
        }
    });
};

const administrationRoleSearchLogs = (id: string, filters: any): Promise<AxiosResponse<PagedResponseType<any>>> => {
    const url = Core.admin.role.searchLogs(id);

    return axios.get<any>(url, { params: filters });
};

const administrationRoleDetail = (roleid: string): Promise<AxiosResponse<Role>> => {
    const url = Core.admin.role.detail(roleid);

    return axios.get(url);
};

//---- ORGANIZATION ROLE ----// 

const organizationRoleCreate = (role: NewRole): Promise<AxiosResponse<Role>> => {
    const url = Core.admin.role.organizations.create();

    return axios.post(url, role);
};

const organizationRoleUpdate = (role: UpdateRole): Promise<AxiosResponse<Role>> => {
    const url = Core.admin.role.organizations.update();

    return axios.put(url, role);
};

const organizationRoleDisable = (roleid: string): Promise<AxiosResponse> => {
    const url = Core.admin.role.organizations.disable(roleid);

    return axios.patch(url);
};

const organizationRoleEnable = (roleid: string): Promise<AxiosResponse> => {
    const url = Core.admin.role.organizations.enable(roleid);

    return axios.patch(url);
};

const organizationRoleSearch = (options: {
    pageIndex: number,
    pageSize: number
}): Promise<AxiosResponse<PagedResponseType<Role>>> => {
    const url = Core.admin.role.organizations.search();

    return axios.get<PagedResponseType<Role>>(url, {
        params: {
            pageIndex: options.pageIndex,
            pageSize: options.pageSize
        }
    });
};

const organizationRoleSearchLogs = (id: string, filters: any): Promise<AxiosResponse<PagedResponseType<any>>> => {
    const url = Core.admin.role.organizations.searchLogs(id);

    return axios.get<any>(url, { params: filters });
};

const organizationRoleDetail = (id: string): Promise<AxiosResponse<Role>> => {
    const url = Core.admin.role.organizations.detail(id);

    return axios.get(url);
};

export {
    administrationRoleCreate,
    administrationRoleUpdate,
    administrationRoleEnable,
    administrationRoleDisable,
    administrationRoleSearch,
    administrationRoleSearchLogs,
    administrationRoleDetail,
    organizationRoleCreate,
    organizationRoleDetail,
    organizationRoleUpdate,
    organizationRoleSearchLogs,
    organizationRoleSearch,
    organizationRoleEnable,
    organizationRoleDisable
};