import { createAsyncThunk } from "@reduxjs/toolkit";
import { startCustomerCareLoading, startLoading, startLogsLoading, startOwnerLoading, startSearchLoading } from "./organizations-slices";
import { OrganizationFilters, GenericOrganizationForEdit } from "src/@types/organizations";
import removeEmptyKeys from "src/utils/removeEmptyKeys";
import { searchVendorStatistics, searchVendor, getNonEditables as nonEditables, createGeneric as createGenericService, updateGeneric as updateGenericService, detailGeneric, searchLogs, searchCustomer, searchCustomerStatistics, getOwners, detailEdit, searchSurveillance } from "src/services/organizationsService";

const searchVendorOrganizationsPowerSearch = createAsyncThunk(
    'customer/searchVendorOrganizationsPowerSearch',
    async (options: { pageIndex: number, pageSize: number, all: string }, { rejectWithValue }) => {
        try {
            const { data } = await searchVendor(options);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;

        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const searchCustomerOrganizationsPowerSearch = createAsyncThunk(
    'customer/searchCustomerOrganizationsPowerSearch',
    async (options: { pageIndex: number, pageSize: number, all: string }, { rejectWithValue }) => {
        try {
            const { data } = await searchCustomer(options);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;

        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const searchVendorOrganizations = createAsyncThunk(
    'organizations/searchVendorOrganizations',
    async (options: OrganizationFilters, { rejectWithValue, dispatch }) => {
        dispatch(startSearchLoading());
        try {
            const { data } = await searchVendor(removeEmptyKeys(options));

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const searchVendorStatistic = createAsyncThunk(
    'organizations/searchVendorStatistic',
    async (options: OrganizationFilters, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await searchVendorStatistics(removeEmptyKeys(options));

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const searchCustomerOrganizations = createAsyncThunk(
    'organizations/searchCustomerOrganizations',
    async (options: OrganizationFilters, { rejectWithValue, dispatch }) => {
        dispatch(startSearchLoading());
        try {
            const { data } = await searchCustomer(removeEmptyKeys(options));

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const searchCustomerStatistic = createAsyncThunk(
    'organizations/searchCustomerStatistic',
    async (options: OrganizationFilters, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await searchCustomerStatistics(removeEmptyKeys(options));

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const getNonEditables = createAsyncThunk(
    'organizations/getNonEditables',
    async (_, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await nonEditables();

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const getOwner = createAsyncThunk(
    'organizations/getOwner',
    async (id: string, { rejectWithValue, dispatch }) => {
        dispatch(startOwnerLoading());
        try {
            const { data } = await getOwners(id);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const getCustomerCareVendors = createAsyncThunk(
    'organizations/getCustomerCareVendors',
    async (id: string, { rejectWithValue, dispatch }) => {
        dispatch(startCustomerCareLoading());
        try {
            const { data } = await getOwners(id);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

/* ------- GENERIC ------- */
const createGeneric = createAsyncThunk(
    'organizations/createGeneric',
    async (organization: GenericOrganizationForEdit, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {

            Object.keys(organization).forEach(key => {
                if (["createdOn", "id"].includes(key)) {
                    delete organization[key];
                }
            });

            const { data } = await createGenericService(removeEmptyKeys(organization));

            if (!data) {
                throw new Error('Something went wrong');
            }
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const updateGeneric = createAsyncThunk(
    'organizations/updateGeneric',
    async (organization: GenericOrganizationForEdit, { rejectWithValue, dispatch }) => {
        try {

            Object.keys(organization).forEach(key => {
                if (["createdOn"].includes(key)) {
                    delete organization[key];
                }
            });

            const { status } = await updateGenericService(removeEmptyKeys(organization));

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return true;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.message);
        }
    }
);

const getGeneric = createAsyncThunk(
    'organizations/getGeneric',
    async (id: string, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await detailGeneric(id);

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const getDetailForEdit = createAsyncThunk(
    'organizations/getDetailForEdit',
    async (id: string, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await detailEdit(id);

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const getHub = createAsyncThunk(
    'organizations/getHub',
    async (id: string, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await detailGeneric(id);

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const getOrganizationLogs = createAsyncThunk(
    'organizations/getOrganizationLogs', async (options: { id: string, filters: any }, { rejectWithValue, dispatch }) => {
        dispatch(startLogsLoading());
        try {

            const { data } = await searchLogs(options.id, removeEmptyKeys(options.filters));

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const getOrganizationSurveillance = createAsyncThunk(
    'organizations/getOrganizationSurveillance',
    async (id: string, { rejectWithValue, dispatch }) => {
        //dispatch(startLoading());
        try {
            const { data } = await searchSurveillance(id);

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const organizationsOperations = {
    searchVendorOrganizationsPowerSearch,
    searchCustomerOrganizationsPowerSearch,
    getNonEditables,
    createGeneric,
    updateGeneric,
    getGeneric,
    getHub,
    getOrganizationLogs,
    searchVendorOrganizations,
    searchVendorStatistic,
    searchCustomerOrganizations,
    searchCustomerStatistic,
    getOwner,
    getCustomerCareVendors,
    getDetailForEdit,
    getOrganizationSurveillance
};

export default organizationsOperations;
