import { MenuItem, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { IconButtonAnimate } from "src/components/animate";
import MenuPopover from "src/components/menu-popover";
import Scrollbar from "src/components/scrollbar";
import { useSettingsContext } from "src/components/settings";
import { useLocales } from "src/locales";

export default function MetricSystemToggle() {
    const { metricSystem, onChangeMetricSystem } = useSettingsContext();

    const { translate } = useLocales();

    const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

    const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
        setOpenPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpenPopover(null);
    };

    const handleChange = (newM: boolean) => {
        if (newM !== metricSystem && newM !== null)
            onChangeMetricSystem();
        handleClosePopover();
    };

    return (<>
        <IconButtonAnimate
            onClick={handleOpenPopover}
            sx={{
                width: 40,
                height: 40,
                ...(openPopover && {
                    bgcolor: 'action.selected',
                }),
            }}
        >
            <Typography color={openPopover ? "primary" : "default"} variant="subtitle1">{metricSystem ? 'Me' : 'Im'}</Typography>
        </IconButtonAnimate>
        <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 180 }}>
            <Scrollbar>
                <Stack spacing={0.75}>

                    <MenuItem
                        selected={metricSystem}
                        onClick={() => handleChange(true)}
                    >
                        <Typography
                            variant='inherit'
                            sx={{ ml: 1 }}>
                            {`${translate('commons.measurementSystem.metric')} (Me)`}
                        </Typography>
                    </MenuItem>

                    <MenuItem
                        selected={!metricSystem}
                        onClick={() => handleChange(false)}
                    >
                        <Typography
                            variant='inherit'
                            sx={{ ml: 1 }}>
                            {`${translate('commons.measurementSystem.imperial')} (Im)`}

                        </Typography>
                    </MenuItem>

                </Stack>
            </Scrollbar>
        </MenuPopover>
    </>
    );

}