import { Box, Stack, Switch, Typography } from "@mui/material";

interface BoxSwitchProps {
    label: string,
    value?: boolean,
    subtitle?: string,
    onChange?: (val: boolean) => void,
    hideSwitch?: boolean,
    required?: boolean
}

export default function BoxSwitch({ onChange, label, subtitle, value, hideSwitch, required }: BoxSwitchProps) {

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Stack>
                <Box sx={{ display: 'flex', gap: 0.5 }}>
                    <Typography variant="h6">{label}</Typography>
                    {required && <Typography variant="h6" color={"error.main"}>*</Typography>}
                </Box>
                {!!subtitle && <Typography variant="body2">{subtitle}</Typography>}
            </Stack>
            {!hideSwitch &&
                <Switch
                    checked={value}
                    onChange={(_, checked) => onChange && onChange(checked)}
                />
            }
        </Box>
    );
}