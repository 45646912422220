export function FormatString(str: string, ...val: string[]) {
    for (let index = 0; index < val.length; index++) {
        str = str.replace(`{${index}}`, val[index]);
    }

    return str;
}

export function getFirstLowerCase(str: string) {
    return str.charAt(0).toLowerCase() + str.slice(1);
}

export function getFirstUpperCase(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}