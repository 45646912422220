import * as signalR from "@microsoft/signalr";

class Connector {

    private connection: signalR.HubConnection;

    public constructor(URL: string, token: () => Promise<string>) {

        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(URL, {

                accessTokenFactory: token,
                transport: signalR.HttpTransportType.WebSockets
            })
            .withAutomaticReconnect()
            .build();
        this.connection.start().catch(err => console.error(err));

    }

    public setEvents(target: string, newHandler: ((payload: any) => void)) {
        //this "off" is necessary to prevent the same event from being loaded multiple times in particular conditions
        this.connection.off(target);
        this.connection.on(target, newHandler);
    }

}

export default Connector;