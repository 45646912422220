import { Box, Container, Tab, Tabs } from "@mui/material";
import LoadingScreen from "src/appComponents/loading-screen";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useEffect, useMemo, useState } from "react";
import { useLocales } from "src/locales";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "src/redux/store";
import { reportsOperations } from "src/redux/reports";
import Page from "src/appComponents/Page";
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from "src/components/settings";
import { ReportDetailTabs, ReportTypeData } from "src/@types/report";
import ReportGeneralDetail from "src/sections/@dashboard/report/detail/ReportGeneralDetail";
import ReportExecutionDetail from "src/sections/@dashboard/report/detail/ReportExecutionsDetail";

interface ReportDetailProps {
    isScheduled?: boolean
}

export default function ReportDetail({ isScheduled }: ReportDetailProps) {

    const { themeStretch } = useSettingsContext();

    const { translate } = useLocales();

    const navigate = useNavigate();

    const { report, isLoading } = useSelector((state: RootState) => state.reports);

    const { id, tab, type } = useParams();

    const [currentTab, setCurrentTab] = useState(tab);

    const backPath = useMemo(() => isScheduled ? PATH_DASHBOARD.reports.scheduled : PATH_DASHBOARD.reports.recurring, [isScheduled]);

    const category = useMemo(() => {

        if (type) {
            if (type.toLowerCase() === "container")
                return "shipping";
            if (type.toLowerCase() === "returnrequest" || type.toLowerCase() === "document")
                return "order";
        }

        return "";
    }, [type]);

    //GET REPORT DATA
    useEffect(() => {
        dispatch(reportsOperations.getReport({ id: id!, isScheduled: !!isScheduled, type: type as ReportTypeData, category: category }));
    }, [category, id, isScheduled, type]);

    //MANAGE TABS
    const onTabChange = (newTab: ReportDetailTabs) => {
        setCurrentTab(newTab);

        //navigate(backPath.detail(id!, newTab));

        if (isScheduled) navigate(PATH_DASHBOARD.reports.scheduled.detail(id!, newTab, type as ReportTypeData));
        navigate(PATH_DASHBOARD.reports.recurring.detail(id!, newTab, type as ReportTypeData));
    };

    const TABS = useMemo(() => [
        {
            value: 'general',
            label: `${translate('report.detail.tabs.general')}`,
            component: report && (
                <ReportGeneralDetail backPath={backPath} isScheduled={!!isScheduled} />
            ),
        },
        ...(!isScheduled ? [{
            value: 'download',
            label: `${translate('report.detail.tabs.download')}`,
            component: report && (
                <ReportExecutionDetail />
            ),
        },] : [])
    ], [translate, report, backPath, isScheduled]);

    return (
        <Page title={`${translate(isScheduled ? 'menu.management.reports.scheduled.title' : 'menu.management.reports.recurring.title')}`}>
            <Container maxWidth={themeStretch ? false : 'lg'}>

                <HeaderBreadcrumbs
                    heading={`${translate(isScheduled ? 'menu.management.reports.scheduled.title' : 'menu.management.reports.recurring.title')}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate(isScheduled ? 'menu.management.reports.scheduled.short' : 'menu.management.reports.recurring.short')}`, href: backPath.list },
                        { name: `${report?.name}` }
                    ]}
                />

                {isLoading ? <LoadingScreen /> :
                    <>
                        {!isScheduled &&
                            <Tabs
                                value={currentTab}
                                onChange={(_, val) => onTabChange(val)}
                                sx={{ ml: 1 }}
                            >
                                {TABS.map((tab, index) => (
                                    <Tab key={index} value={tab.value} label={tab.label} />
                                ))}
                            </Tabs>
                        }

                        {TABS.map((tab, ind) => tab.value === currentTab && (
                            <Box key={ind} sx={{ my: 2 }}>
                                {tab.component}
                            </Box>
                        ))}
                    </>
                }
            </Container>
        </Page>
    );
}
