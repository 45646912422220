import axios, { AxiosResponse } from 'axios';
import { ApprovedMatrixData, IrvMatrixPreview, MatrixTemplateSearchResult, PNO, PNOPrices, RejectedMatrixData, RvMatrixSearchResult, UserMatrixTemplateDocument } from 'src/@types/matrix';
import { PagedResponseType, Tag } from 'src/@types/commons';

// Paths
import { Rv } from './paths';
import { omit } from "lodash";

const createMatrixTemplate = (matrix: UserMatrixTemplateDocument) => {
    const url = Rv.admin.matrixTemplate.createTemplate();

    return axios.post(url, matrix);
};

const createRvMatrix = (matrix: IrvMatrixPreview) => {
    const url = Rv.admin.rvMatrix.create();

    return axios.post(url, matrix);
};

const updateMatrixTemplate = (matrix: UserMatrixTemplateDocument) => {
    const url = Rv.admin.matrixTemplate.createTemplate();

    return axios.put(url, matrix);
};

const updateRvMatrix = (matrix: IrvMatrixPreview) => {
    const url = Rv.admin.rvMatrix.update();

    return axios.put(url, matrix);
};

const searchTemplateTags = (tag: string, size: number): Promise<AxiosResponse<Tag[]>> => {
    const url = Rv.admin.matrixTemplate.searchTags();

    return axios.get<Tag[]>(url, {
        params: {
            query: tag,
            limit: size
        }
    });
};

const searchMatrixTags = (tag: string, size: number): Promise<AxiosResponse<Tag[]>> => {
    const url = Rv.admin.rvMatrix.searchTags();

    return axios.get<Tag[]>(url, {
        params: {
            query: tag,
            limit: size
        }
    });
};

const searchMatrixTemplate = (options: any): Promise<AxiosResponse<PagedResponseType<MatrixTemplateSearchResult>>> => {
    const url = Rv.admin.matrixTemplate.search();

    return axios.get(url, {
        params: options,
        paramsSerializer: {
            indexes: null
        }
    });
};

const searchRvMatrixTemplate = (options: any): Promise<AxiosResponse<PagedResponseType<RvMatrixSearchResult>>> => {
    const url = Rv.admin.rvMatrix.search();

    return axios.get(url, {
        params: options,
        paramsSerializer: {
            indexes: null
        }
    });
};

const getPNO = (): Promise<AxiosResponse<PNO>> => {
    const url = Rv.admin.matrixTemplate.getPNO();

    return axios.get(url);
};

const getPNOPrices = (): Promise<AxiosResponse<PNOPrices[]>> => {
    const url = Rv.admin.matrixTemplate.getPNOPrice();

    return axios.get(url);
};

const detailTemplate = (id: string): Promise<AxiosResponse> => {
    const url = Rv.admin.matrixTemplate.detail(id);

    return axios.get<any>(url);
};

const detailRvMatrix = (id: string): Promise<AxiosResponse> => {
    const url = Rv.admin.rvMatrix.detail(id);

    return axios.get<any>(url);
};

const rvMatrixPreview = (matrix: UserMatrixTemplateDocument): Promise<AxiosResponse<IrvMatrixPreview>> => {
    const url = Rv.admin.rvMatrix.preview();

    return axios.post(url, matrix);
};

const enable = (matrixId: string): Promise<AxiosResponse> => {
    const url = Rv.admin.matrixTemplate.enable(matrixId);

    return axios.patch(url);
};

const approve = (matrixId: string, data: ApprovedMatrixData | RejectedMatrixData, type: string): Promise<AxiosResponse> => {
    const url = type === "Approve" ? Rv.admin.rvMatrix.approve(matrixId) : Rv.admin.rvMatrix.reject(matrixId);

    return axios.patch(url, data);
};

const disable = (matrixId: string): Promise<AxiosResponse> => {
    const url = Rv.admin.matrixTemplate.disable(matrixId);

    return axios.patch(url);
};

const enableRvMatrix = (matrixId: string): Promise<AxiosResponse> => {
    const url = Rv.admin.rvMatrix.enable(matrixId);

    return axios.patch(url);
};

const disableRvMatrix = (matrixId: string): Promise<AxiosResponse> => {
    const url = Rv.admin.rvMatrix.disable(matrixId);

    return axios.patch(url);
};

const uploadMatrix = (test: FormData) => {
    const url = Rv.admin.rvMatrix.fromFile();

    return axios.post(url, test, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

const getExcelRvMatrix = (id: string): Promise<AxiosResponse> => {
    const url = Rv.admin.rvMatrix.getExcelRvMatrix(id);

    return axios.get(url);
};

const downloadExcel = (downloadPath: string) => {
    window.open(`${Rv.admin.rvMatrix.downloadExcel(downloadPath)}`, '_blank');
};

const submitRvMatrix = (id: string): Promise<AxiosResponse> => {
    const url = Rv.admin.rvMatrix.submit(id);

    return axios.patch(url);
};

const getStatuses = (options: any): Promise<AxiosResponse> => {
    const url = Rv.admin.rvMatrix.matrixStatuses();

    return axios.get<any>(url, {
        params: options,
        paramsSerializer: {
            indexes: null
        }
    });
};

const getWidgetStatistics = (options: any): Promise<AxiosResponse> => {
    const url = Rv.admin.rvMatrix.matrixStatuses();

    return axios.get<any>(url, {
        params: { ...options, IncludeDisabled: false },
        paramsSerializer: {
            indexes: null
        }
    });
};

const getPnoStatistics = (options: any): Promise<AxiosResponse> => {
    const url = Rv.admin.rvMatrix.manufacturerStatistics();

    return axios.get<any>(url, {
        params: options,
        paramsSerializer: {
            indexes: null
        }
    });
};

const getPowersearchMatrixes = (options: any): Promise<AxiosResponse<any>> => {
    const url = Rv.admin.rvMatrix.search();

    return axios.get(url, {
        params: options,
        paramsSerializer: {
            indexes: null
        }
    });
};

const getPowersearchMatrixesTemplate = (options: any): Promise<AxiosResponse<any>> => {
    const url = Rv.admin.matrixTemplate.search();

    return axios.get(url, {
        params: options,
        paramsSerializer: {
            indexes: null
        }
    });
};

const searchMatrixLogs = (options: any)
    : Promise<AxiosResponse> => {
    const url = Rv.admin.rvMatrix.logs(options?.id);

    return axios.get<any>(url, {
        params: omit(options, "id"),
        paramsSerializer: {
            indexes: null
        }
    });
};

const defaultExport = {
    createMatrixTemplate,
    createRvMatrix,
    rvMatrixPreview,
    searchRvMatrixTemplate,
    uploadMatrix,
    getPNO,
    //
    getPNOPrices,
    searchTemplateTags,
    searchMatrixTags,
    searchMatrixTemplate,
    detailTemplate,
    detailRvMatrix,
    updateMatrixTemplate,
    updateRvMatrix,
    getExcelRvMatrix,
    enableRvMatrix,
    disableRvMatrix,
    submitRvMatrix,
    enable,
    disable,
    downloadExcel,
    getStatuses,
    getWidgetStatistics,
    getPowersearchMatrixes,
    getPowersearchMatrixesTemplate,
    searchMatrixLogs,
    approve,
    getPnoStatistics
};

export default defaultExport;
