import { Autocomplete, Box, Card, Stack, Switch, TextField, Typography } from '@mui/material';
import { useLocales } from 'src/locales';
import { useEffect, useMemo, useRef, useState } from 'react';
import { RequestNew } from 'src/@types/request';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'src/redux/store';
import { organizationsOperations } from 'src/redux/organizations';
import { Address, OrganizationSearchResult } from 'src/@types/organizations';
import useResponsive from 'src/hooks/useResponsive';
import IncrementalInput from 'src/utils/IncrementalInput';

interface DetailProps {
  request: RequestNew;
  onChange: (name: string, value: any, item?: boolean) => void;
  available: number;
  defaultAddress: OrganizationSearchResult,
  organization: string,
  isVendor: boolean
}

export default function DetailNewParts({
  request,
  onChange,
  available,
  defaultAddress,
  isVendor,
  organization
}: DetailProps) {
  const { translate } = useLocales();

  const isDesktop = useResponsive('up', 'sm');

  useEffect(() => {
    if (request.requestType === 'Core' && available > 0)
      onChange('quantity', 1, true);

    if (available === 0)
      onChange('quantity', 0, true);
  }, [available, request.requestType]);

  return (

    <Card sx={{ my: 2, width: '100%', flexDirection: 'column', gap: 2, display: 'flex', p: 2 }}>
      <Typography variant="subtitle1">{`${translate('request.form.complete')}`}</Typography>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          borderRadius: '8px',
          border: '1px solid',
          borderColor: '#E2E2E2',
          flexWrap: 'wrap'
        }}
      >
        <Stack sx={{ m: 2, mr: 'auto' }}>
          <Typography variant="h6">{`${translate('commons.quantity')}`} <span style={{ color: 'red' }}>*</span></Typography>
          <Typography variant="body2">{`${translate('request.selectQty')}`}</Typography>
        </Stack>

        <Box
          sx={{
            m: 2,
            ml: 'auto',
            mr: 2,
            display: 'flex',
            justifyContent: isDesktop ? 'end' : 'center',
            alignItems: 'center',

          }}
        >
          <Typography color={'text.secondary'} variant="caption" sx={{ pr: 2 }}>
            {`${translate('statuses.available')}`}: {available}
          </Typography>
          <IncrementalInput
            onChange={(name, val) => onChange(name, val, true)}
            defaultValue={
              available > 0 ?
                (request.requestType === 'Core' ? 1 : request.items[0].quantity) : 0
            }
            disabled={request.requestType === 'Core'}
            maxValue={available}
          />
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          borderRadius: '8px',
          border: '1px solid',
          borderColor: '#E2E2E2',
        }}
      >
        <Stack sx={{ m: 2, mr: 'auto' }}>
          <Typography variant="h6">{`${translate('request.big')}`}</Typography>
          <Typography variant="body2">{`${translate('request.messages.bigDesc')}`}</Typography>
        </Stack>
        <Box sx={{ m: 2, ml: 'auto' }}>
          <Switch
            name="bigAndHeavy"
            onChange={(e) => onChange(e.target.name, e.target.checked, true)}
          />
        </Box>
      </Box>

      <Box>
        <DeliveryAddressAutocomplete
          onChange={onChange}
          defaultAddress={defaultAddress}
          required
          organization={organization}
          isVendor={isVendor}
        />
      </Box>
    </Card>
  );
}

interface DeliveryAddressAutocompleteProps {
  onChange: (name: string, value: any) => void,
  defaultAddress?: OrganizationSearchResult,
  required?: boolean,
  organization: string,
  isVendor: boolean
}

export function DeliveryAddressAutocomplete({ onChange, defaultAddress, required, organization, isVendor }: DeliveryAddressAutocompleteProps) {

  const { translate } = useLocales();

  const [all, setAll] = useState('');

  const firstRender = useRef(true);

  const { organizationList, isSearchLoading } = useSelector(
    (state: RootState) => state.organizations
  );

  const handleSearch = async (val: string) => {

    setAll(val);
    isVendor ?
      await dispatch(
        organizationsOperations.searchCustomerOrganizations({
          CustomerParentId: organization,
          pageIndex: 0,
          pageSize: 5,
          all: val,
          status: 'Enabled'
        })
      )
      :
      await dispatch(organizationsOperations.searchVendorOrganizations({
        pageIndex: 0,
        pageSize: 5,
        all: val,
        status: 'Enabled'
      }));
  };

  const getAddress = (address: Address): string[] => {
    let addr = '';
    let city = '';
    let country = '';

    addr += address.address ? address.address + '' : '';
    addr += address.district ? ', ' + address.district : '';
    city += address.zipCode ? ', ' + address.zipCode : '';
    city += address.city ? ', ' + address.city + ' ' : '';
    country += address.country ? address.country : '';

    return [addr, city, country];
  };

  const defaultAdd = useMemo(() => defaultAddress, []);

  return <Autocomplete

    id="deliveryCompanyId"
    freeSolo
    options={organizationList}
    loading={isSearchLoading}
    defaultValue={defaultAdd}
    onOpen={() => {

      if (firstRender.current) {
        isVendor ?
          dispatch(organizationsOperations.searchCustomerOrganizations({
            CustomerParentId: organization,
            all: all,
            pageIndex: 0,
            pageSize: 5,
            status: 'Enabled',
          }))
          :
          dispatch(organizationsOperations.searchVendorOrganizations({
            all: all,
            pageIndex: 0,
            pageSize: 5,
            status: 'Enabled',
          }));

        firstRender.current = false;
      }
    }}
    onChange={(_, v) =>
      onChange('deliveryCompanyId', v ? [(v as OrganizationSearchResult).id, (v as OrganizationSearchResult)] : ["", ""])
    }
    onInputChange={(_, v) => handleSearch(v)}

    getOptionLabel={(option) =>
      typeof option !== 'string' ? getAddress(option.address).join('') : ''
    }
    filterOptions={(options) => options}
    renderOption={(props, option) => {
      return (
        <li
          {...props}
          key={option.id}
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'initial' }}
        >
          <Typography>
            <b>{option.name}</b>
          </Typography>
          <Typography>{getAddress(option.address).join('')}</Typography>
        </li>
      );
    }}
    renderInput={(params) => (
      <TextField {...params} sx={{ '& .MuiInputLabel-asterisk': { color: 'error.main' } }} value={defaultAddress} required={required} label={`${translate('request.deliveryAdd')}`} />
    )}
  />;
}