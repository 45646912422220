import { SurveillanceRulesFormResult } from "src/@types/rules";

const DEFAULT_SURVEILLANCE: SurveillanceRulesFormResult = {
    newPart: 0,
    damagePackaging: 0,
    damageUnpackaging: 0,
    quality: 0,
    fee: 0,
    enabled: true
};

export function defaultRates(rates: SurveillanceRulesFormResult): SurveillanceRulesFormResult {
    return {
        newPart: rates?.newPart || DEFAULT_SURVEILLANCE.newPart,
        damagePackaging: rates?.damagePackaging || DEFAULT_SURVEILLANCE.damagePackaging,
        damageUnpackaging: rates?.damageUnpackaging || DEFAULT_SURVEILLANCE.damageUnpackaging,
        quality: rates?.quality || DEFAULT_SURVEILLANCE.quality,
        fee: rates?.fee || DEFAULT_SURVEILLANCE.fee,
        enabled: rates?.enabled ?? DEFAULT_SURVEILLANCE.enabled
    };
}
