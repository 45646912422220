//
import { Box } from '@mui/material';
//
import { CustomFile } from '../types';

// ----------------------------------------------------------------------

type Props = {
  file: CustomFile | string | null | File;
};

export default function SingleFilePreview({ file }: Props) {
  if (!file) {
    return null;
  }

  const name = file instanceof File ? file.name : file;

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center'
    }}>{name}</Box>
  );
}
