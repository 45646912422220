import { Box, Collapse, Divider, Drawer, Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useLocales } from "src/locales";
import { useEffect, useMemo, useState } from "react";
import LoadingScreen from "src/appComponents/loading-screen/LoadingScreen";
import { RootState, dispatch, useSelector } from "src/redux/store";
import { rolesOperations } from "src/redux/roles";
import Iconify from "src/components/iconify";
import { ClusterPermissionsList, OrganizationPermissionsList } from "src/@types/permissions";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { CheckCircle, Info, RemoveCircle } from "@mui/icons-material";
import { DEFAULT_ROLE } from "src/@types/role";
import { lokalizableRoleStr } from "src/pages/dashboard/role/utils";

type OrganizationRoleDetailsProps = {
    setOpen: (value: boolean) => void,
    open: boolean
    admin?: boolean
};

export default function OrganizationRoleDetails({ setOpen, open, admin }: OrganizationRoleDetailsProps) {

    const { translate } = useLocales();

    const { isLoading, organizationRoleList, adminRoleList } = useSelector((state: RootState) => state.roles);

    const PERMISSION = admin ? ClusterPermissionsList : OrganizationPermissionsList;

    const ROLES = admin ? adminRoleList : organizationRoleList;

    useEffect(() => {
        if (!admin && organizationRoleList.length < 1) {
            dispatch(rolesOperations.searchOrganizationRoles({ pageIndex: 0, pageSize: 100 }));
        }
        else if (admin && adminRoleList.length < 1)
            dispatch(rolesOperations.searchAdminRoles({ pageIndex: 0, pageSize: 100 }));
    }, [admin, adminRoleList, organizationRoleList]);

    const [clickedIndex, setClickedIndex] = useState<number>();

    const TABLE_HEAD = useMemo(() =>
        [
            {
                id: 'empty',
                label: ``,
                role: DEFAULT_ROLE,
            },

            ...(ROLES.filter(r => r.enabled).map(r => ({
                id: r.id,
                label: r.name,
                role: r
            })))
        ], [ROLES]);

    return (
        <Drawer
            onClose={() => setOpen(false)}
            PaperProps={{ sx: { width: '80%' } }}
            open={open}
            anchor='right'
        >
            {
                isLoading ? <LoadingScreen /> :
                    <>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ p: 2 }}
                        >
                            <Typography variant="h6" sx={{ ml: 1 }}>
                                {`${translate("role.detail.title")}`}
                            </Typography>

                            <IconButton onClick={() => setOpen(false)}>
                                <Iconify icon={'eva:close-fill'} width={20} height={20} />
                            </IconButton>
                        </Stack>

                        <Divider />

                        <Box sx={{ p: 3 }}>
                            <Grid container >
                                <Grid
                                    item
                                    container
                                    justifyContent={'space-between'}
                                    columns={TABLE_HEAD.length}
                                    sx={{
                                        p: 3,
                                        background: '#F4F6F8',
                                        borderRadius: '8px 8px 0px 0px',
                                        borderBottom: '1px solid #919EAB'
                                    }}
                                >

                                    <Grid item />

                                    {TABLE_HEAD.map((head, index) =>

                                        <Grid item key={index} >
                                            <Typography variant="subtitle1">
                                                {head.label}
                                            </Typography>
                                        </Grid>

                                    )}
                                </Grid>
                                {Object.keys(PERMISSION).map((permissionType, index) =>
                                    <Grid
                                        item
                                        container
                                        columns={TABLE_HEAD.length}
                                        key={index}
                                        sx={{ borderBottom: '1px solid #919EAB' }}
                                    >
                                        <Grid
                                            item
                                            md={1}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <Box
                                                sx={{
                                                    background: '#F4F6F8',
                                                    py: 2,
                                                    px: 1,
                                                    display: 'flex',
                                                    justifyContent: 'space-between'
                                                }}

                                                onClick={() =>
                                                    clickedIndex === index ?
                                                        setClickedIndex(undefined) :
                                                        setClickedIndex(index)}

                                            >

                                                <Typography variant="subtitle2" >
                                                    {`${translate(`role.type.${permissionType[0].toLowerCase() + permissionType.slice(1)}`)}`}
                                                </Typography>

                                                {clickedIndex === index ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}

                                            </Box>
                                        </Grid>
                                        <Collapse in={clickedIndex === index} timeout={'auto'} unmountOnExit>
                                            <Grid container >

                                                {PERMISSION[permissionType].map((perm, index) =>

                                                    <Grid container item key={index} justifyContent={'space-between'} columns={TABLE_HEAD.length}>

                                                        <Grid item md={1} sx={{ py: 1, pl: 2, background: '#F4F6F8' }} >

                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', pr: 1 }}>
                                                                <Typography variant="body2">
                                                                    {`${translate(`role.permission.${lokalizableRoleStr(perm)}.title`)}`}
                                                                </Typography>

                                                                <Tooltip title={`${translate(`role.permission.${lokalizableRoleStr(perm)}.description`)}`} >
                                                                    <Info color="disabled" sx={{ cursor: 'pointer' }} />
                                                                </Tooltip>
                                                            </Box>

                                                        </Grid>

                                                        {TABLE_HEAD.slice(1).map((ro, index) =>

                                                            <Grid item key={index} md={'auto'} sx={{ pr: 3 }}>

                                                                {ro.role.permissions && ro.role.permissions.includes(perm) ?
                                                                    <CheckCircle color="success" sx={{ mr: 2, mt: 1 }} /> :
                                                                    <RemoveCircle color="disabled" sx={{ mr: 2, mt: 1 }} />
                                                                }

                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Collapse>
                                    </Grid>
                                )}

                            </Grid>
                        </Box>
                    </>
            }
        </Drawer>
    );
}