import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import Page from "src/appComponents/Page";
import { useSettingsContext } from "src/components/settings";
import { ordersOperations } from "src/redux/orders";
import { RootState, dispatch } from "src/redux/store";
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useLocales } from "src/locales";
import { PATH_DASHBOARD } from "src/routes/paths";
import LoadingScreen from "src/appComponents/loading-screen";
import { CreditNote, DeliveryNote, DocType, Invoice } from "src/@types/orders";
import DocumentPrint from "src/sections/@dashboard/documents/detail/DocumentPrint";

interface DocumentDetailProps {
    isVendor: boolean
}

export default function DocumentDetail({ isVendor }: DocumentDetailProps) {

    const { id, type } = useParams();

    const { themeStretch } = useSettingsContext();

    const { translate } = useLocales();

    const { isOrderLoading } = useSelector((state: RootState) => state.orders);

    const [document, setDocument] = useState<Invoice | DeliveryNote | CreditNote>();

    useEffect(() => {
        if (id && type)
            switch (type) {
                case "Invoice":
                    dispatch(ordersOperations.getInvoice(id)).unwrap().then(v => setDocument(v));
                    break;
                case "CreditNote":
                    dispatch(ordersOperations.getCreditNote(id)).unwrap().then(v => setDocument(v));
                    break;
                case "DeliveryNote":
                    dispatch(ordersOperations.getDeliveryNote(id)).unwrap().then(v => setDocument(v));
                    break;
            }
    }, [id, type]);

    return (
        <Page title={`${translate('documents.detail.title')}`}>
            {isOrderLoading || !document ? <LoadingScreen /> :
                <Container maxWidth={themeStretch ? false : 'lg'}>
                    <HeaderBreadcrumbs
                        heading={`${translate('documents.detail.title')}`}
                        links={[
                            { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                            { name: `${translate(`menu.management.documents.list`)}`, href: isVendor ? PATH_DASHBOARD.orders.vendor.documents.list : PATH_DASHBOARD.orders.customer.list },
                            { name: document.externalId }
                        ]}
                    />
                    <DocumentPrint document={document} goBack type={type as DocType} />
                </Container>
            }
        </Page>
    );
}