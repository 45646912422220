import { UserManager, UserRole } from "src/@types/user";
import { ClusterPermissionTypes, OrganizationPermissionTypes } from 'src/@types/permissions';

const getallPermission = (user: UserManager) => {
    if (user.roles)
        return Array.from(
            new Set(
                user.roles.flatMap(role => role.permissions)
            )
        );
    else
        return [];
};

const hasPermissions = (
    permissions: (ClusterPermissionTypes | OrganizationPermissionTypes)[],
    userRoles: UserRole[] | null | undefined,
    absoluteVerification: boolean = false,
): boolean => {
    if (userRoles) {

        if (absoluteVerification) {
            // Scenario 1: se absoluteVerification controlla che siano presenti tutti i permessi richiesti
            return permissions.every((perm) => {
                return userRoles.some((role) => role.permissions.includes(perm));
            });
        }
        else {
            //scenario 2: se absoluteVerification è false, controlla che sia presente almeno uno dei permessi richiesti
            return permissions.some((perm) =>
                userRoles.some((role) => role.permissions.includes(perm)));
        }
    }

    return false;
};

export {
    hasPermissions,
    getallPermission
};