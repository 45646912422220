import { Box, Typography } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import { LogisticsData } from 'src/@types/logistics';
import useLocales from 'src/appHooks/useLocales';
import RHFNumericFormat from 'src/components/hook-form/RHFNumericFormat';
import RHFDatePicker from 'src/components/hook-form/RHFDatePicker';
import { useSettingsContext } from 'src/components/settings';

type Props = {
    outerRef: any;
    formMethods: UseFormReturn<LogisticsData>;
    setOnEditForm: (value: boolean) => void;
    onEditForm: boolean;
};

export default function LogisticStep1({ formMethods, outerRef, setOnEditForm, onEditForm }: Props) {

    const { getValues, trigger, clearErrors } = formMethods;

    const { translate } = useLocales();

    const { currency } = useSettingsContext();

    const validateFields = async (yupSchema: any) => {
        const formValues = getValues();

        try {
            await yupSchema.validate(formValues, { abortEarly: false });
            clearErrors();

            return true;
        } catch (err: any) {
            return false;
        }
    };

    if (!outerRef?.current) {
        outerRef.current = {
            validateFields,
            onSave: () => {
                const formValues = getValues();

                return { ...formValues };
            }
        };
    }

    const onChangeValidate = async (field: any) => {
        await trigger(field, { shouldFocus: true });
    };

    return (
        <>
            <Typography variant="h5">
                {`${translate('matrix.form.generalInfo')}`}
            </Typography>

            <Typography variant="body2" sx={{ mt: 1 }}>
                {`${translate('vehicle.logistic.form.generalDescription')}`}.
            </Typography>

            <Box
                sx={{
                    py: 2,
                    display: 'grid',
                    columnGap: 2,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
                }}
            >
                <RHFDatePicker
                    name="appraisalDate"
                    label={`${translate('vehicle.logistic.form.appraisalDate')}`}
                    required
                    sx={{ '& .MuiInputLabel-asterisk': { color: 'error.main' } }}
                    onChangeVal={onChangeValidate}
                    setOnEditForm={setOnEditForm}
                    onEditForm={onEditForm}
                />
                <RHFNumericFormat
                    name={'damagesWithoutVat'}
                    label={`${translate('vehicle.form.price.damagesWithoutVat')}`}
                    prefix={`${currency.symbol} `}
                    required
                    onChangeVal={onChangeValidate}
                    setOnEditForm={setOnEditForm}
                    onEditForm={onEditForm}
                />
                <RHFNumericFormat
                    name={'damagesWithVat'}
                    label={`${translate('vehicle.form.price.damagesWithVat')}`}
                    prefix={`${currency.symbol} `}
                    required
                    onChangeVal={onChangeValidate}
                    setOnEditForm={setOnEditForm}
                    onEditForm={onEditForm}
                />
            </Box>
        </>
    );
}
