import { Card, Typography } from "@mui/material";
import { Box } from "@mui/system";
import useResponsive from "src/hooks/useResponsive";
import { ReactNode } from "react";

const InfoCardStyle = {
    display: 'flex',
    px: '24px',
    py: '18px',
    alignContent: 'center',
    justifyContent: 'space-between'
};

const InfoCardContentStyle = {
    display: "flex",
    flexDirection: 'column',
    gap: 1,
    mr: 3
};

interface InfoCardProps {
    label: string,
    value: string | number,
    icon: ReactNode,
    iconColor: string,
    onClickFunc?: any
}

export default function InfoCard({ label, value, icon, iconColor, onClickFunc }: InfoCardProps) {

    const isDesktop = useResponsive('up', 'sm');

    return (
        <Card
            sx={{ ...InfoCardStyle, width: isDesktop ? '33%' : '100%', cursor: onClickFunc ? "pointer" : "auto" }}
            onClick={onClickFunc}
        >
            <Box sx={{ ...InfoCardContentStyle }}>
                <Typography variant="subtitle2">
                    {label}
                </Typography>
                <Typography variant="h3">
                    {value}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="24" cy="24" r="24" fill={iconColor} />
                    <foreignObject x="12" y="12" width="24" height="24">
                        {icon}
                    </foreignObject>
                </svg>
            </Box>
        </Card>
    );
}