import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import reportsOperations from "./reports-operations";
import { ErrorResponse, PagedResponseType } from "src/@types/commons";
import { ReportType, ReportSearchResult, ExecutionSearchResult, ReportTemplate, Fields } from "src/@types/report";

export type ReportState = {
    /* COMMONS */
    error: ErrorResponse,
    filtersInUrl: string,
    /* REPORT LIST */
    reportList: ReportSearchResult[],
    isLoading: boolean,
    isExecutionLoading: boolean,
    totalCount: number,
    pageSize: number,
    pageIndex: number,
    /* REPORT */
    report: ReportType | null,
    /* REPORT CREATION */
    columns: Fields[],
    isColumnsLoading: boolean,
    templates: ReportTemplate[]
    executions: PagedResponseType<ExecutionSearchResult>,

};

const initialState: ReportState = {
    /* COMMONS */
    error: null,
    filtersInUrl: "",
    /* REPORT LIST */
    reportList: [],
    isLoading: false,
    totalCount: 0,
    pageIndex: 0,
    pageSize: 10,
    /* REPORT */
    report: null,
    /* REPORT CREATION */
    columns: [],
    templates: [],
    isColumnsLoading: false,
    executions: {
        items: [],
        pageIndex: 0,
        pageSize: 0,
        totalCount: 0,
        hasNextPage: false
    },
    isExecutionLoading: false
};

const ReportsSlice = createSlice({
    name: 'report',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(reportsOperations.createReport.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(reportsOperations.createReport.fulfilled, (state) => {
                state.isLoading = false;
            })
            //-------
            .addCase(reportsOperations.getReport.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message as string;
                state.report = null;
            })
            .addCase(reportsOperations.getReport.fulfilled, (state, action) => {
                state.report = action.payload;
                state.isLoading = false;
            })
            //-------
            .addCase(reportsOperations.disableReport.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(reportsOperations.disableReport.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            //-------
            .addCase(reportsOperations.enableReport.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(reportsOperations.enableReport.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            //-------
            .addCase(reportsOperations.searchReports.rejected, (state, action) => {
                state.reportList = initialState.reportList;
                state.totalCount = initialState.totalCount;
                state.error = action.error.message as string;
                state.isLoading = false;
                state.pageIndex = initialState.pageIndex;
                state.pageSize = initialState.pageSize;
            })
            .addCase(reportsOperations.searchReports.fulfilled, (state, action) => {
                state.isLoading = false;
                state.totalCount = action.payload.totalCount;
                state.reportList = action.payload.items;
                state.pageIndex = action.payload.pageIndex;
                state.pageSize = action.payload.pageSize;
            })
            //-------
            .addCase(reportsOperations.editReport.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(reportsOperations.editReport.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            //-------
            .addCase(reportsOperations.getColumns.rejected, (state, action) => {
                state.isColumnsLoading = false;
                state.columns = initialState.columns;
                state.error = action.error.message as string;
            })
            .addCase(reportsOperations.getColumns.fulfilled, (state, action) => {
                state.columns = action.payload;
                state.isColumnsLoading = false;
            })
            .addCase(reportsOperations.searchExecution.fulfilled, (state, action) => {
                state.executions = action.payload;
                state.isExecutionLoading = false;
            })
            .addCase(reportsOperations.searchExecution.rejected, (state, action) => {
                state.executions = initialState.executions;
                state.error = action.error.message as string;
                state.isExecutionLoading = false;
            })
            .addCase(reportsOperations.getTemplates.rejected, (state, action) => {
                state.templates = initialState.templates;
                state.error = action.error.message as string;
            })
            .addCase(reportsOperations.getTemplates.fulfilled, (state, action) => {
                state.templates = action.payload;
            })
            ;
    },
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        startColumnLoading(state) {
            state.isLoading = true;
        },
        startExecutionLoading(state) {
            state.isExecutionLoading = true;
        },
        // FILTERS IN URL
        setFiltersInUrl(state, action: PayloadAction<string>) {
            state.filtersInUrl = action.payload;
        },
        // REPORT
        clearReport(state) {
            state.report = null;
        },
        //CLEAR LIST
        resetPageIndexSize(state) {
            state.pageIndex = 0;
            state.pageSize = 10;
        },
        resetTemplates(state) {
            state.templates = [];
        }
    }
});

export const {
    startLoading,
    startColumnLoading,
    clearReport,
    resetPageIndexSize,
    setFiltersInUrl,
    startExecutionLoading,
    resetTemplates
} = ReportsSlice.actions;

export default ReportsSlice.reducer;
