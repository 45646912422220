import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ErrorResponse } from "src/@types/commons";

interface SuccessMessage {
    text: ErrorResponse,
    callback?: () => void,
    returnTo?: string | number,
    goTo?: string | number
}

interface ModalReducer {
    error: ErrorResponse | number,
    success: SuccessMessage | null
}

const initialState: ModalReducer = {
    error: null,
    success: null
};

const ModalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        resetErrorMessage(state) {
            state.error = null;
        },
        setErrorMessage(state, action: PayloadAction<string>) {
            state.error = action.payload;
        },
        resetSuccessMessage(state) {
            state.success = null;
        },
        setSuccessMessage(state, action: PayloadAction<SuccessMessage>) {
            state.success = action.payload;
        }
    }

});

export const {
    resetErrorMessage,
    setErrorMessage,
    resetSuccessMessage,
    setSuccessMessage
} = ModalSlice.actions;

export default ModalSlice.reducer;