import { Card, Divider, Button, Box } from "@mui/material";
import DocumentView from "./DocumentView";
import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import PrintIcon from '@mui/icons-material/Print';
import { CreditNote, DeliveryNote, DocType, Invoice } from "src/@types/orders";
import { useNavigate } from "react-router";
import { useLocales } from "src/locales";

interface InvoicePrintProps {
    goBack?: boolean,
    document: Invoice | DeliveryNote | CreditNote,
    type?: DocType
}

export default function DocumentPrint({ goBack, document, type }: InvoicePrintProps) {

    const componentRef = useRef(null);

    const navigate = useNavigate();

    const { translate } = useLocales();

    const [isPrinting, setIsprinting] = useState(false);

    const Print = useReactToPrint({
        onBeforePrint: async () => setIsprinting(true),
        contentRef: componentRef,
        pageStyle: "color: black",
        onAfterPrint: () => setIsprinting(false)
    });

    return (
        <Card>
            <Box ref={componentRef} >
                <DocumentView document={document} type={type} isPrinting={isPrinting} />
            </Box>
            <Divider />
            <Box
                sx={{
                    display: 'flex',
                    px: 5,
                    py: 3,
                    gap: 1,
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}
            >
                {goBack && <Button variant="soft" onClick={() => navigate(-1)}>{`${translate('request.goBack')}`}</Button>}
                <Button sx={{ ml: 'auto' }} variant="contained" startIcon={<PrintIcon />} onClick={() => Print()}>{`${translate('commons.print')}`}</Button>

            </Box>
        </Card>
    );
}

export function DocumentPrintInABox({ goBack, document, type }: InvoicePrintProps) {

    const componentRef = useRef(null);

    const navigate = useNavigate();

    const { translate } = useLocales();

    const [isPrinting, setIsprinting] = useState(false);

    const Print = useReactToPrint({
        contentRef: componentRef,
        pageStyle: "color: black",
        onAfterPrint: () => setIsprinting(false)
    });

    return (
        <Box sx={{ width: "100%" }}>
            <Box ref={componentRef} >
                <style type="text/css" media="print">{"@page { size: landscape; }"}</style>
                <DocumentView document={document} type={type} isPrinting={isPrinting} />
            </Box>
            <Divider />
            <Box
                sx={{
                    display: 'flex',
                    px: 5,
                    py: 3,
                    gap: 1,
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}
            >
                {goBack &&
                    <Button
                        variant="soft"
                        onClick={() => navigate(-1)}
                    >
                        {`${translate('request.goBack')}`}
                    </Button>
                }
                <Button
                    sx={{ ml: 'auto' }}
                    variant="contained"
                    startIcon={<PrintIcon />}
                    onClick={() => {
                        setIsprinting(true);
                        Print();
                    }}
                >
                    {`${translate('commons.print')}`}
                </Button>
            </Box>
        </Box>
    );
}