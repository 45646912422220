import { useState } from 'react';
import { Box, Button, Typography, Modal, Card } from '@mui/material';
import useLocales from '../../../appHooks/useLocales';
import { IconButtonAnimate } from '../../../components/animate';
import useResponsive from 'src/hooks/useResponsive';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'src/redux/store';
import { organizationsOperations } from 'src/redux/organizations';
import { useUserOrganizationContext } from 'src/contexts/UserOrganizationContext';
import { GenericOrganization } from 'src/@types/organizations';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

export default function CustomerCarePopover() {

  const { translate } = useLocales();

  const { customerCareVendors } = useSelector((state: RootState) => state.organizations);

  const { organizationId } = useUserOrganizationContext();

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const [currentIndex, setCurrentIndex] = useState(0);

  const isDesktop = useResponsive('up', 'sm');

  const handleOwners = () => {

    setIsOpenModal(true);

    if (!customerCareVendors || !customerCareVendors.length)
      dispatch(organizationsOperations.getCustomerCareVendors(organizationId));

  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOwners}
      >
        <HeadsetMicIcon />
      </IconButtonAnimate>

      <Modal
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      >
        <Card sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: {
            xs: 300,
            sm: 400
          },
          p: isDesktop ? 5 : 2
        }}>
          <Box sx={{ textAlign: 'center', }}>
            <Box sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: 'relative',
              height: "82.5%",
            }}>

              {currentIndex > 0 &&
                <ArrowCircleLeftIcon
                  onClick={() => setCurrentIndex(prev => prev - 1)}
                  sx={{
                    cursor: 'pointer',
                    position: "absolute",
                    top: isDesktop ? "48.75%" : "89.5%",
                    left: isDesktop ? "-13%" : "10%",
                    width: isDesktop ? 40 : 50,
                    height: isDesktop ? 35 : 40,
                    paddingTop: "5",
                    paddingBottom: "5",
                    zIndex: '100',
                    color: (theme) => theme.palette.grey[500],
                    fontSize: 10
                  }}
                />
              }
              <CustomerCareInfo
                orgs={customerCareVendors!}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
              />

              {currentIndex < customerCareVendors!.length - 1 &&
                <ArrowCircleRightIcon
                  onClick={() => setCurrentIndex(prev => prev + 1)}
                  sx={{
                    cursor: 'pointer',
                    position: 'absolute',
                    top: isDesktop ? "48.75%" : "89.5%",
                    right: isDesktop ? "-13%" : "10%",
                    width: isDesktop ? 40 : 50,
                    height: isDesktop ? 35 : 40,
                    paddingTop: "5",
                    paddingBottom: "5",
                    color: (theme) => theme.palette.grey[500],
                  }}
                />
              }

            </Box>
            {customerCareVendors!.length > 1 &&
              <Box sx={{
                my: '4',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              >
                {
                  customerCareVendors!.map((_, ind) => (
                    <Box
                      key={`dot-${ind}`}
                      onClick={() => setCurrentIndex(ind)}
                      sx={{
                        mx: 0.75,
                        cursor: "pointer",
                        color: (theme) => currentIndex === ind ? theme.palette.primary.main : theme.palette.primary.lighter,
                      }}
                    >
                      &#9679;
                    </Box>
                  ))
                }
              </Box>}
            <Button
              variant="contained"
              size={isDesktop ? "small" : 'large'}
              onClick={() => setIsOpenModal(false)}
              sx={{ borderRadius: "50px !important", mt: 3 }}
            >
              {`${translate('commons.close')}`}
            </Button>

          </Box>
        </Card>
      </Modal >

    </>
  );
}

interface CustomerCareInfoProps {
  orgs: GenericOrganization[],
  currentIndex: number,
  setCurrentIndex: (value: number) => void
}

function CustomerCareInfo({ orgs, currentIndex, setCurrentIndex }: CustomerCareInfoProps) {

  const { translate } = useLocales();

  return (
    <Box>
      <Box component="img" src="/assets/illustrations/illustration_maintenance.svg" sx={{ width: "40%", m: "auto" }} />

      <Typography variant="h6" sx={{ mt: 3 }}>
        {`${translate(`customerCare.contact`)}`}
      </Typography>

      <Typography variant="body1">
        {`${translate(`customerCare.availability`)}`}
      </Typography>

      <Card
        sx={{
          mt: 2,
          bgcolor: (theme) => theme.palette.grey[200]
        }}
      >
        <Box sx={{ textAlign: "center", color: "black" }}>

          <Typography variant="body2" sx={{ mt: 2 }}>
            {orgs[currentIndex].contact?.businessEmail || "-"}
          </Typography>

          <Typography variant="overline" sx={{ mt: 2 }}>
            {`${translate(`customerCare.contactUs`)}`}
          </Typography>

          <Typography variant="body2" sx={{ mt: 1, mb: 2 }}>
            {orgs[currentIndex].contact?.businessPhone || "-"}

          </Typography>

        </Box>
      </Card>

      <Box sx={{ my: 2, display: 'flex', justifyContent: 'center' }}>
        <Typography variant='caption' sx={{ width: '60%' }}>
          {orgs[currentIndex]?.note || "-"}
        </Typography>
      </Box>
    </Box>);
}
