import { useState, memo, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import { Box, Slide, Popper, InputBase, PopperProps, InputAdornment, ClickAwayListener, } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { bgBlur } from 'src/utils/cssStyles';
import Iconify from 'src/components/iconify';
import { IconButtonAnimate } from 'src/components/animate';
import SearchNotFound from 'src/components/search-not-found';
import usePowersearch, { MatrixResult } from 'src/hooks/usePowersearch';
import { MatrixSearchTemplate, OrganizationSearchTemplate, UserSearchTemplate, VehicleSearchTemplate, SparePartsSearchTemplate } from 'src/appComponents/search';
import useLocales from 'src/appHooks/useLocales';
import { startPowerSearchLoading as startLoadingMatrixes } from 'src/redux/matrix/matrix-slices';
import { startPowerSearchLoading as startLoadingUsers } from 'src/redux/users/users-slices';
import { startMyPowerSearchLoading as startMyLoadingOrganizations, startCustomerPowerSearchLoading as startCustomerLoadingOrganizations } from 'src/redux/organizations/organizations-slices';
import { startMyPowerSearchLoading as startLoadingSpareParts, startCustomerPowerSearchLoading as startCustomerLoadingSpareParts } from 'src/redux/spareParts/spareParts-slices';
import { startPowerSearchLoading as startLoadingOrders } from 'src/redux/orders/orders-slices';
import { dispatch } from 'src/redux/store';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import OrdersSearchTemplate from 'src/appComponents/search/OrdersSearchTemplate';
import LicenseGuard from 'src/guards/LicenseGuard';
import { LicenseTypes } from 'src/@types/tenant';
import { hasLicense } from 'src/utils/tenant';
import useTenant from 'src/appHooks/useTenant';
import { ClusterPermissionTypes, OrganizationPermissionTypes } from 'src/@types/permissions';
import CustomerSearchTemplate from 'src/appComponents/search/CustomerSearchTemplate';
import CustomerSparePartsSearchTemplate from 'src/appComponents/search/CustomerSparePartsSearchTemplate';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;

const APPBAR_DESKTOP = 92;

const StyledSearchbar = styled('div')(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const StyledPopper = styled((props: PopperProps) => <Popper {...props} />)(({ theme }) => ({
  left: `8px !important`,
  top: `${APPBAR_MOBILE + 8}px !important`,
  width: 'calc(100% - 16px) !important',
  transform: 'none !important',
  [theme.breakpoints.up('md')]: {
    top: `${APPBAR_DESKTOP + 8}px !important`,
  },
  '& .MuiAutocomplete-paper': {
    padding: theme.spacing(1, 0),
  },
  '& .MuiListSubheader-root': {
    'header-label': {
      ...bgBlur({ color: theme.palette.background.neutral }),
      ...theme.typography.overline,
      top: 0,
      margin: 0,
      lineHeight: '48px',
      borderRadius: theme.shape.borderRadius,
    },
  },
  '& .MuiAutocomplete-listbox': {
    '& .MuiAutocomplete-option': {
      padding: theme.spacing(0.5, 2),
      margin: 0,
      display: 'block',
      border: `dashed 1px transparent`,
      borderBottomColor: theme.palette.divider,
      '&:last-of-type': {
        borderBottomColor: 'transparent',
      },
      '&:hover': {
        borderColor: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
      },
    },
  },
}));

const GroupHeader = styled(Box)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.neutral }),
  ...theme.typography.overline,
  top: 0,
  paddingLeft: '15px !important',
  margin: 0,
  lineHeight: '48px',
  borderRadius: theme.shape.borderRadius,
}));

// ----------------------------------------------------------------------

export type MatrixSearchResult = {
  id: string;
  group: string;
  path: string;
  status: string;
  title: string;
};

function PowersearchBar() {
  const navigate = useNavigate();

  const tenant = useTenant();

  const { translate } = useLocales();

  const [items, searchItems, isLoading] = usePowersearch();

  const [searchQuery, setSearchQuery] = useState('');

  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);

  const startLoading = () => {
    if (hasLicense(tenant, LicenseTypes.Rv)) {
      dispatch(startLoadingMatrixes());
    }
    if (hasLicense(tenant, LicenseTypes.Core)) {
      dispatch(startLoadingUsers());
      dispatch(startMyLoadingOrganizations());
      dispatch(startCustomerLoadingOrganizations());
    }
    if (hasLicense(tenant, LicenseTypes.ProductSparePart)) {
      dispatch(startLoadingSpareParts());
      dispatch(startCustomerLoadingSpareParts());
      //dispatch(startLoadingVehicles());
    }
    else if (hasLicense(tenant, LicenseTypes.WebShop)) {
      dispatch(startLoadingSpareParts());
      dispatch(startLoadingOrders());
      //dispatch(startLoadingVehicles());
    }
  };

  useEffect(() => {
    if (open) {
      handleClose();
    }

  }, [pathname]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = (text: string) => {
    setSearchQuery(text);
    searchItems(text);
  };

  const handleClick = (path: string) => {

    if (path.includes('http')) {
      window.open(path);
    } else {
      navigate(path);
    }
    handleClose();
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {

    if (event.key === 'Enter' && searchQuery.includes('/')) {
      handleClick(searchQuery);
    }
  };

  const renderSearchResult = (option: MatrixResult) => {
    switch (option.group) {
      case 'matrix':
        return (
          <LicenseGuard license={LicenseTypes.Rv}>
            <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Rv_Matrix_View]}>
              <MatrixSearchTemplate option={option} searchQuery={searchQuery} />;
            </PermissionBasedGuard>
          </LicenseGuard>
        );
      case 'template':
        return (
          <LicenseGuard license={LicenseTypes.Rv}>
            <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Rv_Template_View]}>
              <MatrixSearchTemplate option={option} searchQuery={searchQuery} />
            </PermissionBasedGuard>
          </LicenseGuard>
        );
      case 'user':
        return (
          <LicenseGuard license={LicenseTypes.Core}>
            <PermissionBasedGuard permissions={[ClusterPermissionTypes.UserView]}>
              <UserSearchTemplate option={option} searchQuery={searchQuery} />
            </PermissionBasedGuard >
          </LicenseGuard>
        );
      case 'myOrganization':
        return (
          <LicenseGuard license={LicenseTypes.Core}>
            <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Organization_View]}>
              <OrganizationSearchTemplate option={option} searchQuery={searchQuery} />
            </PermissionBasedGuard>
          </LicenseGuard>
        );
      case 'customerOrg':
        return (
          <LicenseGuard license={LicenseTypes.Core}>
            <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Customer_View]}>
              <CustomerSearchTemplate option={option} searchQuery={searchQuery} />
            </PermissionBasedGuard>
          </LicenseGuard>
        );
      case 'vehicle':
        return (
          <LicenseGuard license={LicenseTypes.ProductVehicle}>
            <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Product_Vehicles_View]}>
              <VehicleSearchTemplate option={option} searchQuery={searchQuery} />
            </PermissionBasedGuard>
          </LicenseGuard>
        );
      case 'mySpareParts':
        return (
          <LicenseGuard license={LicenseTypes.ProductSparePart}>
            <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Product_SpareParts_View]}>
              <SparePartsSearchTemplate option={option} searchQuery={searchQuery} />
            </PermissionBasedGuard>
          </LicenseGuard>
        );
      case 'customerSpareParts':
        return (
          <LicenseGuard license={LicenseTypes.ProductSparePart}>
            <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Product_SpareParts_View]}>
              <CustomerSparePartsSearchTemplate option={option} searchQuery={searchQuery} />
            </PermissionBasedGuard>
          </LicenseGuard>
        );
      case 'orders':
        return (
          <LicenseGuard license={LicenseTypes.WebShop}>
            <PermissionBasedGuard permissions={[OrganizationPermissionTypes.WebShop_Orders_CustomerView]} >
              <OrdersSearchTemplate option={option} searchQuery={searchQuery} />
            </PermissionBasedGuard>
          </LicenseGuard>
        );
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!open && (
          <IconButtonAnimate onClick={handleOpen}>
            <Iconify icon="eva:search-fill" />
          </IconButtonAnimate>
        )}

        <Slide direction="down" in={open} mountOnEnter unmountOnExit>
          <StyledSearchbar>
            <Autocomplete
              sx={{ width: 1, height: 1 }}
              autoHighlight
              disablePortal
              disableClearable
              popupIcon={null}
              PopperComponent={StyledPopper}
              onInputChange={(event, value) => {
                startLoading();
                handleSearch(value);
              }}
              noOptionsText={<SearchNotFound query={searchQuery} sx={{ py: 10 }} />}
              options={isLoading ? [] : items}
              groupBy={(option) => option.group}
              getOptionLabel={(option) => option.path}
              isOptionEqualToValue={(option, value) => option.path === value.path}
              filterOptions={(x) => x}
              loading={isLoading}
              loadingText={`${translate('commons.loading')}...`}
              renderGroup={(params) => (
                <div key={params.key}>
                  <GroupHeader>{`${translate(`powersearch.groups.${params.group}`)}`}</GroupHeader>
                  <div>{params.children}</div>
                </div>
              )}
              renderInput={(params) => (
                <InputBase
                  {...params.InputProps}
                  inputProps={params.inputProps}
                  fullWidth
                  autoFocus
                  placeholder={`${translate('commons.search')}`}
                  onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => { handleKeyUp(e); }}
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                  }
                  sx={{ height: 1, typography: 'h6' }}
                // endAdornment={isLoading && <CircularProgress color="inherit" size={20} />}
                />
              )}
              renderOption={(props, option, { inputValue }) => {
                const { path } = option;

                return (
                  <Box component="li" {...props} onClick={() => handleClick(path)}>
                    {renderSearchResult(option)}
                  </Box>
                );
              }}
            />
          </StyledSearchbar>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}

export default memo(PowersearchBar);

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
