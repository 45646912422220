import { Box, Card, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useLocales } from "src/locales";
import { RootState } from "src/redux/store";
import { MarkdownStyle } from "../../inbox/detail/InboxDetail";
import Markdown from "src/components/markdown";

export default function TermsDetail() {

    const { translate } = useLocales();

    const { displayShop } = useSelector((state: RootState) => state.webshop);

    return (
        <Card sx={{ display: 'flex', p: 3, width: "100%", height: '100%', flexDirection: 'column' }}>
            <Box>
                <Typography sx={{ mb: 3 }} variant="h6">{translate('webshop.detail.tabs.terms')}</Typography>
            </Box>
            <Box sx={{ height: '100%' }}>

                <Box>
                    <Box sx={{
                        p: 5,
                        borderRadius: '8px',
                        border: '2px solid',
                        borderColor: theme => theme.palette.grey[400],
                        bgcolor: theme => theme.palette.grey[200],
                        maxHeight: '50vh',
                        overflowY: 'auto'
                    }}>
                        <Typography variant="subtitle2" sx={{ display: "inline-block", whiteSpace: "pre-line" }}>
                            <MarkdownStyle>
                                <Markdown children={displayShop?.termAndCondition.termAndCondition ?? "—"} />
                            </MarkdownStyle>
                        </Typography>
                    </Box>
                </Box>

            </Box>
        </Card>
    );
}