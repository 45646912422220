import Page from '../../../../appComponents/Page';
import useLocales from 'src/appHooks/useLocales';
import { useSettingsContext } from "src/components/settings";
import { PATH_DASHBOARD } from '../../../../routes/paths';
import HeaderBreadcrumbs from '../../../../components/custom-breadcrumbs';
import { Link, useNavigate } from 'react-router-dom';
import { Card, Table, Switch, Button, TableBody, Container, TableContainer, CircularProgress, TableRow, TableCell, Stack, Box, FormControlLabel, TablePagination } from '@mui/material';
import { TableHeadCustom, TableNoData } from "src/components/table";
import useTable from '../../../../appHooks/useTable';
import { useCallback, useEffect, useState } from 'react';
import { administrationRoleDisable, administrationRoleEnable } from 'src/services/roleServices';
import Iconify from 'src/components/iconify';
import { Role } from 'src/@types/role';
import { PagedResponseType } from 'src/@types/commons';
import { scrollBarStyle } from 'src/components/scrollbar/Scrollbar';
import { useSnackbar } from 'notistack';
import AdminRoleTableRow from './AdminRoleTableRow';
import { dispatch } from 'src/redux/store';
import { rolesOperations } from 'src/redux/roles';

export default function AdminRolesList() {

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [roleList, setRoleList] = useState<PagedResponseType<Role>>();

    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const { translate, currentLang } = useLocales();

    const { themeStretch } = useSettingsContext();

    const { page, rowsPerPage, dense, onChangeDense, onChangePage, onChangeRowsPerPage } = useTable();

    const TABLE_HEAD = [
        { id: 'name', label: `${translate('commons.name')}`, align: 'left' },
        { id: 'dataCreation', label: `${translate('commons.date')}`, align: 'left' },
        { id: 'userInRole', label: `${translate('commons.user')}` },
        { id: 'enabled', label: `${translate('commons.enabled')}`, align: 'center' },
        { id: '', align: 'right' }

    ];

    const getList = useCallback(async () => {
        const response = await dispatch(rolesOperations.searchAdminRoles({ pageIndex: page, pageSize: rowsPerPage })).unwrap();

        setRoleList(response);
    }, [page, rowsPerPage]);

    useEffect(() => {
        getList();
    }, [getList, page, rowsPerPage, currentLang]);

    useEffect(() => {
        if (roleList) {
            setIsLoading(false);
        }
    }, [roleList, currentLang]);

    const handleEnableRole = async (roleId: string) => {
        try {
            await administrationRoleEnable(roleId);
            enqueueSnackbar(`${translate('commons.enabled')}`, {
                variant: "success",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
            getList();
        } catch (e) {
            console.error(e);
            enqueueSnackbar(`${translate('commons.requestFailed')}`, {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
    };

    const handleDisableRole = async (roleId: string) => {
        try {
            await administrationRoleDisable(roleId);
            enqueueSnackbar(`${translate('commons.disabled')}`, {
                variant: "success",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
            getList();
        } catch (e) {
            console.error(e);
            enqueueSnackbar(`${translate('commons.requestFailed')}`, {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
    };

    const handleEditRole = (roleId: string) => {
        navigate(PATH_DASHBOARD.tenant.role.admin.edit(roleId));
    };

    const handleDetailRole = (roleId: string) => {
        navigate(PATH_DASHBOARD.tenant.role.admin.detail(roleId));
    };

    const handleLogsRole = (roleId: string) => {
        navigate(PATH_DASHBOARD.tenant.role.admin.logs(roleId));
    };

    return (
        <Page title={`${translate('menu.management.role.admin.title')}`}>
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs
                    heading={`${translate('menu.management.role.admin.title')}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate('role.title_plural')}` },
                    ]}
                    action={
                        <Button
                            variant="contained"
                            component={Link}
                            to={PATH_DASHBOARD.tenant.role.admin.new}
                            startIcon={<Iconify icon={'eva:plus-fill'} />}
                        >
                            {`${translate('role.form.new.admin')}`}
                        </Button>
                    }
                />
                <Card>
                    <TableContainer sx={[{ minWidth: 800, maxHeight: 500, position: 'relative' }, scrollBarStyle]}>
                        <Table stickyHeader size={dense ? 'small' : 'medium'}>
                            <TableHeadCustom
                                headLabel={TABLE_HEAD}
                                rowCount={roleList?.items.length}
                            />
                            {isLoading ? (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={12}>
                                            <Stack
                                                alignItems="center"
                                                justifyContent="center"
                                                sx={{
                                                    height: 1,
                                                    textAlign: 'center',
                                                    p: (theme) => theme.spacing(8, 2),
                                                }}
                                            >
                                                <CircularProgress />
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ) : (
                                <TableBody>
                                    {roleList?.items.map((role: Role) => (
                                        <AdminRoleTableRow
                                            key={role.id}
                                            row={role}
                                            onEditRow={() => handleEditRole(role.id)}
                                            onDetailsRow={() => handleDetailRole(role.id)}
                                            onLogsRow={() => handleLogsRole(role.id)}
                                            onEnableRow={() => handleEnableRole(role.id)}
                                            onDisableRow={() => handleDisableRole(role.id)}
                                        />
                                    ))}
                                    <TableNoData isNotFound={roleList?.items.length === 0} />
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    <Box sx={{ position: 'relative' }}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15, 30]}
                            component="div"
                            count={roleList?.totalCount ? roleList.totalCount : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={onChangePage}
                            onRowsPerPageChange={onChangeRowsPerPage}
                            labelRowsPerPage={`${translate('commons.rowsPerPage')}`}
                        />

                        <FormControlLabel
                            control={<Switch checked={dense} onChange={onChangeDense} />}
                            label={`${translate('commons.dense')}`}
                            sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                        />
                    </Box>
                </Card>
            </Container>

        </Page>
    );
}