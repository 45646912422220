import axios, { AxiosResponse } from 'axios';
import { Currency } from './paths';
import { Rate } from 'src/@types/currency';

const rates = (): Promise<AxiosResponse<Rate[]>> => {
    const url = Currency.admin.rate();

    return axios.get(url);
};

export {
    rates,
};
