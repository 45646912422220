import axios, { AxiosResponse } from "axios";
import { BasketRuleFilters, BasketRuleSearchResult, BasketRuleStatistics, BasketRuleType, NewBasketRule, NewShop, ShopDisplay, ShopFilters, ShopProductFilters, ShopProductSearchResult, ShopSearchResult, ShopStatistics, Shop as ShopType } from "src/@types/webshop";
import { Shop } from "./paths";
import { LogsItem } from "src/@types/logs";
import { InfiniteScrollPagedResponse, PagedResponseType } from "src/@types/commons";

//---- BASKET RULES -----------------------------------------------------------------

const createBasketRule = (basketRule: NewBasketRule): Promise<AxiosResponse> => {
    const url = Shop.admin.basketRule.create();

    return axios.post(url, basketRule);
};

const updateBasketRule = (params: NewBasketRule, id: string): Promise<AxiosResponse> => {
    const url = Shop.admin.basketRule.edit(id);

    return axios.put(url, params);
};

const detailBasketRule = (id: string): Promise<AxiosResponse<BasketRuleType>> => {
    const url = Shop.admin.basketRule.detail(id);

    return axios.get(url);
};

const searchBasketRules = (options: BasketRuleFilters): Promise<AxiosResponse<InfiniteScrollPagedResponse<BasketRuleSearchResult>>> => {
    const url = Shop.admin.basketRule.list();

    return axios.get(url, { params: options, paramsSerializer: { indexes: null } });
};

const searchBasketRuleLogs = (id: string, filters: any): Promise<AxiosResponse<PagedResponseType<LogsItem>>> => {
    const url = Shop.admin.basketRule.logs(id);

    return axios.get(url, { params: filters });
};

const enableBasketRule = (id: string): Promise<AxiosResponse> => {
    const url = Shop.admin.basketRule.enable(id);

    return axios.patch(url);
};

const disableBasketRule = (id: string): Promise<AxiosResponse> => {
    const url = Shop.admin.basketRule.disable(id);

    return axios.patch(url);
};

const activeBasketRule = (id: string): Promise<AxiosResponse> => {
    const url = Shop.admin.basketRule.disable(id);

    return axios.patch(url);
};

const basketRuleStatistics = (options: BasketRuleFilters): Promise<AxiosResponse<BasketRuleStatistics>> => {
    const url = Shop.admin.basketRule.statistics();

    return axios.get(url, { params: options });
};

//---- WEBSHOP ----------------------------------------------------------------------

const createShop = (shop: NewShop): Promise<AxiosResponse<ShopType>> => {
    const url = Shop.admin.shop.create();

    return axios.post(url, shop);
};

const updateShop = (params: NewShop, id: string): Promise<AxiosResponse> => {
    const url = Shop.admin.shop.edit(id);

    return axios.put(url, params);
};

const detailDisplayShop = (id: string): Promise<AxiosResponse<ShopDisplay>> => {
    const url = Shop.admin.shop.display(id);

    return axios.get(url);
};

const detailShop = (id: string): Promise<AxiosResponse<ShopType>> => {
    const url = Shop.admin.shop.detail(id);

    return axios.get(url);
};

const searchShop = (options: ShopFilters): Promise<AxiosResponse<InfiniteScrollPagedResponse<ShopSearchResult>>> => {
    const url = Shop.admin.shop.list();

    return axios.get(url, { params: options, paramsSerializer: { indexes: null } });
};

const searchShopLogs = (id: string, filters: any): Promise<AxiosResponse<PagedResponseType<LogsItem>>> => {
    const url = Shop.admin.shop.logs(id);

    return axios.get(url, { params: filters });
};

const shopStatistics = (options: ShopFilters): Promise<AxiosResponse<ShopStatistics>> => {
    const url = Shop.admin.shop.statistics();

    return axios.get(url, { params: options });
};

const duplicateShop = (id: string): Promise<AxiosResponse> => {
    const url = Shop.admin.shop.duplicate(id);

    return axios.patch(url);
};

const archiveShop = (id: string): Promise<AxiosResponse> => {
    const url = Shop.admin.shop.archive(id);

    return axios.patch(url);
};

const enableShop = (id: string): Promise<AxiosResponse> => {
    const url = Shop.admin.shop.enable(id);

    return axios.patch(url);
};

const disableShop = (id: string): Promise<AxiosResponse> => {
    const url = Shop.admin.shop.disable(id);

    return axios.patch(url);
};

//---- WEBSHOP - PRODUCTS -----------------------------------------------------------

const searchShopProducts = (id: string, options: ShopProductFilters): Promise<AxiosResponse<InfiniteScrollPagedResponse<ShopProductSearchResult>>> => {
    const url = Shop.admin.shop.product.list(id);

    return axios.get(url, { params: options, paramsSerializer: { indexes: null } });
};

export {
    createBasketRule,
    updateBasketRule,
    detailBasketRule,
    searchBasketRules,
    searchBasketRuleLogs,
    enableBasketRule,
    disableBasketRule,
    activeBasketRule,
    basketRuleStatistics,
    createShop,
    updateShop,
    detailDisplayShop,
    searchShop,
    searchShopLogs,
    shopStatistics,
    duplicateShop,
    archiveShop,
    enableShop,
    disableShop,
    detailShop,
    searchShopProducts
};