import { Autocomplete, Box, Chip, MenuItem, TextField, Typography } from '@mui/material';
import { isEqual } from 'lodash';
import { useCallback, useMemo } from 'react';
import { SelectItem } from 'src/@types/commons';
import { useLocales } from 'src/locales';

interface SidebarMultiSelectProps {
    name: string,
    label: string,
    options: SelectItem[],
    sidebarValues: any,
    defaultFilterValues: any,
    handleChangeValues: (values: any, newVal: any, type: string) => void
}

export default function SidebarMultiSelect({ name, label, options, sidebarValues, defaultFilterValues, handleChangeValues }: SidebarMultiSelectProps) {

    const { translate } = useLocales();

    const findLabel = useCallback((name: string) => {
        return options.find((option) => option.value === name)?.label ?? name;
    }, [options]);

    const optionsOnlyValues = useMemo(() => {
        return options.map((option) => option.value);
    }, [options]);

    return (
        <Autocomplete
            id={name}
            options={optionsOnlyValues}
            value={sidebarValues[name] || defaultFilterValues[name]}

            clearOnBlur
            multiple
            filterSelectedOptions

            onChange={(_, v) => handleChangeValues(sidebarValues, v, name)}

            renderOption={(props, option) => {
                return (
                    <MenuItem
                        {...props}
                        key={option}
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'initial' }}
                    >
                        {option === "All" ? `${translate(`commons.all`)}` : findLabel(option)}
                    </MenuItem>
                );
            }}

            renderInput={(params) =>
                <TextField
                    {...params}
                    label={label}
                    onKeyPress={(e) => e.preventDefault()}
                />
            }

            renderTags={(values) => {

                if (isEqual(values, defaultFilterValues[name])) {
                    return <Typography sx={{ px: 1 }}>{`${translate("commons.all")}`}</Typography>;
                }

                return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {values.map((value) => (
                        <Chip
                            key={value}
                            label={findLabel(value)}
                            onDelete={() => handleChangeValues(values, values.filter((x) => x !== value), name)}
                        />
                    ))}
                </Box>;
            }}
        />
    );
}

