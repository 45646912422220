import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_EMAILS_DETAILS, Email, EmailDetails } from "src/@types/email";
import emailOperations from "./email-operations";
import { ErrorResponse } from "src/@types/commons";

interface EmailReducer {
    error: ErrorResponse,
    isLoading: boolean,
    emailList: Email[],
    isEmailLoading: boolean,
    email: EmailDetails | null,
    //--------------------
    pageIndex: number,
    pageSize: number,
    totalCount: number,
    totalPages: number,
    hasNextPage: boolean,
    hasPreviousPage: boolean,
    //--------------------
};

const initialState: EmailReducer = {
    error: null,
    isLoading: false,
    emailList: [],
    isEmailLoading: false,
    email: null,
    //--------------------
    pageIndex: 0,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
    hasNextPage: false,
    hasPreviousPage: false,
    //--------------------
};

const EmailSlice = createSlice({
    name: 'email',
    initialState,
    extraReducers: (builder) => {
        builder
            // ALL EMAILS
            /*             .addCase(emailOperations.getAllEmails.pending, (state, action) => {
                            state.isLoading = true;
                        }) */
            .addCase(emailOperations.getAllEmails.fulfilled, (state, action) => {
                state.pageIndex = action.payload.pageIndex;
                state.pageSize = action.payload.pageSize;
                state.totalCount = action.payload.totalCount;
                state.totalPages = action.payload.totalPages;
                state.hasNextPage = action.payload.hasNextPage;
                state.hasPreviousPage = action.payload.hasPreviousPage;
                state.emailList = action.payload.items;
                state.isLoading = false;
            })
            .addCase(emailOperations.getAllEmails.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            // EMAIL DETAILS
            /*             .addCase(emailOperations.getEmailDetails.pending, (state, action) => {
                            state.isEmailLoading = true;
                        }) */
            .addCase(emailOperations.getEmailDetails.fulfilled, (state, action) => {
                state.email = action.payload;
                state.isEmailLoading = false;
            })
            .addCase(emailOperations.getEmailDetails.rejected, (state, action) => {
                state.email = DEFAULT_EMAILS_DETAILS;
                state.error = action.error.message as string;
                state.isEmailLoading = false;
            });
    },
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
        startEmailLoading(state) {
            state.isEmailLoading = true;
        },
        resetEmail(state) {
            state.email = null;
        },
    }
});

export const {
    startLoading,
    startEmailLoading,
    resetEmail
} = EmailSlice.actions;

export default EmailSlice.reducer;
