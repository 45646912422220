import Page from "src/appComponents/Page";
import { useEffect, useMemo, useState } from "react";
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useLocales } from "src/locales";
import { PATH_DASHBOARD } from "src/routes/paths";
import { Box, Container, Step, StepLabel, Stepper } from "@mui/material";
import DriverBarcodeScan from "./steps/DriverBarcodeScan";
import { useSettingsContext } from "src/components/settings";
import DriverDetails from "./steps/DriverDetails";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "src/redux/store";
import { requestOperations } from "src/redux/request";

interface DriverConfirmProps {
    customer?: boolean
};

export default function DriverConfirm({ customer }: DriverConfirmProps) {

    const { themeStretch } = useSettingsContext();

    const { request } = useSelector((state: RootState) => state.request);

    const { translate } = useLocales();

    const { id } = useParams();

    const [currentStep, setCurrentStep] = useState(id ? 1 : 0);

    const STEPS = useMemo(() => ["scan", "detail", "confirmed"], []);

    useEffect(() => {
        if (id) {
            dispatch(requestOperations.getRequest({ id: id }));
        }
    }, [id]);

    return (
        <Page title={`${translate("request.request")}: ${translate("request.form.title")}`}>
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs
                    heading={`${translate('menu.management.request.title')}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate('menu.management.request.title')}`, href: PATH_DASHBOARD.request.carrier.list },
                        { name: `${translate("returns.newReturn")}` }
                    ]} />
                <Stepper activeStep={currentStep} alternativeLabel>
                    {STEPS.map((step, ind) =>
                        <Step key={ind} completed={ind < currentStep} >
                            <StepLabel>
                                {`${translate(`request.driverSteps.${step}`)}`}
                            </StepLabel>
                        </Step>)}
                </Stepper>
                <Box>
                    {
                        currentStep === 0 && <DriverBarcodeScan customer={!!customer} carrier={!customer} handleNextStep={() => setCurrentStep(prev => prev + 1)} />
                    }
                    {
                        currentStep === 1 && <DriverDetails customer={!!customer} carrier={!customer} request={request} handlePreviousStep={() => setCurrentStep(prev => prev - 1)} />
                    }
                </Box>
            </Container>
        </Page>
    );

}