import { Box, IconButton, Typography } from '@mui/material';
import { Media, MediaFileInfo } from 'src/@types/request';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DownloadIcon from '@mui/icons-material/Download';
import DateZone from 'src/appComponents/DateZone';

type MediaListProps = {
    media: Media[],
    mediaData?: string[]
}

const MediaListStyle = {
    "& .thumbnails": {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        px: 3,
        py: 2,
        borderRadius: '8px',
        alignItems: 'center',
        border: "1px ridge",
        borderColor: "rgba(145, 158, 171, 0.24)"
    },
    "& .createdOnText": {
        fontWeight: 400,
    },
    "& .createdOnText .dateText": {
        fontWeight: 700
    }
};

export default function MediaList({ media, mediaData }: MediaListProps) {

    const getMaxRes = (mediaList: MediaFileInfo[]) => {

        let bestResMedia: MediaFileInfo | undefined = undefined;

        let maxRes = 0;

        mediaList.forEach((media) => {
            const res = media.height * media.width;

            if (res > maxRes) {
                maxRes = res;
                bestResMedia = media;
            }
        });

        return bestResMedia!;
    };

    const download = (href: string, section: string) => {
        fetch(href, { method: "GET", headers: {} })
            .then(response => {
                response.arrayBuffer().then(function (buffer) {
                    const url = window.URL.createObjectURL(new Blob([buffer]));

                    const link = document.createElement("a");

                    link.href = url;
                    link.setAttribute("download", `${section.split(" ").map((label) => label[0].toUpperCase() + label.slice(1)).join("_")}-` + (new Date().toLocaleString().replace(",", "-")) + ".png");
                    document.body.appendChild(link);
                    link.click();
                });
            });
    };

    return (
        <Box
            sx={{
                width: '50%',
                display: "flex",
                flexDirection: "column",
                pb: 1,
                gap: 2,
                ...MediaListStyle
            }}
        >
            {media.map((file, index) =>
                <Box
                    key={index}
                    className={"thumbnails"}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignContent: 'center',
                            gap: 2.5
                        }}
                    >
                        <InsertDriveFileIcon style={{ marginTop: "auto", marginBottom: "auto" }} />

                        <Box
                            sx={{
                                display: 'flex',
                                alignContent: 'center',
                                flexDirection: 'column',
                                mr: 'auto'
                            }}
                        >
                            <Typography variant="h6">
                                {file.section}
                            </Typography>

                            <Typography variant="subtitle2">
                                {mediaData && mediaData[index]}
                            </Typography>
                            {file.uploadDateInfo &&
                                <DateZone variant='body2' date={new Date(file.uploadDateInfo)} onlyDate />
                            }
                        </Box>
                    </Box>

                    {file.fileInfo[0]?.url &&
                        <IconButton
                            sx={{ color: 'inherit', ml: "auto" }}
                            href={file.mediaType !== "Image" ? file.fileInfo[0]?.url : ""}
                            onClick={() => file.mediaType === "Image" ? download(getMaxRes(file.fileInfo).url, file.section) : {}}
                        >
                            <DownloadIcon color="inherit" />
                        </IconButton>
                    }

                </Box>
            )
            }
        </Box >
    );
}