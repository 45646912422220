import { ClusterPermissionsList, LicensePermissionMap, OrganizationPermissionTypes, OrganizationPermissionsList, Permission, PermissionCategory, ReportPermissionsList } from "src/@types/permissions";
import { TenantType, LicenseTypes } from "src/@types/tenant";
import { hasLicense } from "./tenant";
import { lokalizableRoleStr } from "src/pages/dashboard/role/utils";

export function getOrgPermissionList(tenant: TenantType, all?: boolean) {

    let permissions: Record<string, OrganizationPermissionTypes[]> = {
        ...(all ? { 'all': [] } : {}),
        ...(hasLicense(tenant, LicenseTypes.Core) ? { 'Organization': OrganizationPermissionsList['Organization'], "Customer": OrganizationPermissionsList['Customer'], 'TermsCondtions': OrganizationPermissionsList['TermsCondtions'] } : {}),
        ...(hasLicense(tenant, LicenseTypes.ProductSparePart) ? { 'ProductSparePart': OrganizationPermissionsList['ProductSparePart'] } : {}),
        ...(hasLicense(tenant, LicenseTypes.ProductVehicle) ? { 'ProductVehicle': OrganizationPermissionsList['ProductVehicle'] } : {}),
        ...(hasLicense(tenant, LicenseTypes.Rv) ? { 'RvTemplate': OrganizationPermissionsList['RvTemplate'], 'RvMatrix': OrganizationPermissionsList['RvMatrix'] } : {}),
        ...(hasLicense(tenant, LicenseTypes.WebShop) ? { 'WebShop': OrganizationPermissionsList['WebShop'] } : {}),
        ...(hasLicense(tenant, LicenseTypes.Shipping) ? { 'Shipping': OrganizationPermissionsList['Shipping'] } : {}),
        ...(hasLicense(tenant, LicenseTypes.Group) ? { 'Groups': OrganizationPermissionsList['Groups'] } : {})
    };

    if (!hasLicense(tenant, LicenseTypes.Reports)) {
        Object.keys(permissions).forEach(key => {
            permissions[key] = permissions[key].filter(item => !ReportPermissionsList.includes(item));
        });
    }

    if (all) {
        Object.entries(permissions).forEach(([k, v]) => {

            if (k === "all")
                return;

            permissions["all"] = permissions['all'].concat(v);
        });
    }

    return permissions;
}

export function getOrgPermissionListObj(tenant: TenantType, t: (text: unknown, options?: any) => string) {

    let permissionsList: Permission[] = [];

    //iterate for every license
    Object.values(LicenseTypes).forEach((license) => {

        //if report continue 
        if (license === LicenseTypes.Reports) {
            return;
        }

        //check if tenat has license
        if (hasLicense(tenant, license)) {

            //for each category mapped to the license (generally 1 or 2) add all associated permissions to the list
            LicensePermissionMap[license].forEach(type => {

                permissionsList = permissionsList
                    .concat(OrganizationPermissionsList[type]
                        .map(perm => ({
                            id: perm,
                            label: t(`role.permission.${lokalizableRoleStr(perm)}.title`),
                            type
                        })));
            });
        }
    });

    //remove all report permissions if the tenant is not licensed
    if (!hasLicense(tenant, LicenseTypes.Reports))
        permissionsList = permissionsList.filter(perm => !ReportPermissionsList.includes(perm.id as OrganizationPermissionTypes));

    return permissionsList;
}

export function getClusterPermissionListObj(tenant: TenantType, t: (text: unknown, options?: any) => string) {
    let permissions: Permission[] = [];

    Object.entries(ClusterPermissionsList).forEach(([category, permlist]) => {
        if (category === "Notifications" && !hasLicense(tenant, LicenseTypes.Notification))
            return;

        permlist.forEach(v => {
            permissions.push({
                id: v,
                label: t(`role.permission.${lokalizableRoleStr(v)}.title`),
                type: category as PermissionCategory
            });
        });
    });

    return permissions;
}

export function getPermissionCategories(tenant: TenantType): PermissionCategory[] {
    let categories: PermissionCategory[] = [];

    Object.values(LicenseTypes).forEach((license) => {
        if (license === LicenseTypes.Reports) {
            return;
        }

        if (hasLicense(tenant, license))
            LicensePermissionMap[license].forEach(type => categories.push(type));
    });

    return categories;
}