import { Box, Button, Checkbox, Typography } from "@mui/material";
import { useLocales } from "src/locales";
import LoadingScreen from "src/appComponents/loading-screen/LoadingScreen";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "src/redux/store";
import { DataGrid } from "@mui/x-data-grid";
import { DataGridStyle } from "src/utils/DataGridStyle";
import { useCallback, useEffect, useMemo, useState } from "react";
import { rolesOperations } from "src/redux/roles";
import useResponsive from "src/hooks/useResponsive";
import OrganizationRoleDetails from "./OrganizationRoleDetails";
import { UserOrganization, UserRole } from "src/@types/user";

export default function OrganizationRoleAssignment() {

    const { translate } = useLocales();

    const { watch, setValue } = useFormContext();

    const isDesktop = useResponsive('up', 'md');

    const organizations: UserOrganization[] = watch("organizations");

    const { isLoading, organizationRoleList } = useSelector((state: RootState) => state.roles);

    const [openRolesDetails, setOpenRolesDetails] = useState<boolean>(false);

    useEffect(() => {
        if (organizationRoleList.length < 1) {
            dispatch(rolesOperations.searchOrganizationRoles({ pageIndex: 0, pageSize: 10 }));
        }
    }, [organizationRoleList]);

    const getUserRole = useCallback((roleToCheck: string) => {
        const role = organizationRoleList.find((role) => role.name === roleToCheck);

        return {
            id: role!.id,
            name: role!.name,
            permissions: role!.permissions
        };
    }, [organizationRoleList]);

    const flipSwitch = useCallback((idToCheck: string, roleToCheck: string) => {

        let newRolesArr: UserRole[] = [];

        let index = 0;

        const roleData: UserRole = getUserRole(roleToCheck);

        organizations.forEach((org, indexOfOrg) => {

            if (org.organizationId === idToCheck) {

                newRolesArr = [...org.roles];

                index = indexOfOrg;

                if (newRolesArr.find((role) => role.id === roleData.id)) {

                    const findFunc = (role: UserRole) => role.id === roleData.id;

                    const index = newRolesArr.findIndex(findFunc);

                    newRolesArr = [...newRolesArr.slice(0, index), ...newRolesArr.slice(index + 1, newRolesArr.length + 1)];
                }
                else {
                    newRolesArr.push(roleData);
                }
            }
        });

        const newOrgToSet = [...organizations];

        newOrgToSet[index].roles = newRolesArr;

        setValue("organizations", newOrgToSet);

    }, [organizations, setValue, getUserRole]);

    const isChecked = useCallback((idToCheck: string, roleToCheck: string) => {

        const findFunc = (org: UserOrganization) => org.organizationId === idToCheck;

        const indexOfOrg = organizations.findIndex(findFunc);

        const roleDataToCheck = getUserRole(roleToCheck);

        return !!organizations[indexOfOrg].roles.find((role) => role.id === roleDataToCheck.id);
    }, [organizations, getUserRole]);

    const organizationIds = useMemo(() => {
        return organizations.map((org) => { return { ...org, id: org.organizationId }; });
    }, [organizations]);

    const ORGANIZATION_ROLES_NAMES = useMemo(() => {
        return organizationRoleList.filter((role) => role.enabled).map((role) => role.name);
    }, [organizationRoleList]);

    const columns = useMemo(() => {

        const BASE_COLUMN: any[] = [
            {
                field: 'organization',
                headerName: `${translate('organization.title')}`,
                flex: isDesktop ? 2 : undefined,
                minWidth: !isDesktop ? 130 : undefined,
                sortable: false,
                renderCell: (params: any) =>
                    <Box sx={{ width: "100%" }}>
                        <Typography variant="subtitle2">
                            <b>{params.row?.type}</b>
                        </Typography>
                        <Typography variant="body2" noWrap>
                            {params.row?.externalId ? params.row?.externalId + " - " : ""}{params.row?.name}
                        </Typography>
                    </Box >
            }
        ];

        ORGANIZATION_ROLES_NAMES.forEach((role) => {
            BASE_COLUMN.push(
                {
                    field: role,
                    headerName: role,
                    flex: isDesktop ? 1 : undefined,
                    minWidth: !isDesktop ? 130 : undefined,
                    headerAlign: "center",
                    align: "center",
                    sortable: false,
                    renderCell: (obj: any) => {
                        return (
                            <Checkbox
                                checked={isChecked(obj.id, obj.field)}
                                onClick={() => flipSwitch(obj.id, obj.field)}
                            />
                        );
                    }
                }
            );
        });

        return BASE_COLUMN;
    }, [isDesktop, translate, ORGANIZATION_ROLES_NAMES, flipSwitch, isChecked]);

    return (
        <>
            {isLoading ? <LoadingScreen /> :

                <Box>

                    <OrganizationRoleDetails
                        setOpen={setOpenRolesDetails}
                        open={openRolesDetails}
                    />

                    <Box sx={{ display: 'flex', mb: 2.5, flexDirection: isDesktop ? 'row' : 'column' }} >

                        <Typography variant="h6">
                            {`${translate(`role.title_plural`)}`}
                        </Typography>

                        <Button
                            variant="text"
                            size={"small"}
                            onClick={() => setOpenRolesDetails(prev => !prev)}
                            sx={{ ml: "auto", height: "auto", mt: (isDesktop ? 0 : 1.5) }}
                        >
                            {`${translate("role.detail.view")}`}
                        </Button>

                    </Box>

                    <DataGrid
                        rows={organizationIds || []}
                        columns={columns}
                        loading={isLoading}
                        disableColumnMenu
                        disableRowSelectionOnClick
                        getRowHeight={() => 'auto'}
                        disableColumnResize
                        //onRowClick={handleRowClick}
                        hideFooter
                        sx={{
                            cursor: 'auto',
                            ...DataGridStyle,
                            '& .MuiDataGrid-cell': { py: 2.5 }
                        }}
                    />

                </Box>
            }
        </>

    );
}