import { Collapse, Table, TableBody, TableCell, TableHead, TableRow, Typography, alpha, useTheme } from '@mui/material';
import { OrderSearchResult } from 'src/@types/orders';
import { useLocales } from 'src/locales';

type ItemsTableProps = {
    index: number,
    clickedIndex: number | undefined,
    dense: boolean,
    order: OrderSearchResult
}

export default function ItemsTableRow({ index, clickedIndex, dense, order }: ItemsTableProps) {
    const { translate } = useLocales();

    const theme = useTheme();

    return (
        <TableRow>

            <TableCell style={{ padding: 0 }} colSpan={12} >

                <Collapse in={clickedIndex === index} timeout={'auto'} unmountOnExit>

                    <Table size={dense ? 'small' : 'medium'}>

                        <TableHead sx={{ "& .MuiTableCell-head": { backgroundColor: alpha(theme.palette.primary.lighter, 0.25) }, width: '100%' }}>

                            <TableRow >

                                <TableCell />

                                <TableCell > {`${translate('commons.code')}`}</TableCell>

                                <TableCell > {`${translate('commons.name')}`}</TableCell>

                                <TableCell > {`${translate('commons.quantity')}`}</TableCell>

                                <TableCell > {`${translate('request.priority')}`}</TableCell>

                                <TableCell />

                            </TableRow>

                        </TableHead>

                        <TableBody>
                            {order.items.map((item, index) =>
                                <TableRow hover key={index}>

                                    <TableCell />

                                    <TableCell >
                                        <Typography variant="subtitle2">{item.itemExternalId}</Typography>
                                    </TableCell>

                                    <TableCell >
                                        {item.name}
                                    </TableCell>

                                    <TableCell >
                                        {item.quantity}
                                    </TableCell>

                                    <TableCell >
                                        {item.priority}
                                    </TableCell>

                                </TableRow>
                            )}
                        </TableBody>

                    </Table>

                </Collapse>

            </TableCell>

        </TableRow>
    );
}