import Container from '@mui/material/Container';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import Page from 'src/appComponents/Page';
import useLocales from 'src/appHooks/useLocales';
import { useSettingsContext } from 'src/components/settings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { dispatch, RootState } from 'src/redux/store';
import { FetchDataFuncArgs } from 'src/@types/logs';
import Logs from 'src/appComponents/logs/Logs';
import { ordersOperations } from 'src/redux/orders';
import { DocType } from 'src/@types/orders';

const INVOICE_LABELS = [
    "InvoiceCreated",
    "InvoiceUpdated",
    "InvoiceDisabled",
    "InvoiceEnabled"
];

const CREDIT_NOTE_LABELS = [
    "CreditNoteCreated",
    "CreditNoteUpdated",
    "CreditNoteDisabled",
    "CreditNoteEnabled"
];

const DELIVERY_NOTE_LABELS = [
    "DeliveryNoteCreated",
    "DeliveryNoteUpdated",
    "DeliveryNoteDisabled",
    "DeliveryNoteEnabled"
];

interface DocumentLogsProps {
    isVendor: boolean
}

export default function DocumentLogs({ isVendor }: DocumentLogsProps) {

    const { themeStretch } = useSettingsContext();

    const isDesktop = useResponsive('up', 'sm');

    const { translate, currentLang } = useLocales();

    const { isDocsLogsLoading, documentsLogs, invoice, deliveryNote } = useSelector((state: RootState) => state.orders);

    const { id = '', type } = useParams();

    useEffect(() => {
        if (type === "DeliveryNote" && deliveryNote?.id !== id) {
            dispatch(ordersOperations.getDeliveryNote(id));
        } else if (type === "Invoice" && invoice?.id !== id) {
            dispatch(ordersOperations.getInvoice(id));
        }
    }, [id, currentLang, invoice, deliveryNote, type]);

    const fetchLogs = (searchFilters: FetchDataFuncArgs) => {
        dispatch(ordersOperations.getDocsLogs({ id: id, type: type as DocType, filters: searchFilters, isMine: isVendor }));
    };

    const getExternalId = (type: DocType) => {

        switch (type) {
            case "DeliveryNote":
                return deliveryNote?.externalId;
            case "Invoice":
                return invoice?.prettyName;
            case "CreditNote":
                return "";
        }

        return "";
    };

    //-----LABELS
    const getLabels = (type: DocType) => {

        switch (type) {
            case "DeliveryNote":
                return DELIVERY_NOTE_LABELS;
            case "Invoice":
                return INVOICE_LABELS;
            case "CreditNote":
                return CREDIT_NOTE_LABELS;
        }

        return [];
    };

    const createLabels = useCallback(() => {
        return getLabels(type as DocType).map((label) => ({
            label: `${translate(`documents.logs.eventTypes.${label.charAt(0).toLowerCase() + label.slice(1)}`)}`,
            key: label,
        }));
    }, [translate, type]);

    const typesLabels = useMemo(
        () => [
            {
                label: `${translate(`commons.all`)}`,
                key: 'all',
            },
            ...createLabels(),
        ],
        [createLabels, translate]
    );

    return (
        <Page title={`${translate('documents.logs.title')}`}>
            <Container maxWidth={themeStretch ? false : 'lg'} sx={{ p: 0 }}>
                <HeaderBreadcrumbs
                    heading={`${translate('documents.logs.title')}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate('documents.title_plural')}`, href: isVendor ? PATH_DASHBOARD.orders.vendor.documents.list : PATH_DASHBOARD.orders.vendor.documents.list },
                        { name: `${getExternalId(type as DocType)}`, href: isVendor ? PATH_DASHBOARD.orders.customer.documents.logs(id, type as DocType) : PATH_DASHBOARD.orders.customer.documents.logs(id, type as DocType) },
                        { name: `${translate('commons.logs')}` }
                    ]}
                    sx={{ px: isDesktop ? 5 : 2, mb: isDesktop ? 5 : 2 }}
                />

                <Logs
                    tableData={documentsLogs as any}
                    isLoading={isDocsLogsLoading}
                    id={id!}
                    header={`${translate(`commons.logs`)} `}
                    subheader={`${translate(`documents.logs.subtitle`)}`}
                    labels={typesLabels}
                    fetchData={fetchLogs}
                    localesPath={"documents"}
                />
            </Container>
        </Page>
    );
}
