import axios, { AxiosResponse } from "axios";
import { Core } from "./paths";
import { NewTermCondition, TermCondition, TermConditionFilters, TermConditionSearchResult, TermsConditionEntity } from "src/@types/termCondition";
import { PagedResponseType } from "src/@types/commons";
import { LogsFilters, LogsItem } from "src/@types/logs";

const getDefault = (entityType: TermsConditionEntity): Promise<AxiosResponse<TermCondition>> => {
    const url = Core.admin.TermsCondition.getDefault(entityType);

    return axios.get(url);
};

const detail = (id: string): Promise<AxiosResponse<TermCondition>> => {
    const url = Core.admin.TermsCondition.detail(id);

    return axios.get(url);
};

const update = (id: string, newTermCondition: NewTermCondition): Promise<AxiosResponse> => {
    const url = Core.admin.TermsCondition.update(id);

    return axios.put(url, newTermCondition);
};

const create = (newTermCondition: NewTermCondition): Promise<AxiosResponse> => {
    const url = Core.admin.TermsCondition.create();

    return axios.post(url, newTermCondition);
};

const setDefault = (id: string, entityType: string): Promise<AxiosResponse> => {
    const url = Core.admin.TermsCondition.setDefault(id, entityType);

    return axios.patch(url);

};

const enable = (id: string): Promise<AxiosResponse> => {
    const url = Core.admin.TermsCondition.enable(id);

    return axios.patch(url);

};

const disable = (id: string): Promise<AxiosResponse> => {
    const url = Core.admin.TermsCondition.disable(id);

    return axios.patch(url);

};

const searchLogs = (id: string, filters: LogsFilters): Promise<AxiosResponse<PagedResponseType<LogsItem>>> => {
    const url = Core.admin.TermsCondition.logs(id);

    return axios.get<PagedResponseType<LogsItem>>(url, { params: filters, paramsSerializer: { indexes: null } });
};

const search = (filters: TermConditionFilters): Promise<AxiosResponse<PagedResponseType<TermConditionSearchResult>>> => {
    const url = Core.admin.TermsCondition.search();

    return axios.get<PagedResponseType<TermConditionSearchResult>>(url, {
        params: filters, paramsSerializer: { indexes: null }
    });
};

export {
    getDefault,
    searchLogs,
    disable,
    enable,
    create,
    update,
    setDefault,
    detail,
    search
};
