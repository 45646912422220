import { memo, useState } from 'react';
import { Box } from '@mui/material';
import { ScrollbarProps } from './types';
import { StyledRootScrollbar } from './styles';

// ----------------------------------------------------------------------
export const scrollBarStyle = {
  '&::-webkit-scrollbar': {
    width: '0.4em',
    height: '0.4em',
  },
  '&::-webkit-scrollbar-track': {
    width: 10,
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: 8,
    backgroundColor: '#b6bcc2',
    minHeight: 24,
  },
};

export const scrollBarStyleHidden = {
  '&::-webkit-scrollbar': {
    width: '0.4em',
    height: '0.4em',
  },
  '&::-webkit-scrollbar-track': {
    width: 10,
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: 8,
    backgroundColor: '#b6bcc2',
    minHeight: 24,
    display: 'none'
  },
};

function Scrollbar({ children, sx, ...other }: ScrollbarProps) {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

  const [onHover, setonHover] = useState(false);

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  if (isMobile) {
    return (
      <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <StyledRootScrollbar sx={onHover ? scrollBarStyle : scrollBarStyleHidden} onMouseEnter={() => { setonHover(true); }}
      onMouseLeave={() => { setonHover(false); }}>
      {children}
    </StyledRootScrollbar>
  );
}

export default memo(Scrollbar);
