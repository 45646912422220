import { Autocomplete, Box, Button, Divider, MenuItem, TextField, Typography, useTheme } from "@mui/material";
import { UseFormReturn } from 'react-hook-form';
import useLocales from 'src/appHooks/useLocales';
import { GenericOrganization, GenericOrganizationEditWithFather } from "src/@types/organizations";
import { useState } from "react";
import { Brand, DEFAULT_BRAND } from "src/@types/brand";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { alpha } from '@mui/material/styles';

type Props = {
    outerRef: any;
    formMethods: UseFormReturn<GenericOrganizationEditWithFather>;
    brandList: Brand[];
    isDisabled: (name: string, other?: boolean, and?: boolean) => boolean;
};

export default function OrganizationStep5({ outerRef, formMethods, brandList, isDisabled }: Props) {
    const { getValues, setValue, clearErrors, watch } = formMethods;

    const { translate } = useLocales();

    const theme = useTheme();

    const disabled = true;

    const validateFields = async (yupSchema: any) => {
        const formValues = getValues();

        try {
            await yupSchema.validate(formValues, { abortEarly: false });
            clearErrors();

            return true;
        } catch (err: any) {
            return false;
        }
    };

    if (!outerRef?.current) {
        outerRef.current = {
            validateFields,
            onSave: () => {
                const formValues = getValues();

                return { ...formValues };
            }
        };
    };

    const organizationBrandsListAll = watch("selectedBrands");

    const organizationBrandsListId = watch("brands");

    const updatedBrandList = [...brandList, DEFAULT_BRAND];

    const [brandSelected, setBrandSelected] = useState<Brand>(DEFAULT_BRAND);

    const [inputValue, setInputValue] = useState('');

    const handleAdd = () => {
        organizationBrandsListId.push(brandSelected.id);
        organizationBrandsListAll.push(brandSelected);
        setBrandSelected(DEFAULT_BRAND);
    };

    const handleRemove = (brand: Brand) => {
        let index = organizationBrandsListAll.indexOf(brand);

        const copyAllBrand = [...organizationBrandsListAll];

        copyAllBrand.splice(index, 1);
        setValue("selectedBrands", copyAllBrand);
        index = organizationBrandsListId.indexOf(brand.id);
        organizationBrandsListId.splice(index, 1);
    };

    const getVisibility = (id: string) => {
        return (id === "" || organizationBrandsListId.includes(id)) ? "none" : "block";
    };

    return (
        <Box sx={{ height: 500 }}>

            <Typography variant="h5">{`${translate('organization.form.steps.brand')}`}</Typography>

            <Typography variant="body2" sx={{ mt: 1 }}>
                {`${translate('organization.form.description.brand')}`}
            </Typography>

            <Box
                sx={{
                    display: 'grid',
                    columnGap: 2,
                    mb: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: '88.5% 10%' }
                }}
            >
                <Autocomplete
                    fullWidth
                    sx={{ mt: 2, mb: 2 }}
                    id="brandSelection"
                    options={updatedBrandList}
                    getOptionLabel={(option) => option.name}
                    value={brandSelected}
                    onChange={(event, newValue) => {
                        if (newValue) {
                            setBrandSelected(newValue);
                        }
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    renderOption={(props, option) =>
                        <MenuItem {...props} key={option.id} style={{ display: getVisibility(option.id) }} >
                            <Typography>{option.name} - {option.externalId}</Typography>
                        </MenuItem>
                    }
                    renderInput={(params) => (
                        <TextField {...params} label={`${translate('organization.form.steps.brand')}`} />
                    )}

                    disabled={isDisabled("brands")}
                />

                <Button
                    variant='contained'
                    sx={{ height: "58%", mt: 2 }}
                    onClick={handleAdd}
                    disabled={JSON.stringify(brandSelected) === JSON.stringify(DEFAULT_BRAND)}
                >
                    {`${translate('commons.add')}`}
                </Button>
            </Box>

            <Divider />

            <Box sx={{ overflowY: "scroll", height: 350 }}>
                {
                    organizationBrandsListAll.map((brand, index) =>
                    (
                        <Box key={index} sx={{ m: 3 }}>
                            <Box>
                                <Box
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                                        mb: 1
                                    }}
                                >
                                    <Typography variant="h5">{brand.name}</Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end'
                                        }}
                                    >
                                        <DeleteForeverIcon onClick={() => handleRemove(brand)} />
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'grid',
                                        columnGap: 2,
                                        mb: 2,
                                        gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                                    }}
                                >
                                    <TextField
                                        label={`${translate('commons.code')}`}
                                        disabled={disabled}
                                        value={brand.id}
                                        sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { background: disabled ? alpha(theme.palette.grey[300], 0.20) : "", } } }}
                                    />
                                    <TextField
                                        label={`${translate('commons.externalId')}`}
                                        disabled={disabled}
                                        value={brand.externalId}
                                        sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { background: disabled ? alpha(theme.palette.grey[300], 0.20) : "", } } }}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: 'grid',
                                        columnGap: 2,
                                        rowGap: 3,
                                        mb: 2,
                                        gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: `repeat(${Object.keys(brand.customFields!).length > 2 ? 3 : 2}, 1fr)` },
                                    }}
                                >
                                    {brand.customFields && Object.keys(brand.customFields).map((customKey) => (
                                        <TextField
                                            label={customKey}
                                            key={customKey}
                                            disabled={disabled}
                                            value={brand.customFields![customKey]}
                                            sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { background: disabled ? alpha(theme.palette.grey[300], 0.20) : "", } } }}
                                        />
                                    ))}
                                </Box>
                            </Box>
                            <Divider />
                        </Box>
                    ))
                }
            </Box>
        </Box >
    );
}
