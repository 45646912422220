import dayjs from 'dayjs';
import { RvMatrixStatusTypes, UserMatrixTemplateDocument } from 'src/@types/matrix';
import * as Yup from 'yup';

export const MIN_KM = 0;

export const MAX_KM = 500000;

export const MIN_KM_PUNTO_PIVOT = 500;

export const MAX_KM_PUNTO_PIVOT = 500000;

export const MIN_PRICE_VEHICLE_FILTER = 0;

export const MAX_PRICE_VEHICLE_FILTER = 200;

export const STEP_KM_VEHICLE_FILTER = 10000;

export const STEP_PRICE_VEHICLE_FILTER = 10;

const createNewMonthsRange = (acc: any, elem: any, ind: number) => {
    if (ind === 0) return { ...acc, '3': elem };

    return { ...acc, [(ind) * 6]: elem };
};

const dateRegExp = new RegExp(/^$|^([0-9]{4}|[0-9]{2})[.-]([0]?[1-9]|[1][0-2])[.-]([0]?[1-9]|[1|2][0-9]|[3][0|1])$/);

export const form0Schema = Yup.object().shape({
    name: Yup.string().trim().required('commons.validation.requiredField'),
    validFrom: Yup.string().required('commons.validation.requiredField'),
    validTo: Yup.string().matches(dateRegExp, 'commons.validation.wrongDate').required('commons.validation.requiredField'),
});

export const form1Schema = Yup.object().shape({
    manufacturerCodes: Yup.array().min(1, 'commons.validation.choosePNO')
});

const validateRegardingPreviousElement = (value: any, previousValue: any) => {
    return typeof value === "undefined" || (previousValue !== 0 && value === 0) || (previousValue === 0 && value === 0) || value < previousValue;
};

export const form2Schema = Yup.object({
    pivotRv: Yup.object({
        center: Yup.number().required('commons.validation.requiredField'),
        before: Yup.number().required('commons.validation.requiredField'),
        after: Yup.string().required('commons.validation.requiredField'),
        minimal: Yup.string().required('commons.validation.requiredField'),
        monthsPercent: Yup.object({
            "3": Yup.number(),
            "6": Yup.number().test(
                "is-greater-than-3",
                "commons.validation.pivotNextError",
                function (value: number | undefined) {
                    return validateRegardingPreviousElement(value, this.parent["3"]);
                }
            ),
            "12": Yup.number().test(
                "is-greater-than-6",
                "commons.validation.pivotNextError",
                function (value: number | undefined) {
                    return validateRegardingPreviousElement(value, this.parent["6"]);
                }
            ),
            "18": Yup.number().test(
                "is-greater-than-12",
                "commons.validation.pivotNextError",
                function (value: number | undefined) {
                    return validateRegardingPreviousElement(value, this.parent["12"]);
                }
            ),
            "24": Yup.number().test(
                "is-greater-than-18",
                "commons.validation.pivotNextError",
                function (value: number | undefined) {
                    return validateRegardingPreviousElement(value, this.parent["18"]);
                }
            ),
            "30": Yup.number().test(
                "is-greater-than-24",
                "commons.validation.pivotNextError",
                function (value: number | undefined) {
                    return validateRegardingPreviousElement(value, this.parent["24"]);
                }
            ),
            "36": Yup.number().test(
                "is-greater-than-30",
                "commons.validation.pivotNextError",
                function (value: number | undefined) {
                    return validateRegardingPreviousElement(value, this.parent["30"]);
                }
            ),
            "42": Yup.number().test(
                "is-greater-than-36",
                "commons.validation.pivotNextError",
                function (value: number | undefined) {
                    return validateRegardingPreviousElement(value, this.parent["36"]);
                }
            ),
            "48": Yup.number().test(
                "is-greater-than-42",
                "commons.validation.pivotNextError",
                function (value: number | undefined) {
                    return validateRegardingPreviousElement(value, this.parent["42"]);
                }
            ),
            "54": Yup.number().test(
                "is-greater-than-48",
                "commons.validation.pivotNextError",
                function (value: number | undefined) {
                    return validateRegardingPreviousElement(value, this.parent["48"]);
                }
            ),
            "60": Yup.number().test(
                "is-greater-than-54",
                "commons.validation.pivotNextError",
                function (value: number | undefined) {
                    return validateRegardingPreviousElement(value, this.parent["54"]);
                }
            ),
            "66": Yup.number().test(
                "is-greater-than-60",
                "commons.validation.pivotNextError",
                function (value: number | undefined) {
                    return validateRegardingPreviousElement(value, this.parent["60"]);
                }
            ),
            "72": Yup.number().test(
                "is-greater-than-66",
                "commons.validation.pivotNextError",
                function (value: number | undefined) {
                    return validateRegardingPreviousElement(value, this.parent["66"]);
                }
            ),
            "78": Yup.number().test(
                "is-greater-than-72",
                "commons.validation.pivotNextError",
                function (value: number | undefined) {
                    return validateRegardingPreviousElement(value, this.parent["72"]);
                }
            ),
            "84": Yup.number().test(
                "is-greater-than-78",
                "commons.validation.pivotNextError",
                function (value: number | undefined) {
                    return validateRegardingPreviousElement(value, this.parent["78"]);
                }
            ),
            "90": Yup.number().test(
                "is-greater-than-84",
                "commons.validation.pivotNextError",
                function (value: number | undefined) {
                    return validateRegardingPreviousElement(value, this.parent["84"]);
                }
            ),
            "96": Yup.number().test(
                "is-greater-than-90",
                "commons.validation.pivotNextError",
                function (value: number | undefined) {
                    return validateRegardingPreviousElement(value, this.parent["90"]);
                }
            ),
        })
    })
});

export const form3Schema = Yup.object().shape({
    pivotKm: Yup.object().shape({
        kmAmount: Yup.number().required('Field Required').nonNullable().min(1, 'must be greater then zero').max(MAX_KM, 'The max amount of kilometers is 500 000'),
    })
});

export const form4Schema = Yup.object().shape({
    increaseRvSteps: Yup.object().shape({
        kmStep: Yup.number().nonNullable().typeError('must be a number').required('commons.validation.requiredField').min(1, 'commons.validation.requiredField').max(MAX_KM, 'The max amount of kilometers is 500 000'),
    })
});

export const form5Schema = Yup.object().shape({
    decreaseRvSteps: Yup.object().shape({
        upToKm: Yup.number().min(1, 'commons.validation.requiredField'),
        overToKm: Yup.number().min(1, 'commons.validation.requiredField')
    })
});

export const form6Schema = Yup.object().shape({});

const getQuarterFromData = (inputDate: string) => {

    const quarter = quarters.find(q => q.monthFrom === dayjs(inputDate).month())?.value!;

    return quarter;
};

export function defaultMatrix(matrix?: UserMatrixTemplateDocument | null): UserMatrixTemplateDocument {
    return {
        id: matrix?.id || null,
        name: matrix?.name || '',
        description: matrix?.description || '',
        quarter: matrix?.validFrom ? getQuarterFromData(matrix?.validFrom) : '',
        validFrom: matrix?.validFrom || null,
        validTo: matrix?.validTo || null,
        manufacturerCodes: matrix?.manufacturerCodes || [],
        pivotRv: {
            before: matrix?.pivotRv?.before || 0,
            after: matrix?.pivotRv?.after || 0,
            center: matrix?.pivotRv?.center || 1,
            minimal: matrix?.pivotRv?.minimal || 0,
            monthsPercent: matrix?.pivotRv?.monthsPercent ||
                new Array(17).fill(0).reduce(createNewMonthsRange, {})
        },
        pivotKm: {
            kmAmount: matrix?.pivotKm?.kmAmount || 0,
            kmPerMonth: matrix?.pivotKm?.kmPerMonth ||
                new Array(17).fill(0).reduce(createNewMonthsRange, {})
        },
        increaseRvSteps: {
            kmStep: matrix?.increaseRvSteps?.kmStep || 0,
            kmPerMonth: matrix?.increaseRvSteps?.kmPerMonth ||
                new Array(17).fill(0).reduce(createNewMonthsRange, {}),
            increaseRvPerMonth: matrix?.increaseRvSteps?.increaseRvPerMonth ||
                new Array(17).fill(0).reduce(createNewMonthsRange, {})
        },
        decreaseRvSteps: {
            upToKm: matrix?.decreaseRvSteps?.upToKm || 0,
            overToKm: matrix?.decreaseRvSteps?.overToKm || 0,
            steps: matrix?.decreaseRvSteps?.steps || { '0': 0 }
        },
        decreaseRv: {
            decreaseKmVsPivot: matrix?.decreaseRv.decreaseKmVsPivot || {},
            decreasePerMonth: matrix?.decreaseRv?.decreasePerMonth ||
                new Array(17).fill([]).reduce(createNewMonthsRange, {})
        },
        tags: matrix?.tags || []
    };
}

export const defaultSection = {
    from: 0,
    to: 0,
    everyKM: '',
    decreseRV: new Array(17).fill(0).map((num: string) => num),
};

export const statuses = [...Object.keys(RvMatrixStatusTypes).filter(key => isNaN(Number(key)))];

export const filterSchema = Yup.object().shape({
    validFrom: Yup.string().nullable().matches(dateRegExp, 'commons.validation.wrongDate'),
    validTo: Yup.string().nullable().matches(dateRegExp, 'commons.validation.wrongDate'),
});

export const rvMatrixfilterSchema = Yup.object().shape({
    startDate: Yup.string().nullable().matches(dateRegExp, 'commons.validation.wrongDate'),
    endDate: Yup.string().nullable().matches(dateRegExp, 'commons.validation.wrongDate'),
});

export const quarters = [
    {
        label: 'quarter1',
        value: '0',
        dayFrom: 1,
        monthFrom: 0,
        dayTo: 31,
        monthTo: 2,
    },
    {
        label: 'quarter2',
        value: '1',
        dayFrom: 1,
        monthFrom: 3,
        dayTo: 30,
        monthTo: 5,
    },
    {
        label: 'quarter3',
        value: '2',
        dayFrom: 1,
        monthFrom: 6,
        dayTo: 30,
        monthTo: 8,
    },
    {
        label: 'quarter4',
        value: '3',
        dayFrom: 1,
        monthFrom: 9,
        dayTo: 31,
        monthTo: 11,
    }
];
