import axios, { AxiosResponse } from 'axios';

// @types
import { TenantType } from '../@types/tenant';

import { Core } from './paths';
import { CustomField } from 'src/@types/customField';

const getTenant = (): Promise<AxiosResponse<TenantType>> => {
    const url = Core.public.tenant.detail();

    return axios.get<TenantType>(url);
};

const getCustomField = (): Promise<AxiosResponse<CustomField[]>> => {
    const url = Core.public.tenant.customField();

    return axios.get<CustomField[]>(url);
};

export { getTenant, getCustomField };
