import { alpha, Autocomplete, Box, Card, Chip, Divider, FormControlLabel, IconButton, MenuItem, Switch, TablePagination, TextField, Typography } from "@mui/material";
import { debounce } from "lodash";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { GroupFilters, GroupSearchResult, WhiteBlackListDisplay } from "src/@types/group";
import { OrganizationSearchResult } from "src/@types/organizations";
import InfiniteAutocomplete from "src/appComponents/InfiniteAutocomplete";
import StepButtons from "src/components/buttons/StepButtons";
import { useLocales } from "src/locales";
import { groupOperations } from "src/redux/group";
import { dispatch, RootState } from "src/redux/store";
import { usersOperations } from "src/redux/users";
import { WhiteBlackList } from "../../group/newEditGroup/WhiteBlacklistStep";
import useTable from "src/appHooks/useTable";
import useResponsive from "src/hooks/useResponsive";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { DeleteForever } from "@mui/icons-material";
import { noData } from "src/components/empty-content/EmptyContent";
import { DataGridStyle } from "src/utils/DataGridStyle";

interface CustomerStepProps {
    changeStep: Dispatch<SetStateAction<number>>
    onSubmit: (list: GroupSearchResult[]) => void,
    list: GroupSearchResult[]
}

const debounceGroupSearch = debounce((options: GroupFilters) => dispatch(groupOperations.searchGroups(options)), 500);

export default function CustomerStep({ changeStep, onSubmit, list }: CustomerStepProps) {
    const { translate } = useLocales();

    const [allElegibles, setAllElegibles] = useState(false);

    const [groups, setGroups] = useState<GroupSearchResult[]>(list);

    const { groupsList, isLoading } = useSelector((state: RootState) => state.group);

    useEffect(() => {
        searchGroups("");
        dispatch(usersOperations.searchUsers({ pageIndex: 0, pageSize: 5, onlyEnabled: true }));
    }, []);

    const searchGroups = (searchParam: string) => {

        let options: GroupFilters = {
            pageIndex: 0,
            pageSize: 5,
            all: searchParam,
            onlyEnabled: true
        };

        if (searchParam.length >= 3 || searchParam.length === 0)
            debounceGroupSearch(options);
    };

    const handleSubmit = () => {
        onSubmit(groups);
    };

    const filterOptions = (options: GroupSearchResult[]): GroupSearchResult[] => {
        const ids = groups.map(v => v.groupId);

        return options.filter(v => !ids.includes(v.groupId));
    };

    return (
        <Box >
            <Card sx={{ p: 3, display: 'flex', gap: 2, flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" >{translate("webshop.form.eligibleCustomer")}</Typography>
                    <FormControlLabel
                        label={translate("webshop.form.allEligible")}
                        onChange={(_, check) => setAllElegibles(check)}
                        control={<Switch checked={allElegibles} />}
                    />
                </Box>
                {!allElegibles &&
                    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                        <Typography variant="subtitle2">{translate("webshop.form.selectCategory")}</Typography>
                        <InfiniteAutocomplete<GroupSearchResult>
                            searchCallBack={(params) => dispatch(groupOperations.searchGroups({ ...params, onlyEnabled: true })).unwrap()}
                            loading={isLoading}
                            isOptionEqualToValue={(option, value) => option.groupId === value.groupId}
                            startingValues={groupsList}
                            getOptionLabel={(option) => (typeof option !== 'string' && option.displayName) || ""}
                            filterOptions={(options) => filterOptions(options)}
                            onChange={(_, v) => {
                                if (v && typeof v !== "string")
                                    setGroups(prev => prev.concat([v]));
                            }}
                            renderOption={((props, option) => (
                                <Box {...props} component={'li'} key={option.groupId} >
                                    <Typography sx={{ mr: 2 }}>{option.displayName}</Typography>
                                    <Chip label={option.numberOfOrganizations + " customers"} />
                                </Box>
                            ))}

                            renderInput={(params) =>
                                <TextField
                                    label={translate("user.form.searchIdName")}
                                    {...params}
                                    InputProps={{ ...params.InputProps }}
                                    sx={{ '& .MuiInputLabel-asterisk': { color: 'error.main' } }}
                                />}
                        />
                        {!groups.length ?
                            <GreyCounter label={translate("groups.messages.noSelected")} />
                            :
                            <GroupList list={groups} totalCount={groups.length} setList={(list) => setGroups(list)} />
                        }
                        <Divider sx={{ my: 3 }} />

                        <Typography variant="subtitle2">{translate("groups.form.selectBlacklist")}</Typography>

                        <WhitelistBlacklist />
                        <Divider sx={{ my: 3 }} />

                        <Typography variant="subtitle2">{translate("groups.form.selectWhitelist")}</Typography>
                        <WhitelistBlacklist />
                    </Box>}
                <Divider sx={{ my: 3 }} />
                <StepButtons changeStep={changeStep} onSave={handleSubmit} />
            </Card>

        </Box>
    );
}

interface GreyCounterProps {
    label: string
}

export function GreyCounter({ label }: GreyCounterProps) {
    return (
        <Box sx={{
            width: '100%',
            height: "50px",
            borderRadius: '12px',
            bgcolor: (theme) => theme.palette.background.neutral,
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: (theme) => `1px dashed ${alpha(theme.palette.grey[500], 0.32)}`,
        }}>
            <Typography variant="body2" sx={{ color: (theme) => theme.palette.text.disabled }}>{label}</Typography>
        </Box>
    );
}

function WhitelistBlacklist() {

    const { translate } = useLocales();

    const [blackWhiteList, setBlackWhiteList] = useState<WhiteBlackListDisplay>({ organizations: [], users: [] });

    const [selectSearch, setSelectSearch] = useState<"Users" | "Organizations">("Users");

    const { userList, isLoading: userLoading } = useSelector((state: RootState) => state.user);

    return (
        <Box>
            <Box sx={{ justifyContent: 'center', display: 'flex', pb: 0, my: 3, gap: 2 }}>
                <TextField
                    sx={{ width: '30%' }}
                    label={translate('commons.search')}
                    select
                    value={selectSearch}
                    onChange={(e) => setSelectSearch(e.target.value as ("Users" | "Organizations"))}
                >
                    <MenuItem value="Users">
                        {translate('commons.users')}
                    </MenuItem>
                    <MenuItem value="Organizations">
                        {translate('commons.organizations')}
                    </MenuItem>
                </TextField>
                {selectSearch === "Users" &&
                    <Autocomplete
                        fullWidth
                        id="userSelection"
                        freeSolo
                        filterOptions={(options) => options}
                        disabled={blackWhiteList.users.length >= 100}
                        loading={userLoading}
                        options={userList}
                        onChange={(_, v) => {
                            if (v && typeof v !== 'string' && !blackWhiteList.users.find(el => el.id === v.id))
                                setBlackWhiteList(p => ({ ...p, users: p.users.concat([v]) }));
                        }}
                        getOptionLabel={(option) => typeof option !== "string" ? option.firstName + " " + option.lastName : ""}
                        renderOption={(props, option) =>
                            <li {...props} key={option.id}>
                                <Typography sx={{ mr: 2 }}>{option.firstName + " " + option.lastName}</Typography>
                                <Chip label={option.username} />
                            </li>
                        }
                        renderInput={(params) => (<TextField
                            {...params}
                            label={`${translate('user.form.searchIdName')}`}
                            helperText={blackWhiteList.users.length >= 100 && translate('groups.messages.maxNumber')}
                        />)}
                    />}
                {selectSearch === "Organizations" &&
                    <Autocomplete
                        fullWidth
                        id="organizationSelection"
                        freeSolo
                        disabled={blackWhiteList.organizations.length >= 100}

                        filterOptions={(options) => options}
                        options={[] as OrganizationSearchResult[]}
                        onChange={(_, v) => {
                            if (v && typeof v !== 'string' && !blackWhiteList.organizations.find(el => el.id === v.id))
                                setBlackWhiteList(p => ({ ...p, organizations: p.organizations.concat([v]) }));
                        }}
                        getOptionLabel={(option) => typeof option !== "string" ? option.name : ""}
                        renderOption={(props, option) =>
                            <li {...props} key={option.id}>
                                <Typography sx={{ mr: 2 }}>{option.externalId ? option.externalId + " - " : ""}{option.name}</Typography>
                                <Chip label={option.type} />
                            </li>
                        }
                        renderInput={(params) => (
                            <TextField {...params}
                                label={`${translate('user.form.searchIdName')}`}
                                helperText={blackWhiteList.organizations.length >= 100 && translate('groups.messages.maxNumber')}
                            />)}
                    />}
            </Box>
            <Box sx={{ mb: 3 }}>
                {["Users", "Organizations"].map((type) => {

                    const list = type === "Users" ? blackWhiteList.users : blackWhiteList.organizations;

                    if (selectSearch === type)
                        return (

                            <Box key={type}>
                                {!list.length ?
                                    <GreyCounter label={translate('groups.messages.noSelected')} />
                                    :
                                    <WhiteBlackList type={selectSearch} list={blackWhiteList} setList={(v) => setBlackWhiteList(v)} />
                                }
                            </Box>

                        );
                })}
            </Box>
        </Box>
    );
}

interface GroupListProps {
    list: GroupSearchResult[]
    setList?: (list: GroupSearchResult[]) => void,
    isLoading?: boolean,
    totalCount: number
}

function GroupList({ list, setList, isLoading, totalCount }: GroupListProps) {

    const { translate } = useLocales();

    const { dense, onChangeDense, page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable();

    const isDesktop = useResponsive('up', 'lg');

    const handleDelete = useCallback((id: string) => {

        if (setList)
            setList(list.filter(el => el.groupId !== id));
    }, [list, setList]);

    const COLUMNS: GridColDef<GroupSearchResult>[] = useMemo(() => [
        {
            field: 'displayName',
            headerName: `${translate('commons.name')}`,
            flex: isDesktop ? 0.9 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: false
        },
        {
            field: 'groupType',
            headerName: `${translate('groups.list.columns.groupType')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: false,
            valueGetter: (_, param) => translate('groups.list.type.' + param.groupType)
        },
        {
            field: 'numberOfOrganizations',
            headerName: `${translate('groups.list.columns.numberOfOrganizations')}`,
            flex: isDesktop ? 0.7 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false
        },
        {
            field: 'numberOfUsers',
            headerName: `${translate('groups.list.columns.numberOfUsers')}`,
            flex: isDesktop ? 0.7 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false
        },
        ...(setList ? [
            {
                field: 'del',
                headerName: '',
                flex: isDesktop ? 0.25 : undefined,
                minWidth: !isDesktop ? 155 : undefined,
                renderCell: (params: any) =>
                    <IconButton onClick={() => handleDelete(params.id.toString())}>
                        <DeleteForever />
                    </IconButton>
            }
        ] : [])
    ], [handleDelete, isDesktop, setList, translate]);

    const getHeight = () => {
        let height: string | number = "auto";

        const { length } = list;

        if (!totalCount) return 120 + 56 * length;

        if (!dense || length === 0) {
            if (isDesktop) height = rowsPerPage === 5 ? 380 : 650;
            else height = rowsPerPage === 5 ? 440 : 700;
        }

        return height;
    };

    const getMaxHeight = () => {
        if (!totalCount) return "auto";

        return isDesktop ? 650 : 700;
    };

    return (
        <DataGrid
            rows={list}
            columns={COLUMNS}
            pagination
            getRowId={(row) => row.groupId}
            paginationMode="client"
            paginationModel={{
                page: page,
                pageSize: rowsPerPage
            }}
            disableColumnResize
            density={dense ? 'compact' : 'standard'}
            disableColumnMenu
            pageSizeOptions={[5, 10, 15, 30]}
            disableRowSelectionOnClick
            loading={isLoading}
            slots={{
                noRowsOverlay: noData,
                footer: () => (
                    <Box sx={{
                        position: 'relative',
                        width: { xs: "90vw", md: "auto" },
                    }}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15, 30]}
                            component="div"
                            count={totalCount ?? list.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={onChangePage}
                            onRowsPerPageChange={onChangeRowsPerPage}
                            labelRowsPerPage={`${translate('commons.rowsPerPage')}`}
                            sx={{
                                overflow: "hidden",
                                "& .MuiTablePagination-input": {
                                    ml: { xs: 0.5, md: "default" },
                                    mr: { xs: 3.5, md: "default" }
                                }
                            }}
                        />
                        <FormControlLabel
                            control={<Switch
                                checked={dense}
                                onChange={onChangeDense}
                            />}
                            label={`${translate('commons.dense')}`}
                            sx={{
                                py: { xs: 0, sm: 1.5 },
                                pb: { xs: 1.5, sm: 0 },
                                mx: 0,
                                top: 0,
                                justifyContent: "center",
                                width: { xs: "90vw", sm: "auto" },
                                position: { sm: 'absolute' }
                            }}
                        />
                    </Box>
                )
            }}
            sx={{
                ...DataGridStyle,
                cursor: "default",
                height: getHeight(),
                maxHeight: getMaxHeight()
            }}
        />
    );
}
