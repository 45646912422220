import { Typography, TypographyProps } from "@mui/material";
import { useEffect, useState } from "react";
import { ConversionType } from "src/@types/metricSystem";
import { useSettingsContext } from "src/components/settings";
import metricConvert from "src/utils/metricConvert";

interface MetricProps extends TypographyProps {
    value: number;
    type: ConversionType;
    toInteger?: boolean;
}

/**
 * 
 * @param value       value to convert
 * @param type        type of conversion: "distance" (km to miles) / "dimension" (m to feet) / "weight" (kg to lb) / "volume" (lt to gal) / "speed" (km/h to mi/h)
 * @param toInteger   to round the value only to the integer part
 * 
 * @returns           converted value
 */
export default function MetricImperial({ value, type, toInteger, ...TypographyProps }: MetricProps) {

    const { metricSystem } = useSettingsContext();

    const [convertedValue, setConvertedValue] = useState(value);

    useEffect(() => {
        if (!metricSystem) {
            setConvertedValue(metricConvert(value, type));
        } else if (value !== convertedValue) {
            setConvertedValue(value);
        }
    }, [metricSystem]);

    const getUnit = () => {
        let unit = "";

        switch (type) {
            case "distance":
                unit = metricSystem ? " Km" : " Mi";
                break;
            case "weight":
                unit = metricSystem ? " Kg" : " Lb";
                break;
            case "dimension":
                unit = metricSystem ? " M" : " Ft";
                break;
            case "volume":
                unit = metricSystem ? " Lt" : " U.S. Gal";
                break;
            case "speed":
                unit = metricSystem ? " Km/h" : " Mi/h";
                break;
        }

        return unit;
    };

    return (
        <Typography {...TypographyProps}>
            {(toInteger ? Math.round(convertedValue) : convertedValue.toFixed(2)) + getUnit()}
        </Typography>
    );
}