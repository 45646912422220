import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';

import { SettingsState } from "src/@types/settings";

const initialState: SettingsState = {
    isOpen: false
};

const slice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        // SET OPEN
        setOpen(state, action) {
            state.isOpen = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

export function setOpen(openValue: boolean) {
    return () => {
        dispatch(slice.actions.setOpen(openValue));
    };
}
