import { Box, Typography } from "@mui/material";
import useResponsive from "src/hooks/useResponsive";
import MetricImperial from "./MetricImperial";
import { ConversionType } from "src/@types/metricSystem";

interface Props {
    label: string;
    text: string;
    align?: string;
    metricType?: ConversionType;
}

export const LabeledText = ({ label, text, align, metricType }: Props) => {
    const isDesktop = useResponsive('up', 'sm');

    const speedRemover = (text: string) => {

        let textClean = text;

        if (text.includes("km/h")) {
            textClean = text.replace("km/h", "");
        }

        return textClean;
    };

    return (
        <Box sx={{ display: 'grid', alignItems: 'end', gridTemplateColumns: { sm: 'repeat(2, 1fr)' } }}>
            <Typography display="inline" sx={{ color: 'text.secondary', fontWeight: '400', whiteSpace: "nowrap" }}>
                {`${label}: `}
            </Typography>
            {
                (metricType && text !== '—') ?
                    <MetricImperial
                        value={Number(speedRemover(text))}
                        type={metricType}
                        display="inline"
                        sx={{ color: 'text.primary', fontWeight: '600', textAlign: `${align ? align : "left"}`, pl: isDesktop ? 2 : 0 }}
                    /> :
                    <Typography display="inline" sx={{ color: 'text.primary', fontWeight: '600', textAlign: `${align ? align : "left"}`, pl: isDesktop ? 2 : 0 }}>
                        {text}
                    </Typography>
            }
        </Box>
    );
};
