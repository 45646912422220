import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import { MatrixResult } from "src/hooks/usePowersearch";
import { Box } from '@mui/material';

interface CustomerSearchTemplateProps {
    option: MatrixResult;
    searchQuery: string;
};

export default function CustomerSearchTemplate({ option, searchQuery }: CustomerSearchTemplateProps) {

    const nameParts = parse(`${option.organizationName}`, match(`${option.organizationName}`, searchQuery));

    const externalIdParts = parse(`${option.externalId}`, match(`${option.externalId}`, searchQuery));

    const pathParts = parse(option?.path, match(option?.path, searchQuery));

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                {option.externalId && (
                    <div>
                        {externalIdParts.map((part, index) =>
                            <Box
                                key={index}
                                component="span"
                                sx={{
                                    typography: 'subtitle2',
                                    textTransform: 'capitalize',
                                    color: part.highlight ? 'primary.main' : 'text.primary'
                                }}
                            >
                                {part.text}
                            </Box>
                        )}
                    </div>
                )}
                {option.organizationName && (
                    <div>
                        {nameParts.map((part, index) => (
                            <Box
                                key={index}
                                component="span"
                                sx={{
                                    typography: 'subtitle2',
                                    textTransform: 'capitalize',
                                    color: part.highlight ? 'primary.main' : 'text.primary'
                                }}
                            >
                                {part.text}
                            </Box>
                        ))}
                    </div>
                )}
            </div>
            {option.path && (
                <div>
                    {pathParts.map((part, index) => (
                        <Box
                            key={index}
                            component="span"
                            sx={{
                                typography: 'caption',
                                color: part.highlight ? 'primary.main' : 'text.secondary',
                            }}
                        >
                            {part.text}
                        </Box>
                    ))}
                </div>
            )}

        </div>
    );
}