import { memo } from 'react';
import { Box, Stack } from '@mui/material';
import { NavSectionProps, NavListProps } from '../types';
import NavList from './NavList';
import { hasLicense } from 'src/utils/tenant';
import useTenant from 'src/appHooks/useTenant';
import { hasPermissions } from 'src/utils/user';
import { useUserOrganizationContext } from 'src/contexts/UserOrganizationContext';
import { useAuth } from 'src/appHooks/useAuth';
import LicenseGuard from 'src/guards/LicenseGuard';

function NavSectionMini({ data, sx, ...other }: NavSectionProps) {

  const { user } = useAuth();

  const tenant = useTenant();

  const { organization } = useUserOrganizationContext();

  return (
    <Stack
      spacing={0}
      alignItems="center"
      sx={{
        px: 0.75,
        ...sx,
      }}
      {...other}
    >
      {data.map((group) => {

        if (group.forGod && !user.isGod) {
          return null;
        }

        if (tenant.licenses && group.license && !hasLicense(tenant, group.license)) {
          return null;
        }

        if (!group.permissions ||
          hasPermissions(group.permissions, user.roles, false) ||
          hasPermissions(group.permissions, organization.roles, false)) {
          const key = group.subheader || group.items[0].title;

          return <Items key={key} items={group.items} />;
        }

        return <></>;

      })}
    </Stack>
  );
}

export default memo(NavSectionMini);

// ----------------------------------------------------------------------

type ItemsProps = {
  items: NavListProps[];
};

function Items({ items }: ItemsProps) {

  return (
    <>
      {items.map((list, index) => (
        <LicenseGuard key={index} license={list.license}>
          <NavList
            data={list}
            depth={1}
            hasChild={!!list.children}
          />
        </LicenseGuard>
      ))}

      <Box sx={{ width: 24, height: '1px', bgcolor: 'divider', my: '8px !important' }} />
    </>
  );
}
