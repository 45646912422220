import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, BoxProps } from '@mui/material';
import { ThemeModeValue, useSettingsContext } from 'src/components/settings';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
  align?: any;
  theme?: ThemeModeValue
}

export default function Logo({ disabledLink = false, sx, align, theme }: Props) {
  const { themeMode } = useSettingsContext();

  const logoPath = `/assets/${process.env.REACT_APP_TENANT}/logo-${theme ? theme : themeMode}.svg`;

  const logo = (
    <Box
      component="img"
      src={logoPath}
      sx={{ width: 'auto', height: 50, cursor: disabledLink ? 'default' : 'pointer', display: 'inline-block', ...sx }}
    />
  );

  if (disabledLink)
    return (
      <Box style={{ flexGrow: 1, textAlign: align ? align : 'center' }}>
        {logo}
      </Box>);

  return <RouterLink to="/dashboard" style={{ flexGrow: 1, textAlign: align ? align : 'center' }}>{logo}</RouterLink>;
}