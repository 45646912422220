import { Box, BoxProps, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { useMemo } from "react";
import { CustomField } from "src/@types/customField";
import RHFCurrencyTextField from "src/appComponents/RHFCurrencyTextField";
import useTenant from "src/appHooks/useTenant";
import { RHFSelect, RHFTextField } from "src/components/hook-form";
import RHFDatePicker from "src/components/hook-form/RHFDatePicker";
import { useSettingsContext } from "src/components/settings";
import { useLocales } from "src/locales";
import { handleCustomFields } from "./CustomFieldManagment";
import useCurrency from "src/appHooks/useCurrency";
import { useFormContext } from "react-hook-form";

type GenericCustomFieldsInForm = {
    customFields: Record<string, string>
}

interface CustomFieldFormRendererProps<T extends GenericCustomFieldsInForm> extends BoxProps {
    context: string,
    defaultValues: T,
    section?: string[],
    isDisabledFunc?: (name: string, other?: boolean, and?: boolean) => boolean,
    onEditForm?: boolean,
    setOnEditForm?: (value: boolean) => void,
    setErrorInCustomfields: (value: boolean) => void,
    //----- used in react-hook-form validation
    onChangeValidate?: (field: any) => void
}

export default function CustomFieldFormRenderer<T extends GenericCustomFieldsInForm>({
    context,
    defaultValues,
    section,
    isDisabledFunc,
    onEditForm,
    setOnEditForm,
    setErrorInCustomfields,
    onChangeValidate,
    sx
}: CustomFieldFormRendererProps<T>) {

    const { customFields } = useTenant();

    const { currency: currentCurrency } = useSettingsContext();

    const { rates } = useCurrency();

    const { currentLang } = useLocales();

    const { getValues, setValue, setError, clearErrors, watch } = useFormContext();

    const values: Record<string, string> = watch("customFields");

    const contextedCustomfields = useMemo(() => (customFields && customFields.length > 0) ?
        [...customFields.filter(x => x.enabled && x.entityType.toLowerCase() === context.toLowerCase() && (section ? section.includes(x.section) : true))] : [], [customFields, context, section]);

    const handleCallBack = (event: any, dateValue?: string, dateFieldName?: string) => {
        handleCustomFields({
            event,
            setValue,
            setError,
            clearErrors,
            customFields: contextedCustomfields,
            setErrorInCustomfields,
            dateValue,
            dateFieldName,
            currentCurrency,
            rates
        });
    };

    const getDefaultValue = (field: CustomField) => (defaultValues.customFields[field.id]) ? defaultValues.customFields[field.id] : field.defaultValue;

    const getDefaultSelectValue = (field: CustomField) => (values && values[field.id]) ? values[field.id] : field.selectOptions!.find(i => i.isDefault)?.values[0].text || '';

    const checkDisabledField = (name: string, other?: boolean) => isDisabledFunc ? isDisabledFunc(name, !!other) : false;

    const getHelperText = (field: CustomField) => {

        const { validationMessage, required, id } = field;

        if (required && !getValues(`customFields.${id}`)) {

            return "commons.validation.requiredField";
        }

        if (validationMessage && validationMessage.length > 0) {
            if (validationMessage.length === 1) {
                return validationMessage[0].text;
            }

            return validationMessage.find(localString => localString.culture === currentLang.value)?.text;
        }

        return "";
    };

    const getField = (field: CustomField, key: number) => {
        switch (field.type.toLowerCase()) {
            case 'string':
                return (
                    <RHFTextField
                        name={"customFields." + field.id}
                        key={field.id + key}
                        id={field.id}
                        onChangeVal={onChangeValidate}
                        onEditForm={onEditForm}
                        setOnEditForm={setOnEditForm}
                        onChange={handleCallBack}
                        label={field.name.find(x => x.culture === currentLang.value)?.text || field.name[0].text}
                        required={field.required}
                        helperText={getHelperText(field)}
                        defaultValue={getDefaultValue(field)}
                        disabled={checkDisabledField(field.id)}
                    />
                );
            case 'number':
                return (
                    <RHFTextField
                        name={"customFields." + field.id}
                        id={field.id}
                        onChangeVal={onChangeValidate}
                        key={field.id + key}
                        onEditForm={onEditForm}
                        setOnEditForm={setOnEditForm}
                        onChange={handleCallBack}
                        label={field.name.find(x => x.culture === currentLang.value)?.text || field.name[0].text}
                        required={field.required}
                        type={"number"}
                        helperText={getHelperText(field)}
                        defaultValue={getDefaultValue(field)}
                        disabled={checkDisabledField(field.id)}
                    />
                );
            case 'currency':
                return (
                    <RHFCurrencyTextField
                        name={"customFields." + field.id}
                        id={field.id}
                        key={field.id + key}
                        label={(field.name.find(x => x.culture === currentLang.value)?.text || field.name[0].text)}
                        currency={currentCurrency.label}
                        onChangeVal={onChangeValidate}
                        onEditForm={onEditForm}
                        setOnEditForm={setOnEditForm}
                        onChange={handleCallBack}
                        required={field.required}
                        helperText={getHelperText(field)}
                        defaultValue={getDefaultValue(field)}
                        disabled={checkDisabledField(field.id)}
                        isCustom={true}
                    />
                );
            case 'date':
                return (
                    <RHFDatePicker
                        name={"customFields." + field.id}
                        key={field.id + key}
                        id={field.id}
                        label={field.name.find(x => x.culture === currentLang.value)?.text || field.name[0].text}
                        onChangeVal={onChangeValidate}
                        onEditForm={onEditForm}
                        setOnEditForm={setOnEditForm}
                        required={field.required}
                        helperText={getHelperText(field)}
                        defaultValue={getDefaultValue(field) ? new Date(getDefaultValue(field)) : null}
                        disabled={checkDisabledField(field.id)}
                        onChangeDate={handleCallBack}
                    />
                );
            case 'checkbox':
                return (
                    <FormGroup key={key + ' form'}>
                        <FormControlLabel
                            name={"customFields." + field.id}
                            id={field.id}
                            key={field.id + key}
                            onBlur={() => {
                                if (setOnEditForm) {
                                    setOnEditForm(false);
                                }
                            }}
                            control={
                                <Checkbox
                                    required={field.required}
                                    checked={getDefaultValue(field) === "enabled"}
                                    onChange={(e) => handleCallBack(e)}
                                />}
                            label={field.name.find(x => x.culture === currentLang.value)?.text || field.name[0].text}
                            sx={{ ml: 0, '& .MuiFormControlLabel-asterisk': { color: 'error.main' } }}
                            disabled={checkDisabledField(field.id)}
                        />
                    </FormGroup>
                );
            case 'select':
                return (
                    <RHFSelect
                        native
                        onEditForm={onEditForm} setOnEditForm={setOnEditForm}
                        name={"customFields." + field.id}
                        id={field.id}
                        onChangeVal={onChangeValidate}
                        onChange={handleCallBack}
                        key={field.id + key}
                        label={field.name.find(x => x.culture === currentLang.value)?.text || field.name[0].text}
                        required={field.required}
                        defaultValue={getDefaultSelectValue(field)}
                        disabled={checkDisabledField(field.id)}
                    >
                        <option value='' hidden />
                        {field.selectOptions && field.selectOptions.map((option, index) => (
                            <option
                                key={index + '  ' + key}
                                hidden={!!option.values.find(l => l.text === '')}
                                value={option.values[0].text || ''}>
                                {option.values.find(x => x.culture === currentLang.value)?.text || option.values[0].text || ''}
                            </option>)
                        )}

                    </RHFSelect>);
        }
    };

    return (
        <Box
            sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: `repeat(3, 1fr)` },
                ...sx
            }}
        >
            {contextedCustomfields.map((f, ind) => (
                getField(f, ind)
            ))}
        </Box>
    );
}
