import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, Tooltip } from '@mui/material';
import useLocales from 'src/appHooks/useLocales';
import { useFormContext } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Iconify from 'src/components/iconify';
import { ToolbarSearchFilters } from 'src/@types/commons';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { DEFAULT_ORDERS_FILTERS, OrderFilters } from 'src/@types/orders';
import { isEqual, remove } from 'lodash';

type FilterProps = {
    filters: OrderFilters,
    onFilter: VoidFunction,
    resetAll: VoidFunction,
    optionsFields: ToolbarSearchFilters[],
    setShowSummary: (value: boolean) => void,
    showSummary: boolean
}

const DEFAULT_DATE_ERRORS = {
    fromFormat: false,
    fromMissing: false,
    toFormat: false,
    toMissing: false,
    toInvalid: false
};

export default function OrdersGeneralFilters({ filters, onFilter, resetAll, optionsFields, setShowSummary, showSummary }: FilterProps) {

    const { translate } = useLocales();

    const { setValue } = useFormContext();

    const [resetFilter, setResetFilter] = useState(false);

    const [openTooltip, setOpenTooltip] = useState(false);

    const [showDelete, setShowDelete] = useState(false);

    const [datesErrors, setDatesErrors] = useState(DEFAULT_DATE_ERRORS);

    const [filterValues, setFilterValues] = useState<OrderFilters>({ ...filters, serviceType: "itemExternalId", searchValue: "" });

    //---- IS DEFAULT - START ----//
    const fullKeysToRemove: string[] = useMemo(() => ["pageIndex", "pageSize", "sortField", "sortAscending"], []);

    const fullKeyRemover = useCallback((key: string) => {
        return !(fullKeysToRemove.includes(key));
    }, [fullKeysToRemove]);

    const isDefaultx = useCallback((filter: OrderFilters) => {
        const found = remove(Object.keys(filter), fullKeyRemover)
            .find((element) => (filter[element] && filter[element] !== DEFAULT_ORDERS_FILTERS[element]));

        return (!found);
    }, [fullKeyRemover]);
    //---- IS DEFAULT - END ----//

    const searchServiceAndValue = (filters: OrderFilters) => {

        if (filters.documentExternalId) return "documentExternalId";
        if (Object.keys(filters.itemCustomFields || {}).length > 0) return "itemCustomFields";

        return "itemExternalId";
    };

    useEffect(() => {

        if (!isDefaultx(filters) && !showSummary) {

            const serviceType = searchServiceAndValue(filters);

            let searchValue = (serviceType !== "itemCustomFields") ?
                filters[serviceType] :
                (filters.itemCustomFields ? filters.itemCustomFields["stellantis-europe-spareparts-deposit-number"] : "");

            setFilterValues({
                ...filters,
                serviceType: serviceType,
                searchValue: searchValue || "",
                from: filters.from || DEFAULT_ORDERS_FILTERS.from,
                to: filters.to || DEFAULT_ORDERS_FILTERS.to
            });

            if (!filters.from) {
                setDatesErrors(DEFAULT_DATE_ERRORS);
            }
            if (filters.from || filters[serviceType]) {
                setShowDelete(true);
            } else {
                setShowDelete(false);
            }
        } else {
            setFilterValues({
                ...filters,
                serviceType: "itemExternalId",
                searchValue: "",
                from: DEFAULT_ORDERS_FILTERS.from,
                to: DEFAULT_ORDERS_FILTERS.to
            });
            setDatesErrors(DEFAULT_DATE_ERRORS);
            setShowDelete(false);
        }

    }, [filters, isDefaultx]);

    useEffect(() => {
        if (!filterValues.from && !filterValues.to && !isEqual(DEFAULT_DATE_ERRORS, datesErrors)) {
            setDatesErrors(DEFAULT_DATE_ERRORS);
        }
    }, [filterValues, datesErrors]);

    useEffect(() => {
        if (resetFilter) {
            setFilterValues({
                ...DEFAULT_ORDERS_FILTERS,
                serviceType: "itemExternalId",
                searchValue: "",
                from: DEFAULT_ORDERS_FILTERS.from,
                to: DEFAULT_ORDERS_FILTERS.to
            });
            setResetFilter(false);
            resetAll();
        }
    }, [resetFilter, resetAll]);

    const handleChangeValues = (newVal: any, type: string) => {
        const valuesCopy: OrderFilters = JSON.parse(JSON.stringify(filterValues));

        if (type === "itemCustomFields") {
            let itemCustomFieldsList = { ...valuesCopy.itemCustomFieldsList };

            itemCustomFieldsList["stellantis-europe-spareparts-deposit-number"] = newVal;

            valuesCopy.itemCustomFields = itemCustomFieldsList;

        } else valuesCopy[type] = newVal;

        setFilterValues(valuesCopy);
    };

    const setAllValues = () => {
        for (const [key, value] of Object.entries(filterValues)) {

            if (key !== "serviceType" && key !== "searchValue") {

                if (key === "from" || key === "to") {
                    setValue(key, value);
                } else if (filterValues.serviceType === key) {
                    if (key === "itemCustomFields") {
                        setValue(key, { "stellantis-europe-spareparts-deposit-number": filterValues.searchValue });
                    } else setValue(key, filterValues.searchValue);
                } else {
                    setValue(key, DEFAULT_ORDERS_FILTERS[key]);
                }
            }
        }
    };

    const handleSearch = () => {

        if (filterValues.from && !filterValues.to) {
            setDatesErrors({ ...datesErrors, toMissing: true });
        } else if (!filterValues.from && filterValues.to) {
            setDatesErrors({ ...datesErrors, fromMissing: true });
        } else if (filterValues.from || filterValues.searchValue) {
            setAllValues();
            onFilter();
            setShowDelete(true);
            setShowSummary(false);
        }
    };

    const handleDelete = () => {
        setResetFilter(true);
        setDatesErrors(DEFAULT_DATE_ERRORS);
        setShowDelete(false);
    };

    const handleChangeFrom = (date: dayjs.Dayjs) => {

        if (date.toString() !== 'Invalid Date') {

            handleChangeValues(date.format('YYYY-MM-DD'), "from");

            let toInvalidCheck = false;

            if (filterValues.to && dayjs(filterValues.to).diff(date, "d") < 0) {
                toInvalidCheck = true;
            }

            setDatesErrors({ ...datesErrors, fromFormat: false, fromMissing: false, toInvalid: toInvalidCheck });
        }
        else {
            setDatesErrors({ ...datesErrors, fromFormat: true });
        }
    };

    const handleChangeTo = (date: dayjs.Dayjs) => {

        if (date.toString() !== 'Invalid Date') {

            if (date.diff(filterValues.from, "d") < 0) {
                setDatesErrors({ ...datesErrors, toInvalid: true, toFormat: false, toMissing: false });
            } else {
                handleChangeValues(date.format('YYYY-MM-DD'), "to");
                setDatesErrors({ ...datesErrors, toInvalid: false, toFormat: false, toMissing: false });
            }
        }
        else {
            setDatesErrors({ ...datesErrors, toFormat: true });
        }
    };

    const handleDeleteDate = (type: string) => {

        handleChangeValues("", type);

        if (type === "from") {
            setDatesErrors({ ...datesErrors, fromFormat: false, fromMissing: false, toInvalid: false });
        } else {
            setDatesErrors({ ...datesErrors, toFormat: false, toMissing: false, toInvalid: false });
        }
    };

    const errorChecker = () => {
        if (datesErrors.fromFormat) return true;
        if (datesErrors.fromMissing) return true;
        if (datesErrors.toFormat) return true;
        if (datesErrors.toMissing) return true;
        if (datesErrors.toInvalid) return true;

        return false;
    };

    const getDateErrorMessage = (type: string) => {

        if (datesErrors.fromFormat && type === "from") return `${translate("commons.validation.wrongDate")}`;
        if (datesErrors.toFormat && type === "to") return `${translate("commons.validation.wrongDate")}`;
        if (datesErrors.fromMissing && type === "from") return `${translate("commons.validation.missingDate")}`;
        if (datesErrors.toMissing && type === "to") return `${translate("commons.validation.missingDate")}`;
        if (datesErrors.toInvalid && type === "to") return `${translate("commons.validation.invalidDate")}`;

        return "";
    };

    return (
        <Stack
            direction={{ xs: 'column', sm: 'column', md: 'row' }}
            sx={{ px: 3, pt: 2.5, pb: { xs: 0, md: 2.5 } }}
        >
            <FormControl
                sx={{
                    minWidth: { xs: "100%", md: 200 },
                    mr: 2,
                    mb: { xs: 2, md: 0 }
                }}
                variant="outlined"
            >
                <InputLabel
                    id="label"
                    sx={{ '&.Mui-focused': { color: 'grey.500' } }}
                >
                    {`${translate('returns.searchType')}`}
                </InputLabel>
                <Select
                    labelId="label"
                    id="serviceType"
                    value={filterValues.serviceType}
                    input={
                        <OutlinedInput label={`${translate('returns.searchType')}`} />
                    }
                    onChange={(event) => handleChangeValues(event.target.value, "serviceType")}
                >
                    {optionsFields.map((option) => (
                        <MenuItem
                            key={option.key}
                            value={option.key}
                            sx={{
                                mx: 1,
                                my: 0.5,
                                borderRadius: 0.75,
                                typography: 'body2',
                                textTransform: 'capitalize',
                            }}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Box
                sx={{
                    minWidth: { xs: "100%", md: 410 },
                    mr: 2,
                    mb: { xs: 2, md: 0 }
                }}
            >
                <Stack
                    spacing={2}
                    direction={{ xs: 'column', sm: 'row' }}
                >
                    <DatePicker
                        label={`${translate('commons.from')}`}
                        views={['year', 'month', 'day']}
                        format={'dd/MM/yyyy'}
                        value={filterValues.from ? dayjs(filterValues.from).toDate() : null}

                        onAccept={(newValue) => {
                            if (newValue)
                                handleChangeValues(dayjs(newValue).format('YYYY-MM-DD'), "from");
                        }}

                        onChange={(fromDateValue, inputval) => {

                            if (!inputval.validationError && fromDateValue) {

                                const date = dayjs(fromDateValue);

                                handleChangeFrom(date);
                            } else {
                                setDatesErrors({ ...datesErrors, fromFormat: true });
                            }
                        }}

                        slotProps={{
                            textField: {
                                name: 'from',
                                fullWidth: true,
                                error: datesErrors.fromFormat || datesErrors.fromMissing,
                                helperText: getDateErrorMessage("from"),
                                InputProps: {
                                    startAdornment: (filterValues.from &&
                                        <InputAdornment position="end">
                                            <IconButton
                                                sx={{ ml: -2, mr: 1 }}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleDeleteDate("from");
                                                }}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }
                            }
                        }}
                    />

                    <DatePicker
                        label={`${translate('commons.to')}`}
                        views={['year', 'month', 'day']}
                        format={'dd/MM/yyyy'}
                        value={filterValues.to ? dayjs(filterValues.to).toDate() : null}

                        onAccept={(newValue) => {
                            if (newValue)
                                handleChangeValues(dayjs(newValue).format('YYYY-MM-DD'), "to");
                        }}

                        onChange={(fromDateValue, inputval) => {
                            if (!inputval.validationError && fromDateValue) {

                                const date = dayjs(fromDateValue);

                                handleChangeTo(date);
                            } else {
                                setDatesErrors({ ...datesErrors, toFormat: true });
                            }
                        }}

                        slotProps={{
                            textField: {
                                name: 'to',
                                fullWidth: true,
                                error: datesErrors.toFormat || datesErrors.toMissing || datesErrors.toInvalid,
                                helperText: getDateErrorMessage("to"),
                                InputProps: {
                                    startAdornment: (filterValues.to &&
                                        <InputAdornment position="end">
                                            <IconButton
                                                sx={{ ml: -2, mr: 1 }}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleDeleteDate("to");
                                                }}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }
                            }
                        }}
                    />

                </Stack>
            </Box>

            <TextField
                type="search"
                value={filterValues.searchValue}
                onChange={(event) => handleChangeValues(event.target.value, "searchValue")}
                placeholder={`${translate('commons.search')}`}
                sx={{ width: "100%", mr: 2 }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify
                                icon={'eva:search-fill'}
                                sx={{ color: 'text.disabled', width: 20, height: 20 }}
                            />
                        </InputAdornment>
                    ),
                }}
            />

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Tooltip
                    title={`${translate("commons.validation.invalidData")}`}
                    disableFocusListener
                    disableTouchListener
                    disableInteractive
                    open={openTooltip}
                    sx={{
                        mb: () => errorChecker() ? 4 : 0,
                        width: { xs: "100%" }
                    }}
                >
                    <Box
                        onMouseEnter={() => { if (errorChecker()) setOpenTooltip(true); }}
                        onMouseLeave={() => { if (errorChecker()) setOpenTooltip(false); }}
                    >
                        <Button
                            startIcon={
                                <Iconify
                                    icon={'bx:search-alt'}
                                    sx={{
                                        ml: 1.5,
                                        mt: 0.5
                                    }}
                                />
                            }
                            onClick={handleSearch}
                            disabled={errorChecker()}
                            size="large"
                            sx={{
                                mt: 0.5,
                                width: { xs: "100%" }
                            }}
                        />
                    </Box>
                </Tooltip>

                <Button
                    startIcon={<DeleteIcon sx={{ ml: 1.5, mt: 0.75 }} />}
                    onClick={handleDelete}
                    sx={{
                        display: `${(showDelete) ? "inline" : "none"}`,
                        mt: 0.5,
                        width: { xs: "100%" }
                    }}
                    size="large"
                />
            </Box>

        </Stack>
    );
}