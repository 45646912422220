import { useFormContext } from 'react-hook-form';
import { Box, Stack, Badge, Button, Drawer, Divider, IconButton, Typography, MenuItem, TextField, ButtonGroup, Slider } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import useLocales from 'src/appHooks/useLocales';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Currency, NAV } from 'src/config';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { RHFSelect } from 'src/components/hook-form';
import { DEFAULT_DOCUMENTS_FILTERS, DocTypesArr, DocumentFilters, MIN_PRICE_DOC_FILTER, STEP_PRICE_DOC_FILTER } from 'src/@types/orders';
import { useSettingsContext } from 'src/components/settings';
import useCurrency from 'src/appHooks/useCurrency';
import { CurrencyTypes } from 'src/@types/vehicle';
import { convert } from 'src/utils/currency';
import AutocompleteField from 'src/appComponents/AutocompleteField';
import CustomFieldSidebarRenderer from 'src/utils/CustomFieldSidebarRenderer';
import { CustomerAutocomplete } from '../../orders/filters/OrdersFilterSidebar';

type Props = {
    filters: DocumentFilters,
    isDefault: (filter: DocumentFilters, controller?: DocumentFilters) => boolean,
    isOpen: boolean,
    onOpen: VoidFunction,
    onFilter: VoidFunction,
    onResetAll: VoidFunction,
    onClose: VoidFunction,
    reset: boolean,
    resetFormElement: string,
    setResetFormElement: (value: string) => void,
    MAX_PRICE_CONVERTED: number,
    isVendor: boolean
};

export default function DocumentsFilterSidebar({
    isDefault,
    isOpen,
    onResetAll,
    onFilter,
    onOpen,
    onClose,
    reset,
    resetFormElement,
    filters,
    setResetFormElement,
    MAX_PRICE_CONVERTED,
    isVendor
}: Props) {

    const { translate } = useLocales();

    const { rates } = useCurrency();

    const { setValue, getValues } = useFormContext();

    const { currency: currentCurrency } = useSettingsContext();

    const [values, setValues] = useState(filters);

    const [valuesAfterFilter, setValuesAfterFilter] = useState(filters);

    const [inEditTags, setInEditTags] = useState<boolean>(false);

    const [resetSearchCustomer, setResetSearchCustomer] = useState<boolean>(false);

    const STEP_PRICE_CONVERTED = useMemo(() => Math.round(convert(STEP_PRICE_DOC_FILTER, CurrencyTypes.EUR, currentCurrency.label, rates)), [currentCurrency, rates]);

    useEffect(() => {
        if (reset) {

            setValues({
                ...DEFAULT_DOCUMENTS_FILTERS,
                maxAmount: MAX_PRICE_CONVERTED
            });

            setResetSearchCustomer(true);
        }
    }, [reset, MAX_PRICE_CONVERTED]);

    const handleChangeValues = useCallback((values: any, newVal: any, type: string) => {
        const valuesCopy = JSON.parse(JSON.stringify(values));

        if (type === "amount") {
            valuesCopy.minAmount = newVal[0];
            valuesCopy.maxAmount = newVal[1];
        } else if (type === "customer") {
            valuesCopy.customerId = newVal.id;
        } else {
            valuesCopy[type] = newVal;
        }

        setValues(valuesCopy);
    }, []);

    const handleChangeCustomer = (newVal: any, type: string) => {
        const valuesCopy = JSON.parse(JSON.stringify(values));

        valuesCopy.customerId = newVal.id;

        valuesCopy.customer = newVal;

        setValues(valuesCopy);
    };

    const handleChangeTagsValues = (newVal: any, type: string) => {
        const valuesCopy = JSON.parse(JSON.stringify(values));

        valuesCopy[type] = newVal;

        setValues(valuesCopy);
    };

    useEffect(() => {
        if (resetFormElement) {
            handleChangeValues(values, DEFAULT_DOCUMENTS_FILTERS[resetFormElement], resetFormElement);
            if (resetFormElement === "customerId") setResetSearchCustomer(true);
            setResetFormElement("");
        }
    }, [resetFormElement, handleChangeValues, values, setResetFormElement]);

    useEffect(() => {
        if (JSON.stringify(filters) !== JSON.stringify(values)) {
            setValues(filters);
            setValuesAfterFilter(filters);
        }
    }, [filters]);

    const handleCloseFilter = () => {
        if (reset) {
            setValues({
                ...DEFAULT_DOCUMENTS_FILTERS,
                maxAmount: MAX_PRICE_CONVERTED
            });
        } else if (JSON.stringify(values) !== JSON.stringify(valuesAfterFilter)) {
            setValues(valuesAfterFilter);
        }
        onClose();
    };

    const handleOnResetAll = () => {
        setValues({
            ...DEFAULT_DOCUMENTS_FILTERS,
            maxAmount: MAX_PRICE_CONVERTED
        });
        onResetAll();
    };

    const setAllValues = () => {
        for (const [key, value] of Object.entries(values)) {
            if (key === "all") {
                setValue(key, DEFAULT_DOCUMENTS_FILTERS[key]);
            } else {
                const formValue = getValues(key);

                if (JSON.stringify(value) !== JSON.stringify(formValue)) {
                    if (key !== "tags") {
                        setValue(key, value);
                    }
                }
            }
        }
        setValuesAfterFilter(values);
    };

    const handleOnFilter = () => {
        setAllValues();
        onFilter();
    };

    const dotController = useMemo(() => {
        if (valuesAfterFilter) {
            return valuesAfterFilter;
        }

        return ({ ...DEFAULT_DOCUMENTS_FILTERS, maxAmount: MAX_PRICE_CONVERTED });
    }, [valuesAfterFilter, MAX_PRICE_CONVERTED]);

    const marksLabelPrice = [...Array(61)].map((_, index) => {
        const value = index * STEP_PRICE_CONVERTED;

        var firstValue = "";
        if (value / 1000 > 1) {
            firstValue = `${Math.round(value / 1000)}kk`;
        } else {
            firstValue = index === 0 ? `${currentCurrency.symbol} ${value}k` : `${value}k`;
        }

        return {
            value,
            label: index % 8 ? '' : firstValue,
        };
    });

    return (
        <>
            <Button variant="outlined" size='small' onClick={onOpen}>
                <FilterListIcon />
            </Button>
            <Drawer
                anchor="right"
                open={isOpen}
                onClose={handleCloseFilter}
                PaperProps={{
                    sx: { width: NAV.W_DASHBOARD },
                    style: { boxShadow: 'none' }
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ px: 1, py: 2 }}
                >
                    <Typography variant="subtitle1" sx={{ ml: 1 }}>
                        {`${translate("commons.filters")}`}
                    </Typography>

                    <IconButton onClick={handleCloseFilter}>
                        <Iconify icon={'eva:close-fill'} width={20} height={20} />
                    </IconButton>
                </Stack>

                <Divider />

                <Scrollbar>
                    <Stack spacing={3} sx={{ p: 2.5 }}>

                        <Stack spacing={1}>
                            <TextField
                                name='externalId'
                                label={`${translate("documents.filter.docRef")}`}
                                variant="outlined"
                                value={values.externalId}
                                onChange={(e) => handleChangeValues(values, e.target.value, "externalId")}
                            />
                        </Stack>

                        <Stack spacing={1}>
                            <RHFSelect
                                name="documentType"
                                label={`${translate("documents.filter.type")}`}
                                value={values.documentType}
                                onChange={(e) => handleChangeValues(values, e.target.value, "documentType")}
                            >
                                {DocTypesArr.map((element: string) => (
                                    <MenuItem key={element || "All"} value={element || "All"}>
                                        {`${translate(element ? `documents.filter.${element}` : `commons.all`)}`}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                        </Stack>

                        <Stack spacing={1}>
                            <TextField
                                name='parcel'
                                label={`${translate("documents.filter.parcel")}`}
                                variant="outlined"
                                value={values.parcel}
                                onChange={(e) => {
                                    handleChangeValues(values, e.target.value, "parcel");
                                }}
                            />
                        </Stack>

                        <Stack spacing={1}>
                            <TextField
                                name='orderRef'
                                label={`${translate("documents.filter.orderRef")}`}
                                variant="outlined"
                                value={values.orderRef}
                                onChange={(e) => {
                                    handleChangeValues(values, e.target.value, "orderRef");
                                }}
                            />
                        </Stack>

                        <Stack spacing={1} sx={{ pb: 2 }}>
                            <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                                {`${translate('documents.filter.amount')}`}
                            </Typography>

                            <Stack direction="row" spacing={2}>
                                <InputRange currentCurrency={currentCurrency} label={`${translate('commons.min')}`} value={values.minAmount!} />
                                <InputRange currentCurrency={currentCurrency} label={`${translate('commons.max')}`} value={values.maxAmount!} />
                            </Stack>

                            <Slider
                                name="amount"
                                step={STEP_PRICE_CONVERTED}
                                min={MIN_PRICE_DOC_FILTER}
                                max={MAX_PRICE_CONVERTED}
                                marks={marksLabelPrice}
                                sx={{ alignSelf: 'center', width: `calc(100% - 20px)` }}
                                valueLabelDisplay="auto"

                                getAriaValueText={(value: any) => `${value}k ${currentCurrency.symbol}`}
                                valueLabelFormat={(value: any) => `${value}k ${currentCurrency.symbol}`}

                                value={[values.minAmount!, values.maxAmount!]}

                                onChange={(event: Event, newValue: number | number[], activeThumb: number) => {
                                    if (!Array.isArray(newValue)) {
                                        return;
                                    }

                                    const { minAmount, maxAmount } = values;

                                    let min = minAmount!;
                                    let max = maxAmount!;

                                    if (activeThumb === 0) {
                                        min = Math.min(newValue[0], maxAmount! - MIN_PRICE_DOC_FILTER);
                                    } else {
                                        max = Math.max(newValue[1], minAmount! + MIN_PRICE_DOC_FILTER);
                                    }
                                    if (max - min >= STEP_PRICE_DOC_FILTER) {
                                        handleChangeValues(values, [min, max], "amount");
                                    }
                                }}
                            />
                        </Stack>

                        {isVendor &&
                            <>
                                <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                                    {`${translate('commons.customer')}`}
                                </Typography>

                                <Stack spacing={1}>
                                    <CustomerAutocomplete
                                        onChange={handleChangeCustomer}
                                        setResetSearchCustomer={setResetSearchCustomer}
                                        resetSearchCustomer={resetSearchCustomer}
                                        defaultCustomer={values.customer}
                                    />
                                </Stack>
                            </>
                        }

                        <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                            {`${translate("commons.tags")}`}
                        </Typography>

                        <Stack spacing={1}>
                            <AutocompleteField
                                name={'tags'}
                                context={'userFilterTag'}
                                label={`${translate("commons.tags")}`}
                                freeSolo
                                multiple
                                tagsFromForm={values.tags?.length ? values.tags : getValues("tags")}
                                handleChangeValues={handleChangeTagsValues}
                                inEditTags={inEditTags}
                                setInEditTags={setInEditTags}
                            />
                        </Stack>

                        <CustomFieldSidebarRenderer
                            context={["CreditNote", "DeliveryNote", "Invoice"]}
                            filterValues={values}
                            setFilterValues={setValues}
                        />

                    </Stack>

                </Scrollbar>

                <Box sx={{ p: 1, pb: 2, position: 'relative' }}>

                    <Badge
                        color="error"
                        variant="dot"
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        invisible={isDefault(values, dotController as DocumentFilters)}
                        sx={{ width: 1, right: 35, top: 20, position: 'absolute' }}
                    />

                    <ButtonGroup variant="outlined" aria-label="outlined button group" fullWidth>

                        <Button
                            fullWidth
                            size="large"
                            type="submit"
                            color="inherit"
                            variant="outlined"
                            onClick={handleOnResetAll}
                            startIcon={<Iconify icon="ic:round-clear-all" />}
                            sx={{ px: 1 }}
                        >
                            {`${translate("commons.clear")}`}
                        </Button>
                        <Button
                            fullWidth
                            size="large"
                            type="submit"
                            color="inherit"
                            variant="outlined"
                            onClick={handleOnFilter}
                            disabled={inEditTags}
                            startIcon={<Iconify icon="eva:search-fill" />}
                            sx={{ px: 1 }}
                        >
                            {`${translate("commons.apply")}`}
                        </Button>

                    </ButtonGroup>
                </Box>

            </Drawer >
        </>
    );
}

// ----------------------------------------------------------------------

type InputRangeProps = {
    label: string;
    value: number;
    currentCurrency: Currency;
};

function InputRange({ label, value, currentCurrency }: InputRangeProps) {

    return (
        <Stack direction="row" spacing={0.5} sx={{ width: 1 }}>

            <Typography
                variant="subtitle2"
                sx={{ color: 'text.secondary', whiteSpace: 'nowrap' }}
            >
                {label + " (" + currentCurrency.symbol + ")"}
            </Typography>

            <Typography variant="body2" sx={{ textAlign: "right", pl: 2 }}>
                {value + "k"}
            </Typography>

        </Stack>
    );
}