import { createSlice } from "@reduxjs/toolkit";
import { Rule } from "src/@types/rules";
import rulesOperations from "./rules-operations";
import { ErrorResponse, PagedResponseType } from "src/@types/commons";

interface RulesReducer {
    error: ErrorResponse,
    isLoading: boolean,
    rule: Rule | null,
    logs: PagedResponseType<any>
}

const initialState: RulesReducer = {
    error: null,
    isLoading: false,
    rule: null,
    logs: {
        items: [],
        pageIndex: 0,
        pageSize: 0,
        totalCount: 0
    }
};

const RulesSlice = createSlice({
    name: 'rule',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(rulesOperations.getRule.fulfilled, (state, action) => {
                state.rule = action.payload;
                state.isLoading = false;
            })
            .addCase(rulesOperations.getRule.rejected, (state, action) => {
                state.rule = initialState.rule;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(rulesOperations.getLogs.fulfilled, (state, action) => {
                state.logs = action.payload;
                state.isLoading = false;
            })
            .addCase(rulesOperations.getLogs.rejected, (state, action) => {
                state.logs = initialState.logs;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(rulesOperations.updateRuleCategory.fulfilled, (state, action) => {
                state.rule = action.payload;
                state.isLoading = false;
            })
            .addCase(rulesOperations.updateRuleCategory.rejected, (state, action) => {
                state.rule = initialState.rule;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(rulesOperations.updateRuleInvoice.fulfilled, (state, action) => {
                state.rule = action.payload;
                state.isLoading = false;
            })
            .addCase(rulesOperations.updateRuleInvoice.rejected, (state, action) => {
                state.rule = initialState.rule;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(rulesOperations.updateRuleFrequency.fulfilled, (state, action) => {
                state.rule = action.payload;
                state.isLoading = false;
            })
            .addCase(rulesOperations.updateRuleFrequency.rejected, (state, action) => {
                state.rule = initialState.rule;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(rulesOperations.updateRuleParts.fulfilled, (state, action) => {
                state.rule = action.payload;
                state.isLoading = false;
            })
            .addCase(rulesOperations.updateRuleParts.rejected, (state, action) => {
                state.rule = initialState.rule;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(rulesOperations.updateRuleSurveillance.fulfilled, (state, action) => {
                state.rule = action.payload;
                state.isLoading = false;
            })
            .addCase(rulesOperations.updateRuleSurveillance.rejected, (state, action) => {
                state.rule = initialState.rule;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(rulesOperations.updateRuleMinimunAmount.fulfilled, (state, action) => {
                state.rule = action.payload;
                state.isLoading = false;
            })
            .addCase(rulesOperations.updateRuleMinimunAmount.rejected, (state, action) => {
                state.rule = initialState.rule;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(rulesOperations.updateRulePeriod.fulfilled, (state, action) => {
                state.rule = action.payload;
                state.isLoading = false;
            })
            .addCase(rulesOperations.updateRulePeriod.rejected, (state, action) => {
                state.rule = initialState.rule;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            ;
    },
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
    }
});

export const {
    startLoading
} = RulesSlice.actions;

export default RulesSlice.reducer;