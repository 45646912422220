import { createAsyncThunk } from "@reduxjs/toolkit";
import { startLoading, startLogsLoading, enableDisableTermConditionSL, defaultTermConditionSL } from "./termCondition-slices";
import { DefaultTermConditionProps, EnableDisableTermConditionProps, NewTermCondition, TermConditionFilters, TermsConditionEntityAvailable } from "src/@types/termCondition";
import * as termConditionService from '../../services/termConditionService';
import { LogsFilters } from "src/@types/logs";

const getDefaultTermCondition = createAsyncThunk(
    'group/getDefaultTermConditions',
    async (entityType: TermsConditionEntityAvailable, { dispatch, rejectWithValue }) => {
        dispatch(startLoading());
        try {
            const { data } = await termConditionService.getDefault(entityType);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const getTermCondition = createAsyncThunk(
    'termCondition/getTermConditions',
    async (id: string, { dispatch, rejectWithValue }) => {
        dispatch(startLoading());
        try {
            const { data } = await termConditionService.detail(id);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const updateTermCondition = createAsyncThunk(
    'termCondition/updateTermConditions',
    async (params: { id: string, newTermCondition: NewTermCondition }, { dispatch, rejectWithValue }) => {
        try {
            const { id, newTermCondition } = params;

            const { data, status } = await termConditionService.update(id, newTermCondition);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const createTermCondition = createAsyncThunk(
    'termCondition/createTermConditions',
    async (newTermCondition: NewTermCondition, { dispatch, rejectWithValue }) => {
        try {
            const { data, status } = await termConditionService.create(newTermCondition);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const enableDisableTermCondition = createAsyncThunk(
    'termCondition/enableDisableTermConditions',
    async (options: EnableDisableTermConditionProps, { dispatch, rejectWithValue }) => {

        try {

            const { data, status } = options.action === 'enable' ?
                await termConditionService.enable(options.id) :
                await termConditionService.disable(options.id);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            dispatch(enableDisableTermConditionSL(options));

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const defaultTermCondition = createAsyncThunk(
    'termCondition/defaultTermCondition',
    async (options: DefaultTermConditionProps, { dispatch, rejectWithValue }) => {

        try {

            const { data, status } = await termConditionService.setDefault(options.id, options.entityType);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            dispatch(defaultTermConditionSL(options));

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const getTermConditionLogs = createAsyncThunk(
    'termCondition/getTermConditionsLogs',
    async (params: { id: string, filters: LogsFilters }, { rejectWithValue, dispatch }) => {
        dispatch(startLogsLoading());
        try {
            const { id, filters } = params;

            const { data } = await termConditionService.searchLogs(id, filters);

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const searchTermConditions = createAsyncThunk(
    'termCondition/searchTermConditions',
    async (filters: TermConditionFilters, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await termConditionService.search(filters);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const termConditionOperations = {
    getDefaultTermCondition,
    getTermCondition,
    updateTermCondition,
    createTermCondition,
    enableDisableTermCondition,
    getTermConditionLogs,
    searchTermConditions,
    defaultTermCondition
};

export default termConditionOperations;