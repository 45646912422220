import { Card, Box, Button } from '@mui/material';
import { RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import useLocales from 'src/appHooks/useLocales';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import { DataGridStyle } from 'src/utils/DataGridStyle';
import EmptyContent, { noData } from 'src/components/empty-content/EmptyContent';
import useResponsive from 'src/hooks/useResponsive';
import { useNavigate, useParams } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useUserOrganizationContext } from 'src/contexts/UserOrganizationContext';
import { hasPermissions } from 'src/utils/user';
import { FeesInDays, RuleTabs } from 'src/@types/rules';
import { OrganizationPermissionTypes } from 'src/@types/permissions';
import AddIcon from '@mui/icons-material/Add';
import Label from 'src/components/label';
import { useMemo } from 'react';

export default function InvoiceTab() {

    const { translate } = useLocales();

    const navigate = useNavigate();

    const { rulesTab } = useParams();

    const isDesktop = useResponsive('up', 'md');

    const { organization } = useUserOrganizationContext();

    const { isLoading, rule } = useSelector((state: RootState) => state.rules);

    const isEnabled = useMemo(() => rule?.invoice?.enabled ?? false, [rule]);

    //---- HANDLE TABLE START ----//
    const handleRowClick: GridEventListener<'rowClick'> = (params) => {
        if (hasPermissions([OrganizationPermissionTypes.WebShop_ReturnRequests_Rules_VendorEdit], organization.roles!)) {
            navigate(PATH_DASHBOARD.rules.editSoft(rulesTab as RuleTabs));
        } else return null;
    };

    const columns: GridColDef<FeesInDays>[] = [
        {
            field: 'level',
            headerName: `${translate('rules.list.tableHeaders.level')}`,
            flex: isDesktop ? 0.5 : undefined,
            maxWidth: !isDesktop ? 90 : undefined,
            headerAlign: 'left',
            sortable: false,
            renderCell: (obj: any) => {
                return (<strong>{obj.row.level}</strong>);
            }
        },
        {
            field: 'type',
            headerName: `${translate('rules.list.tableHeaders.type')}`,
            flex: isDesktop ? 0.5 : undefined,
            maxWidth: !isDesktop ? 90 : undefined,
            sortable: false,
            valueGetter: () => {
                return `${translate('rules.list.softText')}`;
            }
        },
        {
            field: 'category',
            headerName: `${translate('rules.list.tableHeaders.category')}`,
            flex: isDesktop ? 0.7 : undefined,
            minWidth: !isDesktop ? 100 : undefined,
            sortable: false,
            valueGetter: () => {
                return `${translate('rules.list.tabs.invoice')}`;
            }
        },
        {
            field: 'fromInDays',
            headerName: `${translate('rules.list.tableHeaders.older')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 160 : undefined,
            headerAlign: 'left',
            align: 'left',
            sortable: false,
            valueGetter: (value: string) => {
                return `>${value} ${translate('rules.list.tableContent.invoice.workingDays')}`;
            }
        },
        {
            field: 'toInDays',
            headerName: `${translate('rules.list.tableHeaders.until')}`,
            flex: isDesktop ? 1.25 : undefined,
            minWidth: !isDesktop ? 160 : undefined,
            headerAlign: 'left',
            align: 'left',
            sortable: false,
            valueGetter: (value: string) => {
                return `<= ${value} ${translate('rules.list.tableContent.invoice.workingDays')}`;
            }
        },
        {
            field: 'fee',
            headerName: `${translate('rules.list.tableHeaders.fee')}`,
            flex: isDesktop ? 1.25 : undefined,
            minWidth: !isDesktop ? 185 : undefined,
            headerAlign: 'left',
            align: 'left',
            sortable: false,
            valueGetter: (value: string) => {
                return `-${value}% ${translate('rules.list.tableContent.frequency.feeText')}`;
            }
        },

        {
            field: 'enabledLabel',
            headerAlign: 'left',
            flex: isDesktop ? 0.65 : undefined,
            minWidth: !isDesktop ? 80 : undefined,
            sortable: false,
            renderHeader: () => {
                return (
                    <Label
                        color={isEnabled ? 'success' : 'error'}
                        sx={{ textTransform: 'uppercase' }}
                    >
                        {isEnabled ? `${translate("commons.enabled")}` : `${translate("commons.disabled")}`}
                    </Label>
                );
            }
        }
    ];
    //---- HANDLE TABLE END ----//

    return (
        <Card>

            <Box>
                {!rule || rule?.invoice.feesInDays.length === 0 ?
                    (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                pb: 3, pt: 1
                            }}
                        >
                            <EmptyContent title={`${translate('rules.list.tableContent.invoice.noRules')}`} />
                            <Button
                                variant="text"
                                sx={{ color: (theme) => theme.palette.primary.main }}
                                startIcon={<AddIcon />}
                                onClick={() => navigate(PATH_DASHBOARD.rules.editSoft(rulesTab as RuleTabs))}
                            >
                                {`${translate('rules.list.tableContent.invoice.addNew')}`}
                            </Button>
                        </Box>
                    ) : (
                        <DataGrid
                            rows={rule?.invoice.feesInDays as FeesInDays[]}
                            columns={columns}
                            loading={isLoading}
                            slots={{ noRowsOverlay: noData }}
                            disableColumnMenu
                            disableRowSelectionOnClick
                            onCellClick={(param, e, d) => {
                                if (!window.getSelection()?.toString())
                                    handleRowClick(param as any, e, d);
                            }}
                            hideFooter
                            sx={{
                                ...DataGridStyle,
                                cursor: hasPermissions([OrganizationPermissionTypes.WebShop_ReturnRequests_Rules_VendorEdit], organization.roles) ? 'pointer' : 'default',
                                height: 56.5 + (rule?.invoice.feesInDays.length * 52),
                                maxHeight: 56.5 + (rule?.invoice.feesInDays.length * 52)
                            }}
                        />
                    )}
            </Box>

        </Card>
    );
}