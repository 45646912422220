import { useEffect } from "react";
import Page from "src/appComponents/Page";
import { useLocales } from "src/locales";
import { useParams } from "react-router";
import { dispatch, RootState } from "src/redux/store";
import { useSelector } from "react-redux";
import LoadingScreen from "src/appComponents/loading-screen/LoadingScreen";
import { groupOperations } from "src/redux/group";
import GroupWizard from "../../../sections/@dashboard/group/newEditGroup/GroupWizard";
import { resetGroup } from "src/redux/group/group-slices";

export default function NewEditGroup() {

    const { id } = useParams();

    const { translate } = useLocales();

    const { isLoading, group } = useSelector((state: RootState) => state.group);

    useEffect(() => {
        if (id) {
            dispatch(groupOperations.getGroup(id)).unwrap();
        }

        return () => { dispatch(resetGroup()); };
    }, [id]);

    return (
        <Page title={id ? `${translate('groups.form.edit')}` : `${translate('menu.management.groups.new')}`}>
            {isLoading ?
                <LoadingScreen /> :
                <GroupWizard key={id} group={id ? (group ?? undefined) : undefined} />
            }
        </Page>
    );

}