import { Box, Button, InputAdornment, Stack, TextField } from '@mui/material';
import useLocales from 'src/appHooks/useLocales';
import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import Iconify from 'src/components/iconify';
import DeleteIcon from '@mui/icons-material/Delete';
import { DEFAULT_SPAREPARTS_FILTERS, SparePartsFilters } from 'src/@types/spareParts';

type SparePartsFilterToolbarProps = {
    filters: SparePartsFilters,
    onFilter: VoidFunction,
    resetAll: VoidFunction,
    setShowSummary: (value: boolean) => void,
    showSummary: boolean
}

export default function SparePartsFilterToolbar({ filters, onFilter, resetAll, setShowSummary, showSummary }: SparePartsFilterToolbarProps) {

    const { translate } = useLocales();

    const { setValue } = useFormContext();

    const [resetFilter, setResetFilter] = useState(false);

    const [showDelete, setShowDelete] = useState(false);

    const [filterValues, setFilterValues] = useState<SparePartsFilters>({ ...filters, serviceType: "all", searchValue: "" });

    const [strFilterCheck, setStrFilterCheck] = useState(JSON.stringify(filters));

    useEffect(() => {

        const strFilter = JSON.stringify(filters);

        if (!showSummary) {

            if (strFilter !== strFilterCheck) {

                setStrFilterCheck(strFilter);

                setFilterValues({
                    ...filters,
                    serviceType: "all",
                    searchValue: filters.all || ""
                });

                if (filters.all) {
                    setShowDelete(true);
                }
            }
        } else {
            setFilterValues({
                ...filters,
                serviceType: "all",
                searchValue: filters.all || ""
            });
            setShowDelete(!!filters.all);
        }

    }, [filters]);

    useEffect(() => {
        if (resetFilter) {
            setFilterValues({ ...DEFAULT_SPAREPARTS_FILTERS, serviceType: "all", searchValue: "" });
            setResetFilter(false);
            resetAll();
        }
    }, [resetFilter, resetAll]);

    const handleChangeValues = (newVal: any, type: string) => {
        const valuesCopy = JSON.parse(JSON.stringify(filterValues));

        valuesCopy[type] = newVal;

        setFilterValues(valuesCopy);
    };

    const setAllValues = () => {
        for (const [key, value] of Object.entries(filterValues)) {
            if (key === "serviceType") {
                setValue(value!.toString(), filterValues.searchValue);
            } else if (key !== "searchValue") {
                setValue(key, DEFAULT_SPAREPARTS_FILTERS[key]);
            }
        }
    };

    const handleSearch = () => {
        if (filterValues.searchValue) {
            setAllValues();
            onFilter();
            setShowDelete(true);
            setShowSummary(false);
        }
    };

    const handleDelete = () => {
        setResetFilter(true);
        setShowDelete(false);
    };

    return (
        <Stack
            direction={{ xs: 'column', sm: 'column', md: 'row' }}
            sx={{
                px: 3, pt: 2.5,
                pb: { xs: 0, md: 2.5 }
            }}
        >
            <TextField
                type="search"
                value={filterValues.searchValue}
                onChange={(event) => handleChangeValues(event.target.value, "searchValue")}
                placeholder={`${translate('commons.search')}`}
                sx={{ width: "100%", mr: 2 }}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        handleSearch();
                    }
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify
                                icon={'eva:search-fill'}
                                sx={{ color: 'text.disabled', width: 20, height: 20 }}
                            />
                        </InputAdornment>
                    ),
                }}
            />

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Button
                    startIcon={
                        <Iconify icon={'bx:search-alt'} sx={{ ml: 1.5, mt: 0.5 }} />
                    }
                    onClick={handleSearch}
                    size="large"
                    sx={{
                        mt: 0.5,
                        width: { xs: "100%" }
                    }}
                />

                <Button
                    startIcon={<DeleteIcon sx={{ ml: 1.5, mt: 0.75 }} />}
                    onClick={handleDelete}
                    sx={{
                        display: `${(showDelete) ? "inline" : "none"}`,
                        mt: 0.5,
                        width: { xs: "100%" }
                    }}
                    size="large"
                />
            </Box>

        </Stack>
    );
}