import { Stack, InputAdornment, TextField, Button } from '@mui/material';
import useLocales from "src/appHooks/useLocales";
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

type Props = {
  //optionsFields: EmailSearchFilters[];
  filterName: string;
  //filterField: string;
  onFilterName: (value: string) => void;
  //onFilterField: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSearch: VoidFunction
};

export default function EmailTableToolbar({ filterName, onFilterName, onSearch }: Props) {

  const { translate } = useLocales();

  return (
    <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, px: 3 }}>
      {/*       
      <TextField
        fullWidth
        select
        label={`${translate("returns.searchType")}`}
        value={filterField}
        onChange={onFilterField}
        SelectProps={{
          MenuProps: {
            sx: { '& .MuiPaper-root': { maxHeight: 260 } },
          },
        }}
        sx={{
          maxWidth: { sm: 240 },
          textTransform: 'capitalize',
        }}
      >
        {optionsFields.map((option) => (
          <MenuItem
            key={option.key}
            value={option.key}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body1',
              textTransform: 'capitalize',
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </TextField> 
      */}

      <TextField
        type="search"
        fullWidth
        value={filterName}
        //disabled={filterField === "all"}
        onChange={(event) => onFilterName(event.target.value)}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter') {
            onSearch();
          }
        }}
        placeholder={`${translate("systemEmail.list.search")}`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon={'eva:search-fill'}
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />

      <Button
        startIcon={<Iconify icon={'bx:search-alt'} />}
        onClick={onSearch}
      />
    </Stack>
  );
}
