import BackupIcon from '@mui/icons-material/Backup';
import { Button, Card, MenuItem, Modal, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { NewReportScheduled, NewReportRecurring, ReportTypeData } from 'src/@types/report';
import MenuPopover from 'src/components/menu-popover/MenuPopover';
import { reportsOperations } from 'src/redux/reports';
import { dispatch } from 'src/redux/store';
import useResponsive from 'src/hooks/useResponsive';
import { useLocales } from 'src/locales';
import ReportNewEdit from './edit/ReportNewEdit';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { setSuccessMessage } from 'src/redux/modal/modal-slices';

interface ReportCreationModalProps {
    allFilters: any,
    reportType?: ReportTypeData
}

export default function ReportCreationModal({ allFilters, reportType }: ReportCreationModalProps) {

    const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

    const [isOpen, setIsOpen] = useState<"recurring" | "scheduled" | null>(null);

    const isDesktop = useResponsive('up', 'lg');

    const [goTo, setGoTo] = useState<string>("");

    const { translate } = useLocales();

    const handleOpenModal = (open: 'recurring' | 'scheduled') => {
        setIsOpen(open);
        setOpenPopover(null);
    };

    useEffect(() => {
        if (reportType)
            dispatch(reportsOperations.getColumns(reportType));
    }, [reportType]);

    const handleSubmit = async (state: NewReportScheduled | NewReportRecurring, overrideFilters?: any) => {

        const isScheduled = isOpen === "scheduled";

        setGoTo(isScheduled ? PATH_DASHBOARD.reports.scheduled.list : PATH_DASHBOARD.reports.recurring.list);

        setIsOpen(null);

        if (reportType) {
            await dispatch(reportsOperations.createReport({ isScheduled, report: { ...state, filter: overrideFilters || allFilters }, type: reportType, category: 'order' })).unwrap();

            dispatch(setSuccessMessage({ text: translate('report.messages.successSave'), goTo: goTo }));
        }
    };

    return (
        <>
            <Button
                variant="outlined"
                size='small'
                sx={{ mr: 2 }}
                onClick={(e) => setOpenPopover(e.currentTarget)}
            >
                <BackupIcon />
            </Button>

            <MenuPopover
                disableScrollLock
                open={openPopover}
                onClose={() => setOpenPopover(null)}
            >
                <Stack >
                    <MenuItem
                        onClick={() => handleOpenModal('recurring')}
                    >
                        <Typography variant='body1'>{`${translate('report.recurring.title')}`}</Typography>
                    </MenuItem>

                    <MenuItem
                        onClick={() => handleOpenModal('scheduled')}
                    >
                        <Typography>{`${translate('report.scheduled.title')}`}</Typography>
                    </MenuItem>
                </Stack>
            </MenuPopover>

            <Modal open={!!isOpen}>
                <Card
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: isDesktop ? '40vw' : '70vw',
                        heigth: '70vh',
                        py: isDesktop ? 4 : 2,
                    }}
                >
                    <ReportNewEdit isOpen={isOpen} toggle={() => setIsOpen(null)} onSubmit={handleSubmit} filter={allFilters} />
                </Card>
            </Modal>
        </>
    );
}