import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { ApprovedMatrixData, GetPNOProps, IrvMatrixPreview, UserMatrixTemplateDocument, RejectedMatrixData } from "src/@types/matrix";
import { quarters } from "src/sections/@dashboard/matrix/newEdit/Constant";
import matrixService from 'src/services/matrixService';

const createMatrixTemplate = createAsyncThunk(
    'matrix/createTemplate',
    async (matrix: UserMatrixTemplateDocument, { rejectWithValue }) => {
        try {
            const { data } = await matrixService.createMatrixTemplate(matrix);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const updateMatrixTemplate = createAsyncThunk(
    'matrix/updateTemplate',
    async (matrix: UserMatrixTemplateDocument, { rejectWithValue }) => {
        try {
            const { status } = await matrixService.updateMatrixTemplate(matrix);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return true;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const updateRvMatrix = createAsyncThunk(
    'matrix/updateRvMatrix',
    async (matrix: IrvMatrixPreview, { rejectWithValue, dispatch }) => {
        try {
            const { status } = await matrixService.updateRvMatrix(matrix);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return true;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const getRvMatrixPreview = createAsyncThunk(
    'matrix/getRvMatrixPreview',
    async (matrix: UserMatrixTemplateDocument, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await matrixService.rvMatrixPreview(matrix);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const duplicateTemplate = createAsyncThunk(
    'matrix/duplicateTemplate',
    async ({ id, title, fetch }: { id: string, title: string, fetch: () => void }, { rejectWithValue, dispatch, }) => {
        try {
            const { data } = await matrixService.detailTemplate(id);

            data.name = title;
            if (data) {
                await matrixService.createMatrixTemplate(data);
                fetch();
            }
            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const duplicateRvMatrix = createAsyncThunk(
    'matrix/duplicateRvMatrix',
    async ({ id, title, fetch }: { id: string, title: string, fetch: () => void }, { rejectWithValue, dispatch, }) => {
        try {
            const { data } = await matrixService.detailRvMatrix(id);

            data.name = title;
            if (data) {
                await matrixService.createRvMatrix(data);
                fetch();
            }
            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const getPNO = createAsyncThunk(
    'matrix/getPNO',
    async (options: GetPNOProps, { rejectWithValue, dispatch }) => {
        try {
            const { data: pnos } = await matrixService.getPNO();

            let searchFilters = {
                pageIndex: 0,
                pageSize: 100,
                IncludeDisabled: false,
                ...options
            };

            const { data: matrixes } = await matrixService.searchRvMatrixTemplate(searchFilters);

            if (!pnos || !matrixes) {
                throw new Error('Something went wrong');
            }

            return { pnos, matrixes };
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const getPNOOnly = createAsyncThunk(
    'matrix/getPNOOnly',
    async (_, { rejectWithValue, dispatch }) => {
        try {
            const { data: pnos } = await matrixService.getPNO();

            return { pnos };
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const getPNOPrices = createAsyncThunk(
    'matrix/getPNOPrices',
    async (_, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await matrixService.getPNOPrices();

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const getFilteredMatrixTemplates = createAsyncThunk(
    'matrix/getFilteredMatrixTemplates',
    async (search: any, { rejectWithValue, dispatch, }) => {
        try {
            const { data } = await matrixService.searchMatrixTemplate(search);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const getFilteredRvMatrixes = createAsyncThunk(
    'matrix/getFilteredRvMatrixes',
    async (search: any, { rejectWithValue, dispatch, }) => {
        try {
            const { data } = await matrixService.searchRvMatrixTemplate(search);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const getMatrixTemplateById = createAsyncThunk(
    'matrix/getMatrixTemplateById',
    async (id: string, { rejectWithValue, dispatch, }) => {
        try {
            const { data } = await matrixService.detailTemplate(id);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const getRvMatrixById = createAsyncThunk(
    'matrix/getRvMatrixById',

    async (id: string, { rejectWithValue, dispatch, }) => {
        try {
            const { data } = await matrixService.detailRvMatrix(id);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const createRvMatrix = createAsyncThunk(
    'matrix/createRvMatrix',
    async (matrix: IrvMatrixPreview, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await matrixService.createRvMatrix(matrix);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const createRvMatrixFromFile = createAsyncThunk(
    'matrix/createRvMatrixFromFile',
    async (matrix: FormData, { rejectWithValue, dispatch }) => {
        try {
            const data = await matrixService.uploadMatrix(matrix);

            return data;
        } catch (err) {

            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.status);
        }
    }
);

const getExcelRvMatrix = createAsyncThunk(
    'matrix/getExcelRvMatrix',
    async (id: string, { rejectWithValue, }) => {
        try {
            const { data } = await matrixService.getExcelRvMatrix(id);

            const formatedDownloadUrl = data?.downloadUrl?.replace("/rv", '');

            matrixService.downloadExcel(formatedDownloadUrl);
            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const getRvMatrixesById = createAsyncThunk(
    'matrix/getRvMatrixesById',
    async (rvMatrixes: string[], { rejectWithValue, dispatch }) => {
        try {
            const result: IrvMatrixPreview[] = [];

            await Promise.all(
                rvMatrixes.map(async id => {
                    try {
                        const { data } = await matrixService.detailRvMatrix(id);

                        if (!data) {
                            throw new Error('Something went wrong');
                        }
                        result.push(data);

                        return await Promise.resolve();
                    }
                    catch (err) {
                        if (err?.response?.data?.errors) {
                            return rejectWithValue(err.response.data.errors);
                        }

                        return rejectWithValue(err?.response?.statusText);
                    }
                })
            );

            return result;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const sendToApproveRvMatrix = createAsyncThunk(
    'matrix/sendToApproveRvMatrix',
    async (rvMatrix: IrvMatrixPreview, { rejectWithValue, dispatch }) => {
        try {
            const { status } = await matrixService.updateRvMatrix(rvMatrix);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }
            await matrixService.submitRvMatrix(rvMatrix.id);

            return true;
        } catch (err) {
            if (err?.response?.data?.errors) {
                return rejectWithValue(err.response.data.errors);
            }

            return rejectWithValue(err?.response?.statusText);
        }
    }
);

const disableRvMatrix = createAsyncThunk(
    'matrix/disableRvMatrix',
    async ({ id, fetch }: { id: string, fetch: () => void }, { rejectWithValue, dispatch, }) => {
        try {
            const { status } = await matrixService.disableRvMatrix(id);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return status;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const enableRvMatrix = createAsyncThunk(
    'matrix/enableRvMatrix',
    async ({ id, fetch }: { id: string, fetch: () => void }, { rejectWithValue, dispatch, }) => {
        try {
            const { status } = await matrixService.enableRvMatrix(id);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return status;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const approveRvMatrix = createAsyncThunk(
    'matrix/approveRvMatrix',
    async ({ id, data, type }: { id: string, type: string, data: ApprovedMatrixData | RejectedMatrixData }, { rejectWithValue, dispatch, }) => {
        try {
            const { status } = await matrixService.approve(id, data, type);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return status;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const disableTemplate = createAsyncThunk(
    'matrix/disableTemplate',
    async ({ id, fetch }: { id: string, fetch: () => void }, { rejectWithValue, dispatch, }) => {
        try {
            const { status } = await matrixService.disable(id);

            fetch();
            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return status;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const enableTemplate = createAsyncThunk(
    'matrix/enableTemplate',
    async ({ id, fetch }: { id: string, fetch: () => void }, { rejectWithValue, dispatch, }) => {
        try {

            const { status } = await matrixService.enable(id);

            fetch();
            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return status;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const getMatrixStatusesCount = createAsyncThunk(
    'matrix/getMatrixStatuses',
    async (options: any, { rejectWithValue, dispatch, }) => {
        try {

            const { status, data } = await matrixService.getStatuses(options);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const getMatrixWidgetStatistics = createAsyncThunk(
    'matrix/getWidgetStatistics',
    async (options: any, { rejectWithValue, dispatch, }) => {
        try {

            const { status, data } = await matrixService.getStatuses(options);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const getPowesearchMatrixesResults = createAsyncThunk(
    'matrix/getPowesearchMatrixesResults',
    async (options: any, { rejectWithValue, dispatch, }) => {
        try {

            const { status, data } = await matrixService.getPowersearchMatrixes(options);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const getPowesearchMatrixesTemplatesResults = createAsyncThunk(
    'matrix/getPowesearchMatrixesTemplatesResults',
    async (options: any, { rejectWithValue, dispatch, }) => {
        try {

            const { status, data } = await matrixService.getPowersearchMatrixesTemplate(options);

            if (status >= 400) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const getMatrixLogs = createAsyncThunk(
    'matrix/getMatrixLogs',
    async (options: any, { rejectWithValue }) => {
        try {
            const { data } = await matrixService.searchMatrixLogs(options);

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const getMatrixColorDetails = async (matrix: IrvMatrixPreview) => {

    const pno = matrix.manufacturerCodes[0].slice(0, 5);

    const pno18 = matrix.manufacturerCodes[0].slice(0, 12);

    const a = await (matrixService.getPNO());

    const lista = a.data;

    return (lista[pno].pno12)[pno18].pno18;
};

const getQuarter = (inputDate: string, translate: Function) => {
    const date = new Date(inputDate);

    const year = date.getFullYear();

    const quarter = quarters.find(q =>
        q.monthFrom <= dayjs(inputDate).month() && q.monthTo >= dayjs(inputDate).month()
    )?.value!;

    return `${+quarter + 1} ${translate("commons.quarter")} ${year}`;
};

const marixOperations = {
    createMatrixTemplate,
    getPNO,
    getPNOPrices,
    getFilteredMatrixTemplates,
    getMatrixTemplateById,
    updateMatrixTemplate,
    duplicateTemplate,
    duplicateRvMatrix,
    createRvMatrix,
    getRvMatrixPreview,
    getRvMatrixById,
    getFilteredRvMatrixes,
    updateRvMatrix,
    createRvMatrixFromFile,
    getPNOOnly,
    getExcelRvMatrix,
    sendToApproveRvMatrix,
    getRvMatrixesById,
    disableRvMatrix,
    enableRvMatrix,
    disableTemplate,
    enableTemplate,
    getMatrixStatusesCount,
    getMatrixWidgetStatistics,
    getPowesearchMatrixesResults,
    getPowesearchMatrixesTemplatesResults,
    getMatrixLogs,
    approveRvMatrix,
    getMatrixColorDetails,
    getQuarter
};

export default marixOperations;

