import Page from 'src/appComponents/Page';
import { PATH_DASHBOARD } from 'src/routes/paths';
import HeaderBreadcrumbs from '../../../components/custom-breadcrumbs';
import { Container } from '@mui/material';
import { useLocales } from 'src/locales';
import { useSettingsContext } from 'src/components/settings';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { DEFAULT_BASKET_RULE, NewBasketRule } from 'src/@types/webshop';
import { dispatch, RootState, useSelector } from 'src/redux/store';
import { webshopOperations } from 'src/redux/webshop';
import GeneralStep from 'src/sections/@dashboard/basketRules/form/GeneralStep';
import { setSuccessMessage } from 'src/redux/modal/modal-slices';
import { intervalToDuration } from 'date-fns';
import LoadingScreen from 'src/appComponents/loading-screen';

export type BasketRuleFormAction = {
    payload: NewBasketRule
    type: 'update' | 'reset'
};

export const durationToMinutes = (duration: string) => {

    const minutes = duration.split(":")[1];

    return minutes === "00" ? 60 : Number(minutes);
};

export default function NewEditBasketRule() {

    const { translate } = useLocales();

    const { themeStretch } = useSettingsContext();

    const { id } = useParams();

    const { basketRule, isBasketRuleLoading } = useSelector((state: RootState) => state.webshop);

    useEffect(() => {
        if (id) dispatch(webshopOperations.getBasketRule(id));
    }, [id]);

    const minutesToDuration = (minutes: number) => {

        const duration = intervalToDuration({ start: 0, end: minutes * 60 * 1000 });

        const hours = duration.hours || 0;

        const mins = duration.minutes || 0;

        const paddedHours = String(hours).padStart(2, '0');

        const paddedMinutes = String(mins).padStart(2, '0');

        return `${paddedHours}:${paddedMinutes}:00`;
    };

    const handleSubmit = async (basket: BasketRuleFormAction) => {

        let cleanedBasketRule: NewBasketRule = { ...basket.payload };

        if (cleanedBasketRule.minutes) cleanedBasketRule.duration = minutesToDuration(cleanedBasketRule.minutes);

        if (cleanedBasketRule.minAmount === DEFAULT_BASKET_RULE.minAmount) delete cleanedBasketRule.minAmount;

        if (cleanedBasketRule.minItems === DEFAULT_BASKET_RULE.minItems) delete cleanedBasketRule.minItems;

        delete cleanedBasketRule.minutes;

        if (!id)
            await dispatch(webshopOperations.createBasketRule(cleanedBasketRule)).unwrap();
        else
            await dispatch(webshopOperations.updateBasketRule({ basketRule: cleanedBasketRule, id: id })).unwrap();

        dispatch(setSuccessMessage(
            {
                text: translate(`basketRule.messages.success.${id ? "edit" : "new"}`),
                goTo: PATH_DASHBOARD.basketRules.list
            }));
    };

    return (
        <Page title={translate("basketRule.form.new_short")}>

            <Container maxWidth={themeStretch ? false : 'lg'}>

                <HeaderBreadcrumbs
                    heading={translate(`basketRule.form.${id ? "edit" : "new_short"}`)}
                    links={[
                        { name: translate("commons.home"), href: PATH_DASHBOARD.root },
                        { name: translate("menu.management.basketRules.title"), href: PATH_DASHBOARD.basketRules.list },
                        { name: translate(`commons.${id ? "edit" : "new"}`) },
                    ]}
                />

                {isBasketRuleLoading ?
                    <LoadingScreen />
                    :
                    <GeneralStep
                        key={id}
                        state={id ? (basketRule ?? DEFAULT_BASKET_RULE) : DEFAULT_BASKET_RULE}
                        onSubmit={handleSubmit}
                    />
                }

            </Container>
        </Page>
    );
}