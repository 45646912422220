import Container from '@mui/material/Container';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import Page from 'src/appComponents/Page';
import useLocales from 'src/appHooks/useLocales';
import { useSettingsContext } from 'src/components/settings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { dispatch, RootState } from 'src/redux/store';
import { FetchDataFuncArgs, LogsData } from 'src/@types/logs';
import Logs from 'src/appComponents/logs/Logs';
import { containerOperations } from 'src/redux/container';

const LABELS = [
    'ContainerCreated',
    'ContainerClosed',
    'ContainerShipped'
];

interface ContainerLogsProps {
    isTemplate?: boolean
}

export default function ContainerLogs({ isTemplate }: ContainerLogsProps) {

    const { themeStretch } = useSettingsContext();

    const isDesktop = useResponsive('up', 'sm');

    const { translate, currentLang } = useLocales();

    const { isLogsLoading, containerLogs, container } = useSelector((state: RootState) => state.container);

    const { id = '' } = useParams();

    useEffect(() => {
        if (container?.id !== id) {
            isTemplate ?
                dispatch(containerOperations.getContainerTemplate(id)) :
                dispatch(containerOperations.getContainer(id));
        }
    }, [id, currentLang, container, isTemplate]);

    const fetchLogs = (searchFilters: FetchDataFuncArgs) => {
        dispatch(containerOperations.getContainerLogs({ id: id, filters: searchFilters }));
    };

    //-----LABELS

    const createLabels = useCallback(() => {
        return LABELS.map((label) => ({
            label: `${translate(`containers.logs.eventTypes.${label.charAt(0).toLowerCase() + label.slice(1)}`)}`,
            key: label
        }));
    }, [translate]);

    const typesLabels = useMemo(
        () => [
            {
                label: `${translate(`commons.all`)}`,
                key: 'all',
            },
            ...createLabels(),
        ],
        [createLabels, translate]
    );

    return (
        <Page title={`${translate('containers.logs.title')}`}>
            <Container maxWidth={themeStretch ? false : 'lg'} sx={{ p: 0 }}>
                <HeaderBreadcrumbs
                    heading={`${translate('containers.logs.title')}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate('menu.management.containers.title')}`, href: isTemplate ? PATH_DASHBOARD.containers.templates.list : PATH_DASHBOARD.containers.list },
                        { name: `${container?.name}`, href: isTemplate ? PATH_DASHBOARD.containers.templates.detail(id) : PATH_DASHBOARD.containers.detail(id) },
                        { name: `${translate('commons.logs')}` }
                    ]}
                    sx={{ px: isDesktop ? 5 : 2, mb: isDesktop ? 5 : 2 }}
                />

                <Logs
                    tableData={containerLogs as LogsData}
                    isLoading={isLogsLoading}
                    id={id!}
                    header={`${translate(`commons.logs`)} `}
                    subheader={`${translate(`containers.logs.subtitle`)}`}
                    labels={typesLabels}
                    fetchData={fetchLogs}
                    localesPath={"containers"}
                />
            </Container>
        </Page>
    );
}
