import { Box, Button } from '@mui/material';
import Iconify from 'src/components/iconify';
import { useMemo } from 'react';
import { useLocales } from 'src/locales';
import { FilterListType } from 'src/@types/list';
import { isEqual } from 'lodash';
import { SelectItem } from 'src/@types/commons';
import { CustomFieldsSummaryChip, GenericSummaryChip, MultipleSummaryChip } from './SummaryChips';
import { getSliderFilterNames } from '../sidebar/SidebarSlider';

interface GenericFilterSummaryProps {
    showSummary: boolean,
    defaultFilters: any,
    filterValues: any,
    filterList: FilterListType[],
    onResetFilter: (el: any, value?: any) => void,
    onResetAll: VoidFunction,
    customfieldContext?: string[]
}

export default function GenericFilterSummary({ showSummary, defaultFilters, filterValues, filterList, onResetFilter, onResetAll, customfieldContext }: GenericFilterSummaryProps) {

    const { translate } = useLocales();

    const summaryComponents = useMemo(() => ({
        Single: (name: string, label: string, options?: SelectItem[]) => {

            let summaryValue: string = "";

            let showSummaryFilter: boolean;

            if (filterList.find((filter) => filter.name === name && filter.type === "Slider")) {

                const [minLabel, maxLabel] = getSliderFilterNames(name);

                showSummaryFilter = filterValues[minLabel] > defaultFilters[minLabel] || filterValues[maxLabel] < defaultFilters[maxLabel];

                if (showSummaryFilter) summaryValue = filterValues[minLabel] + " - " + filterValues[maxLabel];

            } else {
                showSummaryFilter = filterValues[name] && filterValues[name] !== defaultFilters[name];

                if (showSummaryFilter) summaryValue = options?.find((option) => option.value === filterValues[name])?.label ?? filterValues[name];
            }

            if (showSummaryFilter) return (
                <GenericSummaryChip
                    name={name}
                    label={label}
                    value={summaryValue}
                    onResetFilter={onResetFilter}
                />
            );
        },
        Multiple: (name: string, label: string, options?: SelectItem[]) => {
            if (filterValues[name] && filterValues[name].length > 0 && !isEqual(filterValues[name], defaultFilters[name])) return (
                <MultipleSummaryChip
                    name={name}
                    label={label}
                    values={filterValues[name]}
                    onResetFilter={onResetFilter}
                    valuesLabels={options}
                />
            );
        },
        CustomFields: (customfieldContext: string[]) => {
            if (filterValues.customFields && Object.entries(filterValues.customFields).length > 0) return (
                <CustomFieldsSummaryChip
                    contextList={customfieldContext}
                    values={filterValues.customFields}
                    onResetFilter={onResetFilter}
                />
            );
        }
    }), [defaultFilters, filterList, filterValues, onResetFilter]);

    return (
        showSummary &&
        <Box
            sx={{
                mb: 2, ml: 2,
                flexGrow: 1,
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center'
            }}
        >
            {filterList.map((filter, index) => {

                let JSXFilter: JSX.Element = <></>;

                switch (filter.type) {
                    case 'TextField':
                    case 'Select':
                    case 'Slider':
                    case 'SwitchGroup':
                        JSXFilter = summaryComponents.Single(filter.name, filter.label, filter.options) ?? <></>;
                        break;
                    case 'Autocomplete':
                    case 'MultiSelect':
                        JSXFilter = summaryComponents.Multiple(filter.name, filter.label, filter.options) ?? <></>;
                        break;
                }

                return <Box key={filter.name + "." + index}>{JSXFilter}</Box>;
            })}

            {customfieldContext && summaryComponents.CustomFields(customfieldContext)}

            <Button
                color="error"
                size="small"
                onClick={onResetAll}
                startIcon={<Iconify icon={'ic:round-clear-all'} />}
            >
                {`${translate("commons.clear")}`}
            </Button>

        </Box>
    );
}

