import HeaderBreadcrumbs from '../../../components/custom-breadcrumbs';
import { Button, Box, Card, TableContainer, Table, TableBody, TableRow, TableCell, CircularProgress, TablePagination, FormControlLabel, Switch } from "@mui/material";
import { Container, Stack } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
import Page from "src/appComponents/Page";
import Iconify from "src/components/iconify";
import { scrollBarStyle } from "src/components/scrollbar/Scrollbar";
import { TableHeadCustom, TableNoData, useTable } from "src/components/table";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useLocales } from 'src/locales';
import { useSettingsContext } from 'src/components/settings';
import { useEffect, useState } from 'react';
import { Brand } from 'src/@types/brand';
import { disable, enable } from 'src/services/brandServices';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'src/redux/store';
import { brandOperations } from 'src/redux/brand';
import BrandTableRow from './BrandTableRow';

export default function BrandList() {

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { adminBrandList } = useSelector((state: RootState) => state.brand);

    const { translate } = useLocales();

    const { enqueueSnackbar } = useSnackbar();

    const { themeStretch } = useSettingsContext();

    const { page, rowsPerPage, dense, onChangeDense, onChangePage, onChangeRowsPerPage } = useTable();

    const navigate = useNavigate();

    const TABLE_HEAD = [
        { id: 'name', label: `${translate('commons.name')}`, align: 'left' },
        { id: 'dataCreation', label: `${translate('commons.date')}`, align: 'left' },
        { id: 'externalId', label: `${translate('commons.externalId')}` },
        { id: 'enabled', label: `${translate('commons.enabled')}`, align: 'center' },
        { id: '', align: 'right' }

    ];

    const getBrand = async (update?: boolean) => {
        if (!adminBrandList || update) {
            try {
                await dispatch(brandOperations.getBrandList(true)).unwrap();
            } catch (e) {
                console.error(e);
            }
        }
    };

    useEffect(() => {
        getBrand();
    }, []);

    useEffect(() => {
        if (adminBrandList)
            setIsLoading(false);
    }, [adminBrandList]);

    const handleEnableBrand = async (brandId: string) => {
        try {
            await enable(brandId);
            enqueueSnackbar(`${translate('commons.enabled')}`, {
                variant: "success",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
            getBrand(true);
        } catch (e) {
            console.error(e);
            enqueueSnackbar(`${translate('commons.requestFailed')}`, {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
    };

    const handleDisableBrand = async (brandId: string) => {
        try {
            await disable(brandId);
            enqueueSnackbar(`${translate('commons.disabled')}`, {
                variant: "success",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
            getBrand(true);
        } catch (e) {
            console.error(e);
            enqueueSnackbar(`${translate('commons.requestFailed')}`, {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
    };

    const handleDetailBrand = (brandId: string) => {
        navigate(PATH_DASHBOARD.brands.detail(brandId));
    };

    const handleEditBrand = (brandId: string) => {
        navigate(PATH_DASHBOARD.brands.edit(brandId));
    };

    return (
        <Page title={`${translate('organization.form.brands.title')}`}>
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs
                    heading={`${translate('organization.form.brands.title')}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate('commons.list')}` },
                    ]}
                    action={
                        <Button
                            variant="contained"
                            component={Link}
                            to={PATH_DASHBOARD.brands.new}
                            startIcon={<Iconify icon={'eva:plus-fill'} />}
                        >
                            {`${translate('organization.form.brands.new')}`}
                        </Button>
                    }
                />
                <Card>
                    <TableContainer sx={[{ minWidth: 800, maxHeight: 500, position: 'relative', height: dense ? '' : rowsPerPage <= 5 ? 400 : 500 }, scrollBarStyle]}>
                        <Table stickyHeader size={dense ? 'small' : 'medium'}>
                            <TableHeadCustom
                                headLabel={TABLE_HEAD}
                                rowCount={adminBrandList?.length}
                            />
                            {isLoading ? (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={12}>
                                            <Stack
                                                alignItems="center"
                                                justifyContent="center"
                                                sx={{
                                                    height: 1,
                                                    textAlign: 'center',
                                                    p: (theme) => theme.spacing(8, 2),
                                                }}
                                            >
                                                <CircularProgress />
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ) : (
                                <TableBody>
                                    {adminBrandList?.map((brand: Brand) => (
                                        <BrandTableRow
                                            key={brand.id}
                                            row={brand}
                                            onEditRow={() => handleEditBrand(brand.id)}
                                            onDetailsRow={() => handleDetailBrand(brand.id)}
                                            onEnableRow={() => handleEnableBrand(brand.id)}
                                            onDisableRow={() => handleDisableBrand(brand.id)}
                                        />
                                    ))}
                                    <TableNoData isNotFound={adminBrandList?.length === 0} />
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    <Box sx={{ position: 'relative' }}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15, 30]}
                            component="div"
                            count={adminBrandList?.length ? adminBrandList.length : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={onChangePage}
                            onRowsPerPageChange={onChangeRowsPerPage}
                            labelRowsPerPage={`${translate('commons.rowsPerPage')}`}
                        />

                        <FormControlLabel
                            control={<Switch checked={dense} onChange={onChangeDense} />}
                            label={`${translate('commons.dense')}`}
                            sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                        />
                    </Box>
                </Card>
            </Container>

        </Page>
    );
}