import { Button } from "@mui/material";
import { isBoolean, isEqual } from "lodash";
import { useMemo } from "react";
import { SelectItem } from "src/@types/commons";
import { ContainerFilters, DEFAULT_CONTAINER_FILTERS } from "src/@types/container";
import Iconify from "src/components/iconify";
import { useLocales } from "src/locales";
import { CustomFieldsFilterChip, FilterChip, MultipleFilterChip, RootStyle } from "src/utils/SummaryUtils";

type Props = {
    filters: ContainerFilters,
    isShowReset: boolean,
    onResetAll: VoidFunction,
    onResetFilter: (el: any, value?: any) => void
}

export default function ContainerFilterSummary({ filters, isShowReset, onResetAll, onResetFilter }: Props) {

    const { translate } = useLocales();

    const { name, contentTypes, onlyEnabled, customFields, tags } = filters;

    const contentTypeLabels = useMemo(() => {
        if (contentTypes)
            return contentTypes.map((type) => {
                return {
                    label: `${translate(`request.${type}`)}`,
                    value: type
                } as SelectItem;
            });

        return [];
    }, [contentTypes, translate]);

    return (isShowReset &&
        <RootStyle sx={{ mb: 2, ml: 2 }}>

            {isBoolean(onlyEnabled) && onlyEnabled !== DEFAULT_CONTAINER_FILTERS.onlyEnabled &&
                <FilterChip
                    el={"containers.list.showEnabled"}
                    value={onlyEnabled}
                    onResetFilter={onResetFilter}
                    toDelete={"onlyEnabled"}
                />
            }

            {name && name !== DEFAULT_CONTAINER_FILTERS.name &&
                <FilterChip
                    el={"containers.tableHeaders.containerRef"}
                    value={name}
                    onResetFilter={onResetFilter}
                    toDelete={"name"}
                />
            }

            {contentTypes && !isEqual(contentTypes, DEFAULT_CONTAINER_FILTERS.contentTypes) &&
                <MultipleFilterChip
                    el={"returns.tableHeaders.reason"}
                    value={contentTypes}
                    onResetFilter={onResetFilter}
                    toDelete={"contentTypes"}
                    labels={contentTypeLabels}
                />
            }

            {customFields && Object.entries(customFields).length > 0 &&
                <CustomFieldsFilterChip
                    context={"Container"}
                    customfieldValues={customFields}
                    onResetFilter={onResetFilter}
                />
            }

            {tags && tags.length > 0 &&
                <MultipleFilterChip
                    el={"commons.tags"}
                    value={tags}
                    onResetFilter={onResetFilter}
                />
            }

            <Button
                color="error"
                size="small"
                onClick={onResetAll}
                startIcon={<Iconify icon={'ic:round-clear-all'} />}
            >
                {`${translate("commons.clear")}`}
            </Button>

        </RootStyle >
    );
}