import { Slider, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { isArray } from "lodash";
import { useMemo } from "react";
import { useLocales } from "src/locales";

export const getSliderFilterNames = (name: string) => {

    const fieldName = name.charAt(0).toUpperCase() + name.slice(1);

    return ["min" + fieldName, "max" + fieldName];
};

interface SidebarSliderProps {
    name: string,
    label: string,
    sidebarValues: any,
    defaultFilterValues: any,
    stepValue: number,
    stepsNumber: number,
    handleChangeValues: (values: any, newVal: any, type: string) => void
};

export default function SidebarSlider({ name, label, sidebarValues, defaultFilterValues, stepValue, stepsNumber, handleChangeValues }: SidebarSliderProps) {

    const { translate } = useLocales();

    const [minLabel, maxLabel] = getSliderFilterNames(name);

    const defaultValues: [number, number] = useMemo(() => [defaultFilterValues[minLabel], defaultFilterValues[maxLabel]], [defaultFilterValues, maxLabel, minLabel]);

    const values: [number, number] = useMemo(() => [sidebarValues[minLabel], sidebarValues[maxLabel]], [maxLabel, minLabel, sidebarValues]);

    const stepSkip: number = defaultFilterValues[maxLabel] / (stepValue * stepsNumber);

    const marksLabelQuantity: { value: number, label: string }[] = [...Array((defaultFilterValues[maxLabel] / stepValue) + 1)].map((_, index) => {

        const value = index * stepValue;

        return {
            value,
            label: index % stepSkip ? '' : value.toString()
        };
    }).filter((mark) => mark.label);

    return (
        <Stack spacing={1} sx={{ pb: 2 }}>
            <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                {label}
            </Typography>

            <Stack direction="row" spacing={2}>
                <InputRange label={`${translate('commons.min')}`} value={values[0] || defaultValues[0]} />
                <InputRange label={`${translate('commons.max')}`} value={values[1] || defaultValues[1]} />
            </Stack>

            <Slider
                name={name}
                step={stepValue}
                min={defaultValues[0]}
                max={defaultValues[1]}
                marks={marksLabelQuantity}
                getAriaValueText={(value: any) => `${value}`}
                valueLabelFormat={(value: any) => `${value}`}
                sx={{ alignSelf: 'center', width: `calc(100% - 20px)` }}
                valueLabelDisplay="auto"

                value={[values[0], values[1]]}

                onChange={(event: Event, newValue: number | number[], activeThumb: number) => {

                    if (!isArray(newValue)) {
                        return;
                    }

                    const [minValueInForm, maxValueInForm] = values;

                    var min = minValueInForm;
                    var max = maxValueInForm;

                    if (activeThumb === 0) {
                        min = Math.min(newValue[0], maxValueInForm! - defaultValues[0]);
                    } else {
                        max = Math.max(newValue[1], minValueInForm! + defaultValues[0]);
                    }

                    if (max! - min! >= stepValue) {
                        handleChangeValues(sidebarValues, [min, max], name);
                    }
                }}
            />
        </Stack>
    );
}

//-------------------------------------------

interface InputRangeProps {
    label: string,
    value: number
};

function InputRange({ label, value }: InputRangeProps) {

    return (
        <Stack direction="row" spacing={0.5} alignItems="center" sx={{ width: 1 }}>
            <Typography variant="caption" sx={{ flexShrink: 0, fontWeight: 'fontWeightBold', color: 'text.disabled', textTransform: 'capitalize', }}>
                {label}:
            </Typography>
            <Typography variant="caption" sx={{ flexShrink: 0, fontWeight: 'fontWeightBold', pl: 1 }}>
                {value}
            </Typography>
        </Stack>
    );
}