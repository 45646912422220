import { createContext, ReactNode, useEffect, useCallback } from 'react';
// services
import { getCustomField, getTenant } from '../services/tenantService';
// @types
import { TenantType } from '../@types/tenant';
import useLocalStorage from 'src/hooks/useLocalStorage';

const TenantContext = createContext(null as TenantType | null);

type TenantProviderProps = {
    children: ReactNode;
};

function TenantProvider({ children }: TenantProviderProps) {

    const [tenant, setTenant] = useLocalStorage("tenant", null as TenantType | null);

    const initialize = useCallback(async () => {
        try {
            const { data, status } = await getTenant();

            const customFields = await getCustomField();

            setTenant({ ...data, status, customFields: customFields.data });
        }
        catch (e) {
            setTenant({ status: e.response.status, endTo: e.response.data.endTo, startFrom: e.response.data.startFrom });
        }
    }, []);

    const refreshCustomFields = async () => {
        const response = await getCustomField();

        response.data && setTenant({ ...tenant!, customFields: response!.data });
    };

    useEffect(() => {
        initialize();
    }, [initialize]);

    return (
        <TenantContext.Provider value={{
            ...tenant!,
            refreshCustomFields,
        }}>
            {children}
        </TenantContext.Provider>
    );
}

export { TenantContext, TenantProvider };
