import React, { useState } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { DEFAULT_IMAGE_IN_MODAL, FileWithPreview, ImageInModalType } from 'src/@types/media';
import ImageEditor from './ImageEditor/ImageEditor';
import UploadInputBox from './UploadInputBox';
import ImagesPreview from './preview/ImagesPreview';

interface ImageUploaderEditorProps {
    onChangeImages: (files: (ImageInModalType & File)[]) => void,
    defaultImages: (ImageInModalType & File)[],
    onChangePreviews: (files: FileWithPreview[]) => void,
    defaultPreviews: FileWithPreview[]
}

export default function ImageUploaderEditor({ onChangeImages, defaultImages, onChangePreviews, defaultPreviews }: ImageUploaderEditorProps) {

    const [previewFiles, setPreviewFiles] = useState<FileWithPreview[]>(defaultPreviews);

    const [originalFiles, setOriginalFiles] = useState<(ImageInModalType & File)[]>(defaultImages);

    const [imageInModal, setImageInModal] = useState<ImageInModalType>(DEFAULT_IMAGE_IN_MODAL);

    const [imgIndex, setImgIndex] = useState<number>(0);

    const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {

        if (e.target.files && e.target.files.length > 0) {

            const newFiles: any[] = [...previewFiles].concat(Object.values(e.target.files).map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    aspect: 16 / 9,
                    originalWidth: 0,
                    originalHeight: 0
                })
            ));

            newFiles.forEach((file) => {
                if (!file.originalWidth) {
                    const img = new Image();

                    img.src = file.preview;

                    img.onload = () => {
                        file.originalWidth = img.width;
                        file.originalHeight = img.height;
                    };
                }
            });

            setPreviewFiles(newFiles as FileWithPreview[]);

            setOriginalFiles(newFiles as (ImageInModalType & File)[]);

            onChangePreviews(newFiles as FileWithPreview[]);

            onChangeImages(newFiles as (ImageInModalType & File)[]);
        }
    };

    const openCropWindow = (index: number) => {

        const file = originalFiles[index];

        const reader = new FileReader();

        reader.addEventListener('load', () => {
            setImageInModal({
                preview: reader.result?.toString() || '',
                crop: file.crop,
                rotation: file.rotation ?? DEFAULT_IMAGE_IN_MODAL.rotation,
                scale: file.scale ?? DEFAULT_IMAGE_IN_MODAL.scale,
                aspect: file.aspect ?? DEFAULT_IMAGE_IN_MODAL.aspect
            });
            setImgIndex(index);
        });

        reader.readAsDataURL(file);
    };

    const onDeleteFile = (fileIndex: number) => {

        setPreviewFiles((prev) => {
            const f = prev.filter((_, index) => index !== fileIndex);

            onChangePreviews(f);

            return f;
        });

        setOriginalFiles((prev) => {
            const f = prev.filter((_, index) => index !== fileIndex);

            onChangeImages(f);

            return f;
        });
    };

    //TODO: quando si inviano le immagini serve applicare lo scale

    return (
        <>
            <ImageEditor
                previewFiles={previewFiles}
                setPreviewFiles={(f) => {
                    setPreviewFiles(f);
                    onChangePreviews(f);
                }}
                originalFiles={originalFiles}
                setOriginalFiles={(f) => {
                    setOriginalFiles(f);
                    onChangeImages(f);
                }}
                imageInModal={imageInModal}
                setImageInModal={setImageInModal}
                imgIndex={imgIndex}
            />

            <UploadInputBox onChange={onSelectFile} />

            {previewFiles.length > 0 &&
                <ImagesPreview
                    files={previewFiles}
                    onEdit={openCropWindow}
                    onRemove={onDeleteFile}
                    onUploadImage={onSelectFile}
                />
            }
        </>
    );
}
