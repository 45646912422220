import { Box, Container, Tab, Tabs } from '@mui/material';
import Page from 'src/appComponents/Page';
import { useSettingsContext } from 'src/components/settings';
import HeaderBreadcrumbs from '../../../components/custom-breadcrumbs';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useEffect, useMemo, useState } from 'react';
import { useLocales } from 'src/locales';
import { useNavigate, useParams } from 'react-router';
import BacklogRequestReturnDetail from 'src/sections/@dashboard/backlogRequest/BacklogRequestReturnDetail';
import OrganizationDetail from 'src/sections/@dashboard/organizations/detail/OrganizationDetail';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'src/redux/store';
import { requestOperations } from 'src/redux/request';
import LoadingScreen from 'src/appComponents/loading-screen';
import { ordersOperations } from 'src/redux/orders';
import { DeliveryNote, DocData, Invoice, Order } from 'src/@types/orders';
import { hasPermissions } from 'src/utils/user';
import { OrganizationPermissionTypes } from 'src/@types/permissions';
import { useUserOrganizationContext } from 'src/contexts/UserOrganizationContext';
import BacklogDoumentDetail from 'src/sections/@dashboard/backlogRequest/BacklogDoumentDetail';
import { RequestItem } from 'src/@types/request';

interface BacklogRequestDetailProps {
  carrier: boolean;
}

export default function BacklogRequestDetail({ carrier }: BacklogRequestDetailProps) {

  const { translate } = useLocales();

  const { themeStretch } = useSettingsContext();

  const { idRequest } = useParams(); //TODO:aggiungere tab

  const { organization } = useUserOrganizationContext();

  const [currentTab, setCurrentTab] = useState('details');

  const [document, setDocument] = useState<Invoice | DeliveryNote>();

  const [orderList, setOrderList] = useState<Order[]>([]);

  const navigate = useNavigate();

  const { request, isLoading } = useSelector((state: RootState) => state.request);

  const { isOrderLoading } = useSelector((state: RootState) => state.orders);

  //GET REQUEST DATA
  useEffect(() => {
    if (idRequest && request?.id !== idRequest)
      dispatch(requestOperations.getRequest({ id: idRequest }));
  }, [idRequest, request?.id]);

  //MANAGE TABS
  const onTabChange = (newTab: string) => {
    switch (newTab) {
      case 'details':
        navigate(PATH_DASHBOARD.request.vendor.backlogDetail(idRequest!));
        break;
      case 'customerInfo':
        navigate(PATH_DASHBOARD.request.vendor.organizationBacklogDetailsTab(idRequest!, newTab, request!.customer.type, request!.customer.id, 'general'));
        break;
      default:
        navigate(PATH_DASHBOARD.request.vendor.backlogDetailsTab(idRequest!, newTab));
    }

    setCurrentTab(newTab);
  };

  useEffect(() => {
    setCurrentTab(currentTab || 'details');
  }, [currentTab]);

  //CREATE ITEMS LIST
  const itemsList: RequestItem[] = useMemo(() => {
    if (request) {
      if (request.items.length > 0) return request.items;
      else if (request.missingItems.length > 0) return request.missingItems;

      return [];
    } else return [];
  }, [request]);

  //GET ORDER DETAILS
  useEffect(() => {
    if (request && idRequest && request.id === idRequest) {
      const orderIdSet = new Set([...itemsList.map((i) => i.orderId)]);

      orderIdSet.forEach((orderId) => {
        dispatch(ordersOperations.getOrder(orderId))
          .unwrap()
          .then((order) => setOrderList((p) => p.concat([order])));
      });
    }
  }, [request, idRequest, itemsList]);

  //GET DOCUMENTS DETAILS
  const invoicesList: DocData[] = useMemo(() => {
    if (request && orderList.length > 0) {

      return itemsList.filter((item) => {
        const order = orderList.find((order) => order.id === item.orderId);

        return (order && order.invoiceId);
      }).map((item) => {

        const order = orderList.find((order) => order.id === item.orderId);

        return {
          partExternalId: item.product.externalId,
          documentId: order!.invoiceId,
          documentExternalId: order!.invoiceExternalId,
        } as DocData;

      });

    } else return [];
  }, [request, itemsList, orderList]);

  const deliveryNotesList: DocData[] = useMemo(() => {
    if (request && orderList.length > 0) {

      return itemsList.filter((item) => {
        const order = orderList.find((order) => order.id === item.orderId);

        return (order && order.deliveryNoteId);
      }).map((item) => {
        const order = orderList.find((order) => order.id === item.orderId);

        return {
          partExternalId: item.product.externalId,
          documentId: order!.deliveryNoteId,
          documentExternalId: order!.deliveryNoteExternalId,
        } as DocData;
      });

    } else return [];
  }, [request, itemsList, orderList]);

  const TABS = useMemo(
    () => [
      {
        value: 'details',
        label: `${translate('request.returnDetails')}`,
        component: request && (
          <BacklogRequestReturnDetail carrier={carrier} request={request} documents={invoicesList} />
        )
      },
      ...(hasPermissions([OrganizationPermissionTypes.WebShop_Orders_VendorView], organization.roles, false) && request?.items[0]
        ? [{
          value: 'documents',
          label: `${translate('request.document')}`,
          component: request && (
            <BacklogDoumentDetail
              document={document}
              setDocument={setDocument}
              invoicesList={invoicesList}
              deliveryNotesList={deliveryNotesList}
              itemsList={itemsList}
            />
          )
        }]
        : []),
      ...(hasPermissions([OrganizationPermissionTypes.Organization_View], organization.roles, false)
        ? [
          {
            value: 'customerInfo',
            label: `${translate('request.customerInfo')}`,
            component: request && (
              <OrganizationDetail
                isVendor={false}
                id={request.customer.id}
                type={request.customer.type}
                tab={'general'}
                requestId={request.id}
                isRequest
              />
            )
          }
        ]
        : [])
    ], [translate, request, carrier, organization.roles, document, deliveryNotesList, invoicesList, itemsList,]);

  return (
    <Page title={`${translate('request.detail.backlogDetail')}`}>
      {isLoading || isOrderLoading ? (
        <LoadingScreen />
      ) : (
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <HeaderBreadcrumbs
            heading={`${translate('menu.management.request.title')}`}
            links={[
              { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
              { name: `${translate('commons.list')}`, href: carrier ? PATH_DASHBOARD.request.carrier.list : PATH_DASHBOARD.request.vendor.list },
              { name: `${translate('returns.filter.idRequest')} ${request?.prettyName}` }
            ]}
          />

          <Tabs value={currentTab} onChange={(_, val) => onTabChange(val)} sx={{ ml: 1 }}>
            {TABS.map((tab, index) => (
              <Tab key={index} value={tab.value} label={tab.label} />
            ))}
          </Tabs>

          {TABS.map((tab, ind) => tab.value === currentTab && (
            <Box key={ind} sx={{ my: 2 }}>
              {tab.component}
            </Box>
          ))}
        </Container>
      )}
    </Page>
  );
}
