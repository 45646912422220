import { Box, Button, Card, Chip, Divider, Stack, Typography, alpha, useTheme } from "@mui/material";
import { useCallback, useMemo } from "react";
import { allLangs, useLocales } from "src/locales";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import Label, { LabelColor } from "src/components/label";
import PersonIcon from '@mui/icons-material/Person';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { isArray, isObject } from "lodash";
import useTenant from "src/appHooks/useTenant";
import dayjs from "dayjs";
import { Fields } from "src/@types/report";
import { getCustomFieldLabel } from "src/utils/CustomFieldManagment";

interface ReportGeneralDetailProps {
    isScheduled: boolean,
    backPath: any
}

export default function ReportGeneralDetail({ isScheduled, backPath }: ReportGeneralDetailProps) {

    const { customFields } = useTenant();

    const { currentLang, translate } = useLocales();

    const theme = useTheme();

    const navigate = useNavigate();

    const { report } = useSelector((state: RootState) => state.reports);

    const { id, type } = useParams();

    const status = useMemo(() => {
        if (report) {
            if (isScheduled) {
                let status = 'waiting';

                if (report.hasError) status = 'error';
                else if (report.lastExecution) status = 'success';

                return status;
            } else {
                return report.enabled ? "Enabled" : "Disabled";
            }
        }
    }, [isScheduled, report]);

    const getStatusColor = (): LabelColor => {

        let color = 'default';

        switch (status) {
            case "Enabled": case "success":
                color = "success";
                break;
            case "Disabled": case "error":
                color = "error";
                break;
            default: color = "warning";
        }

        return color as LabelColor;
    };

    const handleEdit = () => {
        navigate(backPath.edit(id, type));
    };

    const handleDownload = () => {
        if (report) window.open(report.downloadUri, '_blank');
    };

    const showFilter = useCallback((field: string) => {
        return !(field === "vendorId" || field === "customerId" ||
            (isArray(report?.filter[field]) && report?.filter[field].length === 0) ||
            (isObject(report?.filter[field]) && Object.keys(report?.filter[field]).length === 0)
        );
    }, [report]);

    const contextCustomFields = useMemo(() => {

        //const context = "ReturnRequestApprover";

        const fields = customFields.filter((field) => field.enabled && field.searchable); //&& field.entityType.toLowerCase() === context.toLowerCase())

        return fields.sort((a, b) => a.name[0].text!.toLowerCase().localeCompare(b.name[0].text!.toLowerCase()));

    }, [customFields]);

    const getFilterLabel = (field: string, name?: string) => {
        let label = field + ":";

        if (isArray(report?.filter[field])) {
            report?.filter[field].forEach((filter: string, index: number) => {
                label += ' "' + filter + '"' + (index !== report?.filter[field].length - 1 ? "," : "");
            });

        } else if (isObject(report?.filter[field]) && name) {
            let text = "";
            if (field === "customFields") {
                const customFound = contextCustomFields.find(x => x.id === name);

                text = customFound!.name.find(x => x.culture === currentLang.value)?.text || customFound!.name[0].text || "";
            } else {
                text = name;
            }
            label = text + ': "' + (report?.filter[field] as Record<string, string>)[name] + '"';
        } else label += ' "' + report?.filter[field] + '"';

        return label;
    };

    const [singleFilters, arrayFilters, objectFilters] = useMemo(() => {

        const [single, array, object]: [string[], string[], string[]] = [[], [], []];

        if (report?.filter)
            Object.keys(report!.filter).forEach((field) => {
                if (showFilter(field)) {
                    if (isArray(report?.filter[field])) array.push(field);
                    else if (isObject(report?.filter[field])) object.push(field);
                    else single.push(field);
                }
            });

        return [single, array, object];
    }, [report, showFilter]);

    const showFilterSection = useMemo(() => {
        return !!(report?.filter && Object.keys(report!.filter).find((filter) => showFilter(filter)));
    }, [report, showFilter]);

    const showRecipientSection = useMemo(() => report!.additionalRecipients.length > 0, [report]);

    const getTranslation = useCallback((column: Fields) => {
        if (!column.isCustomField)
            return `${translate(column.id)}`;

        return getCustomFieldLabel(customFields, column.id, currentLang);

    }, [currentLang, customFields, translate]);

    return (report &&
        <>
            <Card sx={{ mb: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
                    <Stack>
                        <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1.5, mb: 2 }}>
                            <Typography variant="h3">
                                {report.name}
                            </Typography>

                            <Typography variant="body2" sx={{ pl: 1 }}>
                                {`${translate(`commons.createdOn`)} `}
                                <b>{dayjs(report.createdOn).format('DD MMM YYYY, HH:mm')}</b>
                            </Typography>

                            <Label
                                color={getStatusColor()}
                                sx={{ textTransform: 'uppercase' }}
                            >
                                {`${translate(`statuses.${status![0].toLowerCase() + status!.slice(1)}`)}`}
                            </Label>
                        </Box>

                        <Box sx={{ mb: 1, display: 'flex', alignItems: 'baseline', gap: 1 }}>
                            <Typography variant="h6">
                                {`${translate(`report.tableHeaders.reportType`)}`} :
                            </Typography>

                            <Typography variant="body1">
                                {type && `${translate(`report.types.${type[0].toLowerCase() + type.slice(1)}`)}`}
                            </Typography>
                        </Box>

                        {!isScheduled &&
                            <Box sx={{ mb: 1, display: 'flex', alignItems: 'baseline', gap: 1 }}>
                                <Typography variant="h6">
                                    {`${translate(`report.tableHeaders.recurringType`)}`} :
                                </Typography>

                                <Typography variant="body1">
                                    {`${translate(`report.types.${report.recurringType[0].toLowerCase() + report.recurringType.slice(1)}`)}`}
                                </Typography>
                            </Box>
                        }

                        <Box sx={{ mb: 0.5, display: 'flex', alignItems: 'baseline', gap: 1 }}>
                            <Typography variant="h6">
                                {`${translate(`commons.description`)}`} :
                            </Typography>

                            <Typography variant="body1" sx={{ pl: report.description ? 0 : 3 }}>
                                {report.description || "─"}
                            </Typography>
                        </Box>
                        <Box sx={{ mb: 0.5, display: 'flex', alignItems: 'baseline', gap: 1 }}>
                            <Typography variant="h6">
                                {`${translate(`commons.culture`)}`} :
                            </Typography>

                            <Typography variant="body1" >
                                {allLangs.find(v => v.locale.code === report.overrideCulture)?.label || currentLang.label}
                            </Typography>
                        </Box>
                    </Stack>

                    <Box sx={{ display: 'flex' }}>
                        <Button
                            variant="contained"
                            size="small"
                            sx={{ mr: 1, mt: 1 }}
                            onClick={handleEdit}
                        >
                            {`${translate(isScheduled ? "report.form.title.reschedule" : "commons.edit")}`}
                        </Button>

                        {isScheduled && report.downloadUri &&
                            <Button
                                variant="contained"
                                size="small"
                                sx={{ mr: 1, mt: 1 }}
                                onClick={handleDownload}
                            >
                                {`${translate("report.detail.tabs.download")}`}
                            </Button>
                        }

                    </Box>
                </Box>
            </Card>

            <Card sx={{ p: 2 }}>
                <Box sx={{ mb: (showRecipientSection || showFilterSection ? 3 : 0) }}>
                    <Typography variant="h6">
                        {`${translate('report.form.reportColumns')}`}
                    </Typography>
                    {report.fields.map((column) => (
                        <Chip
                            sx={{ m: '5px', "& .MuiChip-label": { fontSize: 14 } }}
                            key={"column.key." + column.id}
                            label={getTranslation(column)}
                        />
                    ))}
                </Box>

                {showRecipientSection &&
                    <Box sx={{ mb: showFilterSection ? 3 : 0 }}>
                        <Typography variant="h6">
                            {`${translate('report.form.recipients')}`}
                        </Typography>
                        {report.additionalRecipients.map((recipient) => (
                            <Chip
                                sx={{ m: '5px', "& .MuiChip-label": { fontSize: 14 } }}
                                key={"recipient.key." + recipient}
                                label={recipient}
                                icon={<PersonIcon />}
                            />
                        ))}
                    </Box>
                }

                {showFilterSection &&
                    <Box>
                        <Typography variant="h6">
                            {`${translate('commons.filters')}`}
                        </Typography>

                        {singleFilters.map((filter) =>
                            <Chip
                                sx={{ m: '5px', "& .MuiChip-label": { fontSize: 14 } }}
                                key={"filter.key." + filter}
                                label={getFilterLabel(filter)}
                                icon={<FilterAltIcon />}
                            />
                        )}

                        {arrayFilters.map((filter, index) =>
                            <Box key={"filter.array.box." + filter}>
                                {singleFilters.length > 0 && <Divider sx={{ my: 1 }} />}
                                <Box sx={{ display: "flex" }}>
                                    <Chip
                                        sx={{ m: '5px', "& .MuiChip-label": { fontSize: 14 } }}
                                        key={"filter.array.key." + filter}
                                        label={filter + ":"} //`${translate(`report.columns.${column}`)}`
                                        icon={<FilterAltIcon />}
                                    />
                                    <Box>
                                        {report?.filter[filter].map((field: string) =>
                                            <Chip
                                                sx={{ m: '5px', "& .MuiChip-label": { fontSize: 14 }, bgcolor: alpha(theme.palette.primary.light, 0.6) }}
                                                key={"filter.array.key." + filter + "." + field}
                                                label={field}
                                            />
                                        )}
                                    </Box>
                                </Box>
                                {(index < arrayFilters.length - 1 || objectFilters.length > 0) && <Divider sx={{ my: 1 }} />}
                            </Box>
                        )}

                        {objectFilters.map((filter, index) =>
                            <Box key={"filter.object.box." + filter}>
                                <Box sx={{ display: "flex" }}>
                                    <Chip
                                        sx={{ m: '5px', "& .MuiChip-label": { fontSize: 14 } }}
                                        key={"filter.object.key." + filter}
                                        label={filter + ":"} //`${translate(`report.columns.${column}`)}`
                                        icon={<FilterAltIcon />}
                                    />
                                    <Box>
                                        {Object.keys(report?.filter[filter]).map((field: string) =>
                                            <Chip
                                                sx={{ m: '5px', "& .MuiChip-label": { fontSize: 14 }, bgcolor: alpha(theme.palette.primary.light, 0.6) }}
                                                key={"filter.object.key." + filter + "." + field}
                                                label={getFilterLabel(filter, field)}
                                            />
                                        )}
                                    </Box>
                                </Box>
                                {index < objectFilters.length - 1 && <Divider sx={{ my: 1 }} />}
                            </Box>
                        )}
                    </Box>
                }
            </Card>
        </>
    );
}