import Page from "src/appComponents/Page";
import LoadingScreen from "src/appComponents/loading-screen";
import HeaderBreadcrumbs from '../../../../components/custom-breadcrumbs';
import { Container } from "@mui/system";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useLocales } from "src/locales";
import { useEffect, useState } from "react";
import { useSettingsContext } from "src/components/settings";
import { useNavigate, useParams } from "react-router";
import { DEFAULT_ROLE, Role } from "src/@types/role";
import { administrationRoleDetail, administrationRoleUpdate } from "src/services/roleServices";
import { Box, Button, Card, FormHelperText } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ClusterPermissionTypes } from "src/@types/permissions";
import PermissionTable from "src/components/table/PermissionTable";
import { setSuccessMessage } from "src/redux/modal/modal-slices";
import { dispatch } from "src/redux/store";

export default function OrganizationRoleEdit() {

    const { translate, currentLang } = useLocales();

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [type, setType] = useState<string>('Users');

    const { themeStretch } = useSettingsContext();

    const { id } = useParams();

    const navigate = useNavigate();

    const [role, setRole] = useState<Role>(DEFAULT_ROLE);

    useEffect(() => {
        async function getRole(id: string) {
            try {
                const response = await administrationRoleDetail(id);

                setRole(response.data);
            }
            catch (e) {
                console.error(e);
            }
        }

        let roleId = id ? id : '';
        getRole(roleId);
    }, [id, currentLang]);

    useEffect(() => {
        if (role.id) setIsLoading(false);
    }, [role.id, currentLang]);

    const handleSave = async () => {
        const param = {
            name: role.name,
            permissions: role.permissions,
            id: role.id,
            enabled: role.enabled
        };

        await administrationRoleUpdate(param);

        dispatch(setSuccessMessage({ text: translate('role.messages.updated'), returnTo: PATH_DASHBOARD.tenant.role.admin.list }));
    };

    return (
        <Page title={`${translate('role.form.edit.admin')}`}>
            {isLoading ? (
                <LoadingScreen />
            ) : (
                <Container maxWidth={themeStretch ? false : 'lg'}>

                    <HeaderBreadcrumbs
                        heading={role.name}
                        links={[
                            { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                            { name: `${translate('role.title_plural')}`, href: PATH_DASHBOARD.tenant.role.admin.list },
                            { name: `${translate('commons.edit')}` },
                            { name: `${role.name}` },
                        ]}
                    />

                    <Card sx={{ p: 3 }}>

                        <PermissionTable
                            onChange={(per) => setRole(old => ({ ...old, permissions: (per as ClusterPermissionTypes[]) }))}
                            isCluster
                            defaultValue={role.permissions}
                        />

                        <Box hidden={role.permissions.length > 0}>
                            <FormHelperText sx={{ pb: 1.2, textAlign: "right !important" }} error>
                                {`${translate('role.validation.oneRole')}`}
                            </FormHelperText>
                        </Box>

                        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                            <Button variant="soft" color="inherit" onClick={() => navigate(PATH_DASHBOARD.tenant.role.admin.list)}>
                                {`${translate("commons.cancel")}`}
                            </Button>
                            <LoadingButton disabled={role.permissions.length === 0} type="submit" variant="contained" onClick={handleSave}>
                                {`${translate("role.form.update")}`}
                            </LoadingButton>
                        </Box>

                    </Card>
                </Container>)}
        </Page>

    );
}