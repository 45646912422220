import { Box, Tab, Tabs, TextField, Select, MenuItem, FormControl, InputLabel, TextFieldProps } from "@mui/material";
import { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import { useLocales } from "src/locales";
import CloseIcon from '@mui/icons-material/Close';
import { LocalizedString } from "src/@types/commons";
import { alpha, useTheme } from '@mui/material/styles';
import { getCountries } from "./LocalizedInput";

interface GenericLocalizedInputProps {
    name: string,
    label: string,
    setValue: (name: string, value: any) => void,
    tabs: LocalizedString[],
    lang: string,
    onChangeLang: (lang: string) => void,
    disabled?: boolean,
    textFieldProps?: TextFieldProps
}

export default function GenericLocalizedInput({ name, label, setValue, tabs, lang, onChangeLang, disabled, textFieldProps }: GenericLocalizedInputProps) {

    const theme = useTheme();

    const { allLangs, translate } = useLocales();

    const [tabValue, setTabValue] = useState<number>(0);

    const [defaultText, setDefaultText] = useState<string | null>(tabs.length > 0 ? tabs[0].text : "");

    useEffect(() => {
        if (tabValue === 0 && defaultText !== tabs[0].text) {
            setDefaultText(tabs.length > 0 ? tabs[0].text : "");
        }
    }, [tabs]);

    const handleAddLang = () => {
        let newTabs = tabs;

        if (getCountries(null, tabs, allLangs).length > 0) {
            newTabs.push({ text: '', culture: getCountries(null, tabs, allLangs)[0].value });
            onChangeLang && onChangeLang(newTabs.slice(-1)[0].culture);
        }

        setValue(name, newTabs);

        setDefaultText("");
    };

    const handleDeleteLang = (e: any, index: any) => {

        e.stopPropagation();

        const newTabs = tabs.filter((_, i) => i !== index);

        setValue(name, newTabs);

        let tmp = tabValue;

        if (tabValue >= index) {
            tmp--;

            setDefaultText(newTabs[tmp].text);

            setTabValue(tmp);
            if ((tmp) === 0) onChangeLang('en');
            else if (tabs[tmp]) onChangeLang(tabs[tmp].culture);
        }
    };

    const handleChangeLang = (e: any, index: any) => {
        setDefaultText(tabs[index] ? tabs[index].text : "");

        setTabValue(index);

        if (index === 0) onChangeLang('en');
        else if (tabs[index]) onChangeLang(tabs[index].culture);
    };

    const fullSetValue = (value: string) => {
        const langIndex = tabs.findIndex((tab) => tab.culture === lang);

        const newTabs = tabs.splice(0, langIndex).concat([{ culture: lang, text: value }]).concat(tabs.splice(langIndex + 1, tabs.length - 1));

        setValue(name, newTabs);
    };

    const handleDefaultText = (e: any) => {
        setDefaultText(e.target.value);
        fullSetValue(e.target.value);
    };

    const handleChangeCulture = (e: any, index: number) => {

        const newTabs = tabs.map((tab, i) => {
            if (i === index) {
                onChangeLang(e.target.value);

                return { ...tab, culture: e.target.value };
            }

            return tab;
        }) as LocalizedString[];

        setValue(name, newTabs);
    };

    return (
        <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleChangeLang}>

                    {tabs.map((value, index) =>
                        <Tab
                            key={index}
                            label={value.culture}
                            icon={<>{value.culture !== "en" && <CloseIcon onClick={(e) => handleDeleteLang(e, index)} />}</>}
                        />
                    )}

                    {getCountries(null, tabs, allLangs).length > 0 && <Tab label={<AddIcon />} onClick={handleAddLang} />}

                </Tabs>
            </Box>

            {tabs.map((value, index) => (
                <TabPanel key={index} value={tabValue} index={index}>
                    {value.culture !== "en" &&
                        <FormControl fullWidth>
                            <InputLabel>{`${translate('commons.culture')}`}</InputLabel>
                            <Select
                                name={'culture'}
                                label={`${translate('commons.culture')}`}
                                value={value.culture}
                                onChange={(e) => handleChangeCulture(e, index)}
                                sx={{ mb: 3 }}
                            >
                                {getCountries(value, tabs, allLangs).map((l) => (
                                    <MenuItem key={l.icon} value={l.value}>{l.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    }

                    {textFieldProps ?
                        <TextField
                            sx={{
                                '& .MuiInputLabel-asterisk': { color: 'error.main' },
                                '& .MuiOutlinedInput-root': { '& fieldset': { background: disabled ? alpha(theme.palette.grey[300], 0.20) : "" } }
                            }}
                            value={defaultText}
                            label={label}
                            onChange={(e) => handleDefaultText(e)}
                            {...textFieldProps}
                            disabled={!!disabled}
                        />
                        : <TextField
                            sx={{
                                '& .MuiInputLabel-asterisk': { color: 'error.main' },
                                '& .MuiOutlinedInput-root': { '& fieldset': { background: disabled ? alpha(theme.palette.grey[300], 0.20) : "" } }
                            }}
                            value={defaultText}
                            label={label}
                            onChange={(e) => handleDefaultText(e)}
                            fullWidth
                            multiline
                            rows={3}
                            disabled={!!disabled}
                        />}
                </TabPanel>
            ))}

        </Box>
    );
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3, pl: 0 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}