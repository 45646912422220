import Container from '@mui/material/Container';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import Page from 'src/appComponents/Page';
import useLocales from 'src/appHooks/useLocales';
import { useSettingsContext } from 'src/components/settings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { dispatch, RootState } from 'src/redux/store';
import { FetchDataFuncArgs } from 'src/@types/logs';
import Logs from 'src/appComponents/logs/Logs';
import { requestOperations } from 'src/redux/request';

const LABELS = [
    "ReturnRequestCreated",
    "ReturnRequestUpdated",
    "ReturnRequestApproved",
    "ReturnRequestRefused",
    "ReturnRequestPrinted",
    "ReturnRequestReadyToForPickUp",
    "ReturnRequestPickedUp",
    "ReturnRequestAccepted",
    "ReturnRequestReturnedToCustomer",
    "ReturnRequestCloseAnomaly",
    "ReturnRequestWaitingForInvestigation",
    "ReturnRequestRulePartUpdated",
    "ReturnRequestRulePartCategoryUpdated",
    "ReturnRequestRuleInvoiceUpdated",
    "ReturnRequestRuleFrequencyUpdated",
    "ReturnRequestRuleSurveillanceUpdated",
    "returnRequestMediaUploaded",
    "returnRequestToBeAmended",
    "returnRequestMediaRemoved"
];

interface RequestLogsProps {
    vendor: boolean,
    carrier: boolean
}

export default function RequestLogs({ vendor, carrier }: RequestLogsProps) {

    const { themeStretch } = useSettingsContext();

    const isDesktop = useResponsive('up', 'sm');

    const { translate, currentLang } = useLocales();

    const { isLogsLoading, requestLogs, request } = useSelector((state: RootState) => state.request);

    const { id = '' } = useParams();

    useEffect(() => {
        if (request?.id !== id) {
            dispatch(requestOperations.getRequest({ id: id }));
        }
    }, [id, currentLang, request]);

    const fetchLogs = (searchFilters: FetchDataFuncArgs) => {
        dispatch(requestOperations.getRequestsLogs({ id: id, filters: searchFilters }));
    };

    //-----LABELS

    const createLabels = useCallback(() => {
        return LABELS.map((label) => ({
            label: `${translate(`request.logs.eventTypes.${label.charAt(0).toLowerCase() + label.slice(1)}`)}`,
            key: label,
        }));
    }, [translate]);

    const typesLabels = useMemo(
        () => [
            {
                label: `${translate(`commons.all`)}`,
                key: 'all',
            },
            ...createLabels(),
        ],
        [createLabels, translate]
    );

    return (
        <Page title={`${translate('request.logs.title')}`}>
            <Container maxWidth={themeStretch ? false : 'lg'} sx={{ p: 0 }}>
                <HeaderBreadcrumbs
                    heading={`${translate('request.logs.title')}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate('commons.request')}`, href: vendor ? PATH_DASHBOARD.request.vendor.list : carrier ? PATH_DASHBOARD.request.carrier.list : PATH_DASHBOARD.request.customer.list },
                        { name: `${request?.prettyName}`, href: vendor ? PATH_DASHBOARD.request.vendor.backlogDetail(id) : carrier ? PATH_DASHBOARD.request.carrier.backlogDetail(id) : PATH_DASHBOARD.request.customer.detail(id) },
                        { name: `${translate('commons.logs')}` }
                    ]}
                    sx={{ px: isDesktop ? 5 : 2, mb: isDesktop ? 5 : 2 }}
                />

                <Logs
                    tableData={requestLogs as any}
                    isLoading={isLogsLoading}
                    id={id!}
                    header={`${translate(`commons.logs`)} `}
                    subheader={`${translate(`request.logs.subtitle`)}`}
                    labels={typesLabels}
                    fetchData={fetchLogs}
                    localesPath={"request"}
                />
            </Container>
        </Page>
    );
}
