import { Card, Divider, Stack, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { FileWithSection } from "src/@types/media";
import StepButtons from "src/components/buttons/StepButtons";
import { Upload } from "src/components/upload";
import { useLocales } from "src/locales";

interface StockAvailabilityStepProps {
    changeStep: Dispatch<SetStateAction<number>>,
    onFileChange: (f: FileWithSection[]) => void
}

export default function StockAvailabilityStep({ changeStep, onFileChange }: StockAvailabilityStepProps) {

    const { translate } = useLocales();

    const [file, setFile] = useState<File | null>(null);

    return (
        <Card sx={{ p: 3 }}>
            <Stack sx={{ my: 2 }}>
                <Typography variant="h6">{translate("webshop.form.stockAvailability")}</Typography>
                <Typography variant="body2">{translate("webshop.messages.stockSubtitle")}</Typography>
            </Stack>
            <Upload
                accept={{
                    'text/csv': ['.csv'],
                }}
                maxFiles={1}
                onDropAccepted={(f) => {
                    setFile(f[0] ?? null);
                    onFileChange(f);
                }}
                file={file}
            />
            <Divider sx={{ my: 3 }} />
            <StepButtons changeStep={changeStep} />
        </Card>
    );
}