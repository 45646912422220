export const dateRegExp = new RegExp(/^$|^([0-9]{4}|[0-9]{2})[.-]([0]?[1-9]|[1][0-2])[.-]([0]?[1-9]|[1|2][0-9]|[3][0|1])$/);

export const timeRegExp = new RegExp(/^$|^([0-9]?[0-9]):([0-5]?[0-9]):([0-5]?[0-9])$/);

export const emptyRegExp = new RegExp(/^(?!\s*$).+/);

export const emailRegExp = new RegExp(/^((([!#$%&'*+\-/=?^_`{|}~\w])|([!#$%&'*+\-/=?^_`{|}~\w][!#$%&'*+\-/=?^_`{|}~\.\w]{0,}[!#$%&'*+\-/=?^_`{|}~\w]))[@]\w+([-.]\w+)*\.\w+([-.]\w+)*)$/);

//export const emailRegExp = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);

export const faxRegExp = new RegExp(/^(\+?\d{1,}(\s?|\-?)\d*(\s?|\-?)\(?\d{2,}\)?(\s?|\-?)\d{3,}\s?\d{3,})$/);

export const phoneNumberRegExp = new RegExp(/^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/);

export const zipCodeRegExp = new RegExp(/^((\d{5}-\d{4})|(\d{5})|([A-Z]\d[A-Z]\s\d[A-Z]\d))$/);

export const nameRegExp = new RegExp(/^[A-Za-zÀ-ÖØ-öø-ÿ\s]*$/); //Only letter and spaces

export const usernameRegExp = new RegExp(/^[a-zA-Z0-9_.]*$/); //Only letters, numbers, point and underscore

export const usernameLenRegExp = new RegExp(/^.{5,15}$/); // 5 <= len <= 15

export const IBANRegExp = new RegExp(/^(?=.{4,34}$)[A-Z]{2}\d{2}[A-Z\d]{1,30}$/);

