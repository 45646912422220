import { isArray, isPlainObject } from "lodash";

export default function removeEmptyKeys(value: any): any {
    const objResult = {} as { [key: string]: any };

    const arrResult = [] as any[];

    if (isPlainObject(value)) {
        for (let key in value) {
            if (key === 'notes') {
                objResult[key] = value[key as keyof typeof value];
                continue;
            }
            
            objResult[key] = removeEmptyKeys(value[key as keyof typeof value]);
        }

        return objResult;
    }
    if (isArray(value)) {
        value.forEach((element: any) => {
            arrResult.push(removeEmptyKeys(element));
        });

        return arrResult;
    }
    if (value === '' || value === undefined) {
        return null;
    }

    return value;
}