import { Box, Card, CircularProgress, Tab, Tabs, Typography, alpha } from "@mui/material";
import { DeliveryNote, DocData, Invoice } from "src/@types/orders";
import { useLocales } from "src/locales";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useAuth } from "src/appHooks/useAuth";
import { hasPermissions } from "src/utils/user";
import { OrganizationPermissionTypes } from "src/@types/permissions";
import { RootState, dispatch, useSelector } from "src/redux/store";
import { useParams } from "react-router";
import { useUserOrganizationContext } from "src/contexts/UserOrganizationContext";
import { ordersOperations } from "src/redux/orders";
import { RequestItem } from "src/@types/request";
import { DocumentPrintInABox } from "../documents/detail/DocumentPrint";

interface BacklogDoumentDetailProps {
    document: Invoice | DeliveryNote | undefined,
    setDocument: (doc: Invoice | DeliveryNote) => void,
    invoicesList: DocData[],
    deliveryNotesList: DocData[],
    itemsList: RequestItem[]
}

const TabsContentStyle = {
    width: "81%",
    bgcolor: 'background.paper'
};

const TabsStyle = {
    justifyContent: 'flex-start',
    borderRadius: '8px',
    '&:not(:last-of-type)': { mr: 1 },
    "&.Mui-selected": { color: "primary.main" },
    py: '13px',
    px: 2,
    m: 1,
};

export default function BacklogDoumentDetail({ document, setDocument, invoicesList, deliveryNotesList, itemsList }: BacklogDoumentDetailProps) {

    const { translate } = useLocales();

    const { user } = useAuth();

    const { request, anomaly } = useSelector((state: RootState) => state.request);

    const { isDocumentLoading } = useSelector((state: RootState) => state.orders);

    const { organization } = useUserOrganizationContext();

    const { idRequest, id } = useParams();

    const [tabValue, setTabValue] = useState<"invoice" | "deliveryNote">(invoicesList.length > 0 ? "invoice" : "deliveryNote");

    const [searchForDoc, setSearchForDoc] = useState(invoicesList.length > 0 ? invoicesList[0].documentId : (deliveryNotesList.length > 0 ? deliveryNotesList[0].documentId : ""));

    const handleChange = (e: SyntheticEvent, newVal: "invoice" | "deliveryNote") => {
        setTabValue(newVal);
        setSearchForDoc(newVal === "invoice" ? (invoicesList.length > 0 ? invoicesList[0].documentId : "") : (deliveryNotesList.length > 0 ? deliveryNotesList[0].documentId : ""));
    };

    useEffect(() => {
        if (((request && idRequest && request.id === idRequest) || (anomaly && id && anomaly.id === id)) &&
            hasPermissions([OrganizationPermissionTypes.WebShop_Orders_VendorView], organization.roles) && itemsList.length > 0) {
            if (tabValue === "invoice" && searchForDoc) {
                dispatch(ordersOperations.getInvoice(searchForDoc)).unwrap().then(v => setDocument(v));
            } else if (tabValue === "deliveryNote" && searchForDoc) {
                dispatch(ordersOperations.getDeliveryNote(searchForDoc)).unwrap().then(v => setDocument(v));
            }
        }
    }, [organization, anomaly, id, itemsList, request, user, idRequest, setDocument, tabValue, searchForDoc]);

    const tabs = useMemo(() => [
        (
            <Box key={"invoice"} sx={{ ...TabsContentStyle }}>
                {invoicesList.length > 1 &&
                    <DocsTabs content={invoicesList} type={"invoice"} changeDoc={setSearchForDoc} />
                }
                {isDocumentLoading ?
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "75vh"
                        }}
                    >
                        <CircularProgress sx={{ "& .MuiCircularProgress-svg": { transform: "scale(2.5)" } }} />
                    </Box>
                    :
                    (document &&
                        <DocumentPrintInABox document={document} type={"Invoice"} />
                    )
                }
            </Box>
        ),
        (
            <Box key={"deliveryNote"} sx={{ ...TabsContentStyle }}>
                {deliveryNotesList.length > 1 &&
                    <DocsTabs content={deliveryNotesList} type={"deliveryNote"} changeDoc={setSearchForDoc} />
                }
                {isDocumentLoading ?
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "75vh"
                        }}
                    >
                        <CircularProgress sx={{ "& .MuiCircularProgress-svg": { transform: "scale(2.5)" } }} />
                    </Box>
                    :
                    (document &&
                        <DocumentPrintInABox document={document} type={"DeliveryNote"} />
                    )
                }
            </Box>
        )
    ], [isDocumentLoading, document, invoicesList, deliveryNotesList]);

    return (
        <Card sx={{ flexGrow: 1, display: 'flex' }}>
            <Tabs
                orientation='vertical'
                value={tabValue}
                onChange={handleChange}
                TabIndicatorProps={{ style: { display: 'none' } }}
                sx={{
                    bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
                    minWidth: "19%",
                    maxHeight: "19%"
                }}
            >
                {invoicesList.length > 0 &&
                    < Tab
                        value={"invoice"}
                        id={"invoice"}
                        label={`${translate('orders.tableHeaders.invoice')}`}
                        sx={{
                            ...TabsStyle,
                            color: tabValue === "invoice" ? 'primary' : '',
                            backgroundColor: tabValue === "invoice" ? 'primary.lighter' : ''
                        }}
                    />
                }

                {deliveryNotesList.length > 0 &&
                    <Tab
                        value={"deliveryNote"}
                        id={"deliveryNote"}
                        label={`${translate('documents.types.deliveryNote')}`}
                        sx={{
                            ...TabsStyle,
                            color: tabValue === "deliveryNote" ? 'primary' : '',
                            backgroundColor: tabValue === "deliveryNote" ? 'primary.lighter' : ''
                        }}
                    />
                }

            </Tabs>

            {tabs[tabValue === "invoice" ? 0 : 1]}

        </Card>
    );
}

//---------------------------------------------------------------------------------------------

interface DocsTabsProps {
    content: DocData[],
    type: "invoice" | "deliveryNote",
    changeDoc: (value: string) => void
}

function DocsTabs({ content, type, changeDoc }: DocsTabsProps) {

    const { translate } = useLocales();

    return (
        <Box
            display="grid"
            gridTemplateColumns={{
                xs: 'repeat(2, 1fr)',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(3, 1fr)'
            }}
            gap={3}
            sx={{ p: 3 }}
        >
            {content.map((doc) =>
                <Card
                    key={doc.documentId}
                    onClick={() => changeDoc(doc.documentId)}
                    sx={{ cursor: "pointer", display: "flex", flexDirection: "column", alignItems: "center" }}
                >
                    <Box
                        display={"flex"}
                        sx={{ width: "100%", py: 0.5, backgroundColor: (theme) => theme.palette.mode === "light" ? '#edf2f7' : alpha('#F9FAFB', 0.25) }}
                    >
                        <Typography
                            variant="subtitle1"
                            sx={{ mx: "auto", color: 'text.primary', fontWeight: '800' }}
                        >
                            {`${translate('spareParts.list.tableHeaders.partNr')} ` + doc.partExternalId}
                        </Typography>
                    </Box>

                    <Typography
                        variant="body2"
                        sx={{ px: 1.5, py: 1, color: 'text.primary', fontWeight: '500' }}
                    >
                        {`${translate(`documents.detail.${type}N`)}. ` + doc.documentExternalId}
                    </Typography>
                </Card>
            )}
        </Box>
    );
}