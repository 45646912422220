import { Box, Container, Typography } from "@mui/material";
import { useBarcode } from "next-barcode";
import { useMemo } from "react";
import { Container as ContainerT } from "src/@types/container";
import { Logo } from "src/appComponents/logo";
import { useUserOrganizationContext } from "src/contexts/UserOrganizationContext";
import { useLocales } from "src/locales";

interface ContainerLabelProps {
    container: ContainerT
}

export default function ContainerLabel({ container }: ContainerLabelProps) {

    const { translate } = useLocales();

    const { organization } = useUserOrganizationContext();

    const quantity = useMemo(() => {
        let qty = 0;

        container.items.forEach(v => qty += v.quantity);
        container.extraItems.forEach(v => qty += v.quantity);

        return qty;
    }, [container]);

    const { inputRef } = useBarcode({
        value: container.name,
        options: { displayValue: true }
    });

    return (
        <Container sx={{ p: 2 }}>
            <Logo disabledLink theme="light" />
            <Box sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
                mt: 4,
                gap: 5
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4 }}>
                    <canvas ref={inputRef} />

                </Box>
                <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                    <Typography>
                        {`${translate('organization.types.hub')}`}: {organization.externalId ? (organization.externalId + " " + organization.name) : organization.name}
                    </Typography>
                    <Typography>
                        {`${translate('commons.quantity')}`}: {quantity}
                    </Typography>
                </Box>
            </Box>
        </Container>
    );
}