import { Accordion, AccordionDetails, AccordionSummary, Card, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useMemo, useState } from "react";
import { ExpandMore } from "@mui/icons-material";
import { lokalizableRoleStr } from "src/pages/dashboard/role/utils";
import { useLocales } from "src/locales";
import { ClusterPermissionsList, ClusterPermissionTypes, OrganizationPermissionsList, OrganizationPermissionTypes } from "src/@types/permissions";

interface InfoCardProps {
    list: string[]
}

export default function DetailPermissionsList({ list }: InfoCardProps) {

    const { translate } = useLocales();

    const permissions = useMemo(() => {

        const allPermissions = { ...ClusterPermissionsList, ...OrganizationPermissionsList };

        Object.keys(allPermissions).forEach((permissionType) => {
            allPermissions[permissionType] = allPermissions[permissionType].filter((permission) => list.includes(permission)) as ClusterPermissionTypes[] | OrganizationPermissionTypes[];

            if (allPermissions[permissionType].length === 0) delete allPermissions[permissionType];
        });

        return allPermissions;

    }, [list]);

    const [permissionOpen, setPermissionOpen] = useState<string>(Object.keys(permissions)[0] ?? "");

    return (
        <Card
            sx={{
                my: 3,
                border: "2px solid #E2E2E2",
                "& .Mui-expanded": { borderRadius: "0px !important" }
            }}
        >
            {Object.keys(permissions).map((permissionType, index) =>
                <Accordion
                    disableGutters
                    key={"permission." + index}
                    sx={{ overflowY: 'auto' }}
                    expanded={permissionType === permissionOpen}
                >
                    <AccordionSummary
                        sx={{
                            backgroundColor: (theme) => theme.palette.mode === "light" ? '#F4F6F8' : '#29313a',
                            borderTop: `${index === 0 ? 0 : 1}px solid #E2E2E2`
                        }}
                        onClick={() => permissionType !== permissionOpen ? setPermissionOpen(permissionType) : setPermissionOpen("")}
                        expandIcon={<ExpandMore />}
                    >
                        <Box sx={{
                            display: 'flex',
                            maxHeight: '40vh',
                            overflowY: 'auto',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%'
                        }}>
                            <Typography variant="h6">
                                {translate(`role.type.${permissionType.charAt(0).toLowerCase() + permissionType.slice(1)}`)}
                            </Typography>
                        </Box>
                    </AccordionSummary>

                    <AccordionDetails sx={{ p: 0, borderTop: "2px solid #E2E2E2 !important" }}>
                        <Box sx={{ maxHeight: "25vh", overflowY: "auto" }}>
                            {permissions[permissionType].map((permission, index) => {

                                const lokalizableStr = lokalizableRoleStr(permission);

                                return (
                                    <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
                                        <Box width={"33%"} sx={{ backgroundColor: (theme) => theme.palette.mode === "light" ? '#F4F6F8' : '#29313a', p: 2 }}>
                                            <Typography variant="body2">
                                                {`${translate(`role.permission.${lokalizableStr}.title`)}`}
                                            </Typography>
                                        </Box>
                                        <Box width={"67%"} sx={{ px: 1.5 }}>
                                            <Typography variant="subtitle2">
                                                {`${translate(`role.permission.${lokalizableStr}.description`)}`}
                                            </Typography>
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            )}
        </Card>
    );
}