import { Button } from '@mui/material';
import Iconify from 'src/components/iconify';
import { useLocales } from 'src/locales';
import { DEFAULT_ORGANIZATION_FILTERS, OrganizationFilters } from 'src/@types/organizations';
import { useSettingsContext } from 'src/components/settings';
import { CustomFieldsFilterChip, FilterChip, MultipleFilterChip, RootStyle } from 'src/utils/SummaryUtils';

type SummaryProps = {
    filters: OrganizationFilters,
    isShowReset: boolean,
    onResetAll: VoidFunction,
    onResetFilter: (fieldName: string, value?: any) => void,
    show: boolean,
    MAX_BALANCE_CONVERTED: number,
    MAX_LIMIT_CONVERTED: number
}

export default function OrganizationFilterSummary({ filters, isShowReset, onResetAll, onResetFilter, show, MAX_BALANCE_CONVERTED, MAX_LIMIT_CONVERTED }: SummaryProps) {

    const { translate } = useLocales();

    const { currency: currentCurrency } = useSettingsContext();

    const { externalId, name, type, vat, email, minCreditBalance, maxCreditBalance, minCreditLimit, maxCreditLimit, tags, customFields, isMonitored } = filters;

    return (
        <RootStyle sx={{ mb: 2, ml: 2 }}>
            {show &&
                <>
                    {name && name !== DEFAULT_ORGANIZATION_FILTERS.name &&
                        <FilterChip
                            el={"commons.name"}
                            value={name}
                            onResetFilter={onResetFilter} />
                    }

                    {vat && vat !== DEFAULT_ORGANIZATION_FILTERS.vat &&
                        <FilterChip
                            el={"organization.detail.vat"}
                            value={vat}
                            onResetFilter={onResetFilter} />
                    }

                    {isMonitored && isMonitored !== DEFAULT_ORGANIZATION_FILTERS.isMonitored &&
                        <FilterChip
                            el={"organization.list.filter.surveillance"}
                            value={`${translate("commons.yes")}`}
                            onResetFilter={onResetFilter}
                            toDelete={"isMonitored"}
                        />
                    }

                    {email && email !== DEFAULT_ORGANIZATION_FILTERS.email &&
                        <FilterChip
                            el={"commons.email"}
                            value={email}
                            onResetFilter={onResetFilter} />
                    }

                    {externalId && externalId !== DEFAULT_ORGANIZATION_FILTERS.externalId &&
                        <FilterChip
                            el={"commons.externalId"}
                            value={externalId}
                            onResetFilter={onResetFilter} />
                    }

                    {type && type !== DEFAULT_ORGANIZATION_FILTERS.type &&
                        <FilterChip
                            el={"commons.type"}
                            value={type}
                            onResetFilter={onResetFilter} />
                    }

                    {((minCreditBalance && minCreditBalance > 0) || (maxCreditBalance && maxCreditBalance < MAX_BALANCE_CONVERTED)) &&
                        <FilterChip
                            el={"organization.form.payment.balance"}
                            value={`${currentCurrency.symbol} ${minCreditBalance}k - ${maxCreditBalance}k`}
                            onResetFilter={onResetFilter}
                            toDelete={"creditBalance"}
                        />
                    }

                    {((minCreditLimit && minCreditLimit > 0) || (maxCreditLimit && maxCreditLimit < MAX_LIMIT_CONVERTED)) &&
                        <FilterChip
                            el={"organization.form.payment.creditLimit"}
                            value={`${currentCurrency.symbol} ${minCreditLimit}k - ${maxCreditLimit}k`}
                            onResetFilter={onResetFilter}
                            toDelete={"creditLimit"}
                        />
                    }

                    {(tags && tags.length > 0) &&
                        < MultipleFilterChip
                            el={"commons.tags"}
                            value={tags}
                            onResetFilter={onResetFilter}
                        />
                    }

                    {customFields && Object.entries(customFields).length > 0 &&
                        <CustomFieldsFilterChip
                            context={"organization"}
                            customfieldValues={customFields}
                            onResetFilter={onResetFilter}
                        />
                    }

                    {isShowReset && (
                        <Button
                            color="error"
                            size="small"
                            onClick={onResetAll}
                            startIcon={<Iconify icon={'ic:round-clear-all'} />}
                        >
                            {`${translate("commons.clear")}`}
                        </Button>
                    )}
                </>
            }
        </RootStyle >
    );
}