import { Stack, Button, Typography, Box } from '@mui/material';
import { useAuth } from '../../../appHooks/useAuth';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function NavDocs() {

  const { user } = useAuth();

  const { translate } = useLocales();

  return (
    <Stack
      spacing={3}
      sx={{
        px: 5,
        pb: 5,
        mt: 10,
        width: 1,
        display: 'block',
        textAlign: 'center',
      }}
    >
      <Box component="img" src="/assets/illustrations/illustration_docs.svg" />

      <div>
        <Typography gutterBottom variant="subtitle1">
          {`${translate('documentation.hi')}`}, {user?.firstName} {user?.lastName}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>
          {`${translate('documentation.description')}`}
        </Typography>
      </div>

      <Button variant="contained" color='inherit'>
        {`${translate('documentation.documentation')}`}
      </Button>
    </Stack>
  );
}
