import { Box, Button, Card, FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { PropertyCategory } from "src/@types/group";
import { useLocales } from "src/locales";
import { dispatch, RootState } from "src/redux/store";
import DeleteIcon from '@mui/icons-material/Delete';
import { WhiteBlackList } from "../newEditGroup/WhiteBlacklistStep";
import Iconify from "src/components/iconify";
import { groupOperations } from "src/redux/group";
import { UserFilters, UserSearchResult } from "src/@types/user";
import { OrganizationFilters, OrganizationSearchResult } from "src/@types/organizations";
import { hasPermissions } from "src/utils/user";
import { useAuth } from "src/appHooks/useAuth";
import { ClusterPermissionTypes, OrganizationPermissionTypes } from "src/@types/permissions";
import { useUserOrganizationContext } from "src/contexts/UserOrganizationContext";

interface GroupWhiteBlacklistProps {
    isBlacklist?: boolean
}

export default function GroupWhiteBlacklist({ isBlacklist }: GroupWhiteBlacklistProps) {

    const { user } = useAuth();

    const { organization } = useUserOrganizationContext();

    const { translate } = useLocales();

    const { group, blackUserHasFilter, whiteUserHasFilter, blackOrgHasFilter, whiteOrgHasFilter } = useSelector((state: RootState) => state.group);

    const hasUserPermission = useMemo(() => hasPermissions([ClusterPermissionTypes.UserView], user.roles), [user]);

    const hasOrganizationPermission = useMemo(() => hasPermissions([(group && group.groupType === "Organization") ?
        OrganizationPermissionTypes.Organization_View :
        OrganizationPermissionTypes.Customer_View], organization.roles), [group, organization]);

    const [selectSearch, setSelectSearch] = useState<PropertyCategory>(hasUserPermission ? "User" : "Organization");

    const [searchValue, setSearchValue] = useState<string>("");

    const [showDeleteButton, setShowDeleteButton] = useState<boolean>(false);

    const { isWhitelistLoading, userWhitelist, organizationWhitelist, userWhitelistTotalCount, organizationWhitelistTotalCount } = useSelector((state: RootState) => state.group);

    const { isBlacklistLoading, userBlacklist, organizationBlacklist, userBlacklistTotalCount, organizationBlacklistTotalCount } = useSelector((state: RootState) => state.group);

    const totalCount = useMemo(() => {
        if (selectSearch === "User")
            return isBlacklist ? userBlacklistTotalCount : userWhitelistTotalCount;

        return isBlacklist ? organizationBlacklistTotalCount : organizationWhitelistTotalCount;
    }, [isBlacklist, organizationBlacklistTotalCount, organizationWhitelistTotalCount, selectSearch, userBlacklistTotalCount, userWhitelistTotalCount]);

    const getSearchConfirm = (list: (UserSearchResult | OrganizationSearchResult)[] | undefined, resetList: boolean) => {
        return !list || resetList || searchValue;
    };

    const handleSearch = (allValue?: string) => {
        if (group) {

            let options: UserFilters | OrganizationFilters = { pageIndex: 0, pageSize: 100, all: allValue ?? searchValue };

            const resetList = allValue === "";

            if (isBlacklist) {
                options = { ...options, blacklists: group.groupId };

                if (selectSearch === "User" && getSearchConfirm(userBlacklist, resetList)) dispatch(groupOperations.searchGroupBlacklistUsers(options as UserFilters));
                else if (selectSearch === "Organization" && getSearchConfirm(organizationBlacklist, resetList)) dispatch(groupOperations.searchGroupBlacklistOrganizations({ options: options as OrganizationFilters, type: group.groupType }));
            } else {
                options = { ...options, whitelists: group.groupId };

                if (selectSearch === "User" && getSearchConfirm(userWhitelist, resetList)) dispatch(groupOperations.searchGroupWhitelistUsers(options as UserFilters));
                else if (selectSearch === "Organization" && getSearchConfirm(organizationWhitelist, resetList)) dispatch(groupOperations.searchGroupWhitelistOrganizations({ options: options as OrganizationFilters, type: group.groupType }));
            }

            if (allValue ?? searchValue) setShowDeleteButton(true);
        }
    };

    useEffect(() => {
        setSearchValue("");

        setShowDeleteButton(false);

        if (isBlacklist) {
            if ((selectSearch === "User" && blackUserHasFilter) || (selectSearch === "Organization" && blackOrgHasFilter)) handleSearch("");
        } else if ((selectSearch === "User" && whiteUserHasFilter) || (selectSearch === "Organization" && whiteOrgHasFilter)) handleSearch("");
    }, [selectSearch]);

    return (
        <Card>

            <Stack
                direction={{ xs: 'column', sm: 'column', md: 'row' }}
                sx={{ px: 3, pt: 2.5, pb: { xs: 0, md: 2.5 }, display: 'flex', alignItems: 'center' }}
            >

                <FormControl
                    sx={{
                        minWidth: { xs: "100%", md: "30%" },
                        mr: 2, mb: { xs: 2, md: 0 }
                    }}
                    variant="outlined"
                >
                    <InputLabel sx={{ '&.Mui-focused': { color: 'grey.500' } }}>
                        {translate('returns.searchType')}
                    </InputLabel>

                    <Select
                        value={selectSearch}
                        input={<OutlinedInput label={`${translate('returns.searchType')}`} />}
                        onChange={(event) => setSelectSearch(event.target.value as PropertyCategory)}
                    >
                        {hasUserPermission &&
                            <MenuItem value="User" sx={{ mx: 1, my: 0.5, borderRadius: 0.75, typography: 'body2', textTransform: 'capitalize' }}>
                                {translate('commons.users')}
                            </MenuItem>
                        }
                        {hasOrganizationPermission &&
                            <MenuItem value="Organization" sx={{ mx: 1, my: 0.5, borderRadius: 0.75, typography: 'body2', textTransform: 'capitalize' }}>
                                {translate('commons.organizations')}
                            </MenuItem>
                        }
                    </Select>
                </FormControl>

                <TextField
                    type="search"
                    value={searchValue}
                    onChange={(event) => setSearchValue(event.target.value)}
                    placeholder={`${translate('commons.search')}`}
                    sx={{ width: "100%", mr: 2 }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            handleSearch();
                        }
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Iconify
                                    icon={'eva:search-fill'}
                                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                                />
                            </InputAdornment>
                        ),
                    }}
                />

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                    <Button
                        startIcon={<Iconify icon={'bx:search-alt'} sx={{ ml: 1.5, mt: 0.5 }} />}
                        onClick={() => handleSearch()}
                        disabled={!searchValue}
                        size={"large"}
                        sx={{ mt: 0.5, width: { xs: "100%" } }}
                    />

                    <Button
                        startIcon={<DeleteIcon sx={{ ml: 1.5, mt: 0.75 }} />}
                        onClick={() => {
                            setSearchValue("");
                            setShowDeleteButton(false);
                            handleSearch("");
                        }}
                        size={"large"}
                        sx={{
                            display: showDeleteButton ? "inline" : "none",
                            mt: 0.5, width: { xs: "100%" }
                        }}
                    />
                </Box>

            </Stack>

            <WhiteBlackList
                type={selectSearch === "User" ? "Users" : "Organizations"}
                isLoading={isBlacklist ? isBlacklistLoading : isWhitelistLoading}
                totalCount={totalCount}
                list={isBlacklist ?
                    {
                        users: userBlacklist ?? [],
                        organizations: organizationBlacklist ?? []
                    }
                    :
                    {
                        users: userWhitelist ?? [],
                        organizations: organizationWhitelist ?? []
                    }
                }
            />

        </Card>
    );
}