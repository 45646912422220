// @mui
import { useTheme, Breakpoint } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// ----------------------------------------------------------------------

type ReturnType = boolean;

type Query = 'up' | 'down' | 'between' | 'only';

type Value = Breakpoint | number;

export default function useResponsive(query: Query, start?: Value, end?: Value): ReturnType {

  const theme = useTheme();

  let media = theme.breakpoints.only(start as Breakpoint);

  switch (query) {
    case "up":
      media = theme.breakpoints.up(start as Value);
      break;
    case "down":
      media = theme.breakpoints.down(start as Value);
      break;
    case "between":
      media = theme.breakpoints.between(start as Value, end as Value);
      break;
  }

  return useMediaQuery(media);
}

// ----------------------------------------------------------------------

type BreakpointOrNull = Breakpoint | null;

export function useWidth() {

  const theme = useTheme();

  const keys = [...theme.breakpoints.keys].reverse();

  const media = useMediaQuery;

  return (
    keys.reduce((output: BreakpointOrNull, key: Breakpoint) => {

      const matches = media(theme.breakpoints.up(key));

      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}