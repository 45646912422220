import { ConversionType } from "src/@types/metricSystem";

const CONVERSION_TABLE = {
    km: 1.609344,            // 1 mile = 1.609344 km
    mi: 0.62137119223,       // 1 km = 0.6213711922 mile
    m: 3.280839895,          // 1 m = 3.280839895 foot
    foot: 0.3048,            // 1 foot = 0.3048 m
    inch: 12,                // 1 foot = 12 inches
    kg: 2.204623,            // 1 kg = 0.6213711922 mile
    lb: 0.453592,            // 1 lb = 0.453592 kg 
    ltUS: 3.785411784,       // 1 U.S. gal = 3,785411784 lt 
    galUs: 0.264172,         // 1 lt = 0.264172 U.S. gal 
    ltImp: 4.54609,          // 1 imp gal = 4,54609 lt
    galImp: 0.219969        // 1 lt = 0.219969 imp gal
} as { [key: string]: number };

/**
 * Function to convert from Metric system to Imperial system
 * 
 * @param value     value to convert
 * @param type      type of conversion: "distance" (km to miles) / "dimension" (m to feet) / "weight" (kg to lb) / "volume" (lt to gal) / "speed" (km/h to mi/h)
 * @param toMetric  used to convert from Imperial to Metric (default value = false)
 * 
 * @returns         converted value
 */
export default function metricConvert(value: number | string, type: ConversionType, toMetric?: boolean): number {

    let valueConverted = Number(value);

    if (!isNaN(valueConverted)) {

        let conversionValue = 1;
        switch (type) {
            case "speed":
            case "distance":
                conversionValue = toMetric ? CONVERSION_TABLE.km : CONVERSION_TABLE.mi;
                break;
            case "weight":
                conversionValue = toMetric ? CONVERSION_TABLE.kg : CONVERSION_TABLE.lb;
                break;
            case "dimension":
                conversionValue = toMetric ? CONVERSION_TABLE.m : CONVERSION_TABLE.foot;
                break;
            case "volume":
                conversionValue = toMetric ? CONVERSION_TABLE.ltUS : CONVERSION_TABLE.galUs;
                break;
        }
        valueConverted = valueConverted * conversionValue;
    }

    return valueConverted;
}
