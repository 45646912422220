import { Box, FormControlLabel, Switch, Typography } from "@mui/material";
import { useLocales } from "src/locales";

type GenericListFooterProps = {
    dense: boolean,
    onChangeDense: (event: React.ChangeEvent<HTMLInputElement>) => void,
    totalCount: number
};

export default function GenericListFooter({ dense, onChangeDense, totalCount }: GenericListFooterProps) {

    const { translate } = useLocales();

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                justifyContent: { xs: "normal", sm: "space-between" },
                height: { xs: "67px", sm: "56px" },
                minHeight: { xs: "67px", sm: "56px" },
                px: { xs: 2, sm: 4 },
                backgroundColor: "background.neutral"
            }}
        >
            <FormControlLabel
                control={<Switch checked={dense} onChange={onChangeDense} />}
                label={translate('commons.dense')}
            />
            <Typography variant="body2">
                {translate('commons.totalCount') + ": " + totalCount}
            </Typography>
        </Box>
    );
}