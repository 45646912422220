import { Alert } from "@mui/material";
import { CustomContentProps, SnackbarContent, closeSnackbar } from "notistack";
import { forwardRef } from "react";
import { useNavigate } from "react-router";

interface NotificationSnackbarProps extends CustomContentProps {
    link?: string
}

const NotificationSnackbar = forwardRef<HTMLDivElement, NotificationSnackbarProps>((props, ref) => {

    const { message, id, link, ...other } = props;

    const navigate = useNavigate();

    return (
        <SnackbarContent
            ref={ref}
            id={id.toString()}
            className={other.className}
            style={{
                ...other.style,
                borderRadius: '8px'
            }}
        >
            <Alert
                onClick={() => {
                    if (link)
                        navigate(link);
                    closeSnackbar(id);
                }}
                severity='info'
                variant='filled'
                onClose={(e) => {
                    e.stopPropagation();
                    closeSnackbar(id);
                }}
                sx={{
                    cursor: 'pointer',
                    width: '100%',
                    bgcolor: (theme) => theme.palette.primary.dark
                }}
            >
                {message}
            </Alert>
        </SnackbarContent >
    );
});

export default NotificationSnackbar;