import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { Box } from '@mui/material';
import { MatrixResult } from '../../hooks/usePowersearch';

type MatrixSearchTemplateProps = {
  option: MatrixResult;
  searchQuery: string;
};

export default function MatrixSearchTemplate({ option, searchQuery }: MatrixSearchTemplateProps) {
  const titleParts = parse(option?.title || '', match(option?.title || '', searchQuery));

  const statusParts = parse(option?.status || '', match(option?.status || '', searchQuery));

  const pathParts = parse(option?.path, match(option?.path, searchQuery));

  const enabledParts = parse(option?.enabled || '', match(option?.enabled || '', searchQuery));

  return (
    <div>
      {option?.title && (
        <div>
          {titleParts.map((part, index) => (
            <Box
              key={index}
              component="span"
              sx={{
                typography: 'subtitle2',
                textTransform: 'capitalize',
                color: part.highlight ? 'primary.main' : 'text.primary',
              }}
            >
              {part.text}
            </Box>
          ))}
        </div>
      )}

      {option?.status && (
        <div>
          {statusParts?.map((part, index) => (
            <Box
              key={index}
              component="span"
              sx={{
                typography: 'subtitle2',
                textTransform: 'capitalize',
                color: part.highlight ? 'primary.main' : 'text.primary',
              }}
            >
              {part.text}
            </Box>
          ))}
        </div>
      )}

      {option?.enabled && (
        <div>
          {enabledParts?.map((part, index) => (
            <Box
              key={index}
              component="span"
              sx={{
                typography: 'subtitle2',
                textTransform: 'capitalize',
                color: part.highlight ? 'primary.main' : 'text.primary',
              }}
            >
              {part.text}
            </Box>
          ))}
        </div>
      )}

      {option?.path && (
        <div>
          {pathParts?.map((part, index) => (
            <Box
              key={index}
              component="span"
              sx={{
                typography: 'caption',
                color: part.highlight ? 'primary.main' : 'text.secondary',
              }}
            >
              {part.text}
            </Box>
          ))}
        </div>
      )}
    </div>
  );
}
