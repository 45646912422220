import { createSlice } from "@reduxjs/toolkit";
import { ErrorResponse, PagedResponseType } from "src/@types/commons";
import rolesOperations from "./roles-operations";
import { Role } from "src/@types/role";

interface RolesReducer {
    error: ErrorResponse,
    isLoading: boolean,
    role: Role | null,
    logsLoading: boolean,
    logsList: PagedResponseType<any>,
    adminRoleList: Role[],
    organizationRoleList: Role[]
};

const initialState: RolesReducer = {
    error: null,
    isLoading: false,
    role: null,
    logsLoading: false,
    logsList: {
        pageIndex: 0,
        pageSize: 0,
        items: [],
        totalCount: 0
    },
    adminRoleList: [],
    organizationRoleList: []
};

const RolesSlice = createSlice({
    name: 'role',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(rolesOperations.getAdminRole.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message as string;
                state.role = initialState.role;
            })
            .addCase(rolesOperations.getAdminRole.fulfilled, (state, action) => {
                state.isLoading = false;
                state.role = action.payload;
            })
            .addCase(rolesOperations.getAdminRoleLogs.rejected, (state, action) => {
                state.logsLoading = false;
                state.error = action.error.message as string;
                state.logsList = initialState.logsList;
            })
            .addCase(rolesOperations.getAdminRoleLogs.fulfilled, (state, action) => {
                state.logsLoading = false;
                state.logsList = action.payload;
            })
            .addCase(rolesOperations.searchAdminRoles.fulfilled, (state, action) => {
                state.isLoading = false;
                state.adminRoleList = action.payload.items;
            })
            .addCase(rolesOperations.searchAdminRoles.rejected, (state, action) => {
                state.adminRoleList = initialState.adminRoleList;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(rolesOperations.getOrganizationRole.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message as string;
                state.role = initialState.role;
            })
            .addCase(rolesOperations.getOrganizationRole.fulfilled, (state, action) => {
                state.isLoading = false;
                state.role = action.payload;
            })
            .addCase(rolesOperations.getOrganizationRoleLogs.rejected, (state, action) => {
                state.logsLoading = false;
                state.error = action.error.message as string;
                state.logsList = initialState.logsList;
            })
            .addCase(rolesOperations.getOrganizationRoleLogs.fulfilled, (state, action) => {
                state.logsLoading = false;
                state.logsList = action.payload;
            })
            .addCase(rolesOperations.searchOrganizationRoles.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message as string;
                state.organizationRoleList = initialState.organizationRoleList;
            })
            .addCase(rolesOperations.searchOrganizationRoles.fulfilled, (state, action) => {
                state.organizationRoleList = action.payload.items;
                state.isLoading = false;
            });
    },
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        startLogsLoading(state) {
            state.logsLoading = true;
        }
    }
});

export const {
    startLoading,
    startLogsLoading
} = RolesSlice.actions;

export default RolesSlice.reducer;
