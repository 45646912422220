import { MenuItem, TableCell, TableRow, useTheme } from '@mui/material';
import { useState } from 'react';
import { TransportData } from 'src/@types/logistics';
import DateZone from 'src/appComponents/DateZone';
import TableMoreMenu from 'src/appComponents/TableMoreMenu';
import useLocales from 'src/appHooks/useLocales';

interface IProps {
    transportData: TransportData;
    remove: () => void;
    edit: () => void;
    duplicate: () => void;
    editableField: boolean;
}

export const TransportDataRow = ({ remove, edit, duplicate, transportData, editableField }: IProps) => {

    const theme = useTheme();
    
    const { translate } = useLocales();

    const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    return (
        <TableRow
            sx={{
                background: `${editableField && theme.palette.primary.lighter + '80'}`,
                borderBottom: '1px solid',
                borderBottomColor: 'grey.300',
            }}
        >
            <TableCell align="left">{transportData.fromLocation}</TableCell>
            <TableCell align="left">{transportData.toLocation}</TableCell>
            <TableCell align="left">
                <DateZone date={new Date(transportData.startDate!)} onlyDate />
            </TableCell>
            <TableCell align="left">
                <DateZone date={new Date(transportData.arrivalDate!)} onlyDate />
            </TableCell>

            <TableCell align="center" sx={{ p: 1 }}>
                <TableMoreMenu
                    open={openMenu}
                    onOpen={handleOpenMenu}
                    onClose={handleCloseMenu}
                    actions={
                        <>
                            <MenuItem
                                onClick={() => {
                                    edit();
                                    handleCloseMenu();
                                }}
                            >
                                {`${translate('commons.edit')}`}
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    duplicate();
                                    handleCloseMenu();
                                }}
                            >
                                {`${translate('commons.duplicate')}`}
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    remove();
                                    handleCloseMenu();
                                }}
                                sx={{ color: 'error.main' }}
                            >
                                {`${translate('commons.delete')}`}
                            </MenuItem>
                        </>
                    }
                />
            </TableCell>
        </TableRow>
    );
};
