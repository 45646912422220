import { useNavigate, useParams } from 'react-router-dom';
import { Card, Box, Typography, MenuItem } from '@mui/material';
import { RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import useLocales from 'src/appHooks/useLocales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { DataGridStyle } from 'src/utils/DataGridStyle';
import { noData } from 'src/components/empty-content/EmptyContent';
import { RequestRulesSearchResult, RuleTabs } from 'src/@types/rules';
import useResponsive from 'src/hooks/useResponsive';
import { hasPermissions } from 'src/utils/user';
import { OrganizationPermissionTypes } from 'src/@types/permissions';
import { useUserOrganizationContext } from 'src/contexts/UserOrganizationContext';
import { useCallback, useMemo, useState } from 'react';
import CurrencyAdapter from 'src/appComponents/CurrencyAdapter';
import RulesEditModal from '../RulesEditModal';
import RulesLabelExpirationModal from '../RulesLabelExpirationModal';
import TableMoreMenu from 'src/appComponents/TableMoreMenu';

export default function RequestTab() {

    const { translate } = useLocales();

    const navigate = useNavigate();

    const { rulesTab } = useParams();

    const isDesktop = useResponsive('up', 'md');

    const { organization } = useUserOrganizationContext();

    const { isLoading, rule } = useSelector((state: RootState) => state.rules);

    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

    const [isPeriod, setIsPeriod] = useState<boolean>(false);

    const [isExpirationOpen, setIsExpirationOpen] = useState(false);

    const getDetails = useCallback((detail: string, row: string, index: number): string => {

        let strIndex = "";

        switch (index) {
            case 0: strIndex = "newPartInDays"; break;
            case 1: strIndex = "corePartInDays"; break;
            case 2: strIndex = "qualityPartInDays"; break;
            default: strIndex = "damagePartInDays";
        }

        if (row === "returnRequestPeriod")
            return detail + " <= " + rule?.period[strIndex] + ` ${translate('commons.days')}`;

        return detail;
    }, [rule, translate]);

    //---- HANDLE TABLE START ----//
    const ROWS: RequestRulesSearchResult[] = useMemo(() => [
        {
            id: "1",
            name: "returnRequestPeriod",
            type: translate("rules.list.rows.hard").toString(),
            category: translate("rules.list.rows.request").toString(),
            description: translate("rules.list.rows.periodDesc").toString(),
            details: [
                translate("rules.list.rows.periodNewParts").toString(),
                translate("rules.list.rows.periodCore").toString(),
                translate("rules.list.rows.periodQuality").toString(),
                translate("rules.list.rows.periodDamaged").toString()
            ]
        },
        {
            id: "2",
            name: "partValue",
            type: translate("rules.list.rows.hard").toString(),
            category: translate("rules.list.rows.request").toString(),
            description: translate("rules.list.rows.thresholdDesc").toString(),
            details: [translate("rules.list.rows.threshold").toString()]
        },
        {
            id: "3",
            name: "labelExpiration",
            type: translate("rules.list.rows.hard").toString(),
            category: translate("rules.list.rows.request").toString(),
            description: translate("rules.list.rows.labelExpirationDesc").toString(),
            details: [""]
        },
        {
            id: "4",
            name: "partCategories",
            type: translate("rules.list.rows.hard").toString(),
            category: translate("rules.list.rows.request").toString(),
            description: translate("rules.list.rows.categoriesDesc").toString(),
            details: [translate("rules.list.rows.blacklistsDet").toString()]
        },
        {
            id: "5",
            name: "partBlacklist",
            type: translate("rules.list.rows.hard").toString(),
            category: translate("rules.list.rows.request").toString(),
            description: translate("rules.list.rows.partsDesc").toString(),
            details: [translate("rules.list.rows.blacklistsDet").toString()]
        }
    ], [translate]);

    const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

    const [actualRow, setActualRow] = useState<any>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const handleEdit = useCallback((params: GridRowParams<any>) => {
        if ((params.row.name === "partCategories" || params.row.name === "partBlacklist")) {
            navigate(PATH_DASHBOARD.rules.editHard(rulesTab as RuleTabs, params.row.name));
        }
        else if (params.row.name === "returnRequestPeriod" || params.row.name === "partValue") {
            setIsPeriod(params.row.name === "returnRequestPeriod");
            setIsOpenModal(true);
        }
        else if (params.row.name === "labelExpiration")
            setIsExpirationOpen(true);
    }, [navigate, rulesTab]);

    const COLUMNS: GridColDef<any>[] = useMemo(() => [
        {
            field: 'name',
            headerName: `${translate('rules.list.tableHeaders.rule')}`,
            flex: isDesktop ? 1.25 : undefined,
            minWidth: !isDesktop ? 130 : undefined,
            headerAlign: 'left',
            sortable: false,
            renderCell: (obj) => {
                return (<strong>{translate(`rules.list.tableContent.name.${obj.row.name}`) + ""}</strong>);
            }
        },
        {
            field: 'type',
            headerName: `${translate('rules.list.tableHeaders.type')}`,
            flex: isDesktop ? 0.5 : undefined,
            minWidth: !isDesktop ? 75 : undefined,
            headerAlign: 'center',
            align: 'center',
            sortable: false
        },
        {
            field: 'category',
            headerName: `${translate('rules.list.tableHeaders.category')}`,
            flex: isDesktop ? 0.7 : undefined,
            minWidth: !isDesktop ? 125 : undefined,
            headerAlign: 'center',
            align: 'center',
            sortable: false
        },
        {
            field: 'description',
            headerName: `${translate('rules.list.tableHeaders.description')}`,
            flex: isDesktop ? 1.75 : undefined,
            minWidth: !isDesktop ? 185 : undefined,
            headerAlign: 'left',
            align: 'left',
            sortable: false
        },
        {
            field: 'details',
            headerName: `${translate('rules.list.tableHeaders.details')}`,
            flex: isDesktop ? 1.25 : undefined,
            minWidth: !isDesktop ? 200 : undefined,
            headerAlign: 'left',
            align: 'left',
            sortable: false,
            renderCell: (obj: any) => {
                if (obj.row.name === "labelExpiration")

                    return (
                        <Box sx={{ display: "flex", gap: 0.5 }}>
                            <Typography variant='body2'>{(rule?.period.labelExpirationInDays || 0) + ` ${translate('commons.days')}`}</Typography>
                        </Box>
                    );

                return (
                    <Box>
                        {obj.row.name === "partValue" ?
                            <Box sx={{ display: "flex", gap: 0.5 }}>
                                <Typography variant={'body2'}>
                                    {">"}
                                </Typography>
                                <CurrencyAdapter
                                    value={rule!.minimumAmount.withoutVat}
                                    currency={rule!.minimumAmount.currentCurrency}
                                    noVatAsterisk={!!rule!.minimumAmount.withoutVat}
                                    fontSize={'0.875rem'}
                                />
                            </Box>
                            :
                            <>
                                {obj.row.details.map((detail: string, index: number) =>
                                    <Typography key={index} variant={'body2'}>
                                        {getDetails(detail, obj.row.name, index)}
                                    </Typography>
                                )}
                            </>
                        }
                    </Box>
                );
            }
        },
        ...(hasPermissions([OrganizationPermissionTypes.WebShop_ReturnRequests_Rules_VendorEdit], organization.roles!) ? [
            {
                field: 'options',
                headerName: ``,
                flex: isDesktop ? 0.2 : undefined,
                maxWidth: !isDesktop ? 70 : undefined,
                headerAlign: 'center',
                align: "center",
                sortable: false,
                renderCell: (obj: any) => {
                    return (
                        <OptionsComponent
                            openMenu={openMenu}
                            handleOpenMenu={handleOpenMenu}
                            handleCloseMenu={handleCloseMenu}
                            handleEdit={handleEdit}
                            object={obj}
                            currentRow={actualRow}
                        />
                    );
                }
            }
        ] : []) as GridColDef<any>[],
    ], [translate, isDesktop, organization.roles, rule, getDetails, openMenu, handleEdit, actualRow]);

    /*
    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
        '& .super-app-theme--partCategories': {
            '&:hover': {
                cursor: hasPermissions([OrganizationPermissionTypes.WebShop_ReturnRequests_Rules_VendorEdit], organization.roles!) ? "pointer" : "default"
            }
        },
        '& .super-app-theme--partBlacklist': {
            '&:hover': {
                cursor: hasPermissions([OrganizationPermissionTypes.WebShop_ReturnRequests_Rules_VendorEdit], organization.roles!) ? "pointer" : "default"
            }
        }
    }));
    */
    //---- HANDLE TABLE END ----//

    return (
        <>
            {rule &&
                <RulesEditModal
                    isOpen={isOpenModal}
                    setIsOpen={setIsOpenModal}
                    isPeriod={isPeriod}
                    rule={rule}
                />
            }
            {
                rule &&
                <RulesLabelExpirationModal
                    isOpen={isExpirationOpen}
                    setIsOpen={setIsExpirationOpen}
                    period={rule.period}
                />
            }

            <Card>
                <DataGrid
                    rows={ROWS}
                    columns={COLUMNS}
                    loading={isLoading}
                    slots={{
                        noRowsOverlay: noData
                    }}
                    disableColumnMenu
                    disableColumnResize
                    disableRowSelectionOnClick

                    getRowHeight={() => 'auto'}

                    onCellClick={(params) => setActualRow(params)}

                    hideFooter
                    sx={{
                        ...DataGridStyle,
                        '& .MuiDataGrid-cell': { py: 2 }, //alignItems: "start !important"
                        height: 463
                    }}
                //getRowClassName={(params) => `super-app-theme--${params.row.name}`}
                />
            </Card>

            <Typography sx={{ textAlign: 'right', mt: 1.5, mr: 2 }} fontSize={'13px'}>
                {`${translate('orders.messages.vatAdvice')}`}
            </Typography>
        </>
    );
}

type OptionsComponentProps = {
    openMenu: HTMLElement | null,
    handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void,
    handleCloseMenu: () => void,
    handleEdit: (params: GridRowParams<any>) => void
    object: any,
    currentRow: any
};

function OptionsComponent({ openMenu, handleOpenMenu, handleCloseMenu, handleEdit, object, currentRow }: OptionsComponentProps) {

    const { translate } = useLocales();

    return (
        <TableMoreMenu
            showMenu={currentRow && object.id === currentRow.id}
            open={openMenu}
            onOpen={(event) => handleOpenMenu(event)}
            onClose={() => handleCloseMenu()}
            actions={
                <MenuItem
                    onClick={() => {
                        handleEdit(object);
                        handleCloseMenu();
                    }}
                >
                    {`${translate("commons.edit")}`}
                </MenuItem>
            }
        />
    );
}