import { useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, IconButton, Box, Typography, Tooltip } from '@mui/material';
import { bgBlur } from '../../../utils/cssStyles';
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
import { HEADER, NAV } from '../../../config';
import Iconify from '../../../components/iconify';
import { useSettingsContext } from '../../../components/settings';
import PowersearchBar from "src/sections/header/PowersearchBar";
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';
import { Logo } from 'src/appComponents/logo';
import CurrencyPopover from './CurrencyPopover';
import MetricSystemToggle from './MetricSystemToggle';
import CustomerCarePopover from './CustomerCarePopover';
import OrganizationsPopover from './OrganizationsPopover';
import { useAuth } from 'src/appHooks/useAuth';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'src/redux/store';
import { organizationsOperations } from 'src/redux/organizations';
import { useUserOrganizationContext } from 'src/contexts/UserOrganizationContext';
import SettingsPopover from './SettingsPopover';
import { useLocales } from 'src/locales';
import useTenant from 'src/appHooks/useTenant';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {

  const { user } = useAuth();

  const theme = useTheme();

  const { customerCareVendors } = useSelector((state: RootState) => state.organizations);

  const { organizationId, organization } = useUserOrganizationContext();

  const { currentLang } = useLocales();

  const { themeLayout } = useSettingsContext();

  const tenant = useTenant();

  const navigate = useNavigate();

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const isDesktop = useResponsive('up', 'lg');

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  useEffect(() => {
    dispatch(organizationsOperations.getCustomerCareVendors(organizationId));
  }, [organizationId, currentLang]);

  useEffect(() => {
    if (tenant.status === 503) navigate(PATH_DASHBOARD.general.maintenance);
  }, [navigate, tenant]);

  const renderContent = (
    <>
      {isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}

      <Box sx={{ mr: { xs: 0.5, sm: 1.5 } }}>
        <PowersearchBar />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: 'row',
          gap: { xs: 0, sm: 1.5 },
          width: '100%',
          alignItems: "center",
          justifyContent: { xs: "flex-start", sm: "flex-end" },
          overflowX: useResponsive('up', 'sm') ? undefined : 'scroll'
        }}
      >
        <LanguagePopover />

        <MetricSystemToggle />

        <CurrencyPopover />

        <NotificationsPopover />

        {customerCareVendors && customerCareVendors.length > 0 &&
          <CustomerCarePopover />
        }

        {user.organizations.length > 1 && <OrganizationsPopover />}

        {isDesktop && user.organizations.length > 1 &&

          <Tooltip title={
            organization.externalId ?
              organization.externalId.concat(" ", organization.name) :
              organization.name
          }
          >
            <Typography
              sx={{
                maxWidth: '15vw',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                color: (theme) => theme.palette.text.secondary
              }}

              variant="subtitle1"
            >
              {
                organization.externalId ?
                  organization.externalId.concat(" ", organization.name) :
                  organization.name
              }
            </Typography>
          </Tooltip>}

        <SettingsPopover />

      </Box>

      <Box sx={{ ml: { xs: 0.5, sm: 1.5 } }}>
        <AccountPopover />
      </Box>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}

