import { Autocomplete, AutocompleteInputChangeReason, Box, Chip, TextField } from '@mui/material';
import { isArray, isObject, debounce, isString } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Tag } from 'src/@types/commons';
import { OptionResult } from 'src/appComponents/AutocompleteField';
import { AxiosResponse } from 'axios';

interface SidebarAutocompleteProps {
    name: string,
    label: string,
    defaultAutocompleteValues: string[],
    handleChangeValues: (values: any, newVal: any, type: string) => void,
    filterValuesFromForm: any,
    inEditTags: boolean,
    setInEditTags: (value: boolean) => void,
    service: (tag: string, size: number) => Promise<AxiosResponse<Tag[]>>
}

export default function SidebarAutocomplete({ name, label, defaultAutocompleteValues, handleChangeValues, filterValuesFromForm, inEditTags, setInEditTags, service, ...others }: SidebarAutocompleteProps) {

    const [options, setOptions] = useState<OptionResult[]>([]);

    const [autocompleteValues, setAutocompleteValues] = useState<(string | OptionResult)[]>(defaultAutocompleteValues);

    const [singleAutocompleteValue, setSingleAutocompleteValue] = useState<string>("");

    const getOptionsDelayed = useCallback(
        debounce((text) => {
            setOptions([]);
            handleSearchOptions(text, null);
        }, 600), []);

    useEffect(() => {
        if (singleAutocompleteValue) getOptionsDelayed(singleAutocompleteValue);
    }, [singleAutocompleteValue, getOptionsDelayed]);

    const handleSearchOptions = async (value: string, reason: AutocompleteInputChangeReason | null) => {

        if (!!reason && reason === 'reset') return;

        if (value && isArray(value) && !value.length) return;

        let fixedResults: OptionResult[] = [];

        service(value, 3).then((response) => {
            fixedResults = response.data.map(el => {
                const res: OptionResult = {
                    key: (el as Tag).text,
                    label: `${(el as Tag).text} (${(el as Tag).numberOfTimesUsed})`,
                    option: el
                };

                return res;
            });
        }).catch(e => console.error(e));

        setOptions(fixedResults);
    };

    const handleChange = (newValue: (string | OptionResult)[] | string) => {

        if (isArray(newValue)) {
            const values = newValue.map(v => {
                if (isObject(v)) {
                    return (v as OptionResult).key;
                }

                return v;
            });

            const uniqueValues = values.filter((element, index) => {
                return values.indexOf(element) === index;
            });

            setAutocompleteValues(uniqueValues);
            setSingleAutocompleteValue("");
        } else if (isString(newValue)) {
            setSingleAutocompleteValue(newValue);
        }
    };

    const handleIsOptionEqualToValue = (option: OptionResult, value: OptionResult) => {
        const res = option.key === (isObject(value) ? value.key : value as any);

        return res;
    };

    const handleOnFocus = () => {
        setInEditTags(true);
    };

    const handleOnBlur = () => {
        if (inEditTags) {
            setSingleAutocompleteValue("");
            handleChangeValues(filterValuesFromForm, autocompleteValues, name);
            setInEditTags(false);
        }
    };

    const renderChips = (values: (string | OptionResult)[], getTagProps?: any) => {

        var chips: any;

        if (!singleAutocompleteValue) {

            if (isArray(values) && values.length > 0) {

                const uniqueValues = values.filter((element, index) => {
                    return values.indexOf(element) === index;
                });

                chips = uniqueValues.map((option: OptionResult | string, index: number) => (
                    <Chip
                        {...getTagProps({ index })}
                        key={isObject(option) ? option.key : option as string}
                        size="small"
                        label={isObject(option) ? option.key : option as string}
                    />
                ));
            }
        }

        return chips;
    };

    return (
        <Autocomplete
            sx={{ '& .MuiInputLabel-asterisk': { color: 'error.main' } }}
            multiple
            freeSolo
            //inputValue={multiple ? undefined : getValues(name) || ''}
            clearOnBlur={true}
            options={options}
            defaultValue={defaultAutocompleteValues}

            getOptionLabel={(option) => (option as OptionResult).key || ""}

            renderOption={((props: object, option: OptionResult, state: object) => (
                <Box component="li" {...props}>
                    {option.label}
                </Box>
            ))}

            filterSelectedOptions

            isOptionEqualToValue={(option, value) => handleIsOptionEqualToValue(option, value)}

            onChange={(event, newValue, reason) => {
                handleChange(newValue);
            }}

            onInputChange={(event: any, value, reason) => {
                if (value && event && event.target.value.length >= 1) {
                    handleChange(value);
                }
            }}

            renderTags={(values: (OptionResult | string)[], getTagProps) => {
                return renderChips(values, getTagProps);
            }}

            onFocus={handleOnFocus}
            onBlur={handleOnBlur}

            value={autocompleteValues}

            renderInput={(params) =>
                <TextField
                    {...params}
                    InputProps={{ ...params.InputProps }}
                    label={label}
                />
            }
            {...others}
        />
    );
}

