import { Container, Stack } from '@mui/system';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useLocales } from 'src/locales';
import { useNavigate, useParams } from 'react-router';
import { Box, Button, Card, Checkbox, Divider, FormControlLabel, Grid, InputAdornment, Modal, Switch, TablePagination, TextField, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { DeleteForever, Search } from '@mui/icons-material';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'src/redux/store';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import useTable from 'src/appHooks/useTable';
import SaveModal from 'src/components/modals/SaveModal';
import { rulesOperations } from 'src/redux/rules';
import AddIcon from '@mui/icons-material/Add';
import { noData } from 'src/components/empty-content/EmptyContent';
import { DataGridStyle } from 'src/utils/DataGridStyle';
import { ClearModal } from './PartBlacklist';
import { RuleTabs } from 'src/@types/rules';
import { CategoriesFilter, CategoriesSearchResult } from 'src/@types/spareParts';
import { sparePartsOperations } from 'src/redux/spareParts';
import { debounce } from 'lodash';
import sparePartsServices from 'src/services/sparePartsServices';
import LoadingScreen from 'src/appComponents/loading-screen/LoadingScreen';
import { setSuccessMessage } from 'src/redux/modal/modal-slices';

export default function PartCategories() {

    const isDesktop = useResponsive('up', 'md');

    const navigate = useNavigate();

    const { translate } = useLocales();

    const { rulesTab } = useParams();

    const { rule } = useSelector((state: RootState) => state.rules);

    const [loading, setLoading] = useState(false);

    //----MANAGE MODALS
    const [isOpenSave, setIsOpenSave] = useState(false);

    const [isOpenList, setIsOpenList] = useState(false);

    const [openClear, setOpenClear] = useState(false);

    const toggleSave = () => {
        isOpenSave ? setIsOpenSave(false) : setIsOpenSave(true);
    };

    const toggleList = () => {
        isOpenList ? setIsOpenList(false) : setIsOpenList(true);
    };

    const onQuit = () => {
        navigate(PATH_DASHBOARD.rules.listTab(rulesTab as RuleTabs));
    };

    //----MANAGE SUMMARY
    const [itemsSelected, setItemsSelected] = useState<string[]>(rule ? rule.partCategory.categories : []);

    const [categoriesList, setCategoriesList] = useState<CategoriesSearchResult[]>([]);

    const handleItemSelected = (items: CategoriesSearchResult[]) => {

        setCategoriesList(items);

        setItemsSelected(items.map(it => it.code));
    };

    useEffect(() => {
        if (rule && rule.partCategory.categories.length > 0) {
            setLoading(true);
            sparePartsServices.searchCategories({ pageIndex: 0, pageSize: 100, ids: rule.partCategory.categories }).then(val => {
                setCategoriesList(val.data.items);
                setLoading(false);
            });
        }
    }, [rule]);

    const deleteItem = (id: string) => {

        const indexList = itemsSelected.map((item) => item).indexOf(id);

        setCategoriesList(prev => prev.filter(it => it.code !== id));

        setItemsSelected([...itemsSelected.slice(0, indexList), ...itemsSelected.slice(indexList + 1, itemsSelected.length + 1)]);
    };

    const lastId = useMemo(() => itemsSelected[itemsSelected.length - 1], [itemsSelected]);

    const handleSubmit = async () => {
        setIsOpenSave(false);

        await dispatch(rulesOperations.updateRuleCategory({ categories: itemsSelected })).unwrap();

        dispatch(setSuccessMessage({ text: translate('rules.messages.saveText.partCategories'), returnTo: PATH_DASHBOARD.rules.listTab(rulesTab as RuleTabs) }));
    };

    const handleClear = () => {
        setItemsSelected([]);
        setCategoriesList([]);
        setOpenClear(false);
    };

    return loading ? (<LoadingScreen />) : (

        <>

            <SaveModal
                toggle={toggleSave}
                isOpen={isOpenSave}
                handleSave={handleSubmit}
                saveText={`${translate('rules.messages.saveConfirmPartRef')}`}
            />
            <PartsListModal
                toggle={toggleList}
                isOpen={isOpenList}
                itemsSelected={categoriesList}
                setItemsSelected={handleItemSelected}
            />
            <ClearModal
                isOpen={openClear}
                toggle={() => setOpenClear(p => !p)}
                submit={handleClear}
                clearText={"clearPartref"}
            />

            <Card sx={{ mt: 2 }}>

                {itemsSelected.length === 0 &&
                    <Box
                        sx={{
                            display: 'flex', gap: 1,
                            flexDirection: 'column'
                        }}
                    >

                        <Typography
                            variant="h5"
                            sx={{ pt: 3, px: 2.5 }}
                        >
                            {`${translate(`rules.form.partCatList`)}`}
                        </Typography>

                        <Grid container sx={{ px: 3, pt: 2.5, pb: 7.5 }}>

                            <Grid item xs={12} >
                                <Button
                                    variant="soft"
                                    size={"large"}
                                    fullWidth
                                    sx={{ height: "200%" }}
                                    startIcon={<AddIcon />}
                                    onClick={toggleList}
                                >
                                    {`${translate("rules.form.addcategory")}`}
                                </Button>
                            </Grid>

                        </Grid>

                    </Box>
                }

                {itemsSelected.length > 0 &&
                    <Box>

                        <Box
                            sx={{
                                display: 'flex',
                                pt: 3, pb: 1.5, px: 2.5,
                                flexDirection: isDesktop ? 'row' : 'column'
                            }}
                        >

                            <Typography variant="h5">
                                {`${translate(`rules.form.partCatList`)}`}
                            </Typography>

                            <Button
                                variant="text"
                                size={"medium"}
                                onClick={toggleList}
                                startIcon={<AddIcon />}
                                sx={{ ml: "auto", height: "auto", mt: (isDesktop ? 0 : 1.5) }}
                            >
                                {`${translate("rules.form.edit.addPartCat")}`}
                            </Button>
                        </Box>

                        <Box sx={{ overflow: 'auto', maxHeight: 500 }}>
                            {categoriesList.map((item) =>
                                <PartSummary
                                    key={item.code + "summary.item"}
                                    item={item}
                                    deleteItem={deleteItem}
                                    lastId={item.code === lastId}
                                />
                            )}
                        </Box>

                    </Box>
                }

                <Divider />

                {
                    isDesktop ?
                        <Box
                            sx={{
                                display: 'flex',
                                px: 3, py: 3, gap: 1,
                                flexDirection: isDesktop ? 'row' : 'column-reverse'
                            }}
                        >
                            <Button
                                variant="soft"
                                size={"medium"}
                                sx={{ borderRadius: 100 }}
                                onClick={onQuit}
                            >
                                {`${translate("commons.cancel")}`}
                            </Button>

                            <Box
                                sx={{
                                    ml: 'auto',
                                    display: 'flex',
                                    gap: 1,
                                    flexDirection: 'default'
                                }}
                            >
                                {itemsSelected.length > 0 &&
                                    <Button
                                        startIcon={<DeleteForever />}
                                        variant='contained'
                                        color='error'
                                        size={"medium"}
                                        onClick={() => setOpenClear(true)}
                                        sx={{ borderRadius: 100 }}
                                    >
                                        {`${translate('commons.empty')}`}
                                    </Button>
                                }

                                <Button
                                    variant="contained"
                                    size={"medium"}
                                    onClick={toggleSave}
                                    sx={{ ml: 1, borderRadius: 100 }}
                                >
                                    {`${translate("commons.justSave")}`}
                                </Button>

                            </Box>

                        </Box>
                        :
                        <Box
                            sx={{
                                display: 'flex',
                                px: 2, py: 1, gap: 1,
                                flexDirection: 'column'
                            }}
                        >

                            <Box
                                sx={{
                                    mx: 2, mt: 1.5,
                                    display: 'flex', gap: 3,
                                }}
                            >
                                <Button
                                    variant="soft"
                                    size={"medium"}
                                    sx={{ borderRadius: 100 }}
                                    onClick={onQuit}
                                    fullWidth
                                >
                                    {`${translate("commons.cancel")}`}
                                </Button>

                                {itemsSelected.length > 0 &&
                                    <Button
                                        startIcon={<DeleteForever />}
                                        variant='contained'
                                        color='error'
                                        size={"medium"}
                                        onClick={() => setOpenClear(true)}
                                        sx={{ borderRadius: 100 }}
                                        fullWidth
                                    >
                                        {`${translate('commons.empty')}`}
                                    </Button>
                                }

                            </Box>

                            <Button
                                variant="contained"
                                size={"medium"}
                                onClick={toggleSave}
                                sx={{ my: 1.5, mx: 2, borderRadius: 100 }}

                            >
                                {`${translate("commons.justSave")}`}
                            </Button>

                        </Box>
                }

            </Card >
        </>
    );
}

interface PartsListModalType {
    isOpen: boolean;
    toggle: () => void;
    itemsSelected: CategoriesSearchResult[];
    setItemsSelected: (value: CategoriesSearchResult[]) => void;
}

function PartsListModal({ isOpen, toggle, itemsSelected, setItemsSelected }: PartsListModalType) {

    const { translate } = useLocales();

    const isDesktop = useResponsive('up', 'sm');

    const {
        rowsPerPage,
        page,
        setPage,
        onChangeDense,
        onChangePage,
        onChangeRowsPerPage,
        dense,
        order,
        orderBy,
        setOrder,
        setOrderBy
    } = useTable();

    const { isLoading, categories } = useSelector((state: RootState) => state.spareParts);

    const [openClear, setOpenClear] = useState(false);

    const [searchVal, setSearchVal] = useState("");

    const [isOpenSave, setIsOpenSave] = useState(false);

    const [itemsSelectedInList, setItemsSelectedInList] = useState<CategoriesSearchResult[]>(itemsSelected);

    useEffect(() => {
        if (!isOpen) {
            setItemsSelectedInList(itemsSelected);
        }
    }, [itemsSelected, isOpen]);

    const toggleSave = () => {
        isOpenSave ? setIsOpenSave(false) : setIsOpenSave(true);
    };

    const columns: GridColDef<CategoriesSearchResult>[] = [
        {
            field: '',
            headerName: ``,
            flex: 0.25,
            sortable: false,
            align: 'left',
            renderCell: (v) => <Checkbox checked={!!itemsSelectedInList.find(item => item.code === v.row.code)} />
        },
        {
            field: 'code',
            headerName: `${translate('rules.list.tableHeaders.code')}`,
            flex: isDesktop ? 1.1 : undefined,
            maxWidth: !isDesktop ? 90 : undefined,
            valueGetter: (params: string) => {
                if (!params) {
                    return '—';
                }

                return params;
            },
        },
        {
            field: 'description',
            headerName: `${translate('rules.list.tableHeaders.description')}`,
            flex: isDesktop ? 2 : undefined,
            minWidth: !isDesktop ? 335 : undefined,
            valueGetter: (params: string) => {
                if (!params) {
                    return '—';
                }

                return params;
            },
        },
        {
            field: 'numberOfParts',
            headerName: `${translate('rules.list.tableHeaders.counter')}`,
            flex: isDesktop ? 1.5 : undefined,
            minWidth: !isDesktop ? 140 : undefined,
            valueGetter: (params: string) => {
                if (!params) {
                    return '—';
                }

                return params;
            },
        }
    ];

    const handleSort = (sortModel: GridSortModel) => {
        if (sortModel.length > 0) {
            setOrderBy(sortModel[0].field);
            setOrder(sortModel[0].sort!);
        } else {
            setOrderBy("");
            setOrder("asc");
        }
    };

    const [options, setOptions] = useState<CategoriesFilter>({
        pageIndex: page,
        pageSize: rowsPerPage,
        sortAscending: order === 'desc' ? true : false,
        sortField: orderBy || undefined,
    });

    useEffect(() => {
        setOptions({
            pageIndex: page,
            pageSize: rowsPerPage,
            sortAscending: order === 'desc' ? true : false,
            sortField: orderBy || undefined,
            All: searchVal
        });
    }, [order, orderBy, page, rowsPerPage, searchVal]);

    useEffect(() => {
        if (isOpen) {
            dispatch(sparePartsOperations.searchRulesCategories(options));
        }
    }, [options, isOpen]);

    useEffect(() => {
        if (categories.totalCount <= rowsPerPage * page)
            onChangePage(null, 0);
    }, [onChangePage, page, rowsPerPage, categories.totalCount]);

    //calls search only after the user has stopped typing and 200 milliseconds have passed
    const debounced = useRef(
        debounce((options: CategoriesFilter) => {
            dispatch(sparePartsOperations.searchRulesCategories(options));
        }, 500));

    const handleSearch = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearchVal(e.target.value);
        debounced.current({ ...options, All: e.target.value });
    };

    const handleSubmit = () => {
        setItemsSelected(itemsSelectedInList);
        setPage(0);
        toggle();
    };

    const handleClear = () => {
        setOpenClear(false);
        setItemsSelectedInList([]);
    };

    const handleClose = () => {
        setPage(0);
        toggle();
    };

    const handleCancel = () => {
        setItemsSelectedInList(itemsSelected);
        setPage(0);
        toggle();
    };

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            sx={{ m: 'auto' }}
        >
            <Container
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }}
            >

                <ClearModal
                    isOpen={openClear}
                    toggle={() => setOpenClear(p => !p)}
                    submit={handleClear}
                    clearText={"clearPartref"}
                />
                <SaveModal
                    toggle={toggleSave}
                    isOpen={isOpenSave}
                    handleSave={handleSubmit as any}
                    saveText={`${translate('rules.messages.saveConfirmPartRef')}`}
                />

                <Card sx={{ p: 2, pb: 0, maxHeight: '95vh', overflowY: "auto" }}>

                    <Typography variant='h5' sx={{ px: 1 }}>{`${translate("rules.form.addcategory")}`}</Typography>

                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
                        <TextField
                            value={searchVal}
                            onChange={(e) => handleSearch(e)}
                            placeholder={`${translate("rules.form.searchCategory")}...`}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <DataGrid
                            columns={columns}
                            rows={categories.items}
                            getRowId={(row) => row.code}
                            disableColumnMenu
                            disableColumnResize
                            disableRowSelectionOnClick
                            keepNonExistentRowsSelected
                            onRowClick={(row) => {
                                if (itemsSelectedInList.find(item => item.code === row.id))
                                    setItemsSelectedInList(prev => prev.filter(v => v.code !== row.id));
                                else
                                    setItemsSelectedInList(prev => prev.concat([row.row]));
                            }}
                            pagination
                            paginationModel={{
                                page: page,
                                pageSize: rowsPerPage
                            }}
                            density={dense ? 'compact' : 'standard'}
                            sortingMode={"server"}
                            onSortModelChange={handleSort}
                            loading={isLoading}
                            sx={{ ...DataGridStyle, maxHeight: 640 }}
                            pageSizeOptions={[5, 10, 15, 30]}
                            slots={{
                                noRowsOverlay: noData,
                                footer: () => (
                                    <Box sx={{
                                        position: 'relative',
                                        width: { xs: "85vw", sm: "90vw", md: "auto" },
                                    }}>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 15, 30]}
                                            component="div"
                                            count={categories.totalCount} //totalCount
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={onChangePage}
                                            onRowsPerPageChange={onChangeRowsPerPage}
                                            labelRowsPerPage={`${translate('commons.rowsPerPage')}`}
                                            sx={{ overflow: "hidden", "& .MuiTablePagination-toolbar": { px: 1 } }}
                                        />

                                        <FormControlLabel
                                            control={<Switch checked={dense} onChange={onChangeDense} />}
                                            label={`${translate('commons.dense')}`}
                                            sx={{
                                                px: { xs: 0, sm: 2 },
                                                py: { xs: 0, sm: 1.5 },
                                                pb: { xs: 1.5, sm: 0 },
                                                mx: 0, top: 0,
                                                justifyContent: "center",
                                                width: { xs: "80vw", sm: "auto" },
                                                position: { sm: 'absolute' }
                                            }}
                                        />
                                    </Box>
                                )
                            }}
                        />
                    </Box>

                    <Divider />

                    {
                        isDesktop ?
                            <Box
                                sx={{
                                    justifyContent: 'space-between',
                                    ml: 'auto', display: 'flex',
                                    gap: 1, p: 3
                                }}
                            >

                                <Button variant="soft" color="inherit" onClick={handleCancel}>
                                    {`${translate("commons.cancel")}`}
                                </Button>

                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <Button startIcon={<DeleteForever />} variant='contained' color='error' onClick={handleClear}>
                                        {`${translate('rules.form.clear')}`}
                                    </Button>
                                    <Button type="submit" variant="contained" onClick={handleSubmit}>
                                        {`${translate("commons.add")}`}
                                    </Button>
                                </Box>

                            </Box>
                            :
                            <Box
                                sx={{
                                    display: 'flex',
                                    px: 2, py: 1, gap: 1,
                                    flexDirection: 'column'
                                }}
                            >

                                <Box
                                    sx={{
                                        mx: 2, mt: 1.5,
                                        display: 'flex', gap: 3,
                                    }}
                                >
                                    <Button
                                        variant="soft"
                                        color="inherit"
                                        onClick={handleCancel}
                                        fullWidth
                                    >
                                        {`${translate("commons.cancel")}`}
                                    </Button>

                                    <Button
                                        startIcon={<DeleteForever />}
                                        variant='contained'
                                        color='error'
                                        onClick={handleClear}
                                        fullWidth
                                    >
                                        {`${translate('commons.empty')}`} {/* clear */}
                                    </Button>

                                </Box>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    onClick={handleSubmit}
                                    sx={{ my: 1.5, mx: 2 }}

                                >
                                    {`${translate("commons.add")}`}
                                </Button>

                            </Box>
                    }

                </Card>
            </Container>
        </Modal>
    );
}

interface PartSummaryProps {
    item: CategoriesSearchResult,
    deleteItem: (id: string) => void,
    lastId: boolean
}

function PartSummary({ item, deleteItem, lastId }: PartSummaryProps) {

    const { translate } = useLocales();

    const isDesktop = useResponsive('up', 'md');

    const isSM = useResponsive('up', 'sm');

    const returnCounter = useMemo(() => {
        return (
            <Grid item xs={4} sm={2} md={1.5}>
                <Stack sx={{ m: isDesktop ? 2 : 1 }}>
                    <Typography variant="overline">
                        {`${translate('rules.list.tableHeaders.counter')}`}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 0.5 }}>
                        {item?.numberOfParts || '—'}
                    </Typography>
                </Stack>
            </Grid>
        );
    }, [item, isDesktop, translate]);

    const returnDescription = useMemo(() => {
        return (
            <Grid item xs={12} sm={6} md={4.5}>
                <Stack sx={{ m: isDesktop ? 2 : 1 }}>
                    <Typography variant="overline">
                        {`${translate('rules.list.tableHeaders.description')}`}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 0.5 }}>
                        {item?.description || '—'}
                    </Typography>
                </Stack>
            </Grid>
        );
    }, [item, isDesktop, translate]);

    return (
        <>
            <Box
                key={item.code + ".partFamily.summary"}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    position: "relative"
                }}
            >
                <Grid container gap={0.5} sx={{ mx: 2, width: '100%' }}>

                    <Grid item xs={4} sm={1.5} md={1.5}>
                        <Stack sx={{ m: isDesktop ? 2 : 1 }}>
                            <Typography variant="overline">
                                {`${translate('rules.list.tableHeaders.code')}`}
                            </Typography>
                            <Typography variant="body2" sx={{ mt: 0.5 }}>
                                {item.code || '—'}
                            </Typography>
                        </Stack>
                    </Grid>

                    {!isSM ? returnCounter : returnDescription}

                    {isSM ? returnCounter : returnDescription}

                </Grid>

                <Button
                    size={"medium"}
                    startIcon={<DeleteForever sx={{ ml: 1.5 }} />}
                    onClick={() => deleteItem(item.code)}
                    sx={{
                        position: "absolute",
                        right: isDesktop ? "3%" : "1%",
                        top: isDesktop ? "25%" : "5%"
                    }}
                />
            </Box>

            {(!lastId && !isDesktop) && <Divider sx={{ mx: 2 }} />}
        </>
    );
}