import { useState } from 'react';
import { MenuItem, Stack, Typography } from '@mui/material';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import { Currency, allCurrencies } from 'src/config';
import { useSettingsContext } from 'src/components/settings';
import useCurrency from 'src/appHooks/useCurrency';

export default function CurrencyPopover() {

  const { currency: currentCurrency, onChangeCurrency } = useSettingsContext();

  const { rates } = useCurrency();

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleChangeCurrency = (newLang: Currency) => {
    onChangeCurrency(newLang);
    handleClosePopover();
  };

  return (rates.length > 1 &&
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          width: 40,
          height: 40,
          ...(openPopover && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        <Typography color={openPopover ? "primary" : "default"} variant="subtitle1">{currentCurrency.symbol}</Typography>
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 180, height: 300, overflowX: "auto" }}>
        <Stack spacing={0.75}>
          {allCurrencies.map((option: Currency) => (
            <MenuItem
              key={option.label}
              selected={option.label === currentCurrency.label}
              onClick={() => handleChangeCurrency(option)}
            >
              <Typography
                variant='inherit'
                sx={{ ml: 1 }}>
                {option.label} ({option.symbol})
              </Typography>
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}

