import { Autocomplete, AutocompleteProps } from "@mui/material";
import { debounce } from "lodash";
import { useState, UIEvent, useRef } from "react";
import { InfiniteScrollGenericListFilters, InfiniteScrollPagedResponse, PagedResponseType, SequenceToken } from "src/@types/commons";

interface SearchParams {
    pageIndex: number,
    pageSize: number,
    all?: string
}

interface InfiniteAutocompleteProps<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined = undefined,
> extends Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'options'> {
    startingValues?: T[]
    searchCallBack: (params: SearchParams) => Promise<PagedResponseType<T>>
}

export default function InfiniteAutocomplete<
    Value,
    Multiple extends boolean | undefined = false,
    DisableClearable extends boolean | undefined = false,
    FreeSolo extends boolean | undefined = false,
>({ startingValues, searchCallBack, ...autocompleteProps }: InfiniteAutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>) {

    const [allOptions, setAllOptions] = useState<Value[]>(startingValues ?? []);

    const [hasNextPage, setHasNextPage] = useState(true);

    const [infPage, setInfPage] = useState(0);

    const [all, setAll] = useState<string>();

    const [first, setFirst] = useState(true);

    //data getter
    const fetchData = async (params: SearchParams, concat?: boolean) => {
        const search = await searchCallBack(params);

        if (concat)
            setAllOptions(prev => prev.concat(search.items));
        else
            setAllOptions(search.items);

        setInfPage(search.pageIndex);

        setHasNextPage(!!search.hasNextPage);
    };

    //handler for search when scrolled
    const getPage = (e: UIEvent<HTMLUListElement>) => {

        const { scrollTop, scrollHeight, offsetHeight } = e.currentTarget;

        //check if scrollbar reached bottom and if there are other elements
        if (Math.abs(scrollHeight - (scrollTop + offsetHeight)) < 1 && hasNextPage && !autocompleteProps.loading) {

            fetchData({ pageIndex: infPage + 1, pageSize: 5, all: all }, true);

        }
    };

    const debouncedSearch = useRef(debounce(fetchData, 500));

    const handleSearch = (v: string) => {
        setAll(v || undefined);

        if (v.length >= 3 || v.length === 0)
            debouncedSearch.current({ pageIndex: 0, pageSize: 5, all: v });
    };

    return (
        <Autocomplete
            options={allOptions}
            onOpen={() => {
                if (first) {
                    fetchData({ pageIndex: 0, pageSize: 5 });
                    setFirst(false);
                }
            }}
            onInputChange={(e, v, r) => {
                handleSearch(v);

                if (autocompleteProps.onInputChange)
                    autocompleteProps.onInputChange(e, v, r);
            }}

            ListboxProps={{
                ...autocompleteProps.ListboxProps,
                sx: { ...(autocompleteProps.ListboxProps?.sx ?? {}), maxHeight: '30vh' },

                onScroll: (e) => {
                    getPage(e);

                    if (autocompleteProps.ListboxProps?.onScroll)
                        autocompleteProps.ListboxProps?.onScroll(e);
                }
            }}
            {...autocompleteProps}
        />
    );
}

interface AltInfiniteAutocompleteProps<
    T extends SequenceToken,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined = undefined,
> extends Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'options'> {
    startingValues?: T[]
    searchCallBack: (params: InfiniteScrollGenericListFilters) => Promise<InfiniteScrollPagedResponse<T>>
}

export function AltInfiniteAutocomplete<
    Value extends SequenceToken,
    Multiple extends boolean | undefined = false,
    DisableClearable extends boolean | undefined = false,
    FreeSolo extends boolean | undefined = false
>({ startingValues, searchCallBack, ...autocompleteProps }: AltInfiniteAutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>) {

    const [allOptions, setAllOptions] = useState<Value[]>(startingValues ?? []);

    const [all, setAll] = useState<string>();

    const [first, setFirst] = useState(true);

    const [total, setTotal] = useState(0);

    const params: InfiniteScrollGenericListFilters = {
        size: 5,
        pagination: 'After',
        sequenceToken: allOptions[allOptions.length - 1]?.searchSequenceToken ?? null,
        sortDirection: 'Descending',
        sortField: 'createdOn',
    };

    //data getter
    const fetchData = async (params: InfiniteScrollGenericListFilters, concat?: boolean) => {
        const search = await searchCallBack(params);

        if (concat)
            setAllOptions(prev => prev.concat(search.results));
        else
            setAllOptions(search.results);

        setTotal(search.totalCount);
    };

    //handler for search when scrolled
    const getPage = (e: UIEvent<HTMLUListElement>) => {

        const { scrollTop, scrollHeight, offsetHeight } = e.currentTarget;

        //check if scrollbar reached bottom and if there are other elements
        if (Math.abs(scrollHeight - (scrollTop + offsetHeight)) < 1 && allOptions.length < total && !autocompleteProps.loading) {

            fetchData({
                ...params,
                all
            }, true);

        }
    };

    const debouncedSearch = useRef(debounce(fetchData, 500));

    const handleSearch = (v: string) => {
        setAll(v || undefined);

        if (v.length >= 3 || v.length === 0)
            debouncedSearch.current({
                ...params,
                all: v || undefined,

            });
    };

    return (
        <Autocomplete
            options={allOptions}
            onOpen={() => {
                if (first) {
                    fetchData(params);
                    setFirst(false);
                }
            }}
            onInputChange={(e, v, r) => {
                handleSearch(v);

                if (autocompleteProps.onInputChange)
                    autocompleteProps.onInputChange(e, v, r);
            }}

            ListboxProps={{
                ...autocompleteProps.ListboxProps,
                sx: { ...(autocompleteProps.ListboxProps?.sx ?? {}), maxHeight: '30vh' },

                onScroll: (e) => {
                    getPage(e);

                    if (autocompleteProps.ListboxProps?.onScroll)
                        autocompleteProps.ListboxProps?.onScroll(e);
                }
            }}
            {...autocompleteProps}
        />
    );
}