import { Stack, Card, TableContainer, Table, TableBody, TableRow, TableCell, CircularProgress, Typography, Link, Box, FormControlLabel, Switch, TablePagination } from "@mui/material";
import { scrollBarStyle } from "src/components/scrollbar/Scrollbar";
import { TableHeadCustom, TableNoData, useTable } from "src/components/table";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useLocales } from "src/locales";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { usersOperations } from "src/redux/users";
import { RootState, dispatch, useSelector } from "src/redux/store";
import DateZone from "src/appComponents/DateZone";

interface userProps {
    id: string
};

export default function OrganizationUserDetail({ id }: userProps) {

    const { translate, currentLang } = useLocales();

    const navigate = useNavigate();

    const { userList, isLoading, totalCount } = useSelector((state: RootState) => state.user);

    const {
        page,
        order,
        orderBy,
        rowsPerPage,
        onChangeDense,
        onChangePage,
        dense,
        onChangeRowsPerPage,
        onSort
    } = useTable();

    const TABLE_HEAD = [
        { id: 'name', label: `${translate('commons.name')}`, align: 'left' },
        { id: 'email', label: `${translate('commons.email')}`, align: 'left' },
        { id: 'lastLogin', label: `${translate('user.list.tableHeaders.lastLogin')}`, align: 'left' },
    ];

    useEffect(() => {
        dispatch(usersOperations.searchUsers({ pageIndex: page, pageSize: rowsPerPage, organizations: id }));
    }, [id, page, rowsPerPage, currentLang]);

    return (

        <Card>

            <TableContainer sx={[{ minWidth: 800, maxHeight: 500, position: 'relative', mt: 2, height: dense ? '' : rowsPerPage <= 5 ? 400 : 500 }, scrollBarStyle]}>
                <Table stickyHeader>
                    <TableHeadCustom
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={totalCount}
                        onSort={onSort}
                    />
                    {isLoading ? (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={12}>
                                    <Stack
                                        alignItems="center"
                                        justifyContent="center"
                                        sx={{
                                            height: 1,
                                            textAlign: 'center',
                                            p: (theme) => theme.spacing(8, 2),
                                        }}
                                    >
                                        <CircularProgress />
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ) : (
                        <TableBody>
                            {userList.map((row, ind) => (
                                <TableRow key={ind} hover sx={{ cursor: 'pointer' }}>
                                    <TableCell sx={{ display: 'flex', alignItems: 'center' }} onClick={() => navigate(PATH_DASHBOARD.user.detailsTab(row.id, "profile"))} >
                                        <Typography variant="subtitle2" noWrap>
                                            {row.firstName + " " + row.lastName}
                                        </Typography>
                                    </TableCell>

                                    <TableCell align="left" >
                                        <Link
                                            noWrap
                                            variant="body2"
                                            href={"mailto:" + row.email}
                                            sx={{ color: 'text.disabled', cursor: 'pointer' }}
                                        >
                                            {row.email}
                                        </Link>
                                    </TableCell>

                                    <TableCell align='left' onClick={() => navigate(PATH_DASHBOARD.user.detailsTab(row.id, "profile"))} >
                                        <DateZone date={row.lastLogin} />
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableNoData isNotFound={totalCount === 0} />
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
            <Box sx={{ position: 'relative' }}>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 30]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={onChangePage}
                    onRowsPerPageChange={onChangeRowsPerPage}
                    labelRowsPerPage={`${translate('commons.rowsPerPage')}`}
                />

                <FormControlLabel
                    control={<Switch checked={dense} onChange={onChangeDense} />}
                    label={`${translate('commons.dense')}`}
                    sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                />
            </Box>
        </Card>
    );
}