import { createAsyncThunk } from "@reduxjs/toolkit";
import * as inboxService from '../../services/inboxService';
import { startLoadingRecent, startLoading, startSettingsLoading, startTemplateLoading, resetTemplateList } from "./inbox-slices";
import { InboxFilters, NewNotificationTemplate, NotificationSettings, NotificationTemplate } from "src/@types/inbox";
import { dispatch } from "../store";

const getInboxLabelsAndStatistics = createAsyncThunk(
    'inbox/getInboxLabelsAndStatistics',
    async (_, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await inboxService.labelsAndStatistics();

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }

    }
);

const getRecentInboxLabelsAndStatistics = createAsyncThunk(
    'inbox/getRecentInboxLabelsAndStatistics',
    async (_, { rejectWithValue, dispatch }) => {
        dispatch(startLoadingRecent());
        try {
            const { data } = await inboxService.labelsAndStatistics();

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }

    }
);

const getMessages = createAsyncThunk(
    'inbox/getMessages',
    async (params: InboxFilters, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await inboxService.getMessages(params);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    });

const getRecentMessages = createAsyncThunk(
    'inbox/getRecentMessages',
    async (params: InboxFilters, { rejectWithValue, dispatch }) => {
        dispatch(startLoadingRecent());
        try {
            const { data } = await inboxService.getMessages(params);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    });

const getMessageDetail = createAsyncThunk(
    'inbox/getMessageDetail',
    async (mailId: string, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await inboxService.getMessagesDetail(mailId);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    });

const markAsStarred = createAsyncThunk(
    'inbox/markAsStarred',
    async (mailIds: string[], { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { status } = await inboxService.markAsStarred(mailIds);

            if (status < 200 || status >= 300) {
                throw new Error('Something went wrong');
            }

            return true;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    });

const markAsUnStarred = createAsyncThunk(
    'inbox/markAsUnStarred',
    async (mailIds: string[], { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { status } = await inboxService.markAsUnStarred(mailIds);

            if (status < 200 || status >= 300) {
                throw new Error('Something went wrong');
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    });

const markAsImportant = createAsyncThunk(
    'inbox/markAsImportant',
    async (mailIds: string[], { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { status } = await inboxService.markAsImportant(mailIds);

            if (status < 200 || status >= 300) {
                throw new Error('Something went wrong');
            }

            return true;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    });

const markAsUnImportant = createAsyncThunk(
    'inbox/markAsUnImportant',
    async (mailIds: string[], { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { status } = await inboxService.markAsUnImportant(mailIds);

            if (status < 200 || status >= 300) {
                throw new Error('Something went wrong');
            }

            return true;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    });

const markAsRead = createAsyncThunk(
    'inbox/markAsRead',
    async (mailIds: string[], { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { status } = await inboxService.markAsRead(mailIds);

            if (status < 200 || status >= 300) {
                throw new Error('Something went wrong');
            }

            return true;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    });

const markAsUnRead = createAsyncThunk(
    'inbox/markAsUnRead',
    async (mailIds: string[], { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { status } = await inboxService.markAsUnRead(mailIds);

            if (status < 200 || status >= 300) {
                throw new Error('Something went wrong');
            }

            return true;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    });

const deleteMessages = createAsyncThunk(
    'inbox/deleteMessages',
    async (mailIds: string[], { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data, status } = await inboxService.deleteMessages(mailIds);

            if (!data && status !== 204) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    });

const getNotificationSettings = createAsyncThunk(
    'inbox/getNotificationSettings',
    async (_, { rejectWithValue, dispatch }) => {
        dispatch(startSettingsLoading());
        try {
            const { data } = await inboxService.getSettings();

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const updateNotificationSettings = createAsyncThunk(
    'inbox/updateNotificationSettings',
    async (options: NotificationSettings, { rejectWithValue }) => {
        try {
            const { data } = await inboxService.updateSettings(options);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return options;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const createNotificationTemplate = createAsyncThunk(
    'inbox/createNotificationTemplate',
    async (options: NewNotificationTemplate, { rejectWithValue }) => {
        try {
            const { data, status } = await inboxService.createNotificationTemplate(options);

            if (status >= 400)
                throw new Error('Something went wrong');

            dispatch(resetTemplateList());

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const searchNotificationTemplate = createAsyncThunk(
    'inbox/searchNotificationTemplate',
    async (_, { dispatch, rejectWithValue }) => {
        dispatch(startTemplateLoading());
        try {
            const { data, status } = await inboxService.searchNotificationTemplate();

            if (status >= 400)
                throw new Error('Something went wrong');

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const notificationTemplateDetail = createAsyncThunk(
    'inbox/notificationTemplateDetail',
    async (id: string, { dispatch, rejectWithValue }) => {
        dispatch(startTemplateLoading());
        try {
            const { data, status } = await inboxService.notificationTemplateDetail(id);

            if (status >= 400)
                throw new Error('Something went wrong');

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const editNotificationTemplate = createAsyncThunk(
    'inbox/editNotificationTemplate',
    async (options: { id: string, notification: NotificationTemplate }, { rejectWithValue }) => {
        try {
            const { data, status } = await inboxService.updateNotificationType(options.id, options.notification);

            if (status >= 400)
                throw new Error('Something went wrong');

            dispatch(resetTemplateList());

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const inboxOperations = {
    getInboxLabelsAndStatistics,
    getRecentMessages,
    getMessages,
    getMessageDetail,
    markAsStarred,
    markAsUnStarred,
    markAsImportant,
    markAsUnImportant,
    markAsRead,
    markAsUnRead,
    deleteMessages,
    getRecentInboxLabelsAndStatistics,
    getNotificationSettings,
    updateNotificationSettings,
    editNotificationTemplate,
    notificationTemplateDetail,
    searchNotificationTemplate,
    createNotificationTemplate
};

export default inboxOperations;

