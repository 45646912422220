import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { RHFTextField } from 'src/components/hook-form';
import useLocales from 'src/appHooks/useLocales';
import RHFDatePicker from 'src/components/hook-form/RHFDatePicker';
import { DEFAULT_TRANSPORT_DATA, LogisticsData, TransportData } from 'src/@types/logistics';
import { FormProvider, UseFormReturn, useFieldArray, useForm } from 'react-hook-form';
import { useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { transportDataSchema } from '../Constant';
import { TransportDataRow } from '../formRows/TransportDataRow';

type Props = {
    formMethods: UseFormReturn<LogisticsData>;
};

export const TransportDataInfo = ({ formMethods }: Props) => {

    const { control } = formMethods;

    const { translate } = useLocales();

    const [isEdit, setIsEdit] = useState<number | null>(null);

    const yupSchema = useMemo(() => transportDataSchema, []);

    const methods = useForm<TransportData>({
        mode: 'onChange',
        resolver: yupResolver<any>(yupSchema),
        defaultValues: DEFAULT_TRANSPORT_DATA,
    });

    const { getValues, clearErrors, setError, reset, watch } = methods;

    const minDate = watch("startDate");

    const validateFields = async (yupSchema: any) => {
        const formValues = getValues();

        try {
            await yupSchema.validate(formValues, { abortEarly: false });
            clearErrors();

            return true;
        } catch (err: any) {
            clearErrors();
            (err as Yup.ValidationError).inner.forEach((er) => {
                setError(er.path as any, { message: `${translate(`commons.${er.message}`)}` });
            });

            return false;
        }
    };

    const TABLE_HEAD = useMemo(
        () => [
            {
                id: 'fromLocation',
                label: `${translate('vehicle.logistic.form.fromLocation')}`,
                align: 'left',
            },
            {
                id: 'toLocation',
                label: `${translate('vehicle.logistic.form.toLocation')}`,
                align: 'left',
            },
            {
                id: 'startDate',
                label: `${translate('commons.startDate')}`,
                align: 'left',
            },
            {
                id: 'arrivalDate',
                label: `${translate('vehicle.logistic.form.arrivalDate')}`,
                align: 'left',
            },
            {
                id: 'options',
                label: ``,
                align: 'left',
            }
        ],
        [translate]
    );

    const {
        fields: transportFields,
        append: appendTransportData,
        remove: removeTransportData,
        update: updateTransportData,
    } = useFieldArray({ name: 'transport', control });

    const handleAddTransportData = async () => {
        const isValid = await validateFields(yupSchema);

        if (isValid) {
            appendTransportData({ ...getValues() });
            reset(DEFAULT_TRANSPORT_DATA);
        }
    };

    const duplicateTransportData = (index: number) => {
        appendTransportData({ ...transportFields[index] });
    };

    const handleChangeTransportData = (index: number | null) => {
        if (index !== null) {
            updateTransportData(index, { ...getValues() });
            setIsEdit(null);
            reset(DEFAULT_TRANSPORT_DATA);
        }
    };

    const handleEditTransportData = (index: number) => {
        const editedTransportData = transportFields[index];

        reset(editedTransportData);
        setIsEdit(index);
    };

    const handleCancelEditTransportData = () => {
        reset(DEFAULT_TRANSPORT_DATA);
        setIsEdit(null);
    };

    return (
        <Box>
            <Box
                sx={{
                    display: 'grid',
                    columnGap: 2,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(4, 1fr)' },
                    pt: 3
                }}
            >
                <FormProvider {...methods}>
                    <RHFTextField
                        name="fromLocation"
                        label={`${translate('vehicle.logistic.form.fromLocation')}`}
                    />
                    <RHFTextField
                        name={'toLocation'}
                        label={`${translate('vehicle.logistic.form.toLocation')}`}
                    />
                    <RHFDatePicker
                        name={'startDate'}
                        label={`${translate('commons.startDate')}`}
                    />
                    <RHFDatePicker
                        name={'arrivalDate'}
                        label={`${translate('vehicle.logistic.form.arrivalDate')}`}
                        minDate={minDate}
                    />
                </FormProvider>
            </Box>

            <Box
                sx={{
                    p: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    columnGap: 2,
                }}
            >
                {isEdit !== null ? (
                    <>
                        <Button variant="outlined" size="small" onClick={() => handleChangeTransportData(isEdit)}>
                            {`${translate('commons.edit')}`}
                        </Button>
                        <Button
                            variant="outlined"
                            color="error"
                            size="small"
                            onClick={() => handleCancelEditTransportData()}
                        >
                            {`${translate('commons.cancel')}`}
                        </Button>
                    </>
                ) : (
                    <Button variant="outlined" size="small" onClick={() => handleAddTransportData()}>
                        {`${translate('vehicle.logistic.form.addTransportData')}`}
                    </Button>
                )}
            </Box>

            {!!transportFields.length && (
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                    }}
                >
                    <TableContainer sx={{ maxHeight: '400px' }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {TABLE_HEAD.map((item) => (
                                        <TableCell
                                            sx={{ minWidth: '80px', whiteSpace: 'nowrap' }}
                                            align={item.align as 'inherit' | 'left' | 'center' | 'right' | 'justify'}
                                            key={item.id}
                                        >
                                            {item.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {transportFields.map((item, ind) => (
                                    <TransportDataRow
                                        key={ind}
                                        editableField={isEdit === ind}
                                        transportData={item}
                                        remove={() => removeTransportData(ind)}
                                        edit={() => handleEditTransportData(ind)}
                                        duplicate={() => duplicateTransportData(ind)}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}
        </Box>
    );
};
