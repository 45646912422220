import { Box, Button, Card, Container } from "@mui/material";
import Page from "src/appComponents/Page";
import HeaderBreadcrumbs from '../../../components/custom-breadcrumbs';
import { useSettingsContext } from "src/components/settings";
import useLocales from "src/appHooks/useLocales";
import { PATH_DASHBOARD } from "src/routes/paths";
import { RHFTextField } from "src/components/hook-form";
import FormProvider from "src/components/hook-form/FormProvider";
import { useForm } from "react-hook-form";
import useTenant from "src/appHooks/useTenant";
import { useCallback, useEffect, useMemo, useState } from "react";
import LoadingScreen from "src/appComponents/loading-screen/LoadingScreen";
import { LoadingButton } from "@mui/lab";
import { useLocation, useNavigate, useParams } from "react-router";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "src/redux/store";
import { brandOperations } from "src/redux/brand";
import { Brand, DEFAULT_BRAND, NewBrand } from "src/@types/brand";
import { resetBrand } from "src/redux/brand/brand-slices";
import SaveModal from "src/components/modals/SaveModal";
import { setSuccessMessage } from "src/redux/modal/modal-slices";

export default function NewEditBrand() {

    const { themeStretch } = useSettingsContext();

    const { translate, currentLang } = useLocales();

    const navigate = useNavigate();

    const { customFields } = useTenant();

    const { id } = useParams();

    const { pathname } = useLocation();

    const isEdit = pathname.includes('edit');

    const { brand, isBrandLoading } = useSelector((state: RootState) => state.brand);

    const [isFormValid, setIsFormValid] = useState(true);

    const [onEditForm, setOnEditForm] = useState(false);

    useEffect(() => {
        if (id) {
            dispatch(brandOperations.getBrand(id!));
        }
        if (!isEdit) {
            dispatch(resetBrand());
        }
    }, [id, isEdit, currentLang]);

    //---- CREATING FORM - START ----//

    const customContext = useMemo(() => {
        if (customFields) {
            return [...customFields.filter(x => x.enabled && x.entityType.toLowerCase() === "brand")];
        }
    }, [customFields]);

    const getDefaultCustomFields = useCallback(() => {
        if (customContext) {

            const customWithDef = customContext.filter(x => {
                if (x.type === "Select") {
                    return !!x.selectOptions?.find(f => f.isDefault);
                } else return !!x.defaultValue;
            });

            if (customWithDef && customWithDef.length >= 1) {
                const output = {};

                customWithDef.forEach(x => {
                    if (x.type === "Select") {
                        const def = x.selectOptions?.find(f => f.isDefault);

                        Object.assign(output, { [x.id]: def?.values[0].text });
                    } else {
                        Object.assign(output, { [x.id]: x.defaultValue });
                    }
                });

                return output;
            }
        }

        return null;
    }, [customContext]);

    const defaultCustomFields = useMemo(() => getDefaultCustomFields(), [getDefaultCustomFields]);

    const defaultValues = useMemo(() => {

        const out: Brand = {
            id: brand?.id || DEFAULT_BRAND.id,
            externalId: brand?.externalId || DEFAULT_BRAND.externalId,
            createdAt: brand?.createdAt || DEFAULT_BRAND.createdAt,
            name: brand?.name || DEFAULT_BRAND.name,
            enabled: brand?.enabled || DEFAULT_BRAND.enabled,
            customFields: isEdit ? brand?.customFields || defaultCustomFields : defaultCustomFields
        };

        return out;
    }, [brand, defaultCustomFields, isEdit]);

    const yupSchema = Yup.object().shape({
        name: Yup.string().required(`${translate('organization.form.brands.name')} ${translate('commons.required')}`),
        externalId: Yup.string().required(`${translate('organization.form.brands.id')} ${translate('commons.required')}`)
    });

    const methods = useForm<Brand>({
        mode: "onChange",
        resolver: yupResolver<any>(yupSchema),
        defaultValues
    });

    const { getValues, clearErrors, reset, trigger } = methods;

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);
    //---- CREATING FORM - END ----//

    //---- CHECK FORM VALIDITY - START ----//
    const checkValidity = useCallback(async () => {

        try {
            await yupSchema.validate(getValues(), { abortEarly: false });
            clearErrors();
            setIsFormValid(true);
        } catch (errors: any) {
            console.error(errors);
            setIsFormValid(false);
        }

    }, [clearErrors, getValues, yupSchema]);

    useEffect(() => {
        checkValidity();
    }, [checkValidity, onEditForm]);

    const onChangeValidate = async (field: any) => {
        await trigger(field, { shouldFocus: true });
    };
    //---- CHECK FORM VALIDITY - END ----//

    //---- HANDLING MODALS START ----//
    const [isOpenSave, setIsOpenSave] = useState(false);

    const toggleSave = () => {
        isOpenSave ? setIsOpenSave(false) : setIsOpenSave(true);
    };
    //---- HANDLING MODALS END ----//

    //---- HANDLING SAVE START ----//
    const customFieldRemover = (values: Brand) => {

        if (customFields && customContext) {

            const customActualValues = getValues("customFields") || {};

            customContext.forEach(customField => {
                if (customField.defaultValue && !customActualValues[customField.id]) {
                    customActualValues[customField.id] = customField.defaultValue;
                }
                delete values[customField.id];
            });
        }

        return values;
    };

    const handleSaveBrand = async () => {
        try {
            if (isFormValid) {

                const clean = customFieldRemover(getValues());

                if (clean) {

                    if (isEdit) {
                        await dispatch(brandOperations.updateBrand({ ...clean, id: brand!.id })).unwrap();
                    } else {
                        await dispatch(brandOperations.createBrand(clean as NewBrand)).unwrap();
                    }
                }
            }
            dispatch(setSuccessMessage({ text: translate('organization.form.brands.save'), goTo: PATH_DASHBOARD.brands.list }));
        } catch (e) {
            console.error(e);
        }

        toggleSave();
    };
    //---- HANDLING SAVE END ----//

    return (
        <Page title={`${translate("organization.form.brands.new")}`}>
            {isBrandLoading || (isEdit && !brand) ? <LoadingScreen /> :
                <Container maxWidth={themeStretch ? false : 'lg'}>

                    <SaveModal
                        toggle={toggleSave}
                        isOpen={isOpenSave}
                        handleSave={handleSaveBrand}
                        saveText={`${translate('organization.form.brands.saveConfirm')}`}
                    />

                    <HeaderBreadcrumbs
                        heading={!isEdit ? `${translate("organization.form.brands.new")}` : `${translate("organization.form.brands.edit")}`}
                        links={[
                            { name: `${translate("commons.home")}`, href: PATH_DASHBOARD.root },
                            { name: `${translate("organization.form.brands.title")}`, previousPage: 1 },
                            { name: !isEdit ? `${translate("organization.form.brands.new")}` : `${translate("organization.form.brands.edit")}` },
                        ]}
                    />

                    <FormProvider methods={methods}>

                        <Card sx={{ p: 3 }}>
                            <Box
                                sx={{
                                    display: 'grid',
                                    columnGap: 2,
                                    rowGap: 3,
                                    mb: 3,
                                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: (isEdit ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)') },
                                }}
                            >
                                <RHFTextField
                                    name={"name"}
                                    label={`${translate('organization.form.brands.name')}`}
                                    onChangeVal={onChangeValidate}
                                    onEditForm={onEditForm}
                                    setOnEditForm={setOnEditForm}
                                    required
                                />

                                {!isEdit &&
                                    <RHFTextField
                                        name={"externalId"}
                                        label={`${translate('commons.externalId')}`}
                                        //disabled={isEdit}
                                        onChangeVal={onChangeValidate}
                                        onEditForm={onEditForm}
                                        setOnEditForm={setOnEditForm}
                                        required={!isEdit}
                                    />
                                }

                            </Box>

                            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                                <Button variant='soft' color="inherit" onClick={() => navigate(-1)}>
                                    {`${translate("commons.cancel")}`}
                                </Button>
                                <LoadingButton
                                    variant="contained"
                                    disabled={!isFormValid}
                                    onClick={toggleSave}
                                >
                                    {!isEdit ? `${translate("organization.form.brands.createButton")}` : `${translate("organization.form.brands.updateButton")}`}
                                </LoadingButton>
                            </Box>
                        </Card>

                    </FormProvider>
                </Container>
            }
        </Page>
    );
}