import { Box, Card, IconButton, Stack, Switch, TextField, Typography } from '@mui/material';
import { useLocales } from 'src/locales';
import { useEffect, useRef, useState } from 'react';
import { RequestNew } from 'src/@types/request';
import { useDropzone } from 'react-dropzone';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { DeliveryAddressAutocomplete } from './DetailNewParts';
import { OrganizationSearchResult } from 'src/@types/organizations';
import IncrementalInput from 'src/utils/IncrementalInput';
import { CancelRounded } from '@mui/icons-material';

interface DetailProps {
  request: RequestNew;
  onChange: (name: string, value: any, item?: boolean) => void;
  available: number;
  defaultAddress: OrganizationSearchResult;
  images: File[],
  organization: string,
  isVendor: boolean
}

export default function DetailDamaged({
  request,
  onChange,
  available,
  defaultAddress,
  images,
  organization,
  isVendor,
}: DetailProps) {
  const { translate } = useLocales();

  const ref = useRef<HTMLDivElement>(null);

  const [isImage, setIsImage] = useState(false);

  const [files, setFiles] = useState<File[]>(images);

  const [noteError, setNoteError] = useState("");

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles((prev) => prev.concat(acceptedFiles));
    },
    accept: {
      'image/png': ['.png'],
      'image/jpeg': [".jpg", ".jpeg"]
    },
    maxSize: 5000000
  });

  useEffect(() => {

    onChange("images", files);

    ref.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    });

    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    //return () => imagesUrls.forEach((file) => URL.revokeObjectURL(file));

  }, [files]);

  return (
    <Card sx={{ my: 2, width: '100%', flexDirection: 'column', gap: 2, display: 'flex', p: 2 }}>
      <Typography variant="subtitle1">{`${translate('request.form.complete')}`}</Typography>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          borderRadius: '8px',
          border: '1px solid',
          borderColor: '#E2E2E2',
        }}
      >
        <Stack sx={{ m: 2, mr: 'auto' }}>
          <Typography variant="h6">{`${translate('commons.quantity')}`} <span style={{ color: 'red' }}>*</span></Typography>
          <Typography variant="body2">{`${translate('request.selectQty')}`}</Typography>
        </Stack>
        <Box
          sx={{
            m: 2,
            ml: 'auto',
            mr: 2,
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
          }}
        >
          <Typography color={'text.secondary'} variant="caption" sx={{ pr: 2 }}>
            {`${translate('statuses.available')}`}: {available}
          </Typography>
          <IncrementalInput
            onChange={(name, val) => onChange(name, val, true)}
            defaultValue={
              available > 0 ?
                (request.requestType === 'Core' ? 1 : request.items[0].quantity) : 0
            }
            disabled={request.requestType === 'Core'}
            maxValue={available}
          />
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          borderRadius: '8px',
          border: '1px solid',
          borderColor: '#E2E2E2',
        }}
      >
        <Stack sx={{ m: 2, mr: 'auto' }}>
          <Typography variant="h6">{`${translate('request.big')}`}</Typography>
          <Typography variant="body2">{`${translate('request.messages.bigDesc')}`}</Typography>
        </Stack>
        <Box sx={{ m: 2, ml: 'auto' }}>
          <Switch
            name="bigAndHeavy"
            onChange={(e) => onChange(e.target.name, e.target.checked, true)}
          />
        </Box>
      </Box>
      <Box>
        <Box>
          <Typography variant="h6">{`${translate('request.form.damageDescription')} `} <span style={{ color: 'red' }}>*</span></Typography>
          <TextField
            variant="outlined"
            name="note"
            rows={4}
            value={request.note}
            error={!!noteError}
            onBlur={(e) => setNoteError(e.target.value.length === 0 ? `${translate('commons.validation.requiredField')}` : '')}
            helperText={noteError}
            multiline
            fullWidth
            type="text"
            required
            placeholder={`${translate('commons.insertDesc')}`}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Box>
        <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row', width: '100%' }}>
          <Stack sx={{ m: 2, mr: 'auto' }}>
            <Typography variant="h6">{`${translate('request.form.damageQuestion')}`}</Typography>
            <Typography variant="body2">{`${translate('request.form.addPic')}`}</Typography>
          </Stack>
          <Box sx={{ m: 2, ml: 'auto' }}>
            <Switch
              checked={isImage}
              name="damage"
              onChange={(e) => {
                setIsImage(e.target.checked);
                onChange("pack", e.target.checked);
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{ alignItems: 'start', display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <Stack sx={{ m: 2, mr: 'auto' }}>
            <Typography variant="h6">{`${translate('request.form.uploadPhotoDoc')}`} <span style={{ color: 'red' }}>*</span></Typography>
            <Typography variant="body2">{`${translate('request.form.requiredPics')}`}</Typography>
          </Stack>

          <Box sx={{ m: 2, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 3, overflowX: 'auto', width: '100%' }}>
            {files.map((f, ind) => {

              const url = URL.createObjectURL(f);

              return (
                <Box key={ind} sx={{ position: 'relative', borderRadius: '10px' }}>
                  <img
                    style={{ maxHeight: '150px', maxWidth: '150px', borderRadius: '10px', border: '1px ridge', borderColor: '#d3d4d4' }}
                    alt={f.name}
                    src={url}
                    onLoad={() => URL.revokeObjectURL(url)}
                    onError={() => URL.revokeObjectURL(url)}
                  />
                  <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
                    <IconButton
                      aria-label="delete"
                      size="large"
                      sx={{
                        padding: '5px',
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        setFiles(prev => prev.filter((_, index) => ind !== index));
                      }}
                    >
                      <CancelRounded sx={{ color: 'text.disabled' }} />
                    </IconButton>
                  </Box>
                </Box>
              );
            })}
            <div

              {...getRootProps({
                style: {
                  display: 'flex',
                  padding: '20px',
                  height: '150px',
                  width: '150px',
                  minWidth: '150px',
                  minHeight: '150px',
                  borderRadius: '8px',
                  background: '#F4F6F8',
                  color: '#bdbdbd',
                  outline: 'none',
                  cursor: 'pointer',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                },

              })}
            >
              <input  {...getInputProps()} />
              <Box ref={ref} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <AddAPhotoIcon />
                <Typography variant="caption">{`${translate('commons.uploadPhoto')}`}</Typography>
              </Box>
            </div>
          </Box>

        </Box>
      </Box>
      <Box>
        <DeliveryAddressAutocomplete onChange={onChange} defaultAddress={defaultAddress} required organization={organization} isVendor={isVendor} />
      </Box>
    </Card >
  );
}
