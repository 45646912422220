import { Container } from "@mui/system";
import Page from "src/appComponents/Page";
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { PATH_DASHBOARD } from "src/routes/paths";
import { useSettingsContext } from "src/components/settings";
import { useLocales } from "src/locales";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import OrganizationDetail from "./OrganizationDetail";

interface OrganizationDetailPageProps {
    isVendor: boolean
}

export default function OrganizationDetailPage({ isVendor }: OrganizationDetailPageProps) {

    const { organization } = useSelector((state: RootState) => state.organizations);

    const { themeStretch } = useSettingsContext();

    const { translate } = useLocales();

    const backPath = isVendor ? PATH_DASHBOARD.organizations.vendor : PATH_DASHBOARD.organizations.customer;

    return (
        <Page title={`${translate("organization.detail.title")}`}>
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs
                    heading={`${translate('organization.detail.title')}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate("menu.management.organizations.list")}`, href: backPath.list },
                        { name: organization?.name }
                    ]}
                />

                <OrganizationDetail isVendor={isVendor} />

            </Container>
        </Page >
    );
}
