import { createSlice } from "@reduxjs/toolkit";
import { OrganizationSearchResult, GenericOrganization, Statistic, GenericOrganizationEditWithFather, OrganizationSurveillance } from "src/@types/organizations";
import organizationsOperations from "./organizations-operations";
import { ErrorResponse, PagedResponseType } from "src/@types/commons";

interface OrganizationsReducer {
    error: ErrorResponse,
    isLoading: boolean,
    isOwnersLoading: boolean,
    owners: GenericOrganization[] | null;
    customerCareVendors: GenericOrganization[] | null;
    customerCareLoading: boolean,
    organization: GenericOrganization | null,
    organizationEdit: GenericOrganizationEditWithFather | null,
    pageIndex: number,
    pageSize: number,
    totalCount: number,
    organizationList: OrganizationSearchResult[],
    nonEditables: string[],
    statistics: Statistic | null,
    vendorOrganizationsPowersearch: OrganizationSearchResult[],
    customerOrganizationsPowersearch: OrganizationSearchResult[],
    isMyOrganizationPowersearchLoading: boolean,
    isCustomerOrganizationPowersearchLoading: boolean,
    isSearchLoading: boolean,
    hub: GenericOrganization | null,
    logsLoading: boolean,
    logsList: PagedResponseType<any>,
    surveillance: OrganizationSurveillance | null
};

const initialState: OrganizationsReducer = {
    error: null,
    isLoading: false,
    isSearchLoading: false,
    isOwnersLoading: false,
    customerCareLoading: false,
    organization: null,
    pageIndex: 0,
    pageSize: 10,
    totalCount: 0,
    organizationList: [],
    nonEditables: [],
    owners: null,
    customerCareVendors: null,
    statistics: null,
    vendorOrganizationsPowersearch: [],
    customerOrganizationsPowersearch: [],
    isMyOrganizationPowersearchLoading: false,
    isCustomerOrganizationPowersearchLoading: false,
    hub: null,
    logsLoading: false,
    organizationEdit: null,
    logsList: {
        pageIndex: 0,
        pageSize: 0,
        items: [],
        totalCount: 0
    },
    surveillance: null
};

const OrganizationsSlice = createSlice({
    name: 'organization',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(organizationsOperations.createGeneric.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(organizationsOperations.createGeneric.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(organizationsOperations.updateGeneric.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(organizationsOperations.updateGeneric.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(organizationsOperations.getGeneric.fulfilled, (state, action) => {
                state.organization = action.payload as GenericOrganization;
                state.isLoading = false;
            })
            .addCase(organizationsOperations.getGeneric.rejected, (state, action) => {
                state.organization = initialState.organization;
                state.isLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(organizationsOperations.getHub.fulfilled, (state, action) => {
                state.hub = action.payload;
                state.isLoading = false;
            })
            .addCase(organizationsOperations.getHub.rejected, (state, action) => {
                state.hub = initialState.hub;
                state.isLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(organizationsOperations.searchVendorOrganizations.fulfilled, (state, action) => {
                state.isSearchLoading = false;

                const response = action.payload;

                state.organizationList = response.items;
                state.pageIndex = response.pageIndex;
                state.pageSize = response.pageSize;
                state.totalCount = response.totalCount;
            })
            .addCase(organizationsOperations.searchVendorOrganizations.rejected, (state, action) => {
                state.organizationList = initialState.organizationList;
                state.pageIndex = initialState.pageIndex;
                state.pageSize = initialState.pageSize;
                state.totalCount = initialState.totalCount;;
                state.isSearchLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(organizationsOperations.searchCustomerOrganizations.fulfilled, (state, action) => {
                state.isSearchLoading = false;

                const response = action.payload;

                state.organizationList = response.items;
                state.pageIndex = response.pageIndex;
                state.pageSize = response.pageSize;
                state.totalCount = response.totalCount;
            })
            .addCase(organizationsOperations.searchCustomerOrganizations.rejected, (state, action) => {
                state.organizationList = initialState.organizationList;
                state.pageIndex = initialState.pageIndex;
                state.pageSize = initialState.pageSize;
                state.totalCount = initialState.totalCount;;
                state.isSearchLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(organizationsOperations.searchVendorOrganizationsPowerSearch.pending, (state) => {
                state.isMyOrganizationPowersearchLoading = true;
            })
            .addCase(organizationsOperations.searchVendorOrganizationsPowerSearch.fulfilled, (state, action) => {
                state.vendorOrganizationsPowersearch = action.payload.items;
                state.isMyOrganizationPowersearchLoading = false;
            })
            .addCase(organizationsOperations.searchVendorOrganizationsPowerSearch.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.vendorOrganizationsPowersearch = initialState.vendorOrganizationsPowersearch;
                state.isMyOrganizationPowersearchLoading = false;
            })
            .addCase(organizationsOperations.searchCustomerOrganizationsPowerSearch.pending, (state) => {
                state.isCustomerOrganizationPowersearchLoading = true;
            })
            .addCase(organizationsOperations.searchCustomerOrganizationsPowerSearch.fulfilled, (state, action) => {
                state.customerOrganizationsPowersearch = action.payload.items;
                state.isCustomerOrganizationPowersearchLoading = false;
            })
            .addCase(organizationsOperations.searchCustomerOrganizationsPowerSearch.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.customerOrganizationsPowersearch = initialState.customerOrganizationsPowersearch;
                state.isCustomerOrganizationPowersearchLoading = false;
            })
            .addCase(organizationsOperations.getNonEditables.fulfilled, (state, action) => {
                state.nonEditables = (action.payload as string[]).map((str) => {
                    let output = str;
                    if (str.includes("customFields")) {
                        output.replace("customFields.", "");
                    }

                    return output;
                });
                state.isLoading = false;
            })
            .addCase(organizationsOperations.getNonEditables.rejected, (state, action) => {
                state.nonEditables = initialState.nonEditables;
                state.isLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(organizationsOperations.searchVendorStatistic.fulfilled, (state, action) => {
                state.statistics = action.payload;
                state.isLoading = false;
            })
            .addCase(organizationsOperations.searchVendorStatistic.rejected, (state, action) => {
                state.statistics = initialState.statistics;
                state.isLoading = false;
            })
            .addCase(organizationsOperations.searchCustomerStatistic.fulfilled, (state, action) => {
                state.statistics = action.payload;
                state.isLoading = false;
            })
            .addCase(organizationsOperations.searchCustomerStatistic.rejected, (state, action) => {
                state.statistics = initialState.statistics;
                state.isLoading = false;
            })
            .addCase(organizationsOperations.getOrganizationLogs.rejected, (state, action) => {
                state.logsLoading = false;
                state.error = action.error.message as string;
                state.logsList = initialState.logsList;
            })
            .addCase(organizationsOperations.getOrganizationLogs.fulfilled, (state, action) => {
                state.logsLoading = false;
                state.logsList = action.payload;
            })
            .addCase(organizationsOperations.getOwner.fulfilled, (state, action) => {
                state.owners = action.payload;
                state.isOwnersLoading = false;
            })
            .addCase(organizationsOperations.getOwner.rejected, (state, action) => {
                state.owners = initialState.owners;
                state.isOwnersLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(organizationsOperations.getCustomerCareVendors.rejected, (state, action) => {
                state.customerCareVendors = initialState.customerCareVendors;
                state.customerCareLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(organizationsOperations.getCustomerCareVendors.fulfilled, (state, action) => {
                state.customerCareVendors = action.payload;
                state.customerCareLoading = false;
            })
            .addCase(organizationsOperations.getDetailForEdit.rejected, (state, action) => {
                state.isLoading = false;
                state.organizationEdit = null;
                state.error = action.error.message as string;
            })
            .addCase(organizationsOperations.getDetailForEdit.fulfilled, (state, action) => {
                state.organizationEdit = action.payload as GenericOrganizationEditWithFather;
                state.isLoading = false;
            })
            .addCase(organizationsOperations.getOrganizationSurveillance.rejected, (state, action) => {
                state.isLoading = false;
                state.surveillance = initialState.surveillance;
                state.error = action.error.message as string;
            })
            .addCase(organizationsOperations.getOrganizationSurveillance.fulfilled, (state, action) => {
                state.surveillance = action.payload;
                state.isLoading = false;
            })
            ;
    },
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        startSearchLoading(state) {
            state.isSearchLoading = true;
        },
        startOwnerLoading(state) {
            state.isOwnersLoading = true;
        },
        startCustomerCareLoading(state) {
            state.customerCareLoading = true;
        },
        resetOrganization(state) {
            state.organization = null;
            state.organizationEdit = null;
        },
        startMyPowerSearchLoading(state) {
            state.isMyOrganizationPowersearchLoading = true;
        },
        startCustomerPowerSearchLoading(state) {
            state.isMyOrganizationPowersearchLoading = true;
        },
        startLogsLoading(state) {
            state.logsLoading = true;
        },
        //RESET ORGANIZATION LIST
        resetPageIndexSize(state) {
            state.pageIndex = 0;
            state.pageSize = 10;
        },
        resetOrganizationList(state) {
            state.organizationList = initialState.organizationList;
        }
    }
});

export const {
    startLoading,
    resetOrganization,
    startSearchLoading,
    startLogsLoading,
    startCustomerPowerSearchLoading,
    startMyPowerSearchLoading,
    startOwnerLoading,
    resetPageIndexSize,
    resetOrganizationList,
    startCustomerCareLoading
} = OrganizationsSlice.actions;

export default OrganizationsSlice.reducer;
