import { Container } from '@mui/material';
import useLocales from 'src/appHooks/useLocales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import Page from 'src/appComponents/Page';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import ReportList from 'src/sections/@dashboard/report/ReportList';
import { useEffect, useState } from 'react';

interface ReportListRedirectProps {
    isScheduled?: boolean
}

export default function ReportListRedirect({ isScheduled }: ReportListRedirectProps) {

    const { themeStretch } = useSettingsContext();

    const { translate } = useLocales();

    const [firstRender, setFirstRender] = useState(true);

    useEffect(() => {
        if (!firstRender) setFirstRender(true);
    }, [isScheduled]);

    return (
        <Page title={`${translate(isScheduled ? 'menu.management.reports.scheduled.title' : 'menu.management.reports.recurring.title')}`}>
            <Container maxWidth={themeStretch ? false : 'lg'}>

                <HeaderBreadcrumbs
                    heading={`${translate(isScheduled ? 'menu.management.reports.scheduled.title' : 'menu.management.reports.recurring.title')}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate(isScheduled ? 'menu.management.reports.scheduled.short' : 'menu.management.reports.recurring.short')}` }
                    ]}
                />

                <ReportList
                    isScheduled={isScheduled}
                    firstRender={firstRender}
                    setFirstRender={setFirstRender}
                />

            </Container>
        </Page>
    );
}