import { Typography, Box, Divider } from "@mui/material";
import { useEffect, useMemo } from "react";
import { Order } from "src/@types/orders";
import { Media, MediaFileInfo } from "src/@types/request";
import MediaSlider from "src/components/mediaSlider/MediaSlider";
import MoreDetails from "src/components/request-utils/MoreDetails";
import { useLocales } from "src/locales";

interface CompleteDamagedProps {
    request: any,
    order: Order
    files: File[]
}

export default function CompleteDamaged({ request, order, files }: CompleteDamagedProps) {

    const { translate } = useLocales();

    const imagesUrls = useMemo(() => files.map(i => URL.createObjectURL(i)), [files]);

    useEffect(() => {
        //avoid memory leak
        return () => imagesUrls.forEach(i => URL.revokeObjectURL(i));
    }, [imagesUrls]);

    const getMedia = (imagesUrls: string[]) => {
        return imagesUrls.map((url) => {
            return {
                section: "",
                mediaType: "Image",
                fileInfo: [{
                    url: url,
                    watermark: false,
                    width: 250,
                    height: 250,
                    size: 0
                } as MediaFileInfo]
            } as Media;
        });
    };

    return (
        <Box sx={{ p: 2 }}>

            <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1">
                    {`${translate('commons.description')}`}
                </Typography>

                <Typography variant="body1" sx={{ mb: 2 }}>
                    {request.note}
                </Typography>

                <Typography variant="subtitle1">{`${translate('request.form.damageDocuments')}`}</Typography>
                {files.length > 0 &&
                    /*                     <Box sx={{ m: 2, display: 'flex', flexDirection: 'row', gap: 3, overflowX: 'auto' }}>
                                            {files.map((file: File, ind: number) => (
                                                <Box key={ind} sx={{ borderRadius: '10px' }}>
                                                    <img
                                                        style={{ maxHeight: '150px', maxWidth: '150px', borderRadius: '10px', border: '1px ridge', borderColor: '#d3d4d4' }}
                                                        alt={file.name}
                                                        src={imagesUrls[ind]}
                                                    />
                                                </Box>
                                            ))}
                                        </Box> */
                    <Box sx={{ pt: 2 }}>
                        <MediaSlider images={getMedia(imagesUrls)} blockZoom />
                    </Box>
                }
            </Box>

            <Divider sx={{ mb: 2 }} />

            {order && <MoreDetails order={order} />}

        </Box >
    );
}