import { Box, Card, Tab, Tabs, TextField, Select, MenuItem, Button, TextFieldProps } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import { Lang, useLocales } from "src/locales";
import CloseIcon from '@mui/icons-material/Close';
import { LocalizedString } from "src/@types/commons";
import * as Yup from 'yup';
import RichEditor from "src/components/richEditor/RichEditor";
import { SystemStyleObject } from "@mui/system";
import { RichLocalizedString } from "src/@types/webshop";

export const getCountries = (tab: LocalizedString | null, allTabs: LocalizedString[], allLangs: Lang[]) => {

    const selected = new Set(allTabs.map(x => x.culture));

    let result = [...allLangs].filter(x => !selected.has(x.value) && x.value !== 'en');

    if (tab && tab.culture !== 'en') {
        const tmp = allLangs.find(x => x.value === tab.culture);

        tmp && result.push(tmp);
    }

    return result;
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

interface LocalizedInputProps {
    label?: string,
    onChangeTabs: (tabs: LocalizedString[]) => void,
    reset?: boolean,
    error?: boolean,
    defaultTabs?: LocalizedString[],
    onSubmit?: (tabs: LocalizedString[]) => void,
    onChangeLang?: (lang: string) => void,
    children?: ReactNode
    onBlur?: (errors: Record<number, string>) => void
}

const schema = Yup.object().shape({
    text: Yup.string().trim().required()
});
/**
 * Component for language personalization, 
 * through which the user can provide a direct translation for the input in the chosen languages.
 * 
 * @param props
 * -onChangeTabs: call back function callback function to retrieve the list of values whenever it is modified
 * 
 * -onSubmit: call back function, add a button and return the list of values when the button is pressed
 * 
 * -onChangeLang: call back function to pass the current selected lang
 * 
 * -reset: boolean for resetting all values
 * 
 * -defaultTabs: like default value
 * 
 * -error: if true highlight the first language as error
 * @returns 
 */

export default function LocalizedInput(props: LocalizedInputProps & TextFieldProps) {

    const [val, setVal] = useState<boolean | number>(false);

    const [defaultText, setDefaultText] = useState<string | null>(props.defaultTabs?.length ? props.defaultTabs[0].text : '');

    const [tabs, setTabs] = useState<LocalizedString[]>(props.defaultTabs?.slice(1) || []);

    const [errors, setErrors] = useState<Record<number, string>>({});

    const { allLangs, translate } = useLocales();

    useEffect(() => {
        setVal(0);
    }, []);

    useEffect(() => {
        if (props.reset) {
            setTabs([]);
            setDefaultText('');
            setVal(0);
        }
    }, [props.reset]);

    const onC = (e: any) => {
        let t = tabs;
        if (getCountries(null, tabs, allLangs).length > 0) {
            t.push({ text: '', culture: getCountries(null, tabs, allLangs)[0].value });
            props.onChangeLang && props.onChangeLang(t.slice(-1)[0].culture);
        }
        setTabs(t);
        props.onChangeTabs([{ culture: 'En', text: defaultText }].concat(t));
    };

    const handleChange = (e: any, val: any) => {
        setVal(val);
        if (val === 0)
            props.onChangeLang && props.onChangeLang('En');
        else
            tabs[val - 1] && props.onChangeLang && props.onChangeLang(tabs[val - 1].culture);
    };

    const handleDefault = (e: any) => {
        setDefaultText(e.target.value);
        props.onChangeTabs([{ culture: 'En', text: e.target.value }].concat(tabs));
    };

    const handleDelete = (e: any, index: any) => {
        e.stopPropagation();

        const tab = tabs.filter((_, i) => i !== index);

        setTabs(tab);
        let tmp = +val;
        if (tmp > index) {
            tmp--;
        }
        if (tmp < 0)
            tmp = 0;
        setVal(tmp);
        if (tmp === 0)
            props.onChangeLang && props.onChangeLang('En');
        else
            tab[tmp] && props.onChangeLang && props.onChangeLang(tab[tmp].culture);
        props.onChangeTabs([{ culture: 'En', text: defaultText }].concat(tab));
    };

    const handleCulture = (e: any, index: number) => {
        const tab = tabs.map((tab, i) => {
            if (i === index) {
                props.onChangeLang && props.onChangeLang(e.target.value);

                return { ...tab, culture: e.target.value };
            }

            return tab;
        });

        setTabs(tab);
        props.onChangeTabs([{ culture: 'En', text: defaultText }].concat(tab));
    };

    const handleText = (e: any, index: number) => {
        const tab = tabs.map((tab, i) => {
            if (i === index) {
                return { ...tab, text: e.target.value };
            }

            return tab;
        });

        setTabs(tab);
        props.onChangeTabs([{ culture: 'En', text: defaultText }].concat(tab));
    };

    const sub = () => {
        if (tabs.length === 0 && !defaultText)
            return;
        props.onSubmit && props.onSubmit([{ culture: 'En', text: defaultText }].concat(tabs));
        setTabs([]);
        setDefaultText('');
        setVal(0);
    };

    const blur = (e: any, index: number) => {
        let error = errors;
        schema.validateAt('text', { text: [{ culture: 'En', text: defaultText }].concat(tabs)[index].text }).then(
            () => {
                setErrors(prev => ({ ...prev, [index]: '' }));
                error = { ...error, [index]: '' };
            }
        ).catch(err => {
            setErrors(prev => ({ ...prev, [index]: err.message }));
            error = { ...error, [index]: err.message };

        }).finally(
            () => props.onBlur && props.onBlur(error)
        );
    };

    return (
        <Card sx={{ p: 3 }}>

            <Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={val} onChange={handleChange}>
                        <Tab label='en' />
                        {tabs.map((value, index) => (<Tab key={index} label={value.culture} icon={<CloseIcon onClick={(e) => handleDelete(e, index)} />} />))}
                        <Tab label={<AddIcon />} onClick={onC} />
                    </Tabs>
                </Box>

                <TabPanel value={+val} index={0}>
                    <TextField
                        onBlur={(e) => blur(e, 0)}
                        sx={{ width: '50%', '& .MuiInputLabel-asterisk': { color: 'error.main' } }}
                        error={!!errors[0]}
                        helperText={errors[0]}
                        required
                        name='defal'
                        value={defaultText}
                        label={props.label}
                        onChange={(e) => { handleDefault(e); }}
                    />
                </TabPanel>

                {tabs.map((value, index) => (

                    <TabPanel key={index} value={+val} index={index + 1}>
                        <Select
                            name='culture'
                            label={`${translate('commons.culture')}`}
                            value={value.culture}
                            onChange={(e) => handleCulture(e, index)}
                            sx={{ mr: 2 }}
                        >
                            {getCountries(value, tabs, allLangs).map((l) => (
                                <MenuItem key={l.icon} value={l.value}>{l.label}</MenuItem>
                            ))}
                        </Select>

                        <TextField
                            onBlur={(e) => blur(e, index + 1)}
                            sx={{ width: '50%' }}
                            error={!!errors[index + 1]}
                            helperText={errors[index + 1]}
                            name='name'
                            value={value.text}
                            label={props.label}
                            onChange={(e) => handleText(e, index)}
                        />
                    </TabPanel>))}

                {props.children}

                {props.onSubmit &&
                    <Button onClick={() => sub()} type="submit" variant="contained">
                        {`${translate('commons.add')}`}
                    </Button>
                }

            </Box>
        </Card>
    );
}

interface RichLocalizedInputProps {
    label?: string,
    onChangeTabs: (tabs: RichLocalizedString[]) => void,
    reset?: boolean,
    error?: boolean,
    defaultTabs?: RichLocalizedString[],
    onSubmit?: (tabs: RichLocalizedString[]) => void,
    onChangeLang?: (lang: string) => void,
    children?: ReactNode
    onBlur?: (errors: Record<number, string>) => void,
}

export function RichLocalizedInput(props: RichLocalizedInputProps & TextFieldProps) {

    const [val, setVal] = useState<boolean | number>(false);

    const [defaultText, setDefaultText] = useState<string | null>(props.defaultTabs?.length ? props.defaultTabs[0].text : '');

    const [defaultNormal, setDefaultNormal] = useState<string | null>(props.defaultTabs?.length ? props.defaultTabs[0].text : '');

    const [tabs, setTabs] = useState<RichLocalizedString[]>(props.defaultTabs?.slice(1) || []);

    const { allLangs, translate } = useLocales();

    useEffect(() => {
        setVal(0);
    }, []);

    const getCountries = (tab: RichLocalizedString | null) => {

        const selected = new Set(tabs.map(x => x.culture));

        let result = [...allLangs].filter(x => !selected.has(x.value) && x.value !== 'en');

        if (tab && tab.culture !== 'en') {
            const tmp = allLangs.find(x => x.value === tab.culture);

            tmp && result.push(tmp);
        }

        return result;
    };

    useEffect(() => {
        if (props.reset) {
            setTabs([]);
            setDefaultText('');
            setVal(0);
        }
    }, [props.reset]);

    const onC = (e: any) => {
        let t = tabs;
        if (getCountries(null).length > 0) {
            t.push({ text: '', culture: getCountries(null)[0].value });
            props.onChangeLang && props.onChangeLang(t.slice(-1)[0].culture);
        }
        setTabs(t);
        props.onChangeTabs([{ culture: 'En', text: defaultText, normalText: defaultNormal } as RichLocalizedString].concat(t));
    };

    const handleChange = (e: any, val: any) => {
        setVal(val);
        if (val === 0)
            props.onChangeLang && props.onChangeLang('En');
        else
            tabs[val - 1] && props.onChangeLang && props.onChangeLang(tabs[val - 1].culture);
    };

    const handleDefault = (e: string | null, normal: string) => {
        setDefaultText(e);
        setDefaultNormal(normal);
        props.onChangeTabs([({ culture: 'En', text: e, normalText: normal }) as RichLocalizedString].concat(tabs));
    };

    const handleDelete = (e: any, index: any) => {
        e.stopPropagation();

        const tab = tabs.filter((_, i) => i !== index);

        setTabs(tab);
        let tmp = +val;
        if (tmp > index) {
            tmp--;
        }
        if (tmp < 0)
            tmp = 0;
        setVal(tmp);
        if (tmp === 0)
            props.onChangeLang && props.onChangeLang('En');
        else
            tab[tmp] && props.onChangeLang && props.onChangeLang(tab[tmp].culture);
        props.onChangeTabs([{ culture: 'En', text: defaultText, normalText: defaultNormal } as RichLocalizedString].concat(tab));
    };

    const handleCulture = (e: any, index: number) => {
        const tab = tabs.map((tab, i) => {
            if (i === index) {
                props.onChangeLang && props.onChangeLang(e.target.value);

                return { ...tab, culture: e.target.value };
            }

            return tab;
        });

        setTabs(tab);
        props.onChangeTabs([{ culture: 'En', text: defaultText, normalText: defaultNormal } as RichLocalizedString].concat(tab));
    };

    const handleText = (e: string | null, normal: string | null, index: number) => {
        const tab = tabs.map((tab, i) => {
            if (i === index) {
                return { ...tab, text: e, normalText: normal };
            }

            return tab;
        });

        setTabs(tab);
        props.onChangeTabs([{ culture: 'En', text: defaultText, normalText: defaultNormal } as RichLocalizedString].concat(tab));
    };

    const sub = () => {
        if (tabs.length === 0 && !defaultText)
            return;
        props.onSubmit && props.onSubmit([{ culture: 'En', text: defaultText, normalText: defaultNormal } as RichLocalizedString].concat(tabs));
        setTabs([]);
        setDefaultText('');
        setVal(0);
    };

    // const blur = (e: any, index: number) => {
    //     let error = errors;
    //     schema.validateAt('text', { text: [{ culture: 'En', text: defaultText }].concat(tabs)[index].text }).then(
    //         () => {
    //             setErrors(prev => ({ ...prev, [index]: '' }));
    //             error = { ...error, [index]: '' };
    //         }
    //     ).catch(err => {
    //         setErrors(prev => ({ ...prev, [index]: err.message }));
    //         error = { ...error, [index]: err.message };

    //     }).finally(
    //         () => props.onBlur && props.onBlur(error)
    //     );
    // };

    return (
        <Box sx={{ p: 3 }}>

            <Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={val} onChange={handleChange}>
                        <Tab label='en' />
                        {tabs.map((value, index) => (<Tab key={index} label={value.culture} icon={<CloseIcon onClick={(e) => handleDelete(e, index)} />} />))}
                        <Tab label={<AddIcon />} onClick={onC} />
                    </Tabs>
                </Box>

                <TabPanel value={+val} index={0}>
                    <RichEditor
                        sx={(props.sx as SystemStyleObject) ?? { minHeight: '20vh', px: 3, }}
                        value={defaultText ?? undefined}
                        onChange={(val, normal) => handleDefault(val, normal)}
                        error={props.error}
                        errorHelperText={props.helperText?.toString()}
                    />
                    {/* <TextField
                        onBlur={(e) => blur(e, 0)}
                        sx={{ width: '50%', '& .MuiInputLabel-asterisk': { color: 'error.main' } }}
                        error={!!errors[0]}
                        helperText={errors[0]}
                        required
                        name='defal'
                        value={defaultText}
                        label={props.label}
                        onChange={(e) => { handleDefault(e); }}
                    /> */}
                </TabPanel>

                {tabs.map((value, index) => (

                    <TabPanel key={index} value={+val} index={index + 1}>
                        <TextField
                            select
                            name='culture'
                            label={`${translate('commons.culture')}`}
                            value={value.culture}
                            onChange={(e) => handleCulture(e, index)}
                            sx={{ mr: 2, mb: 2 }}
                        >
                            {getCountries(value).map((l) => (
                                <MenuItem key={l.icon} value={l.value}>{l.label}</MenuItem>
                            ))}
                        </TextField>

                        <RichEditor
                            sx={(props.sx as SystemStyleObject) ?? { minHeight: '20vh', px: 3, }}
                            value={value.text ?? undefined}
                            onChange={(val, normal) => handleText(val, normal, index)}
                            error={props.error}
                            errorHelperText={props.helperText?.toString()}
                        />

                    </TabPanel>))}

                {props.children}

                {props.onSubmit &&
                    <Button onClick={() => sub()} type="submit" variant="contained">
                        {`${translate('commons.add')}`}
                    </Button>
                }

            </Box>
        </Box>
    );
}