import { memo } from 'react';
import { Stack } from '@mui/material';
import { hideScrollbarY } from '../../../utils/cssStyles';
import { NavSectionProps, NavListProps } from '../types';
import NavList from './NavList';
import useTenant from 'src/appHooks/useTenant';
import { hasLicense } from 'src/utils/tenant';
import { useAuth } from 'src/appHooks/useAuth';
import { useUserOrganizationContext } from 'src/contexts/UserOrganizationContext';
import { hasPermissions } from 'src/utils/user';
import LicenseGuard from 'src/guards/LicenseGuard';

// ----------------------------------------------------------------------

function NavSectionHorizontal({ data, sx, ...other }: NavSectionProps) {

  const { user } = useAuth();

  const tenant = useTenant();

  const { organization } = useUserOrganizationContext();

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        mx: 'auto',

        ...sx,
      }}
      {...other}
    >
      {data.map((group) => {

        if (group.forGod && !user.isGod) {
          return null;
        }

        if (tenant.licenses && group.license && !hasLicense(tenant, group.license)) {
          return null;
        }

        if (!group.permissions || hasPermissions(group.permissions, user.roles, false) || hasPermissions(group.permissions, organization.roles, false)) {
          const key = group.subheader || group.items[0].title;

          return <Items key={key} items={group.items} />;
        }

        return <></>;

      })}
    </Stack>
  );
}

export default memo(NavSectionHorizontal);

// ----------------------------------------------------------------------

type ItemsProps = {
  items: NavListProps[];
};

function Items({ items }: ItemsProps) {
  return (
    <>
      {items.map((list, index) => (
        <LicenseGuard key={index} license={list.license}>
          <NavList
            data={list}
            depth={1}
            hasChild={!!list.children}
          />
        </LicenseGuard>
      ))}
    </>
  );
}
