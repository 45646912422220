import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { useLocales } from 'src/locales';

interface LogsTableRowProps {
  row: {
    date: string,
    time: string,
    loggedUser: string,
    description: string
  };
}

const LogsTableRow = ({ row }: LogsTableRowProps) => {

  const { translate } = useLocales();

  return (
    <TableRow sx={{ borderBottom: '1px solid', borderBottomColor: 'grey.300' }}>
      <TableCell align="center">
        <Typography variant='body1'>{row.date}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant='body1'>{row.time}</Typography>
      </TableCell>
      <TableCell align="center">
        <Box>
          <Typography variant="body1">{row.loggedUser}</Typography>
        </Box>
      </TableCell>
      <TableCell sx={{ display: 'flex' }}>
        <Typography variant="body1">{`${translate(row.description)}`}</Typography>
      </TableCell>
    </TableRow >
  );
};

export default LogsTableRow;
