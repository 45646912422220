import { Box, Card, FormControlLabel, Switch, TablePagination, Typography } from "@mui/material";
import { DEFAULT_STRUCTURE_FILTERS_VALUE, GenericOrganization, StructureFilter } from "src/@types/organizations";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { convert } from 'src/utils/currency';
import { useTable } from "src/components/table";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "src/redux/store";
import { useLocales } from "src/locales";
import Label from 'src/components/label';
import { CurrencyTypes } from "src/@types/vehicle";
import useCurrency from "src/appHooks/useCurrency";
import { useSettingsContext } from "src/components/settings";
import { PATH_DASHBOARD } from "src/routes/paths";
import { brandOperations } from "src/redux/brand";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { organizationsOperations } from "src/redux/organizations";
import { DataGrid, GridCellParams, GridSortModel } from "@mui/x-data-grid";
import { noData } from "src/components/empty-content/EmptyContent";
import { DataGridStyle } from "src/utils/DataGridStyle";
import { useForm } from "react-hook-form";
import { remove } from "lodash";
import { ToolbarSearchFilters } from "src/@types/commons";
import FormProvider from "src/components/hook-form";
import OrganizationGeneralFilters from "../../filters/OrganizationGeneralFilters";

interface StructureOrganizationDetailProps {
    organization: GenericOrganization;
    isVendor: boolean
};

//TODO: mettere in utils
const DEFAULT_OPTIONS = {
    pageIndex: 0,
    pageSize: 10,
    sortField: "",
    sortAscending: true,
    all: ""
};

export default function StructureOrganizationDetail({ organization, isVendor }: StructureOrganizationDetailProps) {

    const {
        page,
        order,
        orderBy,
        rowsPerPage,
        onChangePage,
        onChangeDense,
        dense,
        onChangeRowsPerPage,
        setOrder,
        setOrderBy,
        setPage
    } = useTable();

    const { organizationList, totalCount, isSearchLoading } = useSelector((state: RootState) => state.organizations);

    const { isLoading: brandLoading, publicBrandList } = useSelector((state: RootState) => state.brand);

    const { translate, currentLang } = useLocales();

    const navigate = useNavigate();

    const { rates } = useCurrency();

    const { id, orgId } = useParams();

    const { currency: currentCurrency } = useSettingsContext();

    const [filters, setFilters] = useState<StructureFilter>(DEFAULT_STRUCTURE_FILTERS_VALUE);

    const backPath = isVendor ? PATH_DASHBOARD.organizations.vendor : PATH_DASHBOARD.organizations.customer;

    const [lastUsedLang, setLastUsedLang] = useState(currentLang.label);

    useEffect(() => {
        if (!publicBrandList)
            dispatch(brandOperations.getBrandList(false));
    }, [publicBrandList]);

    const methods = useForm({ defaultValues: DEFAULT_STRUCTURE_FILTERS_VALUE });

    const { reset, getValues } = methods;

    //---- IS DEFAULT FUNC ----//
    // Checks if there are some filters selected
    const keysToRemove: string[] = useMemo(() => ["pageIndex", 'pageSize', "sortField", "sortAscending"], []);

    const keyRemover = (key: string) => {
        return !(keysToRemove.includes(key));
    };

    const isDefault = (filter: StructureFilter) => {

        const found = remove(Object.keys(DEFAULT_STRUCTURE_FILTERS_VALUE), keyRemover).find((element) => (filter[element] && filter[element] !== DEFAULT_STRUCTURE_FILTERS_VALUE[element]));

        return (!found);
    };

    //---- TOOLBAR OPTIONS ----//
    const toolbarFiltersList: ToolbarSearchFilters[] = useMemo(() =>
        [
            { key: 'all', label: `${translate('commons.all')}` },
            { key: 'name', label: `${translate('commons.name')}` },
            { key: 'externalId', label: `${translate('commons.code')}` }
        ]
        , []);

    //---- FETCH DATA FUNC ----//
    // Gets all filter values ​​other than the default ones and puts them in the url
    const fetchData = useCallback(async (values: StructureFilter) => {

        var searchFilters: any = {};

        if (values === DEFAULT_STRUCTURE_FILTERS_VALUE) {
            searchFilters = DEFAULT_OPTIONS;
        } else {

            searchFilters = {
                pageIndex: page,
                pageSize: rowsPerPage,
                sortField: orderBy,
                sortAscending: order === 'desc' ? false : true,
                all: values.all !== DEFAULT_STRUCTURE_FILTERS_VALUE.all ? values.all : null,
                externalId: values.externalId !== DEFAULT_STRUCTURE_FILTERS_VALUE.externalId ? values.externalId : null,
                name: values.name !== DEFAULT_STRUCTURE_FILTERS_VALUE.name ? values.name : null,
            };
        }

        updateFiltersInUrl(searchFilters);
    }, [getValues, order, orderBy, page, rowsPerPage]);

    //---- FILTERS IN URL GET/SET START ----//
    const location = useLocation();

    const removeHash = () => {
        var uri = window.location.toString();

        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#"));

            window.history.replaceState({}, document.title, clean_uri);
        }
    };

    const updateFiltersInUrl = (filters: any) => {

        const queryString = Object.keys(filters).filter(x => filters[x] && filters[x] !== DEFAULT_STRUCTURE_FILTERS_VALUE[x])
            .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`)
            .join('&');

        window.location.hash = queryString;

        if (!queryString) removeHash();
    };

    const getFiltersFromUrl = () => {
        const { hash } = location;

        let searchFilters = {};
        if (hash) {

            const cleanedHash = hash.slice(1);

            const decodedQuery = decodeURIComponent(cleanedHash);

            const searchParams = new URLSearchParams(decodedQuery);

            searchFilters = {
                pageIndex: page,
                pageSize: rowsPerPage,
                sortField: orderBy || null,
                sortAscending: order === 'desc' ? false : true,
                all: searchParams.get('all'),
                externalId: searchParams.get('externalId'),
                name: searchParams.get('name')
            };

        } else {

            searchFilters = {
                pageIndex: page,
                pageSize: rowsPerPage,
                sortField: orderBy || null,
                sortAscending: order === 'desc' ? false : true
            };
        }

        searchFilters = Object.fromEntries(Object.entries(searchFilters).filter(([_, value]) => value !== null && value !== 'null'));

        return searchFilters as StructureFilter;
    };
    //---- FILTERS IN URL GET/SET END ----//

    //---- SEARCH FOR ITEMS AND STATISTICS FUNC ----//
    // This function is used to call APIs and get Organization list and statistics using filters
    const onSearch = (filtersFromUrl: StructureFilter) => {

        if (JSON.stringify(filtersFromUrl) !== JSON.stringify(DEFAULT_OPTIONS)) {
            updateFiltersInUrl(filtersFromUrl);
        }

        dispatch(organizationsOperations.searchCustomerOrganizations({ ...filtersFromUrl, parentId: orgId || id }));
    };

    //---- SEARCH FOR ITEMS AND STATISTICS HOOK ----//
    // This hook is used to call onSearch when filters or language are changed
    useEffect(() => {
        let searchFilters = getFiltersFromUrl();

        const strSearchFilters = JSON.stringify(searchFilters, Object.keys(searchFilters).sort());

        const strFilters = JSON.stringify(filters, Object.keys(filters).sort());

        if (strSearchFilters !== strFilters || lastUsedLang !== currentLang.label) {
            setFilters(searchFilters as StructureFilter);
            onSearch(searchFilters as StructureFilter);
            setLastUsedLang(currentLang.label);
        }

    }, [location, page, rowsPerPage, orderBy, order, currentLang]);

    //---- FILTERS SEARCH FUNC ----//
    // Used for search buttons in filters
    const handleSearchFilters = () => {
        fetchData(getValues());
        setPage(0);
    };

    //---- FILTERS RESET ALL FUNC ----//
    // Used for full clear buttons in filters
    const handleResetAllFilter = () => {
        setPage(0);
        fetchData(DEFAULT_STRUCTURE_FILTERS_VALUE);
        reset(DEFAULT_STRUCTURE_FILTERS_VALUE);
    };

    const getHeight = () => {
        return rowsPerPage === 5 ? 380 : 650;
    };

    const TABLE_HEAD: any[] = [
        {
            field: 'externalId',
            headerName: `${translate('commons.code')}`,
            headerAlign: 'left',
            flex: 0.5
        },
        {
            field: 'name',
            headerName: `${translate('commons.name')}`,
            headerAlign: 'left',
            flex: 1.75
        },
        {
            field: 'brands',
            headerName: `${translate('commons.brand')}/${translate('commons.category')}`,
            headerAlign: 'left',
            flex: 1,
            renderCell: (val: any) =>
                <Typography variant="body2">
                    {organization.brands && publicBrandList && publicBrandList.filter(brand => val.row.brands && val.row.brands.includes(brand.id)).map(brand => brand.name).join(', ')}
                </Typography>
        },
        {
            field: 'region',
            headerName: `${translate('organization.list.table.region')}`,
            headerAlign: 'left',
            flex: 1
        },
        {
            field: 'type',
            headerName: `${translate('organization.list.table.type')}`,
            headerAlign: 'left',
            flex: 0.75
        },
        {
            field: 'creditLimit',
            headerName: `${translate('organization.list.table.credit')}`,
            headerAlign: 'left',
            flex: 1,
            renderCell: (obj: any) => {
                return <Typography variant="body2">{obj.row.creditLimit ? Math.floor(convert(obj.row.creditLimit, CurrencyTypes.EUR, currentCurrency.label, rates)) + " " + currentCurrency.symbol : ""}</Typography>;
            }
        },
        {
            field: 'status',
            headerName: `${translate('commons.status')}`,
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            sortable: false,
            renderCell: (obj: any) => {
                return (
                    <Label
                        color={obj.row.status ? 'success' : 'error'}
                        sx={{ textTransform: 'uppercase' }}
                    >
                        {`${translate(`statuses.${obj.row.status ? "enabled" : "disabled"}`)}`}
                    </Label>
                );
            }
        },
        {
            field: 'options',
            headerName: ``,
            flex: 0.3,
            renderCell: () => <ArrowOutwardIcon />,
            sortable: false
        }
    ];

    const handleSort = (sortModel: GridSortModel) => {
        if (sortModel.length > 0) {
            setOrderBy(sortModel[0].field);
            setOrder(sortModel[0].sort!);
        } else {
            setOrderBy("");
            setOrder("asc");
        }
    };

    const handleCellClick = (params: GridCellParams<any>) => {
        navigate(backPath.detail(params.id.toString(), params.row.type));
    };

    return (<>
        <Card>
            <FormProvider methods={methods}>
                <OrganizationGeneralFilters
                    filters={filters}
                    onFilter={handleSearchFilters}
                    optionsFields={toolbarFiltersList}
                    resetAll={handleResetAllFilter}
                    showSummary={false}
                />
            </FormProvider>

            <DataGrid
                rows={organizationList}
                columns={TABLE_HEAD}
                pagination
                disableColumnResize
                paginationModel={{
                    page: page,
                    pageSize: rowsPerPage
                }}
                density={dense ? 'compact' : 'standard'}
                sortingMode={"server"}
                onSortModelChange={handleSort}
                loading={isSearchLoading || brandLoading}
                slots={{
                    noRowsOverlay: noData,
                    footer: () => (
                        <Box sx={{ position: 'relative' }}>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15, 30]}
                                component="div"
                                count={totalCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={onChangePage}
                                onRowsPerPageChange={onChangeRowsPerPage}
                                labelRowsPerPage={`${translate('commons.rowsPerPage')}`} />

                            <FormControlLabel
                                control={<Switch checked={dense} onChange={onChangeDense} />}
                                label={`${translate('commons.dense')}`}
                                sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }} />
                        </Box>)
                }}
                disableColumnMenu
                pageSizeOptions={[5, 10, 15, 30]}
                disableRowSelectionOnClick
                onCellClick={(param) => {
                    if (!window.getSelection()?.toString())
                        handleCellClick(param);
                }}
                sx={{
                    ...DataGridStyle,
                    cursor: 'pointer',
                    height: getHeight(),
                    maxHeight: 640
                }}
            />

        </Card>
    </>
    );
}
