import { Address } from "src/@types/organizations";

export const getAddress = (address: Address): string[] => {
    let addr = "";
    let city = "";
    let country = "";

    addr += address.address ? address.address + "" : "";
    addr += address.district ? ", " + address.district : "";
    city += address.zipCode ? ", " + address.zipCode : "";
    city += address.city ? ", " + address.city + " " : "";
    country += address.country ? address.country : "";

    return [addr, city, country];
};