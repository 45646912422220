import Page from "src/appComponents/Page";
import HeaderBreadcrumbs from "src/components/custom-breadcrumbs";
import { useLocales } from "src/locales";
import { PATH_DASHBOARD } from "src/routes/paths";
import ShopCartPopover from "../webshop/ShopCartPopover";

export default function WebshopPublicList() {

    const { translate } = useLocales();

    return (
        <Page title="public">
            <HeaderBreadcrumbs
                heading="Webshop"
                links={[
                    { name: translate('commons.home'), href: PATH_DASHBOARD.root },
                    { name: translate(`role.type.webShop`) }
                ]}
                action={<ShopCartPopover />}
            />
        </Page>
    );
}