import { Box } from "@mui/material";
import { UseFormReturn } from 'react-hook-form';
import { UserManager } from "src/@types/user";
import OrganizationAssignment from "../OrganizationAssignment";

type Props = {
    outerRef: any,
    formMethods: UseFormReturn<UserManager>
};

export default function UserStep2Organizations({
    outerRef,
    formMethods
}: Props) {

    const { getValues } = formMethods;

    const validateFields = async (yupSchema: any) => {
        const formValues = getValues();

        try {
            await yupSchema.validate(formValues, { abortEarly: false });
            //clearErrors();

            return true;
        } catch (err: any) {
            return false;
        }
    };

    if (!outerRef?.current) {
        outerRef.current = {
            validateFields,
            onSave: () => {
                const formValues = getValues();

                return { ...formValues };
            }
        };
    };

    return (
        <Box sx={{ p: 3, mb: 1 }}>
            <OrganizationAssignment />
        </Box>
    );
}
