import { createAsyncThunk } from "@reduxjs/toolkit";
import { organizationRoleSearchLogs, organizationRoleDetail, administrationRoleDetail, administrationRoleSearch, administrationRoleSearchLogs, organizationRoleSearch } from "src/services/roleServices";
import { startLoading, startLogsLoading } from "./roles-slices";

const getOrganizationRole = createAsyncThunk(
    'role/getOrganizationRole',
    async (id: string, { rejectWithValue, dispatch }) => {

        dispatch(startLoading());

        try {
            const { data } = await organizationRoleDetail(id);

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const getOrganizationRoleLogs = createAsyncThunk(
    'role/getOrganizationRoleLogs', async (options: { id: string, filters: any }, { rejectWithValue, dispatch }) => {

        dispatch(startLogsLoading());

        try {

            const { data } = await organizationRoleSearchLogs(options.id, options.filters);

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const getAdminRole = createAsyncThunk(
    'role/getAdminRole',
    async (id: string, { rejectWithValue, dispatch }) => {

        dispatch(startLoading());

        try {
            const { data } = await administrationRoleDetail(id);

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const getAdminRoleLogs = createAsyncThunk(
    'role/getAdminRoleLogs', async (options: { id: string, filters: any }, { rejectWithValue, dispatch }) => {

        dispatch(startLogsLoading());

        try {

            const { data } = await administrationRoleSearchLogs(options.id, options.filters);

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const searchAdminRoles = createAsyncThunk(
    'role/searchAdminRoles',
    async (options: { pageIndex: number, pageSize: number }, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await administrationRoleSearch(options);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const searchOrganizationRoles = createAsyncThunk(
    'role/searchOrganizationRoles',
    async (options: { pageIndex: number, pageSize: number }, { rejectWithValue, dispatch }) => {
        dispatch(startLoading());
        try {
            const { data } = await organizationRoleSearch(options);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const rolesOperations = {
    getOrganizationRole,
    getOrganizationRoleLogs,
    getAdminRoleLogs,
    getAdminRole,
    searchAdminRoles,
    searchOrganizationRoles
};

export default rolesOperations;