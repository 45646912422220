import { useFormContext } from 'react-hook-form';
import { Box, Stack, Badge, Button, Drawer, Divider, IconButton, Typography, TextField, ButtonGroup, Autocomplete } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import useLocales from 'src/appHooks/useLocales';
import { useCallback, useEffect, useMemo, useState, UIEvent } from 'react';
import { NAV } from 'src/config';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { DEFAULT_ORDERS_FILTERS, OrderFilters } from 'src/@types/orders';
import AutocompleteField from 'src/appComponents/AutocompleteField';
import { organizationsOperations } from 'src/redux/organizations';
import { RootState, dispatch, useSelector } from 'src/redux/store';
import { DEFAULT_PARENT, GenericOrganization, OrganizationSearchResult } from 'src/@types/organizations';
import CustomFieldSidebarRenderer from 'src/utils/CustomFieldSidebarRenderer';

type Props = {
    filters: OrderFilters,
    isDefault: (valuesToCheck: any, other?: any) => boolean,
    isOpen: boolean,
    onOpen: VoidFunction,
    onFilter: VoidFunction,
    onResetAll: VoidFunction,
    onClose: VoidFunction,
    reset: boolean,
    resetFormElement: string,
    setShowSummary: (value: boolean) => void,
    isVendor: boolean,
    setResetFormElement: (value: string) => void
};

export default function OrdersFilterSidebar({
    isDefault,
    isOpen,
    onResetAll,
    onFilter,
    onOpen,
    onClose,
    reset,
    resetFormElement,
    filters,
    setShowSummary,
    isVendor,
    setResetFormElement
}: Props) {

    const { translate } = useLocales();

    const { setValue, getValues } = useFormContext();

    const [values, setValues] = useState(filters);

    const [valuesAfterFilter, setValuesAfterFilter] = useState(filters);

    const [inEditTags, setInEditTags] = useState<boolean>(false);

    const [resetSearchCustomer, setResetSearchCustomer] = useState<boolean>(false);

    const showCustomer = isVendor;

    useEffect(() => {
        if (reset) {
            setValues(DEFAULT_ORDERS_FILTERS);
            setResetSearchCustomer(true);
        }
    }, [reset]);

    const handleChangeValues = useCallback((values: any, newVal: any, type: any) => {

        const valuesCopy = JSON.parse(JSON.stringify(values));

        if (type === "customer") valuesCopy.customerId = newVal.id;

        valuesCopy[type] = newVal;

        setValues(valuesCopy);
    }, []);

    const handleChangeTagsValues = (newVal: any, type: string) => {

        const valuesCopy = JSON.parse(JSON.stringify(values));

        valuesCopy[type] = newVal;

        setValues(valuesCopy);
    };

    useEffect(() => {
        if (resetFormElement) {
            handleChangeValues(values, DEFAULT_ORDERS_FILTERS[resetFormElement], resetFormElement);
            if (resetFormElement === "customerId") setResetSearchCustomer(true);
            setResetFormElement("");
        }
    }, [resetFormElement, setResetFormElement, handleChangeValues, values]);

    useEffect(() => {
        if (JSON.stringify(filters) !== JSON.stringify(values)) {
            setValues(filters);
            setValuesAfterFilter(filters);
        }
    }, [filters]);

    const handleCloseFilter = () => {
        if (reset) {
            setValues(DEFAULT_ORDERS_FILTERS);
        } else if (JSON.stringify(values) !== JSON.stringify(valuesAfterFilter)) {
            setValues(valuesAfterFilter);
        }
        onClose();
    };

    const handleOnResetAll = () => {
        setValues(DEFAULT_ORDERS_FILTERS);
        onResetAll();
    };

    const setAllValues = () => {
        for (const [key, value] of Object.entries(values)) {
            const formValue = getValues(`${key}`);

            if (JSON.stringify(value) !== JSON.stringify(formValue)) {
                setValue(`${key}`, value);
            }
        }
        setValuesAfterFilter(values);
    };

    const handleOnFilter = () => {
        setAllValues();
        setShowSummary(true);
        onFilter();
    };

    const dotController = useMemo(
        () => {
            if (valuesAfterFilter) {
                return ({ ...valuesAfterFilter });
            }

            return ({ ...DEFAULT_ORDERS_FILTERS });
        }, [valuesAfterFilter]);

    return (
        <>
            <Button variant="outlined" size='small' onClick={onOpen}>
                <FilterListIcon />
            </Button>
            <Drawer
                anchor="right"
                open={isOpen}
                onClose={handleCloseFilter}
                PaperProps={{
                    sx: { width: NAV.W_DASHBOARD },
                    style: { boxShadow: 'none' }
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ px: 1, py: 2 }}
                >
                    <Typography variant="subtitle1" sx={{ ml: 1 }}>
                        {`${translate("commons.filters")}`}
                    </Typography>

                    <IconButton onClick={handleCloseFilter}>
                        <Iconify icon={'eva:close-fill'} width={20} height={20} />
                    </IconButton>
                </Stack>

                <Divider />

                <Scrollbar>
                    <Stack spacing={3} sx={{ p: 2.5 }}>

                        <Stack spacing={1}>
                            <TextField
                                name='prettyName'
                                label={`${translate(`orders.filter.prettyName`)}`}
                                variant="outlined"
                                value={values.prettyName}
                                onChange={(e) => {
                                    handleChangeValues(values, e.target.value, "prettyName");
                                }}
                            />
                        </Stack>

                        <Stack spacing={1}>
                            <TextField
                                name='documentExternalId'
                                label={`${translate(`orders.tableHeaders.document`)}`}
                                variant="outlined"
                                value={values.documentExternalId}
                                onChange={(e) => {
                                    handleChangeValues(values, e.target.value, "documentExternalId");
                                }}
                            />
                        </Stack>

                        {showCustomer &&
                            <>
                                <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                                    {`${translate('commons.customer')}`}
                                </Typography>
                                <Stack spacing={1}>
                                    <CustomerAutocomplete
                                        onChange={handleChangeTagsValues}
                                        setResetSearchCustomer={setResetSearchCustomer}
                                        resetSearchCustomer={resetSearchCustomer}
                                        defaultCustomer={values.customer}
                                    />
                                </Stack>
                            </>
                        }

                        <Typography variant="subtitle1"> {`${translate('orders.filter.item')}`} </Typography>

                        <Stack spacing={1}>
                            <TextField
                                name='itemName'
                                label={`${translate(`commons.name`)}`}
                                variant="outlined"
                                value={values.itemName}
                                onChange={(e) => {
                                    handleChangeValues(values, e.target.value, "itemName");
                                }}
                            />
                        </Stack>

                        <Stack spacing={1}>
                            <TextField
                                name='itemExternalId'
                                label={`${translate(`commons.externalId`)}`}
                                variant="outlined"
                                value={values.itemExternalId}
                                onChange={(e) => {
                                    handleChangeValues(values, e.target.value, "itemExternalId");
                                }}
                            />
                        </Stack>

                        <Typography variant="subtitle1">{`${translate("commons.tags")}`}</Typography>

                        <Stack spacing={1}>
                            <AutocompleteField
                                name={'tags'}
                                context={'ordersFilterTag'}
                                label={`${translate("commons.tags")}`}
                                freeSolo
                                multiple
                                tagsFromForm={values.tags!.length ? values.tags : getValues("tags")}
                                handleChangeValues={handleChangeTagsValues}
                                inEditTags={inEditTags}
                                setInEditTags={setInEditTags}
                            />
                        </Stack>

                        <CustomFieldSidebarRenderer
                            context={["Order"]}
                            filterValues={values}
                            setFilterValues={setValues}
                        />

                    </Stack>

                </Scrollbar>

                <Box sx={{ p: 1, pb: 2, position: 'relative' }}>

                    <Badge
                        color="error"
                        variant="dot"
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        invisible={isDefault(values, dotController)}
                        sx={{ width: 1, right: 35, top: 20, position: 'absolute' }}
                    />

                    <ButtonGroup variant="outlined" aria-label="outlined button group" fullWidth>

                        <Button
                            fullWidth
                            size="large"
                            type="submit"
                            color="inherit"
                            variant="outlined"
                            onClick={handleOnResetAll}
                            startIcon={<Iconify icon="ic:round-clear-all" />}
                            sx={{ px: 1 }}
                        >
                            {`${translate("commons.clear")}`}
                        </Button>
                        <Button
                            fullWidth
                            size="large"
                            type="submit"
                            color="inherit"
                            variant="outlined"
                            onClick={handleOnFilter}
                            disabled={inEditTags}
                            startIcon={<Iconify icon="eva:search-fill" />}
                            sx={{ px: 1 }}
                        >
                            {`${translate("commons.apply")}`}
                        </Button>

                    </ButtonGroup>
                </Box>

            </Drawer >
        </>
    );
}

interface CustomerAutocompleteProps {
    onChange: (newVal: any, type: any) => void,
    setResetSearchCustomer: (newVal: boolean) => void,
    resetSearchCustomer: boolean,
    defaultCustomer?: OrganizationSearchResult | GenericOrganization
}

export function CustomerAutocomplete({ onChange, setResetSearchCustomer, resetSearchCustomer, defaultCustomer }: CustomerAutocompleteProps) {

    const { translate } = useLocales();

    const { isSearchLoading } = useSelector((state: RootState) => state.organizations);

    const [infPage, setInfPage] = useState(0);

    const [hasNextPage, setHasNextPage] = useState(false);

    const [organizationList, setOrganizationList] = useState<OrganizationSearchResult[]>([]);

    const [searchQuery, setSearchQuery] = useState("");

    const handleSearch = async (val: string) => {
        const search = await dispatch(
            organizationsOperations.searchCustomerOrganizations({
                pageIndex: 0,
                pageSize: 10,
                all: val,
                status: 'Enabled'
            })
        ).unwrap();

        setInfPage(search.pageIndex);

        setOrganizationList(search.items);

        setHasNextPage(!!search.hasNextPage);

        setSearchQuery(val);
    };

    const getPage = (e: UIEvent<HTMLUListElement>) => {

        const { scrollTop, scrollHeight, offsetHeight } = e.currentTarget;

        //check if scrollbar reached bottom and if there are other elements

        if (Math.abs(scrollHeight - (scrollTop + offsetHeight)) < 1 && hasNextPage && !isSearchLoading) {

            dispatch(organizationsOperations.searchCustomerOrganizations({ pageIndex: infPage + 1, pageSize: 10, all: searchQuery }))
                .unwrap()
                .then((v) => {

                    setOrganizationList(prev => prev.concat(v.items));

                    setInfPage(v.pageIndex);

                    setHasNextPage(!!v.hasNextPage);
                });
        }
    };

    useEffect(() => {
        if (resetSearchCustomer) {
            handleSearch("");
            setResetSearchCustomer(false);
        }
    }, [resetSearchCustomer, setResetSearchCustomer]);

    const defaultCust = useMemo(() => defaultCustomer, [defaultCustomer]);

    return (
        <Autocomplete
            id="customer"
            freeSolo
            options={organizationList}
            loading={isSearchLoading}

            defaultValue={defaultCust}

            onChange={(_, v) =>
                onChange(v ? (v as OrganizationSearchResult) : DEFAULT_PARENT, 'customer')
            }

            onInputChange={(_, v) => handleSearch(v)}

            getOptionLabel={(option) =>
                typeof option !== 'string' ? option.name : ''
            }

            filterOptions={(options) => options}
            ListboxProps={{
                onScroll: (e) => {
                    getPage(e);
                }
            }}
            renderOption={(props, option) => {
                return (
                    <li
                        {...props}
                        key={option.id}
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'initial' }}
                    >
                        {option.name}
                    </li>
                );
            }}
            renderInput={(params) => (
                <TextField {...params} value={defaultCust} label={`${translate('orders.filter.customerId')}`} />
            )}
        />
    );
}