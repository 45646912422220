import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Order } from "src/@types/orders";
import { GenericOrganization } from "src/@types/organizations";
import { RequestNew } from "src/@types/request";
import { GridLabeledText } from "src/appComponents/GridLabeledText";
import { useAuth } from "src/appHooks/useAuth";
import MoreDetails from "src/components/request-utils/MoreDetails";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";
import { organizationsOperations } from "src/redux/organizations";
import { dispatch } from "src/redux/store";
import { getAddress } from "src/utils/addressFormatter";

interface CompleteWarrantyProps {
    order: Order,
    request: RequestNew,
    customerId: string,
    vendorId: string
}

export default function CompleteWrong({ order, request, customerId, vendorId }: CompleteWarrantyProps) {

    const { translate } = useLocales();

    const isDesktop = useResponsive("up", "md");

    const { user } = useAuth();

    const [customer, setCustomer] = useState<GenericOrganization>();

    const [vendor, setVendor] = useState<GenericOrganization>();

    useEffect(() => {
        if (request.requestReason === "SurplusDelivered") {
            dispatch(organizationsOperations.getGeneric(customerId)).unwrap().then(v => setCustomer(v));
            dispatch(organizationsOperations.getGeneric(vendorId)).unwrap().then(v => setVendor(v));
        }
    }, []);

    return (
        <Box>
            {request.requestReason !== "SurplusDelivered" && order && <MoreDetails order={order} />}
            {
                request.requestReason === "SurplusDelivered" &&
                <Box >
                    <Typography sx={{ p: 3, pb: 4 }} variant="subtitle1">{`${translate('commons.moreDetail')}`}</Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: isDesktop ? "row" : "column",
                            columnGap: 3, rowGap: 4, px: 3
                        }}
                    >
                        <GridLabeledText
                            align={"left"}
                            label={`${translate('request.customerCode')}`}
                            value={customer?.externalId || '—'}
                            left={4.5}
                            right={7.5}
                            variant="body2"
                        />
                        <GridLabeledText
                            align={"left"}
                            label={`${translate("commons.company")}`}
                            value={customer?.name || '—'}
                            left={4.5}
                            right={7.5}
                            variant="body2"
                        />
                    </Box>
                    <Box sx={{ px: 3, py: 4 }}>
                        <GridLabeledText
                            align={"left"}
                            label={`${translate("request.anomalies.detail.customerAddress")}`}
                            value={(customer && customer.address) ? getAddress(customer.address).join() : '—'}
                            left={2.2}
                            right={9.8}
                            variant="body2"
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: isDesktop ? "row" : "column",
                            columnGap: 3, rowGap: 4, px: 3, pb: 3
                        }}
                    >
                        <GridLabeledText
                            align={"left"}
                            label={`${translate("request.anomalies.detail.requestCreator")}`}
                            value={user.displayName}
                            left={4.5}
                            right={7.5}
                            variant="body2"
                        />
                        <GridLabeledText
                            align={"left"}
                            label={`${translate("commons.warehouse")}`}
                            value={vendor?.name || '—'}
                            left={4.5}
                            right={7.5}
                            variant="body2"
                        />
                    </Box>
                </Box>
            }
        </Box>
    );
}