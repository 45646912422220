import { OrderType } from "./orders";
import { RequestType } from "./request";

export const ContainerStatusesArr = ["", "Open", "Closed", "Shipped"] as const;

export type ContainerStatusType = typeof ContainerStatusesArr[number];

export const DateFiltersArr = ["Opened", "Closed", "Shipped"] as const;

export type DateFiltersType = typeof DateFiltersArr[number];

export const TemplateStatusesArr = ["", "Enabled", "Disabled"] as const;

export type TemplateStatusType = typeof TemplateStatusesArr[number];

export type ContainerFilters = {
    [key: string]: any
    //---COMMONS
    TemplateId?: string,
    pageIndex: number,
    pageSize: number,
    sortField?: string,
    sortAscending?: boolean,
    name?: string,
    contentTypes?: (RequestType | "All")[],
    tags?: string[],
    customFields?: Record<string, string>,
    //---TEMPLATE
    onlyEnabled?: boolean,
    from?: string,
    to?: string,
    //---CONTAINER
    dateField?: DateFiltersType,
    status?: ContainerStatusType,
    fromCreatedOn?: string,
    toCreatedOn?: string,
    fromClosedOn?: string,
    toClosedOn?: string,
    fromShippedOn?: string,
    toShippedOn?: string,
    templateId?: string
};

export type ContainerTemplateSearchResult = {
    id: string,
    createdOn: Date,
    name: string,
    contentTypes: (RequestType | "All")[],
    customFields: Record<string, string>,
    tags: string[],
    //---TEMPLATE
    enabled?: boolean,
    active?: boolean,
    createdBy?: {
        id: string,
        firstName: string,
        lastName: string
    },
    //---CONTAINER
    status?: string,
    closedOn?: string,
    shippedOn?: string,
    numberOfItems?: number,
    numberOfExtraItems?: number
};

export type ContainerStatistics = {
    [key: string]: number
    all: number,
    open: number,
    closed: number,
    shipped: number,
    enabled: number,
    disabled: number
}

export type ContainerTemplate = {
    // [key: string]: any
    id: string,
    createdOn: Date,
    name: string,
    contentTypes: (RequestType | "All")[],
    createdBy: {
        id: string,
        firstName: string,
        lastName: string
    },
    enabled: boolean,
    active: boolean
    customFields: Record<string, string>,
    tags: string[]
};

export type Container = {
    id: string,
    name: string,
    statusTypes: ContainerStatusType,
    createdOn: string,
    createdBy: {
        id: string,
        firstName: string,
        lastName: string
    },
    closedOn: string,
    closedBy: {
        id: string,
        firstName: string,
        lastName: string
    },
    shippedOn: string,
    shippedBy: {
        id: string,
        firstName: string,
        lastName: string
    },
    items: ItemContainer[],
    extraItems: (NewExtraItem & { createdOn: string })[]
    contentTypes: (RequestType | "All")[],
    customFields: Record<string, string>,
    tags: string[]
};

export type NewContainerTemplate = {
    name: string,
    contentTypes: (RequestType | "All")[],
    enabled: boolean,
    customFields: Record<string, string>,
    tags: string[]
}

export type ItemsInContainerSearchResult = {
    id: string,
    externalId: string,
    itemDescription: string,
    quantity: number,
    requestExternalId: string,
    requestBarcode: string,
    requestType: RequestType,
    requestSapCode: string,
    customFields: Record<string, string>,
    tags: string[]
};

export type ItemContainer = {
    id: string,
    createdOn: string,
    quantity: number,
    itemType: string,
    productExternalId: string,
    productName: string,
    productType: OrderType
}

export type EnableDisableSliceProps = {
    id: string,
    tab: TemplateStatusType,
    action: "Enable" | "Disable",
    totalCount: number,
    listLen: number,
    filters: ContainerFilters,
    pageSize: number,
    newItem?: ContainerTemplateSearchResult
}

export type CloseShipSliceProps = {
    id: string,
    tab: ContainerStatusType,
    action: "Close" | "Ship",
    totalCount: number,
    listLen: number,
    filters: ContainerFilters,
    pageSize: number,
    now: string,
    newItem?: ContainerTemplateSearchResult,
    shippedOn?: string
}

export type EditSliceProps = {
    item?: ContainerTemplate,
    tab: TemplateStatusType,
    totalCount: number,
    listLen: number,
    filters: ContainerFilters,
    pageSize: number,
    newItem?: ContainerTemplateSearchResult
}

export type NewSliceProps = {
    item: ContainerTemplate,
    tab: TemplateStatusType
}

export type NewExtraItem = {
    itemId: string,
    itemDescription: string,
    quantity: number,
    reason: string,
    extraFields: Record<string, string>
}

//-------------------------------------------------------------------------

export const DEFAULT_CONTAINER_FILTERS: ContainerFilters = {
    //---COMMONS
    pageIndex: 0,
    pageSize: 10,
    from: "",
    to: "",
    name: "",
    contentTypes: ["All"],
    onlyEnabled: true,
    tags: [],
    sortField: "",
    sortAscending: false,
    customFields: {},
    //---TEMPLATE
    //---CONTAINER
    dateField: "Opened"
};

export const DEFAULT_STATISTIC: ContainerStatistics = {
    all: 0,
    open: 0,
    closed: 0,
    deleted: 0,
    shipped: 0,
    enabled: 0,
    disabled: 0
};

export const DEFAULT_NEW_CONTAINER: NewContainerTemplate = {
    contentTypes: [],
    customFields: {},
    enabled: true,
    name: "",
    tags: []
};

export const DEFAULT_NEW_EXTRAITEM: NewExtraItem = {
    extraFields: {},
    itemDescription: "",
    itemId: "",
    quantity: 0,
    reason: ""
};