import axios, { AxiosResponse } from 'axios';
import { Core } from './paths';

const searchEmails = (options: {
    pageIndex: number,
    pageSize: number,
    recipient?: string | null,
})
    : Promise<AxiosResponse> => {
    const url = Core.admin.email.list();

    return axios.get<any>(url, {
        params: {
            pageIndex: options.pageIndex.toString(),
            pageSize: options.pageSize.toString(),
            recipient: options.recipient
        }
    });
};

const emailDetails = (id: string): Promise<AxiosResponse> => {
    const url = Core.admin.email.details(id);

    return axios.get<any>(url, {});
};

export {
    searchEmails,
    emailDetails
};
