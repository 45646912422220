import { Box, BoxProps } from "@mui/material";
import BoxSwitch from "./BoxSwitch";
import { useState } from "react";

interface BoxSwitchWithChildProps extends BoxProps {
    label: string,
    subtitle?: string,
    alwaysOpen?: boolean,
    checkOpen?: boolean,
    required?: boolean,
    callBack?: VoidFunction
}

export default function BoxSwitchWithChild({ label, subtitle, alwaysOpen, checkOpen, required, callBack, sx, children, ...BoxProps }: BoxSwitchWithChildProps) {

    const [showChild, setShowChild] = useState(alwaysOpen ?? checkOpen ?? false);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...sx }} {...BoxProps}>

            <Box
                sx={{
                    p: 3, width: '100%',
                    border: "1px solid #E2E2E2",
                    borderRadius: '8px',
                    ":focus-within": { borderColor: (theme) => theme.palette.primary.main }
                }}
            >
                <BoxSwitch
                    label={label}
                    subtitle={subtitle}
                    value={alwaysOpen ? true : showChild}
                    onChange={(v) => {
                        setShowChild(v);
                        if (callBack) callBack();
                    }}
                    hideSwitch={alwaysOpen}
                    required={required}
                />

                {showChild && children}

            </Box>
        </Box>
    );
}