import { Box, Button, Card, Modal, Typography } from "@mui/material";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";

interface ModalType {
    isOpen: boolean,
    toggle: () => void,
    handleSave: () => Promise<void>,
    saveText: string
}

export default function SaveModal({ isOpen, toggle, handleSave, saveText }: ModalType) {

    const { translate } = useLocales();

    const isDesktop = useResponsive('up', 'lg');

    return (
        <Modal
            open={isOpen}
            onClose={toggle}
        >
            <Card
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: isDesktop ? '25vw' : '70vw',
                    p: isDesktop ? 4 : 2
                }}
            >
                <Box sx={{ textAlign: "center", mb: 1 }}>

                    <Typography variant="h3">{`${translate('commons.save')}`}</Typography>

                    <Typography variant="body1" sx={{ my: 3 }}>
                        {saveText}
                    </Typography>

                    <Button
                        variant="soft"
                        size={isDesktop ? "small" : "medium"}
                        onClick={toggle}
                        sx={{ mt: 3, mr: { xs: 0, sm: 2 }, borderRadius: "100px" }}
                    >
                        {`${translate("commons.cancel")}`}
                    </Button>
                    <Button
                        variant="contained"
                        size={isDesktop ? "small" : "medium"}
                        onClick={handleSave}
                        sx={{ mt: 3, ml: 2, borderRadius: "100px" }}
                    >
                        {`${translate("commons.save")}`}
                    </Button>

                </Box>
            </Card>
        </Modal>
    );
}
