import { Media } from "src/@types/request";

export const getMediaName = (url: string) => {
    return url ? (url?.split(`/image/`)[1]?.split("?")[0] || "") : "";
};

export const getMediaUrl = (image: Media, widthToCheck: number, heightToCheck: number) => {

    if (image && image.fileInfo) {
        const imageFound = image.fileInfo.find((info) => info.height === heightToCheck && info.width === widthToCheck && !info.watermark);

        return imageFound ? imageFound.url : "";
    }

    return "";
};