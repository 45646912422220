import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, IconButton, Divider, Checkbox } from '@mui/material';
import Iconify from 'src/components/iconify';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import DateZone from 'src/appComponents/DateZone';
import { useLocales } from 'src/locales';

type InboxDetailsToolbarProps = {
  handleChangeSelect: (messageId: string) => void,
  handleChangeStarred: (checked: boolean, messageId: string) => void,
  handleChangeImportant: (checked: boolean, messageId: string) => void,
  handleMarkRead: (messageId?: string) => void,
  handleMarkUnRead: (messageId?: string) => void,
  handleDelete: (messageId?: string) => void
};

export default function InboxDetailsToolbar({ handleChangeSelect, handleChangeStarred, handleChangeImportant, handleMarkRead, handleMarkUnRead, handleDelete }: InboxDetailsToolbarProps) {

  const navigate = useNavigate();

  const { section } = useParams();

  const { translate } = useLocales();

  const { message } = useSelector((state: RootState) => state.inbox);

  const handleBack = () => {
    return navigate(PATH_DASHBOARD.inbox.section(section!));
  };

  return (
    message &&
    <Box>
      <Box sx={{
        m: 1,
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>

        <IconButton onClick={handleBack}>
          <Iconify icon={'eva:arrow-ios-back-fill'} width={20} height={20} />
        </IconButton>

        <Box sx={{ display: 'flex' }}>
          <Checkbox
            color="warning"
            checked={message.starred}
            icon={<Iconify icon={'eva:star-outline'} />}
            checkedIcon={<Iconify icon={'eva:star-fill'} />}
            onClick={() => handleChangeStarred(message.starred, message.id)}
          />

          <Checkbox
            color="warning"
            checked={message.important}
            icon={<Iconify icon={'ic:round-label-important'} />}
            checkedIcon={<Iconify icon={'ic:round-label-important'} />}
            onClick={() => handleChangeImportant(message.important, message.id)}
          />

          <IconButton
            size={"small"}
            key={`${translate("inbox.delete")}`}
            onClick={() => handleDelete(message.id)}
            sx={{ mx: 0.5, '&:hover': { color: 'text.primary' } }}
          >
            <Iconify icon={'eva:trash-2-outline'} width={24} height={24} />
          </IconButton>

          <IconButton
            size={"small"}
            key={message.read ? `${translate("inbox.markAsUnread")}` : `${translate("inbox.markAsRead")}`}
            onClick={() => message.read ? handleMarkUnRead(message.id) : handleMarkRead(message.id)}
            sx={{ mx: 0.5, '&:hover': { color: 'text.primary' } }}
          >
            <Iconify icon={message.read ? 'ic:round-mark-email-unread' : 'ic:round-mark-email-read'} width={24} height={24} />
          </IconButton>
        </Box>

      </Box>

      <Divider />

      <Box sx={{
        my: 1, mx: 2,
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <Typography variant="h6">
          {message.title}
        </Typography>

        <DateZone
          date={message.createdOn}
          shortMonth
          noSeconds
          variant={"caption"}
          noWrap
        />
      </Box>
    </Box>
  );
}

/*
        <CustomAvatar
          alt={mail.from.name}
          src={mail.from.avatar || ''}
          color={createAvatar(mail.from.name).color}
        >
          {createAvatar(mail.from.name).name}
        </CustomAvatar> 
*/