import Container from '@mui/material/Container';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import Page from 'src/appComponents/Page';
import useLocales from 'src/appHooks/useLocales';
import { useSettingsContext } from 'src/components/settings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { dispatch, RootState } from 'src/redux/store';
import { vehicleOperations } from 'src/redux/vehicle';
import { resetVehicle } from 'src/redux/vehicle/vehicle-slices';
import LoadingScreen from 'src/appComponents/loading-screen';
import { Box, Button, Card, Divider, Typography } from '@mui/material';
import VehicleLogisticsInfo from '../details/VehicleLogisticsInfo';

export default function LogisticsView() {
    const { themeStretch } = useSettingsContext();

    const isDesktop = useResponsive('up', 'sm');

    const { translate, currentLang } = useLocales();

    const { vehicle, isLoading } = useSelector((state: RootState) => state.vehicle);

    const navigate = useNavigate();

    const params = useParams();

    useEffect(() => {
        dispatch(vehicleOperations.getVehicleDisplayById(params?.vehicleId || ""));

        return () => {
            dispatch(resetVehicle());
        };
    }, [params?.vehicleId, currentLang]);

    const onQuit = () => {
        navigate(PATH_DASHBOARD.vehicle.list);
    };

    const onEdit = () => {
        navigate(PATH_DASHBOARD.vehicle.logisticEdit(vehicle.id));
    };

    const openLogs = () => {
        navigate(PATH_DASHBOARD.vehicle.logisticLogs(vehicle.id));
    };

    return (
        <Page title="Vehicle: View Logistic">
            <Container maxWidth={themeStretch ? false : 'lg'} sx={{ p: 0 }}>
                {isLoading || !vehicle ? (
                    <LoadingScreen />
                ) : (
                    <>
                        <HeaderBreadcrumbs
                            heading={`${vehicle?.brand} ${vehicle?.model} : ${translate('vehicle.logistic.title')}`}
                            links={[
                                { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                                { name: `${translate('vehicle.title_plural')}`, href: PATH_DASHBOARD.vehicle.list },
                                { name: `${vehicle?.brand} ${vehicle?.model}`, href: PATH_DASHBOARD.vehicle.detail(vehicle?.id) },
                                { name: `${translate('vehicle.logistic.title')}` }
                            ]}
                            sx={{ px: isDesktop ? 5 : 2, mb: isDesktop ? 5 : 2 }}
                        />
                        <Card>

                            <Box
                                sx={{
                                    px: isDesktop ? 5 : 2,
                                    py: isDesktop ? 3 : 1,
                                    gap: 1
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        mb: 2,
                                    }}
                                >
                                    <Box>
                                        <Typography variant="h6" sx={{ pb: 1 }}>
                                            {`${vehicle.brand} ${vehicle.model} ${vehicle.modelCode} `}
                                        </Typography>
                                        <Typography variant="body2">{`${translate('vehicle.logistic.view')}`}</Typography>
                                    </Box>

                                </Box>
                                <VehicleLogisticsInfo alignText={"left"} />
                            </Box>

                            <Divider />
                            <Box
                                sx={{
                                    display: 'flex',
                                    px: isDesktop ? 5 : 2,
                                    py: isDesktop ? 3 : 1,
                                    gap: 1,
                                    flexDirection: isDesktop ? 'row' : 'column-reverse',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Button variant="soft" color="inherit" size={isDesktop ? 'small' : 'large'} onClick={() => { onQuit(); }}>
                                    {`${translate('vehicle.form.backToVehicleList')}`}
                                </Button>
                                <Button variant="soft" size="small" onClick={() => { openLogs(); }}>
                                    {`${translate('vehicle.logistic.logs.open')}`}
                                </Button>
                                <Button variant="soft" size="small" onClick={() => { onEdit(); }}>
                                    {`${translate('vehicle.logistic.add')}`}
                                </Button>
                            </Box>
                        </Card>
                    </>
                )}
            </Container>
        </Page>
    );
}
