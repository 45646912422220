import { createAsyncThunk } from "@reduxjs/toolkit";
import { startDocLogsLoading, startDocumentLoading, startOrderLoading, startOrderLogsLoading } from "./orders-slices";
import { detail, detailInvoice, detailDeliveryNote, searchDocumentsLogs, searchOrdersLogs, searchVendor, searchCustomer, searchVendorDocuments, searchCustomerDocuments, detailCreditNote } from "src/services/ordersServices";
import { DocType, DocumentFilters, OrderFilters } from "src/@types/orders";
import removeEmptyKeys from "src/utils/removeEmptyKeys";

const searchVendorOrders = createAsyncThunk(
    'orders/searchVendorOrders',
    async (options: OrderFilters, { rejectWithValue, dispatch }) => {
        dispatch(startOrderLoading());
        try {
            const { data } = await searchVendor(options);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const searchCustomerOrders = createAsyncThunk(
    'orders/searchCustomerOrders',
    async (options: OrderFilters, { rejectWithValue, dispatch }) => {
        dispatch(startOrderLoading());
        try {
            const { data } = await searchCustomer(options);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const getOrder = createAsyncThunk(
    'orders/getOrder',
    async (id: string, { rejectWithValue, dispatch }) => {
        dispatch(startOrderLoading());
        try {
            const { data } = await detail(id);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const searchVendorDocument = createAsyncThunk(
    'orders/searchVendorDocument',
    async (options: DocumentFilters, { rejectWithValue, dispatch }) => {
        dispatch(startDocumentLoading());
        try {
            const { data } = await searchVendorDocuments(options);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const searchCustomerDocument = createAsyncThunk(
    'orders/searchCustomerDocument',
    async (options: DocumentFilters, { rejectWithValue, dispatch }) => {
        dispatch(startDocumentLoading());
        try {
            const { data } = await searchCustomerDocuments(options);

            if (!data) {
                throw new Error('Something went wrong');
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const searchOrdersPowerSearch = createAsyncThunk(
    'orders/searchOrdersPowerSearch',
    async (options: { pageIndex: number, pageSize: number, prettyName: string }, { rejectWithValue }) => {
        try {
            const { data } = await searchVendor(options);

            if (!data)
                throw new Error('Something went wrong');

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const getInvoice = createAsyncThunk(
    'orders/getInvoice',
    async (id: string, { dispatch, rejectWithValue }) => {
        dispatch(startDocumentLoading());
        try {
            const { data } = await detailInvoice(id);

            if (!data)
                throw new Error('Something went wrong');

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const getDeliveryNote = createAsyncThunk(
    'orders/getDeliveryNote',
    async (id: string, { dispatch, rejectWithValue }) => {
        dispatch(startDocumentLoading());
        try {
            const { data } = await detailDeliveryNote(id);

            if (!data)
                throw new Error('Something went wrong');

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const getCreditNote = createAsyncThunk(
    'orders/getCreditNote',
    async (id: string, { dispatch, rejectWithValue }) => {
        dispatch(startDocumentLoading());
        try {
            const { data } = await detailCreditNote(id);

            if (!data)
                throw new Error('Something went wrong');

            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const getOrdersLogs = createAsyncThunk(
    'orders/getOrdersLogs',
    async (options: { id: string, filters: any, isMine: boolean }, { rejectWithValue, dispatch }) => {
        dispatch(startOrderLogsLoading());
        try {

            const { data } = await searchOrdersLogs(options.id, removeEmptyKeys(options.filters), options.isMine);

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const getDocsLogs = createAsyncThunk(
    'orders/getDocsLogs',
    async (options: { id: string, type: DocType, filters: any, isMine: boolean }, { rejectWithValue, dispatch }) => {
        dispatch(startDocLogsLoading());
        try {

            const { data } = await searchDocumentsLogs(options.id, options.type, removeEmptyKeys(options.filters), options.isMine);

            return data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

const ordersOperations = {
    searchVendorOrders,
    searchCustomerOrders,
    getOrder,
    searchVendorDocument,
    searchCustomerDocument,
    searchOrdersPowerSearch,
    getDeliveryNote,
    getInvoice,
    getOrdersLogs,
    getDocsLogs,
    getCreditNote
};

export default ordersOperations;