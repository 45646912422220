import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import settingsReducer from './slices/settings';
import { vehicleReducer } from './vehicle';
import { inboxReducer } from './inbox';
import { usersReducer } from './users';
import { matrixReducer } from './matrix';
import { organizationsReducer } from './organizations';
import { emailReducer } from './email';
import { brandReducer } from './brand';
import { sparePartsReducer } from './spareParts';
import { requestReducer } from './request';
import { ordersReducer } from './orders';
import { rolesReducer } from './roles';
import { rulesReducer } from './rules';
import { containerReducer } from './container';
import { reportsReducer } from './reports';
import { groupReducer } from './group';
import { termConditionReducer } from './termCondition';
import { modalReducer } from './modal';
import { webshopReducer } from './webshop';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  inbox: inboxReducer,
  email: emailReducer,
  user: usersReducer,
  settings: settingsReducer,
  vehicle: vehicleReducer,
  matrix: matrixReducer,
  organizations: organizationsReducer,
  brand: brandReducer,
  request: requestReducer,
  spareParts: sparePartsReducer,
  orders: ordersReducer,
  roles: rolesReducer,
  rules: rulesReducer,
  container: containerReducer,
  reports: reportsReducer,
  group: groupReducer,
  terms: termConditionReducer,
  modal: modalReducer,
  webshop: webshopReducer
});

export { rootPersistConfig, rootReducer };
