// components
//
import { useContext } from 'react';
import { CurrencyRatesContext } from 'src/contexts/CurrencyRatesContext';

// ----------------------------------------------------------------------

export default function useCurrency() {
    const context = useContext(CurrencyRatesContext);

    if (!context)
        throw new Error('Currency context must be use inside CurrencyRatesProvider');

    return context;
}
