import { Box, Card, Divider, Drawer, IconButton, Stack, Typography, alpha, styled } from "@mui/material";
import Iconify from "src/components/iconify";
import { NAV } from "src/config";
import { useLocales } from "src/locales";
import KeyboardReturnRoundedIcon from '@mui/icons-material/KeyboardReturnRounded';

interface SurveillanceDrawerProps {
    isOpen: boolean,
    onClose: VoidFunction
}

export default function SurveillanceDrawer({ isOpen, onClose }: SurveillanceDrawerProps) {

    const { translate } = useLocales();

    const IconWrapperStyle = styled('div')(({ theme }) => ({
        minWidth: 100,
        minHeight: 100,
        margin: 'auto',
        display: 'flex',
        borderRadius: '50%',
        alignItems: 'center',
        width: theme.spacing(8),
        height: theme.spacing(8),
        justifyContent: 'center',
        marginBottom: theme.spacing(3),
    }));

    return (
        <Drawer
            open={isOpen}
            onClose={onClose}
            anchor="right"
            PaperProps={{
                sx: { width: NAV.W_DASHBOARD },
                style: { boxShadow: 'none' }
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ px: 1, py: 2 }}
            >
                <Typography variant="subtitle1" sx={{ ml: 1 }}>
                    {`${translate('commons.filters')}`}
                </Typography>

                <IconButton onClick={onClose}>
                    <Iconify icon={'eva:close-fill'} width={20} height={20} />
                </IconButton>
            </Stack>

            <Divider />
            <Box
                sx={{
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center'
                }}
            >
                <Card
                    sx={{
                        my: 2,
                        width: '87%',
                        // maxHeight: '28ch',
                        pt: 5,
                        boxShadow: 0,
                        textAlign: 'center',
                        backgroundColor: 'error.lighter',
                    }}
                >
                    <IconWrapperStyle
                        sx={{
                            width: '32%',
                            height: '32%',
                            background: (theme) =>
                                `linear-gradient(135deg, ${alpha(theme.palette.error.light, 0)
                                } 0%, ${alpha(theme.palette.error.dark, 0.24)
                                } 100%)`,
                        }}
                    >
                        <KeyboardReturnRoundedIcon />
                    </IconWrapperStyle>
                    <Stack sx={{ mb: '20px' }}>
                        <Typography
                            variant="h3"
                            sx={
                                { color: 'error.darker' }
                            }
                        >
                            5.6%
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            sx={{
                                color: 'error.darker',
                                opacity: 0.72,
                            }}
                        >
                            Total return rate
                        </Typography>
                    </Stack>
                </Card>
            </Box>
        </Drawer>
    );
}