// @mui
import { enUS, itIT, deDE, frFR, ptPT, esES, nlNL, Localization } from '@mui/material/locale';
// components
import { SettingsValueProps } from './components/settings';
// routes
import { PATH_DASHBOARD } from './routes/paths';

import {
  enGB as gbLocale,
  enUS as usLocale,
  it as itLocale,
  de as deLocale,
  fr as frLocale,
  pt as ptLocale,
  es as esLocale,
  nl as nlLocale,
  Locale
}
  from "date-fns/locale";

import { CurrencyTypes } from './@types/vehicle';
import { toMoneySymbol } from './utils/currency';

// PATHS
export const PUBLIC_URL = '/public';

// API
// ----------------------------------------------------------------------

export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || '';

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.root; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 270,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'orange',
  themeStretch: false,
  currency: { label: CurrencyTypes.EUR, symbol: '€' },
  metricSystem: false
};

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export type Lang = {
  label: string,
  value: string,
  systemValue: Localization,
  icon: string,
  locale: Locale
}

export const allLangs: Lang[] = [
  {
    label: 'English (UK)',
    value: 'en',
    systemValue: enUS,
    icon: 'gb',
    locale: gbLocale
  },
  {
    label: 'English (US)',
    value: 'en-US',
    systemValue: enUS,
    icon: 'us',
    locale: usLocale
  },
  {
    label: 'Italiano',
    value: 'it',
    systemValue: itIT,
    icon: 'it',
    locale: itLocale
  },
  {
    label: 'Deutsch',
    value: 'de',
    systemValue: deDE,
    icon: 'de',
    locale: deLocale
  },
  {
    label: 'Français',
    value: 'fr',
    systemValue: frFR,
    icon: 'fr',
    locale: frLocale
  },
  {
    label: 'Português',
    value: 'pt',
    systemValue: ptPT,
    icon: 'pt',
    locale: ptLocale
  },
  {
    label: 'Español',
    value: 'es',
    systemValue: esES,
    icon: 'es',
    locale: esLocale
  },
  {
    label: 'Nederlands',
    value: 'nl',
    systemValue: nlNL,
    icon: 'nl',
    locale: nlLocale
  }
];

export type Currency = {
  label: CurrencyTypes;
  symbol: string;
}

export const allCurrencies: Currency[] = [];

for (const currency of Object.values(CurrencyTypes)) {
  allCurrencies.push({
    label: currency,
    symbol: toMoneySymbol(currency),
  });
}

export const defaultLang = allLangs[0]; // English

export const defaultCurrency = allCurrencies.filter(x => x.label === CurrencyTypes.EUR)[0]; // English
