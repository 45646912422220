import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@mui/material';
import { useMemo, useState } from 'react';
import { UseFormReturn, useFieldArray, useForm, FormProvider } from 'react-hook-form';
import useLocales from 'src/appHooks/useLocales';
import { ingressDataSchema } from '../Constant';
import { yupResolver } from '@hookform/resolvers/yup';
import RHFDatePicker from 'src/components/hook-form/RHFDatePicker';
import { RHFTextField } from 'src/components/hook-form';
import * as Yup from 'yup';
import { DEFAULT_INGRESS_DATA, IngressData, LogisticsData } from 'src/@types/logistics';
import { IngressDataRow } from '../formRows/IngressDataRow';

type Props = {
    formMethods: UseFormReturn<LogisticsData>;
};

export default function IngressDataInfo({ formMethods }: Props) {

    const { control } = formMethods;

    const { translate } = useLocales();

    const [isEdit, setIsEdit] = useState<number | null>(null);

    const yupSchema = useMemo(() => ingressDataSchema, []);

    const methods = useForm<IngressData>({
        mode: 'onChange',
        resolver: yupResolver<any>(yupSchema),
        defaultValues: DEFAULT_INGRESS_DATA,
    });

    const { getValues, clearErrors, setError, reset, watch } = methods;

    const minDate = watch("ingressDate");

    const validateFields = async (yupSchema: any) => {
        const formValues = getValues();

        try {
            await yupSchema.validate(formValues, { abortEarly: false });
            clearErrors();

            return true;
        } catch (err: any) {
            clearErrors();
            (err as Yup.ValidationError).inner.forEach((er) => {
                setError(er.path as any, { message: `${translate(`commons.${er.message}`)}` });
            });

            return false;
        }
    };

    const TABLE_HEAD = useMemo(
        () => [
            {
                id: 'location',
                label: `${translate('vehicle.logistic.form.location')}`,
                align: 'left',
            },
            {
                id: 'ingressDate',
                label: `${translate('vehicle.logistic.form.ingressDate')}`,
                align: 'left',
            },
            {
                id: 'leaveDate',
                label: `${translate('vehicle.logistic.form.leaveDate')}`,
                align: 'left',
            },
            {
                id: 'options',
                label: ``,
                align: 'left',
            }
        ],
        [translate]
    );

    const {
        fields: ingressFields,
        append: appendIngressData,
        remove: removeIngressData,
        update: updateIngressData,
    } = useFieldArray({ name: 'ingress', control });

    const handleAddIngressData = async () => {
        const isValid = await validateFields(yupSchema);

        if (isValid) {
            appendIngressData({ ...getValues() });
            reset(DEFAULT_INGRESS_DATA);
        }
    };

    const duplicateIngressData = (index: number) => {
        appendIngressData({ ...ingressFields[index] });
    };

    const handleChangeIngressData = (index: number | null) => {
        if (index !== null) {
            updateIngressData(index, { ...getValues() });
            setIsEdit(null);
            reset(DEFAULT_INGRESS_DATA);
        }
    };

    const handleEditIngressData = (index: number) => {
        const editedIngressData = ingressFields[index];

        reset(editedIngressData);
        setIsEdit(index);
    };

    const handleCancelEditIngressData = () => {
        reset(DEFAULT_INGRESS_DATA);
        setIsEdit(null);
    };

    return (
        <Box>
            <Box
                sx={{
                    display: 'grid',
                    columnGap: 2,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
                    pt: 3
                }}
            >
                <FormProvider {...methods}>
                    <RHFTextField
                        name="location"
                        label={`${translate('vehicle.logistic.form.location')}`}
                    />
                    <RHFDatePicker
                        name={'ingressDate'}
                        label={`${translate('vehicle.logistic.form.ingressDate')}`}
                    />
                    <RHFDatePicker
                        name={'leaveDate'}
                        label={`${translate('vehicle.logistic.form.leaveDate')}`}
                        minDate={minDate}
                    />
                </FormProvider>
            </Box>

            <Box
                sx={{
                    p: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    columnGap: 2,
                }}
            >
                {isEdit !== null ? (
                    <>
                        <Button variant="outlined" size="small" onClick={() => handleChangeIngressData(isEdit)}>
                            {`${translate('commons.edit')}`}
                        </Button>
                        <Button
                            variant="outlined"
                            color="error"
                            size="small"
                            onClick={() => handleCancelEditIngressData()}
                        >
                            {`${translate('commons.cancel')}`}
                        </Button>
                    </>
                ) : (
                    <Button variant="outlined" size="small" onClick={() => handleAddIngressData()}>
                        {`${translate('vehicle.logistic.form.addIngressData')}`}
                    </Button>
                )}
            </Box>

            {!!ingressFields.length && (
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                    }}
                >
                    <TableContainer sx={{ maxHeight: '400px' }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {TABLE_HEAD.map((item) => (
                                        <TableCell
                                            sx={{ minWidth: '80px', whiteSpace: 'nowrap' }}
                                            align={item.align as 'inherit' | 'left' | 'center' | 'right' | 'justify'}
                                            key={item.id}
                                        >
                                            {item.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ingressFields.map((item, ind) => (
                                    <IngressDataRow
                                        key={ind}
                                        editableField={isEdit === ind}
                                        ingressData={item}
                                        remove={() => removeIngressData(ind)}
                                        edit={() => handleEditIngressData(ind)}
                                        duplicate={() => duplicateIngressData(ind)}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}
        </Box>
    );
}
