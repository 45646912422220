import axios, { AxiosResponse } from 'axios';
import { Core, Orders } from './paths';
import { OrganizationLogs, OrganizationFilters, OrganizationSearchResult, Statistic, GenericOrganization, GenericOrganizationForEdit, OrganizationSurveillance } from 'src/@types/organizations';
import { PagedResponseType, Suggest, Tag } from 'src/@types/commons';

const getNonEditables = (): Promise<AxiosResponse> => {
    const url = Core.admin.organizations.nonEditables();

    return axios.get(url);
};

const logs = (id: string): Promise<AxiosResponse<PagedResponseType<OrganizationLogs>>> => {
    const url = Core.admin.organizations.searchLogs(id);

    return axios.get(url);
};

const searchCustomerStatistics = (options: OrganizationFilters): Promise<AxiosResponse<Statistic>> => {
    const url = Core.admin.organizations.customer.statistic();

    return axios.get<Statistic>(url, { params: options });
};

const searchCustomer = (options: OrganizationFilters): Promise<AxiosResponse<PagedResponseType<OrganizationSearchResult>>> => {
    const url = Core.admin.organizations.customer.search();

    return axios.get<PagedResponseType<OrganizationSearchResult>>(url, { params: options });
};

const searchCustomerTags = (tag: string, size: number): Promise<AxiosResponse<Tag[]>> => {
    const url = Core.admin.organizations.customer.searchTags();

    return axios.get<Tag[]>(url, {
        params: {
            query: tag,
            limit: size
        }
    });
};

const suggestCustomerRegion = (country: string, searchTerm?: number | null): Promise<AxiosResponse<Suggest[]>> => {
    const url = Core.admin.organizations.customer.searchRegion();

    return axios.get<Suggest[]>(url, {
        params: {
            region: country,
            limit: searchTerm || 1
        }
    });
};

const searchVendorStatistics = (options: OrganizationFilters): Promise<AxiosResponse<Statistic>> => {
    const url = Core.admin.organizations.vendor.statistic();

    return axios.get<Statistic>(url, { params: options });
};

const searchVendor = (options: OrganizationFilters): Promise<AxiosResponse<PagedResponseType<OrganizationSearchResult>>> => {
    const url = Core.admin.organizations.vendor.search();

    return axios.get<PagedResponseType<OrganizationSearchResult>>(url, { params: options });
};

const searchVendorTags = (tag: string, size: number): Promise<AxiosResponse<Tag[]>> => {
    const url = Core.admin.organizations.vendor.searchTags();

    return axios.get<Tag[]>(url, {
        params: {
            query: tag,
            limit: size
        }
    });
};

const getOwners = (id: string): Promise<AxiosResponse<GenericOrganization[]>> => {
    const url = Core.admin.organizations.getOwners(id);

    return axios.get(url);
};

const suggestVendorRegion = (country: string, searchTerm?: number | null): Promise<AxiosResponse<Suggest[]>> => {
    const url = Core.admin.organizations.vendor.searchRegion();

    return axios.get<Suggest[]>(url, {
        params: {
            region: country,
            limit: searchTerm || 1
        }
    });
};

const detailGeneric = (id: string): Promise<AxiosResponse<GenericOrganization>> => {
    const url = Core.admin.organizations.detailGeneric(id);

    return axios.get(url);
};

const detailEdit = (id: string): Promise<AxiosResponse<GenericOrganizationForEdit>> => {

    const url = Core.admin.organizations.detailEdit(id);

    return axios.get(url);

};

const createGeneric = (organization: GenericOrganization): Promise<AxiosResponse> => {
    const url = Core.admin.organizations.createGeneric();

    return axios.post(url, organization);
};

const updateGeneric = (organization: GenericOrganization & { id: string }): Promise<AxiosResponse> => {
    const url = Core.admin.organizations.updateGeneric();

    return axios.put(url, organization);
};

const detailMultiple = (ids: string[]): Promise<AxiosResponse<GenericOrganization[]>> => {
    const url = Core.admin.organizations.detailMultiple();

    return axios.get(url, { params: { ids: ids }, paramsSerializer: { indexes: null } });
};

const searchLogs = (id: string, filters: any): Promise<AxiosResponse<PagedResponseType<any>>> => {
    const url = Core.admin.organizations.searchLogs(id);

    return axios.get<any>(url, { params: filters });
};

const searchSurveillance = (id: string): Promise<AxiosResponse<OrganizationSurveillance>> => {
    const url = Orders.admin.returns.customerSurveillance(id);

    return axios.get(url);
};

export {
    logs,
    getNonEditables,
    detailGeneric,
    createGeneric,
    updateGeneric,
    detailMultiple,
    searchLogs,
    searchCustomer,
    searchCustomerStatistics,
    searchVendor,
    searchCustomerTags,
    searchVendorStatistics,
    searchVendorTags,
    suggestCustomerRegion,
    suggestVendorRegion,
    getOwners,
    detailEdit,
    searchSurveillance
};
