import { LicenseTypes, TenantType } from "src/@types/tenant";

const hasLicense = (
    tenant: TenantType,
    license: LicenseTypes
): boolean => {
    return tenant.licenses.includes(license);
};

export {
    hasLicense
};