import { Card, Typography, Divider, TextField, MenuItem, Container, Button, } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useMemo, useReducer, useState } from "react";
import { DEFAULT_NEW_NOTIFICATION_TEMPLATE, NewNotificationTemplate, NotificationTemplate, NotificationTypeArr } from "src/@types/inbox";
import GenericLocalizedInput from "src/appComponents/GenericLocalizedInput";
import Page from "src/appComponents/Page";
import { useSettingsContext } from "src/components/settings";
import { useLocales } from "src/locales";
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { PATH_DASHBOARD } from "src/routes/paths";
import { useNavigate, useParams } from "react-router";
import { dispatch, RootState } from "src/redux/store";
import { inboxOperations } from "src/redux/inbox";
import { useSelector } from "react-redux";
import LoadingScreen from "src/appComponents/loading-screen/LoadingScreen";
import PermissionTable from "src/components/table/PermissionTable";
import { setSuccessMessage } from "src/redux/modal/modal-slices";

enum ActionTypes {
    STANDARD,
    PERMISSION,
    RESET,
}

type Action = {
    type: ActionTypes
    payload: {
        name: string,
        value: any
    }
};

function reduce(state: NewNotificationTemplate, action: Action): NewNotificationTemplate {
    switch (action.type) {

        case ActionTypes.STANDARD:
            return { ...state, [action.payload.name]: action.payload.value };

        case ActionTypes.PERMISSION:

            return {
                ...state,
                permissionDependency: {
                    ...state.permissionDependency,
                    [action.payload.name]: action.payload.value
                }
            };

        case ActionTypes.RESET:
            return { ...DEFAULT_NEW_NOTIFICATION_TEMPLATE, ...action.payload.value };

        default:
            return state;
    };
}

export default function NewEditNotificationTemplate() {

    const { translate } = useLocales();

    const { themeStretch } = useSettingsContext();

    const navigate = useNavigate();

    const { isTemplateLoading, template } = useSelector((state: RootState) => state.inbox);

    const [nameLang, setNameLang] = useState('en');

    const [descLang, setDescLang] = useState('en');

    const [state, setState] = useReducer(reduce, DEFAULT_NEW_NOTIFICATION_TEMPLATE);

    const { id } = useParams();

    const disabled = useMemo(() => {

        return state.displayName.every(v => !v.text);
    }, [state.displayName]);

    useEffect(() => {
        if (id) {
            dispatch(inboxOperations.notificationTemplateDetail(id)).unwrap().then((val) => {

                setState({ payload: { name: "", value: val }, type: ActionTypes.RESET });
            });
        }
    }, [id]);

    const setValue = (name: string, value: any) => {
        setState({ payload: { name, value }, type: ActionTypes.STANDARD });
    };

    const handleSubmit = async () => {
        if (id)
            await dispatch(inboxOperations.editNotificationTemplate({ id, notification: state as NotificationTemplate })).unwrap();
        else
            await dispatch(inboxOperations.createNotificationTemplate(state)).unwrap();

        dispatch(setSuccessMessage({ text: translate('notificationTemplate.messages.success'), goTo: PATH_DASHBOARD.tenant.notificationTemplate.list }));
    };

    return (
        <Page title={id ? `${translate('notificationTemplate.form.edit')}` : `${translate('menu.management.notification.new')}`}>
            {isTemplateLoading ? <LoadingScreen /> :

                <Container maxWidth={themeStretch ? false : 'lg'}>

                    <HeaderBreadcrumbs
                        heading={id ? `${translate('notificationTemplate.form.edit')}` : `${translate('notificationTemplate.form.new')}`}
                        links={[
                            { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                            { name: `${translate('commons.list')}`, href: PATH_DASHBOARD.tenant.notificationTemplate.list },
                            { name: id ? `${translate('notificationTemplate.form.edit')}` : `${translate('menu.management.notification.new')}` }
                        ]}
                    />
                    <Card sx={{ p: 3 }}>

                        <Box sx={{ display: 'grid', gridTemplateColumns: "1fr 1fr", columnGap: 3, width: '100%' }}>

                            <Box sx={{ width: '100%' }}>
                                <GenericLocalizedInput
                                    name={'displayName'}
                                    label={`${translate('commons.name')}`}
                                    setValue={setValue}
                                    tabs={[...state.displayName]}
                                    lang={nameLang}
                                    onChangeLang={setNameLang}

                                    textFieldProps={{
                                        fullWidth: true,
                                        required: true
                                    }}
                                />
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <GenericLocalizedInput
                                    name={'description'}
                                    label={`${translate('commons.description')}`}
                                    setValue={setValue}
                                    tabs={[...state.description]}
                                    lang={descLang}
                                    onChangeLang={setDescLang}
                                    textFieldProps={{
                                        fullWidth: true,
                                    }}
                                />
                            </Box>
                            <TextField
                                sx={{ mt: 3, pr: 3 }}
                                name="notificationType"
                                select
                                fullWidth
                                label={`${translate('notificationTemplate.form.notificationType')}`}
                                value={state.notificationType}
                                onChange={(e) => setState({
                                    payload: { name: e.target.name, value: e.target.value },
                                    type: ActionTypes.STANDARD
                                })}
                            >
                                {NotificationTypeArr.map((v, ind) =>
                                    <MenuItem value={v} key={ind} >
                                        {`${translate(`notifications.settings.${v[0].toLowerCase() + v.slice(1)}`)}`}
                                    </MenuItem>
                                )}
                            </TextField>
                        </Box>
                        <Divider sx={{ my: 2 }} />

                        <Typography>{`${translate('notificationTemplate.form.permission_plural')}`}</Typography>
                        <Box sx={{ mt: 3 }}>
                            <PermissionTable
                                checkbox
                                isAll
                                switchField
                                setSwitch={(v) => setState({ payload: { name: 'atLeastOne', value: v }, type: ActionTypes.PERMISSION })}
                                swithcValue={state.permissionDependency.atLeastOne}
                                defaultValue={template?.permissionDependency.permissions || undefined}
                                onChange={(perm) => setState({
                                    payload: {
                                        name: "permissions",
                                        value: perm
                                    },
                                    type: ActionTypes.PERMISSION
                                })} />
                        </Box>
                        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                            <Button variant="soft" color="inherit" onClick={() => navigate(PATH_DASHBOARD.tenant.notificationTemplate.list)}>
                                {`${translate("commons.cancel")}`}
                            </Button>
                            <Button type="submit" variant="contained" disabled={disabled} onClick={handleSubmit}>
                                {`${translate("commons.justSave")}`}
                            </Button>
                        </Box>
                    </Card>
                </Container>}
        </Page>
    );
}

