import Page from "src/appComponents/Page";
import { Card, Container } from "@mui/material";
import { useSettingsContext } from "src/components/settings";
import HeaderBreadcrumbs from '../../../components/custom-breadcrumbs';
import { useLocales } from "src/locales";
import useResponsive from "src/hooks/useResponsive";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import { RootState, dispatch } from "src/redux/store";
import { reportsOperations } from "src/redux/reports";
import { NewReportScheduled, NewReportRecurring, ReportTypeData } from "src/@types/report";
import { useSelector } from "react-redux";
import ReportNewEdit from "src/sections/@dashboard/report/edit/ReportNewEdit";
import LoadingScreen from "src/appComponents/loading-screen/LoadingScreen";
import { setSuccessMessage } from "src/redux/modal/modal-slices";

interface ReportEditPageProps {
    isScheduled: boolean
}

export default function ReportEditPage({ isScheduled }: ReportEditPageProps) {

    const { themeStretch } = useSettingsContext();

    const { translate } = useLocales();

    const isDesktop = useResponsive('up', 'md');

    const { id, type } = useParams();

    const category = useMemo(() => {

        if (type) {
            if (type.toLowerCase() === "container")
                return "shipping";
            if (type.toLowerCase() === "returnrequest" || type.toLowerCase() === "document")
                return "order";
        }

        return "";
    }, [type]);

    const { report, isLoading } = useSelector((state: RootState) => state.reports);

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(reportsOperations.getColumns(type?.toLowerCase() as ReportTypeData));
    }, [type]);

    useEffect(() => {
        if (id) {
            dispatch(reportsOperations.getReport({ isScheduled: isScheduled, id, type: type as ReportTypeData, category }));
        }
    }, [category, id, isScheduled, type]);

    const handleSubmit = async (state: NewReportScheduled | NewReportRecurring) => {
        if (!report)
            throw new Error();

        if (isScheduled)
            await dispatch(reportsOperations.createReport({ isScheduled: true, report: { ...state, filter: report.filter }, type: type as ReportTypeData, category: type?.toLowerCase() !== "container" ? 'order' : 'shipping' })).unwrap();
        else
            await dispatch(reportsOperations.editReport({ report: { ...state, filter: report.filter } as any, id: id!, type: type as ReportTypeData })).unwrap();

        dispatch(setSuccessMessage({ text: translate('report.messages.successSave'), returnTo: isScheduled ? PATH_DASHBOARD.reports.scheduled.list : PATH_DASHBOARD.reports.recurring.list }));

        if (id)
            dispatch(reportsOperations.getReport({ isScheduled, id, type: type as ReportTypeData, category }));
    };

    return (
        <Page title={isScheduled ? `${translate('report.form.title.reschedule')}` : `${translate('report.recurring.edit')}`}>
            {isLoading ? <LoadingScreen /> :
                <Container maxWidth={themeStretch ? false : 'lg'}>

                    <HeaderBreadcrumbs
                        heading={isScheduled ? `${translate('report.form.title.reschedule')}` : `${translate('report.recurring.edit')}`}
                        links={[
                            { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                            { name: `${translate(isScheduled ? 'menu.management.reports.scheduled.title' : 'menu.management.reports.recurring.title')}`, href: isScheduled ? PATH_DASHBOARD.reports.scheduled.list : PATH_DASHBOARD.reports.recurring.list },
                            { name: report?.name || "-" }
                        ]}
                        sx={{ px: isDesktop ? 5 : 2, mb: isDesktop ? 5 : 2 }}
                    />

                    <Card sx={{ p: 2 }}>
                        <ReportNewEdit
                            isOpen={isScheduled ? 'scheduled' : 'recurring'}
                            onSubmit={handleSubmit}
                            toggle={() => navigate(PATH_DASHBOARD.reports.recurring.list)}
                            isEdit
                        />
                    </Card>

                </Container>}
        </Page>
    );
}