// @mui
import { Box, Link, Stack, Typography, Breadcrumbs } from '@mui/material';
//
import { CustomBreadcrumbsProps } from './types';
import LinkItem from './LinkItem';
import useResponsive from 'src/hooks/useResponsive';

// ----------------------------------------------------------------------

export default function HeaderBreadcrumbs({
  links,
  action,
  heading,
  moreLink,
  activeLast,
  sx,
  ...other
}: CustomBreadcrumbsProps) {
  const lastLink = links[links.length - 1].name;

  const isDesktop = useResponsive('up', 'md');

  return (
    <Box sx={{ mb: 3, mx: isDesktop ? 0 : 1, ...sx }}>
      <Stack direction={isDesktop ? 'row' : 'column'} alignItems="center">

        <Box sx={{ flexGrow: 1, mr: "auto" }}>
          {/* HEADING */}
          {heading && (
            <Typography variant="h4" gutterBottom >
              {heading}
            </Typography>
          )}

          {/* BREADCRUMBS */}
          {!!links.length && (
            <Breadcrumbs separator={<Separator />} {...other}>
              {links.map((link) => (
                <LinkItem
                  key={link.name || ''}
                  link={link}
                  activeLast={activeLast}
                  disabled={link.name === lastLink}
                  previousPage={link.previousPage}
                />
              ))}
            </Breadcrumbs>
          )}
        </Box>

        {action && <Box sx={{ flexShrink: 0, pt: isDesktop ? 0 : 1.5, ml: "auto" }}> {action} </Box>}
      </Stack >

      {/* MORE LINK */}
      {
        !!moreLink && (
          <Box sx={{ mt: 2 }}>
            {moreLink.map((href) => (
              <Link
                noWrap
                key={href}
                href={href}
                variant="body2"
                target="_blank"
                rel="noopener"
                sx={{ display: 'table' }}
              >
                {href}
              </Link>
            ))}
          </Box>
        )
      }
    </Box >
  );
}

// ----------------------------------------------------------------------

function Separator() {
  return (
    <Box
      component="span"
      sx={{ width: 4, height: 4, borderRadius: '50%', bgcolor: 'text.disabled' }}
    />
  );
}
