import { Box, Card, Container, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import Page from "src/appComponents/Page";
import LoadingScreen from "src/appComponents/loading-screen";
import { CustomAvatar } from "src/components/custom-avatar";
import { useSettingsContext } from "src/components/settings";
import { useLocales } from "src/locales";
import { ordersOperations } from "src/redux/orders";
import { RootState, dispatch } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { bgBlur } from "src/utils/cssStyles";
import ListAltIcon from '@mui/icons-material/ListAlt';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SettingsIcon from '@mui/icons-material/Settings';
import { StyledInfo, StyledRoot } from "src/utils/Detail";
import OrderGeneralDetails from "src/sections/@dashboard/orders/detail/OrderGeneralDetails";
import OrderDetailItems from "src/sections/@dashboard/orders/detail/OrderDetailItems";

interface OrderDetailProps {
    isVendor: boolean
}

export default function OrderDetail({ isVendor }: OrderDetailProps) {
    const { order, isOrderLoading } = useSelector((state: RootState) => state.orders);

    const { id } = useParams();

    const { translate } = useLocales();

    const { themeStretch } = useSettingsContext();

    const [currentTab, setCurrentTab] = useState<string>('general');

    const theme = useTheme();

    const TABS = useMemo(() => [{
        value: 'general',
        label: `${translate('commons.general')}`,
        icon: <AccountBoxIcon />,
        component: <OrderGeneralDetails order={order!} />
    },
    {
        value: 'items',
        label: `${translate('orders.filter.item')}`,
        icon: <SettingsIcon />,
        component: <OrderDetailItems order={order!} />
    }], [order, translate]);

    useEffect(() => {
        if (id)
            dispatch(ordersOperations.getOrder(id));
    }, [id]);

    return (
        <Page title={`${translate("orders.detail.title")}`}>
            {isOrderLoading || !order ? (
                <LoadingScreen />) : (
                <Container maxWidth={themeStretch ? false : 'lg'}>
                    <HeaderBreadcrumbs
                        heading={`${translate('orders.detail.title')}`}
                        links={[
                            { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                            { name: `${translate("menu.management.organizations.list")}`, href: isVendor ? PATH_DASHBOARD.orders.vendor.list : PATH_DASHBOARD.orders.customer.list },
                            { name: order?.prettyName }
                        ]}
                    />
                    <Card
                        sx={{
                            mb: 3,
                            height: 280,
                            position: 'relative',
                        }}
                    >
                        <StyledRoot sx={{
                            '&:before': {
                                ...bgBlur({
                                    color: theme.palette.primary.lighter,
                                }),
                            }
                        }
                        } >
                            <StyledInfo>
                                <CustomAvatar
                                    name={''}
                                    color={"primary"}

                                    sx={{
                                        mx: 'auto',
                                        borderWidth: 2,
                                        borderStyle: 'solid',
                                        borderColor: 'common.white',
                                        color: "white",
                                        width: { xs: 80, md: 128 },
                                        height: { xs: 80, md: 128 },
                                    }}
                                >
                                    <ListAltIcon color="inherit" />
                                </CustomAvatar>

                                <Box
                                    sx={{
                                        ml: { md: 3 },
                                        mt: { xs: 1, md: 0 },
                                        color: 'common.white',
                                        textAlign: { xs: 'center', md: 'left' },
                                    }}
                                >
                                    <Typography color={"black"} fontSize={"24px"} variant="h4">{order?.prettyName}</Typography>
                                </Box>
                            </StyledInfo>
                        </StyledRoot>
                        <Tabs
                            value={currentTab}
                            onChange={(_, newValue) => setCurrentTab(newValue)}
                            sx={{
                                width: 1,
                                bottom: 0,
                                zIndex: 9,
                                pl: 18,
                                position: 'absolute',
                                bgcolor: 'background.paper',
                                '& .MuiTabs-flexContainer': {
                                    pr: { md: 2 },
                                    justifyContent: {
                                        sm: 'center',
                                        md: 'flex-end',
                                    },
                                },
                            }}
                        >
                            {TABS.map((tab, ind) => <Tab key={ind} label={tab.label} icon={tab.icon} value={tab.value} />)}
                        </Tabs>
                    </Card>
                    {TABS.map((tab, ind) => tab.value === currentTab && <Box key={ind}>{tab.component}</Box>)}
                </Container>)}
        </Page>
    );
}