import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import { Box } from '@mui/material';
import { MatrixResult } from '../../hooks/usePowersearch';

type UserSearchTemplateProps = {
  option: MatrixResult;
  searchQuery: string;
};

export default function UserSearchTemplate({ option, searchQuery }: UserSearchTemplateProps) {
  const nameParts = parse(
    `${option.firstname} ${option.lastname}`,
    match(`${option.firstname} ${option.lastname}`, searchQuery)
  );

  const pathParts = parse(option?.path, match(option?.path, searchQuery));

  return (
    <div>
      {option?.firstname && option?.lastname && (
        <div>
          {nameParts.map((part, index) => (
            <Box
              key={index}
              component="span"
              sx={{
                typography: 'subtitle2',
                textTransform: 'capitalize',
                color: part.highlight ? 'primary.main' : 'text.primary',
              }}
            >
              {part.text}
            </Box>
          ))}
        </div>
      )}

      {option?.path && (
        <div>
          {pathParts?.map((part, index) => (
            <Box
              key={index}
              component="span"
              sx={{
                typography: 'caption',
                color: part.highlight ? 'primary.main' : 'text.secondary',
              }}
            >
              {part.text}
            </Box>
          ))}
        </div>
      )}
    </div>
  );
}
