import { Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/system';
import { useCallback, useMemo } from 'react';
import { SelectItem } from 'src/@types/commons';
import useTenant from 'src/appHooks/useTenant';
import { useLocales } from 'src/locales';

export const RootStyle = styled('div')({
    flexGrow: 1,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
});

export const WrapperStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'stretch',
    margin: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    border: `solid 1px ${theme.palette.divider}`
}));

export const LabelStyle = styled('span')(({ theme }) => ({
    ...theme.typography.subtitle2,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.neutral,
    borderRight: `solid 1px ${theme.palette.divider}`
}));

export interface ChipProps {
    el: string,
    onResetFilter: (el: any, value?: any) => void,
    value: string | string[] | boolean,
    toDelete?: string
}

export function FilterChip({ el, onResetFilter, value, toDelete }: ChipProps) {

    const { translate } = useLocales();

    const splittedEl = el.split(".");

    const toRemove = toDelete ? toDelete : splittedEl[splittedEl.length - 1];

    return (
        <WrapperStyle>
            <LabelStyle>{`${translate(el)}`}</LabelStyle>

            <Stack direction="row" flexWrap="wrap" sx={{ p: 0.75 }}>
                <Chip
                    key={el}
                    label={value.toString()}
                    size="small"
                    onDelete={() => onResetFilter(toRemove)}
                    sx={{ m: 0.5 }}
                />
            </Stack>
        </WrapperStyle>
    );
}

export interface CustomFieldsChipProps {
    onResetFilter: (el: any, value?: any) => void,
    context: string,
    customfieldValues: Record<string, string>
}

export function CustomFieldsFilterChip({ onResetFilter, context, customfieldValues }: CustomFieldsChipProps) {

    const { currentLang } = useLocales();

    const { customFields } = useTenant();

    const summaryCustomFields = useMemo(() => {

        const fields = customFields.filter((field) => field.enabled && field.searchable && field.entityType.toLowerCase() === context.toLowerCase());

        return fields.sort((a, b) => a.name[0].text!.toLowerCase().localeCompare(b.name[0].text!.toLowerCase()));

    }, [customFields, context]);

    const getLabel = useCallback((name: string) => {

        const customFound = summaryCustomFields.find(x => x.id === name);

        return customFound!.name.find(x => x.culture === currentLang.value)?.text || customFound!.name[0].text;
    }, [currentLang, summaryCustomFields]);

    return (
        <>
            {
                Object.entries(customfieldValues).map(([key, val], index) =>
                    <WrapperStyle key={key + "." + index}>
                        <LabelStyle>{getLabel(key)}</LabelStyle>

                        <Stack direction="row" flexWrap="wrap" sx={{ p: 0.75 }}>
                            <Chip
                                key={key}
                                label={val.toString()}
                                size={"small"}
                                onDelete={() => onResetFilter("customFields", key)}
                                sx={{ m: 0.5 }}
                            />
                        </Stack>
                    </WrapperStyle>
                )
            }
        </>
    );
}

export interface MultipleChipProps {
    el: string,
    onResetFilter: (el: any, value?: any) => void,
    value: string[],
    labels?: SelectItem[],
    toDelete?: string
}

export function MultipleFilterChip({ el, onResetFilter, value, labels, toDelete }: MultipleChipProps) {

    const { translate } = useLocales();

    const splittedEl = el.split(".");

    const toRemove = toDelete ? toDelete : splittedEl[splittedEl.length - 1];

    return (
        <WrapperStyle>
            <LabelStyle>{`${translate(el)}:`}</LabelStyle>

            <Stack direction="row" flexWrap="wrap" sx={{ p: 0.75 }}>
                {value.map((obj: string) => (
                    <Chip
                        key={obj}
                        label={labels ? labels.find((item) => item.value === obj)?.label : obj}
                        size="small"
                        onDelete={() => onResetFilter(toRemove, obj)}
                        sx={{ m: 0.5 }}
                    />
                ))}
            </Stack>
        </WrapperStyle>
    );
}