import { Box, Button, Container, styled, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Page from "src/appComponents/Page";
import { useAuth } from "src/appHooks/useAuth";
import { useLocales } from "src/locales";
import { PATH_AUTH } from "src/routes/paths";

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

interface AccountPendingActivationProps {
    contactAdmin?: boolean
}

export default function AccountPendingActivation({ contactAdmin }: AccountPendingActivationProps) {

    const { translate } = useLocales();

    const { logout } = useAuth();

    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate(PATH_AUTH.login, { replace: true });
    };

    return (
        <Page title={`${translate('pendingActivation.title')}`} sx={{ bgcolor: (theme) => theme.palette.divider }}>
            <Container >
                <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
                    <Box>
                        {contactAdmin ?
                            <Typography variant="h3" paragraph>
                                {`${translate('pendingActivation.contactAdminTitle')}`}
                            </Typography>
                            :
                            <Typography variant="h3" paragraph>
                                {`${translate('pendingActivation.title')}`}
                            </Typography>}
                    </Box>

                    <Box>
                        {contactAdmin ?
                            <Typography sx={{ color: 'text.secondary' }} paragraph>
                                {`${translate('pendingActivation.contactAdminMessage')}`}
                            </Typography>
                            :
                            <Typography sx={{ color: 'text.secondary' }} paragraph>
                                {`${translate('pendingActivation.message')}`}
                            </Typography>}
                    </Box>

                    <Box sx={{ my: 5, display: 'flex', justifyContent: 'center' }}>
                        <img width='75%' src="/assets/illustrations/characters/character_5.png" alt="waiting" />
                    </Box>

                    <Button size="large" variant="contained" onClick={() => handleLogout()}>
                        {`${translate('commons.logout')}`}
                    </Button>
                </ContentStyle>
            </Container>
        </Page>
    );
}