export type Email = {
    id: string,
    status: string,
    subject: string,
    recipient: string,
    requestedAt: string
};

export type EmailDetails = {
    id: string,
    status: string,
    subject: string,
    recipient: string,
    requestedAt: string,
    processingTimeMilliseconds: number,
    serverResponse: string,
    bounceType: string,
    bounceSubType: string,
    bounceAction: string,
    bounceDiagnosticCode: string,
    complaintArrivalDate: string,
    complaintFeedbackType: string
};

export type EmailStatistics = {
    all: number,
    delivery: number,
    bounce: number,
    complaint: number
};

/* --------------------------- */

export const DEFAULT_EMAIL: Email = {
    id: '',
    status: '',
    subject: '',
    recipient: '',
    requestedAt: ''
};

export const DEFAULT_EMAILS_DETAILS: EmailDetails = {
    id: '',
    status: '',
    subject: '',
    recipient: '',
    requestedAt: '',
    processingTimeMilliseconds: 0,
    serverResponse: '',
    bounceType: '',
    bounceSubType: '',
    bounceAction: '',
    bounceDiagnosticCode: '',
    complaintArrivalDate: '',
    complaintFeedbackType: ''
};

export const DEFAULT_STATISTICS: EmailStatistics = {
    all: 0,
    delivery: 0,
    bounce: 0,
    complaint: 0
};