import { Container } from '@mui/material';
import useLocales from 'src/appHooks/useLocales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import Page from 'src/appComponents/Page';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { useEffect } from 'react';
import { reportsOperations } from 'src/redux/reports';
import { dispatch } from 'src/redux/store';
import DocumentsList from './DocumentsList';

export default function CustomerDocumentsList() {

    const { themeStretch } = useSettingsContext();

    const { translate } = useLocales();

    useEffect(() => {
        dispatch(reportsOperations.getColumns("document"));
    }, []);

    return (
        <Page title={`${translate('menu.management.documents.list')}`}>
            <Container maxWidth={themeStretch ? false : 'lg'}>

                <HeaderBreadcrumbs
                    heading={`${translate('menu.management.documents.list')}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate('commons.list')}` },
                    ]}
                />

                <DocumentsList isVendor={false} />

            </Container>
        </Page>
    );
}
