import axios, { AxiosResponse } from "axios";
import { MinimumAmount, Period, Rule, SoftRuleEdit, SurveillanceRulesFormResult } from "src/@types/rules";
import { Orders } from "./paths";
import { PagedResponseType } from "src/@types/commons";

const detail = (): Promise<AxiosResponse<Rule>> => {
    const url = Orders.admin.returns.rules.detail();

    return axios.get(url);
};

const updateParts = (options: { products: string[] }): Promise<AxiosResponse<Rule>> => {
    const { products } = options;

    const url = Orders.admin.returns.rules.updatePart();

    return axios.put(url, { products: products });
};

const updateCategory = (options: { categories: string[] }): Promise<AxiosResponse<Rule>> => {
    const { categories } = options;

    const url = Orders.admin.returns.rules.updateCategory();

    return axios.put(url, { categories: categories });
};

const updateInvoice = (options: SoftRuleEdit): Promise<AxiosResponse<Rule>> => {

    const url = Orders.admin.returns.rules.updateInvoice();

    return axios.put(url, options);
};

const updateFrequency = (options: SoftRuleEdit): Promise<AxiosResponse<Rule>> => {

    const url = Orders.admin.returns.rules.updateFrequency();

    return axios.put(url, options);
};

const updateSurveillance = (options: SurveillanceRulesFormResult): Promise<AxiosResponse<Rule>> => {

    const url = Orders.admin.returns.rules.updateSurvelliance();

    return axios.put(url, options);
};

const updatePeriod = (period: Period): Promise<AxiosResponse> => {

    const url = Orders.admin.returns.rules.updatePeriod();

    return axios.put(url, period);
};

const updateMinimumAmount = (minimumAmount: MinimumAmount): Promise<AxiosResponse> => {
    const url = Orders.admin.returns.rules.updateMinimunAmount();

    return axios.put(url, { minimumAmount });
};

const logs = (filters: any): Promise<AxiosResponse<PagedResponseType<any>>> => {
    const url = Orders.admin.returns.rules.getLogs();

    return axios.get(url, { params: filters });
};

export {
    detail,
    updateCategory,
    updateInvoice,
    updateFrequency,
    updateParts,
    updateSurveillance,
    logs,
    updateMinimumAmount,
    updatePeriod
};