import { Container } from "@mui/system";
import { useNavigate, useParams } from "react-router";
import Page from "src/appComponents/Page";
import { useSettingsContext } from "src/components/settings";
import HeaderBreadcrumbs from '../../../components/custom-breadcrumbs';
import { useLocales } from "src/locales";
import { PATH_DASHBOARD } from "src/routes/paths";
import { Box, Tab, Tabs } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import AnomalyRequestDetail from "src/sections/@dashboard/anomaly/detail/AnomalyRequestDetail";
import { hasPermissions } from "src/utils/user";
import { ordersOperations } from "src/redux/orders";
import { RootState, dispatch, useSelector } from "src/redux/store";
import { DeliveryNote, DocData, Invoice, Order } from "src/@types/orders";
import { requestOperations } from "src/redux/request";
import { OrganizationPermissionTypes } from "src/@types/permissions";
import { useUserOrganizationContext } from "src/contexts/UserOrganizationContext";
import OrganizationDetail from "src/sections/@dashboard/organizations/detail/OrganizationDetail";
import { RequestItem } from "src/@types/request";
import BacklogDoumentDetail from "src/sections/@dashboard/backlogRequest/BacklogDoumentDetail";
import LoadingScreen from "src/appComponents/loading-screen";

export default function AnomalyDetail() {

    const { translate } = useLocales();

    const { themeStretch } = useSettingsContext();

    const { id, tab, backTo } = useParams();

    const { organization } = useUserOrganizationContext();

    const [currentTab, setCurrentTab] = useState("requestDetails");

    const [document, setDocument] = useState<Invoice | DeliveryNote>();

    const [orderList, setOrderList] = useState<Order[]>([]);

    const navigate = useNavigate();

    const { anomaly, isLoading } = useSelector((state: RootState) => state.request);

    const { isOrderLoading } = useSelector((state: RootState) => state.orders);

    //GET ANOMALY DATA
    useEffect(() => {
        if (id) {
            dispatch(requestOperations.getRequest({ id: id, anomaly: true }));
        }
    }, [id]);

    //MANAGE TABS
    const onTabChange = (newTab: string) => {
        switch (newTab) {
            case "requestDetails":
                navigate(PATH_DASHBOARD.request.vendor.anomalies.detail(id!, backTo!));
                break;
            case "customerInfo":
                navigate(PATH_DASHBOARD.request.vendor.anomalies.organizationDetailsTab(id!, newTab, anomaly!.customer.type, anomaly!.customer.id, "general", backTo!));
                break;
            default:
                navigate(PATH_DASHBOARD.request.vendor.anomalies.detailsTab(id!, newTab, backTo!));
        }

        setCurrentTab(newTab);
    };

    useEffect(() => {
        setCurrentTab(tab || 'requestDetails');
    }, [tab]);

    //CREATE ITEMS LIST
    const itemsList: RequestItem[] = useMemo(() => {
        if (anomaly) {
            if (anomaly.items.length > 0) return anomaly.items;
            else if (anomaly.missingItems.length > 0) return anomaly.missingItems;

            return [];
        } else return [];
    }, [anomaly]);

    //GET ORDER DETAILS
    useEffect(() => {
        if (anomaly && id && anomaly.id === id) {

            const orderIdSet = new Set([...itemsList.map(i => i.orderId)]);

            orderIdSet.forEach((orderId) => {
                dispatch(ordersOperations.getOrder(orderId)).unwrap().then(order => setOrderList(p => p.concat([order])));
            });
        }
    }, [anomaly, id, itemsList]);

    //GET DOCUMENTS DETAILS
    const invoicesList: DocData[] = useMemo(() => {
        if (anomaly && orderList.length > 0) {
            return itemsList.map((item) => {

                const order = orderList.find((order) => order.id === item.orderId);

                if (order && order.invoiceId) {
                    return {
                        partExternalId: item.product.externalId,
                        documentId: order!.invoiceId,
                        documentExternalId: order!.invoiceExternalId
                    } as DocData;
                } else return {} as DocData;

            }).filter((doc) => JSON.stringify(doc) !== "{}");
        } else return [];
    }, [anomaly, itemsList, orderList]);

    const deliveryNotesList: DocData[] = useMemo(() => {
        if (anomaly && orderList.length > 0) {
            return itemsList.map((item) => {

                const order = orderList.find((order) => order.id === item.orderId);

                if (order && order.deliveryNoteId) {
                    return {
                        partExternalId: item.product.externalId,
                        documentId: order!.deliveryNoteId,
                        documentExternalId: order!.deliveryNoteExternalId
                    } as DocData;
                } else return {} as DocData;

            }).filter((doc) => JSON.stringify(doc) !== "{}");
        } else return [];
    }, [anomaly, itemsList, orderList]);

    const TABS = useMemo(() => [
        {
            value: 'requestDetails',
            label: `${translate('request.returnDetails')}`,
            component: anomaly && <AnomalyRequestDetail request={anomaly} />
        },

        ...(hasPermissions([OrganizationPermissionTypes.WebShop_Orders_VendorView], organization.roles, false) ? [{
            value: 'documents',
            label: `${translate('request.document')}`,
            component: anomaly && <BacklogDoumentDetail document={document} setDocument={setDocument} invoicesList={invoicesList} deliveryNotesList={deliveryNotesList} itemsList={itemsList} />
        }] : []),

        ...(hasPermissions([OrganizationPermissionTypes.Organization_View], organization.roles, false) ? [{
            value: 'customerInfo',
            label: `${translate('request.customerInfo')}`,
            component: anomaly && <OrganizationDetail isVendor={false} id={anomaly.customer.id} type={anomaly.customer.type} tab={"general"} requestId={anomaly.id} />,
        }] : [])
    ], [translate, anomaly, organization.roles, document, deliveryNotesList, invoicesList, itemsList]);

    return (
        <Page title={`${translate("request.anomalies.detailPage")}`}>
            {(isLoading || isOrderLoading) ? <LoadingScreen />
                :
                <Container maxWidth={themeStretch ? false : 'lg'}>

                    <HeaderBreadcrumbs
                        heading={`${translate('request.anomalies.detailPage')}`}
                        links={[
                            { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                            { name: `${translate('menu.management.anomalies.title')}`, href: PATH_DASHBOARD.request.vendor.anomalies.list },
                            { name: `${translate('returns.filter.idRequest')} ${anomaly?.prettyName}` }
                        ]}
                    />

                    <Tabs
                        value={currentTab}
                        onChange={(_, newValue) => onTabChange(newValue)}
                    >
                        {TABS.map((tab, index) =>
                            <Tab key={index} value={tab.value} label={tab.label} />
                        )}

                    </Tabs>

                    {TABS.map((tab, ind) =>
                        tab.value === currentTab &&
                        <Box key={ind}>
                            {tab.component}
                        </Box>
                    )}

                </Container>
            }
        </Page>
    );
}