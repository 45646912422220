import { MenuItem, TableCell, TableRow, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { EAMData } from 'src/@types/logistics';
import TableMoreMenu from 'src/appComponents/TableMoreMenu';
import useLocales from 'src/appHooks/useLocales';
import { DescriptionTooltip } from '../../details/VehicleLogisticsInfo';
import { useSettingsContext } from 'src/components/settings';

interface IProps {
    eamData: EAMData;
    remove: () => void;
    edit: () => void;
    duplicate: () => void;
    editableField: boolean;
}

export const EAMDataRow = ({ remove, edit, duplicate, eamData, editableField }: IProps) => {

    const { currency } = useSettingsContext();

    const theme = useTheme();

    const { translate } = useLocales();

    const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    return (
        <TableRow
            sx={{
                background: `${editableField && theme.palette.primary.lighter + '80'}`,
                borderBottom: '1px solid',
                borderBottomColor: 'grey.300',
            }}
        >
            <TableCell align="left">{eamData.code}</TableCell>
            <TableCell align="left">{eamData.price} {`${currency.symbol} `}</TableCell>

            <TableCell align='left' sx={{ maxWidth: "250px" }}>
                <DescriptionTooltip title={eamData.description} arrow>
                    <Typography sx={{ overflow: 'hidden', textOverflow: "ellipsis" }}>
                        {eamData.description}
                    </Typography>
                </DescriptionTooltip>
            </TableCell>

            <TableCell align="center" sx={{ p: 1 }}>
                <TableMoreMenu
                    open={openMenu}
                    onOpen={handleOpenMenu}
                    onClose={handleCloseMenu}
                    actions={
                        <>
                            <MenuItem
                                onClick={() => {
                                    edit();
                                    handleCloseMenu();
                                }}
                            >
                                {`${translate('commons.edit')}`}
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    duplicate();
                                    handleCloseMenu();
                                }}
                            >
                                {`${translate('commons.duplicate')}`}
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    remove();
                                    handleCloseMenu();
                                }}
                                sx={{ color: 'error.main' }}
                            >
                                {`${translate('commons.delete')}`}
                            </MenuItem>
                        </>
                    }
                />
            </TableCell>
        </TableRow>
    );
};
