// i18n
import './locales/i18n';

// scroll bar

// lightbox
import 'react-18-image-lightbox/style.css';

// map
import './utils/mapboxgl';
import 'mapbox-gl/dist/mapbox-gl.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
// redux
import { store, persistor } from './redux/store';
// components
import { SettingsProvider } from './components/settings';
import ScrollToTop from './components/scroll-to-top';
// contexts
import { AxiosProvider } from './contexts/AxiosContext';
import { TenantProvider } from './contexts/TenantContext';
import { MuovytiLocalizationProvider } from './contexts/LocalizationContext';

// Check our docs
// https://docs.minimals.cc/authentication/ts-version
import { AuthProvider } from './contexts/Auth0Context';

//
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { UserOrganizationProvider } from './contexts/UserOrganizationContext';
import EnvironmentVisualizer from './EnvironmentVisualizer';

//License manager for MUI-X Pro
import { LicenseInfo } from '@mui/x-license';

// ----------------------------------------------------------------------

//MUI-X Pro license
LicenseInfo.setLicenseKey("95f4877d7f58207e014549d3c4dc3372Tz0xMDI2MzMsRT0xNzY0MjU0MTk0MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=");

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <AuthProvider>
    <UserOrganizationProvider >
      <AxiosProvider>
        <TenantProvider>
          <HelmetProvider>
            <ReduxProvider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <MuovytiLocalizationProvider>
                  <SettingsProvider>
                    <EnvironmentVisualizer />
                    <BrowserRouter>
                      <ScrollToTop />
                      <App />
                    </BrowserRouter>
                  </SettingsProvider>
                </MuovytiLocalizationProvider>
              </PersistGate>
            </ReduxProvider>
          </HelmetProvider>
        </TenantProvider>
      </AxiosProvider>
    </UserOrganizationProvider>
  </AuthProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
