import { Accordion, AccordionDetails, AccordionSummary, Box, Card, Divider, Stack, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { LabeledText } from "src/appComponents/LabeledText";
import LoadingScreen from "src/appComponents/loading-screen";
import useLocales from "src/appHooks/useLocales";
import { RootState, dispatch } from "src/redux/store";
import { vehicleOperations } from "src/redux/vehicle";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type Props = {
    alignText?: string;
};

export const DescriptionTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

export default function VehicleLogisticsInfo({ alignText }: Props) {

    const { translate, currentLang } = useLocales();

    const { vehicle, logistics, isLogisticsLoading } = useSelector((state: RootState) => state.vehicle);

    useEffect(() => {
        dispatch(vehicleOperations.getVehicleLogistics(vehicle.id));
    }, [vehicle, currentLang]);

    return (
        <>
            {isLogisticsLoading ? (
                <LoadingScreen />
            ) : (
                <Box>
                    <Box
                        sx={{
                            p: 3,
                            display: 'grid',
                            rowGap: 2,
                            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
                        }}
                    >
                        <LabeledText align={alignText ? alignText : "right"} label={`${translate("vehicle.logistic.form.appraisalDate")}`} text={logistics?.appraisalDate || '—'} />
                        <LabeledText align={alignText ? alignText : "right"} label={`${translate("vehicle.form.price.damagesWithoutVat")}`} text={(logistics?.damagesWithoutVat)?.toString() || '—'} />
                        <LabeledText align={alignText ? alignText : "right"} label={`${translate("vehicle.form.price.damagesWithVat")}`} text={(logistics?.damagesWithVat)?.toString() || '—'} />
                    </Box>

                    <Card sx={{ mt: 2 }}>
                        <Accordion defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Stack sx={{ pl: 1 }}>
                                    <Typography variant="subtitle1" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
                                        {`${translate("vehicle.logistic.form.ingress")}`}
                                    </Typography>
                                </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                                {(logistics?.ingress && (!(logistics?.ingress.length === 0))) ? (logistics?.ingress.map((ingressData, index) => (
                                    <Box key={`ingress.box.${index}`}>
                                        {index > 0 && (<Divider />)}
                                        <Box
                                            sx={{
                                                p: 3,
                                                display: 'grid',
                                                rowGap: 1,
                                                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
                                            }}
                                        >
                                            <LabeledText align={alignText ? alignText : "right"} label={`${translate("vehicle.logistic.form.location")}`} text={ingressData.location || '—'} />
                                            <LabeledText align={alignText ? alignText : "right"} label={`${translate("vehicle.logistic.form.ingressDate")}`} text={ingressData.ingressDate || '—'} />
                                            <LabeledText align={alignText ? alignText : "right"} label={`${translate("vehicle.logistic.form.leaveDate")}`} text={ingressData.leaveDate || '—'} />
                                        </Box>
                                    </Box>
                                ))) : (
                                    <Box
                                        sx={{
                                            p: 3,
                                            display: 'grid',
                                            rowGap: 1,
                                            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
                                        }}
                                    >
                                        <LabeledText align={alignText ? alignText : "right"} label={`${translate("vehicle.logistic.form.location")}`} text={'—'} />
                                        <LabeledText align={alignText ? alignText : "right"} label={`${translate("vehicle.logistic.form.ingressDate")}`} text={'—'} />
                                        <LabeledText align={alignText ? alignText : "right"} label={`${translate("vehicle.logistic.form.leaveDate")}`} text={'—'} />
                                    </Box>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    </Card>

                    <Card sx={{ mt: 3 }}>
                        <Accordion defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Stack sx={{ pl: 1 }}>
                                    <Typography variant="subtitle1" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
                                        {`${translate("vehicle.logistic.form.transport")}`}
                                    </Typography>
                                </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                                {(logistics?.transport && (!(logistics?.transport.length === 0))) ? (logistics?.transport.map((transportData, index) => (
                                    <Box key={`transport.box.${index}`}>
                                        {index > 0 && (<Divider />)}
                                        <Box
                                            sx={{
                                                p: 3,
                                                display: 'grid',
                                                rowGap: 1,
                                                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                                            }}
                                        >
                                            <LabeledText align={alignText ? alignText : "right"} label={`${translate("vehicle.logistic.form.fromLocation")}`} text={transportData.fromLocation || '—'} />
                                            <LabeledText align={alignText ? alignText : "right"} label={`${translate("vehicle.logistic.form.toLocation")}`} text={transportData.toLocation || '—'} />
                                            <LabeledText align={alignText ? alignText : "right"} label={`${translate("commons.startDate")}`} text={transportData.startDate || '—'} />
                                            <LabeledText align={alignText ? alignText : "right"} label={`${translate("vehicle.logistic.form.arrivalDate")}`} text={transportData.arrivalDate || '—'} />
                                        </Box>
                                    </Box>
                                ))) : (
                                    <Box
                                        sx={{
                                            p: 3,
                                            display: 'grid',
                                            rowGap: 1,
                                            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                                        }}
                                    >
                                        <LabeledText align={alignText ? alignText : "right"} label={`${translate("vehicle.logistic.form.fromLocation")}`} text={'—'} />
                                        <LabeledText align={alignText ? alignText : "right"} label={`${translate("vehicle.logistic.form.toLocation")}`} text={'—'} />
                                        <LabeledText align={alignText ? alignText : "right"} label={`${translate("commons.startDate")}`} text={'—'} />
                                        <LabeledText align={alignText ? alignText : "right"} label={`${translate("vehicle.logistic.form.arrivalDate")}`} text={'—'} />
                                    </Box>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    </Card>

                    <Card sx={{ mt: 3 }}>
                        <Accordion defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Stack sx={{ pl: 1 }}>
                                    <Typography variant="subtitle1" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
                                        {`${translate("vehicle.logistic.form.adminMissingEl")}`}
                                    </Typography>
                                </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                                {(logistics?.eam && (!(logistics?.eam.length === 0))) ? (logistics?.eam.map((eamData, index) => (
                                    <Box key={`eam.box.${index}`}>
                                        {index > 0 && (<Divider />)}
                                        <Box
                                            sx={{
                                                p: 3,
                                                display: 'grid',
                                                rowGap: 1,
                                                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
                                            }}
                                        >
                                            <LabeledText align={alignText ? alignText : "right"} label={`${translate("commons.code")}`} text={eamData.code || '—'} />
                                            <LabeledText align={alignText ? alignText : "right"} label={`${translate("commons.price")}`} text={(eamData.price)?.toString() || '—'} />

                                            <Box sx={{ display: 'grid', gridTemplateColumns: { sm: 'repeat(2, 1fr)' } }}>
                                                <Typography display="inline" sx={{ color: 'text.secondary', fontWeight: '400', whiteSpace: "nowrap" }}>
                                                    {`${translate("commons.description")}`}
                                                </Typography>
                                                <DescriptionTooltip title={eamData.description || '—'} arrow>
                                                    <Typography display="inline" sx={{ color: 'text.primary', fontWeight: '600', overflow: 'hidden', textOverflow: "ellipsis", textAlign: `${alignText ? alignText : "right"}` }}>
                                                        {eamData.description || '—'}
                                                    </Typography>
                                                </DescriptionTooltip>
                                            </Box>

                                        </Box>
                                    </Box>
                                ))) : (
                                    <Box
                                        sx={{
                                            p: 3,
                                            display: 'grid',
                                            rowGap: 1,
                                            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
                                        }}
                                    >
                                        <LabeledText align={alignText ? alignText : "right"} label={`${translate("commons.code")}`} text={'—'} />
                                        <LabeledText align={alignText ? alignText : "right"} label={`${translate("commons.price")}`} text={'—'} />
                                        <LabeledText align={alignText ? alignText : "right"} label={`${translate("commons.description")}`} text={'—'} />
                                    </Box>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    </Card >

                    <Card sx={{ mt: 3 }}>
                        <Accordion defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Stack sx={{ pl: 1 }}>
                                    <Typography variant="subtitle1" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
                                        {`${translate("vehicle.logistic.form.techMissElement")}`}
                                    </Typography>
                                </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                                {(logistics?.etm && (!(logistics?.etm.length === 0))) ? (logistics?.etm.map((etmData, index) => (
                                    <Box key={`etm.box.${index}`}>
                                        {index > 0 && (<Divider />)}
                                        <Box
                                            sx={{
                                                p: 3,
                                                display: 'grid',
                                                rowGap: 1,
                                                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
                                            }}
                                        >
                                            <LabeledText align={alignText ? alignText : "right"} label={`${translate("commons.code")}`} text={etmData.code || '—'} />
                                            <LabeledText align={alignText ? alignText : "right"} label={`${translate("commons.price")}`} text={(etmData.price)?.toString() || '—'} />

                                            <Box sx={{ display: 'grid', gridTemplateColumns: { sm: 'repeat(2, 1fr)' } }}>
                                                <Typography display="inline" sx={{ color: 'text.secondary', fontWeight: '400', whiteSpace: "nowrap" }}>
                                                    {`${translate("commons.description")}`}
                                                </Typography>
                                                <DescriptionTooltip title={etmData.description || '—'} arrow>
                                                    <Typography display="inline" sx={{ color: 'text.primary', fontWeight: '600', overflow: 'hidden', textOverflow: "ellipsis", textAlign: `${alignText ? alignText : "right"}` }}>
                                                        {etmData.description || '—'}
                                                    </Typography>
                                                </DescriptionTooltip>
                                            </Box>

                                        </Box>
                                    </Box>
                                ))) : (
                                    <Box
                                        sx={{
                                            p: 3,
                                            display: 'grid',
                                            rowGap: 1,
                                            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
                                        }}
                                    >
                                        <LabeledText align={alignText ? alignText : "right"} label={`${translate("commons.code")}`} text={'—'} />
                                        <LabeledText align={alignText ? alignText : "right"} label={`${translate("commons.price")}`} text={'—'} />
                                        <LabeledText align={alignText ? alignText : "right"} label={`${translate("commons.description")}`} text={'—'} />
                                    </Box>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    </Card>
                </Box>
            )}
        </>
    );
}
