import { Box, Button, Checkbox, Typography } from "@mui/material";
import { useLocales } from "src/locales";
import LoadingScreen from "src/appComponents/loading-screen/LoadingScreen";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "src/redux/store";
import { DataGrid } from "@mui/x-data-grid";
import { DataGridStyle } from "src/utils/DataGridStyle";
import { useCallback, useEffect, useMemo, useState } from "react";
import { rolesOperations } from "src/redux/roles";
import useResponsive from "src/hooks/useResponsive";
import OrganizationRoleDetails from "./OrganizationRoleDetails";
import { UserRole } from "src/@types/user";

export default function AdminRoleAssignment() {

    const { translate } = useLocales();

    const { watch, setValue } = useFormContext();

    const isDesktop = useResponsive('up', 'md');

    const roles: UserRole[] = watch("roles");

    const { isLoading, adminRoleList } = useSelector((state: RootState) => state.roles);

    const [openRolesDetails, setOpenRolesDetails] = useState<boolean>(false);

    useEffect(() => {
        if (adminRoleList.length < 1) {
            dispatch(rolesOperations.searchAdminRoles({ pageIndex: 0, pageSize: 10 }));
        }
    }, [adminRoleList]);

    const adminRolesList = useMemo(() => {

        if (adminRoleList) {

            return [{
                id: "fake_id",
                roles: roles
            }];
        }

        return [];
    }, [adminRoleList, roles]);

    const flipSwitch = useCallback((idToCheck: string) => {

        let newRolesArr: UserRole[] = [...roles];

        const roleToCheck = adminRoleList.find((role) => role.id === idToCheck);

        if (roleToCheck) {

            const adaptedToUserRole = {
                id: roleToCheck.id,
                name: roleToCheck.name,
                permissions: roleToCheck.permissions
            };

            if (newRolesArr.find((role) => role.id === idToCheck)) {

                const index = newRolesArr.map((item) => item.id).indexOf(adaptedToUserRole.id);

                newRolesArr = [...newRolesArr.slice(0, index), ...newRolesArr.slice(index + 1, newRolesArr.length + 1)];
            }
            else {
                newRolesArr.push(adaptedToUserRole);
            }
        }

        setValue("roles", newRolesArr);

    }, [setValue, adminRoleList, roles]);

    const isChecked = useCallback((idToCheck: string) => {

        return !!roles.find((role) => role.id === idToCheck);

    }, [roles]);

    const ADMIN_ROLES_NAMES = useMemo(() => {
        return adminRoleList.filter((role) => role.enabled).map((role) => role.name);
    }, [adminRoleList]);

    const columns = useMemo(() => {

        const BASE_COLUMN: any[] = [];

        ADMIN_ROLES_NAMES.forEach((role) => {

            BASE_COLUMN.push(
                {
                    field: role,
                    headerName: role,
                    flex: isDesktop ? 1 : undefined,
                    minWidth: !isDesktop ? 130 : undefined,
                    headerAlign: "center",
                    align: "center",
                    sortable: false,
                    renderCell: (obj: any) => {
                        return (
                            <Checkbox
                                checked={isChecked(adminRoleList.find((role) => role.name === obj.field)!.id)}
                                onClick={() => flipSwitch(adminRoleList.find((role) => role.name === obj.field)!.id)}
                            />
                        );
                    }
                }
            );
        });

        return BASE_COLUMN;
    }, [isDesktop, ADMIN_ROLES_NAMES, adminRoleList, flipSwitch, isChecked]);

    return (
        <>
            {isLoading ? <LoadingScreen /> :

                <Box>

                    <OrganizationRoleDetails
                        setOpen={setOpenRolesDetails}
                        open={openRolesDetails}
                        admin
                    />

                    <Box sx={{ display: 'flex', mb: 2.5, flexDirection: isDesktop ? 'row' : 'column' }} >

                        <Typography variant="h6">
                            {`${translate(`role.title_plural`)}`}
                        </Typography>

                        <Button
                            variant="text"
                            size={"small"}
                            onClick={() => setOpenRolesDetails(prev => !prev)}
                            sx={{ ml: "auto", height: "auto", mt: (isDesktop ? 0 : 1.5) }}
                        >
                            {`${translate("role.detail.view")}`}
                        </Button>

                    </Box>

                    <DataGrid
                        rows={adminRolesList}
                        columns={columns}
                        loading={isLoading}
                        disableColumnMenu
                        disableColumnResize
                        disableRowSelectionOnClick
                        getRowHeight={() => 'auto'}
                        //onRowClick={handleRowClick}
                        hideFooter
                        sx={{
                            cursor: 'auto',
                            ...DataGridStyle,
                            '& .MuiDataGrid-cell': { py: 2.5 }
                        }}
                    />

                </Box>
            }
        </>
    );
}