import { OrderAmount } from "./orders";

export type SparePartsFilters = {
    [key: string]: any
    all?: string,
    onlyEnabled?: boolean,
    onlyDisabled?: boolean,
    onlyDeprecated?: boolean,
    sortField?: string,
    sortAscending?: boolean,
    pageIndex: number,
    pageSize: number,
    minStockQuantity?: number,
    maxStockQuantity?: number,
    minReservationQuantity?: number,
    maxReservationQuantity?: number,
    minAvailableQuantity?: number,
    maxAvailableQuantity?: number,
    minReturnQuantity?: number,
    maxReturnQuantity?: number,
    minPendingReception?: number,
    maxPendingReception?: number,
    externalId?: string,
    tags?: string[],
    customFields?: Record<string, string>
}

export type Statistic = {
    [key: string]: number
    all: number,
    blocked: number,
    disable: number,
    enabled: number
}

export type SparePart = {
    id: string,
    createdOn: Date,
    externalId: string,
    ownerId: string,
    price: {
        catalogue: {
            withVat: number,
            withoutVat: number,
            currentCurrency: string
        },
        selling: {
            withVat: number,
            withoutVat: number,
            currentCurrency: string
        },
        supplier: {
            withVat: number,
            withoutVat: number,
            currentCurrency: string
        }
    },
    enabled: boolean,
    deprecated: boolean,
    replacement: {
        replacedBy: string,
        replacementDate: Date
    },
    manufacturer: {
        description: string,
        code: string
    },
    supplier: {
        description: string,
        code: string
    },
    customFields: Record<string, string>,
    tags: string[],
    name: string,
    description: string,
    europeanArticleNumber: number,
    dimensions: {
        packageWeight: number,
        packageHeight: number,
        packageWidth: number,
        packageDepth: number,
        packageVolume: number
    },
    quantity: {
        stockQuantity: number,
        reservationQuantity: number,
        availableQuantity: number,
        returnQuantity: number,
        pendingReception: number
    },
    family: {
        supplier: {
            description: string,
            code: string
        },
        internal: {
            description: string,
            code: string
        }
    },
    category: {
        supplier: {
            description: string,
            code: string
        },
        internal: {
            description: string,
            code: string
        }
    },
    class: {
        supplier: {
            description: string,
            code: string
        },
        internal: {
            description: string,
            code: string
        }
    }
}

export type SparePartSearchResult = {
    id: string,
    createdOn: string,
    externalId: string,
    name: string,
    enabled: boolean,
    deprecated: boolean,
    stockQuantity: number,
    reservationQuantity: number,
    availableQuantity: number,
    returnQuantity: number,
    pendingReception: number,
    sellAmount: OrderAmount,
    family: {
        code: string,
        name: string
    },
    category: {
        code: string,
        name: string
    },
    class: {
        code: string,
        name: string
    },
    tags: string[],
    customFields: Record<string, string>
}

export type ProductStatistic = {
    sparePartsNumbers: number,
    vehiclesNumbers: number
}

export type CategoriesFilter = {
    ids?: string[]
    All?: string,
    sortField?: string,
    sortAscending?: boolean,
    pageIndex: number,
    pageSize: number
};

export type CategoriesSearchResult = {
    code: string,
    description: string,
    numberOfParts: number
}

/* --------------------------------------------------- */

const MIN_SP_STOCK_QTY = 0;

const MAX_SP_STOCK_QTY = 500;

const MIN_SP_RESERVATION_QTY = 0;

const MAX_SP_RESERVATION_QTY = 500;

const MIN_SP_AVAILABLE_QTY = 0;

const MAX_SP_AVAILABLE_QTY = 500;

const MIN_SP_RETURN_QTY = 0;

const MAX_SP_RETURN_QTY = 500;

const MIN_SP_PENDING_QTY = 0;

const MAX_SP_PENDING_QTY = 500;

export const STEP_SP_QUANTITY = 5;

export const DEFAULT_SPAREPARTS_FILTERS: SparePartsFilters = {
    all: "",
    onlyEnabled: false,
    onlyDisabled: false,
    onlyDeprecated: false,
    sortField: "",
    sortAscending: true,
    pageIndex: 0,
    pageSize: 10,
    minStockQuantity: MIN_SP_STOCK_QTY,
    maxStockQuantity: MAX_SP_STOCK_QTY,
    minReservationQuantity: MIN_SP_RESERVATION_QTY,
    maxReservationQuantity: MAX_SP_RESERVATION_QTY,
    minAvailableQuantity: MIN_SP_AVAILABLE_QTY,
    maxAvailableQuantity: MAX_SP_AVAILABLE_QTY,
    minReturnQuantity: MIN_SP_RETURN_QTY,
    maxReturnQuantity: MAX_SP_RETURN_QTY,
    minPendingReception: MIN_SP_PENDING_QTY,
    maxPendingReception: MAX_SP_PENDING_QTY,
    tags: [],
    customFields: {}
};