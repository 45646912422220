import Container from '@mui/material/Container';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import Page from 'src/appComponents/Page';
import useLocales from 'src/appHooks/useLocales';
import { useSettingsContext } from 'src/components/settings';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSelector } from 'react-redux';
import { dispatch, RootState } from 'src/redux/store';
import { FetchDataFuncArgs } from 'src/@types/logs';
import Logs from 'src/appComponents/logs/Logs';
import { organizationsOperations } from 'src/redux/organizations';
import { PATH_DASHBOARD } from 'src/routes/paths';
import useResponsive from 'src/hooks/useResponsive';

const LABELS = [
    "OrganizationCreated",
    "OrganizationUpdated",
    "OrganizationDisabled",
    "OrganizationEnabled",
    "OrganizationCustomerAdded",
    "OrganizationCustomerUpdated",
    "OrganizationCustomerEnabled",
    "OrganizationCustomerDisabled",
    "OrganizationGroupAdded",
    "OrganizationGroupRemoved",
    "OrganizationBlacklisted",
    "OrganizationBlacklistRemoved",
    "OrganizationWhitelisted",
    "OrganizationWhitelistRemoved"
];

/*
    "OrganizationRoleEnabled",
    "OrganizationRoleDisabled",
    "OrganizationRoleCreated",
    "OrganizationRoleUpdated",
    */

interface OrganizationLogsProps {
    isVendor: boolean
}

export default function OrganizationLogs({ isVendor }: OrganizationLogsProps) {

    const { themeStretch } = useSettingsContext();

    const isDesktop = useResponsive('up', 'sm');

    const { translate, currentLang } = useLocales();

    const { logsLoading, logsList, organization } = useSelector((state: RootState) => state.organizations);

    const { id = '', type = "" } = useParams();

    useEffect(() => {
        if (organization?.id !== id) {
            dispatch(organizationsOperations.getGeneric(id));
        }
    }, [id, currentLang, organization]);

    const fetchLogs = (searchFilters: FetchDataFuncArgs) => {
        dispatch(organizationsOperations.getOrganizationLogs({ id: id, filters: searchFilters }));
    };

    //-----LABELS

    const createLabels = useCallback(() => {
        return LABELS.map((label) => ({
            label: `${translate(`organization.logs.eventTypes.${label.charAt(0).toLowerCase() + label.slice(1)}`)}`,
            key: label,
        }));
    }, [translate]);

    const typesLabels = useMemo(
        () => [
            {
                label: `${translate(`commons.all`)}`,
                key: 'all',
            },
            ...createLabels()
        ],
        [createLabels, translate]
    );

    const backPath = isVendor ? PATH_DASHBOARD.organizations.vendor : PATH_DASHBOARD.organizations.customer;

    return (
        <Page title={`${translate('organization.logs.title')}`}>
            <Container maxWidth={themeStretch ? false : 'lg'} sx={{ p: 0 }}>

                <HeaderBreadcrumbs
                    heading={`${translate('organization.logs.title')}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate("organization.title")}`, href: backPath.list },
                        { name: `${organization?.name}`, href: backPath.detail(id, type) },
                        { name: `${translate('commons.logs')}` }
                    ]}
                    sx={{ px: isDesktop ? 5 : 2, mb: isDesktop ? 5 : 2 }}
                />

                <Logs
                    tableData={logsList as any}
                    isLoading={logsLoading}
                    id={id!}
                    header={`${translate(`commons.logs`)} `}
                    subheader={`${translate(`organization.logs.subtitle`)}`}
                    labels={typesLabels}
                    fetchData={fetchLogs}
                    localesPath={"organization"}
                />
            </Container>
        </Page>
    );
}
