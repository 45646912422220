import { Box, Button, Card, Modal, TextField, Typography } from "@mui/material";
import { isNaN } from "lodash";
import { useState } from "react";
import { Period } from "src/@types/rules";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";
import { rulesOperations } from "src/redux/rules";
import { dispatch } from "src/redux/store";

interface RulesLabelExpirationModalProps {
    isOpen: boolean,
    setIsOpen: (val: boolean) => void,
    period: Period
}

export default function RulesLabelExpirationModal({ isOpen, setIsOpen, period }: RulesLabelExpirationModalProps) {

    const isDesktop = useResponsive("up", "md");

    const { translate } = useLocales();

    const [labelExpirationInDays, setLabelExpirationInDays] = useState(period.labelExpirationInDays);

    const handleChange = (val: string) => {
        if (!isNaN(+val))
            setLabelExpirationInDays(+val);
    };

    const submit = async () => {

        await dispatch(rulesOperations.updateRulePeriod({ ...period, labelExpirationInDays })).unwrap();

        setIsOpen(false);
    };

    return (
        <Modal open={isOpen} >
            <Card sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                p: isDesktop ? 5 : 2,
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
                    <Typography variant="h6" textAlign={'center'}>
                        {`${translate("commons.edit")} - ${translate('rules.form.edit.labelExpirationPeriod')} `}
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Typography>
                        {`${translate('commons.days')}`}
                    </Typography>

                    <TextField
                        fullWidth
                        value={labelExpirationInDays + ""}
                        sx={{ my: 2 }}
                        variant="outlined"
                        onChange={(e) => handleChange(e.target.value)}
                    />

                </Box>

                <Box
                    sx={{
                        mt: 2,
                        justifyContent: 'space-between',
                        ml: isDesktop ? 'auto' : 0,
                        display: 'flex',
                        gap: 1,
                        flexDirection: isDesktop ? 'default' : 'column-reverse'
                    }}
                >

                    <Button
                        variant="outlined"
                        color="inherit"
                        sx={{ borderRadius: '100px' }}
                        onClick={() => {
                            setIsOpen(false);
                            setLabelExpirationInDays(period.labelExpirationInDays);
                        }}
                    >
                        {`${translate(`commons.cancel`)}`}
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ borderRadius: '100px' }}
                        onClick={() => submit()}
                    >
                        {`${translate('commons.justSave')}`}
                    </Button>

                </Box>
            </Card>
        </Modal>
    );
}