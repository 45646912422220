import { DEFAULT_LOGISTICS_DATA, LogisticsData } from "src/@types/logistics";
import { dateRegExp } from "src/constants";
import * as Yup from 'yup';

export const ingressDataSchema = Yup.object().shape({
    location: Yup.string().nullable().trim().required('fieldRequired'),
    ingressDate: Yup.string().nullable().matches(dateRegExp, 'wrongDate').required('fieldRequired'),
    leaveDate: Yup.string().nullable().matches(dateRegExp, 'wrongDate').required('fieldRequired')
});

export const transportDataSchema = Yup.object().shape({
    fromLocation: Yup.string().nullable().trim().required('fieldRequired'),
    toLocation: Yup.string().nullable().trim().required('fieldRequired'),
    startDate: Yup.string().nullable().matches(dateRegExp, 'wrongDate').required('fieldRequired'),
    arrivalDate: Yup.string().nullable().matches(dateRegExp, 'wrongDate').required('fieldRequired')
});

export const EAMDataSchema = Yup.object().shape({
    code: Yup.string().nullable().trim().required('fieldRequired'),
    description: Yup.string().nullable().trim().required('fieldRequired'),
    price: Yup.number().nullable().required('fieldRequired')
});

export const ETMDataSchema = Yup.object().shape({
    code: Yup.string().nullable().trim().required('fieldRequired'),
    description: Yup.string().nullable().trim().required('fieldRequired'),
    price: Yup.number().nullable().required('fieldRequired')
});

export const logisticStep1Schema = Yup.object().shape({
    appraisalDate: Yup.string().nullable().matches(dateRegExp, 'wrongDate').required('requiredField'),
    damagesWithoutVat: Yup.number().required('requiredField'),
    damagesWithVat: Yup.number().required('requiredField')
});

/* ----------------------------------------------- */

export function defaultLogisticsData(logistic: LogisticsData): LogisticsData {
    return {
        appraisalDate: logistic?.appraisalDate || DEFAULT_LOGISTICS_DATA.appraisalDate,
        damagesWithVat: logistic?.damagesWithVat || DEFAULT_LOGISTICS_DATA.damagesWithVat,
        damagesWithoutVat: logistic?.damagesWithoutVat || DEFAULT_LOGISTICS_DATA.damagesWithoutVat,
        ingress: logistic?.ingress.map((ingressData) => ({
            location: ingressData.location || DEFAULT_LOGISTICS_DATA.ingress[0].location,
            ingressDate: ingressData.ingressDate || DEFAULT_LOGISTICS_DATA.ingress[0].ingressDate,
            leaveDate: ingressData.leaveDate || DEFAULT_LOGISTICS_DATA.ingress[0].leaveDate
        })),
        transport: logistic?.transport.map((transportData) => ({
            fromLocation: transportData.fromLocation || DEFAULT_LOGISTICS_DATA.transport[0].fromLocation,
            toLocation: transportData.toLocation || DEFAULT_LOGISTICS_DATA.transport[0].toLocation,
            startDate: transportData.startDate || DEFAULT_LOGISTICS_DATA.transport[0].startDate,
            arrivalDate: transportData.arrivalDate || DEFAULT_LOGISTICS_DATA.transport[0].arrivalDate
        })),
        eam: logistic?.eam.map((eamData) => ({
            code: eamData.code || DEFAULT_LOGISTICS_DATA.eam[0].code,
            description: eamData.description || DEFAULT_LOGISTICS_DATA.eam[0].description,
            price: eamData.price || DEFAULT_LOGISTICS_DATA.eam[0].price
        })),
        etm: logistic?.eam.map((etmData) => ({
            code: etmData.code || DEFAULT_LOGISTICS_DATA.etm[0].code,
            description: etmData.description || DEFAULT_LOGISTICS_DATA.etm[0].description,
            price: etmData.price || DEFAULT_LOGISTICS_DATA.etm[0].price
        })),
    };
}
