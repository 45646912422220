import Container from '@mui/material/Container';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import Page from 'src/appComponents/Page';
import useLocales from 'src/appHooks/useLocales';
import { useSettingsContext } from 'src/components/settings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { dispatch, RootState } from 'src/redux/store';
import { FetchDataFuncArgs } from 'src/@types/logs';
import Logs from 'src/appComponents/logs/Logs';
import { sparePartsOperations } from 'src/redux/spareParts';

const LABELS = [
    'SparePartCreated',
    'SparePartUpdated',
    'SparePartDisabled',
    'SparePartEnabled'
];

export default function SparePartsLogs() {

    const { themeStretch } = useSettingsContext();

    const isDesktop = useResponsive('up', 'sm');

    const { translate, currentLang } = useLocales();

    const { logsLoading, sparePartLogs, sparePart } = useSelector((state: RootState) => state.spareParts);

    const { id = '' } = useParams();

    useEffect(() => {
        if (sparePart?.id !== id) {
            dispatch(sparePartsOperations.getSparePart(id));
        }
    }, [id, currentLang, sparePart]);

    const fetchLogs = (searchFilters: FetchDataFuncArgs) => {
        dispatch(sparePartsOperations.getSparePartLogs({ id: id, filters: searchFilters }));
    };

    //-----LABELS

    const createLabels = useCallback(() => {
        return LABELS.map((label) => ({
            label: `${translate(`spareParts.logs.eventTypes.${label.charAt(0).toLowerCase() + label.slice(1)}`)}`,
            key: label,
        }));
    }, [translate]);

    const typesLabels = useMemo(
        () => [
            {
                label: `${translate(`commons.all`)}`,
                key: 'all',
            },
            ...createLabels(),
        ],
        [createLabels, translate]
    );

    return (
        <Page title={`${translate('spareParts.logs.title')}`}>
            <Container maxWidth={themeStretch ? false : 'lg'} sx={{ p: 0 }}>
                <HeaderBreadcrumbs
                    heading={`${translate('spareParts.logs.title')}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate('spareParts.title_plural')}`, href: PATH_DASHBOARD.spareParts.list },
                        { name: `${sparePart?.name}`, href: PATH_DASHBOARD.spareParts.detail(id) },
                        { name: `${translate('commons.logs')}` }
                    ]}
                    sx={{ px: isDesktop ? 5 : 2, mb: isDesktop ? 5 : 2 }}
                />

                <Logs
                    tableData={sparePartLogs as any}
                    isLoading={logsLoading}
                    id={id!}
                    header={`${translate(`commons.logs`)} `}
                    subheader={`${translate(`spareParts.logs.subtitle`)}`}
                    labels={typesLabels}
                    fetchData={fetchLogs}
                    localesPath={"spareParts"}
                />
            </Container>
        </Page>
    );
}
