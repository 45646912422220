import { Box, Typography } from "@mui/material";
import { UseFormReturn } from 'react-hook-form';
import useLocales from 'src/appHooks/useLocales';
import { RHFTextField } from 'src/components/hook-form';
import RHFNumericFormat from 'src/components/hook-form/RHFNumericFormat';
import { useSettingsContext } from 'src/components/settings';
import { GenericOrganization, GenericOrganizationEditWithFather } from "src/@types/organizations";
import { CustomField } from "src/@types/customField";
import CustomFieldFormRenderer from "src/utils/CustomFieldFormRenderer";

type Props = {
    outerRef: any;
    formMethods: UseFormReturn<GenericOrganizationEditWithFather>;
    setOnEditForm: (value: boolean) => void;
    onEditForm: boolean;
    isDisabled: (name: string, other?: boolean, and?: boolean) => boolean;
    customFieldsProp: CustomField[];
    handleCustomFields: (event: any) => void;
    setErrorInCustom: (value: boolean) => void;
    setFieldsWithError: (value: CustomField[]) => void;
};

export default function OrganizationStep4({
    outerRef,
    formMethods,
    setOnEditForm,
    onEditForm,
    isDisabled,
    customFieldsProp,
    handleCustomFields,
    setErrorInCustom,
    setFieldsWithError
}: Props) {
    const { getValues, trigger, watch } = formMethods;

    const { translate } = useLocales();

    const { currency } = useSettingsContext();

    const validateFields = async (yupSchema: any) => {
        const formValues = getValues();

        try {
            await yupSchema.validate(formValues, { abortEarly: false });

            return true;
        } catch (err: any) {
            return false;
        }
    };

    if (!outerRef?.current) {
        outerRef.current = {
            validateFields,
            onSave: () => {
                const formValues = getValues();

                return { ...formValues };
            }
        };
    };

    const onChangeValidate = async (field: any) => {
        await trigger(field, { shouldFocus: true });
    };

    const customFieldsValues = watch("customFields");

    return (
        <Box sx={{ height: 500 }}>
            <Typography variant="h5">{`${translate('organization.form.steps.payment')}`}</Typography>

            <Typography variant="body2" sx={{ mt: 1 }}>
                {`${translate('organization.form.description.payment')}`}
            </Typography>

            <Box>

                <Box
                    sx={{ mt: 5, mb: 3 }}
                >
                    <RHFTextField
                        name="payment.iban"
                        label={`${translate('organization.form.payment.iban')}`}
                        disabled={isDisabled("payment.iban")}
                    />
                </Box>

                <Box
                    sx={{
                        display: 'grid',
                        columnGap: 2,
                        rowGap: 3,
                        mb: 3,
                        gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
                    }}
                >

                    <RHFTextField
                        name="payment.mode"
                        label={`${translate('organization.form.payment.mode')}`}
                        disabled={isDisabled("payment.mode")}
                    />

                    <RHFNumericFormat
                        name="payment.creditLimit"
                        label={`${translate('organization.form.payment.creditLimit')}`}
                        prefix={` ${currency.symbol} `}
                        allowNegative={false}
                        disabled={isDisabled("payment.creditLimit")}
                    />

                    <RHFNumericFormat
                        name="payment.balance"
                        label={`${translate('organization.form.payment.balance')}`}
                        prefix={` ${currency.symbol} `}
                        disabled={isDisabled("payment.balance")}
                    />

                </Box>

                {/*                 {customFieldsProp && (
                    <CustomFieldFormRenderer
                        context={"organization"}
                        handleCustomFields={handleCustomFields}
                        values={customFieldsValues}
                        columnGap={2}
                        repeat={'repeat(3, 1fr)'}
                        isDisabled={isDisabled}
                        section={["Fiscal", "Fiscal and payment"]}
                        onChangeValidate={onChangeValidate}
                        setOnEditForm={setOnEditForm}
                        onEditForm={onEditForm}
                        setErrorInCustom={setErrorInCustom}
                        setFieldsWithError={setFieldsWithError}
                    />
                )} */}

            </Box>
        </Box>
    );
}
