import { CancelRounded, AddAPhoto } from "@mui/icons-material";
import { Typography, TextField, Switch, IconButton, alpha } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useState, useRef, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useLocales } from "src/locales";
import { DamagedQuality } from "../detail/IncompleteCustomerForm";
import { Media } from "src/@types/request";
import { ReactComponent as MissingImage } from "src/components/mediaSlider/ic_missingImage.svg";

interface QualityAmendProps {
    onChange: (obj: DamagedQuality) => void,
    damagedQuality: DamagedQuality
    medias: Media[],
    setDeleteList: (list: string[]) => void
}

const style = {
    borderRadius: "15px",
    maxHeight: '150px', maxWidth: '150px',
    objectFit: "cover",
    border: "1px ridge",
    borderColor: (theme: any) => theme.palette.text.main,
    display: "flex",
    justifyContent: "center"
};

export default function QualityAmend({ onChange, damagedQuality, medias, setDeleteList }: QualityAmendProps) {

    const { translate } = useLocales();

    const [state, setState] = useState<DamagedQuality>(() => ({
        ...damagedQuality,
        boolCheck: medias.length > 0 ? true : damagedQuality.boolCheck
    }));

    const [deleteList, setList] = useState<string[]>([]);

    const [noteError, setNoteError] = useState("");

    const ref = useRef<HTMLDivElement>(null);

    const handleList = (mediaId: string) => {
        setList(prev => {
            let list = prev.concat([mediaId]);

            setDeleteList(list);

            return list;
        });
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            setState((prev) => {
                let obj = { ...prev, medias: prev.medias.concat(acceptedFiles) };

                onChange(obj);

                return obj;
            });
        },
        accept: {
            'image/png': ['.png'],
            'image/jpeg': [".jpg", ".jpeg"]
        },
        maxSize: 5000000
    });

    useEffect(() => {
        ref.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center'
        });

    }, [state.medias]);

    useEffect(() => {
        if (!state.boolCheck)
            setState(prev => ({ ...prev, medias: [] }));
    }, [state.boolCheck]);

    const handleNotes = (val: string) => {
        setState(prev => {
            let obj = { ...prev, notes: val };

            onChange(obj);

            return obj;
        });

        if (!val) {
            setNoteError(`${translate('commons.validation.requiredField')}`);
        }
        else setNoteError("");
    };

    const handleCheck = (val: boolean) => {
        setState(prev => {
            let obj = { ...prev, boolCheck: val };

            onChange(obj);

            return obj;
        });
    };

    return (

        <Box>
            <Box>
                <Typography variant="h6">{`${translate('request.form.whyNotWork')}`} <span style={{ color: 'red' }}>*</span></Typography>
                <TextField
                    variant="outlined"
                    name="note"
                    rows={4}
                    value={state.notes}
                    error={!!noteError}
                    helperText={noteError}
                    onChange={(e) => handleNotes(e.target.value)}
                    multiline
                    fullWidth
                    type="text"
                    required
                    placeholder={`${translate('commons.insertDesc')}`}
                />
            </Box>
            <Box
                sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row', width: '100%' }}
            >
                <Stack sx={{ m: 2, mr: 'auto' }}>
                    <Typography variant="h6">{`${translate('request.form.issueVisible')}`}</Typography>
                    <Typography variant="body2">{`${translate('request.form.addPic')}`}</Typography>
                </Stack>
                <Box sx={{ m: 2, ml: 'auto' }}>
                    <Switch
                        checked={state.boolCheck}
                        name="damage"
                        onChange={(e) => handleCheck(e.target.checked)}

                    />
                </Box>
            </Box>
            {state.boolCheck && (
                <Box
                    sx={{
                        alignItems: 'start',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <Box sx={{ m: 2, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 3, overflowX: 'auto', width: '100%' }}>
                        {medias.filter(v => !deleteList.includes(v.mediaId!)).map((f) => (
                            <Box key={f.mediaId} sx={{ position: 'relative', borderRadius: '10px' }}>
                                {f.fileInfo.length > 0 ? <img
                                    style={{ maxHeight: '150px', maxWidth: '150px', borderRadius: '10px', border: '1px ridge', borderColor: '#d3d4d4' }}
                                    alt={f.mediaId}
                                    src={f.fileInfo[0].url}
                                />
                                    :
                                    <Box sx={{ backgroundColor: (theme) => theme.palette.mode === "light" ? '#F9FAFB' : alpha('#F9FAFB', 0.8), ...style }}>
                                        <MissingImage />
                                    </Box>
                                }
                                <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
                                    <IconButton
                                        aria-label="delete"
                                        size="large"
                                        sx={{
                                            padding: '5px',
                                            cursor: "pointer"
                                        }}
                                        onClick={() => handleList(f.mediaId!)}
                                    >
                                        <CancelRounded sx={{ color: 'text.disabled' }} />
                                    </IconButton>
                                </Box>
                            </Box>
                        ))}
                        {state.medias.map((f, ind) => {

                            const url = URL.createObjectURL(f);

                            return (
                                <Box key={ind} sx={{ position: 'relative', borderRadius: '10px' }}>
                                    <img
                                        style={{ maxHeight: '150px', maxWidth: '150px', borderRadius: '10px', border: '1px ridge', borderColor: '#d3d4d4' }}
                                        alt={f.name}
                                        src={url}
                                        onLoad={() => URL.revokeObjectURL(url)}
                                        onError={() => URL.revokeObjectURL(url)}
                                    />
                                    <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
                                        <IconButton
                                            aria-label="delete"
                                            size="large"
                                            sx={{
                                                padding: '5px',
                                                cursor: "pointer"
                                            }}
                                            onClick={() => {
                                                setState(prev => {
                                                    let obj = { ...prev, medias: prev.medias.filter((_, index) => ind !== index) };

                                                    onChange(obj);

                                                    return obj;
                                                });
                                            }}
                                        >
                                            <CancelRounded sx={{ color: 'text.disabled' }} />
                                        </IconButton>
                                    </Box>
                                </Box>
                            );
                        })}
                        <div
                            {...getRootProps({
                                style: {
                                    display: 'flex',
                                    padding: '20px',
                                    height: '150px',
                                    width: '150px',
                                    borderRadius: '8px',
                                    background: '#F4F6F8',
                                    color: '#bdbdbd',
                                    minWidth: '150px',
                                    minHeight: '150px',
                                    outline: 'none',
                                    cursor: 'pointer',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                },
                            })}
                        >
                            <input {...getInputProps()} />
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <AddAPhoto />
                                <Typography ref={ref} variant="caption">{`${translate(
                                    'commons.uploadPhoto'
                                )}`}</Typography>
                            </Box>
                        </div>
                    </Box>
                </Box>
            )}
        </Box>
    );
}