import { Card, Tabs, Tab, Box, Container, Button, Typography } from "@mui/material";
import Page from "src/appComponents/Page";
import { PATH_DASHBOARD } from "src/routes/paths";
import HeaderBreadcrumbs from '../../../components/custom-breadcrumbs';
import { useSettingsContext } from "src/components/settings";
import { useLocales } from "src/locales";
import { useNavigate, useParams } from "react-router";
import { useEffect, useMemo, useState } from "react";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import BrandGeneralDetail from "src/sections/@dashboard/brand/BrandGeneralDetail";
import LoadingScreen from "src/appComponents/loading-screen";
import { CustomAvatar } from "src/components/custom-avatar";
import useResponsive from "src/hooks/useResponsive";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "src/redux/store";
import { brandOperations } from "src/redux/brand";
import GodGuard from "src/guards/GodGuard";
import { StyledInfo, StyledRoot } from "src/utils/Detail";

export default function BrandDetail() {
    const { themeStretch } = useSettingsContext();

    const { translate, currentLang } = useLocales();

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'lg');

    const { brandId, tab } = useParams();

    const [currentTab, setCurrentTab] = useState(tab || 'general');

    const { brand } = useSelector((state: RootState) => state.brand);

    const [isLoading, setIsLoading] = useState(true);

    const onTabChange = (newValue: string) => {
        navigate(PATH_DASHBOARD.brands.detailsTab(brandId || "", newValue));
        setCurrentTab(newValue);
    };

    const getBrand = async () => {
        try {
            await dispatch(brandOperations.getBrand(brandId!)).unwrap();
        } catch (e) {
            console.error(e);
        }
    };

    const TABS = useMemo(() => [{
        value: 'general',
        label: `${translate('commons.general')}`,
        icon: <AccountBoxIcon />,
        component: brand ? <BrandGeneralDetail brand={brand!} /> : null,
    }], [translate, brand]);

    useEffect(() => {
        if (brandId) {
            getBrand();
            setIsLoading(false);
        }
    }, [brandId, currentLang]);

    const handleEdit = () => {
        navigate(PATH_DASHBOARD.brands.edit(brandId!));
    };

    return (
        <Page title={brand?.name || ''}>
            {isLoading && !brand ? (
                <LoadingScreen />) : (
                <Container maxWidth={themeStretch ? false : 'lg'}>
                    <HeaderBreadcrumbs
                        heading={`${translate('brands.detail')}`}
                        links={[
                            { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                            { name: `${translate("organization.form.brands.title")}`, previousPage: 1 },
                            { name: brand?.name },
                        ]}
                    />
                    <Card
                        sx={{
                            mb: 3,
                            height: 280,
                            position: 'relative',
                        }}
                    >
                        <StyledRoot>
                            <StyledInfo>
                                <CustomAvatar
                                    name={brand?.name}
                                    sx={{
                                        mx: 'auto',
                                        borderWidth: 2,
                                        borderStyle: 'solid',
                                        borderColor: 'common.white',
                                        width: { xs: 80, md: 128 },
                                        height: { xs: 80, md: 128 },
                                    }}
                                />

                                <Box
                                    sx={{
                                        ml: { md: 3 },
                                        mt: { xs: 1, md: 0 },
                                        color: 'common.white',
                                        textAlign: { xs: 'center', md: 'left' },
                                    }}
                                >
                                    <Typography variant="h4">{`${brand?.name}`}</Typography>

                                    <GodGuard>
                                        <Button variant="contained" size="small" sx={{ my: isDesktop ? 2 : 1 }} onClick={handleEdit}>
                                            {`${translate("commons.edit")}`}
                                        </Button>
                                    </GodGuard>
                                </Box>
                            </StyledInfo>
                        </StyledRoot>
                        <Tabs
                            value={currentTab}
                            onChange={(event, newValue) => onTabChange(newValue)}
                            sx={{
                                width: 1,
                                bottom: 0,
                                zIndex: 9,
                                position: 'absolute',
                                bgcolor: 'background.paper',
                                '& .MuiTabs-flexContainer': {
                                    pr: { md: 3 },
                                    justifyContent: {
                                        sm: 'center',
                                        md: 'flex-end',
                                    },
                                },
                            }}
                        >
                            {TABS.map((tab) => (
                                <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
                            ))}
                        </Tabs>
                    </Card>
                    {TABS.map(
                        (tab) => tab.value === currentTab && <Box key={tab.value}> {tab.component} </Box>
                    )}
                </Container>)}
        </Page>
    );
}