import { useFormContext, Controller, FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { TextField, TextFieldProps, useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useLocales } from 'src/locales';

type Props = TextFieldProps & {
  name: string;
  onChangeVal?: (value: any) => void;
  setOnEditForm?: (value: boolean) => void;
  onEditForm?: boolean;
};

export default function RHFTextField({ name, onChangeVal, setOnEditForm, onEditForm, ...other }: Props) {

  const { control, formState } = useFormContext();

  const { errors } = formState;

  const { translate } = useLocales();

  const theme = useTheme();

  const onBlurFunc = () => {
    if (onChangeVal) {
      onChangeVal(name);
      if (setOnEditForm) {
        setOnEditForm(false);
      }
    }
  };

  const onFocusFunc = () => {
    if (setOnEditForm) {
      setOnEditForm(true);
    }
  };

  const getError = (error: FieldError | undefined) => {
    if (errors.customFields) {

      const errorNames = Object.keys(errors.customFields as Merge<FieldError, FieldErrorsImpl<any>>);

      return errorNames.includes(name);
    }

    return !!error;
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          {...other}
          fullWidth
          value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
          error={other.error || getError(error)}
          helperText={(other.error || getError(error)) && `${translate(other.helperText ? other.helperText : error?.message)}`}
          sx={{ ...other.sx, '& .MuiInputLabel-asterisk': { color: 'error.main' }, '& .MuiOutlinedInput-root': { '& fieldset': { background: other.disabled ? alpha(theme.palette.grey[300], 0.20) : "", } } }}

          onFocus={onFocusFunc}
          onBlur={onBlurFunc}
        />
      )
      }
    />
  );
}
