import React, { useState, useRef } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Box, Button, Card, Grid, Modal } from '@mui/material';
import { DEFAULT_IMAGE_IN_MODAL, FileWithPreview, ImageInModalType } from 'src/@types/media';
import InputSlider from 'src/components/slider/InputSlider';
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import { useDebounceEffect } from './useDebounceEffect';
import { canvasPreview } from './canvasPreview';
import { useLocales } from 'src/locales';

// which is a bit trickier so we use some helper functions.
export function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
    return centerCrop(makeAspectCrop({ unit: 'px', width: mediaWidth * 0.9 }, aspect, mediaWidth, mediaHeight), mediaWidth, mediaHeight);
}

interface ImageUploaderEditorProps {
    previewFiles: FileWithPreview[],
    setPreviewFiles: (value: FileWithPreview[]) => void,
    originalFiles: (ImageInModalType & File)[],
    setOriginalFiles: (value: (ImageInModalType & File)[]) => void,
    imageInModal: ImageInModalType
    setImageInModal: (value: ImageInModalType | ((prev: any) => any)) => void,
    imgIndex: number,
    circularCrop?: boolean
}

export default function ImageEditor({ previewFiles, setPreviewFiles, originalFiles, setOriginalFiles, imgIndex, imageInModal, setImageInModal, circularCrop }: ImageUploaderEditorProps) {

    //const isDesktop = useResponsive('up', 'lg');

    const { translate } = useLocales();

    const previewCanvasRef = useRef<HTMLCanvasElement>(null);

    const imgRef = useRef<HTMLImageElement>(null);

    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

    const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
        const { width, height } = e.currentTarget;

        setImageInModal((prev) => ({
            ...prev,
            crop: prev.crop ?? centerAspectCrop(width, height, prev.aspect)
        }));
    };

    async function updatePreviewAfterCrop() {

        const image = imgRef.current;

        const previewCanvas = previewCanvasRef.current;

        if (image && previewCanvas && completedCrop) {
            // SAVING CROPPED IMAGE WITH CHANGES VALUES

            const scaleX = image.naturalWidth / image.width;

            const scaleY = image.naturalHeight / image.height;

            const offscreen = new OffscreenCanvas(completedCrop.width * scaleX, completedCrop.height * scaleY);

            const ctx = offscreen.getContext('2d');

            if (!ctx) throw new Error('No 2d context');

            ctx.drawImage(previewCanvas, 0, 0, previewCanvas.width, previewCanvas.height, 0, 0, offscreen.width, offscreen.height);

            const blob = await offscreen.convertToBlob({ type: 'image/png', quality: 1 });

            const file = new File([blob], previewFiles[imgIndex].name, { type: previewFiles[imgIndex].type });

            const newFiles = [...previewFiles];

            Object.assign(file, { preview: URL.createObjectURL(file) });

            newFiles[imgIndex] = file as (File & { preview: string });

            setPreviewFiles(newFiles);

            // SAVING ORIGINAL IMAGE WITH CHANGES VALUES

            const canvas = new OffscreenCanvas(image.naturalWidth, image.naturalHeight);

            const originalContext = canvas.getContext('2d');

            if (!originalContext) throw new Error('No 2d context');

            originalContext.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, canvas.width, canvas.height);
            //                 ↓
            originalContext.rotate(imageInModal.rotation ?? 0);

            const originalBlob = await canvas.convertToBlob({ type: 'image/png', quality: 1 });

            const originalFile = new File([originalBlob], originalFiles[imgIndex].name, { type: originalFiles[imgIndex].type });

            const newOriginalFiles = [...originalFiles];

            Object.assign(originalFile, {
                preview: URL.createObjectURL(originalFile),
                crop: imageInModal.crop,
                rotation: imageInModal.rotation,
                scale: imageInModal.scale,
                aspect: imageInModal.aspect,
                originalWidth: originalFiles[imgIndex].originalWidth,
                originalHeight: originalFiles[imgIndex].originalHeight
            });

            newOriginalFiles[imgIndex] = originalFile as (ImageInModalType & File);

            setOriginalFiles(newOriginalFiles);
        }

        onCloseModal();
    }

    useDebounceEffect(
        async () => {
            if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    imageInModal.scale ?? DEFAULT_IMAGE_IN_MODAL.scale,
                    imageInModal.rotation ?? DEFAULT_IMAGE_IN_MODAL.rotation
                );
            }
        }, 100, [completedCrop, imageInModal.scale, imageInModal.rotation]
    );

    const onCloseModal = () => {
        setImageInModal(DEFAULT_IMAGE_IN_MODAL);
        setCompletedCrop(undefined);
    };

    const scaleMarks = [...Array(5)].map((_, index) => ({ value: index + 1, label: index + 1 + '' }));

    const rotationMarks = [...Array(5)].map((_, index) => ({ value: index * 45, label: (index * 45) + '' }));

    return (
        <>
            {!!completedCrop && (
                <div>
                    <canvas
                        ref={previewCanvasRef}
                        style={{ display: 'none' }}
                    />
                </div>
            )}

            <Modal open={!!imageInModal.preview} onClose={onCloseModal}>
                <Card
                    sx={{
                        position: 'absolute',
                        top: '50%', left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '50vw', height: '80vh',
                        p: 3,
                        overflowY: 'auto'
                    }}
                >
                    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>

                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <ReactCrop
                                crop={imageInModal.crop}
                                onChange={(pixelCrop) => setImageInModal((prev) => ({ ...prev, crop: pixelCrop }))}
                                onComplete={(c) => setCompletedCrop(c)}
                                aspect={circularCrop ? 1 : (16 / 9)}
                                minWidth={circularCrop ? 200 : 320}
                                minHeight={circularCrop ? 200 : 180}
                                circularCrop={circularCrop}
                                keepSelection
                                ruleOfThirds
                            >
                                <img
                                    ref={imgRef}
                                    alt="Crop me"
                                    src={imageInModal.preview}
                                    style={{
                                        transform: `scale(${imageInModal.scale}) rotate(${imageInModal.rotation}deg)`,
                                        maxWidth: '46.5vw', maxHeight: '52vh'
                                    }}
                                    onLoad={onImageLoad}
                                />
                            </ReactCrop>
                        </Box>

                        <Grid container sx={{ px: 1, mt: 1 }}>

                            <Grid item xs={12}>
                                <InputSlider
                                    label={'Scale'}
                                    value={imageInModal.scale}
                                    setValue={(value) => setImageInModal((prev) => ({ ...prev, scale: Number(value) }))}
                                    minValue={DEFAULT_IMAGE_IN_MODAL.scale!}
                                    maxValue={5}
                                    step={0.1}
                                    marks={scaleMarks}
                                    icon={<AspectRatioIcon />}
                                    sx={{ px: 1.5 }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <InputSlider
                                    label={'Rotate'}
                                    value={imageInModal.rotation}
                                    setValue={(value) => setImageInModal((prev) => ({ ...prev, rotation: Math.min(180, Math.max(-180, Number(value))) }))}
                                    minValue={DEFAULT_IMAGE_IN_MODAL.rotation!}
                                    maxValue={180}
                                    step={1}
                                    marks={rotationMarks}
                                    icon={<Rotate90DegreesCwIcon />}
                                    sx={{ px: 1.5 }}
                                />
                            </Grid>

                        </Grid>

                        <Box sx={{ display: "flex", justifyContent: "space-between", mx: 2, mt: "auto", mb: 1 }}>
                            <Button
                                variant="soft"
                                size={"medium"}
                                onClick={onCloseModal}
                                sx={{ mt: 3, mr: { xs: 0, sm: 2 }, borderRadius: "100px" }}
                            >
                                {`${translate("commons.cancel")}`}
                            </Button>
                            <Button
                                variant="contained"
                                size={"medium"}
                                onClick={updatePreviewAfterCrop}
                                sx={{ mt: 3, ml: 2, borderRadius: "100px" }}
                            >
                                {`${translate("commons.justSave")}`}
                            </Button>
                        </Box>

                    </Box>
                </Card>
            </Modal>
        </>
    );
}
