import { Card, Divider, Box, Button } from "@mui/material";
import { useLocales } from "src/locales";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import useResponsive from "src/hooks/useResponsive";
import { RootState } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";
import WarrantyAmend from "../amend/WarrantyAmend";
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { Media, Warranty } from "src/@types/request";
import DamagedAmend from "../amend/DamagedAmend";
import QualityAmend from "../amend/QualityAmend";
import { remove } from "lodash";

export type DamagedQuality = {
    notes: string
    boolCheck: boolean,
    medias: File[]
}

interface IncompleteCustomerFormProp {
    setOpenConfirm: (value: boolean) => void,
    warranty: Warranty,
    setWarranty: Dispatch<SetStateAction<Warranty>>,
    damagedQuality: DamagedQuality,
    setDamagedQuality: (value: DamagedQuality) => void,
    files: Record<string, (File & { section?: string }) | null>,
    setFiles: Dispatch<SetStateAction<Record<string, (File & { section?: string }) | null>>>,
    isAR: boolean | undefined,
    medias: Media[],
    setDeleteList: (list: string[]) => void,
    deleteList: string[]
}

export function IncompleteCustomerForm({ deleteList, setOpenConfirm, warranty, setWarranty, damagedQuality, setDamagedQuality, files, setFiles, isAR, medias, setDeleteList }: IncompleteCustomerFormProp) {

    const { translate } = useLocales();

    const isDesktop = useResponsive("up", "sm");

    const navigate = useNavigate();

    const { filtersInUrl, request } = useSelector((state: RootState) => state.request);

    const { isLoading } = useSelector((state: RootState) => state.brand);

    const [warrantyMedia, setWarrantyMedia] = useState<(Media | null)[]>(request?.media || []);

    const warrantyNARKeysRemover = useCallback((key: string) => {
        return !(["mileage", "model"].includes(key));
    }, []);

    const isDamagedQualityValid = useMemo(() => {
        if (request?.requestType === "DamagedPart") {
            return !(damagedQuality.notes && (damagedQuality.medias.length > 0 || request.media.filter(v => !deleteList.includes(v.mediaId || "")).length > 0));
        }
        else
            return !damagedQuality.notes;

    }, [damagedQuality, request?.requestType, request?.media, deleteList]);

    const isWarrantyValid = useMemo(() => {

        if (warranty.invoices.length <= 0) return false;

        if (isAR) return warranty.invoices[0].date !== "" && warranty.invoices[0].number !== "" && Object.keys(files).length === 1;

        let findNullVehicle = undefined;

        if (warranty.vehicle) {
            findNullVehicle = remove(Object.keys(warranty.vehicle), warrantyNARKeysRemover)
                .find((key) => warranty.vehicle![key] === null || warranty.vehicle![key].toString() === "" || warranty.vehicle![key].toString() === "0");
        } else return false;

        const findNullInvoice = warranty.invoices.find((element, index) => {

            const find = remove(Object.keys(element), index > 1 ? () => { return true; } : warrantyNARKeysRemover)
                .find((key) => element[key] === null || element[key].toString() === "" || element[key].toString() === "0");

            return !!find;
        });

        let count = Object.keys(files).length;

        warrantyMedia.forEach((media) => { if (media) count++; });

        return !findNullInvoice && !findNullVehicle && warranty.diagnosis && warranty.note && warranty.solution && count === 5;

    }, [isAR, warranty, warrantyNARKeysRemover, files, warrantyMedia]);

    const handleDamagedQuality = (val: DamagedQuality) => {
        setDamagedQuality(val);

        const media: Record<string, File> = val.medias.reduce((obj, item) => Object.assign(obj, { [item.name]: item }), {});

        setFiles(media);
    };

    return (
        <Card sx={{ p: 3, pt: 2, mt: 2 }}>

            {request?.requestType === "Warranty" && !isLoading &&
                <WarrantyAmend
                    isAR={!!isAR}
                    warranty={warranty}
                    setWarranty={setWarranty}
                    files={files}
                    setFiles={setFiles}
                    setDeleteList={setDeleteList}
                    deleteList={deleteList}
                    warrantyMedia={warrantyMedia}
                    setWarrantyMedia={setWarrantyMedia}
                />
            }
            {
                request?.requestType === "DamagedPart" &&
                <DamagedAmend
                    onChange={handleDamagedQuality}
                    damagedQuality={damagedQuality}
                    medias={medias}
                    setDeleteList={setDeleteList}

                />
            }
            {
                request?.requestType === "Quality" &&
                <QualityAmend
                    onChange={handleDamagedQuality}
                    damagedQuality={damagedQuality}
                    medias={medias}
                    setDeleteList={setDeleteList}
                />
            }

            <Divider />

            <Box
                sx={{
                    justifyContent: 'space-between',
                    ml: isDesktop ? 'auto' : 0,
                    display: 'flex',
                    gap: 1, px: 1, mt: 3,
                    flexDirection: isDesktop ? 'default' : 'column-reverse'
                }}
            >
                <Button
                    variant="soft"
                    sx={{ borderRadius: '100px' }}
                    onClick={() => navigate(PATH_DASHBOARD.request.customer.list + filtersInUrl, { replace: true })}
                >
                    {`${translate('commons.goBack')}`}
                </Button>

                <Button
                    variant="contained"
                    sx={{ borderRadius: '100px' }}
                    disabled={request?.requestType === "Warranty" ? !isWarrantyValid : isDamagedQualityValid}
                    onClick={() => setOpenConfirm(true)}
                >
                    {`${translate('commons.confirm')}`}
                </Button>
            </Box>

        </Card>
    );
}