import { Box, Button, Card, Chip, Divider, Tab, Tabs, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { NewGroup, WhiteBlackListDisplay } from "src/@types/group";
import StepButtons from "src/components/buttons/StepButtons";
import DetailPermissionsList from "src/utils/DetailPermissionsList";
import { GreyCounter } from "../../webshop/newWebshop/CustomerStep";
import { useLocales } from "src/locales";
import useTenant from "src/appHooks/useTenant";
import { getCustomFieldLabel } from "src/utils/CustomFieldManagment";
import { WhiteBlackList } from "./WhiteBlacklistStep";

interface SummaryStepProps {
    changeStep: Dispatch<SetStateAction<number>>,
    state: NewGroup,
    onSubmit?: VoidFunction,
    whitelist: WhiteBlackListDisplay,
    blacklist: WhiteBlackListDisplay
}

export default function SummaryStep({ changeStep, onSubmit, state, blacklist, whitelist }: SummaryStepProps) {

    const { translate, currentLang } = useLocales();

    const { customFields } = useTenant();

    const [blacklistTab, setBlacklistTab] = useState<"Users" | "Organizations">("Users");

    const [whitelistTab, setwhitelistTab] = useState<"Users" | "Organizations">("Users");

    const parsedField = useMemo(() =>
        customFields.filter(field => field.enabled && (field.entityType === "User" || field.entityType === "Organization")),
        [customFields]);

    return (
        <Card sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <Typography variant="h5">
                    {state.displayName}
                </Typography>
                {state && <Typography variant="subtitle1">
                    {translate(state.groupType === "Customer" ? "commons.customer_plural" : "commons.organizations")}
                </Typography>}
            </Box>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ mb: 2 }}>
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant="h5">
                        {translate('groups.form.steps.properties')}
                    </Typography>
                    <Button variant="text" onClick={() => changeStep(1)}>
                        {translate('commons.edit')}
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', gap: 2, }}>
                    {state?.propertyDependency.properties.map(field => (
                        <Box key={field.name} sx={{ display: 'grid', gap: 10, gridTemplateColumns: '0.5fr 1fr' }}>
                            <Box>
                                <Typography>
                                    {getCustomFieldLabel(parsedField, field.name, currentLang)}
                                </Typography>

                            </Box>
                            <Box>
                                {field.values.map((v, ind) => <Chip sx={{ mx: 1 }} key={ind} label={v} />)}
                            </Box>
                        </Box>
                    ))}

                </Box>

            </Box>
            <Divider sx={{ my: 1 }} />
            <Box>
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    <Typography variant="h5">
                        {translate('groups.form.steps.permissions')}

                    </Typography>
                    <Button variant="text" onClick={() => changeStep(2)}>
                        {translate('commons.edit')}
                    </Button>
                </Box>
                <DetailPermissionsList list={state?.permissionDependency.permissions ?? []} />
            </Box>
            <Divider sx={{ my: 1 }} />
            <Box sx={{ mb: 2 }}>
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant="h5">
                        {translate('groups.form.steps.whitelist')}
                    </Typography>
                    <Button variant="text" onClick={() => changeStep(3)}>
                        {translate('commons.edit')}
                    </Button>
                </Box>
                <Tabs value={whitelistTab} onChange={(_, val) => setwhitelistTab(val)} sx={{ mb: 1 }}>
                    <Tab label={translate('groups.list.type.user')} value={"Users"} />
                    <Tab label={translate('groups.list.type.organization')} value={"Organizations"} />
                </Tabs>
                {["Users", "Organizations"].map((type) => {
                    const list = type === "Users" ? whitelist.users : whitelist.organizations;

                    if (whitelistTab === type)
                        return (

                            <Box key={type}>
                                {!list.length ?
                                    <GreyCounter label={translate('groups.messages.noSelected')} />
                                    :
                                    <WhiteBlackList type={whitelistTab} list={whitelist} />
                                }
                            </Box>

                        );
                })}
            </Box>
            <Divider sx={{ my: 1 }} />
            <Box sx={{ mb: 2 }}>
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant="h5">
                        {translate('groups.form.steps.blacklist')}
                    </Typography>
                    <Button variant="text" onClick={() => changeStep(4)}>
                        {translate('commons.edit')}
                    </Button>
                </Box>
                <Tabs value={blacklistTab} onChange={(_, val) => setBlacklistTab(val)} sx={{ mb: 1 }}>
                    <Tab label={translate('groups.list.type.user')} value={"Users"} />
                    <Tab label={translate('groups.list.type.organization')} value={"Organizations"} />
                </Tabs>
                {["Users", "Organizations"].map((type) => {

                    const list = type === "Users" ? blacklist.users : blacklist.organizations;

                    if (blacklistTab === type)
                        return (

                            <Box key={type}>
                                {!list.length ?
                                    <GreyCounter label={translate('groups.messages.noSelected')} />
                                    :
                                    <WhiteBlackList type={blacklistTab} list={blacklist} />
                                }
                            </Box>

                        );
                })}

            </Box>
            <Divider sx={{ my: 1 }} />
            <StepButtons changeStep={changeStep} confirm onSave={onSubmit} />
        </Card>
    );
}
