import { Box, Stack, Typography, StackProps } from '@mui/material';
import { UploadIllustration } from '../../assets/illustrations';
import { useLocales } from 'src/locales';

export default function UploadInputBoxPlaceholder({ sx, ...other }: StackProps) {

    const { translate } = useLocales();

    return (
        <Stack
            spacing={2}
            alignItems="center"
            justifyContent="center"
            direction={{
                xs: 'column',
                md: 'row',
            }}
            sx={{
                width: 1,
                textAlign: {
                    xs: 'center',
                    md: 'left',
                },
                ...sx,
            }}
            {...other}
        >
            <UploadIllustration sx={{ width: 170 }} />

            <Box sx={{ p: 3 }}>

                <Typography gutterBottom variant="h5">
                    {translate("uploader.placeholder.dropTitle")}
                </Typography>

                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {translate("uploader.placeholder.dropSubtitle_start")}
                    <Typography
                        variant="body2"
                        component="span"
                        sx={{
                            mx: 0.5,
                            color: 'primary.main',
                            textDecoration: 'underline'
                        }}
                    >
                        {translate("uploader.placeholder.dropSubtitle_link")}
                    </Typography>
                    {translate("uploader.placeholder.dropSubtitle_end")}
                </Typography>
            </Box>
        </Stack>
    );
}
