import { Typography, FormControlLabel, Switch, TextField, Box } from "@mui/material";
import { useState, ChangeEvent, useEffect } from "react";
import { Request } from "src/@types/request";
import { useLocales } from "src/locales";
import { ContainerSection } from "./WarehouseNewArticle";

interface WarehouseDamagedProps {
    handleFlag: (flags: any) => void,
    changeNote: (val: string) => void,
    changeContainer: (val: string | null) => void,
    request: Request | null,
    changeReasonCode: (val: string | null) => void

}

export default function WarehouseDamaged({ handleFlag, changeContainer, request, changeReasonCode }: WarehouseDamagedProps) {

    const { translate } = useLocales();

    const [flags, setFlags] = useState({ partAccepted: true, partConditionAccepted: true, reasonAccepted: true, administrativeCheckAccepted: true });

    const [reasonCode, setReasonCode] = useState<string | null>(null);

    const handleFlags = (e: ChangeEvent<HTMLInputElement>) => {

        switch (e.target.name) {
            case "correct":
                setFlags((prev) => ({ ...prev, partAccepted: e.target.checked }));
                break;
            case "damaged":
                setFlags((prev) => ({ ...prev, partConditionAccepted: e.target.checked, reasonAccepted: true }));
                break;
            case "reasonCode":
                setFlags((prev) => ({ ...prev, reasonAccepted: e.target.checked }));
                if (e.target.checked) {
                    setReasonCode(null);
                    changeReasonCode(null);
                }
                break;
            case "adminCorrect":
                setFlags((prev) => ({ ...prev, administrativeCheckAccepted: e.target.checked }));
                break;
        }

    };

    useEffect(() => {
        handleFlag(flags);
    }, [flags, handleFlag]);

    const typographySubtitle = {
        color: '#6D6D6D',
        fontSize: 12,
        fontFamily: 'Public Sans',
        fontWeight: '700',
        textTransform: 'uppercase',
        wordWrap: 'break-word'
    };

    return (
        <Box sx={{ m: 3 }}>
            <Typography sx={typographySubtitle}>{`${translate('request.checkCondition')}`}</Typography>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: '100%',
                my: 2,
            }}
            >
                <FormControlLabel
                    label={`${translate('request.correctPart')}`}
                    control={<Switch checked={flags.partAccepted} name="correct" onChange={handleFlags} />}
                />
                {
                    <FormControlLabel
                        label={`${translate('request.isDamaged')}`}
                        control={<Switch checked={flags.partConditionAccepted} name="damaged" onChange={handleFlags} />}
                    />
                }

                {!flags.partConditionAccepted &&
                    <FormControlLabel
                        label={`${translate('request.correctReason')}`}
                        control={<Switch checked={flags.reasonAccepted} name="reasonCode" onChange={handleFlags} />}
                    />
                }
                {
                    !flags.reasonAccepted && !flags.partConditionAccepted &&
                    <TextField
                        label={`${translate('request.form.titleReason')}`}
                        value={reasonCode}
                        onChange={(e) => {
                            setReasonCode(e.target.value);
                            changeReasonCode(e.target.value);
                        }}
                    />
                }
                <FormControlLabel
                    label={`${translate(`request.adminCorrect`)}`}
                    control={<Switch checked={flags.administrativeCheckAccepted} name="adminCorrect" onChange={handleFlags} />}
                />
            </Box>
            {request &&
                <ContainerSection onContainerChange={(container) => changeContainer(container?.id || null)} contentTypes={request.requestType} />
            }
        </Box >);
}