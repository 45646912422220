import { useRef } from "react";
import { UseFormReturn } from "react-hook-form";
import { Box, Button, Card, Divider } from "@mui/material";
import FormProvider from "src/components/hook-form/FormProvider";
import { UserManager } from "src/@types/user";
import { useLocales } from "src/locales";
import UserStep2Organizations from "../steps/UserStep2Organizations";
import UserStep3RolesOrg from "../steps/UserStep3RolesOrg";
import UserStep4RolesAdmin from "../steps/UserStep4RolesAdmin";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useNavigate, useParams } from "react-router";

type UserNewEditFormProps = {
  outerRef: any,
  step: number,
  onSubmitFn: (data: any) => Promise<void>,
  formMethods: UseFormReturn<UserManager>,
  handleNextStep: () => Promise<void>,
  handlePreviousStep: () => void,
  showSubmit: boolean,
  isSaveDisabled: boolean,
  toggleSave: () => void
};

export default function AddForm({
  outerRef,
  step,
  onSubmitFn,
  formMethods,
  handleNextStep,
  handlePreviousStep,
  showSubmit,
  isSaveDisabled,
  toggleSave
}: UserNewEditFormProps) {

  const { translate } = useLocales();

  const navigate = useNavigate();

  const { userId = '' } = useParams();

  const step1Ref = useRef();

  const step2Ref = useRef();

  const step3Ref = useRef();

  const { getValues, handleSubmit, watch } = formMethods;

  const organizations = watch("organizations");

  const onSave = () => (step1Ref?.current as any)?.onSave();

  if (!outerRef?.current) {
    outerRef.current = {
      getValues,
      onSave
    };
  }

  const handleGoBack = () => {
    if (step === 1 || step === 3) {
      navigate(PATH_DASHBOARD.user.edit(userId));
    } else {
      handlePreviousStep();
    }
  };

  return (
    <FormProvider methods={formMethods} onSubmit={handleSubmit(onSubmitFn)}>

      <Box>

        <Card sx={{ mt: 2 }}>
          {
            step === 1 &&
            <UserStep2Organizations
              outerRef={step1Ref}
              formMethods={formMethods}
            />
          }
          {
            step === 2 &&
            <UserStep3RolesOrg
              outerRef={step2Ref}
              formMethods={formMethods}
            />
          }
          {
            step === 3 &&
            <UserStep4RolesAdmin
              outerRef={step3Ref}
              formMethods={formMethods}
            />
          }

          <Divider />

          <Box
            sx={{
              justifyContent: 'space-between',
              display: 'flex',
              gap: 1, p: 3
            }}
          >

            <Button
              variant="soft"
              color="inherit"
              onClick={handleGoBack}
              sx={{ borderRadius: 100 }}
            >
              {`${translate("commons.goBack")}`}
            </Button>

            {showSubmit ?
              <Button
                variant='contained'
                color='primary'
                onClick={toggleSave}
                sx={{ borderRadius: 100 }}
                disabled={isSaveDisabled}
              >
                {`${translate('menu.management.user.update')}`}
              </Button>
              :
              <Button
                variant='contained'
                color='primary'
                onClick={handleNextStep}
                sx={{ borderRadius: 100 }}
                disabled={organizations.length < 1}
              >
                {`${translate('commons.next')}`}
              </Button>
            }

          </Box>

        </Card>

      </Box>

    </FormProvider>
  );
}
