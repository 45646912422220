import { useFormContext, Controller } from 'react-hook-form';
import { TextFieldProps } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
    name: string;
    minDate?: string | null;
    onChangeVal?: (value: any) => void;
    setOnEditForm?: (value: boolean) => void;
    onEditForm?: boolean;
    onChangeDate?: (event: any, dateValue?: string, dateFieldName?: string) => void;
};

export default function RHFDatePicker({ name, minDate, label, onChangeVal, setOnEditForm, onEditForm, onChangeDate, ...other }: Props) {

    const { control } = useFormContext();

    const { translate } = useLocales();

    const onBlurFunc = () => {
        if (onChangeVal) {
            onChangeVal(name);
            if (setOnEditForm) {
                setOnEditForm(false);
            }
        }
    };

    const onFocusFunc = () => {
        if (setOnEditForm) {
            setOnEditForm(true);
        }
    };

    //TODO: implementare X per pulire componente

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) =>
                <DatePicker
                    value={value ? new Date(value) : (other.defaultValue ? new Date(other.defaultValue as any) : null)}
                    views={['year', 'month', 'day']}
                    format={'dd/MM/yyyy'}
                    label={label}
                    onAccept={(value) => {
                        if (value)
                            onChange(format(new Date(value), 'yyyy-MM-dd'));
                    }}

                    onChange={(fromDateValue, inputValue) => {
                        if (!inputValue.validationError) {
                            onChange(fromDateValue);
                            if (onChangeDate && fromDateValue)
                                onChangeDate("", format(new Date(fromDateValue), 'yyyy-MM-dd'), name);
                        }

                    }}
                    slotProps={{
                        textField: {
                            error: !!error || false,
                            ...other,
                            helperText: error && `${translate(error?.message)}`,
                            onFocus: onFocusFunc,
                            onBlur: onBlurFunc,
                            sx: { '& .MuiInputLabel-asterisk': { color: 'error.main' } }
                        }
                    }}
                    minDate={(minDate) ? new Date(minDate) : undefined}
                />
            }
        />
    );
}
