import { InboxSearchResult, InboxLabelStatisticsType, PagedInboxResponseType, DEFAULT_INBOX_SEARCH, NotificationSettings, NotificationTemplateSearchResult, NotificationTemplate } from "src/@types/inbox";
import { createSlice } from '@reduxjs/toolkit';
import inboxOperations from "./inbox-operations";
import { ErrorResponse } from "src/@types/commons";

type InboxState = {
    /* COMMON */
    error: ErrorResponse,
    /* SEARCH */
    isLoading: boolean,
    isLoadingRecent: boolean,
    labels: InboxLabelStatisticsType[],
    messages: PagedInboxResponseType,
    recentMessages: PagedInboxResponseType,
    /* DETAIL */
    message: InboxSearchResult | null
    totalCount: number,
    unreadCount: number,
    /*SETTINGS*/
    isSettingsLoading: boolean
    settings: NotificationSettings | null,
    /*TEMPLATE*/
    isTemplateLoading: boolean,
    templateList: NotificationTemplateSearchResult | null,
    template: NotificationTemplate | null
};

const initialState: InboxState = {
    /* COMMON */
    error: null,
    /* SEARCH */
    isLoading: false,
    isLoadingRecent: false,
    labels: [],
    messages: DEFAULT_INBOX_SEARCH,
    recentMessages: DEFAULT_INBOX_SEARCH,
    /* DETAIL */
    message: null,
    totalCount: 0,
    unreadCount: 0,
    /* SETTINGS */
    isSettingsLoading: false,
    settings: null,
    /*TEMPLATE*/
    isTemplateLoading: false,
    templateList: null,
    template: null
};

const InboxSlice = createSlice({
    name: 'inbox',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(inboxOperations.getInboxLabelsAndStatistics.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isLoading = false;
                state.unreadCount = initialState.unreadCount;
                state.totalCount = initialState.totalCount;
                state.labels = initialState.labels;
            })
            .addCase(inboxOperations.getInboxLabelsAndStatistics.fulfilled, (state, action) => {
                state.labels = action.payload.labels;
                state.totalCount = action.payload.totalCount;
                state.unreadCount = action.payload.totalUnreadCount;
                state.messages.unreadMessageCount = action.payload.totalUnreadCount;
                state.recentMessages.unreadMessageCount = action.payload.totalUnreadCount;
                state.isLoading = false;
            })
            .addCase(inboxOperations.getRecentInboxLabelsAndStatistics.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isLoadingRecent = false;
                state.unreadCount = initialState.unreadCount;
                state.totalCount = initialState.totalCount;
                state.labels = initialState.labels;
            })
            .addCase(inboxOperations.getRecentInboxLabelsAndStatistics.fulfilled, (state, action) => {
                state.labels = action.payload.labels;
                state.totalCount = action.payload.totalCount;
                state.unreadCount = action.payload.totalUnreadCount;
                state.messages.unreadMessageCount = action.payload.totalUnreadCount;
                state.recentMessages.unreadMessageCount = action.payload.totalUnreadCount;
                state.isLoadingRecent = false;
            })
            //-----------------------------------------
            .addCase(inboxOperations.getMessages.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(inboxOperations.getMessages.fulfilled, (state, action) => {
                state.messages = action.payload;
                state.isLoading = false;
            })
            //-----------------------------------------
            .addCase(inboxOperations.getRecentMessages.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isLoadingRecent = false;
            })
            .addCase(inboxOperations.getRecentMessages.fulfilled, (state, action) => {
                state.recentMessages = action.payload;
                state.isLoadingRecent = false;
            })
            //-----------------------------------------
            .addCase(inboxOperations.getMessageDetail.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isLoadingRecent = false;
            })
            .addCase(inboxOperations.getMessageDetail.fulfilled, (state, action) => {
                state.message = action.payload;
                state.isLoadingRecent = false;
            })
            //-----------------------------------------
            .addCase(inboxOperations.markAsStarred.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            //-----------------------------------------
            .addCase(inboxOperations.markAsUnStarred.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            //-----------------------------------------
            .addCase(inboxOperations.markAsImportant.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            //-----------------------------------------
            .addCase(inboxOperations.markAsUnImportant.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            //-----------------------------------------
            .addCase(inboxOperations.markAsRead.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            //-----------------------------------------
            .addCase(inboxOperations.markAsUnRead.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            //-----------------------------------------
            .addCase(inboxOperations.deleteMessages.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(inboxOperations.getNotificationSettings.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.settings = initialState.settings;
                state.isSettingsLoading = false;
            })
            .addCase(inboxOperations.getNotificationSettings.fulfilled, (state, action) => {
                state.settings = action.payload;
                state.isSettingsLoading = false;
            })
            .addCase(inboxOperations.updateNotificationSettings.fulfilled, (state, action) => {
                state.settings = action.payload;
            })
            .addCase(inboxOperations.updateNotificationSettings.rejected, (state, action) => {
                state.error = action.error.message as string;
            })
            .addCase(inboxOperations.createNotificationTemplate.rejected, (state, action) => {
                state.error = action.error.message as string;
            })
            .addCase(inboxOperations.createNotificationTemplate.fulfilled, (state, action) => {
                state.template = action.payload;
            })
            .addCase(inboxOperations.editNotificationTemplate.rejected, (state, action) => {
                state.error = action.error.message as string;
            })
            .addCase(inboxOperations.notificationTemplateDetail.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isTemplateLoading = false;
            })
            .addCase(inboxOperations.notificationTemplateDetail.fulfilled, (state, action) => {
                state.template = action.payload;
                state.isTemplateLoading = false;
            })
            .addCase(inboxOperations.searchNotificationTemplate.fulfilled, (state, action) => {
                state.templateList = action.payload;
                state.isTemplateLoading = false;
            })
            .addCase(inboxOperations.searchNotificationTemplate.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isTemplateLoading = false;
            })
            ;
    },
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        startLoadingRecent(state) {
            state.isLoadingRecent = true;
        },
        startSettingsLoading(state) {
            state.isSettingsLoading = true;
        },
        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload.message;
        },
        incrementUnread(state) {
            state.unreadCount += 1;
        },
        startTemplateLoading(state) {
            state.isTemplateLoading = true;
        },
        resetTemplateList(state) {
            state.templateList = null;
        }
    },
});

export const {
    startLoading,
    startLoadingRecent,
    hasError,
    incrementUnread,
    startSettingsLoading,
    startTemplateLoading,
    resetTemplateList
} = InboxSlice.actions;

export default InboxSlice.reducer;