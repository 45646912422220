import { MenuItem } from '@mui/material';
import { RHFSelect } from '../components/hook-form';
import { useLocales } from 'src/locales';

type CurrencySelectProps = {
    name: string;
    onChangeVal?: (value: string) => void;
    setOnEditForm?: (value: boolean) => void;
    onEditForm?: boolean;
};

function CurrencySelect(props: CurrencySelectProps) {
    const { translate } = useLocales();

    return (
        <RHFSelect
            required
            name={props.name}
            label={`${translate("commons.currency")}`}
            onChangeVal={props.onChangeVal}
            setOnEditForm={props.setOnEditForm}
            onEditForm={props.onEditForm}
        >
            <MenuItem key="USD" value="USD">{`${translate("commons.currencyTypes.AmericanDollar")}`}</MenuItem>
            <MenuItem key="JPY" value="JPY">{`${translate("commons.currencyTypes.JapanYen")}`}</MenuItem>
            <MenuItem key="BGN" value="BGN">{`${translate("commons.currencyTypes.BulgariaLev")}`}</MenuItem>
            <MenuItem key="GBP" value="GBP">{`${translate("commons.currencyTypes.UnitedKingdomPound")}`}</MenuItem>
            <MenuItem key="DKK" value="DKK">{`${translate("commons.currencyTypes.DenmarkKrone")}`}</MenuItem>
            <MenuItem key="CHF" value="CHF">{`${translate("commons.currencyTypes.SwitzerlandFranc")}`}</MenuItem>
            <MenuItem key="NOK" value="NOK">{`${translate("commons.currencyTypes.NorwayKrone")}`}</MenuItem>
            <MenuItem key="AUD" value="AUD">{`${translate("commons.currencyTypes.AustralianDollar")}`}</MenuItem>
            <MenuItem key="CNY" value="CNY">{`${translate("commons.currencyTypes.ChinaYuanRenminbi")}`}</MenuItem>
            <MenuItem key="IDR" value="IDR">{`${translate("commons.currencyTypes.IndonesiaRupiah")}`}</MenuItem>
            <MenuItem key="ILS" value="ILS">{`${translate("commons.currencyTypes.Israel Shekel")}`}</MenuItem>
            <MenuItem key="KRW" value="KRW">{`${translate("commons.currencyTypes.KoreaSouthWon")}`}</MenuItem>
            <MenuItem key="SGD" value="SGD">{`${translate("commons.currencyTypes.SingaporeDollar")}`}</MenuItem>
            <MenuItem key="RON" value="RON">{`${translate("commons.currencyTypes.RomaniaLeu")}`}</MenuItem>
            <MenuItem key="BRL" value="BRL">{`${translate("commons.currencyTypes.BrazilReal")}`}</MenuItem>
            <MenuItem key="INR" value="INR">{`${translate("commons.currencyTypes.IndiaRupee")}`}</MenuItem>
            <MenuItem key="MXN" value="MXN">{`${translate("commons.currencyTypes.MexicoPeso")}`}</MenuItem>
            <MenuItem key="MYR" value="MYR">{`${translate("commons.currencyTypes.MalaysiaRinggit")}`}</MenuItem>
            <MenuItem key="PHP" value="PHP">{`${translate("commons.currencyTypes.PhilippinesPeso")}`}</MenuItem>
            <MenuItem key="THB" value="THB">{`${translate("commons.currencyTypes.ThailandBaht")}`}</MenuItem>
            <MenuItem key="PLN" value="PLN">{`${translate("commons.currencyTypes.PolandZloty")}`}</MenuItem>
            <MenuItem key="SEK" value="SEK">{`${translate("commons.currencyTypes.SwedenKrona")}`}</MenuItem>
            <MenuItem key="CAD" value="CAD">{`${translate("commons.currencyTypes.CanadaDollar")}`}</MenuItem>
            <MenuItem key="HKD" value="HKD">{`${translate("commons.currencyTypes.HongKongDollar")}`}</MenuItem>
            <MenuItem key="EUR" value="EUR">{`${translate("commons.currencyTypes.Euro")}`}</MenuItem>
            <MenuItem key="CZK" value="CZK">{`${translate("commons.currencyTypes.CzechRepublicKoruna")}`}</MenuItem>
            <MenuItem key="HUF" value="HUF">{`${translate("commons.currencyTypes.HungaryForint")}`}</MenuItem>
            <MenuItem key="ISK" value="ISK">{`${translate("commons.currencyTypes.IcelandKrona")}`}</MenuItem>
            <MenuItem key="HRK" value="HRK">{`${translate("commons.currencyTypes.CroatiaKuna")}`}</MenuItem>
            <MenuItem key="TRY" value="TRY">{`${translate("commons.currencyTypes.TurkeyLira")}`}</MenuItem>
            <MenuItem key="NZD" value="NZD">{`${translate("commons.currencyTypes.NewZealandDollar")}`}</MenuItem>
            <MenuItem key="ZAR" value="ZAR">{`${translate("commons.currencyTypes.SouthAfricaRand")}`}</MenuItem>
        </RHFSelect>
    );
}

export default CurrencySelect;

