import { Box, Card, Container, FormControlLabel, Switch, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import Page from "src/appComponents/Page";
import { useSettingsContext } from "src/components/settings";
import { useLocales } from "src/locales";
import { matrixOperations } from "src/redux/matrix";
import { RootState, dispatch } from "src/redux/store";
import HeaderBreadcrumbs from '../../../components/custom-breadcrumbs';
import { PATH_DASHBOARD } from "src/routes/paths";
import { TableHeadCustom, TableNoData } from "src/components/table";
import useTable from "src/appHooks/useTable";
import { convert } from "src/utils/currency";
import useCurrency from "src/appHooks/useCurrency";
import { StyledScrollbar } from "src/components/scrollbar/styles";
import { resetPNOPrices } from "src/redux/matrix/matrix-slices";

export default function MatrixList() {

    const { PNOPrices, rvMatrixPreview } = useSelector((state: RootState) => state.matrix);

    const { themeStretch, currency: currentCurrency } = useSettingsContext();

    const { translate, currentLang } = useLocales();

    const { matrixId } = useParams();

    const { dense, onChangeDense } = useTable();

    const { rates } = useCurrency();

    const TABLE_HEAD = [
        { id: 'PNO18', label: `${translate('commons.PNO18')}`, align: 'left' },
        { id: 'yearEdition', label: `${translate('matrix.yearEdition')}`, align: 'left' },
        { id: 'vehicleLine', label: `${translate('matrix.vehicleLine')}`, align: 'left' },
        { id: 'lineName', label: `${translate('matrix.lineName')}`, align: 'center' },
        { id: 'roofName', label: `${translate('matrix.roofName')}`, align: 'left' },
        { id: 'transmission', label: `${translate('vehicle.form.transmission.title')}`, align: 'left' },
        { id: 'price', label: `${translate('commons.price')}`, align: 'left' },
    ];

    useEffect(() => {
        if (matrixId)
            dispatch(matrixOperations.getRvMatrixById(matrixId));
        else
            dispatch(resetPNOPrices());

    }, [matrixId, currentLang]);
    //todo hasPermissions

    useEffect(() => {
        dispatch(matrixOperations.getPNOPrices());
    }, [rvMatrixPreview, currentLang]);

    return (<>
        <Page title={`${translate('matrix.list')}`}>
            {!matrixId ? (
                <Container maxWidth={themeStretch ? false : 'lg'} >
                    <HeaderBreadcrumbs
                        heading={`${translate('matrix.list')}`}
                        links={[
                            { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                            { name: `${translate('matrix.matrixList.matrix_plural')}`, previousPage: 1 },
                        ]}
                    />
                    <Card sx={{ p: 3 }}>
                        <Box
                            sx={{
                                display: 'grid',
                                columnGap: 2,
                                rowGap: 3,
                                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                                marginBottom: 2,
                                marginTop: 1
                            }}
                        >
                            {/* <FormControl>
                                <InputLabel id='select'>{`${translate("commons.country")}`}</InputLabel>
                                <Select onChange={handleCountry} native name="matrix" label={`${translate("commons.country")}`} placeholder={`${translate("commons.country")}`}>
                                    <option hidden>
                                        {''}
                                    </option>
                                    {getEnabledCountries(user, [OrganizationPermissionTypes.RvTemplateView, OrganizationPermissionTypes.RvMatrixView]).map(
                                        option => (
                                            <option key={option.code} value={option.code}>
                                                {option.label}
                                            </option>
                                        )
                                    )}
                                </Select>
                            </FormControl> */}
                        </Box>
                        <Box>
                            <TableContainer sx={[{ maxHeight: 500 }, StyledScrollbar]}>
                                <Table stickyHeader size={'medium'}>
                                    <TableHeadCustom headLabel={TABLE_HEAD} />

                                    <TableBody>
                                        {
                                            PNOPrices?.map((v) => {
                                                const vat = convert(v.cataloguePrice.withVat, v.cataloguePrice.currentCurrency, currentCurrency.label, rates);

                                                const noVat = convert(v.cataloguePrice.withoutVat, v.cataloguePrice.currentCurrency, currentCurrency.label, rates);

                                                return (
                                                    <TableRow key={v.pno18}>
                                                        <TableCell>{v.pno18}</TableCell>
                                                        <TableCell>{v.yearEdition}</TableCell>
                                                        <TableCell>{v.vehicleLine}</TableCell>
                                                        <TableCell align="center">{v.lineName}</TableCell>
                                                        <TableCell>{v.roofColor}</TableCell>
                                                        <TableCell>{v.transmission}</TableCell>

                                                        <TableCell>
                                                            <Typography display="inline" sx={{ color: 'text.primary', fontSize: 'small', textAlign: 'left', width: '100%' }}>
                                                                {(noVat ? translate('commons.price') + ': ' + noVat.toFixed(2) + currentCurrency.symbol + '\n' : '') + translate('vehicle.form.equipments.priceWithVat') + ': ' + vat.toFixed(2) + currentCurrency.symbol}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        <TableNoData isNotFound={PNOPrices?.length === 0 || !PNOPrices} />
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Card>
                </Container>
            ) : (
                <Container maxWidth={themeStretch ? false : 'lg'} >
                    <HeaderBreadcrumbs
                        heading={`${translate('matrix.list')}`}
                        links={[
                            { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                            { name: `${translate('matrix.matrixList.matrix_plural')}`, href: PATH_DASHBOARD.matrix.managment },
                            { name: rvMatrixPreview?.name }
                        ]}
                    />
                    <Card>
                        <Box>
                            <TableContainer sx={{ maxHeight: 500 }}>
                                <Table stickyHeader size={'medium'}>
                                    <TableHeadCustom headLabel={TABLE_HEAD} />

                                    <TableBody>
                                        {
                                            PNOPrices?.map((v) => {
                                                const vat = convert(v.cataloguePrice.withVat, v.cataloguePrice.currentCurrency, currentCurrency.label, rates);

                                                const noVat = convert(v.cataloguePrice.withoutVat, v.cataloguePrice.currentCurrency, currentCurrency.label, rates);

                                                return (
                                                    <TableRow key={v.pno18}>
                                                        <TableCell>{v.pno18}</TableCell>
                                                        <TableCell>{v.yearEdition}</TableCell>
                                                        <TableCell>{v.vehicleLine}</TableCell>
                                                        <TableCell align="center">{v.lineName}</TableCell>
                                                        <TableCell>{v.roofColor}</TableCell>
                                                        <TableCell>{v.transmission}</TableCell>

                                                        <TableCell>
                                                            <Typography display="inline" sx={{ color: 'text.primary', fontSize: 'small', textAlign: 'left', width: '100%' }}>
                                                                {(noVat ? translate('commons.price') + ': ' + noVat.toFixed(2) + currentCurrency.symbol + '\n' : '') + translate('vehicle.form.equipments.priceWithVat') + ': ' + vat.toFixed(2) + currentCurrency.symbol}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        <TableNoData isNotFound={PNOPrices?.length === 0 || !PNOPrices} />
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Box sx={{ position: 'relative' }}>

                                <FormControlLabel
                                    control={<Switch checked={dense} onChange={onChangeDense} />}
                                    label={`${translate('commons.dense')}`}
                                    sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                                />
                            </Box>
                        </Box>
                    </Card>
                </Container>
            )}
        </Page >

    </>);
}