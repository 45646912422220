import Container from '@mui/material/Container';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import Page from 'src/appComponents/Page';
import useLocales from 'src/appHooks/useLocales';
import { useSettingsContext } from 'src/components/settings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { dispatch, RootState } from 'src/redux/store';
import LoadingScreen from 'src/appComponents/loading-screen';
import { Box, Button, Card, Divider, Stack, Typography } from '@mui/material';
import { emailOperations } from 'src/redux/email';
import { resetEmail } from 'src/redux/email/email-slices';
import { GridLabeledText } from 'src/appComponents/GridLabeledText';

export default function EmailDetails() {
    const { themeStretch } = useSettingsContext();

    const isDesktop = useResponsive('up', 'sm');

    const { translate, currentLang } = useLocales();

    const { email, isEmailLoading } = useSelector((state: RootState) => state.email);

    const navigate = useNavigate();

    const params = useParams();

    useEffect(() => {
        dispatch(emailOperations.getEmailDetails(params?.id || ""));

        return () => {
            dispatch(resetEmail());
        };
    }, [params?.id, currentLang]);

    const onQuit = () => {
        navigate(-1);
    };

    return (
        <Page title="Vehicle: Add Contractual Data">
            <Container maxWidth={themeStretch ? false : 'lg'} sx={{ p: 0 }}>
                {isEmailLoading ? (
                    <LoadingScreen />
                ) : (
                    <>
                        <HeaderBreadcrumbs
                            heading={`${translate('systemEmail.list.title')}`}
                            links={[
                                { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                                { name: `${translate('systemEmail.list.title')}`, href: PATH_DASHBOARD.tenant.systemEmail },
                                { name: `${params?.id}` }
                            ]}
                            sx={{ px: isDesktop ? 5 : 2, mb: isDesktop ? 5 : 2 }}
                        />
                        <Card>

                            <Box
                                sx={{
                                    px: isDesktop ? 5 : 2,
                                    py: isDesktop ? 3 : 1,
                                    gap: 1
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        mb: 2,
                                    }}
                                >
                                    <Box>
                                        <Typography variant="body2">{`${translate('systemEmail.detail.viewDetails')}`}</Typography>
                                    </Box>

                                </Box>

                                <Box sx={{ pl: 3, pt: 3, pr: 3, pb: 2, display: 'grid', columnGap: 2, rowGap: 2, gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' } }} >
                                    <GridLabeledText align={"left"} label={`${translate("commons.code")}`} value={email?.id || '—'} left={3} right={9} />
                                    <GridLabeledText align={"left"} label={`${translate("commons.status")}`} value={email?.status || '—'} left={4} right={8} />
                                    <GridLabeledText align={"left"} label={`${translate("inbox.subject")}`} value={email?.subject || '—'} left={3} right={9} />
                                    <GridLabeledText align={"left"} label={`${translate("systemEmail.detail.recipient")}`} value={email?.recipient || '—'} left={4} right={8} />
                                    <GridLabeledText align={"left"} label={`${translate("systemEmail.detail.requestedAt")}`} value={email?.requestedAt || '—'} left={3} right={9} />
                                    <GridLabeledText align={"left"} label={`${translate("systemEmail.detail.processingTimeMs")}`} value={(email?.processingTimeMilliseconds?.toString()) || '—'} left={4} right={8} />
                                </Box>
                                <Box sx={{ pl: 3, pr: 3, pb: 2 }} >
                                    <GridLabeledText align={"center"} label={`${translate("systemEmail.detail.serverResponse")}`} value={email?.serverResponse || '—'} left={1.45} right={10.55} />
                                </Box>

                                <Stack sx={{ mb: 1, pl: 3 }}>
                                    <Typography variant="subtitle1" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
                                        {`${translate("statuses.bounce")}`}
                                    </Typography>
                                </Stack>

                                <Box sx={{ p: 3, display: 'grid', columnGap: 2, rowGap: 2, gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' } }} >
                                    <GridLabeledText align={"center"} label={`${translate("systemEmail.bounce.type")}`} value={email?.bounceType || '—'} />
                                    <GridLabeledText align={"center"} label={`${translate("systemEmail.bounce.subtype")}`} value={email?.bounceSubType || '—'} />
                                    <GridLabeledText align={"center"} label={`${translate("systemEmail.bounce.action")}`} value={email?.bounceAction || '—'} />
                                    <GridLabeledText align={"center"} label={`${translate("systemEmail.bounce.diagnosticCode")}`} value={email?.bounceDiagnosticCode || '—'} />
                                </Box>

                                <Stack sx={{ mb: 1, pl: 3 }}>
                                    <Typography variant="subtitle1" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
                                        {`${translate("statuses.complaint")}`}
                                    </Typography>
                                </Stack>
                                <Box sx={{ p: 3, display: 'grid', columnGap: 2, rowGap: 2, gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' } }} >
                                    <GridLabeledText align={"center"} label={`${translate("systemEmail.complaint.arrivalDate")}`} value={email?.complaintArrivalDate || '—'} />
                                    <GridLabeledText align={"center"} label={`${translate("systemEmail.complaint.feedbackType")}`} value={email?.complaintFeedbackType || '—'} />
                                </Box>

                            </Box>
                            <Divider />
                            <Box
                                sx={{
                                    display: 'flex',
                                    px: isDesktop ? 5 : 2,
                                    py: isDesktop ? 3 : 1,
                                    gap: 1,
                                    flexDirection: isDesktop ? 'row' : 'column-reverse',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Button variant="soft" color="inherit" size={isDesktop ? 'small' : 'large'} onClick={() => { onQuit(); }}>
                                    {`${translate('systemEmail.detail.backToMail')}`}
                                </Button>
                            </Box>

                        </Card>
                    </>
                )}
            </Container>
        </Page>
    );
}
