import { LocalizedString } from "./commons";

export const TermConditionStatusesArr = ["", "enabled", "disabled"] as const;

export type TermConditionStatusType = typeof TermConditionStatusesArr[number];

export const TermsConditionEntityAvailableArr = ['Shop', 'ReturnRequest'] as const;

export type TermsConditionEntityAvailable = typeof TermsConditionEntityAvailableArr[number];

export const TermsConditionEntityArr = ['User', 'Organization', 'SparePart', 'Vehicle', 'Rv', 'CatalogPrice', 'ReturnRequest', 'Order', 'Invoice', 'DeliveryNote', 'CreditNote', 'Shop'] as const;

export type TermsConditionEntity = typeof TermsConditionEntityArr[number];

export type TermCondition = {
    id: string,
    createdOn: string,
    name: string,
    entityType: TermsConditionEntityAvailable,
    text: LocalizedString[],
    enabled: boolean,
    isDefault: boolean
}

export type NewTermCondition = {
    name: string,
    entityType: TermsConditionEntityAvailable,
    text: LocalizedString[],
    enabled: boolean
}

export type TermConditionSearchResult = {
    id: string,
    name: string,
    entityType: TermsConditionEntity,
    enabled: boolean,
    isDefault: boolean
}

export type TermConditionFilters = {
    [key: string]: any
    pageIndex: number,
    pageSize: number,
    sortField?: string,
    sortAscending?: boolean,
    entityType?: TermsConditionEntity | "all",
    customFields?: Record<string, string>
}

export type EnableDisableTermConditionProps = {
    id: string,
    action: "enable" | "disable"
}

export type DefaultTermConditionProps = {
    id: string,
    entityType: TermsConditionEntity
}

//----------------------------------------

export const DEFAULT_TERM_AND_CONDITIONS_FILTERS: TermConditionFilters = {
    pageIndex: 0,
    pageSize: 10,
    sortField: "",
    sortAscending: false,
    name: "",
    entityType: "all",
    customFields: {}
};

export const DEFAULT_NEW_TERM_AND_CONDITIONS: NewTermCondition = {
    enabled: true,
    entityType: 'Shop',
    name: '',
    text: []
};
