import { useEffect, useState } from 'react';
import { Box, alpha, useTheme } from '@mui/material';
import useResponsive from 'src/hooks/useResponsive';
import { Media } from 'src/@types/request';
import { ReactComponent as MissingImage } from "./ic_missingImage.svg";
import { ReactComponent as CloseIcon } from "./ic_closeIcon.svg";
import { ReactComponent as NavigateBeforeIcon } from "./ic_arrow_back_ios_new.svg";
import { ReactComponent as NavigateNextIcon } from "./ic_arrow_forward_ios.svg";
import { getMediaName, getMediaUrl } from './utils';
import { MediaWithFocusStyle } from './MediaWithFocus';

interface BigImageProps {
    toggle: () => void,
    images: Media[],
    currentIndex: number,
    setCurrentIndex: (value: number) => void
}

export function BigMedia({ toggle, images, currentIndex, setCurrentIndex }: BigImageProps) {

    const isDesktop = useResponsive('up', 'sm');

    const theme = useTheme();

    const [currentSrc, setCurrentSrc] = useState(getMediaUrl(images[currentIndex], 1920, 1080));

    useEffect(() => {
        setCurrentSrc(getMediaUrl(images[currentIndex], 1920, 1080));
    }, [currentIndex, images]);

    const goToPrev = () => {
        const newIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;

        showImage(newIndex);
    };

    const goToNext = () => {
        const newIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;

        showImage(newIndex);
    };

    const showImage = (index: number) => {
        setCurrentIndex(index);
        setCurrentSrc(getMediaUrl(images[index], 1920, 1080));
    };

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: 'relative',
                height: "82.5%",
                mb: isDesktop ? 2 : 0,
                ...MediaWithFocusStyle
            }}
        >
            <NavigateBeforeIcon
                onClick={goToPrev}
                style={{
                    backgroundColor: theme.palette.background.paper,
                    border: `${isDesktop ? "3px" : "2px"} solid`,
                    borderRadius: "10px",
                    borderColor: theme.palette.text.primary,
                    cursor: "pointer",
                    position: "absolute",
                    top: isDesktop ? "48.75%" : "89.5%",
                    left: isDesktop ? "1.5%" : "15%",
                    width: isDesktop ? 65 : 100,
                    height: isDesktop ? 45 : 50,
                    paddingTop: "5",
                    paddingBottom: "5"
                }}
            />

            <CloseIcon
                onClick={() => toggle()}
                className='iconsZ'
                style={{
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: "10px",
                    borderColor: theme.palette.text.primary,
                    cursor: "pointer",
                    position: "absolute",
                    border: `${isDesktop ? "3px" : "2px"} solid`,
                    top: isDesktop ? "-1.5%" : "0.5%",
                    right: isDesktop ? "-0.5%" : "0",
                    width: isDesktop ? 35 : 50,
                    height: isDesktop ? 35 : 50
                }}
            />

            {currentSrc ?
                <img
                    src={currentSrc}
                    alt={getMediaName(currentSrc)}
                    className={"fullImage"}
                />
                :
                <Box
                    sx={{ width: "100%", height: "100%", backgroundColor: (theme) => theme.palette.mode === "light" ? '#F9FAFB' : alpha('#F9FAFB', 0.8) }}
                >
                    <MissingImage />
                </Box>
            }

            <NavigateNextIcon
                onClick={goToNext}
                style={{
                    backgroundColor: theme.palette.background.paper,
                    borderColor: theme.palette.text.primary,
                    border: `${isDesktop ? "3px" : "2px"} solid`,
                    borderRadius: "10px",
                    cursor: "pointer",
                    position: 'absolute',
                    top: isDesktop ? "48.75%" : "89.5%",
                    right: isDesktop ? "1.5%" : "15%",
                    width: isDesktop ? 65 : 100,
                    height: isDesktop ? 45 : 50,
                    paddingTop: "5",
                    paddingBottom: "5"
                }}
            />
        </Box>
    );
}