import { ClusterPermissionTypes, OrganizationPermissionTypes } from "./permissions";

export type NewRole = {
    [key: string]: string | (ClusterPermissionTypes | OrganizationPermissionTypes)[]
    name: string,
    permissions: (ClusterPermissionTypes | OrganizationPermissionTypes)[]
};

export type Role = {
    [key: string]: string | Date | boolean | number | (ClusterPermissionTypes | OrganizationPermissionTypes)[],
    createdOn: Date,
    enabled: boolean,
    id: string,
    name: string,
    userInRole: number,
    permissions: (ClusterPermissionTypes | OrganizationPermissionTypes)[]
};

export type UpdateRole = {
    name: string,
    id: string,
    permissions: (ClusterPermissionTypes | OrganizationPermissionTypes)[],
    enabled: boolean
}

//-----------------------------------

export const DEFAULT_ROLE: Role = {
    name: "",
    createdOn: new Date(),
    id: "",
    enabled: false,
    permissions: [],
    userInRole: 0
};