import { PUBLIC_URL } from "../config";
import useTenant from "../appHooks/useTenant";

export default function ChangeFavicon() {

    const tenant = useTenant();

    if (!tenant.id) {
        return null;
    }

    const fav16 = document.getElementById("favicon-16");

    const fav32 = document.getElementById("favicon-32");

    (fav16 as HTMLElement)?.setAttribute('href', `${PUBLIC_URL}/favicon/${tenant.id}-16x16.svg`);
    (fav32 as HTMLElement)?.setAttribute('href', `${PUBLIC_URL}/favicon/${tenant.id}-32x32.svg`);

    return null;
}