import { Container, MenuItem, Typography } from '@mui/material';
import Page from 'src/appComponents/Page';
import { useNavigate, } from 'react-router-dom';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { useLocales } from 'src/locales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { GridCellParams, GridColDef, GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'src/redux/store';
import { useCallback, useMemo, useState } from 'react';
import Label from 'src/components/label';
import TableMoreMenu from 'src/appComponents/TableMoreMenu';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import { OrganizationPermissionTypes } from 'src/@types/permissions';
import { useSnackbar } from 'notistack';
import GenericList from 'src/utils/list/GenericList';
import { isEqual } from 'lodash';
import useTabs from 'src/appHooks/useTabs';
import { convertArrayToSelectOptions, FilterListType } from 'src/@types/list';
import { DEFAULT_TERM_AND_CONDITIONS_FILTERS, DefaultTermConditionProps, EnableDisableTermConditionProps, TermConditionFilters, TermConditionSearchResult, TermsConditionEntity, TermsConditionEntityArr } from 'src/@types/termCondition';
import termConditionOperations from 'src/redux/termCondition/termCondition-operations';
import { setFiltersInUrl } from 'src/redux/termCondition/termCondition-slices';
import Iconify from 'src/components/iconify';

export default function TermsConditionList() {

    const { translate } = useLocales();

    const { themeStretch } = useSettingsContext();

    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'md');

    const { isLoading, termConditionsList, totalCount, pageSize, pageIndex, filtersInUrl } = useSelector((state: RootState) => state.terms);

    const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('');

    const statsKeysToDelete: string[] = useMemo(() => ["pageIndex", "pageSize", "sortField", "sortAscending", "status"], []);

    const fullKeysToDelete: string[] = useMemo(() => ["name"], []);

    //---- SIDEBAR FILTERS ----// 
    const filtersInSidebar: FilterListType[] = useMemo(() => [
        {
            name: "name",
            label: translate(`commons.name`),
            type: "TextField"
        },
        {
            name: "entityType",
            label: translate('commons.type'),
            type: "Select",
            options: convertArrayToSelectOptions(TermsConditionEntityArr, translate, "termsCondition.form.type.")
        }
    ], [translate]);

    const updateCheckField = useCallback((field: string, filtersToCheck: TermConditionFilters) =>
        (filtersToCheck[field] || typeof filtersToCheck[field] === "boolean") && !isEqual(filtersToCheck[field], DEFAULT_TERM_AND_CONDITIONS_FILTERS[field])
        , []);

    //---- HANDLE TABLE START ----//
    const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

    const [actualRow, setActualRow] = useState<any>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const handleCellClick = (params: GridCellParams<any>) => {

        setActualRow(params);

        if (params.field !== "options") {
            navigate(PATH_DASHBOARD.termsCondition.detailTab(params.row.id, "en"));
        }
    };

    const handleLogs = useCallback((id: string) => {
        navigate(PATH_DASHBOARD.termsCondition.logs(id));
    }, [navigate]);

    const handleEdit = useCallback((id: string) => {
        navigate(PATH_DASHBOARD.termsCondition.edit(id));
    }, [navigate]);

    const handleEnableDisableTerm = useCallback(async (id: string, action: "enable" | "disable") => {

        const options: EnableDisableTermConditionProps = {
            id: id,
            action: action
        };

        let x = await dispatch(termConditionOperations.enableDisableTermCondition(options));

        if (x.meta.requestStatus !== 'fulfilled') {
            enqueueSnackbar(`${translate('commons.error')}`, {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
        else {
            enqueueSnackbar(`${translate(action === "enable" ? 'commons.enabled' : 'commons.disabled')}`, {
                variant: "success",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
    }, [enqueueSnackbar, translate]);

    const handleDefaultTerm = useCallback(async (id: string, entityType: TermsConditionEntity) => {

        const options: DefaultTermConditionProps = {
            id: id,
            entityType: entityType
        };

        let x = await dispatch(termConditionOperations.defaultTermCondition(options));

        if (x.meta.requestStatus !== 'fulfilled') {
            enqueueSnackbar(translate('commons.error'), {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
        else {
            enqueueSnackbar(translate('termsCondition.messages.defaultChange'), {
                variant: "success",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
    }, [enqueueSnackbar, translate]);

    const COLUMNS: GridColDef<TermConditionSearchResult>[] = useMemo(() => [
        {
            field: 'name',
            headerName: translate('commons.name'),
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: false
        },
        {
            field: 'entityType',
            headerName: translate('commons.type'),
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 180 : undefined,
            sortable: false,
            renderCell: (obj) => {
                return (
                    <Typography noWrap variant='body2'>
                        {translate(`termsCondition.form.type.${obj.row.entityType}`)}
                    </Typography>
                );
            }
        },
        {
            field: 'enabled',
            headerName: translate('commons.enabled'),
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj) => {
                return (
                    <Label
                        color={obj.row.enabled ? 'success' : 'error'}
                        sx={{ textTransform: 'uppercase' }}
                    >
                        {obj.row.enabled ? translate("commons.enabled") : translate("commons.disabled")}
                    </Label>
                );
            }
        },
        {
            field: 'isDefault',
            headerName: translate('termsCondition.form.default'),
            flex: isDesktop ? 0.5 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj) => {
                return (
                    <Iconify
                        icon={'eva:checkmark-fill'}
                        sx={{
                            width: 25,
                            height: 25,
                            color: 'success.main',
                            ...(!obj.row.isDefault && { color: (theme) => theme.palette.grey[300] })
                        }}
                    />
                );
            }
        },
        {
            field: 'options',
            headerName: ``,
            flex: isDesktop ? 0.2 : undefined,
            maxWidth: !isDesktop ? 70 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj) => {
                return (
                    <OptionsComponent
                        openMenu={openMenu}
                        handleOpenMenu={handleOpenMenu}
                        handleCloseMenu={handleCloseMenu}
                        object={obj}
                        currentRow={actualRow}
                        handleLogs={handleLogs}
                        handleEnableDisable={handleEnableDisableTerm}
                        handleEdit={handleEdit}
                        handleDefault={handleDefaultTerm}
                    />
                );
            }
        }
    ], [translate, isDesktop, openMenu, actualRow, handleLogs, handleEnableDisableTerm, handleEdit, handleDefaultTerm]);
    //---- HANDLE TABLE END ----//

    return (
        <Page title={`${translate(`menu.management.termsCondition.title`)}`}>
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs
                    heading={`${translate(`menu.management.termsCondition.title`)}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate(`termsCondition.title_short`)}` }
                    ]}
                />

                <GenericList
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    totalCount={totalCount}
                    list={termConditionsList}
                    isLoading={isLoading}
                    defaultFilters={DEFAULT_TERM_AND_CONDITIONS_FILTERS}
                    statsKeysToDelete={statsKeysToDelete}
                    fullKeysToDelete={fullKeysToDelete}
                    filtersInSidebar={filtersInSidebar}
                    datagridColumns={COLUMNS}
                    updateCheckField={updateCheckField}
                    context={"TermAndCondition"}
                    setActualRow={setActualRow}
                    handleCellClick={handleCellClick}
                    filterStatus={filterStatus}
                    onChangeFilterStatus={onChangeFilterStatus}
                    search={termConditionOperations.searchTermConditions}
                    filtersInUrl={filtersInUrl}
                    setFiltersInUrl={setFiltersInUrl}
                    listDescription={translate('termsCondition.subtitle')}
                />

            </Container>
        </Page>
    );
}

type OptionsComponentProps = {
    openMenu: HTMLElement | null,
    handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void,
    handleCloseMenu: () => void,
    object: GridRenderCellParams<TermConditionSearchResult, any, any, GridTreeNodeWithRender>,
    currentRow: any,
    handleLogs: (id: string) => void,
    handleEnableDisable: (id: string, action: "enable" | "disable") => Promise<void>,
    handleEdit: (id: string) => void,
    handleDefault: (id: string, entityType: TermsConditionEntity) => Promise<void>
};

function OptionsComponent({ openMenu, handleOpenMenu, handleCloseMenu, object, currentRow, handleLogs, handleEnableDisable, handleEdit, handleDefault }: OptionsComponentProps) {

    const { translate } = useLocales();

    return (
        <TableMoreMenu
            showMenu={currentRow && (object.id as string) === currentRow.id}
            open={openMenu}
            onOpen={(event) => handleOpenMenu(event)}
            onClose={() => handleCloseMenu()}
            actions={
                <>
                    {!object.row.isDefault &&
                        <PermissionBasedGuard permissions={[OrganizationPermissionTypes.TermsAndCondition_EnableDisable]}>
                            <MenuItem
                                onClick={() => {
                                    if (object.row.enabled) handleEnableDisable(object.id as string, "disable");
                                    else handleEnableDisable(object.id as string, "enable");

                                    handleCloseMenu();
                                }}
                                sx={{ color: object.row.enabled ? 'error.main' : 'success.main' }}
                            >
                                {!object.row.enabled ? `${translate("commons.enable")}` : `${translate("commons.disable")}`}
                            </MenuItem>
                        </PermissionBasedGuard>
                    }

                    {(!object.row.isDefault && object.row.enabled) &&
                        <PermissionBasedGuard permissions={[OrganizationPermissionTypes.TermsAndCondition_EnableDisable]}>
                            <MenuItem
                                onClick={() => {
                                    handleDefault(object.id as string, object.row.entityType);
                                    handleCloseMenu();
                                }}
                            >
                                {translate("termsCondition.setDefault")}
                            </MenuItem>
                        </PermissionBasedGuard>
                    }

                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.TermsAndCondition_Edit]}>
                        <MenuItem
                            onClick={() => {
                                handleEdit(object.id as string);
                                handleCloseMenu();
                            }}
                        >
                            {`${translate("commons.edit")}`}
                        </MenuItem>
                    </PermissionBasedGuard>

                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.TermsAndCondition_ViewLogs]} >
                        <MenuItem
                            onClick={() => {
                                handleLogs(object.id as string);
                                handleCloseMenu();
                            }}
                        >
                            {`${translate("commons.logs")}`}
                        </MenuItem>
                    </PermissionBasedGuard>
                </>
            }
        />
    );
}