import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
//
import { defaultLang } from '../config';

//
import en from './langs/en.json';
import it from './langs/it.json';
import de from './langs/de.json';
import fr from './langs/fr.json';
import es from './langs/es.json';
import nl from './langs/nl.json';
import pt from './langs/pt.json';

import enCommons from './langs/commons/en.json';
import itCommons from './langs/commons/it.json';
import deCommons from './langs/commons/de.json';
import frCommons from './langs/commons/fr.json';
import esCommons from './langs/commons/es.json';
import nlCommons from './langs/commons/nl.json';
import ptCommons from './langs/commons/pt.json';
// ----------------------------------------------------------------------

let lng = defaultLang.value;

if (typeof window !== 'undefined') {
  lng = localStorage.getItem('i18nextLng') || defaultLang.value;
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: { ...en, ...enCommons } },
      it: { translations: { ...it, ...itCommons } },
      de: { translations: { ...de, ...deCommons } },
      fr: { translations: { ...fr, ...frCommons } },
      pt: { translations: { ...pt, ...ptCommons } },
      es: { translations: { ...es, ...esCommons } },
      nl: { translations: { ...nl, ...nlCommons } },
    },
    lng,
    fallbackLng: defaultLang.value,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
