import { Business } from '@mui/icons-material';
import { Box, Card, Divider, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { NewGroup } from 'src/@types/group';
import StepButtons from 'src/components/buttons/StepButtons';
import PermissionTable from 'src/components/table/PermissionTable';
import { useLocales } from 'src/locales';
import { Action } from 'src/sections/@dashboard/group/newEditGroup/GroupWizard';

interface PermissionStepProps {
    changeStep: Dispatch<SetStateAction<number>>,
    state: NewGroup,
    onSubmit: Dispatch<Action>
}

export default function PermissionStep({ changeStep, onSubmit, state }: PermissionStepProps) {

    const { translate } = useLocales();

    const [permissions, setPermissions] = useState<string[]>(state.permissionDependency.permissions);

    const [atLeastOne, setAtLeastOne] = useState(state.permissionDependency.atLeastOne);

    return (
        <Card sx={{ p: 3 }}>
            <Box sx={{ mb: 2 }}>
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', mb: 3 }}>
                    <Typography variant='h6'>
                        {translate('notificationTemplate.form.permission_plural')}
                    </Typography>
                    {state?.groupType && <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Business />
                        <Typography variant="subtitle1">
                            {translate(state.groupType === "Customer" ? "commons.customer_plural" : "commons.organizations")}
                        </Typography>
                    </Box>}
                </Box>

                <PermissionTable
                    defaultValue={state.permissionDependency.permissions}
                    onChange={(val) => setPermissions(val)}
                    switchField
                    noHereditary
                    swithcValue={atLeastOne}
                    setSwitch={(v) => setAtLeastOne(v)}
                />
                <Divider />
            </Box>
            {(!state.propertyDependency.properties.length && !permissions.length) &&
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', mb: 1 }}>
                    <Typography variant="body2" color={'GrayText'}>
                        {translate('groups.messages.validation')}

                    </Typography>
                </Box>}
            <StepButtons
                changeStep={changeStep}
                disabled={!state.propertyDependency.properties.length && !permissions.length}
                onSave={() => onSubmit({
                    payload: {
                        permissionDependency: {
                            permissions,
                            atLeastOne
                        }
                    },
                    type: 'update'
                })}
            />
        </Card>
    );
}