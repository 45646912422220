import { useContext } from 'react';
import { TenantContext } from '../contexts/TenantContext';

// ----------------------------------------------------------------------

const useTenant = () => {
    const context = useContext(TenantContext);

    if (!context) throw new Error('Tenant context must be use inside TenantProvider');

    return context;
};

export default useTenant;
