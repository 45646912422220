import { Card, Step, Typography, StepLabel, Stepper, Box } from "@mui/material";
import { useMemo } from "react";
import { Request } from "src/@types/request";
import DateZone from "src/appComponents/DateZone";
import { useLocales } from "src/locales";

interface TrackingProps {
    request: Request
}

export default function Tracking({ request }: TrackingProps) {

    const { translate } = useLocales();

    const { tracking } = request;

    const currentStep = useMemo(() => tracking.findIndex(val => !val.createdOn) - 1, [tracking]);

    return (
        <Card sx={{ p: 3, minHeight: '800px' }}>
            <Stepper sx={{ height: '100%' }} activeStep={currentStep >= 0 ? currentStep : 0} orientation="vertical">
                {tracking.map((step, index) => (
                    <Step key={index} >
                        <StepLabel optional={
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <Typography variant="caption">
                                    {`${translate(`request.track.description.${step.status[0].toLowerCase() + step.status.slice(1)}`)}`}
                                </Typography>

                                {step.createdOn && <DateZone date={step.createdOn} variant="caption" />}

                            </Box>

                        }>
                            <Typography variant="body2">{`${translate(`request.track.label.${step.status[0].toLowerCase() + step.status.slice(1)}`)}`}</Typography>

                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Card>
    );
}