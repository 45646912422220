import { Button } from '@mui/material';
import Iconify from 'src/components/iconify';
import { useLocales } from 'src/locales';
import { RootStyle, FilterChip, MultipleFilterChip, CustomFieldsFilterChip } from 'src/utils/SummaryUtils';
import { DEFAULT_DOCUMENTS_FILTERS, DocumentFilters } from 'src/@types/orders';
import { useSettingsContext } from 'src/components/settings';

type Props = {
    filters: DocumentFilters,
    isShowReset: boolean,
    onResetAll: VoidFunction,
    onResetFilter: (el: any, value?: any) => void,
    MAX_PRICE_CONVERTED: number
}

export default function DocumentsFilterSummary({ filters, isShowReset, onResetAll, onResetFilter, MAX_PRICE_CONVERTED }: Props) {

    const { translate } = useLocales();

    const { currency: currentCurrency } = useSettingsContext();

    const { externalId, documentType, tags, customer, customerId, minAmount, maxAmount, customFields } = filters;

    return (
        <RootStyle sx={{ mb: 2, ml: 2 }}>
            {isShowReset &&
                <>
                    {externalId !== DEFAULT_DOCUMENTS_FILTERS.externalId &&
                        <FilterChip
                            el={"documents.filter.docRef"}
                            value={externalId!}
                            onResetFilter={onResetFilter}
                            toDelete={"externalId"}
                        />
                    }

                    {documentType !== DEFAULT_DOCUMENTS_FILTERS.documentType &&
                        <FilterChip
                            el={"commons.type"}
                            value={`${translate(`documents.filter.${documentType!}`)}`}
                            onResetFilter={onResetFilter}
                            toDelete={"documentType"}
                        />
                    }

                    {customerId !== DEFAULT_DOCUMENTS_FILTERS.customerId &&
                        < FilterChip
                            el={"orders.filter.customerId"}
                            value={customer!.name!}
                            onResetFilter={onResetFilter}
                        />
                    }

                    {((minAmount && minAmount > 0) || (maxAmount && maxAmount < MAX_PRICE_CONVERTED)) &&
                        <FilterChip
                            el={"organization.form.payment.balance"}
                            value={`${currentCurrency.symbol} ${minAmount}k - ${maxAmount}k`}
                            onResetFilter={onResetFilter}
                            toDelete={"amount"}
                        />
                    }

                    {(tags && tags.length > 0) &&
                        < MultipleFilterChip
                            el={"commons.tags"}
                            value={tags}
                            onResetFilter={onResetFilter}
                        />
                    }

                    {customFields && Object.entries(customFields).length > 0 &&
                        <CustomFieldsFilterChip
                            context={"spareparts"}
                            customfieldValues={customFields}
                            onResetFilter={onResetFilter}
                        />
                    }

                    <Button
                        color="error"
                        size="small"
                        onClick={onResetAll}
                        startIcon={<Iconify icon={'ic:round-clear-all'} />}
                    >
                        {`${translate("commons.clear")}`}
                    </Button>
                </>
            }

        </RootStyle >
    );
}
