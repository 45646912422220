import { useFormContext, Controller } from 'react-hook-form';
import { TextField, TextFieldProps, useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useLocales } from 'src/locales';
import { useRef } from 'react';

// ----------------------------------------------------------------------

type RHFSelectProps = TextFieldProps & {
  name: string;
  native?: boolean;
  maxHeight?: boolean | number;
  children: React.ReactNode;
  onChangeVal?: (value: any) => void;
  setOnEditForm?: (value: boolean) => void;
  onEditForm?: boolean;
};

export function RHFSelect({ name, native, maxHeight = 220, children, onChangeVal, setOnEditForm, onEditForm, ...other }: RHFSelectProps) {

  const { control } = useFormContext();

  const theme = useTheme();

  const { translate } = useLocales();

  const hiddenInputRef = useRef<HTMLInputElement>(null);

  const onBlurFunc = () => {
    if (onChangeVal) {
      onChangeVal(name);
      if (setOnEditForm) {
        setOnEditForm(false);
      }
    }
  };

  const onFocusFunc = () => {
    if (setOnEditForm) {
      setOnEditForm(true);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <TextField
            {...field}
            id={name}
            select
            fullWidth
            SelectProps={{
              native,
              MenuProps: {
                PaperProps: {
                  sx: {
                    ...(!native && {
                      px: 1,
                      maxHeight: typeof maxHeight === 'number' ? maxHeight : 'unset',
                      '& .MuiMenuItem-root': {
                        px: 1,
                        borderRadius: 0.75,
                        typography: 'body2',
                        textTransform: 'capitalize',
                      },
                    }),
                  },
                },
              },
              onClose: () => {
                setTimeout(() => {
                  if (hiddenInputRef.current) {
                    hiddenInputRef.current.focus();
                    hiddenInputRef.current.blur();
                  }
                }, 0);
              },
              sx: {
                textTransform: 'capitalize'
              }
            }}

            error={!!error}
            helperText={error && `${translate(error?.message)}`}
            {...other}
            sx={{ ...other.sx, '& .MuiInputLabel-asterisk': { color: 'error.main' }, '& .MuiOutlinedInput-root': { '& fieldset': { background: other.disabled ? alpha(theme.palette.grey[300], 0.20) : "", } } }}

            onFocus={() => onFocusFunc()}
            onBlur={() => onBlurFunc()}
          >
            {children}
          </TextField>

          <input
            ref={hiddenInputRef}
            style={{ position: 'absolute', opacity: 0, width: 0, height: 0, border: 0, padding: 0 }}
          />
        </>

      )}
    />

  );
}