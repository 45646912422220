import React, { useState } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { DEFAULT_IMAGE_IN_MODAL, FileWithPreview, ImageInModalType } from 'src/@types/media';
import ImageEditor from './ImageEditor/ImageEditor';
import { Box, Typography } from '@mui/material';
import { useLocales } from 'src/locales';
import { fData } from 'src/utils/formatNumber';
import UploadAvatarNew from './UploadAvatarNew';

interface AvatarUploaderEditorProps {
    onChangeImage: (files: (ImageInModalType & File)[]) => void,
    defaultImage: (ImageInModalType & File)[],
    onChangePreview: (files: FileWithPreview[]) => void,
    defaultPreview: FileWithPreview[]
}

export default function AvatarUploaderEditor({ onChangeImage, defaultImage, onChangePreview, defaultPreview }: AvatarUploaderEditorProps) {

    const { translate } = useLocales();

    const [previewFile, setPreviewFile] = useState<FileWithPreview[]>(defaultPreview);

    const [originalFile, setOriginalFile] = useState<(ImageInModalType & File)[]>(defaultImage);

    const [imageInModal, setImageInModal] = useState<ImageInModalType>(DEFAULT_IMAGE_IN_MODAL);

    const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {

        if (e.target.files && e.target.files.length > 0) {

            const newFiles: any[] = Object.values(e.target.files).map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    aspect: 1
                })
            );

            newFiles.forEach((file) => {
                if (!file.originalWidth) {
                    const img = new Image();

                    img.src = file.preview;

                    img.onload = () => {
                        file.originalWidth = img.width;
                        file.originalHeight = img.height;
                    };
                }
            });

            setPreviewFile(newFiles as FileWithPreview[]);

            setOriginalFile(newFiles as (ImageInModalType & File)[]);

            openCropWindow(newFiles as (ImageInModalType & File)[]);

            onChangeImage(newFiles as (ImageInModalType & File)[]);

            onChangePreview(newFiles as FileWithPreview[]);
        }
    };

    const openCropWindow = (newFiles?: (ImageInModalType & File)[]) => {

        const file = newFiles ? newFiles[0] : originalFile[0];

        const reader = new FileReader();

        reader.addEventListener('load', () => {
            setImageInModal({
                preview: reader.result?.toString() || '',
                crop: file.crop,
                rotation: file.rotation ?? DEFAULT_IMAGE_IN_MODAL.rotation,
                scale: file.scale ?? DEFAULT_IMAGE_IN_MODAL.scale,
                aspect: file.aspect ?? DEFAULT_IMAGE_IN_MODAL.aspect
            });
        });

        reader.readAsDataURL(file);
    };

    const onDeleteFile = () => {
        setPreviewFile([]);

        setOriginalFile([]);

        onChangeImage([]);

        onChangePreview([]);
    };

    //TODO: quando si inviano le immagini serve applicare lo scale

    return (
        <>
            <ImageEditor
                previewFiles={previewFile}
                setPreviewFiles={(f) => {
                    setPreviewFile(f);
                    onChangePreview(f);
                }}
                originalFiles={originalFile}
                setOriginalFiles={(f) => {
                    setOriginalFile(f);
                    onChangeImage(f);
                }}
                imageInModal={imageInModal}
                setImageInModal={setImageInModal}
                imgIndex={0}
                circularCrop
            />

            <UploadAvatarNew
                file={previewFile}
                onEdit={openCropWindow}
                onRemove={onDeleteFile}
                onUploadImage={onSelectFile}
                helperText={
                    <Box sx={{ display: 'flex', flexDirection: 'column', my: 2 }}>

                        <Typography variant="subtitle1">{translate("user.form.maxSize")}</Typography>

                        <Typography
                            variant="caption"
                            sx={{
                                mt: 2,
                                mx: 'auto',
                                display: 'block',
                                textAlign: 'center',
                                color: 'text.secondary',
                            }}
                        >
                            {`${translate("user.form.allowed")} *.jpeg, *.jpg, *.png, *.gif`}
                            <br /> {translate("user.form.maxSize")} {fData(3145728)}
                        </Typography>

                    </Box>
                }
            />

        </>
    );
}
