import { CurrencyTypes } from "./vehicle";

export interface UserMatrixTemplateDocument {
    id: string | null;
    name: string;
    description: string;
    quarter?: string,
    validFrom: string | null;
    validTo: string | null;
    manufacturerCodes: string[]; //step2
    pivotRv: PivotRvDocument; //step3
    pivotKm: PivotKmDocument;  //step4
    increaseRvSteps: IncreaseRvStepsDocument; //step5
    decreaseRvSteps: DecreaseRvStepsDocument; //step6
    decreaseRv: DecreaseRvDocument; //step7
    tags: string[]
}

export interface ApprovedMatrixData {
    notes: string
}

export interface RejectedMatrixData {
    reason: string
}

export interface GetPNOProps {
    startDate?: string,
    endDate?: string,
    includeDisabled?: boolean
}

export interface MatrixStatus {
    rejected: number;
    approved: number;
    draft: number;
    pending: number;
    total: number;
}

export interface MatrixTemplatesSearchFilters {
    name: string,
    country: string,
    includeDisabled: boolean,
    manufacturerCodes: string[],
    tags: string[],
    validFrom: string,
    validTo: string
}

export interface MatrixTemplateSearchResult {
    id: string,
    createdOn: string,
    enabled: boolean,
    ownerLastName: string,
    ownerFirstName: string,
    country: string,
    name: string,
    description: string,
    validFrom: string,
    validTo: string,
    manufacturerCodes: string[],
    tags: string[],
}

export interface PivotRvDocument {
    before: number;
    after: number;
    center: number;
    minimal: number;
    monthsPercent: { [key: string]: number; };
}

export interface PivotKmDocument {
    kmAmount: number;
    kmPerMonth: { [key: string]: number; };
}

export interface IncreaseRvStepsDocument {
    kmStep: number;
    kmPerMonth: { [key: string]: number; };
    increaseRvPerMonth: { [key: string]: number; };
}

export interface DecreaseRvStepsDocument {
    upToKm: number;
    overToKm: number;
    steps: { [key: string]: number; }
}

export interface DecreaseRvDocument {
    decreaseKmVsPivot: { [key: string]: number; };
    decreasePerMonth: { [key: string]: (number | null)[]; };
}

export interface PNOList {
    [key: string]: IPNO5
}

export interface IPNO5 {
    engineInfo: string,
    pno12: { [key: string]: PNO12 }
}

export type PNO12 = {
    salesVersion?: string,
    yearEdition: string,
    transmission: string,
    vehicleLine: string,
    lineName: string
    pno18: { [key: string]: PNO18 }

}

export type PNO18 = {
    exteriorName?: string,
    roofColor: string
    roofName?: string,
    lineName: string,
    exteriorColor?: string
}

export interface MatrixRow {
    [key: string]: MatrixColumn[]
}

export interface MatrixColumn {
    months: number,
    rvValueInPercentage: number
}

export interface IrvMatrixPreview {
    rejectedReason: string;
    approvedAt: string;
    rejectedBy: any;
    rejectedAt: string;
    approvedBy: any;
    approverNotes: string;
    status: string,
    country: string,
    startDate: string,
    endDate: string,
    name: string,
    id: string,
    enabled: boolean,
    manufacturerCodes: string[],
    rows: {
        [key: string]: IrvMatrixRow[]
    }
}

export interface IrvMatrixRow {
    months: number,
    rvValueInPercentage: number
}

export type PNO18Price = {
    pno18: string,
    catalogPriceWithVat: number,
    transmission: string,
    catalogPriceWithoutVat: number,
    currencyTypes: CurrencyTypes,
    salesVersion: string,
    lineName: string,
    exteriorName: string,
    importDate: string,
    roofName: string,
    yearEdition: string
}

export interface RvMatrixSearchFilters {
    [key: string]: any,
    username: { name: string, id: string };
    all: string | null;
    ownerId: null | string;
    name: string,
    country: string,
    status: string,
    manufacturerCodes: string[],
    includeDisabled: boolean,
    tags: string[],
    startDate: string,
    endDate: string,
}

export interface RvMatrixSearchResult {
    id: string,
    createdOn: string,
    ownerId: string,
    ownerLastName: string,
    ownerFirstName: string,
    name: string,
    country: string,
    enabled: boolean,
    status: string,
    hasNotes: string,
    hasApproverNotes: string,
    startDate: string,
    endDate: string,
    manufacturerCodes: string[],
    tags: string[],
}

export type PNO = {
    [key: string]: {
        vehicleLine: string,
        pno12: {
            [key: string]: PNO12
        }
    }
}

export type PNOPrices = {
    pno18: string,
    pno18Description: string,
    lineName: string,
    vehicleLine: string,
    yearEdition: string,
    transmission: 'Front' | 'Rear' | 'FourWheelDrive',
    steeringWheelPosition: 'Left' | 'Right' | 'Central',
    exteriorCode: string,
    exteriorColor: string,
    roofCode: string,
    roofColor: string,
    interiorCode: string,
    interiorColor: string,
    winterWheelsCode: string,
    winterWheelPrice: {
        withVat: number,
        withoutVat: number,
        currentCurrency: CurrencyTypes,
        withVatInEuro: number,
        withoutVatInEuro: number,
        rate: number,
        updatedOn: string
    },
    trailerHitchCode: string,
    trailerHitchPrice: {
        withVat: number,
        withoutVat: number,
        currentCurrency: CurrencyTypes,
        withVatInEuro: number,
        withoutVatInEuro: number,
        rate: number,
        updatedOn: string
    },
    cataloguePrice: {
        withVat: number,
        withoutVat: number,
        currentCurrency: CurrencyTypes,
        withVatInEuro: number,
        withoutVatInEuro: number,
        rate: number,
        updatedOn: string
    }
}

export enum RvMatrixStatusTypes {
    // Preview,
    Draft,
    Rejected,
    PendingApproval,
    Approved
}
