import { Autocomplete, Avatar, Box, Card, Chip, FormControlLabel, IconButton, MenuItem, Switch, TablePagination, TextField, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useCallback, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { GroupCategory, NewGroup, WhiteBlackListDisplay } from "src/@types/group";
import { OrganizationFilters, OrganizationSearchResult } from "src/@types/organizations";
import StepButtons from "src/components/buttons/StepButtons";
import { useLocales } from "src/locales";
import { organizationsOperations } from "src/redux/organizations";
import { dispatch, RootState } from "src/redux/store";
import { GreyCounter } from "../../webshop/newWebshop/CustomerStep";
import { debounce } from "lodash";
import { usersOperations } from "src/redux/users";
import { UserFilters, UserSearchResult } from "src/@types/user";
import { Business, DeleteForever } from "@mui/icons-material";
import { GridColDef, DataGrid } from "@mui/x-data-grid";
import useTable from "src/appHooks/useTable";
import useResponsive from "src/hooks/useResponsive";
import { DataGridStyle } from "src/utils/DataGridStyle";
import { noData } from "src/components/empty-content/EmptyContent";
import CurrencyAdapter from "src/appComponents/CurrencyAdapter";
import { CurrencyTypes } from "src/@types/vehicle";
import DateZone from "src/appComponents/DateZone";

interface WhiteBlacklistStepProps {
    changeStep: Dispatch<SetStateAction<number>>,
    state: NewGroup,
    onSubmit: (list: WhiteBlackListDisplay, black?: boolean) => void,
    blackList?: boolean,
    list: WhiteBlackListDisplay
}

export default function WhiteBlacklistStep({ changeStep, onSubmit, state, blackList, list }: WhiteBlacklistStepProps) {

    const { translate } = useLocales();

    const [selectSearch, setSelectSearch] = useState<"Users" | "Organizations">("Users");

    const { isLoading, organizationList } = useSelector((state: RootState) => state.organizations);

    const { isLoading: isUserLoading, userList } = useSelector((state: RootState) => state.user);

    const debounced = useRef(debounce(async (options: OrganizationFilters, type: GroupCategory) => {
        if (type === "Customer")
            await dispatch(organizationsOperations.searchCustomerOrganizations(options));
        else
            await dispatch(organizationsOperations.searchVendorOrganizations(options));

    }, 500));

    const searchOrganizations = (v: string) => {
        let options: OrganizationFilters = {
            pageIndex: 0,
            pageSize: 5,
            status: "Enabled",
            all: v
        };

        debounced.current(options, state.groupType);
    };

    const debouncedUsers = useRef(debounce(async (options: UserFilters) => {
        await dispatch(usersOperations.searchUsers(options));

    }, 500));

    const searchUsers = (v: string) => {
        let options: UserFilters = {
            pageIndex: 0,
            pageSize: 5,
            onlyEnabled: true,
            all: v
        };

        debouncedUsers.current(options);
    };

    const [blackWhiteList, setBlackWhiteList] = useState<WhiteBlackListDisplay>(list);

    const handleSave = () => {

        onSubmit(blackWhiteList, blackList);
    };

    return (
        <Card sx={{ p: 3 }}>
            <Box sx={{ my: 3 }}>
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', mb: 3 }}>
                    <Typography variant='h6'>
                        {translate(blackList ? "groups.form.selectBlacklist" : "groups.form.selectWhitelist")}
                    </Typography>
                    {state?.groupType && <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Business />
                        <Typography variant="subtitle1">
                            {translate(state.groupType === "Customer" ? "commons.customer_plural" : "commons.organizations")}
                        </Typography>
                    </Box>}
                </Box>
                <Box sx={{ justifyContent: 'center', display: 'flex', pb: 0, my: 3, gap: 2 }}>
                    <TextField
                        sx={{ width: '30%' }}
                        label={translate('commons.search')}
                        select
                        value={selectSearch}
                        onChange={(e) => setSelectSearch(e.target.value as ("Users" | "Organizations"))}
                    >
                        <MenuItem value="Users">
                            {translate('commons.users')}
                        </MenuItem>
                        <MenuItem value="Organizations">
                            {translate('commons.organizations')}
                        </MenuItem>
                    </TextField>
                    {selectSearch === "Users" &&
                        <Autocomplete
                            fullWidth
                            id="userSelection"
                            freeSolo
                            filterOptions={(options) => options}
                            disabled={blackWhiteList.users.length >= 100}
                            loading={isUserLoading}
                            options={userList}
                            onInputChange={(_, v) => searchUsers(v)}
                            onChange={(_, v) => {
                                if (v && typeof v !== 'string' && !blackWhiteList.users.find(el => el.id === v.id))
                                    setBlackWhiteList(p => ({ ...p, users: p.users.concat([v]) }));
                            }}
                            getOptionLabel={(option) => typeof option !== "string" ? option.firstName + " " + option.lastName : ""}
                            renderOption={(props, option) =>
                                <li {...props} key={option.id}>
                                    <Typography sx={{ mr: 2 }}>{option.firstName + " " + option.lastName}</Typography>
                                    <Chip label={option.username} />
                                </li>
                            }
                            renderInput={(params) => (<TextField
                                {...params}
                                label={`${translate('user.form.searchIdName')}`}
                                helperText={blackWhiteList.users.length >= 100 && translate('groups.messages.maxNumber')}
                            />)}
                        />}
                    {selectSearch === "Organizations" &&
                        <Autocomplete
                            fullWidth
                            id="organizationSelection"
                            freeSolo
                            disabled={blackWhiteList.organizations.length >= 100}
                            loading={isLoading}
                            filterOptions={(options) => options}
                            options={organizationList}
                            onInputChange={(_, v) => searchOrganizations(v)}
                            onChange={(_, v) => {
                                if (v && typeof v !== 'string' && !blackWhiteList.organizations.find(el => el.id === v.id))
                                    setBlackWhiteList(p => ({ ...p, organizations: p.organizations.concat([v]) }));
                            }}
                            getOptionLabel={(option) => typeof option !== "string" ? option.name : ""}
                            renderOption={(props, option) =>
                                <li {...props} key={option.id}>
                                    <Typography sx={{ mr: 2 }}>{option.externalId ? option.externalId + " - " : ""}{option.name}</Typography>
                                    <Chip label={option.type} />
                                </li>
                            }
                            renderInput={(params) => (
                                <TextField {...params}
                                    label={`${translate('user.form.searchIdName')}`}
                                    helperText={blackWhiteList.organizations.length >= 100 && translate('groups.messages.maxNumber')}
                                />)}
                        />}
                </Box>
            </Box>
            <Box sx={{ mb: 3 }}>
                {["Users", "Organizations"].map((type) => {

                    const list = type === "Users" ? blackWhiteList.users : blackWhiteList.organizations;

                    if (selectSearch === type)
                        return (

                            <Box key={type}>
                                {!list.length ?
                                    <GreyCounter label={translate('groups.messages.noSelected')} />
                                    :
                                    <WhiteBlackList type={selectSearch} list={blackWhiteList} setList={(v) => setBlackWhiteList(v)} />
                                }
                            </Box>

                        );
                })}
            </Box>
            <StepButtons changeStep={changeStep} onSave={() => handleSave()} />
        </Card>
    );
}

interface WhiteBlackListProps {
    list: WhiteBlackListDisplay,
    type: "Users" | "Organizations",
    setList?: (list: WhiteBlackListDisplay) => void,
    isLoading?: boolean,
    totalCount?: number
}

export function WhiteBlackList({ list, setList, type, isLoading, totalCount }: WhiteBlackListProps) {

    const { translate } = useLocales();

    const { dense, onChangeDense, page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable();

    const isDesktop = useResponsive('up', 'lg');

    const handleDelete = useCallback((id: string) => {

        const returnList: WhiteBlackListDisplay = {
            organizations: type === "Organizations" ? list.organizations.filter(el => el.id !== id) : list.organizations,
            users: type === "Users" ? list.users.filter(el => el.id !== id) : list.users,
        };

        if (setList)
            setList(returnList);
    }, [list, setList, type]);

    const COLUMNS_USERS: GridColDef<UserSearchResult>[] = useMemo(() => [
        {
            field: 'firstName',
            headerName: `${translate('commons.name')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            renderCell: (obj) => {
                return (
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Avatar
                            alt={obj.row.firstName + " " + obj.row.lastName}
                            src={obj.row.pictureUrl}
                            sx={{ mr: 2, width: dense ? 24 : 40, height: dense ? 24 : 40 }}
                        />
                        <Typography variant="subtitle2" noWrap>
                            {obj.row.firstName + " " + obj.row.lastName}
                        </Typography>
                    </Box>
                );
            }
        },
        {
            field: 'username',
            headerName: `${translate('commons.username')}`,
            flex: isDesktop ? 0.8 : undefined,
            minWidth: !isDesktop ? 250 : undefined
        },
        {
            field: 'lastLogin',
            headerName: `${translate('user.list.tableHeaders.lastLogin')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 200 : undefined,
            renderCell: (obj) => {
                return (
                    <DateZone
                        date={obj.row.lastLogin}
                        shortMonth
                        noSeconds
                        variant={"body2"}
                    />
                );
            }
        },
        {
            field: 'email',
            headerName: `${translate('commons.email')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 200 : undefined
        },
        ...(setList ? [
            {
                field: 'del',
                headerName: '',
                flex: isDesktop ? 0.25 : undefined,
                minWidth: !isDesktop ? 155 : undefined,
                renderCell: (params: any) =>
                    <IconButton onClick={() => handleDelete(params.id.toString())}>
                        <DeleteForever />
                    </IconButton>
            }
        ] : [])
    ], [dense, handleDelete, isDesktop, setList, translate]);

    const COLUMNS_ORGS: GridColDef<OrganizationSearchResult>[] = useMemo(() => [
        {
            field: 'externalId',
            headerName: `${translate('commons.code')}`,
            flex: isDesktop ? 0.6 : undefined,
            minWidth: !isDesktop ? 100 : undefined
        },
        {
            field: 'name',
            headerName: `${translate('commons.name')}`,
            flex: isDesktop ? 1.3 : undefined,
            minWidth: !isDesktop ? 250 : undefined
        },
        {
            field: 'type',
            headerName: `${translate('organization.list.table.type')}`,
            flex: isDesktop ? 0.75 : undefined,
            minWidth: !isDesktop ? 150 : undefined
        },
        {
            field: 'credit',
            headerName: `${translate('organization.list.table.credit')}`,
            flex: isDesktop ? 0.6 : undefined,
            minWidth: !isDesktop ? 150 : undefined,
            renderCell: (obj) => {
                return (
                    <CurrencyAdapter
                        value={obj.row.creditLimit}
                        currency={CurrencyTypes.EUR}
                        fontSize={'0.875rem'}
                        sx={{ pl: 1 }}
                    />
                );

            },
        },
        ...(setList ? [
            {
                field: 'del',
                headerName: '',
                flex: isDesktop ? 0.25 : undefined,
                minWidth: !isDesktop ? 155 : undefined,
                renderCell: (params: any) =>
                    <IconButton onClick={() => handleDelete(params.id.toString())}>
                        <DeleteForever />
                    </IconButton>
            }
        ] : [])
    ], [handleDelete, isDesktop, setList, translate]);

    const getHeight = () => {
        let height: string | number = "auto";

        const { length } = (type === 'Users' ? list.users : list.organizations);

        // if (!totalCount) return 120 + 56 * length;

        if (!dense || length === 0) {
            if (isDesktop) height = rowsPerPage === 5 ? 380 : 650;
            else height = rowsPerPage === 5 ? 440 : 700;
        }

        return height;
    };

    const getMaxHeight = () => {
        //if (!totalCount) return "auto";

        return isDesktop ? 650 : 700;
    };

    return (
        <DataGrid
            rows={(type === 'Users' ? list.users : list.organizations) as any}
            columns={(type === 'Users' ? COLUMNS_USERS : COLUMNS_ORGS) as any}
            pagination
            paginationMode="client"
            paginationModel={{
                page: page,
                pageSize: rowsPerPage
            }}
            disableColumnResize
            density={dense ? 'compact' : 'standard'}
            disableColumnMenu
            pageSizeOptions={[5, 10, 15, 30]}
            disableRowSelectionOnClick
            loading={isLoading}
            slots={{
                noRowsOverlay: noData,
                footer: () => (
                    <Box sx={{
                        position: 'relative',
                        width: { xs: "90vw", md: "auto" },
                    }}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15, 30]}
                            component="div"
                            count={totalCount ? totalCount : (type === 'Users' ? list.users.length : list.organizations.length)}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={onChangePage}
                            onRowsPerPageChange={onChangeRowsPerPage}
                            labelRowsPerPage={`${translate('commons.rowsPerPage')}`}
                            sx={{
                                overflow: "hidden",
                                "& .MuiTablePagination-input": {
                                    ml: { xs: 0.5, md: "default" },
                                    mr: { xs: 3.5, md: "default" }
                                }
                            }}
                        />
                        <FormControlLabel
                            control={<Switch
                                checked={dense}
                                onChange={onChangeDense}
                            />}
                            label={`${translate('commons.dense')}`}
                            sx={{
                                py: { xs: 0, sm: 1.5 },
                                pb: { xs: 1.5, sm: 0 },
                                mx: 0,
                                top: 0,
                                justifyContent: "center",
                                width: { xs: "90vw", sm: "auto" },
                                position: { sm: 'absolute' }
                            }}
                        />
                    </Box>
                )
            }}
            sx={{
                ...DataGridStyle,
                cursor: "default",
                height: getHeight(),
                maxHeight: getMaxHeight()
            }}
        />
    );
}