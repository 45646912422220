import { Container } from '@mui/material';
import useLocales from 'src/appHooks/useLocales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import Page from 'src/appComponents/Page';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import OrdersList from './OrdersList';

export default function CustomerOrdersList() {

    const { themeStretch } = useSettingsContext();

    const { translate } = useLocales();

    return (
        <Page title={`${translate('menu.management.orders.custTitle')}`}>
            <Container maxWidth={themeStretch ? false : 'lg'}>

                <HeaderBreadcrumbs
                    heading={`${translate('menu.management.orders.custTitle')}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate('commons.list')}` },
                    ]}
                />

                <OrdersList isVendor={false} />

            </Container>
        </Page>
    );
}
